import gql from "graphql-tag";
import { ApolloLink, Observable } from "apollo-link";
import { UserInsuranceStatus } from "./enums";
import { userFragment } from "@/portal/pages/admin/fragments";
import { store } from "@/portal/store";
import router from "@/portal/router";
import { apolloClient } from "@/portal/apollo";
import mobileApp from "@/portal/mobileApp";
import { sleep, blitzInitializeUser } from "@/utils";
import { UserInviteType } from "@/common/enums";
/*
  - calls login mutation
  - commits the creds to the
  vuex store and local storage

  - it optionally does redirect as well
*/
export async function loginUser(mutation, redirect = false, router) {
  try {
    // if user is already loggedIn skip login
    if (store?.getters?.isLoggedIn && store?.state?.user && redirect) {
      redirectAfterAuthn(store.state.user, router);
      return { status: "success", message: "User already logged in" };
    }
    const authenticateUser = await mutation();
    if (!authenticateUser) {
      redirectToUnauthorized(router);
      return { status: "error", message: "Couldn't login user!" };
    }
    const { user, token, refreshToken } = authenticateUser;
    if (user?.id) {
      // initialize blitzllama user attributes after login
      blitzInitializeUser(user);
    }
    if (user?.orgEntity) {
      store.commit("setSelectedOrgEntity", user?.orgEntity);
    }
    store.commit("loginUser", { user, token, refreshToken });

    await checkAndUpdateUserConsentStatus();

    if (redirect) {
      redirectAfterAuthn(user, router);
    }
  } catch (err) {
    const magicToken = router?.currentRoute?.query?.magicToken;
    if (err.message.includes("Magic link expired") && magicToken) {
      router.push({ name: "invite-expired", query: { magicToken, type: UserInviteType.MagicLink } });
      return { status: "error", message: "Magic link expired" };
    }
    redirectToUnauthorized(router);
    return { status: "error", message: "Unable to login" };
  }
}

export async function loginImpersonatedUser(authenticateUser) {
  const { token, refreshToken } = authenticateUser;

  localStorage.setItem("imposterToken", localStorage.getItem("token"));
  localStorage.setItem("imposterRefreshToken", localStorage.getItem("refreshToken"));
  localStorage.setItem("imposterUser", localStorage.getItem("user"));
  localStorage.setItem("imposterLastVisitedUrl", window.location.href);

  // need to do it here to get impersonateOtherUser details
  localStorage.setItem("token", token);

  await apolloClient.cache.data.clear();

  const newUser = await apolloClient.query({
    query: gql`
      query ImpersonatedUser {
        me {
          ...User
        }
      }
      ${userFragment}
    `,
  });

  store.commit("loginUser", { user: newUser.data.me, token, refreshToken });
  if (newUser?.data?.me?.orgEntity) {
    store.commit("setSelectedOrgEntity", newUser?.data?.me?.orgEntity);
  }
}

export async function logoutImpersonatedUser() {
  const authenticateUser = {
    token: localStorage.getItem("imposterToken"),
    refreshToken: localStorage.getItem("imposterRefreshToken"),
    user: JSON.parse(localStorage.getItem("imposterUser")),
  };

  await loginUser(async () => authenticateUser);

  const redirectUrl = localStorage.getItem("imposterLastVisitedUrl");
  localStorage.removeItem("imposterToken");
  localStorage.removeItem("imposterUser");
  localStorage.removeItem("imposterLastVisitedUrl");
  localStorage.removeItem("imposterRefreshToken");

  window.location.href = redirectUrl;
}

export function redirectToUnauthorized(router) {
  router.push({ name: "error", params: { type: "unauthorized" } });
}

/*
  Logout user removes the creds from
  vuex store and local storage

  it shows the toast for logout
  redirects to unauthorized page
*/
export async function logoutActiveUser(forced = true, isMobileApp = false) {
  return new Promise(async (resolve, reject) => {
    try {
      // mobile app google signout
      if (isMobileApp) {
        await mobileApp.request("GOOGLE_SIGNOUT");
      }

      // update store and local storage
      try {
        store.commit("logoutUser");
      } catch (e) {
        // do nothing
      }

      // redirection
      if (forced) {
        // TODO - we need to have a state for force logout
        router.push({ path: "/error/unauthorized" });
      } else {
        router.push({ path: "/login/logout" }).catch((err) => {
          // Ignore navigation duplicated errors, as this function might be called form the logout screen itself
          if (err.name !== "NavigationDuplicated") throw err;
        });
      }

      // handle after logout
      resolve({
        showMessage: () => {
          store.commit("addToast", {
            variant: "success",
            message: "You were successfully logged out",
          });
        },
      });
    } catch (err) {
      reject(err);
    }
  });
}

function getInitialRouteBasedOnRole(store) {
  if (store.getters.isProspect) return "/prospect/bulk-upload";
  if (store.getters.isCheckupAdmin) return "/external-checkup-booking";
  return "/dashboard";
}

export function redirectAfterAuthn(user, router) {
  if (!router || !user) {
    console.warn("Unable to find user/router");
    return;
  }

  window.posthog.capture("employee_login", {
    employee_email: user?.email,
    show_onboarding_screen: user.insuranceStatus === UserInsuranceStatus.OnboardingPending,
    org_name: user?.org.name,
  });
  const routeQueryParam = router.currentRoute.query;
  if (routeQueryParam?.magicToken && routeQueryParam?.redirectTo) {
    const pageToRedirect = routeQueryParam?.redirectTo;
    if (!store?.state?.isCookieAndConsentPermissionGranted) {
      store.commit("setRedirectToUrl", pageToRedirect);
      router.push("/privacy-settings");
      return;
    }
    router.push(pageToRedirect);
    return;
  }
  let redirectionUrl = getInitialRouteBasedOnRole(store);
  if (!store?.state?.isCookieAndConsentPermissionGranted) {
    redirectionUrl = "/privacy-settings";
  }
  router.push(redirectionUrl);
}

/*
  Checks if token is already expired,
  that is the diff b/w current and expiry
  time is smaller than 20s
*/
export function isTokenExpired(expiryTimestamp) {
  const currentTimestamp = new Date();
  // TOD DO - we should watch the response the request
  // to refresh the token
  if ((expiryTimestamp.getTime() - currentTimestamp.getTime()) / 1000 < 20) {
    return true;
  }

  return false;
}

const tokenBeingRefreshed = {
  isTrue: false,
};

/*
 TO DO: This middleware is not being used any more
 as TokenRefreshLink handles the queueing
*/
export const handleTokenRefreshApiQueue = new ApolloLink((operation, forward) => {
  if (!tokenBeingRefreshed.isTrue) {
    // this holds on all the incoming request while token is being refreshed
    // and fires it once token is there
    return promiseToObservable(sleep(0)).flatMap(() => forward(operation));
  } else {
    return promiseToObservable(watcher(tokenBeingRefreshed)).flatMap(() => {
      const token = localStorage.getItem("token");
      if (token) {
        operation.setContext({
          headers: {
            authorization: `Bearer ${token}`,
          },
        });
      }

      forward(operation);
    });
  }
});

export const promiseToObservable = (promise) =>
  new Observable((subscriber) => {
    promise.then(
      (value) => {
        if (subscriber.closed) return;
        subscriber.next(value);
        subscriber.complete();
      },
      (err) => subscriber.error(err)
    );
    return subscriber;
  });

export async function watcher(cond) {
  return new Promise(async (resolve, reject) => {
    while (cond.isTrue) {
      await sleep(500);
    }

    resolve(true);
  });
}

const checkAndUpdateUserConsentStatus = async () => {
  const isCookieAndConsentGranted = (
    await apolloClient.query({
      query: gql`
        query VerifyConsents {
          verifyConsents {
            consentsVerified
          }
        }
      `,
    })
  )?.data?.verifyConsents?.consentsVerified;
  store.commit("setCookieAndConsentFlag", isCookieAndConsentGranted);
};
