<template lang="pug">
b-modal(
  :id="id",
  size="lg",
  centered,
  hide-header,
  hide-footer,
  no-close-on-backdrop,
  no-close-on-esc,
  body-class="p-0",
  content-class="rounded-3",
  dialog-class="checkup-dialog-modal",
  modal-class="checkup-discard-modal",
  v-model="showModal")
  .d-flex.align-items-center.d-sm-none.p-3
    n-icon(name="chevron-left", :size="1.5", @click="closeCheckupModal")
    .text-center.font-weight-semibold.font-lg.flex-grow-1 Discard Booking
  //- TODO: Refactor to remove two image tags
  img.d-none.d-sm-block.img-fluid.w-100.rounded-lg.rounded-bottom-none(:src="require('@/assets/images/checkup-discard-modal-bg.svg')")
  img.d-sm-none.img-fluid.w-100.rounded-lg.rounded-bottom-none(:src="require('@/assets/images/checkup-discard-modal-mobile-bg.svg')")
  .modal-icon.text-center
    .pt-3.bg-white.rounded-sides.shadow-sm
      n-icon.text-danger(name="warning", :size="2.25")
  .d-flex.flex-column.justify-content-between.checkup-content
    .modal-info.px-3.text-center.mt-2.mb-4.text-gray-900.font-weight-semibold
      .p-3.font-xl Discard Booking Progress
      .px-4
        | Exiting the booking process now will erase all the progress you've made in the
        | booking so far. Are you sure want to exit now ?
    .d-flex.flex-column.flex-sm-row.justify-content-center.mb-4
      n-button.mx-2.px-5.mb-2.mb-sm-0(
        variant="outline-dark",
        buttonText="Continue Booking",
        size="xs",
        @click="closeCheckupModal")
      n-button.mx-2.px-5(variant="danger", buttonText="Discard Booking", size="sm", @click="discardProgress")
</template>

<script>
import { mapState } from "vuex";
import NButton from "@/components/NovaButton.vue";
import NIcon from "@/components/NovaIcon";

export default {
  name: "CheckupDiscardModal",
  components: {
    NButton,
    NIcon,
  },
  props: {
    id: {
      type: String,
      default: "checkup-discard-modal",
    },
    redirectTo: {
      type: String,
      default: "/dashboard",
    },
  },
  data() {
    return {
      showModal: false,
    };
  },
  computed: {
    ...mapState(["user"]),
    isCheckupAdmin() {
      if (!this.user?.roles) return false;
      return this.user.roles.includes("checkup_admin");
    },
  },
  methods: {
    closeCheckupModal() {
      this.showModal = false;
      window.posthog.capture("checkup_discard_modal_continued_booking");
      this.$emit("close");
    },
    discardProgress() {
      this.showModal = false;
      window.posthog.capture("checkup_discard_modal_discarded_progress");
      if (this.isCheckupAdmin) {
        this.$router.push({ name: "external-checkup-booking" });
      } else {
        this.$router.push(this.redirectTo);
      }
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/_variables.scss";
@import "@/assets/styles/mixins/_breakpoints.scss";
.checkup-discard-modal {
  .modal-icon {
    & > div {
      position: relative;
      top: -2.25rem;
      height: 4.5rem;
      width: 4.5rem;
      margin: auto;
      border: 2px solid $gray-300;
    }
  }
  .modal-info {
    position: relative;
    top: -2rem;
  }
  @include media-breakpoint-down(xs) {
    .checkup-content {
      min-height: 50vh;
    }
    .checkup-dialog-modal {
      min-height: 100vh;
      margin: 0;
      & .modal-content {
        min-height: 100vh;
        border-radius: 0;
      }
    }
  }
  @include media-breakpoint-up(lg) {
    .modal-lg {
      max-width: 550px;
    }
  }
}
</style>
