<template lang="pug">
.new-version-strip.d-flex.justify-content-center.align-items-center.px-2.py-3.text-white(@click="loadNewUi", v-if="$store.state.showVersionStrip", :class="versionStripClasses")
  n-icon(v-if="!isSwLoading",name="star")
  .font-sm.font-weight-medium.px-2 {{title}}
  b-spinner(v-if="isSwLoading", :small="true")
  n-icon(v-else, name="chevron-right")
</template>

<script>
import { mapGetters } from "vuex";
import { deleteBundleCache } from "../utils/misc";
export default {
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["isTopNav", "isSwLoading", "showVersionStrip"]),
    isSidebarExpanded() {
      return document.documentElement.clientWidth > 992;
    },
    title() {
      if (this.isSwLoading) {
        return "Your newly upgraded Nova portal is loading!";
      }
      return "A new version of your Nova portal is available. Click here for a seamless experience!";
    },
    versionStripClasses() {
      return [
        this.isSwLoading ? "bg-gray-700" : "sw-loaded bg-malibu-900 cursor-pointer",
        {
          "version-strip-margin-open": !this.isTopNav && this.isSidebarExpanded,
          "version-strip-margin": !this.isTopNav && !this.isSidebarExpanded,
        },
      ];
    },
  },
  methods: {
    loadNewUi() {
      if (!this.isSwLoading) deleteBundleCache();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
@import "@/assets/styles/mixins/_breakpoints.scss";
.new-version-strip {
  position: sticky;
  top: 0;
  width: auto;
  z-index: 1000;
}
.version-strip-margin {
  margin-left: 4.5rem !important;
  margin-top: 0;
}
.version-strip-margin-open {
  margin-left: 13.5rem !important;
  transition: padding-left 0.3s ease;
}
.sw-loaded:hover {
  background: $malibu-800 !important;
}
@include media-breakpoint-down(md) {
  .version-strip-margin {
    margin-left: 0 !important;
  }
}
@include media-breakpoint-down(sm) {
  .version-strip-margin {
    margin-left: 0 !important;
    margin-top: 4.5rem;
  }
}
</style>
