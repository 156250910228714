<template lang="pug">
.d-flex-column.p-0
  n-spinner(:active="$apollo.loading")
  div
    .d-flex.justify-content-between.text-center
      .hb3.py-4.py-md-5.px-0 Help & Support
    .d-flex.flex-md-row.flex-column.justify-content-between.mb-2
      .col-sm-4.d-inline-flex.ml-0.pt-1.px-0
        i.icon-support-1.text-gray-600.mr-2.pb-1.mt-3
        .text-gray-600
          .mt-3 Support
        i.icon-chevron-right.align-top.mt-3
        .text-tiber.mw-100.vw-50.text-nowrap.mt-3 Your Tickets
      .mb-1.d-sm-inline-flex
        .mr-4.mb-4.mb-md-3.mt-md-3.mt-4.pt-2.font-weight-medium.font-sm.text-blue-500.cursor-pointer(
          @click="resetFilters") Clear Filter
        b-form-select.col-sm.mt-2.mr-2.font-weight-bold(
          v-model="selectedStatus",
          :options="statusOptions", @change="handleFilterChange('status')")
        b-form-select.col-sm.mt-2.mr-2.font-weight-bold(
          v-model="selectedTime",
          :options="timeOptions", @change="handleFilterChange('time')")
  div(v-if="cards.length === 0 && !isLoading")
    zero-state(:isFilterSet="isFilterSet")
  .row.p-2(v-else)
    .col-sm-12.col-md-6.col-lg-4.no-gutters.mt-3.px-2.w-100(
      v-for="card in cards",
      :key="card.id")
      b-card.mb-2.p-2.d-inline-flex.h-100.flex-row.border-gray-400(
        body-class="p-0")
        .pl-0
          .d-flex.flex-column.mt-2.mx-2
            .mt-2.text-gray-700
              .font-sm.pb-2 Ticket # {{ card.ticketNumber }} | {{ formatDate(card.createdTime) }}
            .font-weight-semibold.font-lg.mb-3.mr-1.text-truncate(
              v-b-tooltip.hover.top,
              :title="card.subject") {{ shortenTitle(card.subject) }}
            .d-flex-column
              .d-inline-flex.ml-0.pb-2.row
                span.font-sm.text-gray-700 Assigned To
                span.font-sm.text-gray-700 :
                .ml-1.font-weight-medium
                  span.font-sm.text-gray-900.align-top.ml-1(
                    v-if="card.assignee") {{ card.assignee.firstName }} {{ card.assignee.lastName }}
                  span.font-sm.text-gray-900.align-top.ml-1(v-else) Unassigned
              .d-flex.ml-0.pb-2.row
                .font-sm.text-gray-700 Status
                .ml-4.pl-2.font-sm.text-gray-700 :
                .ml-1.font-weight-medium
                  b-badge.font-sm.font-weight-medium.bg-malibu-100.text-gray-900.ml-2(
                    v-if="card.status === 'Open'",
                    pill,
                    variant="primary") Ticket Created
                  b-badge.font-sm.font-weight-medium.ml-2(
                    v-else,
                    pill,
                    variant="alabaster") Closed
            .w-100
              .col-12.p-1.rounded-sm.mr-2(
                :class="card.status === 'Open' ? 'bg-malibu-100' : 'bg-alabaster'")
                .font-weight-semibold.font-sm.pt-1.ml-0.mb-1 {{ conversationTitle(card.status).header }}
                p.font-sm.pb-1 {{ conversationTitle(card.status).subTitle }}
  n-pagination(
    v-model="currentPage",
    :total-rows="totalCount",
    :per-page="pageSize",
    align="center")
</template>

<script>
import moment from "moment";
import { mapState } from "vuex";
import resDefs from "./definitions";
import ZeroState from "./ZeroState";
import NButton from "@/components/NovaButton.vue";
import NChip from "@/components/NovaChip.vue";
export default {
  components: {
    NButton,
    NChip,
    ZeroState,
  },
  data() {
    const resName = "supportTickets";
    return {
      resName,
      timeRange: "",
      statusOptions: [
        { value: null, text: "All Tickets" },
        { value: "Open", text: "Active Tickets" },
        { value: "Closed", text: "Closed Tickets" },
      ],
      cards: [],
      pageSize: 6,
      currentPage: 1,
      totalCount: 0,
      selectedTime: "NONE",
      selectedStatus: null,
      isLoading: true,
      timeOptions: [
        {
          value: "NONE",
          text: "All Time",
        },
        {
          value: "TODAY",
          text: "Today",
        },
        {
          value: "WEEK",
          text: "This Week",
        },
        {
          value: "MONTH",
          text: "This Month",
        },
      ],
      resDef: resDefs[resName],
    };
  },
  computed: {
    ...mapState(["user"]),
    isFilterSet() {
      return !(this.selectedStatus === null && this.selectedTime === "NONE");
    },
  },
  watch: {
    selectedTime(newVal) {
      switch (newVal) {
        case "NONE":
          this.timeRange = "2020-02-02T07:51:43.589Z".concat(",").concat(moment.utc().toISOString());
          break;
        case "TODAY":
          this.timeRange = this.getTimeForPeriod("start", "day") + "," + this.getTimeForPeriod("end", "day");
          break;
        case "WEEK":
          this.timeRange = this.getTimeForPeriod("start", "isoWeek") + "," + this.getTimeForPeriod("end", "isoWeek");
          break;
        case "MONTH":
          this.timeRange = this.getTimeForPeriod("start", "month") + "," + this.getTimeForPeriod("end", "month");
          break;
      }
    },
  },
  async created() {
    await this.$apollo.addSmartQuery("cards", {
      query: this.resDef.listQuery,
      variables: () => ({
        limit: this.pageSize,
        offset: (this.currentPage - 1) * this.pageSize,
        filter: {
          param: "email",
          status: this.selectedStatus !== null ? this.selectedStatus : null,
          email: this.user.email,
          timeRange: this.timeRange,
        },
      }),
      update: (data) => {
        this.isLoading = false;
        this.totalCount = data[this.resName]?.totalCount;
        return this.resDef.transform(data);
      },
    });
  },
  methods: {
    resetFilters() {
      this.selectedStatus = null;
      this.selectedTime = "NONE";
      this.isLoading = false;
    },
    conversationTitle(status) {
      if (status === "Open") {
        return {
          header: "Hey There! 👋",
          subTitle:
            "Our team is reviewing your ticket. Meanwhile you can track and respond to your tickets via email. Use the ticket ID to search for the ticket related info.",
        };
      } else {
        return {
          header: "Helping you makes our day! ☘️",
          subTitle: "We hope we were able to resolve your concern and look forward to keep helping in the future.",
        };
      }
    },
    formatDate(date) {
      return moment(date).format("ddd, MMM D, hh:mmA");
    },
    shortenTitle: function (headingString) {
      if (headingString.length > 41) return headingString.substr(0, 38) + "...";
      return headingString;
    },
    getTimeForPeriod(type, period) {
      if (type === "start") return moment.utc().startOf(period).toISOString();
      else if (type === "end") return moment.utc().endOf(period).toISOString();
    },
    handleFilterChange(filterType) {
      window.posthog.capture(`${filterType}_filter_clicked`, {});
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
@import "@/assets/styles/mixins/_breakpoints.scss";
@include media-breakpoint-down(md) {
  .card {
    max-width: 28rem;
  }
}
.border-gray-400 {
  border-color: $gray-400;
}
</style>
