<template lang="pug">
  b-modal(
    :id="id",
    size="lg",
    centered,
    hide-header,
    hide-footer,
    no-close-on-backdrop,
    modal-class="versioning-modal",
    content-class="border-none rounded-lg",
    body-class="p-0",
    v-model="$store.state.majorVersionMismatch && isSwLoading")
    .modal-bg.mb-5.mb-sm-0.rounded-lg.rounded-bottom-none.bg-teal-800
      #hello-animation
    .modal-icon.text-center.d-none.d-sm-block
      .pt-3.bg-white.rounded-sides.shadow-sm
        n-icon(name="nova-basic", :size="2.25")
    .modal-info.px-3.text-center
      .text-gray-900.p-3.font-weight-semibold.font-xl
        span Say hi to the new Nova Benefits!&nbsp;
          img.pb-2(:src="require('@/assets/emojis/sparkles.svg')", width="32")
      .text-gray-800.px-3
        span
          | We’re proud to announce the new version of the Nova Benefits Portal
          | with enhanced functionality and attention to detail.
      b-card.mx-3.my-4.py-1.rounded-2.bg-malibu-100.shadow-sm(body-class="py-2")
        .font-weight-medium.my-0.text-gray-900
          | If the button does not work, please clear cache and reload the portal
          br
          | by ‘{{ refreshText }}’ to check out the new updates
          img.ml-2(:src="require('@/assets/emojis/tada.png')", width="16")
      .w-100
      b-card.mx-3.my-4.py-1.rounded-2.bg-red-100.shadow-sm(v-if="devText", body-class="py-2")
        .font-weight-medium.my-0.text-gray-900.px-4 For devs, {{ devText }}
      .w-100
        n-button.mx-3.w-50(
          v-if="showRefreshButton",
          variant="tiber",
          @click="deleteBundleCache",
          buttonText="Jump right in ->")
        .pt-4.pb-3(v-else)
          n-spinner(:active="true", background-color="rgba(255,255,255,0)")
</template>

<script>
import { mapGetters } from "vuex";
import utils, { deleteBundleCache } from "../utils";
import NButton from "@/components/NovaButton.vue";
import NModal from "@/components/NovaModal.vue";
export default {
  components: {
    NButton,
    NModal,
  },
  props: {
    id: {
      type: String,
      default: "nova-versioning-modal",
    },
  },
  computed: {
    ...mapGetters(["showVersioningModal", "isSwLoading"]),
    refreshText() {
      if (utils.isUsingSafari()) return "pressing Shift + Reload toolbar button";
      if (utils.mobileCheck() || utils.tabCheck()) return "pulling from top to reload";
      return "pressing Cmd/Ctrl + Shift + R";
    },
    devText() {
      if (process.env.NODE_ENV !== "production")
        return "rebuild portal and api containers and verify if both package versions are the same";
      return "";
    },
    showRefreshButton() {
      return !this.isSwLoading;
    },
    showVersionModal() {
      console.log("majorVersionMismatch", this.$store.state.majorVersionMismatch);
      if (this.$store.state.majorVersionMismatch) {
        return true;
      }
      return false;
    },
  },
  async mounted() {
    const loadLottieAndContainer = setInterval(() => {
      if (window?.lottie && document.getElementById("hello-animation")) {
        window.lottie.loadAnimation({
          container: document.getElementById("hello-animation"),
          renderer: "svg",
          loop: false,
          autoplay: true,
          animationData: require("@/assets/animations/hello_anim.json"),
        });
        clearInterval(loadLottieAndContainer);
      }
    }, 500);
    window.posthog.capture("version_modal_opened");

    const registration = await navigator?.serviceWorker?.getRegistration();
    if (!registration && this.isSwLoading) {
      this.$store.commit("setSwLoadingState", false);
    }
  },
  beforeDestroy() {
    window.lottie?.destroy();
  },
  methods: {
    deleteBundleCache() {
      deleteBundleCache();
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/_variables.scss";
@import "@/assets/styles/mixins/_breakpoints.scss";
.versioning-modal {
  .modal-bg {
    height: 41vw;
    @include media-breakpoint-up(sm) {
      height: 13rem;
    }
    @include media-breakpoint-up(lg) {
      height: 17rem;
    }
    position: relative;
    top: -0.5px;
    & > img {
      width: 100%;
      height: 100%;
    }
    & > div > svg {
      border-top-left-radius: $border-radius-lg;
      border-top-right-radius: $border-radius-lg;
    }
  }
  .modal-icon {
    & > div {
      position: relative;
      top: -2.25rem;
      height: 4.5rem;
      width: 4.5rem;
      margin: auto;
      border: 2px solid $gray-300;
    }
  }
  .modal-info {
    position: relative;
    top: -2rem;
  }
  @media (min-width: 992px) {
    .modal-lg {
      max-width: 650px;
    }
  }
}
.versioning-modal + .modal-backdrop {
  background-color: #666666bb !important;
  backdrop-filter: blur(4px);
}
</style>
