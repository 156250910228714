import gql from "graphql-tag";

export default {
  upsertTransaction: gql`
    mutation upsertTransaction(
      $insurerId: ID
      $accountId: ID
      $orgId: ID!
      $orgEntityId: ID
      $status: String
      $notes: String
      $transactionType: String!
      $transactionDate: String
      $amount: Float!
      $utr: String
      $paymentMethod: String
      $fileId: ID
    ) {
      upsertTransaction(
        input: {
          insurerId: $insurerId
          accountId: $accountId
          orgId: $orgId
          orgEntityId: $orgEntityId
          status: $status
          notes: $notes
          transactionType: $transactionType
          transactionDate: $transactionDate
          amount: $amount
          utr: $utr
          paymentMethod: $paymentMethod
          fileId: $fileId
        }
      ) {
        success
      }
    }
  `,
};
