import { getIdSelectorQuery } from "../queries";
import { FileAction } from "@/common/enums";

export default {
  filterSchema: {
    fields: [
      {
        label: "Organization",
        model: "org",
        type: "id-selector",
        queryName: "orgs",
        gqlQuery: getIdSelectorQuery("Org"),
        placeholder: "Organization filter (none)",
        showSelected: false,
      },
      {
        label: "Action",
        model: "action",
        type: "inline-select",
        options: [
          { value: FileAction.OnboardOrg, label: "Onboard Org" },
          { value: FileAction.ImportECards, label: "Import ECards" },
          { value: FileAction.FetchECards, label: "Fetch ECards" },
          { value: FileAction.ClaimsBulkUpdate, label: "Bulk Update Claims" },
          { value: FileAction.BulkUpload, label: "Bulk Upload" },
          { value: FileAction.BulkDelete, label: "Bulk Delete" },
          { value: FileAction.PremiumDetails, label: "Premium Details" },
          { value: FileAction.SetUpOrgAndPolicies, label: "Process Closing Slip" },
          { value: FileAction.benefitsBulkAddition, label: "Benefits Bulk Addition" },
          { value: FileAction.PolicyDataCheck, label: "Policy Data Check" },
          { value: FileAction.EmailTemplate, label: "Email Template" },
          { value: FileAction.OrgDocUpload, label: "Org Doc Upload" },
        ],
        showSelected: false,
      },
    ],
  },
};
