export const TransactionStatus = {
  PENDING: "pending",
  COMPLETED: "completed",
  FAILED: "failed",
  INVALID: "invalid",
};

export const TransactionType = {
  CREDIT: "credit",
  DEBIT: "debit",
};
