<template lang="pug">
div.py-7
  div
    .image-container 
      img(:src="require(`@/assets/images/${this.emptyState.image}.svg`)")
  div
    .empty-states-msg1.font-weight-semibold.font-xl {{this.emptyState.text}}
    .empty-states-msg2.font-lg.font-weight-medium.mx-auto  {{this.emptyState.subtext}}
</template>

<script>
export default {
  name: "EmptyStatesEndorsements",
  props: {
    tableType: {
      type: String,
      required: true,
    },
    hasNoSearchResults: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      emptyState: this.getEmptyState(),
    };
  },
  methods: {
    getEmptyState() {
      if (this.hasNoSearchResults) {
        return {
          text: "No search results found 🙂",
          subtext: "Try adjusting your search input.",
          image: "search",
        };
      }
      switch (this.tableType) {
        case "enrollment-pending":
          return {
            text: "Woohoo!",
            subtext: "You don’t have any endorsements with missing data 🙌",
            image: "success",
          };
        case "unapproved":
          return {
            text: "Nothing Pending!",
            subtext:
              "There are no endorsements pending to be approved. Please check for missing data, if everything is complete you are all set! 🙌",
            image: "hourglassSmall",
          };
        case "approved":
          return {
            text: "No endorsements yet!",
            subtext: "There are no approved endorsement added here. Please check endorsements, pending to be approved.",
            image: "hourglassSmall",
          };
        case "org-ok":
          return {
            text: "No endorsements yet!",
            subtext: "There are no approved endorsement added here. Please check endorsements, pending to be approved.",
            image: "hourglassSmall",
          };
        case "nova-ok":
          return {
            text: "Endorsements sent to Insurer",
            subtext: "All endorsements submitted to the insurer will appear here, until they are approved.",
            image: "message",
          };
        case "provider-ok":
          return {
            text: "Endorsements approved by Insurer",
            subtext:
              "All the endorsements approved by the insurer will appear here, until all documents are uploaded and E-cards are mapped.",
            image: "success",
          };
        case "done":
          return {
            text: "Completed Endorsements",
            subtext: "All completed Endorsements will appear here.",
            image: "success",
          };
        case "rejected":
          return {
            text: "Rejected Endorsements.",
            subtext: "All rejected endorsements will appear here.",
            image: "alert",
          };
        case "previous-endorsements":
          return {
            text: "Woohoo!",
            subtext: "You don’t have any previous endorsements.",
            image: "success",
          };
        case "empty-dependent":
          return {
            text: "Nothing to show here!",
            subtext: "No dependent are added yet.",
            image: "empty-box",
          };
        default:
          return {
            text: "Hooray!",
            subtext: "You have approved all endorsements for this batch!",
            image: "success",
          };
      }
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/_variables.scss";
@import "@/assets/styles/mixins/_breakpoints.scss";
.empty-states-msg1 {
  font-family: "Hergon Grotesk";
  font-style: normal;
  text-align: center;
  color: $gray-900;
  margin-bottom: 0.5rem;
  margin-top: 0.8rem;
}
.empty-states-msg2 {
  font-family: "Inter";
  font-style: normal;
  text-align: center;
  letter-spacing: 0.012em;
  color: $gray-700;
  max-width: 50%;
  @include media-breakpoint-down(sm) {
    max-width: 90%;
  }
}
.image-container {
  text-align: center;
}
</style>
