<template lang="pug">
.checkup-provider-selection
  n-spinner(
    :active="isLoading",
    spinner="ring",
    size="50",
    duration="1")
  .d-none.d-md-flex.align-items-center.justify-content-between.mb-3
    .d-flex.align-items-center
      n-icon(name="location", :size="1")
      .text-gray-900.font-lg.font-weight-semibold.ml-2 Select Provider &amp; Slot
    .d-flex.align-self-start.justify-content-center.align-items-center.rounded-4.font-xs.font-weight-semibold.px-2.py-1(
      v-if="sampleCollectionConfig?.sampleCollectionTitle", :class="sampleCollectionConfig.class")
      n-icon.mr-1(:name="sampleCollectionConfig.iconName", :size="0.7", :variant="sampleCollectionConfig.variant")
      | {{ sampleCollectionConfig?.sampleCollectionTitle }}

  .d-flex.flex-column.mb-2(v-if="providers?.length")
    provider-details-card(v-for="(provider, index) in providers.slice(0, numberOfTopProviders)",
      :id="index",
      :provider="provider",
      :isHomeCollection="isHomeCollection")
    b-collapse(id="collapse-providers", v-model="providersExpanded")
      provider-details-card(v-for="(provider, index) in providers.slice(numberOfTopProviders)",
        :id="numberOfTopProviders + index",
        :provider="provider",
        :isHomeCollection="isHomeCollection")
    .d-flex.justify-content-center.mx-3.mt-3.mb-4
      n-button.w-30.py-2.mb-3.border.checkup-show-provider-button(
        v-if="providers.length > numberOfTopProviders",
        v-b-toggle.collapse-providers,
        :buttonText="providersExpanded ? 'Show less providers' : `${providers.length - numberOfTopProviders} more providers`",
        :rightImageIcon="providersExpanded ? 'chevron-up' : 'chevron-down'",
        variant="light-outline",)
    .d-flex.py-3.px-4.fasting-card.flex-column.shadow-sm(v-if="isFastingRequired")
      .font-lg.font-weight-semibold Test Instructions
      .border-dotted-bottom.mt-3
      .row.my-2.w-100(v-for="instruction in fastingInstructions")
        .col-12.col-md-5.d-flex.align-items-center.my-1
          n-icon.text-teal-700.mr-2(name="check-circle", :size="1")
          .font-md.font-weight-semibold {{ instruction.title }}
        .col-12.col-md-7.d-flex.font-sm.text-gray-700.font-weight-medium.my-1 {{ instruction.description }}
  empty-state.border-1-dashed.rounded-lg(v-else,
    :imagePath="require(`@/assets/images/magnifying-glass.svg`)",
    :message1="emptyState.message1",
    :message2="emptyState.message2",
    :supportMessage="emptyState.supportMessage")
  checkup-footer.d-block(variant="success",
    :buttonText="confirmButtonText",
    :isButtonDisabled="isLoading || isButtonDisabled",
    @goBack="goBack"
    @continueCheckupFlow="goToNextStep")

</template>

<script>
import _ from "lodash";
import { mapGetters } from "vuex";
import resDefs from "../../admin/definitions";
import * as checkupUtils from "../checkupUtils";
import ProviderDetailsCard from "./ProviderDetailsCard.vue";
import CheckupFooter from "./CheckupFooter.vue";
import NButton from "@/components/NovaButton.vue";
import EmptyState from "@/components/Cards/EmptyStates/EmptyState.vue";
import utils from "@/utils";
export default {
  name: "ProviderSelection",
  components: {
    ProviderDetailsCard,
    NButton,
    CheckupFooter,
    EmptyState,
  },
  props: {
    isHomeCollection: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const fastingInstructions = [
      {
        title: "Fasting Required",
        description: "Please ensure you are on complete fasting for 10 -12 hours prior to the test.",
      },
      {
        title: "Fasting Precautions",
        description:
          "During fasting time do not take any kind of medication, alcohol, cigarettes, tobacco or any other liquid (excpet water) in the morning.",
      },
    ];
    return {
      emptyState: {
        message1: "Oops, all nearby providers are unserviceable",
        message2:
          "This could be due to server errors or other technical difficulties. Please try booking again at a later time or if the issue persists, reach out to us",
        supportMessage: "here for support",
      },
      providers: [],
      numberOfTopProviders: 3,
      providersExpanded: false,
      fastingInstructions,
      isLoading: true,
    };
  },
  computed: {
    ...mapGetters(["ahcProspectState", "isCheckupAdmin"]),
    selectedGroupPackage() {
      return this.ahcProspectState.selectedGroupPackage || {};
    },
    checkupPackages() {
      return this.selectedGroupPackage?.checkupPackages || [];
    },
    sampleCollectionConfig() {
      let sampleCollectionConfig = checkupUtils.getSampleCollectionTitleAndIcon(
        this.checkupPackages[0]?.sampleCollectionType || ""
      );
      if (this.checkupPackages?.length > 1) {
        sampleCollectionConfig = { ...sampleCollectionConfig, icon: "grouped" };
      }
      return sampleCollectionConfig;
    },
    isMobile() {
      return utils.mobileCheck();
    },
    selectedAddress() {
      return this.ahcProspectState?.selectedAddress || {};
    },
    selectedProvider() {
      return this.ahcProspectState?.selectedProvider || {};
    },
    selectedDate() {
      return this.ahcProspectState?.selectedDateTimeSlot?.date || "";
    },
    selectedTime() {
      return this.ahcProspectState?.selectedDateTimeSlot?.time?.startTime || "";
    },
    selectedMembers() {
      return this.ahcProspectState?.selectedMembers || [];
    },
    displayAddress() {
      return `${this.selectedAddress?.address_line_one ? `${this.selectedAddress.address_line_one}, ` : ""}${
        this.selectedAddress?.address
      }`;
    },
    formattedAddress() {
      let shortenedAddress = this.displayAddress?.substring(0, 55);
      if (this.displayAddress?.length > 55) {
        shortenedAddress += "...";
      }
      return shortenedAddress;
    },
    isFastingRequired() {
      return this.providers.some((provider) => provider?.meta?.fastingAlert !== "");
    },
    isButtonDisabled() {
      return !this.selectedDate || !this.selectedTime;
    },
    confirmButtonText() {
      return `Confirm${!this.isMobile ? " Provider" : ""}`;
    },
  },
  watch: {
    selectedProvider: {
      deep: true,
      handler(newProvider) {
        for (const provider of this.providers.slice(this.numberOfTopProviders)) {
          if (provider?.id === newProvider.id) {
            this.providersExpanded = true;
          }
        }
      },
    },
  },
  async created() {
    const packageIds = this.selectedMembers?.map(({ checkupPackage }) => checkupPackage.id);
    const { latitude, longitude, pincode } = this.selectedAddress;
    const { data } = await this.$apollo.query({
      query: resDefs.checkupProviders.listQuery,
      variables: {
        filter: {
          orgId: this.$route.params.bookingUser?.org_id || null,
          geolocation: { latitude, longitude },
          pincode,
          packageIds: [...new Set(packageIds)],
        },
        sortBy: {
          field: "rating",
          direction: "DESC",
        },
      },
    });
    this.isLoading = false;
    for (const edge of data.checkupProviders.edges) {
      this.providers.push(edge?.node);
    }
    this.providers = data.checkupProviders.edges.map(({ node }) => node);
  },
  methods: {
    changeAddress() {
      window.posthog.capture("checkup_booking_form_change_location");
      this.$store.commit("updateAhcProspectStateMeta", {
        isAddressSelected: false,
        selectedProvider: {},
        selectedDateTimeSlot: {},
        selectedAddress: {
          ...this.selectedAddress,
          address_line_one: null,
        },
      });
      this.$emit("sync-ahc-prospect-state", { skipProspectEvent: true });
    },
    goToNextStep() {
      this.$emit("goToNextStep");
    },
    goBack() {
      this.$emit("backHandler");
    },
    isNotEmpty(val) {
      return !_.isEmpty(val);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
.selected-provider {
  max-width: 45vw;
}

.letter-spacing {
  letter-spacing: 0.1em;
}

.border-dotted-bottom {
  border-bottom: 0.125rem dashed $gray-300;
}

.fasting-card {
  border: 1px solid $gray-200;
  border-radius: 0.5rem;
}
.checkup-show-provider-button {
  padding-left: 0.75rem !important;
  padding-right: 0.75rem !important;
}
</style>
