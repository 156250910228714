import gql from "graphql-tag";
import { getFilteredListQuery, getResourceListQuery, getSingleQuery } from "../queries";

export default {
  gqlType: "UserChangeBatch",
  singleQuery: getSingleQuery("UserChangeBatch"),
  listQuery: getFilteredListQuery("UserChangeBatch"),
  resourceListQuery: getResourceListQuery("UserChangeBatch"),
  markNoEndorsementFromHR: gql`
    mutation markNoEndorsementFromHR($batchId: ID!, $orgAdmins: [JSONObject]!, $cxEntities: [JSONObject]!) {
      markNoEndorsementFromHR(input: { batchId: $batchId, orgAdmins: $orgAdmins, cxEntities: $cxEntities }) {
        success
      }
    }
  `,
  sendPremiumEstimateMails: gql`
    mutation sendPremiumEstimateMails($batchId: ID!, $orgAdmins: [JSONObject]!, $cxEntities: [JSONObject]!) {
      sendPremiumEstimateMails(input: { batchId: $batchId, orgAdmins: $orgAdmins, cxEntities: $cxEntities }) {
        success
      }
    }
  `,
  updateDelayStatusConfig: gql`
    mutation updateDelayStatusConfig($batchId: ID!, $isDelayed: Boolean!, $reason: String, $remark: String) {
      updateDelayStatusConfig(input: { batchId: $batchId, isDelayed: $isDelayed, reason: $reason, remark: $remark }) {
        success
      }
    }
  `,
  upsertEndorsementBatchTrx: gql`
    mutation upsertEndorsementBatchTrx($orgId: ID!, $insurerWiseDeposit: JSONObject!, $batchId: ID!) {
      upsertEndorsementBatchTrx(input: { orgId: $orgId, insurerWiseDeposit: $insurerWiseDeposit, batchId: $batchId }) {
        success
      }
    }
  `,
  getEndorsementBatchDocuments: gql`
    query getEndorsementBatchDocuments($batchId: ID!) {
      getEndorsementBatchDocuments(batchId: $batchId)
    }
  `,
  getEndorsementStageStatuses: gql`
    query getEndorsementStageStatuses($batchId: ID!) {
      getEndorsementStageStatuses(batchId: $batchId)
    }
  `,
};
