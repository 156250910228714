<template lang="pug">
row(no-body, :class="{'b-card': currentPage !== 'reports', 'p-3': currentPage !== 'reports'}")
  .row.p-0
    .col-md-3.col-sm-12.border-right.p-0.pl-3.d-none.d-md-block.pt-3
      h5.font-hc.text-gray-600 Edit Details
      profile-nav
    .reset-password-banner.col-md-8.ml-4.d-flex.justify-content-between.p-4(v-if="showResetPasswordButton")
      .text-container
        h4.heading.font-weight-medium.hb4 Password
        h6.sub-text You have not yet created a password for your account!
      .cta-container
        n-button.ml-md-4(
          size="large",
          variant="dark",
          type="button",
          @click="sendResetPasswordMail",
          buttonText="Create New Password")
    template(v-else)
      .col
        .row
          .col-md-12.nova-info-banner.p-0
            profile-info-banner(
              v-if="currentPage === 'details' && showProfileInfoBanner"
              title="Request to change the profile details is in process",
              subtext="For more information, please contact your HR.",
              :user="user",
              :userChanges="userChanges",
            )
        .row
          .col-md-9.offset-md-1.col-sm-12.pt-3(v-if="!['cookie-preference', 'reports', 'data-deletion'].includes(currentPage)")
            b-form(@submit.preventDefault="currentPage === 'security' ? changePassword($event) : updateProfile($event)")
              .mb-4.d-flex.flex-column.flex-md-row.justify-conent-center.align-items-center(v-if="currentPage === 'details'")
                n-avatar.d-md-none.mb-4.mb-md-0(
                  :size="8",
                  :name="profileModel.name",
                  :image="profileModel.photoFile?profileModel.photoFile.url:null",
                  :loading="loadingProfile")
                n-avatar.d-none.d-md-block(
                  :size="4",
                  :name="profileModel.name",
                  :image="profileModel.photoFile ? profileModel.photoFile.url : null",
                  :loading="loadingProfile")
                .d-flex.justify-content-between.align-items-center
                  file-upload.btn.btn-outline-primary.ml-md-4(
                    extensions="gif,jpg,jpeg,png,webp",
                    accept="image/png,image/gif,image/jpeg,image/webp",
                    :directory="false",
                    :size="1024 * 1024 * 10",
                    :thread="3",
                    :drop="true",
                    :drop-directory="true",
                    @input-file="handleProfileUpload")
                    n-icon.align-sub.mr-2(name="renew")
                    span.font-weight-medium Upload new
                  n-button.btn.text-danger.ml-2(imageIcon="delete",size="large", variant="outline-secondary",buttonText="Remove", @click.prevent="removeProfilePicture()")
              .text-gray-800.font-weight-medium.mb-4(v-if="currentPage === 'security'") Reset Password
              vue-form-generator(
                :schema="dynamicSchema",
                :model="currentPage === 'security' ? pwdModel : profileModel",
                :options="formOptions",
                :ref="currentPage")
              n-password-policy(v-if="currentPage === 'security'", :password="pwdModel.newPassword", :passwordChangeSubmitted="passwordChangeSubmitted")
              n-button.mt-5(
                variant="secondary",
                :disabled="currentPage === 'details' && getFeatureFlags.DISABLE_ADDITION_AND_UPDATION_FOR_USER_AND_DEPENDENTS",
                :buttonText="currentPage === 'details' ? 'Request Update' : 'Save Changes'",
                type="submit",
                data-cy="user-profile-submit-btn"
              )
          //- Cookie Preference Section
          .col-md-9.offset-md-1.col-sm-12.pt-3(v-else-if="currentPage === 'cookie-preference'")
            .d-flex.flex-column
              span Choose which types of cookies you'd like to allow on our website. Please note that opting out of certain cookies may impact your browsing experience.
              .cookie-card-wrapper.mt-3
                cookie-card(
                  v-for="(cookie, index) in cookiePreference"
                  :key="index"
                  :label="cookie.title"
                  :description="cookie.description"
                  :is-accepted="cookie.userConsentStatus"
                  :is-required="cookie.isMandatory"
                  :isToggleDisabled="cookie.isDisabled"
                  @update:isAccepted="updateCookieAcceptance(index, $event)"
                  )
              .col-md-4.pl-0             
                n-button.mt-5(
                buttonText="Save Changes",
                variant="secondary",
                @click="saveCookiePreference",
                :disabled="isCookiePrefSaveDisabled",
              )

          .col-md-9.offset-md-1.col-sm-12.pt-3(v-else-if="currentPage === 'data-deletion'")
            .d-flex.flex-column 
              .font-lg.text-gray-900 
                | Please 
                a.font-lg(href="https://www.novabenefits.com/privacy#:~:text=Account%20Deletion%20Process", target="_blank") click here 
                | to raise a request for data deletion.

          // Reports section
          .col-md-9.offset-md-1.col-sm-12.pt-3(v-else-if="currentPage === 'reports'")
            assessment-reports

          special-modal(
            id="otp-verification-modal",
            title="otp-verification-modal",
            logoIcon="messaging",
            iconColor="gray-600",
            :enableCross="true",
            centered,
            :close="hideOtpModal",
            customContentStyle="pt-0 otp-special-modal")
            template(v-slot:modal-content)
              .overflow-hidden.mt-5
                otp-modal(:profileModel="profileModel", :user="user", @otp-verified="onOtpVerified")
            template(v-slot:buttons-area)
              .mb-1
          special-modal(
            id="update-profile-confirmation-modal",
            headerIcon="icon",
            :iconColor="'grey-900'",
            logoIcon="error",
            centered,
            :heroImage="require('@/assets/images/confirm-profile-update-hero-image.svg')"
          )
            template(v-slot:title)
              h3.font-weight-bold Before you request changes...
            template(v-slot:modal-content)
              .text-center.text-gray-700.text-md.font-weight-medium.w-75.mx-auto Requested changes are subject to your HR’s approval or rejection. Any changes here might impact your policy coverage based on insurer’s policy guideline.
            template(v-slot:buttons-area)
              .d-flex.w-100.justify-content-around
                n-button.ml-3(
                  buttonText="Cancel",
                  variant="outline-dark",
                  size="lg",
                  color="gray-200",
                  @click="closeModal",
                  style="width:40%;",
                )
                n-button.mr-3(
                  buttonText="Confirm ->",
                  variant="dark",
                  size="lg",
                  @click="updateUserWithPolicies($event)",
                  style="width:40%;",
                  data-cy="user-profile-confirm-modal-btn",
                )
</template>

<script>
import gql from "graphql-tag";
import { mapGetters, mapState } from "vuex";
import FileUpload from "vue-upload-component";
import { isEqual, cloneDeep } from "lodash-es";
import resDefs from "../definitions";
import adminResDefs from "../../admin/definitions";
import OtpModal from "../getStarted/OtpModal.vue";
import { MY_INFO } from "../../../../common/definitions/auth";
import { UserChangeStatus } from "../../../../common/enums/userChangeStatus.enum";
import CookieCard from "../../components/CookieCard.vue";
import { ComplianceConsentSource, UserComplianceConsentStatus } from "../../../../common/enums/complianceConsent.enum";
import ProfileNav from "./components/ProfileNav.vue";
import ProfileInfoBanner from "./components/ProfileInfoBanner.vue";
import AssessmentReports from "./components/Reports.vue";
import { changeUserPassword } from "@/common/queriesAndMutations/auth";
import NIcon from "@/components/NovaIcon.vue";
import NButton from "@/components/NovaButton.vue";
import NAvatar from "@/components/Avatar.vue";
import NPasswordPolicy from "@/components/PasswordPolicy.vue";
import SpecialModal from "@/components/SpecialModal.vue";
import utils, { isVfgErrorPresent, validateOtpVerification, validateIndianMobileNumbersForVfg } from "@/utils";
export default {
  components: {
    NButton,
    NAvatar,
    ProfileNav,
    FileUpload,
    NIcon,
    NPasswordPolicy,
    SpecialModal,
    ProfileInfoBanner,
    OtpModal,
    CookieCard,
    AssessmentReports,
  },
  data() {
    const resDef = resDefs.profile;
    return {
      resDefs,
      otpModalVisible: true,
      formOptions: {
        validateAfterLoad: false,
        validateAfterChanged: false,
        validateAsync: true,
      },
      loadingProfile: false,
      prevProfileModel: {},
      profileModel: {},
      pwdModel: {},
      showResetPasswordButtonFlag: false,
      passwordChangeSubmitted: false,
      isOtpVerified: false,
      cookiePreference: null,
    };
  },
  computed: {
    ...mapState(["user"]),
    ...mapGetters(["getFeatureFlags"]),
    currentPage() {
      const currentPage = this.$route.path;
      return currentPage.split("/user/profile/")?.[1];
    },
    dynamicSchema() {
      // This computed property generates a vue form schema that is tailored to the user's current page
      const resDef = resDefs.profile;
      switch (this.currentPage) {
        case "details":
          if (this.getFeatureFlags.DISABLE_ADDITION_AND_UPDATION_FOR_USER_AND_DEPENDENTS) {
            resDef.profileSchema.fields = resDef.profileSchema.fields.map((field) => {
              return {
                ...field,
                disabled: true,
              };
            });

            return resDef.profileSchema;
          }
          return resDef.profileSchema;
        case "about":
          return resDef.aboutSchema;
        case "security":
          return resDef.pwdSchema;
        case "contact-details":
          // we added this code to facilitate the dynamic inclusion of validators
          resDef.contactSchema.fields = resDef.contactSchema.fields.map((field) => {
            if (field.model === "meta") {
              field.schema.fields = field.schema.fields.map((childField) => {
                if (childField.model === "contactNumber") {
                  childField.validator = [];
                  if (this.isOtpEnabled) {
                    childField.validator.push(validateOtpVerification);
                  }
                  childField.validator.push(validateIndianMobileNumbersForVfg);
                }
                return childField;
              });
            }
            return field;
          });
          return resDef.contactSchema;
        default:
          return {};
      }
    },
    showResetPasswordButton() {
      return this.showResetPasswordButtonFlag && this.currentPage === "security";
    },
    isOtpEnabled() {
      return !!this.getFeatureFlags?.ENABLE_OTP;
    },
    showHrUpdateConfirmationModal() {
      const confirmationFields = ["name", "dob", "gender"];
      return confirmationFields.some((field) => this.prevProfileModel[field] !== this.profileModel[field]);
    },
    showProfileInfoBanner() {
      return this.userChanges?.some(
        (change) => change.type === "update" && change.status !== UserChangeStatus.REJECTED
      );
    },
    isCookiePrefSaveDisabled() {
      return this.$store.getters.isImposter;
    },
  },
  watch: {
    "$route.path"() {
      if (this.$route.path.includes("/security")) {
        this.$apollo.queries.profileQuery.refetch();
      }
      if (this.$route.path.includes("/cookie-preference")) {
        this.$apollo.queries.userConsentQuery.refetch();
      }
    },
  },
  created() {
    this.$apollo.addSmartQuery("profileModel", {
      query: MY_INFO,
      update(data) {
        const user = { ...data.me };
        this.isOtpVerified = user.meta?.isNumberVerified;
        this.prevProfileModel = this.profileModel.__typename
          ? { ...cloneDeep(this.profileModel) }
          : { ...cloneDeep(user) };
        return user;
      },
    });
    // This query has been added for reflecting current state of
    // user password status in security tab as our state isn't reactive
    this.$apollo.addSmartQuery("profileQuery", {
      query: MY_INFO,
      update(data) {
        this.showResetPasswordButtonFlag = data.me?.meta?.isPasswordSetupRequired;
        if (data.me) {
          this.$store.commit("updateUser", data.me);
        }
        return null;
      },
      fetchPolicy: "no-cache",
    });

    // fetch cookie preference
    this.fetchCookiePreference();
  },
  apollo: {
    userChanges: {
      query: gql`
        query MyUserChanges {
          me {
            id
            userChanges {
              type
            }
          }
        }
      `,
      update(data) {
        return data?.me?.userChanges;
      },
    },
  },
  mounted() {
    this.$store.commit("updateSectionHeader", "Your Profile");
    this.$store.commit("updateNavHeader", {
      name: "Basic Details",
      back: true,
    });
    if (!this.isOtpVerified && this.isOtpEnabled && this.$refs[this.currentPage]) {
      setTimeout(async () => {
        await this.$refs[this.currentPage].validate();
      }, 1000);
    }
  },
  methods: {
    hideOtpModal() {
      this.$bvModal.hide("otp-verification-modal");
      this.$emit("cancelled");
    },
    uploadImage() {
      this.$refs.profileUpload.click();
    },
    async handleProfileUpload(newFile, oldFile) {
      if (newFile && !oldFile) {
        this.loadingProfile = true;
        const MAX_WIDTH = 320;
        const MAX_HEIGHT = 180;
        const MIME_TYPE = "image/jpeg";
        const QUALITY = 0.7;
        try {
          const file = await utils.getCompressedImage(newFile.file, MAX_HEIGHT, MAX_WIDTH, MIME_TYPE, QUALITY);
          let id = null;
          if (this.profileModel.photoFile) {
            id = this.profileModel.photoFile.id;
          }
          const result = await this.$apollo.mutate({
            mutation: adminResDefs.files.upsertMutation,
            variables: {
              file,
              id,
              action: "profilePhotoUpload",
              name: this.$store.state.user.name + " profile photo",
            },
          });
          this.profileModel.photoFile = result.data.upsertFile.file;
          const userData = {
            id: this.profileModel.id,
            orgId: this.profileModel.orgId,
            photoFileId: this.profileModel.photoFile.id,
          };
          await this.updateUser(userData);
        } catch (err) {
          console.log(err);
          this.$store.commit("addToast", {
            variant: "danger",
            message: "Sorry, Something went wrong while uploading profile picture.",
          });
        }
        this.loadingProfile = false;
      }
    },
    transformProfileModel(e) {
      if (e) {
        e.preventDefault();
      }
      const { photoFile, userChanges, ...restUser } = this.profileModel;
      return restUser;
    },
    hasPolicies() {
      return this.user.benefits.some((benefit) => benefit.node.isPolicy);
    },
    async updateUserWithPolicies(e) {
      await this.updateUser(this.transformProfileModel(e));
      this.closeModal();
    },
    async updateProfile(e) {
      e?.preventDefault();
      if (this.prevProfileModel.meta.contactNumber !== this.profileModel.meta.contactNumber) {
        this.profileModel.meta.isNumberVerified = false;
      }
      await this.$refs[this.currentPage].validate();

      if (isVfgErrorPresent(this.$refs[this.currentPage].errors)) {
        if (this.shouldShowOtpModal()) {
          this.$bvModal.show("otp-verification-modal");
        } else {
          this.$store.commit("clearToasts");
          this.$store.commit("addToast", {
            variant: "danger",
            message: "Please fill valid details.",
          });
        }
        return;
      }
      if (this.showHrUpdateConfirmationModal && (this.hasPolicies() || this.showProfileInfoBanner)) {
        this.$bvModal.show("update-profile-confirmation-modal");
      } else {
        await this.updateUser(this.transformProfileModel());
      }
    },
    closeModal() {
      this.$bvModal.hide("update-profile-confirmation-modal");
    },
    async removeProfilePicture() {
      const userData = {
        id: this.profileModel.id,
        photoFileId: null,
      };
      await this.updateUser(userData);
      this.profileModel.photoFile = null;
    },
    async updateUser(userInput) {
      this.$store.commit("clearToasts");
      const changedUserInfo = {};
      for (const key in userInput) {
        if (!isEqual(userInput[key], this.user[key])) {
          changedUserInfo[key] = userInput[key];
        }
      }
      delete changedUserInfo.orgId;
      try {
        const res = await this.$apollo.mutate({
          mutation: resDefs.userChanges.updateUserOrDependentInfo,
          variables: {
            userId: userInput.id,
            orgId: userInput.orgId,
            changedUserInfo,
          },
        });
        await this.$apollo.queries.profileQuery.refetch();
        await this.$apollo.queries.profileModel.refetch();
        this.$store.commit("addToast", {
          variant: "success",
          message: "Successfully updated the details",
        });
      } catch (err) {
        console.log(err);
      }
    },
    async sendResetPasswordMail() {
      try {
        const result = await this.$apollo.mutate({
          mutation: gql`
            mutation SendResetPasswordMail($email: String!) {
              sendResetPasswordEmail(input: { email: $email }) {
                emailSent
              }
            }
          `,
          variables: {
            email: this.user.email,
          },
        });
        const emailSent = result.data.sendResetPasswordEmail.emailSent;
        if (emailSent) {
          this.$store.commit("addToast", {
            variant: "success",
            message: "Password reset link has been sent to your registered Mail ID",
          });
        } else {
          this.$store.commit("addToast", {
            variant: "danger",
            message: "Sorry, Something went wrong, please try again",
          });
        }
      } catch (err) {
        console.log(err);
      }
    },
    validatePasswords() {
      if (!this.pwdModel.currentPassword) {
        return [false, "Old password is required when updating the password"];
      }
      if (!this.pwdModel.newPassword || !this.pwdModel.confirmNewPassword) {
        return [false, "Please enter new password and confirmation"];
      }
      if (this.pwdModel.newPassword !== this.pwdModel.confirmNewPassword) {
        return [false, "New password and confirmation don't match"];
      }
      return [true];
    },
    async changePassword(e) {
      e.preventDefault();
      await this.$refs[this.currentPage].validate();
      if (isVfgErrorPresent(this.$refs[this.currentPage].errors)) {
        this.passwordChangeSubmitted = true;
        this.$store.commit("addToast", {
          variant: "danger",
          message: "Password does not satisfy given conditions.",
        });
        return;
      }
      const [valid, errMessage] = this.validatePasswords();
      if (!valid) {
        this.$store.commit("clearToasts");
        this.$store.commit("addToast", {
          variant: "danger",
          message: errMessage,
        });
        return;
      }
      const passwordData = {
        id: this.profileModel.id,
        ...this.pwdModel,
      };
      delete passwordData.confirmNewPassword;
      this.$store.commit("clearToasts");
      try {
        const res = await changeUserPassword({
          ...passwordData,
        });
        if (res?.data?.changePassword?.passwordUpdated) {
          this.$store.commit("addToast", {
            variant: "success",
            message: "Password successfully updated",
          });
          return;
        }
      } catch (err) {
        console.log(err);
      }
    },
    shouldShowOtpModal() {
      if (!this.isOtpEnabled) return false;
      if (this.isOtpVerified && this.prevProfileModel.meta.contactNumber === this.profileModel.meta.contactNumber)
        return false;
      if (!utils.validateIndianMobileNumbers(this.profileModel.meta.contactNumber)) return false;
      return true;
    },
    async onOtpVerified() {
      this.isOtpVerified = true;
      this.$bvModal.hide("otp-verification-modal");
      await this.$apollo.queries.profileModel.refetch();
      await this.updateProfile();
    },
    async fetchCookiePreference() {
      this.$apollo.addSmartQuery("userConsentQuery", {
        query: this.resDefs.compliance.userConsents,
        update(result) {
          this.cookiePreference = result?.getUserConsents?.edges
            .map((item) => {
              const isMandatory = item.node.isMandatory;
              const status = item.node.userConsentStatus;

              return {
                ...item.node,
                isDisabled: isMandatory,
                userConsentStatus: status === null ? isMandatory : status !== "rejected",
              };
            })
            .sort((a, b) => {
              return b.isMandatory - a.isMandatory;
            });
        },
        fetchPolicy: "no-cache",
      });
    },
    async saveCookiePreference() {
      const allComplianceConsents = [];
      this.cookiePreference
        .filter((cookie) => !cookie.isMandatory)
        .map((cookie) => {
          if (cookie.userConsentStatus === true || cookie.userConsentStatus === "accepted") {
            allComplianceConsents.push({
              complianceConsentId: cookie.id,
              status: UserComplianceConsentStatus.ACCEPTED,
            });
          } else {
            allComplianceConsents.push({
              complianceConsentId: cookie.id,
              status: UserComplianceConsentStatus.REJECTED,
            });
          }
        });

      const result = await this.$apollo.mutate({
        mutation: this.resDefs.compliance.upsertUserConsent,
        variables: {
          timestamp: new Date(),
          source: ComplianceConsentSource.PROFILE_PAGE,
          consents: allComplianceConsents,
        },
      });

      if (result.data.upsertUserConsents.success) {
        this.$store.commit("addToast", {
          variant: "success",
          message: "Successfully updated cookie preference.",
        });
      }
    },
    updateCookieAcceptance(index, userConsentStatus) {
      this.$set(this.cookiePreference, index, {
        ...this.cookiePreference[index],
        userConsentStatus,
      });
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/_variables.scss";

.reset-password-banner {
  height: fit-content;
  background-color: $gray-100;
  align-items: center;
  .sub-text {
    color: $gray-700;
  }
}
#otp-verification-modal___BV_modal_content_ {
  max-width: 480px;
  max-height: 478px;
}
.otp-special-modal {
  padding-bottom: 0rem !important;
}
</style>
