import gql from "graphql-tag";
import { novaOrderFragment } from "../payments/definitions";
import { novaProductFragment } from "../admin/fragments/novaProduct";

export const checkupTestComponentFragment = gql`
  fragment CheckupTestComponent on CheckupTestComponent {
    id
    partnerComponentId
    partnerComponentName
    partnerComponentGroup
    meta
  }
`;

export const checkupPackageTestComponentEdgeFragment = gql`
  fragment CheckupPackageTestComponentEdgeEdge on CheckupPackageTestComponentEdgeEdge {
    node {
      ...CheckupTestComponent
    }
  }
  ${checkupTestComponentFragment}
`;

export const checkupPackageFragment = gql`
  fragment CheckupPackage on CheckupPackage {
    id
    checkupTestComponents {
      ...CheckupPackageTestComponentEdgeEdge
    }
    partner
    service
    partnerPackageId
    sampleCollectionType
    isDependentPackage
    packageGroupName
    linearPackage
    org {
      id
      name
      meta
      orgEntities {
        id
      }
    }
    orgEntity {
      id
      name
    }
    benefit {
      id
      name
    }
    updatedByUser {
      name
      org {
        name
      }
    }
    meta
    novaProduct {
      ...NovaProduct
    }
    packageFreeFor
    updatedPackageFreeFor
    packageAvailableFor
    createdAt
    updatedAt
    homeOptionForCheckupPackage {
      id
      checkupTestComponents {
        ...CheckupPackageTestComponentEdgeEdge
      }
      partner
      service
      partnerPackageId
      isDependentPackage
      meta
    }
  }
  ${checkupPackageTestComponentEdgeFragment}
  ${novaProductFragment}
`;

export const checkupLabResultFragment = gql`
  fragment CheckupLabResult on CheckupLabResult {
    id
    results
    meta
    checkupTestComponent {
      ...CheckupTestComponent
    }
  }
  ${checkupTestComponentFragment}
`;

export const checkupAppointmentFragment = gql`
  fragment CheckupAppointment on CheckupAppointment {
    id
    isExtraMember
    user {
      id
      name
      gender
      dob
    }
    dependent {
      id
      name
      gender
      relation
      dob
    }
    meta
    partnerAppointmentId
    status
    scheduledAt
    verifiedReportLink
    checkupPackage {
      id
      novaProduct {
        ...NovaProduct
      }
      checkupTestComponents {
        ...CheckupPackageTestComponentEdgeEdge
      }
      partner
      service
      meta
      sampleCollectionType
    }
    checkupLabResults {
      ...CheckupLabResult
    }
  }
  ${checkupPackageTestComponentEdgeFragment}
  ${checkupLabResultFragment}
  ${novaProductFragment}
`;

export const checkupBookingAppointmentFragment = gql`
  fragment CheckupBookingAppointmentEdge on CheckupBookingAppointmentEdge {
    node {
      ...CheckupAppointment
    }
  }
  ${checkupAppointmentFragment}
`;

export const checkupBookingFragment = gql`
  fragment CheckupBooking on CheckupBooking {
    id
    meta
    status
    partnerBookingId
    partnerProviderId
    partnerProviderMeta
    novaOrder {
      ...NovaOrder
    }
    user {
      id
      name
      gender
      dob
    }
    checkupAppointments {
      ...CheckupBookingAppointmentEdge
    }
    novaOrderItems {
      quantity
      node {
        ...NovaProduct
      }
    }
  }
  ${checkupBookingAppointmentFragment}
  ${novaOrderFragment}
  ${novaProductFragment}
`;

export const rescheduleCheckupBookingMutation = gql`
  mutation rescheduleCheckupBooking($id: ID!, $userId: ID!, $rescheduledAt: String!) {
    rescheduleCheckupBooking(input: { id: $id, userId: $userId, rescheduledAt: $rescheduledAt }) {
      success
    }
  }
`;

export const cancelBookingMutation = gql`
  mutation cancelCheckupBooking($id: ID, $userId: ID, $meta: JSONObject) {
    cancelCheckupBooking(input: { id: $id, userId: $userId, meta: $meta }) {
      checkupBooking {
        ...CheckupBooking
      }
    }
  }
  ${checkupBookingFragment}
`;

export const rescheduleCheckupAppointmentMutation = gql`
  mutation rescheduleCheckupAppointment($id: ID!, $userId: ID!, $rescheduledAt: String!) {
    rescheduleCheckupAppointment(input: { id: $id, userId: $userId, rescheduledAt: $rescheduledAt }) {
      success
    }
  }
`;

export const cancelAppointmentMutation = gql`
  mutation cancelCheckupAppointment($id: ID, $userId: ID, $meta: JSONObject) {
    cancelCheckupAppointment(input: { id: $id, userId: $userId, meta: $meta }) {
      checkupAppointment {
        ...CheckupAppointment
      }
    }
  }
  ${checkupAppointmentFragment}
`;

export const gqlResourceConfig = {
  CheckupPackage: {
    plural: "checkupPackages",
    fragment: checkupPackageFragment,
    fragmentName: "CheckupPackage",
  },
  CheckupAppointment: {
    plural: "checkupAppointments",
    fragment: checkupAppointmentFragment,
    fragmentName: "CheckupAppointment",
  },
  CheckupBooking: {
    plural: "checkupBookings",
    fragment: checkupBookingFragment,
    fragmentName: "CheckupBooking",
  },
};

export const getSingleQuery = (gqlType) => {
  const { fragment, fragmentName } = gqlResourceConfig[gqlType];
  const query = gql`query ${gqlType}Single($id: ID!){
    node(id: $id){ ...${fragmentName} }
  }
  ${fragment}`;
  return query;
};

export const getListQuery = (gqlType) => {
  const { plural, fragment, fragmentName } = gqlResourceConfig[gqlType];
  const query = gql`
  query ${gqlType}List(
      $query: String
      $offset: Int
      $limit: Int
      $filter: JSONObject
    ) {
      ${plural}(
        query: $query
        offset: $offset
        limit: $limit
        filter: $filter
      ) {
      edges {
        node { ...${fragmentName} }
      }
      totalCount
    }
  }
  ${fragment}`;
  return query;
};

export default {
  checkupPackages: {
    singleQuery: getSingleQuery("CheckupPackage"),
    listQuery: getListQuery("CheckupPackage"),
  },
  checkupAppointments: {
    singleQuery: getSingleQuery("CheckupAppointment"),
    listQuery: getListQuery("CheckupAppointment"),
    mutation: gql`
      mutation upsertCheckupAppointment(
        $id: ID
        $checkupPackageId: ID
        $checkupBookingId: ID
        $partnerAppointmentId: String
        $userId: ID
        $dependentId: ID
        $voucherFileId: ID
        $status: String
        $verifiedReportLink: String
        $meta: JSONObject
      ) {
        upsertCheckupAppointment(
          input: {
            id: $id
            checkupPackageId: $checkupPackageId
            checkupBookingId: $checkupBookingId
            partnerAppointmentId: $partnerAppointmentId
            userId: $userId
            dependentId: $dependentId
            voucherFileId: $voucherFileId
            status: $status
            verifiedReportLink: $verifiedReportLink
            meta: $meta
          }
        ) {
          checkupAppointment {
            ...CheckupAppointment
          }
        }
      }
      ${checkupAppointmentFragment}
    `,
    rescheduleCheckupAppointment: rescheduleCheckupAppointmentMutation,
    cancelAppointment: cancelAppointmentMutation,
  },
  checkupBookings: {
    singleQuery: getSingleQuery("CheckupBooking"),
    listQuery: getListQuery("CheckupBooking"),
    mutation: gql`
      mutation upsertCheckupBooking(
        $id: ID
        $userId: ID
        $benefitId: ID
        $membersBookingData: [JSONObject]
        $partnerBookingId: String
        $meta: JSONObject
        $scheduledAt: String
        $partnerProviderId: String
        $partnerProviderMeta: JSONObject
        $billingAddress: String
        $stateOfResidence: String
      ) {
        upsertCheckupBooking(
          input: {
            id: $id
            userId: $userId
            benefitId: $benefitId
            membersBookingData: $membersBookingData
            partnerBookingId: $partnerBookingId
            meta: $meta
            scheduledAt: $scheduledAt
            partnerProviderId: $partnerProviderId
            partnerProviderMeta: $partnerProviderMeta
            billingAddress: $billingAddress
            stateOfResidence: $stateOfResidence
          }
        ) {
          checkupBooking {
            ...CheckupBooking
          }
        }
      }
      ${checkupBookingFragment}
    `,
    rescheduleCheckupBooking: rescheduleCheckupBookingMutation,
    cancelBooking: cancelBookingMutation,
  },
};

export const GET_CHECKUP_VERIFIED_REPORTS = gql`
  query getCheckupVerifiedReports($appointmentId: String!) {
    getCheckupVerifiedReports(appointmentId: $appointmentId)
  }
`;
export const GET_CHECKUP_VOUCHER = gql`
  query getCheckupVoucher($appointmentId: String!) {
    getCheckupVoucher(appointmentId: $appointmentId)
  }
`;

export const getExternalCheckupBookingEnabledOrgs = gql`
  query {
    getExternalCheckupBookingEnabledOrgs
  }
`;

export const getExternalCheckupBookingUsers = gql`
  query getExternalCheckupBookingUsers($orgId: String!) {
    getExternalCheckupBookingUsers(orgId: $orgId)
  }
`;

export const GET_CHECKUP_BOOKING_USER_BY_EMAIL = gql`
  query getCheckupBookingUserByEmail($email: String!, $orgId: String!) {
    getCheckupBookingUserByEmail(email: $email, orgId: $orgId)
  }
`;

export const getProductDetailsByCheckupPackage = gql`
  query getProductDetailsByCheckupPackage($checkupPackageId: String!) {
    getProductDetailsByCheckupPackage(checkupPackageId: $checkupPackageId)
  }
`;
