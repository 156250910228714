<template lang="pug">
.mt-0
  .text-gray-600.font-sm.font-weight-medium Query details
  .text-gray-900.font-md.font-weight-medium.mt-1 {{  query.queryText  }}
  .text-gray-600.font-sm.font-weight-medium.mt-3 Query letter
  .cursor-pointer(@click="preview(query.queryLetterLink)")
    .d-flex.justify-content-center.rounded.bg-gray-500.py-2.position-relative.col-2.mt-1
      .d-flex.justify-content-center.position-absolute.border.rounded-circle.cancel-icon.bg-white
      n-icon(name="eye", variant="gray-900")
    .text-tiber.font-xs.font-weight-light.mt-1 QueryLetter.pdf
</template>

<script>
import NIcon from "@/components/NovaIcon.vue";

export default {
  name: "QueryDetails",
  components: {
    NIcon,
  },
  props: {
    query: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    preview(url) {
      window.open(url, "_blank");
    },
  },
};
</script>
