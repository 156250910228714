<template lang="pug">
b-modal(
  id="onboarding-success-modal",
  size="md",
  centered,
  hide-header,
  hide-footer,
  no-close-on-backdrop,
  no-close-on-esc,
  body-class="p-0",
  content-class="rounded-3",
  dialog-class="onboarding-success-dialog-modal",
  modal-class="onboarding-success-modal")
  img.d-none.d-sm-block.img-fluid.w-100.rounded-lg.rounded-bottom-none(:src="require('@/assets/images/onboarding-success-modal.svg')")
  img.d-sm-none.img-fluid.w-100.rounded-lg.rounded-bottom-none(:src="require('@/assets/images/onboarding-success-modal.svg')")
  .modal-icon.text-center
    .pt-3.bg-white.rounded-sides.shadow-sm
      n-icon.text-danger(name="charge", :size="2.25")
  .d-flex.flex-column.justify-content-between.onboarding-success-content
    .modal-info.px-3.text-center.mt-2.mb-4.text-gray-900.font-weight-semibold
      .p-3.font-xl Information Saved ✨
      .px-4
        | Your information is saved and sound. 
        | Redirecting you to your Nova portal...
      img.mt-5(:src="require('@/assets/images/onboarding-loading.svg')")
</template>

<script>
import NModal from "@/components/NovaModal.vue";

export default {
  name: "OnboardingSuccessModal",
  components: {
    NModal,
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/_variables.scss";
@import "@/assets/styles/mixins/_breakpoints.scss";

.onboarding-success-modal {
  .modal-icon {
    & > div {
      position: relative;
      top: -2.25rem;
      height: 4.5rem;
      width: 4.5rem;
      margin: auto;
      border: 2px solid $gray-300;
    }
  }
  .modal-info {
    position: relative;
    top: -2rem;
  }
  @include media-breakpoint-down(xs) {
    .onboarding-success-content {
      min-height: 50vh;
    }
    .onboarding-success-dialog-modal {
      min-height: 100vh;
      margin: 0;
      & .modal-content {
        min-height: 100vh;
        border-radius: 0;
      }
    }
  }
  @include media-breakpoint-up(lg) {
    .modal-lg {
      max-width: 550px;
    }
  }
}
</style>
