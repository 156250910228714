import { render, staticRenderFns } from "./NovaPopoverChip.vue?vue&type=template&id=bf7533aa&scoped=true&lang=pug"
import script from "./NovaPopoverChip.vue?vue&type=script&lang=js"
export * from "./NovaPopoverChip.vue?vue&type=script&lang=js"
import style0 from "./NovaPopoverChip.vue?vue&type=style&index=0&id=bf7533aa&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bf7533aa",
  null
  
)

export default component.exports