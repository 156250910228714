<template lang="pug">
div(v-touch:swipe.left="swipeLeftHandler", v-touch:swipe.right="swipeRightHandler")
  .bg-white
    .container.border-bottom-gray-300
      b-nav.d-flex
        b-nav-item.flex-fill.flex-md-grow-0(
          :to="{ name: 'team_fitness' }",
          :class="$route.path==='/fitness/team'?'nav-active':''",
          link-classes="ph-fitness-nav-to-team-page"
        )
          .nav-item-text
            span.ml-2 Team
        b-nav-item.flex-fill.flex-md-grow-0(
          :to="{ name: 'my_fitness' }",
          :class="$route.path==='/fitness/self'?'nav-active':''",
          link-classes="ph-fitness-nav-to-self-page"
        )
          .nav-item-text
            span.ml-2 My Fitness
        b-nav-item.flex-fill.flex-md-grow-0(
          to="/fitness/rewards",
          :class="$route.path==='/fitness/rewards'?'nav-active':''"
          link-classes="ph-fitness-nav-to-rewards-page"
        )
          .nav-item-text
            span.ml-2 Rewards
  .container.container-fitness.p-0
    transition(:name="transitionName" mode="out-in")
      router-view(:key="$route.path", @refetchFitnessData="refetchFitnessData")
  </template>

<script>
import { mapGetters } from "vuex";
import gql from "graphql-tag";
import { userWithChallengesFragment } from "../admin/fragments";
export default {
  beforeRouteLeave(to, from, next) {
    this.$store.commit("toggleNavBottomBorder", true);
    next();
  },
  data() {
    return {
      transitionName: "fade",
    };
  },
  computed: {
    ...mapGetters(["isLoggedIn"]),
  },
  beforeMount() {
    this.$store.commit("toggleNavBottomBorder", false);
    this.$Progress.start();
  },
  created() {
    this.$options.ROUTES = ["/fitness/team", "/fitness/self", "/fitness/rewards"];
    if (this.isLoggedIn) {
      this.$apollo.addSmartQuery("fitnessProfile", {
        query: gql`
          query MyFitnessProfile {
            myFitnessData {
              ...UserWithChallenges
            }
          }
          ${userWithChallengesFragment}
        `,
        update(data) {
          const user = { ...data.myFitnessData };
          if (data.myFitnessData.org) {
            user.orgId = data.myFitnessData.org.id;
          }
          this.$store.commit("updateUserFitnessData", user);
          this.$Progress.finish();
          return user;
        },
      });
    }
  },
  updated() {
    if (this.transitionName !== "fade") {
      // Reset the page transition to fade for portal and nav item clicks
      setTimeout(() => {
        this.transitionName = "fade";
      }, 1000);
    }
  },
  methods: {
    swipeLeftHandler() {
      const currentRouteIndex = this.$options.ROUTES.indexOf(this.$route.path);
      if (currentRouteIndex < this.$options.ROUTES.length - 1) {
        this.transitionName = "slide-right";
        this.$router.push(this.$options.ROUTES[currentRouteIndex + 1]);
      }
    },
    swipeRightHandler() {
      const currentRouteIndex = this.$options.ROUTES.indexOf(this.$route.path);
      if (currentRouteIndex > 0) {
        this.transitionName = "slide-left";
        this.$router.push(this.$options.ROUTES[currentRouteIndex - 1]);
      }
    },
    refetchFitnessData() {
      this.$Progress.start();
      this.$apollo.queries.fitnessProfile.refetch();
    },
  },
};
</script>

<style scoped>
.active > .home-nav-text {
  color: white !important;
}
</style>

<style lang="scss">
@import "@/assets/styles/_variables.scss";
@import "@/assets/styles/mixins/_breakpoints.scss";
.challenge-progressbar-height {
  height: 0.375rem;
}
.challenge-details-back-button {
  position: absolute;
  left: 1rem;
  top: 1rem;
}

/* Horizontal separator */
.border-gray-300 {
  border-color: $gray-300;
}

/* Vertical avatar connector */
.connector {
  background: $gray-300;
  z-index: 0;
  width: 2px;
  height: 100%;
  position: absolute;
  left: 1rem;
}
.connector-first {
  height: 200%;
}
.bottom-mask {
  background: $portal-bg;
  @include media-breakpoint-up(lg) {
    background: $white;
  }
}
.border-bottom-gray-300 {
  border-bottom: 1px solid $gray-300;
}
.nav-item-text {
  padding: 0.6rem 0;
}
.challenges-zero-state-card {
  border-top-color: #695c4d !important;
}

/* end of feed notif styling */
.container-fitness {
  .hr-text {
    display: flex;
    flex-direction: row;
  }
  .hr-text:before,
  .hr-text:after {
    content: "";
    flex: 1 1;
    border-bottom: 1px solid;
    margin: auto;
    border-color: $gray-400;
  }
  .hr-text:before {
    margin-right: 15px;
  }
  .hr-text:after {
    margin-left: 15px;
  }
}

.slide-left-enter-active,
.slide-left-leave-active {
  transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out;
}

.slide-left-enter,
.slide-left-leave-to {
  transform: translateX(-100%);
  opacity: 0;
}

.slide-left-leave-active {
  transform: translateX(100%);
}

.slide-right-enter-active,
.slide-right-leave-active {
  transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out;
}

.slide-right-enter,
.slide-right-leave-to {
  transform: translateX(100%);
  opacity: 0;
}

.slide-right-leave-active {
  transform: translateX(-100%);
}
</style>
