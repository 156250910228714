<template lang="pug">
.nova-entity-switcher
  b-dropdown.dropdown(id="profile-switcher", dropup, offset="0", boundary="viewport", :no-caret="true", :class="isSidebarExpanded ? '' : 'collapsed-entity-switcher'")
    template.border(slot="button-content", v-if="!isSidebarExpanded")
      n-avatar.cursor-pointer(:size="2",
        :name="selectedOrgOrEntityName",
        tooltipPlacement="topleft")
      .chevron-sort-icon.border
        n-icon(name="switcher", :size="0.8")
    template(slot="button-content", v-else)
      .text-white.text-left.font-weight-medium.font-xs.mb-2 Switch Roles
      .d-flex.align-items-center.justify-content-between
        n-avatar.mr-2(:image="user.photoFile?.url",
          :name="selectedOrgOrEntityName",
          :size="1.25")
        span.font-weight-medium.font-md.text-truncate {{ selectedOrgOrEntityName }}
        n-icon.ml-auto(name="switcher")
    b-dropdown-header.bg-stone.rounded-top.pt-2
      .text-gray-700.font-weight-medium.font-xs.pb-0 {{ header }}
    b-dropdown-item.bg-stone(v-if="user.org", @click="selectOrg")
      .d-flex.align-items-center.dropdown-item-content
        n-avatar.mr-2(:image="null" :name="user.org.name")
        .div
          .font-sm.mr-2 {{ user.org.name }}
          span.font-xs.text-gray-800 Parent Org
        n-icon.ml-auto(v-if="!selectedOrgEntity",name="check", variant="teal-800")
    b-dropdown-item.bg-stone(v-for="orgEntity in user.org.orgEntities", @click="updateSelectedOrgEntity(orgEntity)")
      .d-flex.align-items-center.dropdown-item-content
        n-avatar.mr-2(:image="null", :name="orgEntity.name")
        .div
          .font-sm {{ orgEntity.name }}
        n-icon.ml-auto(v-if="selectedOrgEntity && orgEntity.id === selectedOrgEntity.id", name="check", variant="teal-800")
    b-dropdown-header
      .font-xs.font-weight-medium Switch to personal profile
    b-dropdown-item(to="/dashboard")
      .d-flex.align-items-center.justify-content-between
        n-avatar.mr-2(:image="user.photoFile?.url", :name="user.name")
        span.font-weight-medium.font-md {{ user.name }}
        n-icon.ml-auto(name="chevron-right")
</template>

<script>
import { mapGetters } from "vuex";
import NAvatar from "@/components/Avatar.vue";
import NButton from "@/components/NovaButton.vue";
export default {
  components: {
    NAvatar,
    NButton,
  },
  props: {
    isSidebarExpanded: {
      type: Boolean,
    },
  },
  data() {
    return {
      selectedOrgOrEntityName: "",
    };
  },
  computed: {
    ...mapGetters(["selectedOrgEntity", "user"]),
    header() {
      if (this.user.org.orgEntities && this.user.org.orgEntities.length > 0) {
        return "Switch Organization";
      }
      return "Organization";
    },
  },
  watch: {
    isSidebarExpanded: function (isSidebarExpanded) {
      var entitySwitcher = document.getElementById("profile-switcher__BV_toggle_");
      if (!isSidebarExpanded) {
        entitySwitcher.style.setProperty("--dropdown-background-color", "none");
      } else {
        entitySwitcher.style.setProperty("--dropdown-background-color", "rgba(255, 255, 255, 0.15)");
      }
    },
  },
  created() {
    this.selectedOrgOrEntityName = this.user.org.name;
  },

  methods: {
    updateSelectedOrgEntity(selectedOrgEntity) {
      this.$store.commit("setSelectedOrgEntity", selectedOrgEntity);
      this.$store.commit("addToast", {
        variant: "success",
        message: "You are now viewing data of org " + selectedOrgEntity.name,
      });
      this.selectedOrgOrEntityName = selectedOrgEntity.name;
    },
    selectOrg() {
      this.$store.commit("setSelectedOrgEntity", null);
      this.$store.commit("addToast", {
        variant: "success",
        message: "You are now viewing data of org " + this.user.org.name,
      });
      this.selectedOrgOrEntityName = this.user.org.name;
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/_variables.scss";
@import "@/assets/styles/mixins/_breakpoints.scss";
#profile-switcher__BV_toggle_ {
  background-color: var(--dropdown-background-color, rgba(255, 255, 255, 0.15));
  width: 10rem;
  border: none;
}
#profile-switcher__BV_toggle_:hover {
  border: 10px;
}
#profile-switcher .dropdown-item {
  height: auto;
  padding-inline: 1rem;
  padding-block: 0.75rem;
  /* padding: 0; */
}
.dropdown-menu {
  margin-bottom: 10px;
  top: 10px;
}
#profile-switcher > ul {
  margin-bottom: 1rem;
  border: none;
  box-shadow: $box-shadow-lg;
}
#profile-switcher > ul > li.dropdown-item.bg-stone.rounded-top > a {
  padding: 0;
}
.dropdown-header {
  padding-bottom: 0 !important;
}
.chevron-sort-icon {
  position: absolute;
  background: $white;
  margin-left: 1.3rem;
  margin-top: -0.95rem;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.chevron-sort-icon i {
  color: $gray-900;
}
.collapsed-entity-switcher {
  width: 100%;
}
</style>
