<template lang="pug">
div
  .d-flex.justify-content-between
    .text-gray-900.font-lg(v-for="section in sections", :key="section.routeName")
      router-link(:to="{ name: 'hr_onboarding_section_details', params: {section: section.name} }") {{ section.title }}
  b-card.container
    transition(name="fade", mode="out-in")
      router-view(:key="$route.path")
</template>

<script>
export default {
  name: "HrOnboarding",
  components: {},
  data() {
    return {
      sections: [
        {
          title: "Org Setup",
          name: "org",
        },
        {
          title: "Policies setup",
          name: "policy",
        },
        {
          title: "Benefits setup",
          name: "benefit",
        },
      ],
    };
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/_variables.scss";
</style>
