<template lang="pug">
div
  b-container.mt-2
    b-row(v-for="(section, index) in sections", :key="index")
      b-col(sm="12", class="p-0")
        div.my-2
          .hb4.m-0 {{ section.title }}
          b-card.mt-3.w-100.p-0(no-body)
            b-card-body.w-100.p-0.border
              b-list-group(flush)
                n-chip.ml-4.mt-3(
                  v-if="index === mostRecentIndex",
                  chipIcon="home-1",
                  textColor="purple-600",
                  :class="`bg-purple-100`",
                  pill,
                ) Most Recent
                b-list-group-item(
                  class="d-flex align-items-center p-3 cursor-pointer hover",
                  @click="navigateToReport(section.reports[0].id)",
                )
                  .d-flex.flex-column.align-items-baseline.ml-2.flex-grow-1
                    span.font-weight-semibold.text-gray-900.text-capitalize Report Summary: {{ section.reports[0].score }}
                    span.font-weight-medium.text-gray-700 Test taken: {{ getFormattedDate(section.reports[0].takenOn) }}
                  i.icon-chevron-right.ml-auto
          b-collapse(:id="'collapse-' + index", v-model="collapses[index]", class="w-100")
            b-card.mt-3.w-100.p-0(no-body v-for="(report, idx) in section.reports.slice(1)", :key="idx")
              b-card-body.w-100.p-0.border
                b-list-group-item(
                  class="d-flex align-items-center p-3 cursor-pointer hover",
                  @click="navigateToReport(report.id)",
                )
                  .d-flex.flex-column.align-items-baseline.ml-2.flex-grow-1
                    span.font-weight-semibold.text-gray-900.text-capitalize Report Summary: {{ report.score }}
                    span.font-weight-medium.text-gray-700 Test taken: {{ getFormattedDate(report.takenOn) }}
                  i.icon-chevron-right.ml-auto
          div.d-flex.justify-content-end.p-3
            a(
              href="#",
              @click.prevent="toggleCollapse(index)",
              :class="{'text-primary': true, 'font-weight-semibold': true}",
            ) 
              span.font-sm {{ collapses[index] ? 'Hide' : 'View All' }}&nbsp;&nbsp;
              i.font-sm.fa(:class="collapses[index] ? 'fa-solid fa-chevron-up' : 'fa-solid fa-chevron-down'")

</template>

<script>
import NChip from "../../../../../components/NovaChip.vue";
import resDef from "../../definitions";
import moment from "moment";

export default {
  name: "MobileAssessmentReports",
  components: {
    NChip,
  },
  data() {
    return {
      reports: [],
      collapses: [],
      mostRecentIndex: -1,
    };
  },
  computed: {
    sections() {
      const section = {};
      this.reports.forEach((item) => {
        if (item.reports.length > 0) {
          if (!section[item.title]) {
            section[item.title] = {
              title: item.title,
              reports: [],
            };
          }
          section[item.title].reports.push(...item.reports);
        }
      });

      const sortedSections = Object.values(section).sort((a, b) => {
        const dateA = moment(a.reports[0].takenOn);
        const dateB = moment(b.reports[0].takenOn);
        return dateB - dateA;
      });
      this.mostRecentIndex = Object.values(section).indexOf(sortedSections[0]);

      return sortedSections;
    },
  },
  async mounted() {
    await this.fetchReports();
    this.collapses = new Array(this.sections.length).fill(false);
  },
  methods: {
    async fetchReports() {
      try {
        const allReports = (
          await this.$apollo.query({
            query: resDef.assessments.getAllReport,
          })
        )?.data?.reports?.edges?.map((item) => item.node);

        this.reports = allReports.map((section) => ({
          title: section.title,
          reports: section.reports.map((report) => ({
            id: report.id,
            score: report.scoreName,
            takenOn: report.updatedAt,
          })),
        }));
      } catch (error) {
        //  Handled by apollo
      }
    },
    toggleCollapse(index) {
      this.$set(this.collapses, index, !this.collapses[index]);
    },
    navigateToReport(id) {
      this.$router.push({
        name: "assessmentReport",
        params: {
          reportId: id,
        },
      });
    },
    getFormattedDate(date) {
      return moment(date).fromNow();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
@import "@/assets/styles/_typography.scss";
.border {
  border-radius: $border-radius;
}
</style>
