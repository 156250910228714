// import gql from "graphql-tag";
import { getListQuery, getSingleQuery } from "../queries";
import ngageUserDetailsMutations from "../mutations/ngageUserDetails";

// TODO: flesh out this entity. Commented details not needed for v1
export default {
  gqlType: "ngageUserDetail",
  fields: ["name", "type", "updatedAt"],
  // listQuery: getListQuery("NgageUserDetail"),
  // singleQuery: getSingleQuery("NgageUserDetail"),
  ...ngageUserDetailsMutations,
  validate: (n) => {
    return false;
  },
  transform: (n, write = false) => {
    return n;
  },
};
