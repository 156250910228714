export const ClaimDocument = {
  CLAIM_FORM_PART_A: "Claim form: Part A",
  CLAIM_FORM_PART_B: "Claim form: Part B",
  CKYC_FORM: "CKYC Form",
  AADHAAR_PRIMARY: "Aadhaar Card of Primary Insured",
  AADHAAR_PATIENT: "Aadhaar Card of the Patient",
  PAN_PRIMARY: "PAN Card of Primary Insured",
  CANCELLED_CHEQUE: "Cancelled cheque with name of primary insured",
  DISCHARGE_SUMMARY: "Detailed discharge summary",
  INVESTIGATION_REPORT: "Investigation reports",
  HOSPITAL_BILL: "Main hospital bill",
  PAID_RECEIPT: "Receipt of the amount paid",
  MED_INV_PRESCRIPTION: "Prescriptions for medicines & investigations",
  PHARMACY_BILL: "Pharmacy bills for medicines",
  INVESTIGATION_BILL: "Investigation bills",
  E_CARD: "E-Card",
  IMPLANTS_INVOICE: "Implant Bill",
  IMPLANTS_LABEL: "Implant Details",
  CONSULTATION_PAPERS: "Doctor Consultation Papers",
  OTHER_CLAIM_FORMS: "Other Claim Forms",
  OPD_BILL: "OPD Bill",
  OTHER_DOCUMENTS: "Other Documents",
  QUERY_DOCUMENTS: "Query Documents",
  UPLOAD_ATTACHMENTS: "Upload Attachments",
  SETTLEMENT_LETTER: "Settlement Letter",
  REJECTION_LETTER: "Rejection Letter",
};

export const ExcludedClaimDocs = [
  ClaimDocument.UPLOAD_ATTACHMENTS,
  ClaimDocument.SETTLEMENT_LETTER,
  ClaimDocument.REJECTION_LETTER,
];
