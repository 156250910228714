import gql from "graphql-tag";

export const checkupProviderFragment = gql`
  fragment CheckupProvider on CheckupProvider {
    id
    name
    partnerId
    latitude
    longitude
    address
    meta
    providerSlots
    createdAt
    updatedAt
  }
`;
