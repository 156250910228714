<template lang="pug">
.d-inline-flex.align-items-center.bg-mustard-100.text-mustard-600.p-2.btn-sm(
  v-if="status === QueryStatus.OPEN")
  i.icon-clock.mr-1
  span.font-sm Pending
.d-inline-flex.align-items-center.bg-teal-100.text-teal-900.p-2.btn-sm(
  v-else)
  i.icon-check-circle.mr-1
  span.font-sm Responded
</template>

<script>
import { QueryStatus } from "@/common/enums/claims/queryStatus";

export default {
  name: "QueryStatusPill",
  components: {},
  props: {
    status: String,
    default: () => "",
  },
  data() {
    return {
      QueryStatus,
    };
  },
};
</script>
