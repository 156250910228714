<template lang="pug">
.pl-2.py-3.mb-3.rounded(:class="isHighlighted ? 'bg-teal-100' : 'shadow'")
  .d-flex
    div
      img(:src="require(`@/assets/images/whatsapp-logo.svg`)")
    .ml-2
      .font-weight-semibold.text-teal-900 Get updates and help, on WhatsApp 💫
      .font-sm.text-gray-900.mt-1 Only the most important updates. NO SPAM, we promise! 
      b-form-checkbox.mt-2(v-model="ctrlValue")
        span.text-teal-900.font-weight-semibold.font-sm Yes, count me in
</template>

<script>
export default {
  name: "NovaContactConsent",
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    isHighlighted: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ctrlValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
};
</script>

<style></style>
