<template lang="pug">
div.w-100
  .d-flex.flex-column.p-2
    .d-flex.justify-content-between.align-items-center.w-100.mt-2
      h3.font-weight-medium Your Information
      n-button.text-blue-800(
        variant="outline-secondary",
        buttonText="Edit",
        imageIcon="edit",
        size="sm",
        @click="$router.push({ name: 'about' })")
    b-card.d-flex.flex-column.my-2
      .d-flex.justify-content-start.my-2(v-for="userDetail in userDetails()")
        n-icon.text-gray-700.mr-1(name="check", :size="1.2")
        .detail-border-bottom.w-100.d-flex
          .font-md.text-gray-700.mr-2 {{ userDetail.label }}:
          .font-md {{ userDetail.value }}
  .d-flex.p-2.flex-column(v-if="dependentDetails.length > 0")
    .d-flex.justify-content-between.align-items-center.w-100
      h3.font-weight-medium Dependents
      n-button.text-blue-800(
        v-if="true"
        variant="outline-secondary",
        buttonText="Edit",
        imageIcon="edit",
        size="sm",
        :disabled="!isDependentsScreenAdded",
        @click="$router.push({ name: 'dependents' })")
    b-card.dependents-list.my-2
      dependent-details-wrapper(
        v-if="arePoliciesLoaded",
        :dependentsModel="dependentDetails",
        :policies="policies",
        :shouldSortDependents="false",
        :coverPOrPil="coverPOrPil")
</template>

<script>
import moment from "moment";
import DependentDetailsWrapper from "../components/DependentDetailsWrapper.vue";
import NButton from "../../../../../components/NovaButton.vue";
import mixin from "../mixin";
export default {
  name: "SummaryRight",
  components: {
    DependentDetailsWrapper,
    NButton,
  },
  mixins: [mixin],
  methods: {
    userDetails() {
      let userInfo = [
        {
          label: "Full Name",
          value: this.$store.state.getStarted.formData.selfDetails.name,
        },
        {
          label: "Work Email",
          value: this.$store.state.getStarted.formData.selfDetails.email,
        },
        {
          label: "Phone Number",
          value: this.$store.state.getStarted.formData.selfDetails.meta.contactNumber,
        },
        {
          label: "Date of Birth",
          value: moment(this.$store.state.getStarted.formData.selfDetails.dob).format("DD-MMM-YYYY"),
        },
        {
          label: "Gender",
          value: this.$store.state.getStarted.formData.selfDetails.gender,
        },
        {
          label: "Employee ID",
          value: this.$store.state.getStarted.formData.selfDetails.meta?.employeeId,
        },
      ];
      if (!this.isMobileView) {
        userInfo = [
          ...userInfo,
          {
            label: "City",
            value: this.$store.state.getStarted.formData.selfDetails.meta?.city,
          },
          {
            label: "Pincode",
            value: this.$store.state.getStarted.formData.selfDetails.meta.pincode,
          },
          {
            label: "Secondary Email",
            value: this.$store.state.getStarted.formData.selfDetails.meta.altEmail,
          },
        ];
      }

      return userInfo;
    },
  },
};
</script>
