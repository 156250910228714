export const ClaimStage = {
  MANDATORY_DOCUMENTS_PENDING: "Mandatory Documents Pending",
  CLAIM_INTIMATION_BY_EMPLOYEE: "Claim Intimation by employee",
  NO_DOCUMENT_RECEIVED_REMINDER_1: "No Document received - reminder 1",
  NO_DOCUMENT_RECEIVED_REMINDER_2: "No Document received - reminder 2",
  PARTIAL_DOCUMENTS_RECEIVED_REMINDER_1: "Partial documents received - reminder 1",
  PARTIAL_DOCUMENTS_RECEIVED_REMINDER_2: "Partial documents received - reminder 2",
  DORMANT: "Dormant",
  COMPLETE_DOCUMENTS_RECEIVED_AFTER_DEFICIENCY_CHECK_COURIER_PENDING:
    "Complete documents received after deficiency check - courier pending",
  DOCS_HARD_COPY_COURIERED_BY_EMPLOYEE: "Docs hard copy couriered by employee",
  DOCS_HARD_COPY_RECEIVED_BY_TPA: "Docs hard copy received by TPA",
  CLAIM_REGISTERED: "Claim registered",
  DOCS_UNDER_VERIFICATION: "Docs under verification",
  CLAIM_UNDER_QUERY: "Claim under query",
  QUERY_RESPONDED_BY_EMPLOYEE: "Query responded by employee",
  QUERY_DOCS_UNDER_VERIFICATION: "Query docs under verification",
  CLAIM_UNDER_QUERY_2: "Claim under query 2",
  QUERY_DOCS_UNDER_VERIFICATION_2: "Query docs under verification 2",
  CLAIM_UNDER_INVESTIGATION: "Claim under Investigation",
  CLAIM_UNDER_QUERY_REMINDER_1: "Claim under query - reminder 1",
  CLAIM_UNDER_QUERY_REMINDER_2: "Claim under query - reminder 2",
  DORMANT_POST_REGISTRATION: "Dormant (post-registration)",
  CLAIM_APPROVED_BY_TPA: "Claim approved by TPA",
  DOCS_HARD_COPY_COURIERED_BY_EMPLOYEE_POSTAPPROVAL: "Docs hard copy couriered by employee (post-approval)",
  DOCS_HARD_COPY_RECEIVED_BY_TPA_POSTAPPROVAL: "Docs hard copy received by TPA (post-approval)",
  DOCS_HARD_COPY_VERIFICATION: "Docs hard copy verification",
  PAYMENT_UNDER_PROCESS: "Payment under process",
  CLAIM_SETTLED: "Claim settled",
  CLAIM_REOPENED: "Claim re-opened",
  CLAIM_REPUDIATED_BUT_WAITING_CONCURRENCE: "Claim Repudiated but waiting concurrence",
  CLAIM_REPUDIATED: "Claim Repudiated",
  INVALID: "Invalid",
  DOCS_SOFT_COPY_UPLOADED: "Docs soft copy uploaded",
  // cashless
  UPCOMING: "Upcoming",
  INTIMATION: "Intimation",
  PREAUTH_RECEIVED: "Pre-Auth Received",
  INFORMATION_AWAITED: "Information Awaited",
  PREAUTH_APPROVED: "Pre-Auth Approved",
  INTERIUM_APPROVAL_IN_PROGRESS: "Interium Approval in progress",
  INTERIUM_APPROVED: "Interium Approved",
  APPROVED: "Approved",
  CANCELLED: "Cancelled",
  QUERY_TO_THE_HOSPITAL: "Query to the Hospital",
  QUERY_RECEIVED_FROM_HOSPITAL: "Query received from Hospital",
  DENIED: "Denied",
  RE_ACTIVATED_DEFICIENCY_CHECK_PENDING: "Re-Activated, Deficiency check pending"
};
