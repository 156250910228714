import userTeamDesignationMutation from "../mutations/userTeamDesignations";
import { getListQuery, getSingleQuery } from "../queries";
import userTeamDesignationSchema from "../schemas/userTeamDesignations";

export default {
  gqlType: "UserTeamDesignation",
  singleQuery: getSingleQuery("UserTeamDesignation"),
  listQuery: getListQuery("UserTeamDesignation"),
  ...userTeamDesignationSchema,
  ...userTeamDesignationMutation,
  transform: (n, write = false) => {
    const userTeamDesignations = n.userTeamDesignations.edges;
    const data = userTeamDesignations.map((userTeamDesignation) => {
      const record = userTeamDesignation.node;
      const userData = record?.user;
      return {
        id: record?.id,
        userEmail: record?.user?.email,
        reportsToEmail: record?.reportsTo?.email || "-",
        teamName: record?.team?.name,
        designationName: record?.designation?.name,
        employmentType: record?.employmentType || "-",
        status: record?.status,
        userOrgName: userData?.orgEntity?.name || userData?.org?.name,
        isUserPartOfOrgEntity: !!userData?.orgEntity?.name,
        createdAt: record?.createdAt,
        updatedAt: record?.updatedAt,
      };
    });
    return { data, totalCount: n.userTeamDesignations.totalCount };
  },
};
