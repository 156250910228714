<template lang="pug">
.d-flex.flex-column.flex-lg-row.callout-container(:id="id", :class="`bg-${bgColor}`", v-bind="{...$props, ...$attrs}",)
  img.callout-icon(v-if="img" :src="img")
  .callout-description.text-gray-800.font-weight-medium.font-md
    slot(name="description")
</template>

<script>
export default {
  name: "Callout",
  props: {
    id: {
      type: String,
      default: null,
    },
    bgColor: {
      type: String,
      default: null,
    },
    img: {
      type: String,
      default: null,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_mixins.scss";
@import "@/assets/styles/_variables.scss";

.callout-container {
  padding: 1rem;
  border-radius: 0.5rem;
  @include media-breakpoint-down(md) {
    padding: 0.75rem;
  }
}

.callout-icon {
  width: 1rem;
  height: 1rem;
}

.callout-description {
  margin-left: 0.5rem;
  line-height: 140%;
  @include media-breakpoint-down(md) {
    font-size: 0.75rem;
    margin-left: unset;
    margin-top: 0.5rem;
  }
}
</style>
