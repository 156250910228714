import gql from "graphql-tag";

export default {
  upsertMutation: gql`
    mutation CliToolsMutation($file: Upload, $emailTemplateFile: Upload, $cmdName: String!, $args: JSONObject) {
      invokeCliTool(input: { file: $file, emailTemplateFile: $emailTemplateFile, cmdName: $cmdName, args: $args }) {
        job {
          id
          status
          logs
          result
        }
      }
    }
  `,
};
