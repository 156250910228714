<template lang="pug">
.checkup-booking-container.mb-3
  .tabs-container.justify-content-start.align-items-center.border-bottom
    .tab-heading.d-inline-block.cursor-pointer.font-md.font-weight-semibold.text-center.text-gray-700.px-3.pb-3.pt-2.p-md-3(
      v-for="tab in bookingTabs",
      :key="tab.route",
      @click="updateTab(tab.route)",
      :class="{ 'active-tab': $route.path.includes(tab.route) }") {{tab.title}}
  schedule-booking(v-if="currentPage === CheckupRoutes.SCHEDULE_BOOKING")
  booking-history(v-else-if="currentPage === CheckupRoutes.BOOKING_HISTORY")
</template>

<script>
import gql from "graphql-tag";
import ScheduleBooking from "./ScheduleBooking.vue";
import BookingHistory from "./BookingHistory.vue";
import { CheckupRoutes } from "@/common/enums";

export default {
  name: "CheckupBooking",
  components: {
    ScheduleBooking,
    BookingHistory,
  },
  props: {},
  data() {
    return {};
  },
  computed: {
    CheckupRoutes: () => CheckupRoutes,
    benefitId() {
      return this.$route.params.benefitId;
    },
    currentPage() {
      if (this.$route.path.includes(CheckupRoutes.BOOKING_HISTORY)) return CheckupRoutes.BOOKING_HISTORY;
      return CheckupRoutes.SCHEDULE_BOOKING;
    },
    bookingTabs() {
      const tabList = [
        {
          route: CheckupRoutes.SCHEDULE_BOOKING,
          title: "Schedule Booking",
        },
        {
          route: CheckupRoutes.BOOKING_HISTORY,
          title: "Booking History",
        },
      ];
      return tabList;
    },
  },
  methods: {
    updateTab(route) {
      if (route.includes(CheckupRoutes.SCHEDULE_BOOKING))
        window.posthog.capture("checkup_navigated_to_schedule_booking");
      else window.posthog.capture("checkup_navigated_to_your_bookings");
      let name = "bookingHistory";
      if (route === CheckupRoutes.SCHEDULE_BOOKING) name = "scheduleBooking";
      this.$router.push({
        name,
        params: {
          benefitId: this.$route.params.benefitId,
          bookingUser: this.$route.params.bookingUser,
        },
      });
    },
  },
  apollo: {
    doesUserHaveActiveBookings: {
      query: gql`
        query UserWithBookings {
          me {
            id
            doesUserHaveActiveBookings
          }
        }
      `,
      update(data) {
        return data.me.doesUserHaveActiveBookings;
      },
      fetchPolicy: "no-cache",
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/_variables.scss";
@import "@/assets/styles/mixins/_breakpoints.scss";

.checkup-booking-container {
  .tabs-container {
    @include media-breakpoint-down(sm) {
      margin-left: -15px;
      margin-right: -15px;
    }
  }
  .active-tab {
    color: $tiber;
    border-bottom: 2px solid $tiber;
  }
  .border-bottom {
    border-bottom: 2px solid $gray-300;
  }
}
</style>
