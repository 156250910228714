<template lang="pug">
n-overlay
  div
    .d-flex.flex-column.w-100.h-100.overflow-hidden
      .shadow.fixed-top.bg-white
        .container
          .row.d-flex.align-items-center.py-3.justify-content-between
            .d-flex.header-title-wrapper
              n-icon(name="arrow", variant="gray-600", :size="1.5", @click="goBack")
              .d-flex.align-items-center.header-title
                span.ml-2.font-lg.font-weight-semibold Documents Upload
            .d-flex
            #saveBtn
              n-button.d-none.d-md-block.mr-4.px-4(variant="success", :buttonText="saveBtnText", @click="saveAndExit", size="sm")
              n-popover(v-if="isClaimFormAGenerationEnabled", target="saveBtn", message="Proceed to generate Claim Form Part A", triggers="hover", :showHeader="false", placement="bottom")  
      .overflow-scroll.pt-5.my-5.docs-container
        .container
          .row.d-none.d-sm-flex
            .col-md-8
              .d-flex.shadow-sm.border.rounded.mb-1(v-for="type of Object.keys(types)", v-if="!(type === 'Insurance Claim Forms' && isClaimFormAGenerationEnabled)")
                .col-md-4.pt-4
                    span.font-weight-semibold {{type}}
                    b-link.d-block.mt-3(v-if="shouldShowSectionAction(type)" @click.prevent="downloadForms(type)")
                      .d-flex 
                        i.icon-download.mr-2
                        | {{getSectionActionText(type)}}
                .col-md-8
                  batch-uploader-wrapper.mb-5(v-model="uploadedFiles", :types="getTypeAndDescription(type)",:requiredDetails="getReqDetails()")
            .col-md-4.fixed-column
                .bg-white.border.shadow-sm.rounded.px-4.py-4
                  span.font-weight-semibold.text-gray-900.text-nowrap.font-xl {{totalUploadedDocuments}}/{{totalDocuments}} 
                  span.font-weight-medium.text-gray-700 Documents uploaded
                  .d-flex.align-items-center.justify-content-between.mt-2
                    .progress.progress-bar-container
                      .progress-bar.pb(
                        role="'progressbar'",
                        :aria-valuenow="totalUploadedDocuments",
                        aria-valuemin="0",
                        :aria-valuemax="totalDocuments",
                        :style="'width: '+(100/totalDocuments)*totalUploadedDocuments+'%;'")
                  hr
                  span.font-lg.font-weight-semibold.text-gray-900 {{ sideContent.heading }}
                  .mt-2(v-if="!isMandatoryDocsFlowEnabled")
                    span.font-weight-medium.text-gray-700 P.S: Users who upload all their documents at this stage see
                    span.font-weight-bold.text-teal-700.ml-1 40% faster claim settlements
                  .mt-2(v-else)
                    span.font-weight-medium.text-gray-700 {{ sideContent.text }}
                  .mt-3(v-if="isMandatoryDocsFlowEnabled")
                    span.text-gray-900.font-sm {{ sideContent.subText }}
                .bg-malibu-100.mt-3.shadow-sm.rounded.px-4.py-4(v-if="!isMandatoryDocsFlowEnabled")
                  span.font-weight-semibold.font-lg.text-gray-900 Upload all the documents
                  .mt-2
                    span.font-sm.text-gray-700 Upload all documents related to the claim right away, to ensure a smooth and speedy settlement!
                  .mt-3
                    span.font-sm.text-gray-700.mt-2
                      |  Please ensure each upload is below 3 MB. 

          .d-flex.flex-column.d-md-none
            .d-flex.justify-content-between
              span.font-weight-semibold.mb-3 {{ segment }}
              .d-flex.mr-2
                span.font-weight-semibold.mb-3(:class="totalFilesInSegment === 0?'text-danger':'text-gray-900'") {{totalFilesInSegment}}/{{types[segment].length}}
                span.font-weight-semibold.text-gray-500 &nbsp;uploaded
            .font-sm.d-block
              span(v-if="segment === 'Insurance Claim Forms'") Please download the claim forms provided on TPA's
                | &nbsp;
                b-link(@click.prevent="downloadForms(segment)")
                  span website&nbsp;
                span complete filling them up, and upload them here.
              span(v-else-if="segment === 'Identification Docs' && isCKYCRequired") Please tap 
                | &nbsp;
                b-link(@click.prevent="downloadForms(segment)")
                  span here&nbsp;
                span to download the CKYC form.
            
            batch-uploader-wrapper.mb-5(v-model="uploadedFiles", :types="getTypeAndDescription(segment)",:requiredDetails="getReqDetails()")
      .p-5
      .fixed-bottom.bg-white.border.shadow-lg.px-4.py-4.d-md-none.bottom-actions
        .container
          .d-flex.align-items-center.justify-content-between
            span.font-weight-semibold.text-gray-900.text-nowrap Section {{currentStep}}/{{totalSteps}}
            .progress.progress-bar-container.ml-2
              .progress-bar.pb(
                role="'progressbar'",
                :aria-valuenow="currentStep",
                aria-valuemin="0",
                :aria-valuemax="totalSteps",
                :style="'width: '+(100/totalSteps)*currentStep+'%;'")
          .mt-4
            span.font-weight-medium.text-gray-700 {{ bottomSheet.content }} 
            span.font-weight-medium.text-teal-700 {{ bottomSheet.highlight }}
            .font-md.text-gray-700.mt-3(v-if="currentStep === 1")
              | ⚠️ Please ensure each upload is below 3 MB.
          .mt-4
            n-button.w-100(variant="success", :buttonText="bottomSheet.actionText", @click="saveAndProceed")
  
    special-modal(
      id="congrats-modal",
      headerIcon="icon",
      :heroImage="require('@/assets/images/Save_and_Exit_Header_Image_Container.svg')",
      heroImageSize="md",
      logoIcon="charge", 
      centered,
      @hide="gotoDetailsPage")
      template(v-slot:title)
        h3.hb3.d-inline.font-weight-semibold.text-gray-900 Documents Uploaded ✨
      template(v-slot:modal-content)
        .text-center
          | The documents have been updated to your claim!
      template(v-slot:buttons-area)
        .rounded-circle
      
    special-modal.w-75(
      id="missing-claim-docs-modal",
      logoIcon="error",
      :heroImage="require('@/assets/images/Unsaved_Data_Header_Image_Container.svg')",
      centered
    )
      template(v-slot:title)
        h3.hb3.d-inline.font-weight-semibold.text-gray-900 Missing Documents ⚠️
      template(v-slot:modal-content)
        .text-center.px-5
          | Your claim cannot be processed without the required documents. Upload all required documents now, or save your progress. 
      template(v-slot:buttons-area)
        .d-flex.align-items-center.mt-3.mb-2
          n-button.px-3.ml-2(variant="light", button-text="Save as Draft", @click="handleSaveDraft($event)")
          n-button.px-3.ml-2(variant="dark", button-text="Upload Now ->", @click="handleUploadNow($event)")
          

    special-modal.w-75(
      id="mandatory-docs-uploaded-modal",
      logoIcon="check-circle",
      :heroImage="require('@/assets/images/claim_created_after_all_docs_modal.svg')"
      heroImageSize="md"
      centered
    )
      template(v-slot:title)
        .hb3.d-inline.font-weight-semibold.text-gray-900 You’re all set ✨
      template(v-slot:modal-content)
        .d-flex.align-items-center.flex-column
          .w-75.text-center
            .font-weight-semibold.font-lg
              | Your claim request has been created
            .my-2
              | Someone from our team will reach out to you shortly!
            .font-weight-medium.text-gray-700.font-md
              | You can track your claim status from the claims page on your nova portal
      template(v-slot:buttons-area)
        .d-flex.align-items-center.mt-3.mb-2
          n-button.px-3.ml-2(variant="dark", button-text="View your claim ->", @click="gotoDetailsPage()")
      

</template>

<script>
import { mapGetters } from "vuex";
import userDefs from "../definitions";
import adminDefs from "../../admin/definitions";
import {
  claimDocumentDescription,
  claimDocumentSegments,
  claimDocumentsList,
  opdClaimDocumentSegments,
  opdClaimDocumentDescription,
  opdClaimDocumentsList,
  opdClaimDocumentSegmentMandatoryDoc,
  claimDocumentSegmentMandatoryDoc,
} from "../../admin/constants";
import { ClaimSourceTypes } from "../../../../common/enums";
import { ClaimDocument } from "../../../../common/enums/claims/claimDocument";
import BatchUploaderWrapper from "./components/BatchUploaderWrapper.vue";
import NButton from "@/components/NovaButton";
import NOverlay from "@/components/Overlay";
import SpecialModal from "@/components/SpecialModal";
import utils from "@/utils";
import NPopover from "@/components/NovaPopover.vue";
export default {
  components: {
    NButton,
    NOverlay,
    SpecialModal,
    BatchUploaderWrapper,
    NPopover,
  },
  data() {
    return {
      claimId: null,
      uploadedFiles: [],
      currentStep: 1,
      totalSteps: 6,
      types: utils.deepClone(claimDocumentSegments),
      claimFormsLink: null,
      isOpdClaim: null,
      missingDocNames: [],
      docNameErrorState: {},
      claimFilesUpdated: false,
      steps: [1, 2, 3, 4],
      isCKYCRequired: false,
    };
  },
  computed: {
    ...mapGetters(["getFeatureFlags"]),
    segmentedFiles() {
      const files = {};
      this.uploadedFiles.forEach((file) => {
        if (!file.docType) file.docType = "Other";
        if (!(file.docType in files)) files[file.docType] = [];
        files[file.docType].push(file);
      });
      return files;
    },
    isMobile() {
      return utils.mobileCheck();
    },
    totalFilesInSegment() {
      let totalUploadedFiles = 0;
      this.types[this.segment].forEach((key) => {
        if (key in this.segmentedFiles) {
          totalUploadedFiles = totalUploadedFiles + 1;
        }
      });
      return totalUploadedFiles;
    },
    bottomSheet() {
      let actionText = this.currentStep === this.totalSteps ? "Save & Exit" : "Save & Proceed";

      let content = "P.S: Users who upload all their documents at this stage see";
      let highlight = "40% faster claim settlements";
      if (this.currentStep === 1) {
        content = "It’s okay if you don’t have all the documents right now.";
        highlight = "Just upload the available ones!";
      } else if (this.currentStep === this.totalSteps) {
        content = `You’ve uploaded total ${this.totalUploadedDocuments} out of ${this.totalDocuments} documents.`;
        highlight = "";
      }
      if (this.isMandatoryDocsFlowEnabled) {
        actionText = "Next";
        content = "Your claim will only be initiated upon";
        highlight = "uploading all necessary documents.";
      }
      return {
        content,
        highlight,
        actionText,
      };
    },
    isMandatoryDocsFlowEnabled() {
      return Boolean(this.getFeatureFlags.MANDATORY_CLAIM_DOCS_FLOW);
    },
    isClaimFormAGenerationEnabled() {
      const formA = this.uploadedFiles?.find((file) => file.docType === ClaimDocument.CLAIM_FORM_PART_A);
      if (formA && formA?.meta.generatedBy !== "MAGIC_FILL") {
        return false;
      }

      return Boolean(this.getFeatureFlags.ENABLE_CLAIM_FORM_A_GENERATION);
    },
    saveBtnText() {
      if (this.isClaimFormAGenerationEnabled) {
        return "Continue";
      }

      if (this.isMandatoryDocsFlowEnabled) {
        return "Create your Claim";
      } else if (this.$route.query.firstTime) {
        return "Save & Create Claim";
      }

      return "Save & Exit";
    },
    sideContent() {
      return {
        heading: "Upload documents for your claim! 🙌",
        text: "A claim can be processed only after the required documents are uploaded",
        subText: "⚠️ Please ensure each upload is below 3 MB.",
      };
    },
    totalUploadedDocuments() {
      return Object.keys(this.segmentedFiles).length;
    },
    totalDocuments() {
      const docs = this.isOpdClaim ? opdClaimDocumentsList : claimDocumentsList;
      return docs.length;
    },
    segment() {
      let segments = Object.keys(this.types);
      if (this.isClaimFormAGenerationEnabled) {
        segments = segments.filter((item) => item !== "Insurance Claim Forms");
      }
      this.totalSteps = segments.length - 1;
      return segments[this.currentStep - 1];
    },
  },
  watch: {
    isOpdClaim(val) {
      if (val === true) {
        this.types = utils.deepClone(opdClaimDocumentSegments);
      } else {
        this.types = utils.deepClone(claimDocumentSegments);
      }
    },
    isCKYCRequired(val) {
      if (val) {
        if (!this.types["Identification Docs"].includes(ClaimDocument.CKYC_FORM)) {
          this.types["Identification Docs"] = [...this.types["Identification Docs"], ClaimDocument.CKYC_FORM];
        }

        this.validateAllSections();
        this.showMissingDocErrors();
      }
    },
  },
  async created() {
    this.claimId = this.$route.params.id;
  },
  async mounted() {
    this.validateAllSections();
    this.showMissingDocErrors();
    this.$root.$on("claimFileUploaded", async () => {
      await this.handleFileUploaded();
    });
  },
  methods: {
    getTypeAndDescription(type) {
      const types = this.types[type];
      const typesAndDesc = [];
      const discriptions = this.isOpdClaim ? opdClaimDocumentDescription : claimDocumentDescription;
      for (const typeName of types) {
        typesAndDesc.push({
          name: typeName,
          description: discriptions.get(typeName),
          isError: this.docNameErrorState[typeName],
        });
      }
      return typesAndDesc;
    },
    async saveAndProceed() {
      let success = true;
      if (this.currentStep === this.totalSteps) {
        await this.saveAndExit();
      } else {
        success = await this.saveData();
      }
      if (success) this.currentStep = this.currentStep < this.totalSteps ? this.currentStep + 1 : this.totalSteps;
    },
    validateAllSections() {
      const errors = [];
      this.steps.forEach((step) => {
        const error = this.validateMandatoryDocBySection(step);
        errors.push(error);
      });
      this.missingDocNames = errors;
      const errorCount = errors.reduce((acc, sectionError) => {
        acc += Object.values(sectionError)[0].length;
        return acc;
      }, 0);
      return errorCount === 0;
    },
    async saveAndExit() {
      const isSuccess = this.isMandatoryDocsFlowEnabled ? this.validateAllSections() : true;
      if (!isSuccess) {
        this.showMissingDocErrors();
        this.showModal("missing-claim-docs-modal");
        return false;
      }
      await this.saveData();
      this.$apollo.queries.claim.refetch();
      if (this.isClaimFormAGenerationEnabled) {
        this.$router.push({
          path: `/user/claims/generate-form-a/${this.claimId}`,
        });
        return;
      }
      if (this.isMandatoryDocsFlowEnabled) {
        this.showModal("mandatory-docs-uploaded-modal");
        return;
      }
      this.$bvModal.show("congrats-modal");
      setTimeout(() => {
        this.gotoDetailsPage();
      }, 3000);
    },
    validateMandatoryDocBySection(step) {
      const mandatoryDocsList = this.isOpdClaim
        ? opdClaimDocumentSegmentMandatoryDoc
        : claimDocumentSegmentMandatoryDoc;

      /** Form A shouldn't be a mandatory doc in the doc upload page if its generation is done by us. */
      if (this.isClaimFormAGenerationEnabled) {
        mandatoryDocsList["Insurance Claim Forms"] = [];
      }
      if (this.isCKYCRequired) {
        mandatoryDocsList["Identification Docs"].push(ClaimDocument.CKYC_FORM);
      }
      const curSectionName = Object.keys(claimDocumentSegments)[step - 1];
      const currSectionMandatoryDocs = mandatoryDocsList[curSectionName];
      const addedDocuments = this.uploadedFiles
        .filter((file) => currSectionMandatoryDocs.includes(file.docType))
        .map((doc) => doc.docType);
      const missingDocuments = currSectionMandatoryDocs.filter((doc) => !addedDocuments.includes(doc));
      return { [curSectionName]: missingDocuments };
    },
    async saveData(skipValidation = false) {
      if (this.currentStep === 4 && !skipValidation && this.isMandatoryDocsFlowEnabled) {
        const docsMissing = this.validateMandatoryDocBySection(this.currentStep);
        const missingDocsNames = Object.values(docsMissing)[0];
        if (missingDocsNames?.length > 0) {
          this.missingDocNames = [docsMissing];
          this.showMissingDocErrors();
          this.showModal("missing-claim-docs-modal");
          return false;
        }
      }

      this.uploadedFiles = this.uploadedFiles.filter((file) => !file.autoAttached);

      const meta = {
        ...this.claim.meta,
        claimFilesUpdated: this.claimFilesUpdated,
      };
      try {
        await this.$apollo.mutate({
          mutation: userDefs.claims.upsertMutation,
          variables: {
            status: this.claim.status,
            meta,
            id: this.claim.id,
            userId: this.claim.user.id,
            type: this.claim.type,
            source: ClaimSourceTypes.PORTAL_USER,
          },
        });
      } catch (err) {
        console.log(err);
      }
      return true;
    },
    gotoDetailsPage() {
      this.$router.push({
        name: "claim-details",
        params: { id: this.claim.id },
      });
    },
    goBack() {
      if (this.currentStep === 1) {
        this.gotoDetailsPage();
      } else {
        this.currentStep = this.currentStep - 1;
      }
    },
    downloadForms(type) {
      if (type === "Insurance Claim Forms" && this.claimFormsLink) {
        window.open(this.claimFormsLink, "_blank");
      } else if (type === "Identification Docs") {
        window.open(process.env.VUE_APP_CLAIMS_CKYC_FORM, "_blank");
      }
    },
    showModal(id) {
      this.$bvModal.show(id);
    },
    hideModal(id) {
      this.$bvModal.hide(id);
    },
    resetDocNameErrorState() {
      this.docNameErrorState = {};
    },
    showMissingDocErrors() {
      this.resetDocNameErrorState();
      this.missingDocNames.forEach((entry) => {
        const [segmentName, missingDocNames] = Object.entries(entry)[0];

        this.types[segmentName].forEach((type) => {
          if (missingDocNames.includes(type)) {
            this.docNameErrorState[type] = true;
          }
        });

        // to trigger re-render of getTypeAndDescription method
        const docsName = this.types[segmentName];
        this.types[segmentName] = [];
        this.types[segmentName] = docsName;
      });
    },
    scrollToDocUploadError() {
      const element = document.querySelector(".border-danger");
      element?.scrollIntoView({ behavior: "smooth", block: "center", inline: "center" });
    },
    handleUploadNow(event) {
      this.hideModal("missing-claim-docs-modal");
      if (!this.isMobile) {
        return this.scrollToDocUploadError();
      }

      for (const stepNumber of this.steps) {
        const error = this.validateMandatoryDocBySection(stepNumber);
        const errorKey = Object.keys(error)[0];

        if (error[errorKey].length > 0) {
          this.currentStep = stepNumber + (this.isClaimFormAGenerationEnabled ? -1 : 0);
          break;
        }
      }
      this.validateAllSections();
      this.showMissingDocErrors();
      this.scrollToDocUploadError();
    },
    async handleSaveDraft(event) {
      this.hideModal("missing-claim-docs-modal");
      await this.saveData(true);
      this.$apollo.queries.claim.refetch();
      this.$bvModal.show("congrats-modal");
      setTimeout(() => {
        this.$router.replace("/user/claims");
        this.$store.commit("addToast", {
          variant: "success",
          title: "Draft Created",
          message: "Your claim was saved as a draft, please upload necessary documents to initiate it.",
        });
      }, 2000);
    },
    getReqDetails() {
      return {
        claimId: this.claim ? this.claim.id : null,
      };
    },
    async handleFileUploaded() {
      await this.$apollo.queries.claimFiles.refetch();
      this.claimFilesUpdated = true;
    },
    shouldShowSectionAction(type) {
      return (
        (type === "Insurance Claim Forms" && this.claimFormsLink) ||
        (type === "Identification Docs" && this.isCKYCRequired)
      );
    },
    getSectionActionText(type) {
      if (type === "Insurance Claim Forms") {
        return "Download Form(s)";
      }
      if (type === "Identification Docs") {
        return "Download CKYC Form";
      }
      return "";
    },
  },
  apollo: {
    claim: {
      query: userDefs.claims.singleQuery,
      fetchPolicy: "no-cache",
      variables() {
        return { id: this.$route.params.id };
      },
      update(data) {
        const claim = userDefs.claims.transform(data.node);

        this.isOpdClaim = claim?.meta?.formType === "opd";
        this.isCKYCRequired = claim?.meta?.estimatedClaimAmount >= 1_00_000;
        return claim;
      },
    },
    claimFiles: {
      query: adminDefs.claimFiles.getClaimFiles,
      fetchPolicy: "no-cache",
      skip() {
        return !this.claim?.id;
      },
      variables() {
        return { claimId: this.claim.id };
      },
      update(data) {
        this.uploadedFiles = data.claimFiles;
        const patientBenefit = this.claim.user.benefits.find((benefit) => benefit.node.id === this.claim.policyId);
        const eCardUrl = patientBenefit?.meta?.cardUrl;
        if (eCardUrl) {
          this.uploadedFiles.push({
            claimId: this.claim.id,
            docType: "E-Card",
            fileDetails: {
              name: "E-Card.pdf",
              url: eCardUrl,
            },
            meta: { type: "pdf" },
            autoAttached: true,
          });
        }
      },
    },
    policy: {
      query: adminDefs.policies.singleQuery,
      skip() {
        return !this.claim?.policy;
      },
      variables() {
        return { id: this.claim.policy.id };
      },
      update(data) {
        this.claimFormsLink = data.node?.claimFormsLink;
        return data.node;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
@import "@/assets/styles/mixins/_breakpoints.scss";
@import "@/assets/styles/_variables.scss";

.progress-bar-container {
  width: 100%;
  height: 5px;
  .pb {
    background-color: $blue-500;
    border-radius: 32px;
  }
}

.fixed-column {
  position: fixed;
  right: calc((100% - 1140px) / 2);
  width: calc((1140px / 12) * 4);
}

@media only screen and (max-width: 992px) {
  .header-title-wrapper {
    width: 100%;
  }

  .header-title {
    width: 100%;
    justify-content: center;
  }

  .icon-arrow {
    position: absolute;
    margin-left: 1.5rem;
  }
}

@media (max-width: 1200px) {
  .fixed-column {
    right: calc((100% - 960px) / 2);
    width: calc((960px / 12) * 4);
  }
}

@media (max-width: 1000px) {
  .fixed-column {
    right: calc((100% - 720px) / 2);
    width: calc((720px / 12) * 4);
  }
}

@include media-breakpoint-down(sm) {
  .docs-container {
    overflow-y: scroll;
    height: calc(100vh - 268px);
  }
}
</style>
