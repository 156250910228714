<template lang="pug">
div
  .textarea-wrap
    textarea#new-note.form-control.input-wrapper.w-100.text-gray-900(
      v-bind="{...$props, ...$attrs}",
      :rows="rows", :maxlength="maxLength",
      :placeholder="placeholder",
      :cols="cols",
      @keyup="getMaxRows",
      v-model="inputValue",
      :class="[state, fontSize ? `font-${fontSize}` : '']")
    span.badge-pill.py-1.font-xs.message-counter.text-white.bg-gray-800 {{this.charCount}}/{{this.maxLength}}
  .input-sub-text.text-left.pt-2(v-if="subtext", :class="state")
    span.align-middle {{ subtext }}

  .input-sub-text.text-left.pt-2(v-if="validationMessage", :class="state")
    n-icon.pr-1.sub-text-icon.align-middle(v-if="state && state !== 'disabled'", :name="stateIconMap[state] || ''", :size="1")
    span.align-middle {{ validationMessage }}
</template>

<script>
export default {
  name: "NTextArea",
  props: {
    value: {
      type: String,
      default: "",
    },
    rows: {
      type: Number,
      default: 3,
    },
    placeholder: {
      type: String,
      default: "",
    },
    maxLength: {
      type: Number,
      default: 150,
    },
    validationMessage: {
      type: String,
      default: null,
    },
    state: {
      type: String,
      default: null,
    },
    subtext: {
      type: String,
      default: null,
    },
    fontSize: {
      type: String,
      default: "",
    },
    maxRows: {
      type: Number,
      default: 4,
    },
    cols: {
      type: Number,
      default: 20,
    },
    dynamicHeight: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const stateIconMap = {
      success: "check-circle",
      error: "error",
      warning: "warning",
    };
    return {
      stateIconMap,
      inputValue: this.value,
      charCount: this.value ? this.value.length : 0,
    };
  },
  watch: {
    value(newValue) {
      this.inputValue = newValue;
      this.charCount = newValue ? newValue.length : 0;
    },
    inputValue() {
      this.charCount = this.inputValue.length;
      this.$emit("input", this.inputValue);
    },
  },
  methods: {
    getMaxRows(event) {
      if (!this.dynamicHeight) {
        return;
      }

      const textbox = event.target;
      const totalColumns = textbox.cols;
      const textArray = textbox?.value?.split("\n");
      let rows = textArray.length;

      textArray.forEach((textSubpart) => {
        rows += parseInt(textSubpart.length / totalColumns);
      });

      if (rows > this.maxRows) textbox.rows = this.maxRows;
      else textbox.rows = rows;
    },
  },
};
</script>

<style>
.textarea-wrap {
  position: relative;
  margin: auto;
}
.message-counter {
  position: absolute;
  right: 0rem;
  margin-right: 1rem;
  bottom: 0.75rem;
  line-height: initial;
}
</style>
