import gql from "graphql-tag";
import { fileGroupFragment } from "./fileGroup";

export const fileGroupEdgeFragment = gql`
  fragment FileGroupEdgeEdge on FileGroupEdgeEdge {
    node {
      ...FileGroup
    }
    meta
  }
  ${fileGroupFragment}
`;

export const fileFragment = gql`
  fragment File on File {
    id
    name
    url
    meta
    fileGroups {
      ...FileGroupEdgeEdge
    }
    uploadingUser {
      name
      org {
        name
      }
    }
    action
    createdAt
  }
  ${fileGroupEdgeFragment}
`;
