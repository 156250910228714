import { apolloClient } from "@/portal/apollo";
import { UPLOAD_FILE, GET_FILE_SIGNED_URL, GET_HANDBOOK_FILE_URL } from "../definitions/file";

/*
  mutations and queries
*/
export async function uploadFile(variables) {
  return apolloClient.mutate({
    mutation: UPLOAD_FILE,
    variables,
  });
}
export async function getFileSignedUrl(action, filePath) {
  return apolloClient.query({
    query: GET_FILE_SIGNED_URL,
    variables: { action, filePath },
  });
}
export async function getHandbookFileUrl(insurerId, tpaId) {
  return apolloClient.query({
    query: GET_HANDBOOK_FILE_URL,
    variables: { insurerId, tpaId },
  });
}
