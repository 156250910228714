import moment from "moment";
import { memberRelationOptions } from "../checkupUtils";
import { validateName, validateDependentGender, validateDate } from "@/utils";
export default {
  schema: {
    fields: [
      {
        model: "name",
        type: "inline-input",
        inputType: "text",
        label: `Name<span style="color: red">*</span>`,
        placeholder: "Enter full name",
        required: true,
        noPadding: true,
        styleClasses: "mb-4",
        validator: [validateName],
      },
      {
        model: "dob",
        type: "datepicker",
        label: `Date of Birth<span style="color: red">*</span>`,
        required: true,
        min: "1900-01-01",
        max: moment().format("YYYY-MM-DD"),
        styleClasses: "d-inline-block w-75 pr-2 mb-4",
        labelClasses: "mb-2 pb-1",
        shouldFormat: true,
      },
      {
        model: "age",
        type: "inline-input",
        inputType: "text",
        label: "Age",
        disabled: true,
        styleClasses: "d-inline-block w-25 pl-2 mb-2 align-top",
        labelClasses: "mb-2 pb-1",
      },
      {
        model: "gender",
        type: "select-cards",
        label: `Gender<span style="color: red">*</span>`,
        validator: [validateDependentGender],
        cardsData: [
          {
            name: "gender",
            icon: "female",
            label: "Female",
            card_value: "female",
          },
          {
            name: "gender",
            icon: "male",
            label: "Male",
            card_value: "male",
          },
        ],
        styleClasses: "mt-0 mb-2",
      },
      {
        model: "relation",
        type: "inline-select",
        label: `Relationship<span style="color: red">*</span>`,
        placeholder: "How is the member related to you?",
        // validator: [validateDependentCount, validateInlineSelect],
        reduce: (v) => v.value,
        options: memberRelationOptions,
      },
    ],
  },
};
