import { render, staticRenderFns } from "./StatusCard.vue?vue&type=template&id=f7873e32&scoped=true&lang=pug"
import script from "./StatusCard.vue?vue&type=script&lang=js"
export * from "./StatusCard.vue?vue&type=script&lang=js"
import style0 from "./StatusCard.vue?vue&type=style&index=0&id=f7873e32&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f7873e32",
  null
  
)

export default component.exports