<template lang="pug">
section.container
  support-tickets
</template>

<script>
import SupportTickets from "./SupportTickets.vue";
export default{
  components:{
    SupportTickets,
  },
  mounted() {
    window.posthog.capture("$pageview");
  },
}
</script>

<style lang="scss" scoped>
</style>