<template lang="pug">
div
  hr.d-none.d-md-block.mb-0
  router-view
</template>

<script>
import NButton from "@/components/NovaButton.vue";

export default {
  components: {
    NButton,
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
.user-nav.nav-pills .nav-link.active {
  background-color: $gray-200;
  color: $gray-900;
  .text-dark i {
    color: $gray-900;
  }
}
.user-nav.nav-pills .nav-link {
  color: $gray-700;
  .text-dark i {
    color: $gray-700;
  }
}

.container-card {
  min-height: 500px;
}
</style>
