<template lang="pug">
b-modal#basic-modal(
    ref="basicModal",
    centered,
    no-close-on-backdrop,
    no-close-on-esc,
    hide-header-close
  )
  template(v-slot:modal-title)
    .d-flex.flex-column.justify-content-between.font-lg.font-weight-semibold.text-gray-900 {{ title }}
  .font-md.font-inter.font-weight-medium.pb-4.text-gray-900 {{ description }}
  template(v-slot:modal-footer)
    .buttons-container.d-flex.justify-content-end.align-items-center
      n-button.mr-3(
        variant="light",
        :buttonText="cancelBtnText",
        type="submit",
        @click="cancelBtnClicked")
      n-button(
        variant="primary",
        :buttonText="proceedBtnText",
        type="submit",
        @click="proceedBtnClicked",
        @keydown.enter="proceedBtnClicked")
</template>

<script>
import NButton from "@/components/NovaButton.vue";

export default {
  name: "SyncWarningModal",
  components: {
    NButton,
  },
  props: {
    title: {
      type: String,
      default: "Hey There!",
    },
    description: {
      type: String,
      default: "This is a baisc modal!",
    },
    cancelBtnText: {
      type: String,
      default: "Cancel",
    },
    proceedBtnText: {
      type: String,
      default: "Continue",
    },
  },
  methods: {
    proceedBtnClicked() {
      this.$emit("proceedBtnClicked");
    },
    cancelBtnClicked() {
      this.$emit("cancelBtnClicked");
    },
  },
};
</script>
