<template lang="pug">
step-modal(
  v-if="stepItems.length"
  :id="id",
  :stepItems="stepItems",
  :isCdTransactionsModal="isCdTransactionsModal",
  v-bind:model="model",
  @update:model="updateModel",
  @step-changed="handleStepChange",
  @submit="handleSubmit",
  :data-cy="dataCy",
)
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";
import blitz from "blitzllama-js";
import resDefs from "../../orgAdmin/definitions";
import adminDefs from "../../admin/definitions";
import { cdPaymentMethods, policyTypes } from "../../admin/constants";
import { TransactionStatus, TransactionType, FileGroupId, FileAction } from "../../../../common/enums";
import { fromGlobalId } from "../../../../utils";
import StepModal from "./StepModal.vue";
import utils, { validateAmount } from "@/utils";

export default {
  name: "EndorsementStepModalWrapper",
  components: {
    StepModal,
  },
  props: {
    policiesWithPremium: {
      type: Array,
      default: () => [],
    },
    id: {
      type: String,
      default: "",
    },
    totalChanges: {
      type: Number,
      default: 0,
    },
    modalVariant: {
      type: String,
      default: "",
    },
    changesData: {
      type: Array,
      default: () => [],
    },
    obPendingCount: {
      type: Number,
      default: 0,
    },
    dateOfInterest: {
      type: String,
      default: "",
    },
    unapprovedData: {
      type: Object,
      default: () => {
        return {
          add: 0,
          update: 0,
          delete: 0,
        };
      },
    },
    currentBatchId: {
      type: String,
      default: "",
    },
    insurers: {
      type: Array,
      default: () => [],
    },
    policies: {
      type: Array,
      default: () => [],
    },
    insurerPolicyMap: {
      type: Object,
      default: () => {},
    },
    showDepositSteps: {
      type: Boolean,
      default: false,
    },
    showReviewModal: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      default: () => [],
    },
    isSelectedBatchOverdue: {
      type: Boolean,
      default: false,
    },
    dataCy: {
      type: String,
      default: "",
    },
    isCdTransactionsModal: {
      type: Boolean,
      default: false,
    },
    orgData: {
      type: Object,
      default: () => {},
    },
    insurerDetails: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    const resName = "userChanges";
    return {
      resDef: resDefs[resName],
      orgId: this.$route.params.orgId ?? this.$store.state.user.org.id,
      allSelected: {
        selectiveApproval: [],
        reviewChangesModal: [],
      },
      indeterminate: {
        selectiveApproval: [],
        reviewChangesModal: [],
      },
      model: {},
      selectedPolicyIds: [],
      disableCTAFlag: false,
      selectiveApprovalConstants: {
        header: "Approving Changes",
        title: "Please select a policy to approve changes",
        isTogglable: true,
        isContinueCTADisabled: false,
        accordionData: [],
        noteText: [],
      },
      reviewChangesModalConstants: {
        header: "Approving Changes",
        subTitle: "These changes will be forwarded to Nova to be processed on " + this.dateOfInterest,
        isTogglable: true,
        isContinueCTADisabled: false,
        accordionData: [],
        noteText: [],
      },
      policyStepConstants: {
        header: "Policy Premium Details",
        accordionData: [],
        allSelected: [],
        indeterminate: [],
        ref: "policyStepFormData",
      },
      insurerStepConstants: {
        header: "CD Balance Details",
        accordionData: [],
        allSelected: [],
        indeterminate: [],
        ref: "cdUpdateFormData",
      },
      cdTransactionSteps: [
        {
          header: "CD Transactions Details",
          subHeading: "Transaction Details",
          schema: {
            fields: [
              {
                model: "amountPaid",
                type: "inline-input",
                label: "Amount Paid",
                fieldClasses: ["form-select"],
                imageIcon: "rupee",
                inputType: "number",
                step: "0.01",
                required: true,
                styleClasses: "m-0 p-0",
                validator: ["required", validateAmount],
                min: 1,
              },
              {
                model: "paymentMethod",
                type: "select",
                values: cdPaymentMethods,
                fieldClasses: ["form-select"],
                label: "Payment Methods",
                required: true,
                styleClasses: "m-0 p-0 mb-3",
                validator: ["required"],
              },
              {
                model: "utrNumber",
                type: "inline-input",
                label: "Transaction Reference Number",
                fieldClasses: ["form-select"],
                placeholder: "Enter UTR Number",
                required: true,
                styleClasses: "m-0 p-0",
                validator: ["required"],
              },
              {
                model: "transactionDate",
                type: "datepicker",
                label: "Transaction Date",
                fieldClasses: ["form-select"],
                min: "1900-01-01",
                max: moment().format("YYYY-MM-DD"),
                required: true,
                styleClasses: "m-0 p-0",
                validator: ["required"],
              },
            ],
          },
          ref: "cdTransactionsRef",
        },
        {
          header: "CD Transactions Details",
          subHeading: "Attach Payment Receipt",
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["selectedOrgEntity", "isOrgEntityAdmin", "user"]),
    orgEntityId() {
      if (this.isOrgEntityAdmin) return this.$store.state.user?.orgEntity?.id;

      if (this.selectedOrgEntity?.id) return this.selectedOrgEntity?.id;

      return null;
    },
    filteredSelectedPolicyIds() {
      return this.selectedPolicyIds?.filter((policyId) => !this.policiesWithPremium?.includes(policyId));
    },
    tabName() {
      return this.$route.params.tabName;
    },
    updatedUnapprovedData() {
      return {
        ...this.unapprovedData,
        invite: this.obPendingCount,
        add: this.unapprovedData.add - this.obPendingCount,
      };
    },
    benefitIds() {
      return this.changesData.flatMap((change) =>
        change.user_changes.filter((change) => !change.isPolicy).map((change) => change.benefit_id)
      );
    },
    policyIds() {
      const policyIds = [];
      for (const row of this.changesData) {
        for (const change of row.user_changes) {
          if (change.isPolicy && !policyIds.includes(change.benefit_id)) {
            policyIds.push(change.benefit_id);
          }
        }
      }
      return policyIds;
    },
    selectedInsurers() {
      return this.insurers.filter((insurer) => {
        return Object.entries(this.insurerPolicyMap).some(
          ([policyId, insurerId]) => insurerId === insurer.id && this.filteredSelectedPolicyIds?.includes(policyId)
        );
      });
    },
    expiredPoliciesInSelection() {
      const activePolicies = this.policies?.map((policy) => policy.id);
      return this.policyIds?.filter((policyId) => !activePolicies?.includes(policyId));
    },
    insurerPolicyPremiumMap() {
      const insurerPolicyPremiumMapObject = {};

      Object.entries(this.insurerPolicyMap).forEach(([policyId, mappedInsurerId]) => {
        if (!(mappedInsurerId in insurerPolicyPremiumMapObject)) {
          insurerPolicyPremiumMapObject[mappedInsurerId] = {};
        }
        if (this.model?.premiumForm?.[policyId])
          insurerPolicyPremiumMapObject[mappedInsurerId][policyId] = utils.deepClone(this.model.premiumForm[policyId]);
      });

      return insurerPolicyPremiumMapObject;
    },
    stepItems() {
      const items = [];
      if (["org-ok", "nova-ok", "provider-ok"].includes(this.$route.params.tabName)) {
        this.populateSelectivePolicyStepConstants();
        items.push({
          ...this.selectiveApprovalConstants,
          isContinueCTADisabled: !(this.atleast1Selected("selectiveApproval") || this.changesData?.length),
        });
      }
      if (this.showReviewModal) {
        items.push({
          ...this.reviewChangesModalConstants,
          isContinueCTADisabled: !this.atleast1Selected("reviewChangesModal"),
        });
      }
      if (this.showDepositSteps && this.policies.length && this.insurers.length) {
        // Policy Step
        if (this.filteredSelectedPolicyIds?.length) {
          this.populatePolicyStepConstants();
          items.push(this.policyStepConstants);
        }

        // Insurer Step
        if (this.selectedInsurers?.length) {
          this.populateInsurerStepConstants();
          items.push({ ...this.insurerStepConstants, isContinueCTADisabled: this.disableCTAFlag });
        }
      }
      if (this.isCdTransactionsModal) {
        items.push(...this.cdTransactionSteps);
      }
      return items;
    },
    getTotalChanges() {
      let total = 0;
      Object.keys(this.unapprovedData).forEach((key) => {
        total += this.unapprovedData[key] || 0;
      });
      return total;
    },
  },
  watch: {
    changesData() {
      this.removeStaleData();
      this.segregateChanges();
    },
    modalVariant() {
      this.allSelected.reviewChangesModal = this.allSelected.reviewChangesModal.map((item) => (item ? false : item));
      if (this.showReviewModal) {
        this.populateReviewChangesConstants();
      }
    },
    "allSelected.reviewChangesModal": {
      handler: function (updatedSelections) {
        this.reviewChangesModalConstants.accordionData.forEach((change, index) => {
          if (updatedSelections[index] === true) {
            change.isSelected = true;
          } else {
            change.isSelected = false;
          }
        });
      },
      deep: true,
    },
    "allSelected.selectiveApproval": {
      handler() {
        this.selectedPolicyIds = this.selectiveApprovalConstants?.accordionData
          ?.filter((policy, index) => policy.id && this.allSelected.selectiveApproval[index])
          ?.map((policy) => policy.id);
      },
      deep: true,
    },
  },
  created() {
    this.$options.policySelectionVarieties = {
      POLICY_SELECTION: "policy-selection",
      POLICY_PREMIUM: "policy-premium",
    };
    this.$options.obPendingNoteText = "Employees will not be able to add dependents once the changes are approved!";
    this.$options.defaultRejectionNote = "Changes for expired benefits would automatically be rejected.";
    if (this.showReviewModal) {
      this.populateReviewChangesConstants();
    }
  },
  methods: {
    atleast1Selected(modalName) {
      const allSelected = this.allSelected[modalName];
      const indeterminate = this.indeterminate[modalName];

      for (let i = 0; i < allSelected.length; i++) {
        if (allSelected[i] ^ indeterminate[i]) {
          return true;
        }
      }

      return false;
    },
    getFileType() {
      let type = null;
      const fileType = this.FILE.type || this.FILE.meta?.type;
      type = fileType.split("/")[0];
      if (type === "application") {
        type = fileType.split("/")[1] === "pdf" ? "pdf" : "zip";
      }
      return type;
    },
    addNoteText(variable, noteText) {
      const index = variable.noteText.findIndex((note) => note === noteText);
      if (index !== -1) {
        variable.noteText.splice(index, 1, noteText);
      } else {
        variable.noteText.push(noteText);
      }
    },
    async sendRemindersToUsers() {
      if (!this.obPendingCount) return;
      await this.$apollo.mutate({
        mutation: adminDefs.users.sendOnboardingInvites,
        variables: {
          inviteBy: "orgId",
          orgId: this.orgId,
          batchId: this.currentBatchId,
          invitationType: "ACTIVATE_COVERAGE",
          isSelectedBatchOverdue: this.isSelectedBatchOverdue,
          obPendingFlag: true,
        },
      });
      this.$store.commit("addToast", {
        variant: "success",
        message: "Successfully sent reminders to the selected users",
      });
    },
    populateReviewChangesConstants() {
      this.reviewChangesModalConstants.allSelected = this.allSelected.reviewChangesModal;
      this.reviewChangesModalConstants.indeterminate = this.indeterminate.reviewChangesModal;
      this.reviewChangesModalConstants.title =
        "You're about to approve " + this.getTotalChanges + " changes for endorsement";
      const data = [
        {
          id: "invite",
          type: "invite",
          name: "Additions: Onboarding Pending",
          style: "bg-mustard-100",
          isSelected: false,
          variant: "warning",
          dataCy: "invite-checkbox",
        },
        {
          id: "add",
          type: "add",
          name: "Additions",
          style: "bg-teal-100",
          isSelected: false,
          variant: "success",
          dataCy: "add-checkbox",
        },
        {
          id: "delete",
          type: "delete",
          name: "Deletions",
          style: "bg-red-100",
          isSelected: false,
          variant: "danger",
          dataCy: "delete-checkbox",
        },
        {
          id: "update",
          type: "update",
          name: "Updates",
          style: "bg-gray-100",
          isSelected: false,
          variant: "secondary",
          dataCy: "update-checkbox",
        },
      ];
      if (this.tabName === "unapproved") {
        if (this.modalVariant === "reminder") {
          this.reviewChangesModalConstants.header = "Reminders";
          this.reviewChangesModalConstants.submitBtnText = "Send Reminders ->";
          this.reviewChangesModalConstants.accordionData = this.reviewChangesModalConstants.accordionData.filter(
            (item) => item.id === "invite"
          );
          const index = this.reviewChangesModalConstants.accordionData.findIndex((item) => item.id === "invite");
          const item = data.find((e) => e.id === "invite");
          if (index !== -1) {
            this.reviewChangesModalConstants.accordionData.splice(index, 1, item);
          } else {
            this.reviewChangesModalConstants.accordionData.push(item);
          }
        } else {
          this.reviewChangesModalConstants.header = "Approving Changes";
          delete this.reviewChangesModalConstants?.submitBtnText;
          data.forEach((item) => {
            var exists = this.reviewChangesModalConstants.accordionData.find(
              (existingItem) => existingItem.id === item.id
            );
            if (!exists) {
              this.reviewChangesModalConstants.accordionData.push(item);
            }
          });
        }
        if (this.updatedUnapprovedData.invite) {
          this.reviewChangesModalConstants.title = `Onboarding is still pending for ${this.obPendingCount} Employee${
            this.obPendingCount === 1 ? "" : "s"
          }`;

          if (this.modalVariant === "reminder") {
            this.reviewChangesModalConstants.subTitle =
              "Send them a reminder to verify or add any missing information to complete their onboarding.";
            this.reviewChangesModalConstants.noteText = [];
          } else if (this.obPendingCount > 0) {
            this.reviewChangesModalConstants.subTitle =
              "Approving these will make the changes ready to be sent for processing.";
            this.addNoteText(this.reviewChangesModalConstants, this.$options.obPendingNoteText);
          }
        }
      } else {
        this.addNoteText(this.reviewChangesModalConstants, this.$options.defaultRejectionNote);
        data.slice(1)?.forEach((item) => {
          var exists = this.reviewChangesModalConstants.accordionData.find(
            (existingItem) => existingItem.id === item.id
          );
          if (!exists) {
            this.reviewChangesModalConstants.accordionData.push(item);
          }
        });
      }
      this.reviewChangesModalConstants.accordionData = this.reviewChangesModalConstants.accordionData.map(
        (item, index) => {
          return { ...item, index: index };
        }
      );
      this.removeStaleData();
      this.segregateChanges();
    },
    getChangeIdsByBenefitId(benefitIds) {
      const changeIds = [];
      for (const row of this.changesData) {
        for (const change of row.user_changes) {
          if (benefitIds.includes(change.benefit_id)) {
            changeIds.push(change.id);
          }
        }
      }
      return changeIds;
    },
    getEligibleChangeAndPolicyIds() {
      const changeIds = [];
      if (this.benefitIds.length) {
        changeIds.push(...this.getChangeIdsByBenefitId(this.benefitIds));
      }
      const policyIds = this.selectiveApprovalConstants?.accordionData
        ?.filter((policy, index) => policy.id && this.allSelected.selectiveApproval[index])
        ?.map((policy) => policy.id);
      changeIds.push(...this.getChangeIdsByBenefitId(policyIds));
      return { changeIds, policyIds };
    },
    populateSelectivePolicyStepConstants() {
      this.addNoteText(this.selectiveApprovalConstants, this.$options.defaultRejectionNote);
      this.selectiveApprovalConstants.accordionData = utils.deepClone(
        this.selectiveApprovalConstants.accordionData.filter(
          (item) => this.policyIds.includes(item.policyId) || !item.policyId
        )
      );
      this.selectiveApprovalConstants.allSelected = this.allSelected.selectiveApproval;
      this.selectiveApprovalConstants.indeterminate = this.indeterminate.selectiveApproval;
      this.selectiveApprovalConstants.subTitle =
        "These changes will be forwarded to Nova to be processed on " + this.dateOfInterest;
      this.policies
        .filter((policy) => this.policyIds.includes(policy.id))
        .forEach((policy, policyIndex) => {
          const isSelected = this.allSelected.selectiveApproval[policyIndex] ?? false;
          const policyItem = {
            ...this.getPolicyStepSkeleton(this.$options.policySelectionVarieties.POLICY_SELECTION),
            isSelected,
          };
          policyItem.name = policy.name;
          policyItem.policyId = policy.id;
          policyItem.id = policy.id;
          const index = this.selectiveApprovalConstants.accordionData.findIndex((item) => item.policyId === policy.id);
          if (index !== -1) {
            this.selectiveApprovalConstants.accordionData.splice(index, 1, policyItem);
          } else {
            this.selectiveApprovalConstants.accordionData.push(policyItem);
          }
        });
      const allBenefitsItem = this.getAllBenefitsAccItem();
      const index = this.selectiveApprovalConstants.accordionData.findIndex((item) => !item.policyId);
      if (this.benefitIds.length) {
        allBenefitsItem.isSelected = true;
        this.allSelected.selectiveApproval[index] = true;
      }
      if (index !== -1) {
        this.selectiveApprovalConstants.accordionData.splice(index, 1, allBenefitsItem);
      } else {
        this.selectiveApprovalConstants.accordionData.push(allBenefitsItem);
      }
    },
    populatePolicyStepConstants() {
      this.policyStepConstants.accordionData = utils.deepClone(
        this.policyStepConstants.accordionData.filter((policy) => this.filteredSelectedPolicyIds?.includes(policy.id))
      );

      this.filteredSelectedPolicyIds?.forEach((policyId) => {
        const policy = this.policies.find((policy) => policy.id === policyId);
        const policyItem = this.getPolicyStepSkeleton(this.$options.policySelectionVarieties.POLICY_PREMIUM);
        policyItem.formItems.find((item) => item.id === "premium-details").schema.fields =
          this.getPolicySchemaFields(policy);
        policyItem.formItems
          .find((item) => item.id === "premium-details")
          .summaryContent.find((obj) => obj.id === "netAmount").amount = this.getNetAmount(policy.id);
        policyItem.name = policy.name;
        policyItem.policyId = policy.id;
        policyItem.id = policy.id;
        policyItem.icon = policyTypes[policy.type].icon;
        const index = this.policyStepConstants.accordionData.findIndex((item) => item.policyId === policy.id);
        if (index !== -1) {
          this.policyStepConstants.accordionData.splice(index, 1, policyItem);
        } else {
          this.policyStepConstants.accordionData.push(policyItem);
        }
      });
    },
    populateInsurerStepConstants() {
      this.insurerStepConstants.accordionData = utils.deepClone(
        this.insurerStepConstants.accordionData.filter((insurer) => {
          return Object.entries(this.insurerPolicyMap).some(
            ([policyId, insurerId]) => insurerId === insurer.id && this.filteredSelectedPolicyIds?.includes(policyId)
          );
        })
      );
      this.disableCTAFlag = false;
      delete this.insurerStepConstants?.subTitle;
      this.selectedInsurers?.forEach((insurer) => {
        const insurerItem = this.getInsurerStepSkeleton();
        insurerItem.formItems[1].schema.fields = this.getInsurerSchemaFields(insurer);
        insurerItem.name = insurer.name;
        insurerItem.insurerId = insurer.id;
        insurerItem.id = insurer.id;
        insurerItem.imageSrc = insurer.s3Url;
        const premiumForAddition = this.getTotalActualPremiumForInsurer(insurer.id, "Addition");
        const refundFromDeletion = this.getTotalActualPremiumForInsurer(insurer.id, "Deletion");
        insurerItem.formItems
          .find((item) => item.id === "total-premium")
          .summaryContent.find((obj) => obj.id === "premiumForAddition").amount = premiumForAddition;
        insurerItem.formItems
          .find((item) => item.id === "total-premium")
          .summaryContent.find((obj) => obj.id === "refundFromDeletion").amount = refundFromDeletion;
        insurerItem.formItems
          .find((item) => item.id === "total-premium")
          .summaryContent.find((obj) => obj.id === "netPremium").amount = refundFromDeletion - premiumForAddition;
        insurerItem.formItems
          .find((item) => item.id === "cd-details")
          .summaryContent.find((obj) => obj.id === "currentCdBalance").amount = insurer.cdBalanceData?.cdBalance || "-";
        const remainingCD =
          (this.model.insurerForm?.[insurer.id]?.deposit || 0) +
          (insurer.cdBalanceData?.cdBalance || 0) +
          refundFromDeletion -
          premiumForAddition;
        if (remainingCD < 0) {
          this.disableCTAFlag = true;
          this.insurerStepConstants.subTitle =
            "Remaining CD balance of all accounts must be greater than or equal to 0 at all times.";
        }
        insurerItem.formItems
          .find((item) => item.id === "cd-details")
          .summaryContent.find((obj) => obj.id === "remainingCdBalance").amount =
          (this.model.insurerForm?.[insurer.id]?.deposit || 0) +
          (insurer.cdBalanceData?.cdBalance || 0) +
          refundFromDeletion -
          premiumForAddition;
        const index = this.insurerStepConstants.accordionData.findIndex((item) => item.insurerId === insurer.id);
        if (index !== -1) {
          this.insurerStepConstants.accordionData.splice(index, 1, insurerItem);
        } else {
          this.insurerStepConstants.accordionData.push(insurerItem);
        }
      });
    },
    getTotalActualPremiumForInsurer(insurerId, premiumType) {
      let totalAmount = 0;
      switch (premiumType) {
        case "Addition": {
          this.model?.premiumForm &&
            Object.entries(this.model.premiumForm).forEach(([policyId, premiumData]) => {
              if (this.insurerPolicyMap[policyId] === insurerId) {
                totalAmount += premiumData.premiumForAddition;
              }
            });
          break;
        }
        case "Deletion": {
          this.model?.premiumForm &&
            Object.entries(this.model.premiumForm).forEach(([policyId, premiumData]) => {
              if (this.insurerPolicyMap[policyId] === insurerId) {
                totalAmount += premiumData.refundFromDeletion;
              }
            });
          break;
        }
        default: {
          totalAmount = 0;
        }
      }
      return totalAmount;
    },
    async addCdTransactionDetails(data) {
      try {
        const result = await this.$apollo.mutate({
          mutation: adminDefs.files.upsertMutation,
          variables: {
            file: data.file,
            action: FileAction.BankDetails,
          },
        });
        const documentFileId = result?.data?.upsertFile?.file?.id;
        await this.$apollo.mutate({
          mutation: adminDefs.files.upsertOrgGroupFileEdges,
          variables: {
            fileId: documentFileId,
            orgId: this.orgData.id,
            orgEntityId: this.orgEntityId || null,
            fileGroupIds: [utils.toGlobalId("FileGroup", FileGroupId.ENDORSEMENT)],
            meta: { isDocInternal: true },
          },
        });
        await this.$apollo.mutate({
          mutation: adminDefs.transactions.upsertTransaction,
          variables: {
            insurerId: fromGlobalId(this.insurerDetails?.id)?.id,
            accountId: this.insurerDetails?.accountId,
            orgId: this.orgData.id,
            orgEntityId: this.orgEntityId || null,
            status: TransactionStatus.COMPLETED,
            notes: "Added through CD Accounts Page",
            transactionType: TransactionType.CREDIT,
            transactionDate: data.transactionDate,
            amount: data.amountPaid,
            utr: data?.utrNumber,
            paymentMethod: data?.paymentMethod,
            fileId: fromGlobalId(documentFileId)?.id,
          },
        });
        //shown only for success
        this.$bvModal.show(`${this.id}-success`);
        setTimeout(() => this.$bvModal.hide(`${this.id}-success`), 3000);
      } catch (error) {
        console.log(error);
      }
    },

    async handleSubmit(data) {
      if (this.isCdTransactionsModal) {
        await this.addCdTransactionDetails(data);
        this.$emit("refreshCdAccountsPage");
        this.$bvModal.hide(this.id);
        this.model = {};
        return;
      }
      if (this.showReviewModal) {
        if (this.tabName === "unapproved") {
          if (this.modalVariant === "reminder") {
            const inviteFlag = this.reviewChangesModalConstants.accordionData.filter(
              (change) => change.type === "invite" && change.isSelected
            ).length;
            if (inviteFlag) await this.sendRemindersToUsers();
          } else {
            const selections = this.reviewChangesModalConstants?.accordionData
              ?.filter((change) => this.allSelected.reviewChangesModal[change.index])
              ?.map((change) => change.type);
            if (selections.some((item) => ["invite", "add", "update"].includes(item))) {
              this.$emit("checkCDBalance");
            }
            if (["invite", "add", "update", "delete"].some((item) => selections.includes(item))) {
              let types;
              if (selections.includes("invite")) types = ["add"];
              await this.updateUserChangeStatus(types);
            }
          }
        } else {
          await this.updateUserChangeStatus();
        }
      } else {
        this.$emit("submit", { ...this.getEligibleChangeAndPolicyIds(), allBenefits: !!this.benefitIds.length });
      }
      this.$emit("refreshTable");
      this.$emit("resetFloatingBar");
      this.removeStaleData();
    },
    async addTransaction(variables) {
      let result;
      try {
        result = await this.$apollo.mutate({
          mutation: adminDefs.userChangeBatches.upsertEndorsementBatchTrx,
          variables: {
            ...variables,
            orgId: this.orgId,
            batchId: this.currentBatchId,
          },
        });
      } catch (err) {
        console.error(err);
      }
      if (result?.data?.upsertEndorsementBatchTrx?.success) {
        this.$store.commit("addToast", {
          variant: "success",
          message: "Successfully added transaction records",
        });
      } else {
        this.$store.commit("addToast", {
          variant: "danger",
          message: "Something went wrong while adding the transaction record",
        });
      }
      return result?.data?.upsertEndorsementBatchTrx?.success;
    },
    getPolicySchemaFields(policy) {
      return [
        {
          model: `premiumForm.${policy.id}.premiumForAddition`,
          type: "inline-input",
          inputType: "number",
          label: `Estimated Premium for additions (A)<span style="color: red">*</span>`,
          step: "0.01",
          noPadding: true,
          required: true,
          validator: ["required"],
          imageIcon: "rupee",
          labelClasses: "mb-2 pb-1",
          allowZero: true,
          hint: `${
            policy?.premiumData?.premiumAddition
              ? "Estimated premium additions amount to a total of ₹" + policy?.premiumData?.premiumAddition.toFixed(4)
              : "Estimated premium additions amount does't exist for this item."
          }`,
        },
        {
          model: `premiumForm.${policy.id}.refundFromDeletion`,
          type: "inline-input",
          inputType: "number",
          label: `Refund from deletions (B)<span style="color: red">*</span>`,
          step: "0.01",
          noPadding: true,
          required: true,
          validator: ["required"],
          imageIcon: "rupee",
          labelClasses: "mb-2 pb-1",
          allowZero: true,
          hint: `${
            policy?.premiumData?.premiumDeduction
              ? "Estimated premium deductions amount to a total of ₹" + policy?.premiumData?.premiumDeduction.toFixed(4)
              : "Estimated premium deductions amount doesn't exist for this item."
          }`,
        },
      ];
    },
    getAllBenefitsAccItem() {
      return {
        name: "All Benefits",
        style: "bg-blue-100",
        isDisabled: !this.benefitIds.length,
      };
    },
    getPolicyStepSkeleton(type) {
      switch (type) {
        case this.$options.policySelectionVarieties.POLICY_SELECTION: {
          return {
            imageSrc: "",
            name: "",
            policyId: "",
            style: "bg-blue-100",
          };
        }
        case this.$options.policySelectionVarieties.POLICY_PREMIUM: {
          return {
            imageSrc: "",
            name: "",
            policyId: "",
            style: "bg-blue-100",
            formItems: [
              {
                id: "premium-details",
                schema: {
                  fields: [],
                },
                header: "Premium Details",
                summaryContent: [
                  {
                    id: "netAmount",
                    icon: "rupee",
                    text: "Net Amount (A-B)",
                    amount: 0,
                  },
                ],
              },
            ],
          };
        }
      }
    },
    getInsurerSchemaFields(insurer) {
      return [
        {
          model: `insurerForm.${insurer.id}.deposit`,
          type: "inline-input",
          inputType: "number",
          label: "Amount Deposited (D)",
          step: "0.01",
          noPadding: true,
          imageIcon: "rupee",
          labelClasses: "mb-2 pb-1",
          allowZero: true,
        },
        {
          model: `insurerForm.${insurer.id}.trxDate`,
          type: "datepicker",
          label: "Transaction Date",
          min: "1900-01-01",
          max: moment().format("YYYY-MM-DD"),
          labelClasses: "mb-2 pb-1",
        },
      ];
    },
    getInsurerStepSkeleton() {
      const sampleItem = {
        imageSrc: "",
        name: "",
        insurerId: "",
        style: "bg-blue-100",
        formItems: [
          {
            id: "total-premium",
            header: "Total Premium",
            summaryContent: [
              {
                id: "premiumForAddition",
                icon: "arrow",
                text: "Total Premium for Additions (A)",
                amount: "-",
                iconClasses: [`rotated-anti-90`],
                addSign: true,
                sign: "negative",
              },
              {
                id: "refundFromDeletion",
                icon: "arrow",
                text: "Total Refund from Deletions (B)",
                amount: "-",
                iconClasses: [`rotated-90`],
                addSign: true,
                sign: "positive",
              },
              {
                id: "netPremium",
                icon: "rupee",
                text: "Total Net Amount (A+B=C)",
                amount: "-",
              },
            ],
          },
          {
            id: "recent-deposit",
            schema: {
              fields: [],
            },
            header: "Recent Deposits to CD Account",
          },
          {
            id: "cd-details",
            header: "CD Account Details",
            summaryContent: [
              {
                id: "currentCdBalance",
                icon: "money",
                text: "Current CD Balance (E)",
                amount: "-",
              },
              {
                id: "remainingCdBalance",
                icon: "money",
                text: "Remaining CD Balance (C+D+E)",
                amount: "-",
              },
            ],
          },
        ],
      };
      return sampleItem;
    },
    updateModel(model) {
      this.model = model;
    },
    handleStepChange(currentStep) {
      if (this.isCdTransactionsModal) {
        return;
      }
      if (!this.stepItems[currentStep - 1].allSelected.length) {
        this.stepItems[currentStep - 1].allSelected = this.stepItems[currentStep - 1].accordionData.map(() => false);
      }
      if (!this.stepItems[currentStep - 1].indeterminate.length) {
        this.stepItems[currentStep - 1].indeterminate = this.stepItems[currentStep - 1].accordionData.map(() => false);
      }
    },
    segregateChanges() {
      this.reviewChangesModalConstants.accordionData.forEach((change) => {
        if (this.tabName === "unapproved") {
          if (this.modalVariant === "reminder") {
            change.isSelected = true;
            this.allSelected.reviewChangesModal[change.index] = true;
          } else {
            if (this.updatedUnapprovedData[change.type] && change.type !== "invite") {
              change.isSelected = true;
              this.allSelected.reviewChangesModal[change.index] = true;
            }
          }
          change.countString =
            this.updatedUnapprovedData[change.type] +
            ` change${this.updatedUnapprovedData[change.type] > 1 ? "s" : ""}`;
          change.isDisabled = !this.updatedUnapprovedData[change.type];
          if (change.type === "add") {
            change.name = "Additions: Onboarding Complete";
          }
        } else {
          if (this.unapprovedData[change.type]) {
            change.isSelected = true;
            this.allSelected.reviewChangesModal[change.index] = true;
          }
          change.countString =
            this.unapprovedData[change.type] + ` change${this.unapprovedData[change.type] > 1 ? "s" : ""}`;
          change.isDisabled = !this.unapprovedData[change.type];
        }
      });
    },
    removeStaleData() {
      this.reviewChangesModalConstants.accordionData.forEach((change) => {
        change.isSelected = false;
      });
      this.model = {};
    },
    getNetAmount(policyId) {
      return (
        (this.model.premiumForm?.[policyId]?.premiumForAddition || 0) -
        (this.model.premiumForm?.[policyId]?.refundFromDeletion || 0)
      );
    },
    async approveUserChanges(currentStatus, newStatus, userChangeTypes) {
      if (currentStatus === "draft" && newStatus === "org-ok") {
        blitz.triggerEvent("send_to_ready_for_nova");
      }
      let allBenefits = false;
      const policyIds = [];
      policyIds.push(
        ...this.selectiveApprovalConstants.accordionData
          .filter((policy) => policy.id && policy.isSelected)
          .map((policy) => policy.id)
      );
      if (this.benefitIds.length) {
        allBenefits = true;
      }

      let addOnboardingCompletedUsers = false;
      let addOnboardingPendingUsers = false;
      if (this.tabName === "unapproved") {
        if (
          this.allSelected.reviewChangesModal[
            this.reviewChangesModalConstants?.accordionData.findIndex((item) => item.type === "add")
          ]
        ) {
          addOnboardingCompletedUsers = true;
        }
        if (
          this.allSelected.reviewChangesModal[
            this.reviewChangesModalConstants?.accordionData.findIndex((item) => item.type === "invite")
          ]
        ) {
          addOnboardingPendingUsers = true;
        }
      }

      let insurerWiseDeposit = {};
      if (this.showDepositSteps && this.filteredSelectedPolicyIds?.length && this.selectedInsurers?.length) {
        insurerWiseDeposit = this.model.insurerForm ? utils.deepClone(this.model.insurerForm) : {};
        this.selectedInsurers?.forEach((insurer) => {
          insurerWiseDeposit[insurer.id] = {
            ...insurerWiseDeposit[insurer.id],
            policiesPremiumData: this.insurerPolicyPremiumMap?.[insurer.id],
          };
        });
      }

      return this.$apollo.mutate({
        mutation: this.resDef.updateUserChangeStatus,
        variables: {
          orgId: this.orgId,
          batchId: this.currentBatchId,
          currentStatus,
          newStatus,
          userChangeTypes,
          onboardingFilters: {
            addOnboardingCompletedUsers,
            addOnboardingPendingUsers,
          },
          policyIds: [...new Set(policyIds)],
          allBenefits: allBenefits,
          insurerWiseDeposit,
        },
      });
    },
    async updateUserChangeStatus(types) {
      let userChangeTypes = [];
      userChangeTypes = this.reviewChangesModalConstants.accordionData
        .filter((change) => change.isSelected)
        .map((change) => change.type);
      if (types) userChangeTypes = [...new Set([...userChangeTypes, ...types])];
      window.posthog.capture("endorsements_approve_changes", {
        source: "bulk_selection_action",
        number_of_employees: userChangeTypes?.reduce((count, type) => count + this.unapprovedData[type], 0),
        day_of_month: new Date().getDate(),
        org_name: this.user?.org?.name,
        email: this.user.email,
      });
      try {
        let currentStatus = "draft";
        let newStatus = "org-ok";
        const tabName = this.$route.params.tabName;
        if (["org-ok", "nova-ok", "provider-ok"].includes(tabName)) {
          currentStatus = tabName;
          if (tabName === "org-ok") {
            newStatus = "nova-ok";
          } else if (tabName === "nova-ok") {
            newStatus = "provider-ok";
          } else {
            newStatus = "done";
          }
        }
        const result = await this.approveUserChanges(currentStatus, newStatus, userChangeTypes);
        if (!result?.data?.updateUserChangeStatus?.success) {
          this.$store.commit("addToast", {
            variant: "danger",
            message: result?.data?.updateUserChangeStatus?.errorMessage,
          });
        }
        if (newStatus === "org-ok") {
          this.$emit("submitted-to-org-ok");
        }
        if (newStatus === "nova-ok") this.$emit("insurer-submission-updated");
      } catch (err) {
        console.error(err);
        this.$store.commit("addToast", {
          variant: "danger",
          message: "Something went wrong while approving the selected changes",
        });
      }
    },
  },
};
</script>
