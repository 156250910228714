<template lang="pug">
.fixed-bottom.z-1000.w-100.py-3.bg-white
  .container.d-flex.align-items-center.justify-content-between
    .d-none.d-md-flex.justify-content-end.align-items-center
      template
        n-button.py-2.px-4(
          v-if="showBackButton",
          variant="outline-success",
          buttonText="<- Go Back",
          type="submit",
          :disabled="false",
          @click="$emit('goBack')")
    .d-flex
      .align-items-center
        slot(name="footer-content")
    .d-flex
      n-button.py-2.px-3(
        :variant="variant",
        :buttonText="isMobileView ? 'Confirm' : buttonText",
        type="submit",
        :rightImageIcon="isMobileView ? 'chevron-right' : buttonText",
        :disabled="isButtonDisabled",
        @click="$emit('continueFlow')")
</template>

<script>
import NButton from "../../../components/NovaButton.vue";
import utils from "@/utils";
export default {
  name: "BaseFooter",
  components: {
    NButton,
  },
  props: {
    variant: {
      type: String,
      required: true,
    },
    buttonText: {
      type: String,
      required: true,
    },
    isButtonDisabled: {
      type: Boolean,
      default: false,
    },
    showBackButton: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isMobileView() {
      return utils.mobileCheck();
    },
  },
};
</script>
