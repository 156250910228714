import gql from "graphql-tag";
import { fileGroupFragment } from "../fragments";

export default {
  upsertMutation: gql`
    mutation ($id: ID, $name: String, $meta: JSONObject) {
      upsertFileGroup(input: { id: $id, name: $name, meta: $meta }) {
        fileGroup {
          ...FileGroup
        }
      }
    }
    ${fileGroupFragment}
  `,
  deleteMutation: gql`
    mutation deleteFileGroup($id: ID!) {
      deleteFileGroup(input: { id: $id }) {
        success
      }
    }
  `,
};
