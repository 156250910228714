export const SUPER_TOPUP_STATUS_STATES = {
  captured: {
    icon: "check",
    iconColor: "teal-700",
    heading: "Your payment was successful!",
    bodyText1: "Your policy will be activated within the next couple of days.",
    bodyText2: "You'll receive a confirmation email with all the details shortly.",
    buttonText: "Go To Dashboard ->",
    redirectTo: "/dashboard",
  },
  failed: {
    icon: "error",
    iconColor: "red-600",
    heading: "Oops! Payment Unsuccessful",
    bodyText1:
      "It looks like there was an issue processing your payment. Please review your payment information and try again.",
    buttonText: "Go To Dashboard ->",
    redirectTo: "/dashboard",
  },
  redirectUrlAfterTimeout: "/super-topups/policy-summary",
};

export const CHECKUP_PACKAGE_STATUS_STATES = {
  captured: {
    pending: {
      imagePath: require(`@/assets/images/hourglass.svg`),
      heading: "Your booking request is being processed",
      bodyText1: "",
      bodyText2:
        "We're trying to confirm your booking request.You'll receive a confirmation email with all the details once the booking is confirmed",
      buttonText: "View Booking",
      redirectTo: "/checkup/booking-history",
    },
    completed: {
      imagePath:  require(`@/assets/images/message.svg`),
      heading: "Your booking request has been received!",
      bodyText1: "",
      bodyText2:
        "You'll receive a confirmation email with all the details once the booking is accepted by our lab partner.",
      buttonText: "View Booking",
      redirectTo: "/checkup/booking-history",
    },
  },
  failed: {
    icon: "error",
    iconColor: "red-600",
    heading: "Oops! Payment Unsuccessful",
    bodyText1:
      "It looks like there was an issue processing your payment. Please review your payment information and try again.",
    buttonText: "Try again",
    redirectTo: "/checkup/schedule-booking",
  },
  redirectUrlAfterTimeout: "/checkup/schedule-booking",
};
