<template lang="pug">
.user-changes
  .tabs-container.justify-content-start.align-items-center.py-3(v-if="getTabValues.length", :class="isShrinkTabs ? 'px-2' : 'px-4'")
    .tab-heading.d-inline-block.cursor-pointer.font-md.font-weight-semibold.text-center.text-gray-800(
      v-for="tab in getTabValues.filter((tab) => !tab.hide)",
      @click="activeTab = tab.value",
      :key="tab.value",
      :class="[{'active-tab': activeTab === tab.value}, isShrinkTabs ? 'px-2' : 'px-3']"
    )
      span.d-inline-flex.flex-row.align-items-center.align-middle(:class="isShrinkTabs ? 'mr-1' : 'mr-2'") {{tab.title}}
      n-chip(:variant="activeTab === tab.value ? 'primary' : 'secondary'", pill) {{getCountForTab(tab.value)}}
  .banner-container
    banner(
      v-if="totalCount",
      ref="banner",
      :variant="activeTab",
      :title="bannerData.bannerTitle",
      :subtext="bannerData.bannerSubtext",
      :icon="bannerData.bannerIcon",
      :buttonText="bannerData.buttonText",
      rightImageIcon="chevron-right",
      buttonTextSecondary="",
      :selectedBatchStatus="selectedBatchStatus",
      :insurer-specific="insurerSpecific",
      rightImageIconSecondary="swap-horizontal",
      :iconColor="bannerData.iconColor",
      :bannerBackground="bannerData.bannerBackground",
      :exportFlag="bannerData.exportFlag",
      :employeeCount="getMissingEmployeeCount",
      :orgId="orgId",
      :currentTab="activeTab",
      :selectedBatchId="getSelectedBatchId",
      :items="items",
      :draft-changes-count="draftChangesCount"
      :total-changes="totalCount",
      :unapproved-data="unapprovedData",
      :ob-pending-count="obPendingCount",
      :modalName="bannerData.modalName",
      :showDepositSteps="bannerData.showDepositSteps",
      :dateOfInterest="dateOfInterest",
      :modelNameSecondary="'transfer-batch-modal-for-batch'",
      :exportButtonVariant="bannerData.exportButtonVariant",
      :is-selected-batch-overdue="isSelectedBatchOverdue",
      :policiesWithPremium="policiesWithPremium",
      @refreshTable="$emit('row-updated')",
      @open-confirmation-modal="$emit('open-confirmation-modal', $event)",
      @resetFloatingBar="$emit('resetFloatingBar')",
      :batches="batches",
      @update-batch="$emit('update-batch', $event)",
      :showSecondaryButton="!$route.path.includes(`org-admin/`)",
      :buttonDisabledSecondary="buttonDisabledSecondary",
      :insurers="insurers",
      :policies="policies",
      :insurerPolicyMap="insurerPolicyMap",
      :currentOrg="currentOrg",
      :shortFallAmount="shortFallAmount",
      :bufferAmount="bufferAmount",
      :batch="batch",
      @submitted-to-org-ok="$emit('submitted-to-org-ok')"
    )
</template>

<script>
import Banner from "../../endorsements/components/Banner.vue";
import { UserChangeBatchStages } from "../../../../common/enums/userChangeBatchStages.enum";
import NChip from "@/components/NovaChip.vue";

export default {
  components: {
    NChip,
    Banner,
  },
  props: {
    insurers: {
      type: Array,
      default: () => [],
    },
    draftChangesCount: {
      type: Number,
      default: 0,
    },
    policies: {
      type: Array,
      default: () => [],
    },
    insurerPolicyMap: {
      type: Object,
      default: () => {},
    },
    tabs: {
      type: Object,
      default: () => {},
    },
    tabsCount: {
      type: Object,
      default: () => {},
    },
    defaultActiveTab: {
      // TODO: Default to 0th index of first tab.
      type: String,
    },
    orgId: {
      type: String,
    },
    selectedBatchId: {
      type: String,
    },

    selectedBatchStatus: {
      type: String,
    },
    insurerSpecific: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      default: () => [],
      required: true,
    },
    dateOfInterest: {
      type: String,
      default: "",
    },
    bannerData: {
      type: Object,
    },
    isSelectedBatchOverdue: {
      type: Boolean,
      default: false,
    },
    totalCount: {
      type: Number,
      default: 0,
    },
    unapprovedData: {
      type: Object,
      default: () => {},
    },
    obPendingCount: {
      type: Number,
      default: 0,
    },
    batches: {
      type: Array,
      default: () => [],
    },
    buttonDisabledSecondary: {
      type: Boolean,
      default: false,
    },
    policiesWithPremium: {
      type: Array,
      default: () => [],
    },
    currentOrg: {
      type: Object,
      default: () => {},
    },
    batch: {
      type: Object,
      default: null,
    },
    shortFallAmount: {
      type: Number,
      default: 0,
    },
    bufferAmount: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      activeTab: this.defaultActiveTab,
    };
  },
  computed: {
    getMissingEmployeeCount() {
      return this.getTabValues[0]?.count || 0;
    },
    getSelectedBatchId() {
      return this.$route.params.batchId || this.selectedBatchId;
    },
    isShrinkTabs() {
      return this.getTabValues?.length > 6;
    },
    getTabValues() {
      return Object.values(this.tabs);
    },
    isSuperAdmin() {
      return this.$route.path.includes("/admin");
    },
  },
  watch: {
    activeTab() {
      this.$emit("tab-changed", this.activeTab);
    },
  },
  methods: {
    getCountForTab(tab) {
      if ([UserChangeBatchStages.APPROVED, UserChangeBatchStages.ORG_OK].includes(tab) && !this.isSuperAdmin) {
        return (
          (this.tabsCount[UserChangeBatchStages.ORG_OK] || 0) +
          (this.tabsCount[UserChangeBatchStages.NOVA_OK] || 0) +
          (this.tabsCount[UserChangeBatchStages.PROVIDER_OK] || 0) +
          (this.tabsCount[UserChangeBatchStages.DONE] || 0)
        );
      }
      if (!this.tabsCount[tab]) {
        return 0;
      }
      return this.tabsCount[tab];
    },
    openModal() {
      this.$refs.banner.openModal();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
@import "@/assets/styles/_typography.scss";

.tabs-container {
  background-color: $white;
  border: $border-width-custom-1 solid $gray-300;
  align-items: flex-end !important;
  .tab-heading {
    padding-top: 0.875rem;
    padding-bottom: 0.875rem;
  }
}
.active-tab {
  background: $blue-100;
  color: $blue-600;
  border-radius: 0.5rem;
}
</style>
