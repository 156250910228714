export const FileAction = {
  OnboardOrg: "onboardOrg",
  ImportECards: "importECards",
  FetchECards: "fetchECards",
  ClaimsBulkUpdate: "claimsBulkUpdate",
  BulkUpload: "bulkUpload",
  BulkDelete: "bulkDelete",
  PremiumDetails: "premiumDetails",
  EndorsementDoc: "endorsementDocuments",
  InsurerImage: "insurerImage",
  TpaImage: "tpaImage",
  PolicyDocUpload: "policyDocUpload",
  SetUpOrgAndPolicies: "setupOrgAndPolicies",
  FileUpload: "fileUpload",
  InsurerTpaHandbook: "insurerTpaHandbook",
  BenefitsBulkAddition: "benefitsBulkAddition",
  OrgPropBrandLogo: "orgPropBrandLogo",
  OrgPropBrandImage: "orgPropBrandImage",
  OrgPropBrandImageMobile: "orgPropBrandImageMobile",
  OrgPropEcardPrimaryHeader: "orgPropEcardPrimaryHeader",
  OrgPropEcardDependentHeader: "orgPropEcardDependentHeader",
  PolicyDataCheck: "policyDataCheck",
  BankDetails: "bankDetails",
  EchoPostTemplateImage: "echoPostTemplateImage",
  CustomBannerImage: "customBannerImage",
  OrgDocUpload: "orgDocUpload",
};
