import { getListQuery, getSingleQuery } from "../queries";
import { DesignationSchema } from "../schemas/designations";
import designationMutations from "../mutations/designations";
export default {
  gqlType: "Designation",
  fields: ["name", "createdAt", "updatedAt"],
  searchPlaceholder: "Search by designation name",
  singleQuery: getSingleQuery("Designation"),
  listQuery: getListQuery("Designation"),
  ...DesignationSchema,
  ...designationMutations,

  transform: (n) => {
    return { ...n };
  },
};
