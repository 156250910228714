import { getListQuery, getSingleQuery } from "../queries";
import { CXPodSchema } from "../schemas/CXEntities";
import CXPodMutations from "../mutations/CXPods";

export default {
  gqlType: "CXPod",
  fields: ["name"],
  singleQuery: getSingleQuery("CXPod"),
  listQuery: getListQuery("CXPod"),
  ...CXPodSchema,
  ...CXPodMutations,
  transform: (n, write = false) => {
    return { ...n };
  },
};
