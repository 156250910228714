<template lang="pug">
.review-changes-modal-endorsement
  b-modal#review-changes-modal(
    :id="id",
    hide-header-close,
    size="md",
    dialog-class="review-changes-modal-dialog",
    cancel-variant="light",
    :scrollable="true"
  )
    template(v-slot:modal-title)
      .text-gray-900.font-weight-semibold Approving changes
    .text-center.mx-5.px-5
      .h4 You're about to approve {{ getTotalChanges }} changes for endorsement
      .text-gray-700.font-weight-medium.font-sm These changes will be forwarded to your insurer to be processed on
        |
        | {{ dateOfInterest }}
    .my-4
      div(v-for="(change, index) in reviewData")
        .rounded-top(
          :class="change.style",
          v-b-toggle="`${change.type}-${index}`"
        )
          .py-4
            .d-flex.flex-row.justify-content-between
              .d-flex.font-weight-medium
                b-form-checkbox.ml-3.endors-main-category-checkbox(
                  v-model="allSelected[index]",
                  @change="toggleAll($event, index)",
                  :indeterminate.sync="indeterminate[index]",
                  :disabled="unapprovedData[change.type] === 0"
                ) {{ change.name }}
              .d-flex.text-gray-700.font-weight-medium
                div {{ unapprovedData[change.type] }} changes
                i.mx-3
        b-collapse(
          :id="`${change.type}-${index}`",
          :accordion="`changes-accordion-${index}`",
          role="tabpanel"
        )
        hr.my-2(v-if="index < reviewData.length-1").border-gray-400
    template(v-slot:modal-footer)
      n-button(
        buttonText="Cancel",
        variant="light",
        @click="$bvModal.hide('review-changes-modal')"
      )
      n-button(
        buttonText="Approve all changes",
        imageIcon="check-circle",
        @click="approveAllChanges",
        variant="primary",
        :disabled="!atleast1Selected"
      )
</template>

<script>
import { mapGetters } from "vuex";

import blitz from "blitzllama-js";
import resDefs from "../../orgAdmin/definitions";
import NButton from "@/components/NovaButton.vue";
import NChip from "@/components/NovaChip.vue";
import NTable from "@/components/NovaTable.vue";
import NAvatar from "@/components/Avatar.vue";
import utils from "@/utils";

export default {
  name: "ReviewChangesModal",
  components: {
    NButton,
    NTable,
    NChip,
    NAvatar,
  },
  props: {
    totalChanges: {
      type: Number,
      default: 0,
    },
    changesData: {
      type: Array,
      default: () => [],
    },
    dateOfInterest: {
      type: String,
      default: "",
    },
    unapprovedData: {
      type: Object,
      default: () => {
        return {
          add: 0,
          update: 0,
          delete: 0,
        };
      },
    },
    currentBatchId: {
      type: String,
      default: "",
    },
    orgId: {
      type: String,
      default: "",
    },
  },
  data() {
    const resName = "userChanges";
    return {
      id: "review-changes-modal",
      status: "",
      resDef: resDefs[resName],
      popoversData: resDefs[resName].popovers,
      reviewData: [
        {
          type: "add",
          index: 0,
          name: "Additions",
          style: "bg-teal-100",
          isSelected: false,
          changes: [],
          variant: "success",
        },
        {
          type: "delete",
          index: 1,
          name: "Deletions",
          style: "bg-red-100",
          isSelected: false,
          changes: [],
          variant: "danger",
        },
        {
          type: "update",
          index: 2,
          name: "Updates",
          style: "bg-gray-100",
          isSelected: false,
          changes: [],
          variant: "secondary",
        },
      ],
      allSelected: [],
      indeterminate: [],
      selected: [],
      mainCheckBoxStatus: [{ status: "" }, { status: "" }, { status: "" }],
      fields: [
        {
          key: "selected",
          label: "",
          tdClass: ["align-middle", "checkbox-width"],
        },
        {
          key: "avatar",
          label: "",
          tdClass: ["align-middle", "checkbox-width"],
        },
        { key: "name", label: "Employee Name", tdClass: "align-middle" },
      ],
      visible: [false, false, false],
      allUserChanges: null,
    };
  },
  computed: {
    ...mapGetters(["user"]),
    nextMonthDetails() {
      return utils.getNextMonthDetails();
    },
    atleast1Selected() {
      const result =
        this.allSelected[0] ^ this.indeterminate[0] ||
        this.allSelected[1] ^ this.indeterminate[1] ||
        this.allSelected[2] ^ this.indeterminate[2];
      return Boolean(result);
    },
    getTotalChanges() {
      let total = 0;
      Object.keys(this.unapprovedData).forEach((key) => {
        total += this.unapprovedData[key] || 0;
      });
      return total;
    },
  },
  watch: {
    changesData() {
      this.removeStaleData();
      this.segregateChanges();
    },
  },
  created() {
    this.segregateChanges();
  },
  methods: {
    isDepRow(data) {
      return utils.isDependentRow(data);
    },
    statusToIcon(status) {
      return utils.statusToIconDetails(status);
    },
    getCategoryCheckBoxState(index) {
      if (this.reviewData[index].changes.length === 0) return "disabled";
      else {
        if (this.reviewData[index].changes.every((change) => change.selected)) return "all";
        else return "indeterminate";
      }
    },
    toggleAll(checked, index) {
      this.reviewData[index].isSelected = checked;
    },
    refreshCheckBoxState(index) {
      const allSelected = this.reviewData[index].changes.every((change) => change.selected);
      if (allSelected) {
        this.indeterminate.splice(index, 1, false);
        this.allSelected.splice(index, 1, true);
      } else {
        if (this.reviewData[index].changes.every((change) => !change.selected)) {
          this.indeterminate.splice(index, 1, false);
          this.allSelected.splice(index, 1, false);
        } else {
          this.indeterminate.splice(index, 1, true);
          this.allSelected.splice(index, 1, false);
        }
      }
    },
    getDataChangeString(data) {
      return utils.getUserChangeStringForUpdate(data);
    },
    async updateUserChangeStatus(currentStatus, newStatus, userChangeTypes) {
      // add policyIds, allBenefits for benefit wise approval
      return this.$apollo.mutate({
        mutation: this.resDef.updateUserChangeStatus,
        variables: {
          orgId: this.orgId,
          batchId: this.currentBatchId,
          currentStatus,
          newStatus,
          userChangeTypes,
        },
      });
    },
    async approveAllChanges() {
      const userChangeTypes = this.reviewData.filter((change) => change.isSelected).map((change) => change.type);
      window.posthog.capture("endorsements_approve_changes", {
        source: "bulk_selection_action",
        number_of_employees: userChangeTypes?.reduce((count, type) => count + this.unapprovedData[type], 0),
        day_of_month: new Date().getDate(),
        org_name: this.user?.org?.name,
        email: this.user.email,
      });
      try {
        let currentStatus = "draft";
        let newStatus = "org-ok";
        const tabName = this.$route.params.tabName;
        if (["org-ok", "nova-ok"].includes(tabName)) {
          currentStatus = tabName;
          if (tabName === "org-ok") {
            newStatus = "nova-ok";
          } else {
            newStatus = "provider-ok";
          }
        }
        if (currentStatus === "draft" && newStatus === "org-ok") {
          blitz.triggerEvent("send_to_ready_for_nova");
        }
        const result = await this.updateUserChangeStatus(currentStatus, newStatus, userChangeTypes);
        if (!result?.data?.updateUserChangeStatus?.success) {
          this.$store.commit("addToast", {
            variant: "danger",
            message: result?.data?.updateUserChangeStatus?.errorMessage,
          });
        }
        if (newStatus === "nova-ok") this.$emit("insurer-submission-updated");
      } catch (err) {
        console.error(err);
      }
      this.$emit("refreshTable");
      this.$emit("resetFloatingBar");
      this.removeStaleData();
      this.$bvModal.hide("review-changes-modal");
    },
    segregateChanges() {
      this.reviewData.forEach((change) => {
        if (this.unapprovedData[change.type]) {
          change.isSelected = true;
          this.allSelected[change.index] = true;
        }
      });
    },
    removeStaleData() {
      this.reviewData.forEach((change) => {
        change.isSelected = false;
      });
    },
    isTypeAddOrDelete(data) {
      return data.item.type === "add" || data.item.type === "delete";
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/_variables.scss";
.review-changes-modal-dialog {
  max-width: 564px !important;
}

.endors-main-category-checkbox {
  .custom-control-input:checked ~ .custom-control-label::before,
  .custom-control-input:indeterminate ~ .custom-control-label::before {
    color: #fff;
    border-color: $blackpearl;
    background-color: $blackpearl;
  }
}

.review-changes-modal-checkbox > input.custom-control-input {
  min-height: 0 !important;
}
.review-changes-modal-table {
  .nova-table-wrapper {
    border: none !important;
  }
}
.checkbox-width {
  max-width: 20px;
}
</style>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
@import "@/assets/styles/mixins/_breakpoints.scss";

.bg-teal-100 {
  background: $teal-100;
}
.bg-red-100 {
  background: $red-100;
}

.bg-gray-100 {
  background: $gray-100;
}

.border-gray-400 {
  border-color: $gray-400;
}
.modal-avatar-sm {
  width: 24px;
  height: 24px;
  background: $white;
}
</style>
