<template lang="pug">
.row
  .col-12
    .plan-card-container.rounded-top-none.rounded-bottom-none.px-4.py-5
      .row
        .col-md-7.col-sm-12
          span.hb6.text-gray-600.mt-2 INSURER
          .d-flex.align-items-center.pt-2.mb-2
            .insurer-img-styles
              img.mw-100(style="margin-top:-5px;", :src="insurerImageLink")
            h4.hb4.m-0.text-gray-900.ml-3  {{ insurerName }}
          .plan-card-container.rounded-top-none.rounded-bottom-none.px-4.py-3.mt-2
            .row.no-gutters
              span.hb5.text-gray-800.mt-2.mb-1 {{ displayActualPremium ? "Premium" : "Estimated premium" }} for policies covered
              template(v-for="policy in policies")
                .col-sm-8.my-3
                  div
                    b-avatar.bg-stone.mr-1(size="2rem", variant="none")
                      i.ic-large.align-sub.font-lg(:class="[`icon-${policyTypes[policy.type].icon}`, `text-${policyTypes[policy.type].color}`]")
                    span.hb5 {{ policyTypes[policy.type].tagline }}
                  span.h5.ml-4.text-gray-600 {{ getSingularOrPlural("Employee", Number(policy.users)) }}
                  span.h5.text-gray-600(v-if="policy.dependents")
                    i.icon-disabled-stage.font-xs.mx-1
                    | {{ getSingularOrPlural("Dependent", Number(policy.dependents)) }}
                .my-auto.ml-auto.text-right
                  span.hb5 {{ getUpdatedPremiumAmount(policy.premiumAddition - policy.premiumDeduction) }}
        
        .col-md-5.col-sm-12
          .plan-card-container.rounded-top-none.rounded-bottom-none
            .pb-1(:class="getPremiumBreakupColorClass(true)")
              template(v-for="amountType in amountDetailsType")
                .pt-3.pb-2.px-3
                  span.font-weight-semibold.hb5.mt-2 {{ amountType }}
                  .mt-2
                    template(v-for="amount in getSetOfAmounts(amountType)")
                      .row.no-gutters
                        .col.mt-2.mb-2
                          .text-gray-800
                            span.align-sub.d-inline-flex
                              i.mr-1(:class="[`icon-${amountTypes[amount].icon}`, getCSSClass(amount)]")
                            span.hb5 {{ amountTypes[amount].text }}
                        .my-auto.ml-auto.text-right
                          span.hb5(:class="`text-${getAmountColor(amount)}`")
                            template(v-if="!(amount==='totalDeduction' && isPaid)") {{ amountTypes[amount].symbol }}
                            | {{ getUpdatedPremiumAmount(amountsObject[amount]) }}
                .w-100.col-12.px-3.pt-3.pb-2(:class="amountType==='Premium Details'&& (isRefundCase() || displayActualPremium) ? ['bg-teal-200'] : ['d-none']")
                  template(v-if="!displayActualPremium")
                    div.pt-1.ml-0.mb-2.d-flex.position-relative
                      .font-weight-semibold.font-md 💸 A refund!&nbsp
                    p.font-sm.text-gray-800
                      span A refund of 
                      span.font-weight-semibold {{ getFormattedINRWithFloatingPoint(amountsObject["totalDeduction"], true)?.slice(1) }}
                      | &nbsp;will be credited to your account.
                  template.mt-2(v-else-if="isBatchPremiumPaid")
                    div.pt-1.ml-0.mb-2.d-flex.position-relative
                      .font-weight-semibold.font-md Premium Paid&nbsp
                      img(:src="require(`@/assets/emojis/white-check-mark.png`)", height="16", width="16")
                    p.font-sm.text-gray-800
                      span.font-weight-semibold You have paid your premium amount for this batch.
                      p.font-sm.text-gray-800(v-if="!displayActualPremium")
                        | Kindly maintain a CD Balance of {{ getFormattedINRWithFloatingPoint(minBalance, true) }} in your account as a buffer for future emergency inclusions. Maintaining a healthy CD Balance will help in seamless Claims and Endorsements experience!  
            .w-100.col-12.px-3.pt-3.pb-2(:class="getPremiumBreakupColorClass()")
              template(v-if="displayActualPremium")
                template(v-if="isCDBalanceHealthy")
                  div.pt-1.ml-0.mb-2.d-flex.position-relative
                    img(
                        :src="require(`@/assets/emojis/wave.svg`)", height="16", width="16")
                    .font-weight-semibold.font-md &nbsp Hi there!
                  p.font-sm
                    span Your CD Balance looks healthy and it meets our recommended buffer of&nbsp;
                    span.font-weight-semibold {{ getUpdatedPremiumAmount(minBalance) }}.
                template(v-else)
                  div.pt-1.ml-0.mb-2.d-flex.position-relative
                    .font-weight-semibold.font-md Your CD balance is low&nbsp
                    img(:src="require(`@/assets/emojis/disappointed-face.png`)", height="16", width="16")
                  p.font-sm.text-gray-800
                    span We recommend to maintain a CD Balance of&nbsp;
                    span.font-weight-semibold {{ getUpdatedPremiumAmount(minBalance) }} 
                    | in your account as a buffer for future emergency inclusions.
                    | Maintaining a healthy CD Balance will help in seamless Claims and Endorsements experience!
                  p.font-sm.text-gray-800
                    span Kindly add&nbsp;
                    span.font-weight-semibold {{ getUpdatedPremiumAmount(Math.abs(minBalance - currentCDBalance)) }} 
                    | in your CD account as a buffer for future emergency inclusions.
              template(v-else)
                template(v-if="getRemainingCDBalance")
                  div.pt-1.ml-0.mb-2.d-flex.position-relative
                    img(
                        :src="require(`@/assets/emojis/wave.svg`)", height="16", width="16")
                    .font-weight-semibold.font-md &nbsp Hi there!
                  p.font-sm
                    span Kindly maintain a CD Balance of&nbsp;
                    span.font-weight-semibold {{ getFormattedINRWithFloatingPoint(minBalance, true) }}&nbsp
                    | in your account as a buffer for future emergency inclusions. Maintaining a healthy CD Balance will help in seamless
                    | Claims and Endorsements experience!
                template(v-else)
                  div.pt-1.ml-0.mb-2.d-flex.position-relative
                    .font-weight-semibold.font-md Your CD balance is running short&nbsp
                    img(:src="require(`@/assets/emojis/disappointed-face.png`)", height="16", width="16")
                  p.font-sm.text-gray-800
                    span.font-weight-semibold Please add the shortfall amount of {{ getFormattedINRWithFloatingPoint(getShortfallAmount, true) }} 
                    | to continue processing these endorsements.
                  p.font-sm.text-gray-800
                    span In addition to the shortfall amount, we recommend you to add an additional amount of 
                    span.font-weight-semibold {{ getFormattedINRWithFloatingPoint(minBalance, true) }} 
                    | for future&nbsp
                    | emergency inclusions, depositing a total of 
                    span.font-weight-semibold {{ getFormattedINRWithFloatingPoint(minBalance + getShortfallAmount, true) }} 
                    | in your CD amount.
                    n-button.mt-3(buttonText="View CD Details", variant="light", rightImageIcon="chevron-right", @click="handleViewCdDetails")
</template>

<script>
import { policyTypes } from "../../admin/constants";
import NButton from "../../../../components/NovaButton.vue";
import utils from "@/utils";

export default {
  name: "PremiumBreakup",
  components: {
    NButton,
  },
  props: {
    insurerName: {
      type: String,
      default: null,
    },
    insurerLogo: {
      type: String,
      default: null,
    },
    policies: {
      type: Array,
      default: () => [],
    },
    premiumAddition: {
      type: Number,
      default: 0,
    },
    premiumDeduction: {
      type: Number,
      default: 0,
    },
    currentCDBalance: {
      type: Number,
      default: 0,
    },
    minBalance: {
      type: Number,
      default: 0,
    },
    isPaid: {
      type: Boolean,
      default: false,
    },
    isEndorsementPremiumPresent: {
      type: Boolean,
      default: false,
    },
    displayActualPremium: {
      type: Boolean,
      default: false,
    },
    isBatchCompleted: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const amountTypes = {
      premiumAddition: {
        icon: "arrow",
        text: this.displayActualPremium ? "Premium for Additions (A)" : "Estimated Premium for Additions (A)",
        amountColor: "blackpearl",
      },
      premiumDeduction: {
        icon: "arrow",
        text: "Refund from deletions (B)",
        amountColor: "gray-900",
      },
      totalDeduction: {
        icon: "rupee",
        text: null,
        amountColor: "blackpearl",
      },
      currentCDBalance: {
        icon: "money",
        text: "Current CD balance",
      },
      shortfallAmount: {
        icon: "money",
        text: "Shortfall amount",
      },
      remainingBalance: {
        icon: "money",
        text: "Remaining CD balance after deduction",
      },
    };
    const amountDetailsType = [
      "Premium Details",
      ...(!this.isPaid || this.displayActualPremium ? ["CD Account Details"] : []),
    ];
    return {
      policyTypes,
      amountTypes,
      amountsObject: {},
      amountDetailsType,
      getSingularOrPlural: utils.getSingularOrPlural,
    };
  },
  computed: {
    getTotalDeductions() {
      return Number(this.premiumAddition - this.premiumDeduction);
    },
    getShortfallAmount() {
      return this.getTotalDeductions > this.currentCDBalance ? this.getTotalDeductions - this.currentCDBalance : 0;
    },
    getRemainingCDBalance() {
      return this.currentCDBalance > this.getTotalDeductions ? this.currentCDBalance - this.getTotalDeductions : 0;
    },
    insurerImageLink() {
      return this.insurerLogo;
    },
    isBatchPremiumPaid() {
      return this.isPaid || this.displayActualPremium;
    },
    isCDBalanceHealthy() {
      return this.currentCDBalance >= this.minBalance;
    },
  },
  created() {
    this.amountsObject = {
      premiumAddition: this.premiumAddition,
      premiumDeduction: this.premiumDeduction,
      totalDeduction: this.getTotalDeductions,
      currentCDBalance: this.currentCDBalance,
      shortfallAmount: this.getShortfallAmount,
      remainingBalance: this.getRemainingCDBalance,
    };
    this.setTotalPremiumText();
  },
  methods: {
    getUpdatedPremiumAmount(amount) {
      if (this.isBatchCompleted && !this.isEndorsementPremiumPresent) {
        return "N/A";
      }
      return this.getFormattedINRWithFloatingPoint(amount, true);
    },
    setTotalPremiumText() {
      this.amountTypes.totalDeduction.text = this.isBatchPremiumPaid ? "Total premium amount paid" : "Net Amount (A-B)";
    },
    handleViewCdDetails() {
      this.$router.push({ path: "/org-admin/cd-accounts" });
    },
    getAmountColor(amount) {
      if (this.isRefundCase() && amount === "totalDeduction") return "teal-700";
      else if (amount === "remainingBalance" && this.getRemainingCDBalance < this.minBalance) return "mustard-700";
      else if (this.amountTypes[amount].amountColor) return this.amountTypes[amount].amountColor;
      else if (!this.getRemainingCDBalance && this.checkShortfallAmount(amount)) return "red-600";
      return "gray-900";
    },
    getFormattedINRWithFloatingPoint(value, withRupeeSym) {
      return utils.getFormattedINRWithFloatingPoint(value, withRupeeSym);
    },
    checkShortfallAmount(amountType) {
      return amountType === "currentCDBalance" || amountType === "shortfallAmount";
    },
    getSetOfAmounts(amountType) {
      if (amountType === "Premium Details") {
        return Object.keys(this.amountsObject).slice(0, 3);
      } else {
        if (this.displayActualPremium) {
          return [Object.keys(this.amountsObject).slice(-3)[0]];
        } else {
          return Object.keys(this.amountsObject).slice(-3);
        }
      }
    },
    isRefundCase() {
      return this.premiumDeduction - this.premiumAddition > 0;
    },
    checkIfIconRotationRequired(amountType) {
      return amountType === "premiumAddition" || amountType === "premiumDeduction";
    },
    getIconDirectionClass(amountType) {
      return amountType === "premiumDeduction" ? "rotated-90" : "rotated-anti-90";
    },
    getCSSClass(amountType) {
      if (!this.getRemainingCDBalance && this.checkShortfallAmount(amountType)) return "text-red-600";
      else if (this.checkIfIconRotationRequired(amountType)) return this.getIconDirectionClass(amountType);
      else return "";
    },
    getPremiumBreakupColorClass(isTransparent = false) {
      if (this.isPaid) {
        return isTransparent ? "transparent-teal" : "bg-teal-100";
      }
      if (this.displayActualPremium && !isTransparent) {
        return this.isCDBalanceHealthy ? "bg-teal-100" : "bg-mustard-100";
      }
      if (this.getRemainingCDBalance) {
        return isTransparent ? "transparent-purple" : "bg-purple-100";
      }
      return isTransparent ? "transparent-red" : "bg-red-100";
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
@import "@/assets/styles/mixins/_breakpoints.scss";
@import "@/assets/styles/_misc.scss";

.plan-card-container {
  height: auto !important;
}
.transparent-red {
  background-color: rgba(255, 240, 240, 0.2);
}
.transparent-purple {
  background-color: rgba(245, 242, 255, 0.2);
}
.transparent-teal {
  background-color: rgba(236, 253, 252, 0.2);
}
.rotated-90 {
  transform: rotate(90deg);
}
.rotated-anti-90 {
  transform: rotate(-90deg);
}
.insurer-img-styles {
  flex: 1;
  max-width: 3.125rem;
  overflow: hidden;
  img {
    max-width: 100%;
    height: auto;
    object-fit: contain;
  }
}
</style>
