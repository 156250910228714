<template lang="pug">
div
  .col-md-4.mt-2
    b-button.btn-block(
      variant="primary",
      type="submit",
      :disabled="status==='loading'")
      span(v-if="status==='loading'")
        b-spinner(small)
        span.sr-only Loading...
      span(v-else) {{ buttonMessage }}
  b-modal#modal-1(size="sm", hide-footer, hide-header, centered)
    .text-center
      template(v-if="status==='success'")
        i.text-success.fa.fa-5x.fa-check-circle
        p.my-1 {{ onSuccess }}
      template(v-if="status==='failure'")
        i.text-danger.fa.fa-5x.fa-times-circle
        p.my-1 {{ onFailure }}
      b-button(size="sm", @click="hideModal") Dismiss
</template>

<script>
export default {
  props: {
    status: {
      type: String,
      default: null,
    },
    butttonText: {
      type: String,
      default: "Submit",
    },
    onSuccess: {
      type: String,
      default: "Submitted successfully!",
    },
    onFailure: {
      type: String,
      default: "Oops there was some error..",
    },
  },
  data() {
    return {
      buttonMessage: this.butttonText,
    };
  },
  watch: {
    status: function () {
      if (this.status === "success" || this.status === "failure") {
        this.$bvModal.show("modal-1");
        this.buttonMessage = "Submit Again";
      }
    },
  },
  methods: {
    hideModal() {
      this.$bvModal.hide("modal-1");
    },
  },
};
</script>
