<template lang="pug">
div.w-100
  .hb5.text-gray-700 Add your family members on your insurance policy
  dependent-details-wrapper.py-3(
    v-if="arePoliciesLoaded",
    :dependentsModel="dependentsModel",
    :policies="policies",
    :coverPOrPil="coverPOrPil",
    @clicked="showData")

  b-modal(id="dependent-modal", centered, no-close-on-backdrop, hide-footer, body-class="p-0 h-75", @hidden="selectedDependent={}")
      template(v-slot:modal-header)
        h5.hb5.mx-auto {{ !isUpdateModal ? "Adding Dependents" :"Updating Dependents" }}
        n-icon.cursor-pointer(name="cross", variant="gray-600",  @click="$bvModal.hide('dependent-modal')")
      dependent-modal.h-100(
        v-if="arePoliciesLoaded"
        :user="user",
        :userState="userState",
        :selectedDependent="selectedDependent",
        :dependentsList="dependentsModel",
        :policies="policies",
        :isUpdateModal="isUpdateModal",
        @pPilCoverage="updateCoveredPOrPil",
        @modelUpdated="saveDependents",
        @deleteDependent="deleteDependent",
        ref="dependentModal")

  special-modal(
    id="dependent-verification-modal",
    headerIcon="icon",
    :bgColor="'mustard-200'",
    :iconColor="'mustard-600'",
    logoIcon="warning",
    centered)
    template(v-slot:title)
      h3.hb3.d-inline.font-weight-semibold.text-gray-900(v-if="areNoDependentsAdded") No Dependent added!
      h3.hb3.d-inline.font-weight-semibold.text-gray-900(v-else) Add more Dependents
    template(v-slot:modal-content)
      .text-center(v-if="areNoDependentsAdded")
        | Your health insurance will not cover your
        br
        | dependents, if you don't add them now.
      .text-center.px-5(v-else)
        | Your health insurance covers your {{ dependentContent }}
        | Sure you don't want to add them?
    template(v-slot:buttons-area)
      .d-flex.align-items-center.mt-3.mb-2
        n-button.px-3(variant="dark", button-text="Add Dependents", @click="$bvModal.hide('dependent-verification-modal')")
        n-button.px-5.ml-2(variant="light", button-text="Skip ->", @click="skipAddition")
</template>

<script>
import DependentDetailsWrapper from "../components/DependentDetailsWrapper.vue";
import DependentModal from "../components/DependentModal.vue";
import mixin from "../mixin";
import SpecialModal from "../../../../../components/SpecialModal.vue";
import { AcceptedRelations } from "../../../../../common/enums";
import { DEPENDENT_META_FIELDS } from "../constants";
import { isSamePerson, extractMetaFields } from "./../utils";
import utils from "@/utils";
export default {
  name: "AddDependentsDetailsRight",
  components: {
    DependentDetailsWrapper,
    DependentModal,
    SpecialModal,
  },
  mixins: [mixin],
  async beforeRouteLeave(to, from, next) {
    let depNameWithMissingDetails = this.getDepNameWithMissingData()
    if (depNameWithMissingDetails) {
        this.$store.commit("addToast", {
          variant: "danger",
          message: `Details for dependent with name: ${depNameWithMissingDetails} are missing. Please add them before proceeding`,
        });
        return;
      }
    if (!this.skipVerification) {
      const shouldVerify = await this.verifyDependentCount();
      if (shouldVerify && to.name === "review") {
        this.$bvModal.show("dependent-verification-modal");
        return;
      }
    }
    const transformedDependents = this.updatedDependents.map((dep) => {
      const { id, tempId, name, dob, gender, relation, meta, isMidTerm } = dep;
      const extractedMeta = extractMetaFields(meta, DEPENDENT_META_FIELDS);
      return { id, tempId, name, dob, gender, relation, meta: extractedMeta, isMidTerm };
    });

    this.$store.commit("getStarted/setDependentDetails", transformedDependents);
    this.handleRouteChange(to, from, next);
  },
  data() {
    return {
      skipVerification: false,
    };
  },
  computed: {
    isUpdateModal() {
      if (this.selectedDependent.tempId || this.selectedDependent.id) {
        return true;
      }
      return false;
    },
    areNoDependentsAdded() {
      return this.areDependentsCovered && this.dependentsModel.length === 0;
    },
  },
  watch: {
    dependentsModel() {
      if (this.depObj.pOrILFlag) {
        const pAndPilCount = {
          parent: 0,
          "parent-in-law": 0,
        };
        this.dependentsModel.forEach((dep) => {
          if (dep.relation === AcceptedRelations.PARENT) pAndPilCount.parent++;
          if (dep.relation === AcceptedRelations.PARENT_IN_LAW) pAndPilCount["parent-in-law"]++;
        });
        this.user.dependents = this.dependentsModel.map((dep) => {
          if (dep.relation.includes(AcceptedRelations.PARENT)) {
            if (this.depObj.pOrILFlag && pAndPilCount[`parent${dep.relation === "parent" ? "-in-law" : ""}`] === 0) {
              dep.meta.isPreferred = true;
            }
            if (dep.relation !== this.coverPOrPil) {
              this.$store.commit("getStarted/setSessionProps", {
                coverPOrPil: dep.relation,
              });
            }
          }
          return dep;
        });
      }
    },
  },
  mounted() {
    this.skipVerification = false;
    this.updatedDependents = utils.deepClone(this.dependentDetails);
  },
  methods: {
    async saveDependents(updatedDeps) {
      this.$store.commit("clearToasts");
      window.posthog.capture("emp_signup_save_dependent_click", {
        employee_email: this.user?.email,
        org_name: this.user?.org.name,
        dependent_count: updatedDeps.length,
      });
      try {
        this.avoidExtractingDependents = true;
        this.updatedDependents = [...updatedDeps];
        this.$store.commit("getStarted/setDependentDetails", this.updatedDependents);
        this.avoidExtractingDependents = false;
        this.$bvModal.hide("dependent-modal");
      } catch (err) {
        console.error(err);
        throw err;
      }
    },
    async deleteDependent({ dependent, coveredChildren }) {
      try {
        if (dependent.relation === AcceptedRelations.CHILD) {
          const index = coveredChildren.findIndex((child) => isSamePerson(child, dependent));
          if (index >= 0) coveredChildren.splice(index, 1);
        }
        const index = this.updatedDependents.findIndex((dep) => isSamePerson(dep, dependent));
        if (index >= 0) this.updatedDependents.splice(index, 1);
        this.$bvModal.hide("dependent-deletion-modal");
        this.$bvModal.hide("dependent-modal");
      } catch (err) {
        console.log(err);
      }
    },
    showData(dependent) {
      this.$bvModal.show("dependent-modal");
      this.selectedDependent = dependent;
    },
    async skipAddition() {
      this.skipVerification = true;
      this.$bvModal.hide("dependent-verification-modal");
      this.$store.commit("getStarted/setSkipDependentAddition", true);
      this.$router.push({ name: "review" });
    },
    async verifyDependentCount() {
      let depModel = this.dependentsModel;
      // Getting a count of the number of `children` added
      const childrenAdded = depModel.filter((dep) => dep.relation === AcceptedRelations.CHILD).length;
      depModel = [...new Set(depModel.map((dep) => dep.relation))];
      if (this.$store.state.getStarted.sessionProps.areDependentsCovered) {
        if (!depModel.length) return true;
        else {
          // Checking for the cases when either the `parent` or the `parent-in-law` is covered
          if (this.depObj.pOrILFlag) {
            // Checking if all covered dependent relations are present in the added set of relations
            // Also checking if the no. of `children` added >= no. of `children` covered
            // Finally checking if either `parent` or `parent-in-law` have been added
            return !(
              this.depObj.coveredDependents.every((element) => depModel.includes(element)) &&
              childrenAdded >= this.depObj.childrenCount &&
              (depModel.includes("parent") || depModel.includes("parent-in-law"))
            );
          }
          // If the previous condition was false
          // Checking if all covered relations are added, and
          // Checking if the no. of `children` added >= no. of `children` covered
          else {
            return !(
              this.depObj.coveredDependents.every((element) => depModel.includes(element)) &&
              childrenAdded >= this.depObj.childrenCount
            );
          }
        }
      }
      return false;
    },
  },
};
</script>

<style scoped>
/* Add your component styles here */
</style>
