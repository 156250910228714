import gql from "graphql-tag";
import { orgEntityFragment } from "../fragments";

export default {
  upsertMutation: gql`
    mutation upsertOrgEntity(
      $id: ID
      $orgId: ID!
      $name: String!
      $address: String!
      $pincode: String!
      $meta: JSONObject
      $status: String
    ) {
      upsertOrgEntity(
        input: {
          id: $id
          orgId: $orgId
          name: $name
          address: $address
          pincode: $pincode
          meta: $meta
          status: $status
        }
      ) {
        orgEntity {
          ...OrgEntity
        }
      }
    }
    ${orgEntityFragment}
  `,
  // TODO: Add export mutation in backend
  exportDataMutation: gql`
    mutation exportOrgEntityEmployeeData($orgEntityId: ID, $meta: JSONObject) {
      exportOrgEntityEmployeeData(input: { orgEntityId: $orgEntityId, meta: $meta }) {
        message
      }
    }
  `,
  resyncOrgOnboardingStageTasks: gql`
    mutation resyncOrgOnboardingStageTasks($orgId: ID!, $orgEntityId: ID) {
      resyncOrgOnboardingStageTasks(input: { orgId: $orgId, orgEntityId: $orgEntityId }) {
        success
      }
    }
  `,
};
