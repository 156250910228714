<template lang="pug">
n-chip.border.additional-benefits(:isMinimal="true") {{` ${chipText}`}}
  template(v-slot:icon)
    div +{{ remainingItemsCount }}
</template>

<script>
import NChip from "@/components/NovaChip.vue";
import NIcon from "@/components/NovaIcon.vue";

export default {
    name : "PlusNChip",
    components : {
      NChip,
      NIcon
    },
    props : {
      chipText : {
        required : true,
        default : "",
      },
      remainingItemsCount : {
        required : true,
        deafult : 1,
      },
    }
}

</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
@import "@/assets/styles/mixins/_breakpoints.scss";

.additional-benefits {
  font-size: 88%;
  padding-top: $btn-padding-y-xs;
  padding-bottom: $btn-padding-y-xs;

  div {
    padding-left: $btn-padding-y-xs;
    padding-right: $btn-padding-y-xs;
    margin-right: 4px;
    border: $border-width-custom-2 solid $purple-600;
    border-radius: $border-radius-pill;
    color: $purple-600;
  }
}

</style>