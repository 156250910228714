import { getIdSelectorQuery, getListQuery } from "../queries";

export const CXPodSchema = {
  schema: {
    fields: [
      {
        model: "name",
        label: "Name",
        type: "input",
        inputType: "text",
        required: true,
      },
      {
        model: "meta",
        type: "object",
        label: "Metadata",
        schema: {
          fields: [
            {
              type: "input",
              inputType: "text",
              label: "Slack Channel Name",
              model: "slackChannelName",
            },
            {
              type: "input",
              inputType: "number",
              label: "Weight",
              model: "weight",
            },
            {
              type: "array",
              label: "Segments",
              model: "segments",
            },
          ],
        },
      },
    ],
  },
};

/** Non CX entity members who need temp access to an org can be assigned the "Other" role. */
export const CXPodMemberRoles = ["CA", "EA", "OA", "SA", "RM", "KAM", "IRM", "CXPgM", "CXHead", "Other", "Wellness"];
export const CXPodMemberStatuses = ["ACTIVE", "INACTIVE"];

export const CXPodMemberSchema = {
  schema: {
    fields: [
      {
        model: "pod",
        type: "id-selector",
        queryName: "CXPods",
        gqlQuery: getListQuery("CXPod"),
        label: "CX Pod",
      },
      {
        model: "user",
        type: "id-selector",
        queryName: "users",
        gqlQuery: getIdSelectorQuery("User"),
        queryVariables: () => ({
          filter: {
            orgId: process.env.VUE_APP_NOVA_ORG_ID,
          },
        }),
        label: "User",
      },
      {
        model: "role",
        label: "Role",
        type: "select",
        values: CXPodMemberRoles,
        required: true,
      },
      {
        model: "status",
        label: "Status",
        type: "select",
        values: CXPodMemberStatuses,
        required: true,
      },
      {
        model: "meta",
        type: "object",
        label: "Metadata",
        schema: {
          fields: [
            {
              type: "input",
              inputType: "text",
              label: "Slack User Id",
              model: "slackUserId",
            },
            {
              type: "input",
              inputType: "number",
              label: "Weight",
              model: "weight",
            },
            {
              type: "input",
              inputType: "text",
              label: "Exotel Number",
              model: "exotelNumber",
            },
          ],
        },
      },
    ],
  },
  filterSchema: {
    fields: [
      {
        model: "pod",
        type: "id-selector",
        queryName: "CXPods",
        gqlQuery: getListQuery("CXPod"),
        label: "CX Pod",
      },
      {
        model: "role",
        type: "select",
        values: CXPodMemberRoles,
        label: "Role",
      },
      {
        model: "status",
        type: "select",
        values: CXPodMemberStatuses,
        label: "Status",
      },
    ],
  },
};

export const CXAccountOwnerSchema = {
  schema: {
    fields: [
      {
        model: "org",
        type: "id-selector",
        queryName: "orgs",
        gqlQuery: getIdSelectorQuery("Org"),
        label: "Org",
      },
      {
        model: "podMember",
        type: "id-selector",
        queryName: "CXPodMembers",
        gqlQuery: getListQuery("CXPodMember"),
        label: "Pod Member",
      },
      {
        model: "meta",
        type: "object",
        label: "Metadata",
        schema: {
          fields: [
            {
              model: "sendSlackUpdates",
              type: "checkbox",
              label: "Send Slack Updates",
            },
          ],
        },
      },
    ],
  },
  filterSchema: {
    fields: [
      {
        model: "org",
        type: "id-selector",
        queryName: "orgs",
        gqlQuery: getIdSelectorQuery("Org"),
        placeholder: "Organization filter (none)",
        label: "Account",
        showSelected: false,
      },
      {
        model: "role",
        type: "select",
        values: CXPodMemberRoles,
        label: "Role",
      },
      {
        model: "status",
        type: "select",
        values: CXPodMemberStatuses,
        label: "Status",
      },
    ],
  },
};

export const CXPodOnboardingSchema = {
  schema: {
    fields: [
      {
        model: "org",
        type: "id-selector",
        queryName: "orgs",
        gqlQuery: getIdSelectorQuery("Org"),
        label: "Org",
      },
      {
        model: "pod",
        type: "id-selector",
        queryName: "CXPods",
        gqlQuery: getListQuery("CXPod"),
        label: "CX Pod",
      },
    ],
  },
};
