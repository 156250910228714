import gql from "graphql-tag";
import { echoPostTemplateFragment } from "../fragments/echoPostTemplate";

export default {
  upsertMutation: gql`
    mutation upsertEchoPostTemplate(
      $id: ID
      $orgId: ID!
      $category: String
      $subject: String
      $defaultMessage: String
      $imageFileId: ID
      $logoFileId: ID
      $status: String
      $meta: JSONObject!
    ) {
      upsertEchoPostTemplate(
        input: {
          id: $id
          orgId: $orgId
          category: $category
          subject: $subject
          imageFileId: $imageFileId
          logoFileId: $logoFileId
          defaultMessage: $defaultMessage
          status: $status
          meta: $meta
        }
      ) {
        echoPostTemplate {
          ...EchoPostTemplate
        }
      }
    }
    ${echoPostTemplateFragment}
  `,
}
