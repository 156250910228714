export const UserChangeBatchStatus = {
  EMPTY: "empty",
  DRAFT: "draft",
  ORG_OK: "org-ok",
  NOVA_OK: "nova-ok",
  PROVIDER_OK: "provider-ok",
  DONE: "done",
  DELETED: "deleted",
  REJECTED: "rejected",
};

export const UserChangeBatchSteps = {
  APPROVED_BY_HR: "Approved by HR",
  REVIEWED_BY_NOVA: "Reviewed by Nova",
  SUBMITTED_TO_INSURER: "Submitted to Insurer",
  APPROVED_BY_INSURER: "Approved by Insurer",
  ENDORSEMENT_COMPLETED: "Endorsement Completed",
};
