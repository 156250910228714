import SelfDetailsRight from "./pages/SelfDetailsRight.vue";
import AddDependentsDetailsRight from "./pages/AddDependentsDetailsRight.vue";
import SummaryRight from "./pages/SummaryRight.vue";
import SummaryLeft from "./pages/SummaryLeft.vue";
export const Base = require("./Base.vue").default;

export const routes = [
  { path: "", redirect: "about" },
  {
    path: "about",
    components: {
      right: SelfDetailsRight,
    },
    name: "about",
  },
  { path: "custom-onboarding", component: Base, name: "custom-onboarding" },
  {
    path: "dependents",
    components: {
      right: AddDependentsDetailsRight,
    },
    name: "dependents",
  },
  {
    path: "review",
    components: {
      left: SummaryLeft,
      right: SummaryRight,
    },
    name: "review",
  },
];
