<template lang="pug">
b-card.shadow-sm.shadow-none-sm.mb-3
  .cursor-pointer(@click="toggleExpandCard")
    .d-flex.justify-content-between
      .d-flex.flex-column.justify-content-center
        .hb5.align-self-center {{ provider.name }}
        .d-flex.align-items-center.mt-1
          n-icon.pr-1.align-middle(v-if="provider?.meta?.rating", name="circular-star", variant="teal-800", :size="0.8")
          span.font-sm.font-weight-semibold(v-if="provider?.meta?.rating") {{ provider.meta.rating }}
      .d-flex(:class="provider?.meta?.rating ? 'align-items-end' : 'align-items-center'")
        .d-flex.align-items-center
          .font-sm.font-weight-semibold.text-blue-700.text-nowrap {{ isCardExpanded ? "Hide" : "View Slots" }}
          n-icon.text-center.cursor-pointer(:name="`chevron-${isCardExpanded ? 'up' : 'down'}`", variant="blue-700")
  b-collapse(role="tabpanel", v-model="isCardExpanded")
    hr.text-gray-600
    .font-sm.text-gray-700.font-weight-semibold.my-2.letter-spacing SELECT DATE
    h-date-picker.mb-3(v-model="selectedDate", :availableDates="availableDates")
    div(:ref="`provider-details-card-scroll-to-${id}`")
    .font-sm.text-gray-700.font-weight-semibold.my-2.letter-spacing(v-if="availableTimeSlots?.length") AVAILABLE SLOTS
    h-time-picker(v-if="availableTimeSlots?.length",
      v-model="selectedTimeSlot"
      :availableTimeSlots="availableTimeSlots")
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import HDatePicker from "@/components/HorizontalDatePicker.vue";
import HTimePicker from "@/components/HorizontalTimePicker.vue";
import NChip from "@/components/NovaChip.vue";
import utils from "@/utils";
import NIcon from "@/components/NovaIcon.vue";
export default {
  name: "ProviderDetailsCard",
  components: {
    HDatePicker,
    HTimePicker,
    NChip,
    NIcon,
  },
  props: {
    id: {
      type: Number,
      default: null,
    },
    provider: {
      type: Object,
      default: () => {},
    },
    isHomeCollection: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isCardExpanded: false,
      selectedDate: "",
      availableTimeSlots: [],
      selectedTimeSlot: null,
      estimatedDistance: this.provider.meta.estimatedDistance,
      estimatedTime: this.provider.meta.estimatedTime,
    };
  },
  computed: {
    ...mapGetters(["ahcProspectState"]),
    isMobile() {
      return utils.mobileCheck();
    },
    availableDates() {
      const dates = [];
      Object.keys(this.provider.providerSlots).forEach((date) => {
        if (date && this.provider.providerSlots[date].slots.length > 0) {
          dates.push(date);
        }
      });
      return dates;
    },
    selectedProvider() {
      return this.ahcProspectState?.selectedProvider || {};
    },
    selectedDateTimeSlot() {
      return this.ahcProspectState?.selectedDateTimeSlot || {};
    },
  },
  watch: {
    selectedDate(value) {
      if (value) {
        this.availableTimeSlots = this.provider.providerSlots[value].slots;
        this.$store.commit("updateAhcProspectStateMeta", {
          selectedDateTimeSlot: { date: value },
        });
      }
    },
    selectedTimeSlot(value) {
      if (value) {
        let scheduledAt = null;
        if (this.selectedDate && value) {
          const [hour, minute] = value?.startTime?.split(":");
          scheduledAt = moment(this.selectedDate).set({ hour, minute });
        }
        this.$store.commit("updateAhcProspectStateMeta", {
          selectedDateTimeSlot: { date: this.selectedDate, time: value },
          scheduledAt,
        });
      }
    },
    ahcProspectState: {
      handler(value) {
        if (this.provider?.id === value?.selectedProvider?.id) {
          this.isCardExpanded = true;
          this.selectedDate = value?.selectedDateTimeSlot?.date;
          this.selectedTimeSlot = value?.selectedDateTimeSlot?.time;
        } else {
          this.isCardExpanded = false;
        }
      },
      deep: true,
    },
  },
  created() {
    if (this.id === 0 && !this.selectedProvider?.id) {
      const ahcProspectStateMeta = {
        selectedProvider: { ...this.provider },
        selectedDateTimeSlot: {},
        scheduledAt: null,
      };
      this.$store.commit("updateAhcProspectStateMeta", ahcProspectStateMeta);
    }
  },
  mounted() {
    if (this.provider?.id === this.selectedProvider?.id) {
      this.isCardExpanded = true;
      this.selectedDate = this.selectedDateTimeSlot?.date || "";
      this.selectedTimeSlot = this.selectedDateTimeSlot?.time || {};
      setTimeout(() => {
        this.$refs[`provider-details-card-scroll-to-${this.id}`]?.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }, 500);
    }
  },
  methods: {
    toggleExpandCard() {
      this.availableTimeSlots = [];
      this.isCardExpanded = !this.isCardExpanded;
      const ahcProspectStateMeta = { selectedProvider: {}, selectedDateTimeSlot: {}, scheduledAt: null };
      if (this.isCardExpanded) {
        ahcProspectStateMeta.selectedProvider = { ...this.provider };
      }
      this.$store.commit("updateAhcProspectStateMeta", ahcProspectStateMeta);
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/_variables.scss";
.test-instruction-border {
  border: 1.5px solid $gray-300;
}
</style>
