<!-- original source
https://www.npmjs.com/package/otp-input-vue2?activeTab=code -->
<template lang="pug">
.vue-otp-input
  .otp-wrapper(:id="id",
    :class="isInputFocused ? activeWrapperClassHandler : wrapperClassHandler",
    :style="wrapperStyle")
    input.otp-input(v-for="(digitInput, index) in digits" ref="digitInput",
      :key="id + index",
      v-model="inputValue[index]",
      autocomplete="one-time-code",
      class="otp-input",
      :class="[inputClassHandler, activeInput === index ? activeInputClassHandler : '']",
      :placeholder="placeholder",
      :disabled="isDisabled",
      :style="inputStyle",
      @focus="onFocus(index)",
      @blur="onBlur",
      @paste="OnPaste",
      @input="onInput(index, $event)",
      @change="onChanged(index)",
      @keydown="keydownHandler(index, $event)")
  span(:class="errorClassHandler")
    slot(name="errorMessage")
</template>

<script>
export default {
  name: "OtpInput",
  props: {
    id: {
      type: String,
      default: "otp",
    },
    digits: {
      type: Number,
      default: 5,
    },
    mode: {
      type: String,
      default: "separate",
    },
    type: {
      type: String,
      default: "number",
    },
    placeholder: {
      type: String,
      default: "-",
    },
    radius: {
      type: Number,
      default: 5,
    },
    gap: {
      type: Number,
      default: 10,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isValid: {
      type: Boolean,
      default: true,
    },
    rtl: {
      type: Boolean,
      default: false,
    },
    autoFocus: {
      type: Boolean,
      default: true,
    },
    errorClass: {
      type: String,
      default: "",
    },
    separateInputClass: {
      type: String,
      default: "",
    },
    separateWrapperClass: {
      type: String,
      default: "",
    },
    groupInputClass: {
      type: String,
      default: "",
    },
    groupWrapperClass: {
      type: String,
      default: "",
    },
    activeInputClass: {
      type: String,
      default: "",
    },
    activeWrapperClass: {
      type: String,
      default: "",
    },
    resetSignal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      inputValue: [],
      joinedValue: "",
      isInputFocused: false,
      activeInput: -1,
      isPaste: false,
    };
  },
  computed: {
    wrapperStyle() {
      const dir = this.rtl ? "rtl" : "ltr";
      const styles = {
        direction: dir,
        gap: `${this.gap}px`,
        "border-radius": `${this.radius}px`,
      };
      return styles;
    },
    inputStyle() {
      return {
        "--border-radius": `${this.radius}px`,
      };
    },
    inputClassHandler() {
      if (this.mode === "separate") {
        if (this.isValid) {
          return this.separateInputClass ? "default-input-separate" : "default-input-separate";
        } else {
          if (this.isDisabled) {
            return "default-input-separate";
          }
          return this.separateInputClass ? "default-input-separate" : "default-error-input-separate";
        }
      }
      if (this.mode === "group") {
        return this.groupInputClass ? this.groupInputClass : "default-input-group";
      }
      return "";
    },
    activeInputClassHandler() {
      if (this.mode === "separate") {
        return this.activeInputClass ? this.activeInputClass : "default-active-input";
      }
      return "";
    },
    activeWrapperClassHandler() {
      if (this.mode === "group") {
        if (this.isValid) {
          return this.activeWrapperClass ? this.activeWrapperClass : "default-active-wrapper";
        } else {
          return this.activeWrapperClass ? this.activeWrapperClass : "default-error-wrapper-group";
        }
      }
      return "";
    },
    wrapperClassHandler() {
      if (this.mode === "separate") {
        return this.separateWrapperClass ? this.separateWrapperClass : "default-wrapper-separate";
      }
      if (this.mode === "group") {
        if (this.isValid) {
          return this.groupWrapperClass ? this.groupWrapperClass : "default-wrapper-group";
        } else {
          return this.groupWrapperClass ? this.groupWrapperClass : "default-error-wrapper-group";
        }
      }
      return "";
    },
    errorClassHandler() {
      return this.errorClass === "success" ? "input.default-active-input" : "input.default-error-input-separate";
    },
  },
  watch: {
    resetSignal() {
      this.resetHandler();
    },
  },
  mounted() {
    if (this.autoFocus && !this.isDisabled) {
      this.onFocus(0);
      // setting input type based on the existing types for now
      const types = ["text", "password", "number"];
      if (types.indexOf(this.type) != -1) {
        for (const box of this.$refs.digitInput) {
          box.type = this.type;
        }
      }
      this.$refs.digitInput[0].focus();
      setTimeout(() => {
        this.$refs.digitInput[0].select();
      }, 0);
    }
  },
  methods: {
    keydownHandler(index, e) {
      if (e.keyCode === 8 && e.target.value === "") {
        this.$refs.digitInput[Math.max(0, index - 1)].focus();
      }
    },
    resetHandler() {
      this.inputValue = [];
      this.joinedValue = "";
      this.onBlur();
      this.$emit("value", this.joinedValue);
      this.$refs.digitInput.forEach((input) => {
        input.value = "";
      });
    },
    onInput(index, e) {
      // In mobile devices, when user pastes content from
      // suggestion, it doesnt produce
      // 1. Copy event
      // 2. On key down event
      // Below condition checks if input.length is === no of digits of otp
      // then it assumes its a paste event
      // since its only possible on mobile
      if (e.target.value.length === this.digits) {
        this.isPaste = true;
      }
      // for firefox and IE 🙃
      if (!e.target.validity.valid) {
        e.target.value = e.target.value.replace(/[^0-9]/g, "");
        this.inputValue[index] = e.target.value;
      }
      const [first, ...rest] =
        this.type === "number" ? this.inputValue[index].replace(/[^0-9]/g, "") : this.inputValue[index];

      if (index === 0 && this.inputValue[index].length > 1 && this.type === "number" && !this.isPaste) {
        rest.pop();
      }
      this.inputValue[index] = first === null || first === undefined ? "" : first;
      const lastInputBox = index === this.digits - 1;
      const insertedContent = first !== undefined;
      if (insertedContent && !lastInputBox) {
        this.$refs.digitInput[index + 1].focus();
        // part of original code to vanish next numbers when someone edits
        if (this.isPaste) this.$refs.digitInput[index + 1].value = rest.join("");
        this.$refs.digitInput[index + 1].dispatchEvent(new Event("input"));
      }
      this.joinedValue = this.inputValue.map((value) => value).join("");
      this.$emit("value", this.joinedValue);
      if (this.joinedValue.length === this.digits) {
        this.onComplete(this.joinedValue);
        this.isPaste = false;
      }
    },
    onFocus(index) {
      this.activeInput = index;
      this.isInputFocused = true;
    },
    onBlur() {
      this.activeInput = -1;
      this.isInputFocused = false;
    },
    onComplete(joinedValue) {
      this.onBlur();
      this.$refs.digitInput[this.digits - 1].blur();
      if (this.type === "password") {
        this.$emit("on-complete", joinedValue);
      } else this.$emit("on-complete", joinedValue);
    },
    onChanged(index) {
      this.$emit("on-changed", this.inputValue[index]);
    },
    OnPaste(event) {
      this.isPaste = true;
      this.$emit("on-paste", event);
    },
  },
};
</script>

<style lang="css" scoped>
.vue-otp-input {
  width: max-content;
}
.vue-otp-input > .otp-wrapper {
  direction: var(--direction);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 0.3s;
}

.vue-otp-input > .otp-wrapper > input.otp-input {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 0.3s;
  border-radius: var(--border-radius);
}
.vue-otp-input > .otp-wrapper > input.otp-input:disabled {
  background-color: #e5ecfb !important;
  border: none !important;
}
/* SINGLE INPUT IN SEPARATE MODE */
.vue-otp-input > .otp-wrapper > input.default-input-separate {
  box-sizing: border-box;
  text-align: center;
  font-weight: 600;
  background-color: transparent;
  border: solid 2px #d8e3f8;
  width: 3rem;
  height: 48px;
}
.vue-otp-input > .otp-wrapper > input.default-error-input-separate {
  box-sizing: border-box;
  text-align: center;
  font-weight: 600;
  background-color: transparent;
  border: solid 2px #ffacac;
  width: 3rem;
  height: 48px;
}
@media only screen and (max-width: 600px) {
  .vue-otp-input > .otp-wrapper > input.default-input-separate {
    width: 2.5rem;
    height: 40px;
  }
  .vue-otp-input > .otp-wrapper > input.default-error-input-separate {
    width: 2.5rem;
    height: 40px;
  }
}
/* INPUTS WRAPPER IN SEPARATE MODE */
.vue-otp-input > .default-wrapper-separate {
  background: transparent;
}
/* INPUTS WRAPPER IN GROUP MODE */
.vue-otp-input > .default-wrapper-group {
  border: solid 2px #ececec;
}
.vue-otp-input > .default-error-wrapper-group {
  border: solid 2px #d50000;
}

/* SINGLE INPUT IN GROUP MODE */
.vue-otp-input > .otp-wrapper > input.default-input-group {
  background-color: transparent;
  font-weight: 600;
  border: none;
  width: 3rem;
  height: 48px;
  text-align: center;
}
@media only screen and (max-width: 600px) {
  .vue-otp-input > .otp-wrapper > input.default-input-group {
    width: 2.5rem;
    height: 40px;
  }
}
.vue-otp-input > .otp-wrapper > input.default-active-input {
  border: solid 2px #525252;
}
.vue-otp-input > .default-active-wrapper {
  border: solid 2px #525252;
}
.vue-otp-input > span.default-error-class {
  color: #d50000;
  font-weight: bold;
}
input:focus {
  outline: none;
}
/* removing the arrow keys on side of the input area */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>
