import gql from "graphql-tag";
import { ngageUserDetailFragment } from "../fragments";

export default {
  upsertMutation: gql`
    mutation upsertNgageUserDetail($id: ID, $userId: ID, $deviceIntegrationProvider: String, $meta: JSONObject) {
      upsertNgageUserDetail(
        input: { id: $id, userId: $userId, deviceIntegrationProvider: $deviceIntegrationProvider, meta: $meta }
      ) {
        ngageUserDetail {
          ...NgageUserDetail
        }
      }
    }
    ${ngageUserDetailFragment}
  `,
  deleteMutation: gql`
    mutation deleteNgageUserDetail($id: ID, $userId: ID, $deviceIntegrationProvider: String) {
      deleteNgageUserDetail(
        input: { id: $id, userId: $userId, deviceIntegrationProvider: $deviceIntegrationProvider }
      ) {
        success
      }
    }
  `,
};
