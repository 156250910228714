import { getListQuery, getSingleQuery } from "../queries";
import { NovaOrdersSchema } from "../schemas/novaOrders";

export default {
  gqlType: "NovaOrder",
  fields: ["empId", "email", "orderAmount", "status", "createdAt"],
  paymentTableFields: ["paymentId", "status", "gateway", "createdAt", "updatedAt"],
  orderItemTableFields: ["name", "quantity", "netCost", "taxRate", "itemTotal", "tax", "totalWithTax"],
  searchPlaceholder: "Search by emp id or email",
  singleQuery: getSingleQuery("NovaOrder"),
  listQuery: getListQuery("NovaOrder"),
  ...NovaOrdersSchema,
  transform: (n) => {
  const { email, meta: { employeeId: empId } = {}, name } = n?.user || {};
    return { ...n, email, empId, orderAmount: n.orderAmount, name };
  },
};
