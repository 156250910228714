<template lang="pug">
div(:class="{ 'pb-8': isMobileBrowser() || isApp() }")
  form-card(
    ref="formCard1",
    :model="superTopupProspectState.nomineeDetail",
    :title="nomineeDetailsStage.nomineeDetails.title",
    :schemaPart1="nomineeDetailsStage.nomineeDetails.schemaTop",
    @validated="handleValidation"
  )
  form-card(
    ref="formCard2",
    :model="superTopupProspectState.nomineeContactInfo",
    :title="nomineeDetailsStage.nomineeAddress.title",
    :schemaPart1="nomineeDetailsStage.nomineeAddress.schemaTop"
    :schemaPart2="nomineeDetailsStage.nomineeAddress.schemaBottom",
    @validated="handleValidation"
  )
</template>

<script>
import NovaIcon from "../../../../../components/NovaIcon.vue";
import { nomineeDetailsStage } from "../definitions";
import ContactDetails from "./ContactDetails.vue";
import FormCard from "./FormCard.vue";
import mixin from "./../mixin";
export default {
  name: "NomineeDetails",
  components: {
    NovaIcon,
    ContactDetails,
    FormCard,
  },
  mixins: [mixin],
  data() {
    return {
      nomineeDetailsStage,
      validationResults: [],
      resolveValidation: null,
    };
  },
  watch: {
    "superTopupProspectState.nomineeDetail": {
      deep: true,
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          if (!this.superTopupProspectState.nomineeDetail) {
            this.$set(this.superTopupProspectState, "nomineeDetail", {});
          }
          this.$store.commit("updateSuperTopupProspectStateMeta", { nomineeDetail: newVal });
        }
      },
    },
    "superTopupProspectState.nomineeContactInfo": {
      deep: true,
      handler(newVal, oldVal) {
        if (newVal && newVal !== oldVal) {
          if (!this.superTopupProspectState.nomineeContactInfo) {
            this.$set(this.superTopupProspectState, "nomineeContactInfo", {});
          }
          this.$store.commit("updateSuperTopupProspectStateMeta", { nomineeContactInfo: newVal });
        }
      },
    },
  },
  created() {},
  methods: {
    async validateForms() {
      return new Promise((resolve) => {
        this.$root.$emit("validateForm");
        this.resolveValidation = resolve;
      });
    },
    handleValidation(isValid) {
      this.validationResults.push(isValid);
      if (this.validationResults.length === 2) {
        const allValid = this.validationResults.every((result) => result);
        this.$emit("validated", allValid);
        this.resolveValidation(allValid);
        this.validationResults = [];
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
</style>
