import { getIdSelectorQuery, getListQuery } from "../queries";
import stagedClaimsMutations from "../mutations/stagedClaims";

export const errorMap = {
  USER_NOT_FOUND: {
    text: "User Not Found",
    reasons: [
      "Same name but employee ID not matching",
      "User there but in a subsidiary organisation",
      "User there but in a deleted organisation",
      "User not in our database",
    ],
  },
  POLICY_NOT_FOUND: {
    text: "Policy Number Incorrect",
    reasons: ["Policy number incorrect", "Policy number format incorrect"],
  },
  POLICY_NOT_LINKED: {
    text: "Policy Not Linked",
    reasons: ["Policy linked to different organisation or different subsidiary organisation"],
  },
  ORG_NOT_FOUND: {
    text: "Org Not Found",
    reasons: ["Policy linked to an organisation but the user is linked to a different organisation"],
  },
  STAGE_NOT_FOUND: {
    text: "Stage Not Found",
    reasons: ["Mapping mismatch will require code change"],
  },
  MULTIPLE_DEPENDENTS_FOUND: {
    text: "Multiple Dependents Found",
    reasons: ["Multiple entries for same dependent where one might be deleted or active"],
  },
  DEPENDENT_NOT_FOUND: {
    text: "Dependent Not Found",
    reasons: ["Spelling error ", "Dependent not added to our database"],
  },
  INVALID_CLAIM_TYPE: {
    text: "Invalid Claim Type",
    reasons: ["Type not recognised, will require a change in the claim type or will require code change"],
  },
  CLAIM_NUMBER_EXISTS: {
    text: "Claim Number Exists",
    reasons: ["Another claim with the same number already exists in our database"],
  },
  MULTIPLE_SUB_CLAIMS_FOUND: {
    text: "Multiple Sub Claims Found for FHPL",
    reasons: ["Multiple sub claims found for the same claim number"],
  },
};

export default {
  gqlType: "StagedClaim",
  fields: [
    { key: "employeeId", label: "Employee ID" },
    { key: "patient", label: "Patient" },
    { key: "primaryEmail", label: "Primary Email" },
    { key: "organization", label: "Organization" },
    { key: "orgEntity", label: "Org Entity" },
    { key: "errorReason", label: "Error Type" },
    { key: "status", label: "Status" },
    { key: "claimNumber", label: "Claim Number" },
  ],
  listQuery: getListQuery("StagedClaim"),
  ...stagedClaimsMutations,

  transform: (n) => {
    return {
      ...n,
      employeeId: n.claimIdentifiers.employeeId,
      patient: n.claimIdentifiers.patientName?.toLowerCase().replace(/\b\w/g, (match) => match.toUpperCase()),
      primaryEmail: n.claimIdentifiers.employeeEmail || "-",
      organization: n.claimIdentifiers.orgName || "-",
      orgEntity: n.claimIdentifiers.orgEntityName || "-",
      errorReason: errorMap[n.errorReason],
      status: n.status === "CREATED" ? "Error" : "Resolved",
      claimNumber: n.claimNumber,
      novaDetails: {
        policyNumber: n.claimData.novaPolicyNumber,
      },
    };
  },
  filterSchema: {
    fields: [
      {
        model: "errorReason",
        type: "inline-select",
        reduce: (v) => v.value,
        options: Object.keys(errorMap).map((errorKey) => ({
          label: errorMap[errorKey].text,
          value: errorKey,
        })),
        label: "Error Type",
      },
      {
        model: "status",
        type: "inline-select",
        reduce: (v) => v.value,
        options: [
          { label: "Error", value: "CREATED" },
          { label: "Resolved", value: "PROCESSED" },
        ],
        label: "Status",
      },
      {
        model: "org",
        type: "id-selector",
        queryName: "orgs",
        label: "Organization Name",
        gqlQuery: getIdSelectorQuery("Org"),
        placeholder: "None Selected",
        showSelected: false,
      },
      {
        model: "orgEntity",
        label: `Organisation Entity`,
        type: "id-selector",
        queryName: "orgEntities",
        gqlQuery: getIdSelectorQuery("OrgEntity"),
        queryVariables: () => ({ filter: {} }),
        placeholder: "Org Entity filter (none)",
      },
    ],
  },
};
