<template lang="pug">
.start-claim.overlay
  .container.mt-5
    .row.mt-4(v-if="formSubmitted")
      .col-sm-12.offset-md-3.col-md-6(v-if="formType === 'reimbursement-claim-form'")
        div.mt-5
          .d-flex.flex-column.justify-conent-center.align-items-center.p-2
            img(:src="require(`@/assets/emojis/high-five.svg`)")
            h3.h3.font-hergon.mt-3.text-center
              | Your request for a reimbursement claim was received
            p.text-gray-900.mt-3.text-center.font-lg.font-weight-medium
              | To continue your reimbursement process, start
              br
              | uploading the required documents.
            n-button.w-100.mb-3(
              buttonText="View documents list ->",
              variant="outline-primary",
              @click="$bvModal.show('document-check-list')")
            b-modal(id="document-check-list", no-footer, title="Documents List", hide-footer, centered)
              template(slot='modal-header')
                .d-flex.justify-content-between.align-items-center.w-100
                  .py-1.d-flex.align-items-center
                    span.font-lg.font-weight-semibold.mr-2 Documents List
                  .py-1
                    b-avatar.shadow-none(variant="none", button, @click="$bvModal.hide('document-check-list')")
                      i.icon-cross
              .p-2
                .d-flex.justify-content-between.align-items-center.mb-4(v-for="document in claimDocumentsList")
                  span.font-weight-medium.text-gray-900.font-md(
                    v-if="shouldShowLink(document)") {{ document }} ->
                    b-link(:href="claimFormsLink", target="_blank") &nbsp;Download
                  span.font-weight-medium.text-gray-900.font-md(v-else) {{ document }}
            n-button.w-100.mb-2(
              buttonText="Continue to upload documents ->",
              variant="secondary",
              :to="{name: 'upload-documents', params: {id: claimAssist.id }}")
            p.text-gray-800.mt-4.mb-5.text-center
              | You can track your claim status from the
              br
              | claims page on the Nova Portal
      .col-md-12(v-else)
        .offset-md-2.col-md-8.d-flex.flex-column.justify-conent-center.align-items-center.p-2
          img(:src="require(`@/assets/emojis/high-five.svg`)")
          h3.h3.font-hergon.mt-3
            | You’re all set ✨
          p.text-gray-900.mt-1.text-center.font-lg.font-weight-medium
            | Your claim request has been created.
          p.text-gray-900.mt-1.text-center.font-lg.font-weight-medium
            | Someone from our team will reach out to you.
            br
            | Keep your phone handy
          p.text-gray-800.mt-4.mb-5.text-center
            | You can track your claim status from the
            br
            | claims page on the Nova Portal
          n-button.w-50.mb-2(
            buttonText="Back to claims page ->",
            variant="secondary",
            :to="{name: 'claim-details', params: {id: claimAssist.id}}")
          n-button.w-50.mb-5(
            buttonText="View your policy details ->",
            variant="outline-primary",
            :to="{name: 'policy', params: {policyId: claimAssist.model.policyId}}")

    .row.mt-5(v-else)
      .col-sm-12.offset-md-3.col-md-6
        div
          b-card.mb-4.rounded
            .d-flex.justify-content-between.align-items-center
              i.icon-arrow.flex-1.cursor-pointer.text-gray-600(@click="$router.go(-1)")
              span.flex-1.font-hc.text-gray-600(v-if="formType==='claim-intimation-form'") Claim Intimation form
              span.flex-1.font-hc.text-gray-600(
                v-else-if="['reimbursement-claim-form', 'opd-form'].includes(formType)") Reimbursement Claim Form
              span.flex-1.font-hc.text-gray-600(v-else) Emergency Claim Form
              i.flex-1 &nbsp;
            hr
            div(v-if="policyId")
              form(@submit="submitClaim")
                vue-form-generator(
                  :schema="claimAssist.schemaPart1",
                  :model="claimAssist.model",
                  :options="claimAssist.options",
                  ref="claimForm1")
                div(v-if="isWhatsappEnabled")
                  n-contact-consent(v-model="whatsappUserConsent", :is-highlighted="true")
                vue-form-generator(
                  :schema="claimAssist.schemaPart2",
                  :model="claimAssist.model",
                  :options="claimAssist.options",
                  ref="claimForm2")
                n-button.w-100(
                  variant="dark",
                  :buttonText="saveClaimBtnText",
                  data-cy="user-claim-submit-btn")
            div(v-else)
              b-alert(show, variant="warning")
                | You do not have a insurance policy assigned to your account yet. Please contact us for further help
</template>

<script>
import gql from "graphql-tag";
import { mapState, mapGetters } from "vuex";
import moment from "moment";
import userDefs from "../../admin/definitions";
import { claimDocumentsList } from "../../admin/constants";
import { validateChildFields, validateIndianMobileNumbersForVfg, validateInlineSelect } from "@/utils/validators";
import utils, { validateEditableDropdown } from "@/utils";
import NUploadComponent from "@/components/FileUpload";
import NContactConsent from "@/components/NovaContactConsent";
import NButton from "@/components/NovaButton";

const getCitiesQuery = () => {
  const query = gql`
    query DropdownCities($query: String) {
      dropdownCities(query: $query)
    }
  `;
  return query;
};

export default {
  name: "ClaimAssist",
  components: {
    NButton,
    NUploadComponent,
    NContactConsent,
  },
  beforeRouteLeave(to, from, next) {
    if (from.name === "reimbursement-form" && to.name === "start-claim") {
      this.isUserGoingBack = true;
    } else {
      this.isUserGoingBack = false;
    }
    this.$store.commit("setClaimFormData", this.claimAssist);
    next();
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (from.name === "upload-documents" && to.name === "reimbursement-form") {
        vm.isUserGoingBack = true;
        const savedFormData = vm.$store.state.claimFormData;
        const patientId = savedFormData.model.patientId;
        const patientDropdownOptions = savedFormData.schemaPart1.fields[1].options || [];
        const patient = patientDropdownOptions.find((patient) => patient.id === patientId);
        savedFormData.model.patientId = patient;
        vm.claimAssist = savedFormData;
      }
    });
  },

  data() {
    const data = {
      saveClaimBtnText: "Create a claim request",
      eligibleDependents: [],
      claimIntimationStatus: null,
      claimDocumentsList,
      userId: null,
      formSubmitted: false,
      policyId: null,
      status: null,
      upload: false,
      formType: null,
      claimAssist: {
        id: null,
        model: {
          policyId: null,
          patientId: null,
          meta: {
            emergency: false,
            category: "",
            diagnosis: "",
            city: null,
            hospital: null,
            contact: "",
            details: "",
            doa: "",
            dod: "",
            estimatedClaimAmount: null,
            formType: null,
          },
        },
        schemaPart1: {
          fields: [
            {
              model: "policyId",
              type: "inline-select",
              label: "Policy",
              imageIcon: "claims-1",
              validator: [validateInlineSelect],
              dataCy: "user-claim-select-policy",
              options: [
                ...utils
                  .extractUserDependentBenefits(this.$store.state.user, true)
                  .filter(
                    (p) => p.node.isPolicy && ["gmc", "opd", "ppc"].includes(p.node.type) && !this.isPolicyExpired(p)
                  )
                  .map((policy) => ({
                    ...policy.node,
                    label: policy.node.name,
                    policyMeta: policy.meta,
                  })),
              ],
              reduce: (obj) => obj.id,
              placeholder: "Select Policy",
              required: true,
            },
            {
              type: "inline-select",
              label: "Patient Name",
              model: "patientId",
              validator: [validateInlineSelect],
              dataCy: "user-claim-select-patient",
              imageIcon: "user-circle",
              reduce: (obj) => obj.id,
              options: [
                {
                  label: this.$store.state.user.name,
                  id: this.$store.state.user.id,
                },
                ...(this.eligibleDependents || []).map((dep) => {
                  return {
                    label: dep.name,
                    id: dep.id,
                  };
                }),
              ],
              placeholder: "Select patient",
              required: true,
            },
            {
              model: "meta",
              type: "object",
              validator: validateChildFields,
              schema: {
                fields: [
                  {
                    model: "city",
                    type: "editable-dropdown",
                    label: "City of treatment (Optional)",
                    imageIcon: "city",
                    placeholder: "Select a city",
                    gqlQuery: getCitiesQuery(),
                    transform: (v) => v.name,
                    queryName: "dropdownCities",
                    required: true,
                    taggable: true,
                    dataCy: "user-claim-select-city",
                  },
                  {
                    model: "contact",
                    type: "inline-input",
                    inputType: "tel",
                    label: "Where should we call?",
                    validator: [validateIndianMobileNumbersForVfg],
                    required: true,
                    placeholder: "Enter mobile number",
                    styleClasses: ["mb-0"],
                    "data-cy": "user-claim-input-contact",
                  },
                ],
              },
            },
          ],
        },
        schemaPart2: ((formType) => {
          const fields = [
            {
              model: "meta",
              type: "object",
              validator: [validateChildFields],
              schema: {
                fields: [
                  {
                    model: "hospital",
                    type: "editable-dropdown",
                    label: "Hospital (Optional)",
                    imageIcon: "hospital",
                    placeholder: "Select Hospital",
                    gqlQuery: userDefs.hospitals.listQuery,
                    transform: (value) => value.name,
                    queryName: "hospitals",
                    taggable: true,
                    dataCy: "user-claim-select-hospital",
                    additionalVariables: () => {
                      return {
                        city: this.claimAssist.model.meta.city?.name,
                      };
                    },
                  },
                  {
                    model: "diagnosis",
                    type: formType !== "opd-form" ? "text-area" : "inline-input",
                    inputType: "text",
                    label: "Procedure or Diagnosis Details (Optional)",
                    placeholder: "Enter procedure details",
                    fieldClasses: ["input-wrapper"],
                    attributes: { "data-cy": "user-claim-input-diagnosis" },
                  },
                  {
                    model: "details",
                    type: formType !== "opd-form" ? "text-area" : "inline-input",
                    inputType: "text",
                    label: "Any additional details? (Optional)",
                    placeholder: "Enter additional details",
                    fieldClasses: ["input-wrapper"],
                    attributes: { "data-cy": "user-claim-input-details" },
                  },
                ],
              },
            },
          ];
          if (formType === "claim-intimation-form") {
            fields[0].schema.fields.splice(1, 0, {
              model: "doa",
              type: "datepicker",
              label: "Estimated Hospitalization Date",
              placeholder: "Select estimated hospitalization date",
              min: moment().format("YYYY-MM-DD"),
              max: "2100-12-31",
              required: true,
              subText: "This should be the date you plan on visiting the hospital",
              validator: [this.validateIsDatePast],
              dataCy: "user-claim-add-doa-picker-input",
            });
            fields[0].schema.fields.splice(2, 0, {
              model: "doctor",
              type: "inline-input",
              imageIcon: "user-circle",
              inputType: "text",
              label: "Treating Doctor (Optional)",
              placeholder: "Enter treating doctor's name",
              "data-cy": "user-claim-input-doctor",
            });
            fields[0].schema.fields.splice(3, 0, {
              model: "estimatedClaimAmount",
              type: "inline-input",
              imageIcon: "rupee",
              inputType: "number",
              label: "Estimated Medical Expenses",
              placeholder: "Enter expense estimate here",
              required: true,
              "data-cy": "user-claim-input-est-claim-amount",
            });
          }
          if (formType === "reimbursement-claim-form") {
            fields[0].schema.fields.splice(1, 0, {
              model: "doa",
              type: "datepicker",
              label: "Hospitalization Date",
              placeholder: "Select Hospitalization Date",
              min: "1900-01-01",
              max: moment().format("YYYY-MM-DD"),
              required: true,
              subText: "This should be the date you had visited the hospital",
              validator: [this.validateIsDateFuture],
              dataCy: "user-claim-add-doa-picker-input",
            });
            fields[0].schema.fields.splice(2, 0, {
              model: "doctor",
              type: "inline-input",
              imageIcon: "user-circle",
              inputType: "text",
              label: "Treating Doctor",
              placeholder: "Enter treating doctor's name",
              "data-cy": "user-claim-input-doctor",
            });
            fields[0].schema.fields.splice(3, 0, {
              model: "estimatedClaimAmount",
              type: "inline-input",
              imageIcon: "rupee",
              inputType: "number",
              label: "Medical Expenses",
              placeholder: "Enter your expense here",
              required: true,
              "data-cy": "user-claim-input-est-claim-amount",
            });
            fields[0].schema.fields.splice(1, 0, {
              model: "category",
              type: "select-cards",
              label: "What kind of treatment did you undergo?",
              cardType: "full-width",
              cardsData: [
                {
                  name: "category",
                  icon: "hr24-store",
                  iconColor: "red-400",
                  card_value: "daycare",
                  description:
                    "These are treatments and procedures that require Hospitalization for less than 24-hours",
                  label: "Day Care Procedure",
                },
                {
                  name: "category",
                  icon: "bed",
                  iconColor: "blue-400",
                  card_value: "hospitalization",
                  description:
                    "Select this option if you think you’ll have to be hospitalized for longer than 24-hours",
                  label: "Hospitalization",
                },
              ],
              required: true,
            });
          }

          if (formType === "opd-form") {
            fields[0].schema.fields.splice(1, 0, {
              model: "hospitalization",
              type: "date-range",
              label: "Hospital Visit (OPD) Date",
              placeholder: "Select Hospitalization Date",
              min: "1900-01-01",
              max: moment().format("YYYY-MM-DD"),
              value: null,
              required: true,
              hint: "Please add the first and last date of hospital visits",
              validator: [this.validateDateRange],
              dataCy: "user-claim-add-hospitalizationDate-picker-input",
            });
            fields[0].schema.fields.splice(2, 0, {
              model: "doctor",
              type: "inline-input",
              imageIcon: "user-circle",
              inputType: "text",
              label: "Treating Doctor",
              placeholder: "Enter treating doctor's name",
              "data-cy": "user-claim-input-doctor",
            });
            fields[0].schema.fields.splice(3, 0, {
              model: "estimatedClaimAmount",
              type: "inline-input",
              imageIcon: "rupee",
              inputType: "number",
              label: "Medical Expenses",
              placeholder: "Enter your expense here",
              required: true,
              "data-cy": "user-claim-input-est-claim-amount",
            });
          }
          return { fields };
        })(this.$route.path.replace("/user/claims/", "")),
        options: {
          validateAfterLoad: false,
          validateAfterChanged: false,
          validateAsync: true,
        },
      },
      whatsappUserConsent: true,
      isDateRangeError: false,
      isDateError: false,
      claimFormsLink: null,
      claimId: null,
      isUserGoingBack: false,
    };
    return data;
  },
  computed: {
    ...mapState(["user"]),
    ...mapGetters(["getFeatureFlags"]),
    isWhatsappEnabled() {
      return this.getFeatureFlags.CLAIMS_WHATSAPP ?? true;
    },
    isMandatoryDocsFlowEnabled() {
      return this.getFeatureFlags.MANDATORY_CLAIM_DOCS_FLOW;
    },
    contactNumber() {
      return this.$store.state.user.meta.contactNumber;
    },
  },
  watch: {
    "claimAssist.model.meta.category": function () {
      if (this.claimAssist.model?.meta?.category === "hospitalization") {
        this.claimAssist.schemaPart2.fields[0].schema.fields.splice(2, 1, {
          model: "hospitalization",
          type: "date-range",
          label: "Hospitalization Date",
          placeholder: "Select Hospitalization Date",
          min: "1900-01-01",
          max: moment().format("YYYY-MM-DD"),
          value: null,
          required: true,
          hint: "This should be the date you had visited the hospital",
          validator: [this.validateDateRange],
          dataCy: "user-claim-add-hospitalizationDate-picker-input",
        });
      } else {
        this.claimAssist.schemaPart2.fields[0].schema.fields.splice(2, 1, {
          model: "doa",
          type: "datepicker",
          label: "Hospitalization Date",
          placeholder: "Select Hospitalization Date",
          min: "1900-01-01",
          max: moment().format("YYYY-MM-DD"),
          required: true,
          subText: "This should be the date you had visited the hospital",
          validator: [this.validateIsDateFuture],
          dataCy: "user-claim-add-doa-picker-input",
        });
      }
    },
    "claimAssist.model.policyId": function () {
      this.eligibleDependents = [];
      const selectedPolicyId = this.claimAssist?.model?.policyId;
      this.$store.state?.user?.dependents?.map((dep) =>
        dep?.benefits?.every((ben) => {
          if (selectedPolicyId === ben?.node?.id) {
            this.eligibleDependents.push(dep);
            return false;
          }
          return true;
        })
      );
      if (selectedPolicyId === "None selected") return;
      // Reset selected patient each time selection of policy changes
      if (!this.isUserGoingBack) {
        this.claimAssist.model.patientId = "None selected";
      }
      const selectedPolicy = utils.extractUserDependentBenefits(this.$store.state.user, true).find((ben) => {
        return ben.node.id === selectedPolicyId;
      });
      if (selectedPolicy.node.type === "gpa") {
        const newOptions = {
          label: this.user.name,
          id: this.user.id,
        };
        this.claimAssist.schemaPart1.fields[1].options = [newOptions];
      } else {
        const options = [
          ...this.eligibleDependents.map((dep) => {
            return {
              label: dep.name,
              id: dep.id,
            };
          }),
        ];
        if (selectedPolicy.node.type !== "ppc")
          options.unshift({
            label: this.user.name,
            id: this.user.id,
          });
        this.claimAssist.schemaPart1.fields[1].options = options;
      }
    },
  },
  mounted() {
    const policyId = this.$route.query?.id;
    if (policyId) {
      this.claimAssist.model.policyId = policyId;
    }
    this.formType = this.$route.path.replace("/user/claims/", "");
    if (this.formType === "emergency-claim-form") {
      this.claimAssist.model.meta.emergency = true;
    }
    if (this.formType === "reimbursement-claim-form") {
      this.saveClaimBtnText = "Continue to Upload Documents";
      this.claimAssist.schemaPart2.fields[0].schema.fields[0].label = "Hospital";
      this.claimAssist.schemaPart2.fields[0].schema.fields[0].required = true;
      this.claimAssist.schemaPart2.fields[0].schema.fields[0].validator = [validateEditableDropdown, "required"];
    }
    if (this.formType === "opd-form") {
      this.saveClaimBtnText = "Continue to Upload Documents";
    }
    this.claimAssist.model.meta.contact = this.contactNumber;
  },
  methods: {
    isPolicyExpired(policy) {
      if (!policy.node.meta.endDate) {
        return false;
      }
      return utils.isExpired(policy.node.meta.endDate);
    },
    downloadEcard() {
      let currentPolicy = this.$store.state.user.benefits.filter(
        (policy) => policy.node.id === this.claimAssist.model.policyId
      );
      currentPolicy = currentPolicy[0];
      if (currentPolicy.meta.cardUrl) {
        window.open(currentPolicy.meta.cardUrl, "_blank");
      }
    },
    async submitClaim(e) {
      this.isDateRangeError = false;
      e.preventDefault();
      const isValid = await this.validateForm();
      if (!isValid) {
        this.$store.commit("addToast", {
          variant: "danger",
          message: "Please enter valid details before continuing to the next step",
        });
        if (this.isDateRangeError) {
          this.$store.commit("addToast", {
            variant: "danger",
            message: "Date of Discharge can't be before Date of Admission",
          });
          return;
        }
        return;
      }
      if (this.isDateError) {
        this.$store.commit("addToast", {
          variant: "danger",
          message: this.isDateError,
        });
        return;
      }
      try {
        const formTypeMapping = {
          "claim-intimation-form": "planned",
          "reimbursement-claim-form": "discharged",
          "emergency-claim-form": "emergency",
          // todo what type to give this?
          "opd-form": "opd",
        };
        this.claimAssist.model.meta.formType = formTypeMapping[this.formType];
        if (this.isWhatsappEnabled) {
          this.claimAssist.model.meta.claimsWhatsappUserConsent = this.whatsappUserConsent;
        }
        if (this.claimAssist.model.meta.category === "hospitalization") {
          this.claimAssist.model.meta.doa = this.claimAssist.model.meta.hospitalization?.startDate;
          this.claimAssist.model.meta.dod = this.claimAssist.model.meta.hospitalization?.endDate;
        }
        this.claimIntimationStatus = "loading";
        const variables = {
          meta: {
            ...this.claimAssist.model.meta,
            ...(this.isUserGoingBack && { reSubmittedAt: new Date().toISOString() }),
          },
          policyId: this.claimAssist.model.policyId,
          id: this.claimAssist.id,
          userId: this.userId,
          dependentId: null,
        };
        const patientId =
          typeof this.claimAssist.model.patientId === "string"
            ? this.claimAssist.model.patientId
            : this.claimAssist.model.patientId.id;
        if (this.userId !== patientId) {
          variables.dependentId = patientId;
          this.claimAssist.model.patientId = patientId;
        }
        const result = await this.$apollo.mutate({
          mutation: gql`
            mutation CreateClaim($meta: JSONObject, $policyId: ID, $id: ID, $userId: ID, $dependentId: ID) {
              upsertClaim(
                input: { meta: $meta, policyId: $policyId, id: $id, userId: $userId, dependentId: $dependentId }
              ) {
                claim {
                  id
                  policy {
                    id
                  }
                }
              }
            }
          `,
          variables: variables,
        });
        this.claimIntimationStatus = "success";
        this.claimAssist.id = result.data.upsertClaim.claim.id;
        this.policyId = result.data.upsertClaim.claim.policy.id;
        if (["discharged", "opd"].includes(this.claimAssist.model.meta.formType)) {
          this.$router.push({
            path: `/user/claims/upload-documents/${this.claimAssist.id}`,
            query: { firstTime: true },
          });
          if (!this.isMandatoryDocsFlowEnabled) {
            this.$store.commit("addToast", {
              variant: "warning",
              title: "Claim Missing Documents",
              message:
                "Your claim was recorded by Nova. But we still need you to upload your documents for the claim to be complete!",
            });
          }
          return;
        }
        this.$store.commit("addToast", {
          variant: "success",
          message:
            "Your claim intimation was successfully recorded by us. We will reach out to you for further actions",
        });
        this.formSubmitted = true;
        await this.$apollo.queries.policy.refetch();
      } catch (err) {
        // gql errors handled globally
        this.claimIntimationStatus = "failure";
      }
    },
    async validateForm() {
      let isValid = true;
      if (this.$refs.claimForm1 && this.$refs.claimForm2) {
        await this.$refs.claimForm1.validate();
        await this.$refs.claimForm2.validate();
        isValid = this.$refs.claimForm1.errors.length < 1 && this.$refs.claimForm2.errors.length < 1;
      }
      return isValid;
    },
    validateDateRange(range) {
      const { endDate, startDate } = range;
      if (moment(endDate).isBefore(moment(startDate))) {
        this.isDateRangeError = true;
        return ["Date of Discharge can't be before Date of Admission"];
      }
    },
    validateIsDateFuture(date) {
      this.isDateError = null;
      if (utils.isFuture(date)) this.isDateError = "A future date can't be selected";
      return [];
    },
    validateIsDatePast(date) {
      this.isDateError = null;
      if (utils.isExpired(date)) this.isDateError = "A past date can't be selected";
      return [];
    },
    shouldShowLink(docName) {
      const claimForms = ["Claim form: Part A", "Claim form: Part B"];
      return claimForms.includes(docName) && this.claimFormsLink;
    },
  },
  apollo: {
    policyId: {
      query: gql`
        query MyPoliciesQuery {
          me {
            id
            benefits {
              node {
                id
                name
                type
                isPolicy
              }
            }
          }
        }
      `,
      update(data) {
        const policies = data.me.benefits.filter((p) => p.node.isPolicy);
        if (policies.length) return policies[0].node.id;
        else return null;
      },
    },
    user: {
      query: gql`
        query LoggedInUser {
          me {
            id
          }
        }
      `,
      update(data) {
        this.userId = data?.me?.id;
      },
    },
    policy: {
      query: userDefs.policies.singleQuery,
      skip() {
        return !this.policyId;
      },
      variables() {
        return { id: this.policyId };
      },
      update(data) {
        this.claimFormsLink = data.node?.claimFormsLink;
        return data.node;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  background-color: #fbfbfb;
  height: 100%;
  width: 100%;
  overflow: auto;
  z-index: 1000;
}
</style>
