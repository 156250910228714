import gql from "graphql-tag";

export const userAddressFragment = gql`
  fragment UserAddress on UserAddress {
    id
    city
    state
    country
    pincode
    addressLineOne
    addressLineTwo
    isPrimary
    meta
    status
    updatedAt
  }
`;
