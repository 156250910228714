<template lang="pug">
.package-card
  .row.no-gutters.align-items-center.justify-content-center
    .col-3.col-sm-2
      img.img-fluid(:src="require(`@/assets/images/checkup-package-${groupCheckupPackageIcon}-icon.png`)")
    .col-9.col-sm-10.d-flex.flex-column.justify-content-around.pl-3
      .d-flex.align-self-start.justify-content-center.align-items-center.rounded-4.font-xs.font-weight-semibold.px-2.py-1.mb-1(
        v-if="sampleCollectionConfig?.sampleCollectionTitle && !isReviewPage",
        :class="sampleCollectionConfig.class")
        n-icon.mr-1(:name="sampleCollectionConfig.iconName", :size="0.7", :variant="sampleCollectionConfig.variant")
        | {{ sampleCollectionConfig?.sampleCollectionTitle }}
      .font-lg.font-weight-semibold.mb-1  {{ groupedCheckupPackage.packageGroupName }}
      .row.no-gutters.font-sm.font-weight-medium.mb-1
        .col-5.col-sm-3.col-xl-2.text-gray-700  Available for:
        .col-7.col-sm-9.col-xl-10.text-gray-800  {{ availableForDescription }}
      .row.no-gutters.font-sm.font-weight-medium.d-none.d-lg-flex
        .col-4.col-sm-3.col-xl-2.text-gray-700  Includes:
        .col-8.col-sm-9.col-xl-10.text-gray-800
          .overflow-ellipsis
            template(v-for="[index, testComponent] of minimalCheckupTestComponents.entries()")
              span.text-nowrap {{ testComponent }}
              span.text-gray-500.mx-2(v-if="index + 1 !== minimalCheckupTestComponents.length") |
          .text-blue-500.d-inline-flex.justify-content-start.align-items-center.text-nowrap.mt-1.cursor-pointer(v-if="packageTestCount", v-b-toggle="sidesheetId")
            | {{ packageTestCount }} tests
            n-icon(name="chevron-right", :size="0.7", variant="blue-500")
  .d-flex.d-lg-none.flex-nowrap.font-sm.font-weight-medium.mt-2
    .text-gray-700 Includes:
    .overflow-ellipsis.ml-2
      .overflow-ellipsis.text-gray-800
        template(v-for="[index, testComponent] of minimalCheckupTestComponents.entries()")
          span.text-nowrap {{ testComponent }}
          span.text-gray-500.mx-2(v-if="index + 1 !== minimalCheckupTestComponents.length") |
      .text-blue-500.d-inline-flex.justify-content-start.align-items-center.text-nowrap.mt-1.cursor-pointer(v-if="packageTestCount", v-b-toggle="sidesheetId")
        | {{ packageTestCount }} tests
        n-icon(name="chevron-right", :size="0.7", variant="blue-500")
  template(v-if="!isReviewPage")
    .package-card-divider.my-3
    .d-flex.justify-content-between.align-items-center
      .d-flex.flex-column.align-items-start.justify-content-around
        span(v-if="freeForDescription")
          span.text-gray-700.font-xs.font-weight-medium.mr-2.strikethrough(v-if="maximumRetailPrice && !isSinglePaymentLinearPackage")
            | {{ formatAmountWithRupee(maximumRetailPrice) }}
          span.text-teal-800.font-sm.font-weight-bold.mr-2(v-if="isSinglePaymentLinearPackage && linearPackageName")
            | Covered with {{ linearPackageName }}
          span.text-teal-800.font-sm.font-weight-bold(v-else) Free for {{ freeForDescription }}
        span(v-else)
          span.text-gray-700.font-xs.font-weight-medium.mr-2(v-if="isGroupPackage && isPriceVariable") starting from
          span.text-gray-700.font-xs.font-weight-medium.mr-2.strikethrough(v-else-if="maximumRetailPrice") {{ formatAmountWithRupee(maximumRetailPrice) }}
          span.text-gray-800.font-md.font-weight-semibold {{ formatAmountWithRupee(packageCost) }}
        .text-gray-700.font-xs.font-weight-medium(v-if="payableForDescription") {{ payableForDescription }}
      n-button.align-self-center.text-nowrap(:disabled="!isSinglePaymentLinearPackage && isLinearPackagePending",
        variant="dark",
        :buttonText="isMobile ? 'Book' : 'Book Package'",
        rightImageIcon="chevron-right",
        size="xs",
        @click="bookPackage")
    .d-flex.align-items-center.mt-3.border.border-mustard-200.bg-mustard-100.rounded-8.p-2(v-if="!isSinglePaymentLinearPackage && isLinearPackagePending && linearPackageName")
      n-icon.mr-2(name="info", :size="0.8", :pathCount="2", variant="mustard-700")
      .text-gray-900.font-sm.font-weight-medium You will be able to book this package after reports of {{ linearPackageName }} are available.

  package-details-sidesheet.package-sidesheet-style(
    title="Package details for",
    :sidesheetId="sidesheetId"
    :groupedCheckupPackage="groupedCheckupPackage",
    :isBookPackageButtonVisible="isBookPackageButtonVisible",
    :isBookPackageButtonDisabled="!isSinglePaymentLinearPackage && isLinearPackagePending",
    @book-package="bookPackage")

  b-modal(v-if="isSinglePaymentLinearPackage && freeForDescription && isLinearPackagePending", :id="modalId", centered)
    template(v-slot:modal-header)
      .font-lg.font-weight-semibold.text-gray-900 Utilise {{ linearPackageName }} to unlock package
    template(v-slot:default)
      .font-md.font-weight-medium You will be able to book {{ groupedCheckupPackage.packageGroupName }} once {{ linearPackageName }} is utilised and reports are generated.
    template(v-slot:modal-footer="{ hide }")
      n-button.px-3(variant="primary", size="xs", buttonText="I Understand" @click="hide")
</template>

<script>
import { mapState, mapGetters } from "vuex";
import resDefs from "../../admin/definitions";
import { CheckupPackageSampleCollectionType } from "../../../../common/enums";
import { formatAmountWithRupee } from "../../../../utils/misc";
import * as checkupUtils from "../checkupUtils";
import PackageDetailsSidesheet from "./PackageDetailsSidesheet.vue";
import PackageTestsCountChip from "./PackageTestsCountChip.vue";
import NChip from "@/components/NovaChip.vue";
import NPopover from "@/components/NovaPopover.vue";
import NButton from "@/components/NovaButton.vue";
import utils from "@/utils";

export default {
  name: "PackageCard",
  components: {
    NButton,
    NChip,
    NPopover,
    PackageDetailsSidesheet,
    PackageTestsCountChip,
  },
  props: {
    groupedCheckupPackage: {
      type: Object,
      default: () => {},
    },
    isReviewPage: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState(["user"]),
    ...mapGetters(["ahcProspectState", "isCheckupAdmin"]),
    isMobile() {
      return utils.mobileCheck();
    },
    benefitId() {
      return this.$route.params.benefitId;
    },
    bookingUser() {
      if (this.isCheckupAdmin) return this.$route.params?.bookingUser;
      return this.user;
    },
    checkupPackages() {
      return this.groupedCheckupPackage?.checkupPackages || [];
    },
    sampleCollectionConfig() {
      let sampleCollectionConfig = checkupUtils.getSampleCollectionTitleAndIcon(
        this.checkupPackages[0]?.sampleCollectionType || ""
      );
      if (this.checkupPackages?.length > 1) {
        sampleCollectionConfig = { ...sampleCollectionConfig, icon: "grouped" };
      }
      return sampleCollectionConfig;
    },
    availableForDescription() {
      if (!this.usersWithEligibleCheckupPackage?.length) return "";
      const availableRelations = this.usersWithEligibleCheckupPackage.map(({ relation }) => relation);
      return checkupUtils.availableForDescription(availableRelations) || "";
    },
    freeForDescription() {
      return checkupUtils.freeForDescription(this.groupedCheckupPackage?.updatedPackageFreeFor) || "";
    },
    isFreePackageUtilized() {
      const checkupPackages = this.usersWithEligibleCheckupPackage
        .filter((member) => member.isMemberEligibleForFreePackage)
        .map(({ checkupPackage }) => checkupPackage);
      const packagesWithFreeFor = checkupPackages.some(
        ({ packageFreeFor }) =>
          packageFreeFor && !Object.values(packageFreeFor).every((grade) => ["None", "None selected"].includes(grade))
      );

      return packagesWithFreeFor && !this.groupedCheckupPackage.updatedPackageFreeFor.length;
    },
    isSinglePaymentLinearPackage() {
      return this.checkupPackages?.some(({ meta }) => meta.isSinglePaymentLinearPackage);
    },
    payableForDescription() {
      const { isSelfOnlyPackage = false } = this.groupedCheckupPackage || {};
      const isSelfOnlyEligiblePackage =
        this.usersWithEligibleCheckupPackage?.length === 1 && !this.usersWithEligibleCheckupPackage[0].relation;
      const isMembersWithPaidDependentPackage = this.usersWithEligibleCheckupPackage.some(
        ({ relation, isPaidAppointment }) => relation && isPaidAppointment
      );

      if (this.isFreePackageUtilized && !this.freeForDescription && !this.isSinglePaymentLinearPackage)
        return "Your free packages has been utilised";

      return !isSelfOnlyPackage &&
        !isSelfOnlyEligiblePackage &&
        isMembersWithPaidDependentPackage &&
        !(this.isSinglePaymentLinearPackage && this.freeForDescription)
        ? `Payable for ${this.freeForDescription ? "other dependents" : "everyone"}`
        : "";
    },
    packageCost() {
      const combinedPackageCost = this.checkupPackages
        .map((checkupPackage) => checkupPackage?.novaProduct?.netCost)
        .filter(Boolean);
      return Math.min(...(combinedPackageCost || []));
    },
    packageTestCount() {
      if (!this.usersWithEligibleCheckupPackage?.length) return 0;
      const groupedPackageTestCount = this.usersWithEligibleCheckupPackage
        .map(({ checkupPackage }) => checkupPackage?.checkupTestComponents?.length || 0)
        .filter(Boolean);
      return groupedPackageTestCount.length ? Math.max(...groupedPackageTestCount) : 0;
    },
    groupCheckupPackageIcon() {
      return this.isGroupPackage ? "grouped" : this.sampleCollectionConfig.icon;
    },
    minimalCheckupTestComponents() {
      const minimalCheckupTestComponents = [];
      const checkupTestComponents = checkupUtils.mergeCheckupTestComponents(this.checkupPackages);
      const checkupTestComponentsGroups = checkupTestComponents.map(
        (checkupTestComponent) =>
          checkupTestComponent?.partnerComponentGroup || checkupTestComponent?.partnerComponentName
      );
      [...new Set(checkupTestComponentsGroups || [])]
        .sort((a, b) => (a || "").length - (b || "").length)
        .map((checkupTestComponentsGroup) => {
          if (minimalCheckupTestComponents.join().length + (checkupTestComponentsGroup || "").length < 90) {
            minimalCheckupTestComponents.push(checkupTestComponentsGroup);
          }
        });
      return minimalCheckupTestComponents;
    },
    linearPackageName() {
      const { linearPackage = {} } = this.checkupPackages[0] || {};
      return linearPackage?.groupName || linearPackage?.name;
    },
    isLinearPackagePending() {
      const { isLinearPackageGroup, linearPackageCompletedUsers } = this.groupedCheckupPackage;
      return isLinearPackageGroup && !linearPackageCompletedUsers?.length;
    },
    sidesheetId() {
      const packageIds =
        this.groupedCheckupPackage?.checkupPackages?.map((checkupPackage) => checkupPackage.id)?.join("-") || "";
      return `sidesheet-${packageIds}`;
    },
    modalId() {
      const packageIds =
        this.groupedCheckupPackage?.checkupPackages?.map((checkupPackage) => checkupPackage.id)?.join("-") || "";
      return `modal-${packageIds}`;
    },
    isBookPackageButtonVisible() {
      return this.checkupPackages[0]?.sampleCollectionType !== CheckupPackageSampleCollectionType.ONSITE;
    },
    isGroupPackage() {
      return this.groupedCheckupPackage?.checkupPackages?.length > 1;
    },
    isPriceVariable() {
      const combinedPackageCost = this.checkupPackages
        .map((checkupPackage) => checkupPackage?.novaProduct?.netCost)
        .filter(Boolean);
      return new Set(combinedPackageCost).size !== 1;
    },
    maximumRetailPrice() {
      const groupedMRP = this.checkupPackages
        .map(({ meta }) => meta?.maximumRetailPrice)
        .filter(Boolean)
        .map((e) => parseInt(e) || 0);
      return !groupedMRP?.length ? 0 : Math.max(...groupedMRP);
    },
    usersWithEligibleCheckupPackage() {
      return (
        checkupUtils.getUsersWithEligibleCheckupPackage(
          this.bookingUser,
          this.ahcProspectState.extraMembers,
          this.groupedCheckupPackage
        ) || []
      );
    },
  },
  // TODO : Add logic to open sidesheet for particular package directly when route is packages/:packageId
  methods: {
    formatAmountWithRupee,
    async createAHCProspectState() {
      try {
        const checkupPackageIds = this.groupedCheckupPackage?.checkupPackages?.map(({ id }) => id);
        const result = await this.$apollo.mutate({
          mutation: resDefs.prospects.upsertMutation,
          variables: {
            email: this.bookingUser?.email,
            meta: {
              prospectType: "ahc",
              userId: this.bookingUser?.id,
              benefitId: this.benefitId,
              packageIds: checkupPackageIds,
              ahcProspectState: {
                ...this.ahcProspectState,
                selectedGroupPackage: this.groupedCheckupPackage,
                contactDetails: {
                  email: this.bookingUser?.email,
                  phoneNumber: this.bookingUser?.meta?.contactNumber,
                },
              },
            },
          },
        });
        return result?.data?.upsertProspect?.prospect || {};
      } catch (err) {
        console.log(err);
        this.$store.commit("addToast", {
          variant: "danger",
          message: "Unable to create prospect state, Please reach out to Nova's customer support.",
        });
      }
    },
    async bookPackage() {
      if (this.isSinglePaymentLinearPackage && this.freeForDescription && this.isLinearPackagePending) {
        this.$bvModal.show(this.modalId);
        return;
      }
      window.posthog.capture("checkup_package_clicked_book_package");
      if (!this.groupedCheckupPackage?.checkupPackages?.length) {
        this.$store.commit("addToast", {
          variant: "danger",
          message: "Unable to find checkup packages, Please reach out to Nova's customer support.",
        });
      }
      const prospectState = await this.createAHCProspectState();
      const { meta: prospectMeta } = prospectState;
      this.$store.commit("saveAhcProspectState", { ...(prospectState || {}) });
      this.$router.push({
        name: "bookingForm",
        params: {
          bookingUser: this.bookingUser,
          benefitId: prospectMeta?.benefitId,
          ahcNanoId: prospectMeta?.nanoId,
        },
      });
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/_variables.scss";
.package-sidesheet-style {
  position: relative;
  z-index: 6000;
}
.transparent-teal-background {
  border: solid 1px $teal-200;
  background: rgba(236, 253, 252, 0.5);
}
.overflow-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.package-card-divider {
  border: 0px solid $gray-300;
  border-top-width: 0.031rem !important;
}
</style>
