export const Base = require("./Base.vue").default;
export const routes = [
  {
    path: "medibuddy",
    component: () =>
      import("./Medibuddy.vue"),
    name: "medibuddy",
  },
  {
    path: "zyla",
    component: () =>
      import("./ZylaPwa.vue"),
    name: "zyla_pwa",
  },
  {
    path: "ekincare",
    component: () =>
      import("./EkincarePwa.vue"),
    name: "ekincare_pwa",
  },
];
