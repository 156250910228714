<template lang="pug">
b-card(:body-class="cardBodyClasses")
  b-table(
    responsive,
    :tbody-tr-class="rowClass",
    :items="items",
    :fields="fields",
    table-class="nova-table",
    v-bind="$attrs",
    v-on="$listeners")
    template(v-slot:thead-top)
      slot(name="thead-top")
    template(v-for="field in fields", v-slot:[getSlotName(field.key)]="data")
      slot(:name="field.key", :data="data")
        span {{ data.value }}
    template(v-slot:custom-foot)
      slot(name="custom-foot")
    template(v-slot:row-details="data")
      slot(name="row-details", :data="data")
</template>

<script>
import { orgOnboardingStageTaskStatusSchema } from "../portal/pages/admin/constants";
import EmptyStates from "@/components/Cards/EmptyStates/EmptyStateCard.vue";
export default {
  components: {
    EmptyStates,
  },
  props: {
    fields: {
      type: Array,
      default: () => [],
    },
    items: {
      type: Array,
      default: () => [],
    },
    cardBodyClassList: {
      type: Array,
      default: () => [],
    },
    tbodyTrClass: {
      type: [String, Array],
    },
  },
  computed: {
    cardBodyClasses() {
      return [...this.cardBodyClassList, "nova-table-wrapper"];
    },
  },
  methods: {
    getSlotName(col) {
      return `cell(${col})`;
    },
    getRowBgClass(status) {
      switch (status) {
        case "overdue":
          return " bg-red-100";
        case orgOnboardingStageTaskStatusSchema.COMPLETED:
          return " bg-malibu-100";
        case orgOnboardingStageTaskStatusSchema.IN_PROGRESS:
        case orgOnboardingStageTaskStatusSchema.ON_HOLD:
          return " bg-alabaster";
        default:
          return "";
      }
    },
    rowClass(item, type) {
      if (!item || type !== "row") return this.tbodyTrClass;
      if (Array.isArray(this.tbodyTrClass)) this.tbodyTrClass = this.tbodyTrClass.join(" ");
      return this.tbodyTrClass + this.getRowBgClass(item.status);
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  box-shadow: none;
}
</style>

<style lang="scss">
@import "@/assets/styles/_variables.scss";

.nova-table-wrapper {
  border: 1px solid $gray-300;
  border-radius: 0.5rem;
  padding: 0rem;
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;

  .table-responsive {
    margin: 0;
  }

  .nova-table {
    thead {
      tr:last-of-type {
        background-color: $alabaster;
      }
      th {
        border-top: 0;
        font-size: 0.625rem;
        color: $gray-900;
        font-weight: 600;
      }
    }

    tbody {
      tr {
        border: 1px solid $gray-300;
        border-left: 0;
        border-right: 0;

        &:last-of-type {
          border-bottom: 0;
          td {
            border-bottom: 0;
            &:first-of-type {
              border-left: 0;
              border-bottom: 0;
            }
            &:last-of-type {
              border-right: 0;
              border-bottom: 0;
            }
          }
        }

        td {
          color: $gray-800;
          font-weight: 500;
          font-size: 0.75rem;
        }
      }
    }
  }
}
</style>
