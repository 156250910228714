import gql from "graphql-tag";
import { designationFragment } from "../fragments";

export default {
  upsertMutation: gql`
    mutation upsertDesignation($id: ID, $name: String!, $status: String) {
      upsertDesignation(input: { id: $id, name: $name, status: $status }) {
        designation {
          ...Designation
        }
      }
    }
    ${designationFragment}
  `,
};
