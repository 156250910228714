import { IntegrationType } from "./integrationTypes.enum";

export const IntegrationsWithUserManagement = {
  [IntegrationType.GSUITE]: IntegrationType.GSUITE,
  [IntegrationType.DARWINBOX]: IntegrationType.DARWINBOX,
  [IntegrationType.KEKA]: IntegrationType.KEKA,
  [IntegrationType.ZOHOPEOPLE]: IntegrationType.ZOHOPEOPLE,
  [IntegrationType.ZING]: IntegrationType.ZING,
  [IntegrationType.BAMBOOHR]: IntegrationType.BAMBOOHR,
  [IntegrationType.GREYTHR]: IntegrationType.GREYTHR,
};
