import gql from "graphql-tag";

import adminDefs from "../admin/definitions";
import { strapiContentFragment } from "../admin/fragments";
import { fitnessActivityList } from "./constants";

export const challengeTemplateFragment = gql`
  fragment ChallengeTemplateWithContent on ChallengeTemplate {
    id
    name
    challenge
    challengeContent {
      ...StrapiContent
    }
    activityTypes
    activityTypesContent
    duration
    baseGoalValue
    points
    meta
    updatedAt
  }
  ${strapiContentFragment}
`;

const orgChallengeFragment = gql`
  fragment OrgChallengeWithContent on OrgChallenge {
    id
    challengeTemplate {
      ...ChallengeTemplateWithContent
    }
    org {
      id
      name
    }
    user {
      id
      name
      photoFile {
        id
        url
      }
    }
    parentChallenge {
      id
    }
    userContributions {
      id
      currentTotal
      user {
        id
        name
        photoFile {
          id
          url
        }
      }
    }
    goalValue
    currentTotal
    startDate
    startTime
    expiryDate
    expiryTime
    challengeStatus
    points
    meta
    updatedAt
  }
  ${challengeTemplateFragment}
`;

const userChallengeFragment = gql`
  fragment UserChallengeWithContent on UserChallenge {
    id
    challengeTemplate {
      ...ChallengeTemplateWithContent
    }
    org {
      id
      name
    }
    user {
      id
      name
      photoFile {
        id
        url
      }
    }
    goalValue
    currentTotal
    startDate
    startTime
    expiryDate
    expiryTime
    challengeStatus
    points
    meta
    updatedAt
  }
  ${challengeTemplateFragment}
`;

const ngageActivityFragment = gql`
  fragment NgageActivity on NgageActivity {
    id
    user {
      id
      displayName
      name
      photoFile {
        id
        url
      }
    }
    activityType
    activityValue
    givenValue
    givenUnits
    relevantChallenges {
      ... on UserChallenge {
        ...UserChallengeWithContent
        __typename
      }
      ... on OrgChallenge {
        ...OrgChallengeWithContent
        __typename
      }
    }
    meta
    createdAt
    updatedAt
  }
  ${orgChallengeFragment}
  ${userChallengeFragment}
`;

export const gqlResourceConfig = {
  NgageActivity: {
    plural: "ngageActivities",
    fragment: ngageActivityFragment,
    fragmentName: "NgageActivity",
  },
  ChallengeTemplate: {
    plural: "challengeTemplates",
    fragment: challengeTemplateFragment,
    fragmentName: "ChallengeTemplateWithContent",
  },
  OrgChallenge: {
    plural: "orgChallenges",
    fragment: orgChallengeFragment,
    fragmentName: "OrgChallengeWithContent",
  },
  UserChallenge: {
    plural: "userChallenges",
    fragment: userChallengeFragment,
    fragmentName: "UserChallengeWithContent",
  },
};

export const getSingleQuery = (gqlType) => {
  const { fragment, fragmentName } = gqlResourceConfig[gqlType];
  const query = gql`query ${gqlType}Single($id: ID!){
    node(id: $id){ ...${fragmentName} }
  }
  ${fragment}`;
  return query;
};

export const getListQuery = (gqlType) => {
  const { plural, fragment, fragmentName } = gqlResourceConfig[gqlType];
  const query = gql`
  query ${gqlType}List(
      $query: String
      $offset: Int
      $limit: Int
      $orgId: ID
      $userId: ID
    ) {
      ${plural}(
        query: $query
        offset: $offset
        limit: $limit
        orgId: $orgId
        userId: $userId
      ) {
      edges {
        node { ...${fragmentName} }
      }
      totalCount
    }
  }
  ${fragment}`;
  return query;
};

export default {
  ngageActivities: {
    gqlType: "NgageActivity",
    listQuery: gql`
      query ngageActivitiesList(
        $userId: ID
        $orgId: ID
        $limit: Int
        $startDate: String
        $endDate: String
        $autoRecordedActivitiesOnly: Boolean
      ) {
        ngageActivities(
          userId: $userId
          orgId: $orgId
          limit: $limit
          startDate: $startDate
          endDate: $endDate
          autoRecordedActivitiesOnly: $autoRecordedActivitiesOnly
        ) {
          ...NgageActivity
        }
      }
      ${ngageActivityFragment}
    `,
    fitnessChallengeFeedQuery: gql`
      query fitnessChallengeFeed($userId: ID, $orgId: ID, $challengeId: ID, $limit: Int) {
        fitnessChallengeFeed(userId: $userId, orgId: $orgId, challengeId: $challengeId, limit: $limit) {
          ...NgageActivity
          meta
        }
      }
      ${ngageActivityFragment}
    `,
    fitnessUserFeedQuery: gql`
      query fitnessUserFeed($userId: ID, $orgId: ID, $limit: Int) {
        fitnessUserFeed(userId: $userId, orgId: $orgId, limit: $limit) {
          ... on NgageActivity {
            ...NgageActivity
            __typename
          }
          ... on UserChallenge {
            ...UserChallengeWithContent
            __typename
          }
        }
      }
      ${ngageActivityFragment}
    `,
    fitnessOrgFeedQuery: gql`
      query fitnessOrgFeed($userId: ID, $orgId: ID, $limit: Int) {
        fitnessOrgFeed(userId: $userId, orgId: $orgId, limit: $limit) {
          ... on NgageActivity {
            ...NgageActivity
            __typename
          }
          ... on UserChallenge {
            ...UserChallengeWithContent
            __typename
          }
          ... on OrgChallenge {
            ...OrgChallengeWithContent
            __typename
          }
        }
      }
      ${ngageActivityFragment}
    `,
    createMutation: gql`
      mutation createMutation(
        $id: ID
        $meta: JSONObject
        $userId: ID
        $orgId: ID
        $activityType: String
        $givenValue: Float
        $givenUnits: String
        $createdAt: String
        $updatedAt: String
      ) {
        upsertNgageActivity(
          input: {
            id: $id
            meta: $meta
            userId: $userId
            orgId: $orgId
            activityType: $activityType
            givenValue: $givenValue
            givenUnits: $givenUnits
            createdAt: $createdAt
            updatedAt: $updatedAt
          }
        ) {
          ngageActivity {
            ...NgageActivity
          }
        }
      }
      ${ngageActivityFragment}
    `,
    activities: {
      ...fitnessActivityList,
      challengeCompleted: {
        icon: "trophy",
        bgColor: "warning",
        hideFromList: true,
      },
    },

    transform: (n) => {
      return { ...n };
    },
  },
  orgProperties: {
    singleQueryByName: adminDefs.orgProperties.singleQueryByName,
  },
  challengeTemplates: {
    ...adminDefs.challengeTemplates,
    singleQuery: getSingleQuery("ChallengeTemplate"),
  },
  orgChallenges: {
    ...adminDefs.orgChallenges,
    singleQuery: getSingleQuery("OrgChallenge"),
  },
  userChallenges: {
    ...adminDefs.userChallenges,
    singleQuery: getSingleQuery("UserChallenge"),
  },
  ngageUserDetails: {
    ...adminDefs.ngageUserDetails,
  },
  challengeFeed: {
    getIcon: (activity, resDef) => {
      if (activity.challengeTemplate) {
        return resDef.activities.challengeCompleted.icon;
      }
      if (resDef.activities[activity.activityType]) {
        return resDef.activities[activity.activityType].icon;
      }
      return "running";
    },
    getBgColor: (activity, resDef) => {
      if (activity.challengeTemplate) {
        return resDef.activities.challengeCompleted.bgColor;
      }
      if (resDef.activities[activity.activityType]) {
        return resDef.activities[activity.activityType].bgColor;
      }
      return "secondary";
    },
    getActivityText: (activity, resDef) => {
      if (resDef.activities[activity.activityType]) {
        return resDef.activities[activity.activityType].text;
      }
      return activity.activityType;
    },
  },
};
