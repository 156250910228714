<template lang="pug">
.package-details-sidesheet(v-if="groupedCheckupPackage")
  n-sidesheet.benefit-details-sidesheet(
    :ssId="sidesheetId",
    :headerName="sidesheetTitle",
    :hideTabs="true",
    @hidden="closeHandler",
    :onChange="toggleBodyScrollbar",
    ref="packageDetailsSidesheet",
    :infoText="infoText",
    shadow,
    backdrop)
    template(v-slot:content)
      .d-flex.flex-column.bg-gray-100.border.border-gray-300.rounded-8.mx-3.my-2.p-2(v-for="[index, userOrDependent] of usersWithEligibleCheckupPackage.entries()")
        .d-flex.justify-content-between.cursor-pointer.p-2(@click="toggleAccordion(index)")
          .d-flex.flex-column.justify-content-around
            .text-gray-900.font-md.font-weight-medium.mb-1.text-capitalize {{ userOrDependent.name }}
            .text-gray-700.font-sm.font-weight-medium {{ `${userOrDependent.formattedRelation} • ${userOrDependent.gender} • ${userOrDependent.age} Years` }}
          span.text-blue-600.font-sm.font-weight-semibold.d-inline-flex.justify-content-start.align-items-center.text-nowrap
            | {{ userOrDependent.checkupPackage?.checkupTestComponents?.length }} tests
            n-icon(:name="`chevron-${isNthAccordionVisible(index) ? 'up' : 'down'}`", :size="1", variant="blue-600")
        b-collapse(:visible="isNthAccordionVisible(index)", role="tabpanel")
          .package-details-sidesheet-divider.my-2.mx-1
          template(v-for="group of groupCheckupTestComponents(userOrDependent.checkupPackage)")
            .font-sm.font-weight-semibold.p-1 {{ group.partnerComponentGroup }}
            template(v-for="checkupTestComponentName of group.checkupTestComponents")
              .d-flex.align-items-center.px-2.py-1
                n-icon.text-teal-700.mr-2(name="check-circle", :size="0.9")
                span.font-sm.font-weight-semibold.text-gray-800 {{ checkupTestComponentName }}
    template(v-slot:footer, v-if="isPackageSelectionScreen")
      .sidesheet-footer.d-flex.justify-content-between.align-items-center.px-3.py-2.bg-white
        .d-flex.flex-column.align-items-start.justify-content-around
          span(v-if="freeForDescription")
            span.text-gray-700.font-xs.font-weight-medium.mr-2.strikethrough(v-if="maximumRetailPrice && !isSinglePaymentLinearPackage") {{ formatAmountWithRupee(maximumRetailPrice) }}
            span.text-teal-800.font-sm.font-weight-bold.mr-2(v-if="isSinglePaymentLinearPackage && linearPackageName") Covered with {{ linearPackageName }}
            span.text-teal-800.font-sm.font-weight-bold(v-else) Free for {{ freeForDescription }}
          span(v-else)
            span.text-gray-700.font-xs.font-weight-medium.mr-2(v-if="isGroupPackage && isPriceVariable") starting from
            span.text-gray-700.font-xs.font-weight-medium.mr-2.strikethrough(v-else-if="maximumRetailPrice") {{ formatAmountWithRupee(maximumRetailPrice) }}
            span.text-gray-800.font-md.font-weight-semibold {{ formatAmountWithRupee(packageCost) }}
          .text-gray-700.font-xs.font-weight-medium(v-if="payableForDescription") {{ payableForDescription }}
        n-button.align-self-center.text-nowrap(v-if="isBookPackageButtonVisible"
          :disabled="isBookPackageButtonDisabled",
          variant="dark",
          size="lg",
          :buttonText="isMobile ? 'Book' : 'Book Package'",
          rightImageIcon="chevron-right",
          @click="bookPackage")
</template>

<script>
import { mapState, mapGetters } from "vuex";
import * as checkupUtils from "../checkupUtils";
import { formatAmountWithRupee } from "../../../../utils/misc";
import NButton from "@/components/NovaButton.vue";
import NSidesheet from "@/components/NovaSideSheet";
import utils from "@/utils";
export default {
  name: "PackageDetailsSidesheet",
  components: {
    NButton,
    NSidesheet,
  },
  props: {
    title: {
      type: String,
      default: "Package details for",
    },
    sidesheetId: {
      type: String,
      default: "",
    },
    isBookPackageButtonVisible: {
      type: Boolean,
      default: false,
    },
    isBookPackageButtonDisabled: {
      type: Boolean,
      default: false,
    },
    groupedCheckupPackage: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      visibleAccordion: 1,
      infoText: "Test numbers vary by age and gender of a member.",
    };
  },
  computed: {
    ...mapState(["user"]),
    ...mapGetters(["ahcProspectState", "isCheckupAdmin"]),
    isMobile() {
      return utils.mobileCheck();
    },
    bookingUser() {
      if (this.isCheckupAdmin) return this.$route.params?.bookingUser;
      return this.user;
    },
    checkupPackages() {
      return this.groupedCheckupPackage?.checkupPackages || [];
    },
    selectedMembers() {
      return this.ahcProspectState?.selectedMembers || [];
    },
    usersWithEligibleCheckupPackage() {
      return (
        checkupUtils.getUsersWithEligibleCheckupPackage(
          this.bookingUser,
          this.ahcProspectState.extraMembers,
          this.groupedCheckupPackage,
          this.selectedMembers
        ) || []
      );
    },
    sidesheetTitle() {
      return this.title;
    },
    freeForDescription() {
      return checkupUtils.freeForDescription(this.groupedCheckupPackage?.updatedPackageFreeFor) || "";
    },
    payableForDescription() {
      const { isSelfOnlyPackage = false } = this.groupedCheckupPackage || {};
      const isSelfOnlyEligiblePackage =
        this.usersWithEligibleCheckupPackage?.length === 1 && !this.usersWithEligibleCheckupPackage[0].relation;
      const isMembersWithPaidDependentPackage = this.usersWithEligibleCheckupPackage.some(
        ({ relation, isPaidAppointment }) => relation && isPaidAppointment
      );

      if (this.isFreePackageUtilized && !this.freeForDescription && !this.isSinglePaymentLinearPackage)
        return "Your free packages has been utilised";

      return !isSelfOnlyPackage &&
        !isSelfOnlyEligiblePackage &&
        isMembersWithPaidDependentPackage &&
        !(this.isSinglePaymentLinearPackage && this.freeForDescription)
        ? `Payable for ${this.freeForDescription ? "other dependents" : "everyone"}`
        : "";
    },
    isPackageSelectionScreen() {
      return this.$route.path.includes("schedule-booking");
    },
    isFreePackageUtilized() {
      const checkupPackages = this.usersWithEligibleCheckupPackage
        .filter((member) => member.isMemberEligibleForFreePackage)
        .map(({ checkupPackage }) => checkupPackage);
      const packagesWithFreeFor = checkupPackages.some(
        ({ packageFreeFor }) =>
          packageFreeFor && !Object.values(packageFreeFor).every((grade) => ["None", "None selected"].includes(grade))
      );

      return packagesWithFreeFor && !this.groupedCheckupPackage.updatedPackageFreeFor.length;
    },
    isGroupPackage() {
      return this.checkupPackages?.length > 1;
    },
    isPriceVariable() {
      const combinedPackageCost = this.checkupPackages
        ?.map((checkupPackage) => checkupPackage?.novaProduct?.netCost)
        .filter(Boolean);
      return new Set(combinedPackageCost).size !== 1;
    },
    isSinglePaymentLinearPackage() {
      return this.checkupPackages?.some(({ meta }) => meta.isSinglePaymentLinearPackage);
    },
    linearPackageName() {
      const { linearPackage = {} } = this.checkupPackages[0] || {};
      return linearPackage?.groupName || linearPackage?.name;
    },
    packageCost() {
      const combinedPackageCost = this.checkupPackages
        ?.map((checkupPackage) => checkupPackage?.novaProduct?.netCost)
        .filter(Boolean);
      return Math.min(...(combinedPackageCost || []));
    },
    maximumRetailPrice() {
      const groupedMRP = this.checkupPackages
        .map(({ meta }) => meta?.maximumRetailPrice)
        .filter(Boolean)
        .map((e) => parseInt(e) || 0);
      return !groupedMRP?.length ? 0 : Math.max(...groupedMRP);
    },
  },
  watch: {
    usersWithEligibleCheckupPackage: {
      handler: function (members) {
        this.visibleAccordion = members.length === 1 ? 0 : 1;
      },
      deep: true,
    },
  },
  beforeDestroy() {
    this.toggleBodyScrollbar(false);
  },
  methods: {
    formatAmountWithRupee,
    toggleBodyScrollbar(visible) {
      const body = document.getElementsByTagName("body")?.[0];
      if (visible) body.classList.add("overflow-hidden");
      else body.classList.remove("overflow-hidden");
    },
    async bookPackage() {
      this.$refs.packageDetailsSidesheet.$refs.novaSidesheet.hide();
      this.$emit("book-package", this.groupedCheckupPackage);
    },
    closeHandler() {
      this.visibleAccordion = 1;
      this.$refs.packageDetailsSidesheet.$refs.novaSidesheet.hide();
    },
    groupCheckupTestComponents(checkupPackage) {
      if (!checkupPackage?.checkupTestComponents?.length) return [];

      const groupedCheckupTestComponents = checkupPackage.checkupTestComponents.reduce(
        (accumulator, checkupTestComponent) => {
          const { partnerComponentName, partnerComponentGroup } = checkupTestComponent || {};
          if (!accumulator[partnerComponentGroup]) {
            accumulator[partnerComponentGroup] = { partnerComponentGroup, checkupTestComponents: [] };
          }
          accumulator[partnerComponentGroup].checkupTestComponents.push(partnerComponentName);
          return accumulator;
        },
        {}
      );
      return Object.values(groupedCheckupTestComponents || {})
        .map((groupedCheckupTestComponent) => {
          groupedCheckupTestComponent.checkupTestComponents.sort();
          return groupedCheckupTestComponent;
        })
        .sort((a, b) => (a.partnerComponentGroup || "").localeCompare(b.partnerComponentGroup || ""));
    },
    toggleAccordion(index) {
      this.visibleAccordion = this.visibleAccordion !== index ? index : -1;
    },
    isNthAccordionVisible(index) {
      return this.visibleAccordion === index;
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/_variables.scss";
.package-details-sidesheet {
  z-index: 6001 !important;
  .sidesheet-footer {
    border-top: 1px solid $gray-300;
    min-height: 4rem;
  }
  .package-details-sidesheet-divider {
    border: 0px solid $gray-400;
    border-top-width: 0.031rem !important;
  }
}
</style>
