<template lang="pug">
b-nav.my-2(pills, vertical, card-header)
  b-nav-item(
    v-for="item in getNavItems",
    :key="item.route",
    :to="`/user${item.route}`",
    active-class="profile-nav-item-active",
    link-classes="profile-nav-item rounded-0 text-gray-700 py-1 my-1"
  )
    .d-flex.align-items-center.my-1
      i.mr-2(:class="`icon-${item.icon}`")
      span.profile-nav-content.font-weight-medium {{ item.label }}
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      navItems: [
        {
          route: "/profile/details",
          icon: "human-head",
          label: "Basic Details",
        },
        {
          route: "/profile/about",
          icon: "men",
          label: "About You",
        },
        {
          route: "/profile/contact-details",
          icon: "at-the-rate",
          label: "Contact Details",
        },
        {
          route: "/dependents",
          icon: "users",
          label: "Dependents",
        },
        {
          route: "/profile/security",
          icon: "key",
          label: "Security",
        },
        {
          route: "/profile/cookie-preference",
          icon: "cookie-preference",
          label: "Cookie Preferences",
        },
        {
          route: "/profile/data-deletion",
          icon: "warning",
          label: "Data Deletion",
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["user"]),
    getNavItems() {
      let navItems = [...this.navItems];
      if (this.user.hasAssessmentReports) {
        const routeExists = navItems.some((item) => item.route === "/profile/reports");
        if (!routeExists) {
          navItems.push({
            route: "/profile/reports",
            icon: "document-new",
            label: "Your Reports",
          });
        }
      }
      return navItems;
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/_variables.scss";
.profile-nav-item:hover {
  color: $gray-800;
}
.profile-nav-item-active {
  background-color: unset;
  color: $gray-900 !important;
  border-right: 2px solid $gray-900 !important;
  .profile-nav-content {
    font-weight: 600 !important;
  }
}
</style>
