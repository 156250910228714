export const Base = require("./Base.vue").default;
export const routes = [
  {
    path: "",
    component: () => import(/* webpackChunkName: "org_admin" */ "./Home.vue"),
    name: "org_admin_home",
  },
  {
    path: "auth",
    component: () => import(/* webpackChunkName: "org_admin" */ "./Auth.vue"),
    name: "auth",
  },
  {
    path: "integrations",
    component: () => import(/* webpackChunkName: "org_admin" */ "./integrations/Integrations.vue"),
    name: "org_admin_integrations",
  },
  {
    path: "organization",
    component: () => import(/* webpackChunkName: "org_admin" */ "./Organization/Base.vue"),
    name: "org_admin_organization",
  },
  {
    path: "analytics",
    component: () => import(/* webpackChunkName: "org_admin" */ "../analytics/Base.vue"),
    name: "org_admin_analytics",
    meta: {
      featureFlags: ["HR_ANALYTICS"],
    },
  },
  {
    path: "analytics/:collection",
    component: () => import(/* webpackChunkName: "org_admin" */ "../analytics/Base.vue"),
    name: "org_admin_analytics_collection",
    meta: {
      featureFlags: ["HR_ANALYTICS"],
    },
  },
  {
    path: "analytics/:collection/:dashboardId",
    component: () => import(/* webpackChunkName: "org_admin" */ "../analytics/Base.vue"),
    name: "org_admin_analytics_dashboard_id",
    meta: {
      featureFlags: ["HR_ANALYTICS"],
    },
  },
  {
    path: "analytics/:collection/:dashboardId/:dashboardName",
    component: () => import(/* webpackChunkName: "org_admin" */ "../analytics/MetabaseDashboard.vue"),
    name: "org_admin_analytics_dashboard",
    meta: {
      featureFlags: ["HR_ANALYTICS"],
    },
  },
  {
    path: "organization/integrations",
    component: () => import(/* webpackChunkName: "org_admin" */ "./Organization/Base.vue"),
    name: "org_admin_organization",
  },
  {
    path: "organization/auth",
    component: () => import(/* webpackChunkName: "org_admin" */ "./Organization/Base.vue"),
    name: "org_admin_organization",
  },
  {
    path: "integrations/review-hrms-data/:type/:tab",
    component: () => import(/* webpackChunkName: "org_admin" */ "./integrations/hrms/Base.vue"),
    name: "integrations_review_hrms_data",
    meta: { allowedRoles: ["*"] },
  },
  {
    path: "integrations/:type",
    component: () => import(/* webpackChunkName: "org_admin" */ "./integrations/Integrations.vue"),
    name: "org_admin_hrms_integration_data",
  },
  {
    path: "review-user-changes",
    component: () => import(/* webpackChunkName: "org_admin" */ "./ReviewChanges.vue"),
    name: "org_admin_review",
  },
  {
    path: "changes",
    component: require("../endorsements/Base.vue").default,
    children: require("../endorsements").routes,
    meta: { allowedRoles: ["*"], featureFlags: ["ENDORSEMENTS"] },
    name: "org_admin_endorsements",
  },
  {
    path: "changes/overview/:batchId",
    component: require("../endorsements/Overview/Base.vue").default,
    children: require("../endorsements").routes,
    name: "org_admin_endorsements_overview",
  },
  {
    path: "changes/premium-details/:batchId",
    name: "org_admin_premium_details",
    component: require("../endorsements/PremiumDetails.vue").default,
    children: require("../endorsements").routes,
    meta: { featureFlags: ["PREMIUM_BREAKUP"] },
  },
  {
    path: "cd-accounts",
    component: () => import(/* webpackChunkName: "org_admin" */ "./CdAccounts.vue"),
    name: "CD Accounts",
  },
  {
    path: "org-hierarchy",
    component: () => import(/* webpackChunkName: "org_admin" */ "./OrgHierarchy/Base.vue"),
    name: "Org Hierarchy",
    meta: {
      allowedRoles: ["org_admin"],
    },
  },
  {
    path: "members",
    component: () => import(/* webpackChunkName: "org_admin" */ "./MembersList.vue"),
    name: "org_admin_members_list",
  },
  {
    path: "members/create",
    component: () => import(/* webpackChunkName: "org_admin" */ "./MemberCreate.vue"),
    name: "org_admin_member_create",
  },
  {
    path: "members/invite",
    component: () => import(/* webpackChunkName: "org_admin" */ "./MemberCreate.vue"),
    name: "org_admin_member_invite",
  },
  {
    path: "members/onboard-users",
    component: () => import(/* webpackChunkName: "bulk_upload" */ "./OnboardUsers/Base.vue"),
    name: "org_admin_member_onboard",
    meta: { featureFlags: ["BULK_UPLOAD_USERS"] },
  },
  {
    path: "members/bulk-delete-users",
    component: () => import(/* webpackChunkName: "bulk_upload" */ "./DeleteUsers/Base.vue"),
    name: "org_admin_bulk_delete",
    meta: { featureFlags: ["BULK_DELETE_USERS"] },
  },
  {
    path: "members/:id/edit",
    component: () => import(/* webpackChunkName: "org_admin" */ "./MemberCreate.vue"),
    name: "org_admin_member_edit",
  },
  {
    path: "onboarding",
    component: () => import(/* webpackChunkName: "org_admin" */ "./HrOnboarding/Base.vue"),
    name: "org_admin_onboarding",
    children: require("./HrOnboarding").routes,
    meta: {
      featureFlags: ["HR_ONBOARDING"],
    },
  },
  {
    path: ":resource(claims)",
    component: () => import(/* webpackChunkName: "org_admin" */ "./ResourceList.vue"),
    name: "org_admin_claims_list",
    meta: {
      featureFlags: ["CLAIMS_PORTAL_EXPERIENCE"],
      disableForWellnessOnlyOrgs: true,
    },
  },
  {
    path: ":resource(echoPostTemplates)",
    component: () => import(/* webpackChunkName: "org_admin" */ "./ResourceList.vue"),
    name: "org_admin_echo_post_templates_list",
    meta: {
      featureFlags: ["ECHO_INTEGRATION"],
    },
  },
  {
    path: ":resource(echoPostTemplates)/create",
    component: () => import(/* webpackChunkName: "org_admin" */ "./ResourceSingle.vue"),
    name: "org_admin_echo_post_templates_create",
    meta: {
      featureFlags: ["ECHO_INTEGRATION", "ECHO_PAID_FEATURES"],
    },
  },
  {
    path: ":resource(echoPostTemplates)/:id/edit",
    component: () => import(/* webpackChunkName: "org_admin" */ "./ResourceSingle.vue"),
    name: "org_admin_echo_post_templates_edit",
    meta: {
      featureFlags: ["ECHO_INTEGRATION"],
    },
  },
  {
    path: ":resource(benefits|files)",
    component: () => import(/* webpackChunkName: "org_admin" */ "./ResourceList.vue"),
    name: "org_admin_res_list",
  },
];
