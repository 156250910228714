<template lang="pug">
div.align-items-center.py-10.px-2.px-sm-4.px-md-5.bg-white
  b-card.shadow-sm.my-3.py-8.p-sm-5.text-center.mx-lg-10.mx-auto(
    header-bg-variant="transparent",
    bg-variant="transparent",
    body-bg-variant="white",
    body-class="shadow-none",
  )
    div(v-if="paymentDetail === 'pending'")
      svg.initial-spinner(viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg")
        circle(cx="50" cy="50" r="45")
      br
      span Processing payment...
    div(v-if="paymentDetail === 'captured' && showGif")
      img.mb-4(:src="require(`@/assets/images/payment-tick.gif`)")
      .h3.mb-3.font-weight-medium Payment Received

    .col-md-6.mx-auto(v-else-if="['captured', 'failed'].includes(paymentDetail)")
      .d-flex.justify-content-center.mb-4(v-if="statusStates.imagePath")
        img.image-size(:src="statusStates.imagePath")
      b-avatar.bg-blue-100.mb-4(v-else, size="3.5rem")
        n-icon(:name="statusStates.icon", :variant="statusStates.iconColor", :size="2")
      .h3.mb-3.font-weight-medium {{ statusStates.heading }}
      span.mb-2 {{ statusStates.bodyText1 }}
      div.mb-5(v-if="statusStates.bodyText2")
        span.text-gray-800.font-weight-medium.mb-2 {{ statusStates.bodyText2 }}
      n-button(:buttonText="statusStates.buttonText", size="lg", @click="redirectTo()")
    
</template>

<script>
import { mapGetters } from "vuex";
import userDefs from "../../user/definitions";
import NButton from "../../../../components/NovaButton.vue";
import { SUPER_TOPUP_STATUS_STATES, CHECKUP_PACKAGE_STATUS_STATES } from "../constants";
import { PaymentStatus } from "../../admin/schemas/pgPayments";
import { NovaProductType } from "../../novaProducts/enum";

export default {
  name: "StatusPage",
  components: {
    NButton,
  },
  data() {
    return {
      paymentDetail: null,
      novaOrder: null,
      interval: null,
      timeout: null,
      showGif: true,
    };
  },

  computed: {
    ...mapGetters(["getFeatureFlags"]),
    productType() {
      return this.$route.params?.productType;
    },
    statusStates() {
      if (this.productType === NovaProductType.CHECKUP_PACKAGES) {
        return CHECKUP_PACKAGE_STATUS_STATES[this.paymentDetail][this.novaOrder.status];
      }
      return SUPER_TOPUP_STATUS_STATES[this.paymentDetail];
    },
    redirectUrlAfterTimeout() {
      return this.statusStates?.redirectUrlAfterTimeout || "/dashboard";
    },
  },
  watch: {
    paymentDetail: function (status) {
      if (status === "captured") {
        this.showGif = true;
        setTimeout(() => {
          this.showGif = false;
        }, 3000);
      }
    },
  },
  mounted() {
    this.fetchPaymentDetail();
    this.interval = setInterval(() => {
      this.fetchPaymentDetail();
    }, 500);
    this.timeout = setTimeout(() => {
      clearInterval(this.interval);
      this.$router.push(this.redirectUrlAfterTimeout);
    }, 600000); // 10 minutes
  },
  methods: {
    fetchPaymentDetail() {
      this.$apollo.queries.paymentDetail
        .refetch()
        .then((response) => {
          const status = response.data.paymentDetail.status;
          if ([PaymentStatus.CAPTURED, PaymentStatus.FAILED].includes(status)) {
            clearInterval(this.interval);
            clearTimeout(this.timeout);
          }
        })
        .catch((error) => {
          console.error("Error fetching payment detail:", error);
        });
    },
    redirectTo() {
      if (this.productType == NovaProductType.SUPER_TOPUP) {
        const url = this.statusStates.redirectTo;
        this.$router.push({ name: "dashboard", params: { refetchPolicies: true } });
      } else if (this.productType === NovaProductType.CHECKUP_PACKAGES) {
        const url = this.statusStates?.redirectTo;
        this.$router.push(url);
      } else {
        const url = this.statusStates.redirectTo;
        this.$router.push(url);
      }
    },
  },
  apollo: {
    paymentDetail: {
      query: userDefs.payments.paymentDetail,
      variables() {
        return {
          pgOrderId: this.$route.params.pgOrderId,
        };
      },
      update(data) {
        this.novaOrder = data?.paymentDetail?.novaOrder;
        return data?.paymentDetail?.status;
      },
      fetchPolicy: "no-cache",
    },
  },
};
</script>

<style lang="scss" scoped>
.image-size {
  width: 5rem;
}
</style>
