<template lang="pug">
  div
    b-button(v-if="schema.modal", :disabled="schema.disabled", :variant="schema.variant", @click="$bvModal.show(`${getModalId}`)") {{schema.name || "View Uploaded Files" }} 
      n-icon.align-middle(v-if="schema.icon", :name="schema.icon")
    file-upload.pt-1.pb-3(
      v-else,
      v-model="value",
      v-bind="schema",
      :state="errors.length>0 ? 'error':''",
      :validationMessage="errors.length>0 ? errors[0]:''",
      :accept="schema.accept",
      :disabled="disabled",
      :extensions="schema.extensions",
      :options="schema.options",
      :productType="schema.productType ? schema.productType :null",
      :requiredDetails="schema.productType ? {id:model.id}:null")
    b-modal(
      v-if="schema.modal",
      :id="`${getModalId}`",
      centered,
      title="Review Uploaded Files",
      :hide-footer="true",
      size="lg")
      file-upload.pt-1.pb-3(
        v-model="value",
        v-bind="schema",
        :state="errors.length>0 ? 'error':''",
        :validationMessage="errors.length>0 ? errors[0]:''",
        :accept="schema.accept",
        :disabled="disabled",
        :extensions="schema.extensions",
        :options="schema.options",
        :productType="schema.productType ? schema.productType :null",
        :requiredDetails="schema.productType ? {id:model.id}:null")
</template>

<script>
import { abstractField } from "vue-form-generator";
import FileUpload from "@/components/FileUpload.vue";
import NButton from "@/components/NovaButton.vue";

export default {
  components: {
    FileUpload,
    NButton,
  },
  mixins: [abstractField],
  props: {
    requiredDetails: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    getModalId() {
      return new Date().getUTCMilliseconds();
    },
  },
};
</script>
