import gql from "graphql-tag";

export const stagedClaimFragment = gql`
  fragment StagedClaim on StagedClaim {
    id
    claimNumber
    claimId
    claimData
    meta
    status
    createdAt
    updatedAt
    errorReason
    claimIdentifiers
  }
`;

export const stagedClaimResourceListFragment = gql`
  fragment StagedClaim on StagedClaim {
    id
    claimNumber
    claimId
    claimData
    meta
    status
    createdAt
    updatedAt
    errorReason
    claimIdentifiers
  }
`;
