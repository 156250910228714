<template lang="pug">
n-overlay
  .d-flex.flex-column.w-100.h-100.overflow-hidden.position-relative
    .shadow.fixed-top.bg-white
      .container
        .row.d-flex.align-items-center.py-3.justify-content-between
          .d-flex.header-title-wrapper
            n-icon(name="arrow", variant="gray-600", :size="1.5", @click="goBack")
            .d-flex.align-items-center.header-title
              span.ml-2.font-lg.font-weight-semibold Claim Form Part A
          .d-flex
            n-button.d-none.d-lg-block.mr-4.px-4(v-if="!isPreview", variant="success", buttonText="Submit Claim", @click="generateClaimForm(false)", size="sm")
    .overflow-scroll.mt-5.row-12
      .container.d-flex
        .mf-loader-container.w-100.d-flex.justify-content-center.align-items-center(v-if="isClaimFormLoading")
          #mf-loader
          .text-gray-800.font-md.font-weight-semibold.text-center.d-flex.justify-content-center.align-items-center
            span Magic Filling
            .mf-separator.ml-2
            .text-gray-600.ml-2 {{ dummyLoaderPercentage }}%
        .preview-pdf.w-100(v-if="isPreview && claimFormLink")
          adobe-pdf(:url="claimFormLink")
        .mt-0.w-100(v-if="!isPreview")
          callout.mb-4(id="5", :img="require(`@/assets/images/callout-bulb.svg`)", bgColor="stone")
            template(v-slot:description)
              | 1. Enter and review your details. This information will be used to generate your <b>digital claim form</b>.<br>
              | 2. We collect <b>only mandatory information to save your time,</b> and fill in a <b>valid signature on your behalf</b>.<br>
              | 3. Use <b>Preview</b> button to check if all the details are correct, and edit as required.<br>
              | 4. <b>Submit</b> your claim.
          .d-flex
            .form-container
              .form(v-for="(value, key) in formASchema.categories")
                div(v-b-toggle="value.ref")
                  .form-title
                    .d-flex.font-weight-medium.w-100.justify-content-between
                      .font-inter.font-md.font-weight-semibold.text-gray-800 {{ value.title }}
                      .d-flex.text-gray-800.font-weight-medium
                        span
                          n-icon(:name="value.expanded ? 'chevron-up' : 'chevron-down'")
                b-collapse(
                  :id="value.ref",
                  :accordion="value.ref",
                  role="tabpanel"
                  :class="[{ 'collapse-visible': true }]",
                  v-model="value.expanded",
                )
                  template(v-slot:default)
                    slot(name="default")
                      b-form.form-content
                        vue-form-generator(
                          :schema="value.schema",
                          :model="formASchema.model",
                          :options="formASchema.options",
                          :ref="value.ref"
                        )
              n-checkbox-input.mb-3(v-if="claimFormAcknowledgementText", v-model="formASchema.model.isFormAcknowledged", :label="claimFormAcknowledgementText", fieldId="claim_form_ack",
                name="claimFormAck", id="claimFormAck" variant="default")
            .pdf-container.d-none.d-lg-block
              .pdf
                iframe(v-if="claimFormLink", :src="`${claimFormLink}#toolbar=0&navpanes=0&scrollbar=0`", width="100%", height="100%")
              n-button.mt-2.d-none.d-md-block.mr-4.px-4.w-100(variant="outline-secondary", buttonText="Preview Filled Form", @click="generateClaimForm(true)", size="sm")

    .fixed-bottom.bg-white.border.shadow-lg.d-lg-none.bottom-actions.px-4(v-if="!isPreview")
      .buttons-container.d-flex.justify-content-center.align-items-center.h-100
        n-button.w-50(
          buttonText="Preview Form",
          variant="outline-secondary",
          size="md",
          @click="generateClaimForm(true)"
        )
        n-button.w-50(
          variant="success",
          size="md",
          buttonText="Submit Claim",
          @click="generateClaimForm(false)"
        )

    transition(name="fade")
      .position-absolute.w-100.d-flex.justify-content-center.mf-progress-wrapper(v-if="!isAiDataExtracted")
        .mf-progress-container.bg-gray-800
          .mf-progress-inner-container.bg-gray-900.w-100.d-flex.justify-content-between
            .mf-progress-title-container.d-flex.justify-content-center
              .mf-progress-img.d-flex.justify-content-center.align-items-center
                img(:src="require(`@/assets/images/magic-fill.svg`)")
              .mf-progress-title
                span.title.text-white.font-lg.font-weight-semibold Magic Filling
                span.description.text-gray-500.font-md Fetching your details from the uploaded documents
            .mf-progress-count.text-white.font-md.font-weight-semibold.d-flex.align-items-center {{dummyAiExtractionPercentage}}%
</template>

<script>
import { mapGetters } from "vuex";
import gql from "graphql-tag";
import userDefs from "../definitions";
import AdobePdf from "./components/AdobePdf.vue";
import NButton from "@/components/NovaButton";
import NOverlay from "@/components/Overlay";
import SpecialModal from "@/components/SpecialModal";
import utils from "@/utils";
import NPopover from "@/components/NovaPopover.vue";
import Callout from "@/components/Callout.vue";
import NCheckboxInput from "@/components/NovaCheckbox.vue";

export default {
  components: {
    NButton,
    NOverlay,
    SpecialModal,
    NPopover,
    AdobePdf,
    Callout,
    NCheckboxInput,
  },
  data() {
    return {
      claimId: null,
      saveBtnText: "Save & Exit",
      collapsibleVisible: true,
      claimFormLink: null,
      isClaimFormLoading: false,
      isPreview: false,
      dummyLoaderPercentage: 0,
      dummyAiExtractionPercentage: 0,
      interval: null,
      claimFormAcknowledgementText: null,
      isAiDataExtracted: false,
      formASchema: {
        id: null,
        options: {
          validateAfterLoad: false,
          validateAfterChanged: false,
          validateAsync: true,
        },
        model: {
          policyNumber: null,
          primaryInsuredName: null,
          primaryInsuredAddress1: null,
          primaryInsuredAddress2: null,
          primaryInsuredCity: null,
          primaryInsuredPincode: null,
          primaryInsuredState: null,
          primaryInsuredEmail: null,
          primaryInsuredPhoneNumber: null,

          patientName: null,
          patientGender: null,
          patientDob: null,
          patientAge: null,
          patientRelationship: null,
          patientUhid: null,
          patientAddress1: null,
          patientAddress2: null,
          patientCity: null,
          patientPincode: null,
          patientState: null,
          patientEmail: null,
          isPatientAddressSame: true,

          isInsuranceHistoryCovered: false,
          insuranceHistoryCompanyName: null,
          insuranceHistoryPolicyNumber: null,
          insuranceHistorySumInsured: null,
          insuranceHistoryDiagnosis: null,

          hospitalName: null,
          dateOfAdmission: null,
          dateOfDischarge: null,

          preHospitalizationExpense: null,
          postHospitalizationExpense: null,
          hospitalizationExpense: null,
          healthCheckupCost: null,
          ambulanceCharges: null,
          otherCharges: null,
          totalExpense: 0,

          panNumber: null,
          accountNumber: null,
          bankNameAndBranch: null,
          ifscCode: null,

          isFormAcknowledged: false,
        },
        categories: {
          detailsOfPrimaryInsured: {
            schema: {
              fields: [
                {
                  model: "policyNumber",
                  type: "inline-input",
                  inputType: "text",
                  label: "Policy Number" + this.addMandatorySpan(),
                  styleClasses: "d-inline-flex col-md-6 flex-column pt-0",
                  placeholder: "Enter Policy Number",
                  required: true,
                  disabled: true,
                  validator: ["required"],
                },
                {
                  model: "primaryInsuredName",
                  type: "inline-input",
                  inputType: "text",
                  label: "Name of Primary Insured" + this.addMandatorySpan(),
                  styleClasses: "d-inline-flex col-md-6 flex-column",
                  placeholder: "Enter Primary Insured Name",
                  required: true,
                  disabled: true,
                  validator: ["required"],
                },
                {
                  model: "primaryInsuredAddress1",
                  type: "inline-input",
                  inputType: "text",
                  label: "Address Line 1" + this.addMandatorySpan(),
                  styleClasses: "d-inline-flex col-md-6 flex-column",
                  placeholder: "Enter House/Flat/Floor No.",
                  required: true,
                  validator: ["string", "required"],
                },
                {
                  model: "primaryInsuredAddress2",
                  type: "inline-input",
                  inputType: "text",
                  label: `Address Line 2${this.addMandatorySpan()}`,
                  styleClasses: "d-inline-flex col-md-6 flex-column",
                  placeholder: "Enter Apartment/Road/Area",
                  required: true,
                  validator: ["string", "required"],
                },
                {
                  model: "primaryInsuredCity",
                  type: "inline-input",
                  inputType: "text",
                  label: `City${this.addMandatorySpan()}`,
                  styleClasses: "d-inline-flex col-md-6 flex-column",
                  placeholder: "Enter your city of residence",
                  required: true,
                  validator: ["required"],
                },
                {
                  model: "primaryInsuredPincode",
                  type: "inline-input",
                  inputType: "text",
                  label: `Pincode${this.addMandatorySpan()}`,
                  styleClasses: "d-inline-flex col-md-6 flex-column",
                  placeholder: "Enter your pincode",
                  required: true,
                  validator: ["required"],
                },
                {
                  model: "primaryInsuredState",
                  type: "inline-input",
                  inputType: "text",
                  label: `State${this.addMandatorySpan()}`,
                  styleClasses: "d-inline-flex col-md-6 flex-column offset-md-right-6",
                  placeholder: "Enter Primary Insured State",
                  required: true,
                  validator: ["required"],
                },
                {
                  model: "primaryInsuredEmail",
                  type: "inline-input",
                  inputType: "text",
                  label: `Email ID${this.addMandatorySpan()}`,
                  styleClasses: "d-inline-flex col-md-6 flex-column",
                  placeholder: "Enter Primary Insured Email ID",
                  required: true,
                  disabled: true,
                  validator: ["required"],
                },
                {
                  model: "primaryInsuredPhoneNumber",
                  type: "inline-input",
                  inputType: "text",
                  label: `Phone No.${this.addMandatorySpan()}`,
                  styleClasses: "d-inline-flex col-md-6 flex-column",
                  placeholder: "Enter Primary Insured Phone Number",
                  required: true,
                  disabled: true,
                  validator: ["required"],
                },
              ],
            },
            expanded: true,
            ref: "detailsOfPrimaryInsured",
            title: "Details of Primary Insured",
          },
          detailsOfInsuredPersonHospitalized: {
            schema: {
              fields: [
                {
                  model: "patientName",
                  type: "inline-input",
                  inputType: "text",
                  label: `Name of Patient${this.addMandatorySpan()}`,
                  styleClasses: "d-inline-flex col-md-6 flex-column",
                  placeholder: "Enter Patient Name",
                  required: true,
                  disabled: true,
                  validator: ["required"],
                },
                {
                  model: "patientGender",
                  type: "inline-input",
                  inputType: "text",
                  label: `Gender${this.addMandatorySpan()}`,
                  styleClasses: "d-inline-flex col-md-6 flex-column",
                  placeholder: "Enter Patient Gender",
                  required: true,
                  disabled: true,
                  validator: ["required"],
                },
                {
                  model: "patientDob",
                  type: "inline-input",
                  inputType: "text",
                  label: `Date of Birth`,
                  styleClasses: "d-inline-flex col-md-6 flex-column",
                  placeholder: "Enter Patient Date of Birth",
                  required: true,
                  disabled: true,
                  validator: ["required"],
                },
                {
                  model: "patientAge",
                  type: "inline-input",
                  inputType: "text",
                  label: `Age`,
                  styleClasses: "d-inline-flex col-md-6 flex-column",
                  placeholder: "Enter Patient Age",
                  required: true,
                  disabled: true,
                  validator: ["required"],
                },
                {
                  model: "patientRelationship",
                  type: "inline-input",
                  label: `Relationship with Primary Insured${this.addMandatorySpan()}`,
                  styleClasses: "d-inline-flex col-md-6 flex-column",
                  required: true,
                  validator: ["required"],
                  disabled: true,
                },
                {
                  model: "patientUhid",
                  type: "inline-input",
                  label: `UHID of Patient`,
                  placeholder: "UHID of patient",
                  styleClasses: "d-inline-flex col-md-6 flex-column",
                },
                {
                  model: "patientAddress1",
                  type: "inline-input",
                  inputType: "text",
                  label: `Address Line 1${this.addMandatorySpan()}`,
                  styleClasses: "d-inline-flex col-md-6 flex-column",
                  placeholder: "Enter House/Flat/Floor No.",
                  required: true,
                  validator: ["string", "required"],
                  disabled: true,
                },
                {
                  model: "patientAddress2",
                  type: "inline-input",
                  inputType: "text",
                  label: `Address Line 2${this.addMandatorySpan()}`,
                  styleClasses: "d-inline-flex col-md-6 flex-column",
                  placeholder: "Enter Apartment/Road/Area",
                  required: true,
                  validator: ["string", "required"],
                  disabled: true,
                },
                {
                  model: "patientCity",
                  type: "inline-input",
                  inputType: "text",
                  label: `City${this.addMandatorySpan()}`,
                  styleClasses: "d-inline-flex col-md-6 flex-column",
                  placeholder: "Enter Patient City",
                  required: true,
                  validator: ["required"],
                  disabled: true,
                },
                {
                  model: "patientPincode",
                  type: "inline-input",
                  inputType: "text",
                  label: `Pincode${this.addMandatorySpan()}`,
                  styleClasses: "d-inline-flex col-md-6 flex-column",
                  placeholder: "Enter Patient Pincode",
                  required: true,
                  validator: ["required"],
                  disabled: true,
                },
                {
                  model: "patientState",
                  type: "inline-input",
                  inputType: "text",
                  label: `State${this.addMandatorySpan()}`,
                  styleClasses: "d-inline-flex col-md-6 flex-column",
                  placeholder: "Enter Patient State",
                  required: true,
                  validator: ["required"],
                  disabled: true,
                },
                {
                  model: "isPatientAddressSame",
                  type: "checkbox",
                  inputType: "checkbox",
                  label: "Address of the person hospitalised is same as that of Primary Insured",
                  fieldClasses: ["ml-3"],
                },
              ],
            },
            expanded: true,
            ref: "detailsOfInsuredPersonHospitalized",
            title: "Details of Insured Person Hospitalized",
          },
          detailsOfInsuranceHistory: {
            schema: {
              fields: [
                {
                  model: "isInsuranceHistoryCovered",
                  type: "checkbox",
                  inputType: "checkbox",
                  label: `Currently covered by any other Mediclaim / Health Insurance`,
                  fieldClasses: ["ml-3", "mb-3"],
                },
                {
                  model: "insuranceHistoryCompanyName",
                  type: "inline-input",
                  inputType: "text",
                  label: `Company Name`,
                  styleClasses: "d-inline-flex col-md-6 flex-column",
                  placeholder: "Enter Company Name",
                  disabled: true,
                },
                {
                  model: "insuranceHistoryPolicyNumber",
                  type: "inline-input",
                  inputType: "text",
                  label: `Policy No.`,
                  styleClasses: "d-inline-flex col-md-6 flex-column",
                  placeholder: "Enter Policy Number",
                  disabled: true,
                },
                {
                  model: "insuranceHistorySumInsured",
                  type: "inline-input",
                  inputType: "number",
                  label: `Sum Insured (Rs.)`,
                  styleClasses: "d-inline-flex col-md-6 flex-column",
                  placeholder: "Enter Sum Insured",
                  disabled: true,
                },
                {
                  model: "insuranceHistoryDiagnosis",
                  type: "inline-input",
                  inputType: "text",
                  label: `Diagnosis`,
                  styleClasses: "d-inline-flex col-md-6 flex-column",
                  placeholder: "Enter diagnosis",
                  disabled: true,
                },
              ],
            },
            expanded: true,
            ref: "detailsOfInsuranceHistory",
            title: "Details of Insurance History (Fill this in if you’re filing a partial claim)",
          },
          detailsOfHospitalization: {
            schema: {
              fields: [
                {
                  model: "hospitalName",
                  type: "inline-input",
                  inputType: "text",
                  label: `Name of the Hospital where admitted${this.addMandatorySpan()}`,
                  styleClasses: "d-inline-flex col-md-12 flex-column",
                  placeholder: "Enter Hospital Name",
                  required: true,
                  disabled: true,
                  validator: ["required"],
                },
                {
                  model: "dateOfAdmission",
                  type: "inline-input",
                  label: `Date of Admission${this.addMandatorySpan()}`,
                  styleClasses: "d-inline-flex col-md-6 flex-column",
                  placeholder: "Enter Date of Admission",
                  required: true,
                  disabled: true,
                  validator: ["required"],
                },
                {
                  model: "dateOfDischarge",
                  type: "inline-input",
                  label: `Date of Discharge${this.addMandatorySpan()}`,
                  styleClasses: "d-inline-flex col-md-6 flex-column",
                  placeholder: "Enter Date of Discharge",
                  required: true,
                  disabled: true,
                  validator: ["required"],
                },
              ],
            },
            expanded: true,
            ref: "detailsOfHospitalization",
            title: "Details of Hospitalization",
          },
          detailsOfClaim: {
            schema: {
              fields: [
                {
                  model: "preHospitalizationExpense",
                  type: "inline-input",
                  inputType: "number",
                  label: `Pre-hospitalization Expenses`,
                  styleClasses: "d-inline-flex col-md-6 flex-column",
                  placeholder: "Enter Pre-hospitalization Expenses",
                },
                {
                  model: "hospitalizationExpense",
                  type: "inline-input",
                  inputType: "number",
                  label: `Hospitalization Expenses${this.addMandatorySpan()}`,
                  styleClasses: "d-inline-flex col-md-6 flex-column",
                  placeholder: "Hospitalization Expenses",
                  required: true,
                  validator: ["required"],
                },
                {
                  model: "postHospitalizationExpense",
                  type: "inline-input",
                  inputType: "number",
                  label: `Post-hospitalization Expenses`,
                  styleClasses: "d-inline-flex col-md-6 flex-column",
                  placeholder: "Enter Post-hospitalization Expenses",
                },
                {
                  model: "healthCheckupCost",
                  type: "inline-input",
                  inputType: "number",
                  label: `Health Check-up Cost`,
                  styleClasses: "d-inline-flex col-md-6 flex-column",
                  placeholder: "Enter Health Check-up Cost",
                },
                {
                  model: "ambulanceCharges",
                  type: "inline-input",
                  inputType: "number",
                  label: `Ambulance Charges`,
                  styleClasses: "d-inline-flex col-md-6 flex-column",
                  placeholder: "Enter Ambulance Charges",
                },
                {
                  model: "otherCharges",
                  type: "inline-input",
                  inputType: "number",
                  label: `Other Charges`,
                  styleClasses: "d-inline-flex col-md-6 flex-column",
                  placeholder: "Enter Ambulance Charges",
                },
                {
                  model: "totalExpense",
                  type: "inline-input",
                  inputType: "number",
                  label: `Total${this.addMandatorySpan()}`,
                  styleClasses: "d-inline-flex col-md-6 flex-column",
                  placeholder: "Total Expenses",
                  required: true,
                  disabled: true,
                  min: 0,
                  default: 0,
                  validator: ["required"],
                },
              ],
            },
            expanded: true,
            ref: "detailsOfClaim",
            title: "Details of Claim",
          },
          detailsOfPrimaryInsuredBankAccount: {
            schema: {
              fields: [
                {
                  model: "panNumber",
                  type: "inline-input",
                  inputType: "text",
                  label: `PAN${this.addMandatorySpan()}`,
                  styleClasses: "d-inline-flex col-md-6 flex-column",
                  placeholder: "Enter PAN Number",
                  required: true,
                  subTextIcon: "color-magic-fill",
                  subTextIconPathCount: 2,
                  subTextIconClass: "magic-fill-icon",
                  subText: `Extracted by MagicFill`,
                  validator: ["required"],
                },
                {
                  model: "accountNumber",
                  type: "inline-input",
                  inputType: "text",
                  label: `Account Number${this.addMandatorySpan()}`,
                  styleClasses: "d-inline-flex col-md-6 flex-column",
                  placeholder: "Enter Bank Account Number",
                  required: true,
                  subTextIcon: "color-magic-fill",
                  subTextIconPathCount: 2,
                  subTextIconClass: "magic-fill-icon",
                  subText: `Extracted by MagicFill`,
                  validator: ["required"],
                },
                {
                  model: "bankNameAndBranch",
                  type: "inline-input",
                  inputType: "text",
                  label: `Bank Name and Branch${this.addMandatorySpan()}`,
                  styleClasses: "d-inline-flex col-md-6 flex-column",
                  placeholder: "Enter Bank Name and Branch",
                  required: true,
                  subTextIcon: "color-magic-fill",
                  subTextIconPathCount: 2,
                  subTextIconClass: "magic-fill-icon",
                  subText: `Extracted by MagicFill`,
                  validator: ["required"],
                },
                {
                  model: "ifscCode",
                  type: "inline-input",
                  inputType: "text",
                  label: `IFSC Code${this.addMandatorySpan()}`,
                  styleClasses: "d-inline-flex col-md-6 flex-column",
                  placeholder: "Enter Bank's IFSC Code",
                  required: true,
                  subTextIcon: "color-magic-fill",
                  subTextIconPathCount: 2,
                  subTextIconClass: "magic-fill-icon",
                  subText: `Extracted by MagicFill`,
                  validator: ["required"],
                },
              ],
            },
            expanded: true,
            ref: "detailsOfPrimaryInsuredBankAccount",
            title: "Details of Primary Insured Bank Account",
          },
        },
      },
    };
  },
  computed: {
    ...mapGetters(["getFeatureFlags", "user"]),
    isMobile() {
      return utils.isMobileResolution();
    },
    isMandatoryDocsFlowEnabled() {
      return Boolean(this.getFeatureFlags.MANDATORY_CLAIM_DOCS_FLOW);
    },
    isClaimFormAGenerationEnabled() {
      return Boolean(this.getFeatureFlags.ENABLE_CLAIM_FORM_A_GENERATION);
    },
    expenses() {
      return [
        this.formASchema.model.preHospitalizationExpense,
        this.formASchema.model.hospitalizationExpense,
        this.formASchema.model.postHospitalizationExpense,
        this.formASchema.model.healthCheckupCost,
        this.formASchema.model.ambulanceCharges,
        this.formASchema.model.otherCharges,
      ];
    },
  },
  watch: {
    "formASchema.model.primaryInsuredAddress1": function (addressLine1) {
      if (this.formASchema.model.isPatientAddressSame) {
        this.formASchema.model.patientAddress1 = addressLine1;
      }
    },
    "formASchema.model.primaryInsuredAddress2": function (addressLine2) {
      if (this.formASchema.model.isPatientAddressSame) {
        this.formASchema.model.patientAddress2 = addressLine2;
      }
    },
    "formASchema.model.primaryInsuredCity": function (city) {
      if (this.formASchema.model.isPatientAddressSame) {
        this.formASchema.model.patientCity = city;
      }
    },
    "formASchema.model.primaryInsuredState": function (state) {
      if (this.formASchema.model.isPatientAddressSame) {
        this.formASchema.model.patientState = state;
      }
    },
    "formASchema.model.primaryInsuredPincode": function (pincode) {
      if (this.formASchema.model.isPatientAddressSame) {
        this.formASchema.model.patientPincode = pincode;
      }
    },
    "formASchema.model.isInsuranceHistoryCovered": function (checkboxState) {
      this.formASchema.categories.detailsOfInsuranceHistory.schema.fields.forEach((field) => {
        if (field.model !== "isInsuranceHistoryCovered") {
          field.disabled = !checkboxState;
        }
      });

      if (!checkboxState) {
        this.formASchema.model.insuranceHistoryCompanyName =
          this.formASchema.model.insuranceHistoryPolicyNumber =
          this.formASchema.model.insuranceHistorySumInsured =
          this.formASchema.model.insuranceHistoryDiagnosis =
            null;
      }
    },
    "formASchema.model.isPatientAddressSame": function (checkboxState) {
      this.formASchema.model.patientAddress1 = checkboxState
        ? this.formASchema.model.primaryInsuredAddress1
        : this.formASchema.model.patientAddress1;
      this.formASchema.model.patientAddress2 = checkboxState
        ? this.formASchema.model.primaryInsuredAddress2
        : this.formASchema.model.patientAddress2;
      this.formASchema.model.patientCity = checkboxState
        ? this.formASchema.model.primaryInsuredCity
        : this.formASchema.model.patientCity;
      this.formASchema.model.patientState = checkboxState
        ? this.formASchema.model.primaryInsuredState
        : this.formASchema.model.patientState;
      this.formASchema.model.patientPincode = checkboxState
        ? this.formASchema.model.primaryInsuredPincode
        : this.formASchema.model.patientPincode;
      this.formASchema.categories.detailsOfInsuredPersonHospitalized.schema.fields.splice(6, 1, {
        model: "patientAddress1",
        type: "inline-input",
        inputType: "text",
        label: `Address Line 1${this.addMandatorySpan()}`,
        styleClasses: "d-inline-flex col-md-6 flex-column",
        placeholder: "Enter Patient Address",
        required: true,
        maxRows: 2,
        maxLength: 1000,
        validator: ["string", "required"],
        disabled: checkboxState,
      });
      this.formASchema.categories.detailsOfInsuredPersonHospitalized.schema.fields.splice(7, 1, {
        model: "patientAddress2",
        type: "inline-input",
        inputType: "text",
        label: `Address Line 2${this.addMandatorySpan()}`,
        styleClasses: "d-inline-flex col-md-6 flex-column",
        placeholder: "Enter Patient Address",
        required: true,
        maxRows: 2,
        maxLength: 1000,
        validator: ["string", "required"],
        disabled: checkboxState,
      });
      this.formASchema.categories.detailsOfInsuredPersonHospitalized.schema.fields.splice(8, 1, {
        model: "patientCity",
        type: "inline-input",
        inputType: "text",
        label: `City<span style="color: red">*</span>`,
        styleClasses: "d-inline-flex col-md-6 flex-column",
        placeholder: "Enter Patient City",
        required: true,
        validator: ["required"],
        disabled: checkboxState,
      });
      this.formASchema.categories.detailsOfInsuredPersonHospitalized.schema.fields.splice(9, 1, {
        model: "patientPincode",
        type: "inline-input",
        inputType: "text",
        label: `Pincode<span style="color: red">*</span>`,
        styleClasses: "d-inline-flex col-md-6 flex-column",
        placeholder: "Enter Patient Pincode",
        required: true,
        validator: ["required"],
        disabled: checkboxState,
      });
      this.formASchema.categories.detailsOfInsuredPersonHospitalized.schema.fields.splice(10, 1, {
        model: "patientState",
        type: "inline-input",
        inputType: "text",
        label: `State<span style="color: red">*</span>`,
        styleClasses: "d-inline-flex col-md-6 flex-column",
        placeholder: "Enter Patient State",
        required: true,
        validator: ["required"],
        disabled: checkboxState,
      });
    },
    expenses: {
      deep: true,
      handler() {
        this.calculateTotalExpense();
      },
    },
    isClaimFormLoading: {
      handler: function (value) {
        if (value) {
          this.interval = setInterval(this.incrementMagicFillPdfPercentage, 500);
        } else {
          this.dummyLoaderPercentage = 0;
          clearInterval(this.interval);
        }
      },
    },
  },
  async created() {
    this.claimId = this.$route.params.id;
  },
  async mounted() {
    const existingScript = document.querySelector('script[src="https://acrobatservices.adobe.com/view-sdk/viewer.js"]');

    if (!existingScript) {
      this.adobePdfViewer = document.createElement("script");
      this.adobePdfViewer.setAttribute("src", "https://acrobatservices.adobe.com/view-sdk/viewer.js");
      document.body.appendChild(this.adobePdfViewer);
    }

    this.interval = setInterval(this.incrementAIExtractionPercentage, 500);
  },
  beforeDestroy() {
    window.lottie?.destroy();
  },
  methods: {
    incrementMagicFillPdfPercentage() {
      if (this.dummyLoaderPercentage < 100) this.dummyLoaderPercentage = this.dummyLoaderPercentage + 10;
    },
    incrementAIExtractionPercentage() {
      if (this.dummyAiExtractionPercentage < 100)
        this.dummyAiExtractionPercentage = this.dummyAiExtractionPercentage + 10;
    },
    goBack() {
      if (this.isPreview) {
        this.isPreview = false;
        return;
      }
      this.$router.push({
        name: "upload-documents",
        params: { id: this.claim.id },
      });
    },
    saveAndExit() {},
    async generateClaimForm(isSaveProgress) {
      try {
        if (!isSaveProgress) {
          const isValid = await this.validateForm();
          if (!isValid) {
            this.$store.commit("addToast", {
              variant: "danger",
              message: "Please enter valid details before continuing to generate the form",
            });

            return;
          }

          if (isValid && this.claimFormAcknowledgementText && !this.formASchema.model.isFormAcknowledged) {
            this.$store.commit("addToast", {
              variant: "danger",
              message: "Please acknowledge before continuing to submit the form",
            });

            document.getElementById("claimFormAck")?.scrollIntoView({ behavior: "smooth" });
            return;
          }
        } else {
          const loadLottieAndContainer = setInterval(() => {
            if (window?.lottie && document.getElementById("mf-loader")) {
              window.lottie.loadAnimation({
                container: document.getElementById("mf-loader"),
                renderer: "svg",
                loop: true,
                autoplay: true,
                animationData: require("@/assets/animations/magic_fill.json"),
              });
              clearInterval(loadLottieAndContainer);
            }
          }, 500);

          this.isPreview = true;
          this.claimFormLink = null;
          this.isClaimFormLoading = true;
        }

        const result = await this.$apollo.mutate({
          mutation: gql`
            mutation GenerateAutofilledClaimForm($id: ID, $claimFormFields: JSONObject, $isPreview: Boolean) {
              GenerateAutofilledClaimForm(
                input: { id: $id, claimFormFields: $claimFormFields, isPreview: $isPreview }
              ) {
                autofilledClaimFormUrl
              }
            }
          `,
          variables: {
            id: this.$route.params.id,
            claimFormFields: this.formASchema.model,
            isPreview: this.isPreview,
          },
        });
        this.claimFormLink = result.data.GenerateAutofilledClaimForm.autofilledClaimFormUrl;
        this.isClaimFormLoading = false;
        if (!this.isPreview) {
          this.$router.replace("/user/claims");
          this.$store.commit("addToast", {
            variant: "success",
            title: "Claim Submitted",
            message: "Your claim was submitted successfully.",
          });
        }
      } catch (err) {
        throw Error("Something went wrong" + err);
      }
    },
    async validateForm() {
      let isValid = true;
      const formRefs = [];
      Object.values(this.formASchema.categories).forEach((category) => {
        formRefs.push(category.ref);
      });

      await Promise.all(
        formRefs.map((ref) => {
          return this.$refs[ref][0].validate();
        })
      );

      // Check if all formRefs have no errors
      isValid = formRefs.every((ref) => this.$refs[ref][0].errors.length < 1);

      return isValid;
    },
    calculateTotalExpense() {
      const preHospitalizationExpense = parseFloat(this.formASchema.model.preHospitalizationExpense) || 0;
      const hospitalizationExpense = parseFloat(this.formASchema.model.hospitalizationExpense) || 0;
      const postHospitalizationExpense = parseFloat(this.formASchema.model.postHospitalizationExpense) || 0;
      const healthCheckupCost = parseFloat(this.formASchema.model.healthCheckupCost) || 0;
      const ambulanceCharges = parseFloat(this.formASchema.model.ambulanceCharges) || 0;
      const otherCharges = parseFloat(this.formASchema.model.otherCharges) || 0;

      this.formASchema.model.totalExpense =
        preHospitalizationExpense +
        hospitalizationExpense +
        postHospitalizationExpense +
        healthCheckupCost +
        ambulanceCharges +
        otherCharges;
    },
    addMandatorySpan() {
      return `<span style="color: red">*</span>`;
    },
  },
  apollo: {
    claim: {
      query: userDefs.claims.singleQuery,
      variables() {
        return { id: this.$route.params.id };
      },
      update(data) {
        const policy = this.user.benefits?.find((benefit) => benefit.node.id === data.node.policy.id);
        this.claimFormAcknowledgementText = policy.node.tpa.meta?.claimFormAcknowledgementText?.trim();
        return userDefs.claims.transform(data.node);
      },
    },
    formAData: {
      query: gql`
        query getClaimFormADetails($id: ID!) {
          getClaimFormADetails(id: $id)
        }
      `,
      variables() {
        return { id: this.$route.params.id };
      },
      fetchPolicy: "no-cache",
      update(data) {
        setTimeout(() => {
          const { claimFormAData, reimbursementFormLink } = data.getClaimFormADetails;
          this.formASchema.model = { ...this.formASchema.model, ...claimFormAData };
          this.claimFormLink = reimbursementFormLink;
          this.isAiDataExtracted = true;
          clearInterval(this.interval);
        }, 2000);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
@import "@/assets/styles/mixins/_breakpoints.scss";
@import "@/assets/styles/_mixins.scss";

.mf-progress-wrapper {
  bottom: 1.5rem;
}

.mf-progress-container {
  width: 50%;
  border-radius: 1rem;
}

.mf-progress-title {
  margin-left: 1.5rem;
  display: grid;

  .description {
    margin-top: 0.5rem;
  }
}

.mf-progress-img {
  img {
    height: 1.75rem;
  }
}

.mf-progress-inner-container {
  position: relative;
  padding: 1.25rem;
  border-radius: 1rem;

  display: grid;
  place-content: center;

  --border-angle: 0turn;
  --main-bg: conic-gradient(from var(--border-angle), #213, rgb(21, 21, 46) 5%, #112 60%, #213 95%);

  border: solid 0.25rem transparent;
  --gradient-border: conic-gradient(from var(--border-angle), #5d6a83 0%, #fb42dd 100%);
  // --gradient-border: linear-gradient(269deg, #3DE7DB 0.8%, rgba(251, 66, 221, 0.00) 98.95%);

  background: var(--main-bg) padding-box, var(--gradient-border) border-box, var(--main-bg) border-box;
  background-position: center center;

  animation: bg-spin 2.5s linear infinite;
  @keyframes bg-spin {
    to {
      --border-angle: 1turn;
    }
  }
}

@property --border-angle {
  syntax: "<angle>";
  inherits: true;
  initial-value: 0turn;
}

.fade-enter-active,
.fade-leave-active {
  // transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.mf-loader-container {
  height: 80vh;
  flex-direction: column;
}

.mf-separator {
  border: 1.48px solid rgba(229, 236, 251, 1);
  height: 100%;
  width: 1px;
}
#mf-loader {
  width: 6.25rem;
  height: 6.25rem;
}
.form {
  margin-bottom: 1.5rem;
  border-radius: 6px;
  border: 1px solid $gray-300 !important;
  box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.04), 0px 0px 2px 0px rgba(0, 0, 0, 0.06),
    0px 4px 8px 0px rgba(0, 0, 0, 0.04) !important;
}

.form-container {
  width: 56.83%;
  margin-right: 1.5rem;
}

.pdf-container {
  flex-grow: 1;

  .pdf {
    padding: 1rem;
    height: 60vh;

    border: 1px solid $gray-300;
    border-radius: 12px;
  }
}

.overflow-scroll {
  padding-top: 3rem;
}
@media only screen and (max-width: 992px) {
  .mf-progress-container {
    width: 90%;
  }

  .mf-progress-inner-container {
    padding: 1rem;
  }

  .mf-progress-wrapper {
    bottom: 5.5rem;
  }

  .mf-progress-title {
    margin-left: 1rem;

    .title {
      font-size: 0.875rem;
    }
    .description {
      font-size: 0.75rem;
      margin-top: 0.25rem;
    }
  }

  .mf-progress-count {
    font-size: 0.75rem;
  }

  .form-container {
    width: 100%;
    margin-right: unset !important;
  }

  .overflow-scroll {
    margin-bottom: 4.5rem !important;
    padding-top: 1.5rem !important;
  }

  .header-title-wrapper {
    width: 100%;
  }
  .header-title {
    width: 100%;
    justify-content: center;
  }

  .icon-arrow {
    position: absolute;
    margin-left: 1.5rem;
  }

  .form-content {
    padding: unset;
  }
}

.form-title {
  padding: 1.25rem;
}

.form-content {
  padding: 0 1.25rem 1.25rem 1.25rem;
}

.preview-pdf {
  height: 89vh;
}

.fixed-bottom {
  height: 4.5rem;
}

.buttons-container {
  gap: 1rem;
}

::v-deep .vue-form-generator {
  label {
    color: $gray-700;
  }
}

::v-deep .pdf-container > embed > html > body > .toolbar {
  display: none !important;
}
</style>
