import { getListQuery } from "../queries";
import { validateChildFields, validateInlineSelect } from "@/utils/validators";
import misc from "@/utils/misc";

export default {
  schema: {
    fields: [
      {
        model: "name",
        type: "input",
        inputType: "text",
        label: "TPA Name",
        required: true,
      },
      {
        model: "meta",
        type: "object",
        label: "Metadata",
        validator: validateChildFields,
        schema: {
          fields: [
            {
              model: "networkHospitalLink",
              type: "input",
              inputType: "text",
              label: "Network Hospital Link",
            },
            {
              model: "blacklistedHospitalLink",
              type: "input",
              inputType: "text",
              label: "Blacklisted Hospitals Link",
            },
            {
              model: "claimFormsLink",
              type: "input",
              inputType: "text",
              label: "Claims Forms Link",
              hint: "Publicly accessible claim form link",
            },
            {
              model: "claimFormAcknowledgementText",
              type: "input",
              inputType: "text",
              label: "Claim Form Acknowledgement Text",
              hint: "Text to show for acknowledgement in claims magic fill form",
            },
            {
              model: "contactNumber",
              type: "input",
              inputType: "text",
              label: "TPA Number",
            },
            {
              model: "tpaType",
              label: "Tpa Type",
              type: "inline-select",
              options: ["External", "In-house"],
              required: true,
              validator: ["required", validateInlineSelect],
            },
            {
              model: "cashlessTat",
              type: "input",
              inputType: "number",
              min: 0,
              label: "Cashless TAT (in hours)",
            },
            {
              model: "reimbursementTat",
              type: "input",
              inputType: "number",
              min: 0,
              label: "Reimbursement TAT (in days)",
            },
          ],
        },
      },
    ],
  },
};
