import gql from "graphql-tag";
import { CXPodMemberFragment } from "../fragments";

export default {
  upsertMutation: gql`
    mutation upsertCXPodMember(
      $id: ID
      $podId: ID!
      $userId: ID!
      $status: String!
      $role: String!
      $meta: JSONObject!
    ) {
      upsertCXPodMember(input: { id: $id, podId: $podId, userId: $userId, status: $status, role: $role, meta: $meta }) {
        podMember {
          ...CXPodMember
        }
      }
    }
    ${CXPodMemberFragment}
  `,
};
