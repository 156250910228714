import gql from "graphql-tag";

export const userTeamDesignationFragment = gql`
  fragment UserTeamDesignation on UserTeamDesignation {
    id
    user {
      id
      name
      email
      org {
        id
        name
      }
      orgEntity {
        id
        name
      }
    }
    reportsTo {
      id
      name
      email
    }
    team {
      id
      name
      status
    }
    designation {
      id
      name
      status
    }
    employmentType
    createdAt
    updatedAt
  }
`;
