import gql from "graphql-tag";
import { userTeamDesignationFragment } from "../fragments";
export default {
  upsertMutation: gql`
    mutation ($id: ID, $data: JSONObject) {
      upsertUserTeamDesignation(input: { id: $id, data: $data }) {
        userTeamDesignation {
          ...UserTeamDesignation
        }
      }
    }
    ${userTeamDesignationFragment}
  `,
};
