import gql from "graphql-tag";
import { pgPaymentFragment } from "../admin/fragments/pgPayment";

export const novaOrderFragment = gql`
  fragment NovaOrder on NovaOrder {
    id
    orderAmount
    pgOrderId
    billingAddress
    stateOfResidence
    status
    createdAt
    updatedAt
    pgPayments {
      ...PgPayment
    }
  }
  ${pgPaymentFragment}
`;

export default {
  placeNovaOrderMutation: gql`
    mutation placeNovaOrder($orderItems: [JSONObject]!, $billingAddress: String!, $stateOfResidence: String!) {
      placeNovaOrder(
        input: { orderItems: $orderItems, billingAddress: $billingAddress, stateOfResidence: $stateOfResidence }
      ) {
        novaOrder {
          ...NovaOrder
        }
      }
    }
    ${novaOrderFragment}
  `,
  cancelNovaOrderMutation: gql`
    mutation cancelNovaOrder($novaOrderId: ID!) {
      cancelNovaOrder(input: { novaOrderId: $novaOrderId }) {
        order
      }
    }
  `,
};
