import { EchoPostPlatform, EchoPostStatus } from "../../../../common/enums";
import { getListQuery } from "../queries";

export default {
  schema: {
    fields: [
      {
        model: "org",
        type: "id-selector",
        queryName: "orgs",
        gqlQuery: getListQuery("Org"),
        label: "Org of Sending User",
        disabled: true,
      },
      {
        model: "user",
        type: "id-selector",
        queryName: "users",
        gqlQuery: getListQuery("User"),
        label: "Sending User",
        disabled: true,
      },
      {
        model: "echoPostTemplate",
        type: "id-selector",
        queryName: "echoPostTemplates",
        gqlQuery: getListQuery("EchoPostTemplate"),
        label: "Template of this post",
        disabled: true,
      },
      {
        model: "platform",
        type: "select",
        values: Object.values(EchoPostPlatform),
        label: "Post Platform",
        disabled: true,
      },
      {
        model: "message",
        type: "text-area",
        inputType: "text",
        label: "Sent Message",
        disabled: true,
      },
      {
        model: "meta",
        type: "object",
        schema: {
          fields: [],
        },
      },
      {
        model: "status",
        type: "select",
        values: Object.values(EchoPostStatus),
        label: "Post Status",
        required: true,
      },
    ],
  },
}
