import { getListQuery, getSingleQuery } from "../queries";
import tpaOfficesSchema from "../schemas/tpaOffices";
import tpaOfficesMutations from "../mutations/tpaOffices";
import misc from "@/utils/misc";

const name = "TpaOffice";
export default {
  gqlType: name,
  singleQuery: getSingleQuery(name),
  listQuery: getListQuery(name),
  fields: ["name", "address", "pincode", "status"],
  ...tpaOfficesSchema,
  ...tpaOfficesMutations,
  transform: (n, write = false) => {
    if (write) {
      const tpa = misc.deepClone(n?.tpa);
      const payload = {
        ...n,
        tpaId: tpa?.id,
      };
      delete payload?.tpa;
      return payload;
    }
    return n;
  },
  getEdgesFromData: (data) => data.tpaOffices.edges,
};
