export const Base = require("./Base.vue").default;
export const routes = [
  {
    path: "",
    redirect: "org",
  },
  {
    path: ":section",
    component: () =>
      import(/* webpackChunkName: "org_admin" */ "./SectionDetails.vue"),
    name: "hr_onboarding_section_details",
  },
];
