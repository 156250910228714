import {
  InMemoryCache,
  IntrospectionFragmentMatcher,
} from "apollo-cache-inmemory";
import { CachePersistor } from "apollo-cache-persist";
import introspectionQueryResultData from "./fragmentTypes.json";

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData,
});

export const cache = new InMemoryCache({
  dataIdFromObject: (o) => o.id,
  fragmentMatcher,
});

export const persistor = new CachePersistor({
  cache,
  storage: window.localStorage,
});
