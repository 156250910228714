import moment from "moment";
import { RecurringBatchConfig } from "../../../../common/enums";
import { orgStatusSchema, industriesList, orgServicesOptions } from "../constants";

export default {
  schema: {
    fields: [
      {
        model: "name",
        type: "input",
        inputType: "text",
        label: "Org Name",
        required: true,
      },
      {
        model: "address",
        type: "input",
        inputType: "text",
        label: "Address",
        required: true,
      },
      {
        model: "pincode",
        type: "input",
        inputType: "text",
        label: "Pincode",
        validator: ["required", "regexp"],
        pattern: "^[1-9]{1}[0-9]{2}\\s{0,1}[0-9]{3}$",
        required: true,
      },
      {
        model: "meta",
        type: "object",
        label: "Metadata",
        schema: {
          fields: [
            {
              model: "period",
              type: "inline-select",
              label: "Period",
              options: () => {
                const currentYear = moment().year();
                const options = [];
                for (let year = currentYear; year <= currentYear + 2; year++) {
                  options.push(`${year}-${year + 1}`);
                }
                return options;
              },
              validator: ["required"],
              required: true,
            },
            {
              model: "displayName",
              type: "input",
              inputType: "text",
              label: "Display Name",
            },
            {
              model: "servicesOffered",
              type: "select",
              inputType: "text",
              label: "Services Offered",
              values: orgServicesOptions,
            },
            {
              model: "gstin",
              type: "input",
              inputType: "text",
              label: "GSTIN",
            },
            {
              model: "bank_acc",
              type: "input",
              inputType: "text",
              label: "Bank Account Number",
            },
            {
              model: "bank_ifsc",
              type: "input",
              inputType: "text",
              label: "Bank IFSC Code",
            },
            {
              model: "bank_bene",
              type: "input",
              inputType: "text",
              label: "Beneficiary Name",
            },
            {
              model: "contact_person",
              type: "input",
              inputType: "text",
              label: "Contact Person",
            },
            {
              model: "contact_number",
              type: "input",
              inputType: "text",
              label: "Contact Number",
            },
            {
              model: "onboardingWindow",
              type: "datepicker",
              label: "Onboarding Window",
              min: moment().format("YYYY-MM-DD HH:mm:ss"),
              max: "2100-12-31",
              placeholder: "DD/MM/YYYY",
              required: false,
              includeTimePicker: true,
            },
            {
              model: "industry",
              type: "inline-select",
              inputType: "text",
              label: "Industry",
              options: industriesList,
            },
            {
              model: "zohoId",
              type: "input",
              inputType: "text",
              label: "Org's ID as per Zoho CRM",
            },
            {
              model: "customOnboardingUrl",
              type: "input",
              inputType: "text",
              label: "Custom-Onboarding-Screen embed url",
            },
            {
              model: "customOnboardingTitle",
              type: "input",
              inputType: "text",
              label: "Custom-Onboarding-Screen title(defaults to 'Customize Your Coverage')",
            },
            {
              model: "doj",
              label: "Past Date of Joining Limit",
              type: "input",
              inputType: "number",
              min: 1,
              max: 90,
            },
            {
              model: "defaultCCListForClaims",
              label: "Default CC list for claims - Enter CSV (Optional)",
              type: "input",
              inputType: "text",
            },
          ],
        },
      },
      {
        model: "status",
        type: "select",
        label: "Status",
        values: orgStatusSchema,
        required: false,
      },
      {
        model: "batchConfig",
        type: "object",
        label: "Batch Configuration",
        schema: {
          fields: [
            {
              model: "recurring",
              type: "select",
              label: "Repeat every",
              values: Object.values(RecurringBatchConfig),
              required: true,
            },
            {
              model: "startDate",
              type: "input",
              inputType: "number",
              label: "Cycle start date",
              min: 1,
              max: 28,
            },
          ],
        },
      },
    ],
  },
};
