export const TeamsSchema = {
  schema: {
    fields: [
      {
        model: "name",
        type: "input",
        inputType: "text",
        label: "Team Name",
        required: true,
      },
      {
        model: "createdAt",
        type: "inline-input",
        inputType: "text",
        label: "Created At",
        styleClasses: "d-inline-block w-50 pr-1",
        disabled: true,
      },
      {
        model: "updatedAt",
        type: "inline-input",
        inputType: "text",
        label: "Last Updated At",
        styleClasses: "d-inline-block w-50 pr-1",
        disabled: true,
      },
    ],
  },
};
