<template lang="pug">
.dependent-selection(:class="{ 'pb-8': isMobileBrowser() || isApp() }")
  .text-gray-800.font-md.font-weight-medium.align-items-center.pt-4
    n-icon.mr-1.align-middle(
      name="users",
      :size=1
    )
    | Nova Plus: Coverage for you, your spouse &amp; children

  </br>
  .user-card-details.my-1
    .d-flex.user-card.align-items-center.py-3.cursor-pointer.my-1(
      v-for="(member, index) in getPlusLives()", 
      :key="index")
      b-form-checkbox.mx-3(
        :id="'member-' + member.id + '-checkbox'",
        :checked="isLifeSelected(member.id)",
        @change="updateSelectedLives(member, 'plus')"
      )
      .details
        .text-gray-900.font-weight-semibold.font-lg {{ member.name }}
        .text-gray-700.font-weight-medium.font-sm {{ member.relation }} • DOB • {{ getFormattedDate(member.dob) }}

  div(v-for="(plusDependent, index) in superTopupProspectState.plusDependents", :key="index")
    div.user-card-details.my-1
      .d-flex.user-card.align-items-center.py-3.cursor-pointer(:class="{'bg-teal-100 plus-dep-card-top': plusDependent.checked}")
        b-form-checkbox.mx-3(v-model="plusDependent.checked", @change="updatePlusDepSelection(plusDependent)")
        .details.text-gray-900.font-weight-semibold.font-lg
          | {{ getPlusRelation(plusDependent) }}
          template(v-if="plusDependent.relation !== 'spouse'")
            |  {{ getChildIndex(index) }}
      .user-card.form-container.p-4(v-if="plusDependent.checked", :class="{'plus-dep-card-bottom': plusDependent.checked}")
        b-form(@change="updatePlusDepSelection(plusDependent)")
          vue-form-generator(
            :schema="getSchema(plusDependent.relation)"
            :options="formOptions"
            ref="formData"
            :model="plusDependent")

  </br>
  .text-gray-800.font-md.font-weight-medium.align-items-center.pt-4
    n-icon.mr-1.align-middle(
      name="users",
      :size=1
    )
    | Nova Extended: Top-up options for your parents

  </br>
  div(v-for="(parent, index) in superTopupProspectState.parents", :key="index")
    div.user-card-details.my-1
      .d-flex.user-card.align-items-center.py-3.cursor-pointer(:class="{'bg-teal-100 plus-dep-card-top': parent.checked}")
        b-form-checkbox.mx-3(v-model="parent.checked", @change="updateParentSelection(parent)")
        .details.text-gray-900.font-weight-semibold.font-lg {{ "Parent " + (index + 1) }}
      .user-card.form-container.p-4(v-if="parent.checked", :class="{'plus-dep-card-bottom': parent.checked}")
        b-form(@change="updateParentSelection(parent)")
          vue-form-generator(
            :schema="getSchema('parent')"
            :options="formOptions"
            ref="formData"
            :model="parent")
</template>

<script>
import moment from "moment";
import NovaIcon from "../../../../../components/NovaIcon.vue";
import {
  validateDependentGender,
  validateParentAgeSuperTopup,
  validateSpouseAgeSuperTopup,
} from "../../../../../utils/validators";
import { AcceptedRelations } from "../../../../../common/enums";
import mixin from "./../mixin";
export default {
  name: "MemberAddition",
  components: {
    NovaIcon,
  },
  mixins: [mixin],
  data() {
    return {
      policyDetail: {},
      formOptions: {
        validateAfterLoad: true,
        validateAfterChanged: true,
        validateAsync: true,
      },
      title: "",
      schema: {
        fields: [
          {
            model: "name",
            type: "inline-input",
            inputType: "text",
            label: `Dependent Name`,
            placeholder: "Enter dependent’s full name",
            styleClasses: "d-inline-block w-50 pr-2 mb-4",
            labelClasses: "mb-2 pb-1",
            validator: ["required"],
            required: true,
          },
          {
            model: "gender",
            type: "select-cards",
            label: `Gender`,
            cardsData: [
              {
                name: "gender",
                icon: "female",
                label: "Female",
                card_value: "female",
                dataCy: "user-add-dependents-gender-female",
              },
              {
                name: "gender",
                icon: "male",
                label: "Male",
                card_value: "male",
                dataCy: "user-add-dependents-gender-male",
              },
            ],
            styleClasses: "mt-0 mb-2",
            validator: [validateDependentGender, "required"],
            required: true,
          },
        ],
      },
    };
  },
  watch: {
    "superTopupProspectState.plusDependents": {
      deep: true,
      handler(newPlusDependents, oldPlusDependents) {
        if (newPlusDependents !== oldPlusDependents) {
          this.$store.commit("updateSuperTopupProspectStateMeta", {
            plusDependents: newPlusDependents,
          });
        }
      },
    },
    "superTopupProspectState.parents": {
      deep: true,
      handler(newParents, oldParents) {
        if (newParents !== oldParents) {
          this.$store.commit("updateSuperTopupProspectStateMeta", {
            parents: newParents,
          });
        }
      },
    },
  },
  created() {
    this.$parent.$on("pushDepStates", () => {
      this.pushPlusDepToStore();
      this.pushParentsToStore();
    });
    this.$store.commit("updateSuperTopupProspectStateMeta", {
      isFormValid: true,
    });
    if (!this.superTopupProspectState.existingParentsInitialized) {
      this.populateParentData();
    }
    if (!this.superTopupProspectState.existingSpouseAndChildrenInitialized) {
      this.populatePlusLivesData();
    }
  },
  async mounted() {
    this.$store.commit("updateSuperTopupProspectStateMeta", {
      acknowledgedStages: {
        ...this.superTopupProspectState.acknowledgedStages,
        "add-members": false,
      },
    });
    await this.$apollo.queries.retailPolicies.refetch();
  },
  methods: {
    getSchema(relation) {
      const existingFields = [...this.schema.fields];
      existingFields.splice(1, 0, {
        model: "dob",
        type: "datepicker",
        label: `Date of Birth`,
        required: true,
        min: "1900-01-01",
        max: moment().format("YYYY-MM-DD"),
        styleClasses: "d-inline-block w-50 pl-2 align-top",
        labelClasses: "mb-2 pb-1",
        validator: this.getValidator(relation),
        required: true,
      });
      return {
        fields: existingFields,
      };
    },
    getValidator(relation) {
      if (relation === AcceptedRelations.PARENT) return ["required", validateParentAgeSuperTopup];
      if (relation === AcceptedRelations.SPOUSE) return ["required", validateSpouseAgeSuperTopup];
      return ["required"];
    },
    populatePlusLivesData() {
      this.populateSpouseData();
      this.populateChildrenData();
      if (
        !this.superTopupProspectState.existingSpouseAndChildrenInitialized &&
        (this.getSpouseFromStore() || this.getChildrenFromStore().length)
      ) {
        this.$store.commit("updateSuperTopupProspectStateMeta", {
          existingSpouseAndChildrenInitialized: true,
        });
      }
    },
    populateSpouseData() {
      const spouseFromStore = this.getSpouseFromStore();
      if (spouseFromStore) {
        const spouse = this.superTopupProspectState.plusDependents.find(
          (dep) => dep.relation === AcceptedRelations.SPOUSE
        );
        spouse.checked = true;
        spouse.name = spouseFromStore.name;
        spouse.dob = spouseFromStore.dob;
        spouse.gender = spouseFromStore.gender;
      }
    },
    populateChildrenData() {
      const childrenFromStore = this.getChildrenFromStore();
      if (childrenFromStore && childrenFromStore.length > 0) {
        this.superTopupProspectState.plusDependents
          .filter((dep) => dep.relation === AcceptedRelations.CHILD)
          .forEach((dep, index) => {
            if (childrenFromStore[index]) {
              dep.checked = true;
              dep.name = childrenFromStore[index].name;
              dep.dob = childrenFromStore[index].dob;
              dep.gender = childrenFromStore[index].gender;
            }
          });
      }
    },
    populateParentData() {
      const parentsFromStore = this.getParentsFromStore();
      if (parentsFromStore && parentsFromStore.length > 0) {
        this.superTopupProspectState.parents.forEach((parent, index) => {
          if (parentsFromStore[index]) {
            parent.checked = true;
            parent.name = parentsFromStore[index].name;
            parent.dob = parentsFromStore[index].dob;
            parent.gender = parentsFromStore[index].gender;
          }
        });
      }
      if (!this.superTopupProspectState.existingParentsInitialized && this.getParentsFromStore().length) {
        this.$store.commit("updateSuperTopupProspectStateMeta", {
          existingParentsInitialized: true,
        });
      }
    },
    getChildIndex(index) {
      return this.getPlusLives().some((life) => life.relation === AcceptedRelations.SPOUSE) ? index + 1 : index;
    },
    getPlusRelation(life) {
      return life.relation === AcceptedRelations.SPOUSE ? "Spouse" : "Child";
    },
    isPlusDepValid(life) {
      return life.name && life.dob && life.gender;
    },
    updateFormValidity() {
      const isAnyPlusDepValid = this.superTopupProspectState.plusDependents.some((life) => {
        return !life.checked || (life.checked && this.isPlusDepValid(life));
      });

      const isAnyParentValid = this.superTopupProspectState.parents.some((parent) => {
        return !parent.checked || (parent.checked && this.isParentValid(parent));
      });

      const isFormValid = isAnyPlusDepValid || isAnyParentValid;

      this.$store.commit("updateSuperTopupProspectStateMeta", {
        isFormValid,
      });
    },
    pushPlusDepToStore() {
      this.superTopupProspectState.plusDependents.forEach((life) => this.updatePlusDepSelection(life, true));
    },
    pushParentsToStore() {
      this.superTopupProspectState.parents.forEach((parent) => this.updateParentSelection(parent, true));
    },
    updatePlusDepSelection(life, reset = false) {
      const selectedPolicyId = this.getPlusPolicy?.id;
      const policyDetail = this.superTopupProspectState.policyDetail;
      if (!selectedPolicyId) return;

      if (!policyDetail.hasOwnProperty(selectedPolicyId)) {
        this.$set(policyDetail, selectedPolicyId, {
          coveredMembers: [],
        });
      }
      const policyIdEntry = policyDetail[selectedPolicyId];
      const index = this.superTopupProspectState.plusDependents.findIndex((c) => c === life);

      if (index !== -1) {
        const userKey = index.toString();
        const indexInCoveredMembers = policyIdEntry.coveredMembers.findIndex((member) => member.key === userKey);

        if (life.checked) {
          let member;
          if (life.relation === AcceptedRelations.SPOUSE) {
            member = {
              key: userKey,
              name: life.name,
              dob: life.dob,
              relation: AcceptedRelations.SPOUSE,
            };
          } else {
            member = {
              key: userKey,
              name: life.name,
              dob: life.dob,
              relation: AcceptedRelations.CHILD,
            };
          }
          if (indexInCoveredMembers === -1) {
            policyIdEntry.coveredMembers.push({ ...member });
          } else {
            policyIdEntry.coveredMembers[indexInCoveredMembers] = { ...member };
          }
        } else if (!life.checked && indexInCoveredMembers !== -1) {
          policyIdEntry.coveredMembers.splice(indexInCoveredMembers, 1);
        }
        if (!life.checked && reset) {
          life.name = "";
          life.dob = "";
          life.gender = "";
        }

        this.$set(policyDetail, selectedPolicyId, policyIdEntry);
        const updatePlusSelection = {};
        if (!this.getAllSelectedPlusMembers().length) {
          updatePlusSelection.isNovaPlusSelected = false;
          updatePlusSelection.novaPlusPremiumDetails = {};
        }
        this.$store.commit("updateSuperTopupProspectStateMeta", {
          policyDetail,
          ...updatePlusSelection,
        });
      }
      this.updateFormValidity();
    },
    updateParentSelection(parent, reset = false) {
      const selectedPolicyId = this.getExtendedPolicy?.id;
      const policyDetail = this.superTopupProspectState.policyDetail;
      if (!selectedPolicyId) return;
      if (!policyDetail.hasOwnProperty(selectedPolicyId)) {
        this.$set(policyDetail, selectedPolicyId, {
          coveredMembers: [],
        });
      }

      const policyIdEntry = policyDetail[selectedPolicyId];

      const index = this.superTopupProspectState.parents.findIndex((p) => p === parent);
      if (index !== -1) {
        const userKey = index.toString();
        const indexInCoveredMembers = policyIdEntry.coveredMembers.findIndex((member) => member.key === userKey);

        if (parent.checked) {
          const member = {
            key: userKey,
            name: parent.name,
            dob: parent.dob,
            relation: AcceptedRelations.PARENT,
          };
          if (indexInCoveredMembers === -1) {
            policyIdEntry.coveredMembers.push({ ...member });
          } else {
            policyIdEntry.coveredMembers[indexInCoveredMembers] = { ...member };
          }
        } else if (!parent.checked && indexInCoveredMembers !== -1) {
          policyIdEntry.coveredMembers.splice(indexInCoveredMembers, 1);
        }
        if (!parent.checked && reset) {
          parent.name = "";
          parent.dob = "";
          parent.gender = "";
        }

        this.$set(policyDetail, selectedPolicyId, policyIdEntry);
        const updateExtendedSelection = {};
        if (!policyIdEntry.coveredMembers?.length) {
          updateExtendedSelection.isNovaExtendedSelected = false;
          updateExtendedSelection.novaExtendedPremiumDetails = {};
        }
        this.$store.commit("updateSuperTopupProspectStateMeta", {
          policyDetail,
          ...updateExtendedSelection,
        });
      }
      this.updateFormValidity();
    },
    validateForm(isValid, errors) {
      this.$store.commit("updateSuperTopupProspectStateMeta", {
        isFormValid: isValid,
      });
    },
    getFormattedDate(date) {
      return moment(date).format("Do MMMM YYYY");
    },
    isLifeSelected(id) {
      return this.superTopupProspectState?.selectedLives.findIndex((life) => life.id === id) !== -1;
    },
    allLives() {
      return [...this.getPlusLives()];
    },
    updateSelectedLives(user, type) {
      const selectedPolicyId = type === "plus" ? this.getPlusPolicy?.id : this.getExtendedPolicy?.id;
      const policyDetail = this.superTopupProspectState.policyDetail;
      if (!selectedPolicyId) return;

      if (!policyDetail.hasOwnProperty(selectedPolicyId)) {
        this.$set(policyDetail, selectedPolicyId, {
          coveredMembers: [],
        });
      }

      const policyIdEntry = policyDetail[selectedPolicyId];

      const selectedLives = this.superTopupProspectState.selectedLives;

      const indexInSelectedLives = selectedLives.findIndex((life) => life.id === user.id);

      if (indexInSelectedLives !== -1) {
        selectedLives.splice(indexInSelectedLives, 1);
        const indexInCoveredMembers = policyIdEntry.coveredMembers.findIndex((member) => member.id === user.id);
        if (indexInCoveredMembers !== -1) {
          policyIdEntry.coveredMembers.splice(indexInCoveredMembers, 1);
        }
      } else {
        selectedLives.push(user);

        policyIdEntry.coveredMembers.push({
          name: user.name,
          relation: user.relation,
          dob: user.dob,
          id: user.id,
        });
      }

      this.$set(policyDetail, selectedPolicyId, policyIdEntry);

      const filteredLives = this.allLives().filter((user) => selectedLives.some((life) => life.id === user.id));

      this.$store.commit("updateSuperTopupProspectStateMeta", {
        selectedLives: filteredLives,
        policyDetail,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
.dependent-selection {
  .user-card {
    background-color: white;
    border: 0.125rem solid $gray-300;
    border-radius: 0.375rem;

    &.selected {
      background-color: $teal-100;
      border: 0.125rem solid $teal-700;
      box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.04);
    }
  }

  .plus-dep-card-top {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom: 0;
  }
  .plus-dep-card-bottom {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}
</style>
