export const PaymentStatus = {
  CREATED: "created",
  AUTHORIZED: "authorized",
  CAPTURED: "captured",
  REFUND_CREATED: "refund-created",
  REFUNDED: "refunded",
  REFUND_FAILED: "refund-failed",
  FAILED: "failed",
  REVERSED: "reversed",
  PENDING: "pending",
  BLOCKED: "blocked",
  DISPUTED: "disputed",
};

export const PaymentGateway = {
  RAZORPAY: "razorpay",
};

export const SimplifiedRefundStatus = {
  INITIATED: "initiated",
  COMPLETED: "completed",
};
