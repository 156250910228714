import { getIdSelectorQuery, getListQuery } from "../queries";
import { challengeStatusSchema } from "../constants";
import { validateDateRange } from "@/utils";

export default {
  schema: {
    fields: [
      {
        model: "segment",
        type: "id-selector",
        queryName: "segments",
        gqlQuery: getListQuery("Segment"),
        queryVariables: () => ({
          filter: {
            segmentType: "FITNESS",
          },
        }),
        label: "Fitness Segment",
        required: true,
      },
      {
        model: "org",
        type: "id-selector",
        queryName: "orgs",
        gqlQuery: getIdSelectorQuery("Org"),
        label: "Organization",
        hint: "During creation, organization will only be considered if no segment is provided",
        required: true,
      },
      {
        model: "challengeTemplate",
        type: "id-selector",
        queryName: "challengeTemplates",
        gqlQuery: getListQuery("ChallengeTemplate"),
        queryVariables: () => ({
          filter: {
            templateType: "org",
          },
        }),
        label: "Challenge Template",
        required: true,
      },
      {
        model: "startDate",
        type: "datepicker",
        label: "Challenge Start Date",
        min: "2020-01-01",
        max: "2100-12-31",
        required: true,
      },
      {
        model: "startTime",
        type: "timepicker",
        label: "Challenge Start Time",
        required: true,
      },
      {
        model: "expiryDate",
        type: "datepicker",
        label: "Challenge Expiry Date",
        min: "2020-01-01",
        max: "2100-12-31",
        readonly: true,
        hint: "This date is calculated from the given start date, start time and duration of the chosen challenge template",
      },
      {
        model: "expiryTime",
        type: "timepicker",
        label: "Challenge Expiry Time",
        readonly: true,
        hint: "This time is calculated from the given start date, start time and duration of the chosen challenge template",
      },
      {
        model: "challengeStatus",
        type: "select",
        label: "Status",
        values: Object.values(challengeStatusSchema),
        hint: "Status will always be CREATED when the challenge is initially created",
      },
    ],
  },
  filterSchema: {
    fields: [
      {
        model: "segment",
        label: "Segment",
        type: "id-selector",
        queryName: "segments",
        gqlQuery: getListQuery("Segment"),
        queryVariables: () => ({
          filter: {
            segmentType: "FITNESS",
          },
        }),
        placeholder: "Fitness Segment filter (none)",
        showSelected: false,
      },
      {
        model: "org",
        label: "Organization",
        type: "id-selector",
        queryName: "orgs",
        gqlQuery: getIdSelectorQuery("Org"),
        placeholder: "Organization filter (none)",
        showSelected: false,
      },
      {
        model: "challengeStatus",
        label: "Challenge status",
        type: "select",
        values: Object.values(challengeStatusSchema),
        selectOptions: {
          noneSelectedText: "Challenge Status filter (none)",
        },
      },
      {
        model: "challengeStartDateBeginning",
        label: "Start Date Beginning",
        type: "datepicker",
        placeholder: "Start Date Beginning (none)",
        min: "2020-01-01",
        max: "2100-12-31",
      },
      {
        model: "challengeStartDateEnd",
        label: "Start Date End",
        type: "datepicker",
        placeholder: "Start Date End (none)",
        min: "2020-01-01",
        max: "2100-12-31",
      },
    ],
  },
  copySchema: {
    fields: [
      {
        model: "segment",
        type: "id-selector",
        queryName: "segments",
        gqlQuery: getListQuery("Segment"),
        queryVariables: () => ({
          filter: {
            segmentType: "FITNESS",
          },
        }),
        label: "Fitness Segment",
        required: true,
      },
      {
        model: "dates",
        type: "date-range",
        label: "Dates to copy current schedule",
        min: "1900-01-01",
        max: "2100-12-31",
        value: null,
        required: true,
        validator: [validateDateRange],
      },
    ],
  },
};
