<template lang="pug">
b-badge.n-chip.d-inline-flex.align-items-center.font-weight-semibold(
  v-bind="{ ...$props, ...$attrs }",
  v-on="$listeners", 
  :class="[textColorClass, smallChip ? `font-xs small-chip`: 'font-sm', isMinimal ? 'minimal-chip' : '']")
  span.mr-1(v-if="chipIcon && strokeCount")
    n-icon.font-xs(
      :name="chipIcon",
      :pathCount="strokeCount",
      :size="size",
      :class="`${iconColor ? `text-${iconColor}`: ''}`")
  span.mr-1(v-else-if="chipIcon")
    i.h5.m-0.align-text-bottom.font-xs(:style="`font-size: ${size}rem`", :class="`icon-${chipIcon} ${iconColor ? `text-${iconColor}`: ''}`")

  template(v-else)
    slot(name="icon")
  template.font-weight-medium.font-xs
    slot(name="default")

  template(v-if="$slots.secondary")
    span.mx-2.text-gray-900 |
    slot(name="secondary")
</template>

<script>
export default {
  name: "NChip",
  props: {
    chipIcon: {
      type: String,
      default: null,
    },
    isMinimal: {
      type: Boolean,
      default: false,
    },
    smallChip: {
      type: Boolean,
      default: false,
    },
    iconColor: {
      type: String,
      default: null,
    },
    textColor: {
      type: String,
      default: null,
    },
    iconSize: {
      type: Number,
      default: null,
    },
    strokeCount: {
      type: Number,
      default: null,
    },
  },
  computed: {
    textColorClass() {
      if (this.textColor) {
        return `text-${this.textColor}`;
      } else if (this.isMinimal) {
        return "text-blackpearl";
      }
      return "";
    },
    size() {
      if (this.iconSize) return this.iconSize;
      else if (this.smallChip) {
        return 0.8;
      }
      return 1;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
@import "@/assets/styles/_typography.scss";

.n-chip {
  padding: 0.37rem 0.625rem;
  max-width: max-content;
  cursor: pointer;
  max-height: 1.5rem;
  .chip-icon {
    color: $white;
  }
}
.badge-pill {
  border-radius: 10rem;
}

.minimal-chip {
  border: 1px solid $gray-300;
  background-color: $white !important;
}
.small-chip {
  padding: 0.25rem 0.37rem;
}
</style>
