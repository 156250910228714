import { ApolloLink } from "apollo-link";
import { TokenRefreshLink } from "apollo-link-token-refresh";
import { refreshToken } from "../queriesAndMutations/auth";
import { store } from "./../../portal/store";
import { isTokenExpired, logoutActiveUser } from "./../auth";
import { OPEN_QUERIES_AND_MUTATIONS } from "./../constants/auth";

export const authMiddleware = new ApolloLink((op, forward) => {
  const { operationName } = op;

  if (OPEN_QUERIES_AND_MUTATIONS.includes(operationName)) {
    return forward(op);
  }

  const token = localStorage.getItem("token");

  const imposterToken = localStorage.getItem("imposterToken");
  if (token) {
    op.setContext({
      headers: {
        authorization: `Bearer ${token}`,
        date: new Date().toISOString(),
        "apollo-require-preflight": true,
        ...(imposterToken && { "impersonated-token": `Bearer ${imposterToken}` }),
      },
    });

    return forward(op);
  } else if (store.getters.isLoggedIn) {
    window.posthog.capture("forced_logout_closed_mutation", { operationName });
    console.error("Logging out because mutation was not open and token was not found:", operationName);
    logoutActiveUser(false);
  } else {
    return forward(op);
  }
});

export const refreshTokenMiddleware = new TokenRefreshLink({
  accessTokenField: "token",
  isTokenValidOrUndefined: () => {
    const { accessTokenExpiry } = store.state;
    // TEMP
    if (!accessTokenExpiry) {
      return true;
    }

    if (isTokenExpired(accessTokenExpiry)) {
      return false;
    }

    return true;
  },
  fetchAccessToken: async () => {
    let tokenBeingRefreshed = true;

    if (!store.state.refreshToken) {
      return;
    }

    try {
      const { user, token } = await fetchNewAccessToken();
      tokenBeingRefreshed = false;
      return { user, token };
    } catch (e) {
      tokenBeingRefreshed = false;
      if (e?.networkError?.result?.errors[0]?.errorMessage !== "VERSION_MISMATCH") {
        await logoutActiveUser(false);
      }
    }
  },
  handleResponse: (operation, accessTokenField) => (authenticateUser) => authenticateUser || {},
  handleFetch: (accessToken, operation) => {
    // do nothing
  },
  handleError: async (err) => {
    console.warn("Your refresh token is invalid. Try to relogin");
    console.error(err);
    // we're not calling logout here, since
    // it is already called once refreshing token
    // is failed, in renew token
  },
});

export const fetchNewAccessToken = async () => {
  const freshTokens = await refreshToken(store.state.refreshToken);
  const { user, token } = freshTokens?.data?.authenticateWithRefreshToken;

  store.commit("refreshAccessToken", { user, token });
  return { user, token };
};
