import gql from "graphql-tag";

export const jobFragment = gql`
  fragment Job on Job {
    id
    name
    queueName
    status
    failedReason
    result
    logs
    timestamp
    email
    fileURL
    progress
  }
`;

export const jobWithoutLogsFragment = gql`
  fragment Job on Job {
    id
    name
    queueName
    status
    failedReason
    result
    timestamp
    email
    fileURL
    progress
  }
`;
