import gql from "graphql-tag";

export const designationFragment = gql`
  fragment Designation on Designation {
    id
    name
    status
    createdAt
    updatedAt
  }
`;
