import gql from "graphql-tag";
import { novaProductFragment } from "../fragments/novaProduct";

export default {
  updateNovaProductStatus: gql`
    mutation updateNovaProductStatus($id: ID!, $status: String!) {
      updateNovaProductStatus(input: { id: $id, status: $status }) {
        novaProduct {
          ...NovaProduct
        }
      }
    }
    ${novaProductFragment}
  `,
};
