export default {
  schema: {
    fields: [
      { 
        model: "name", 
        type: "input", 
        inputType: "text", 
        label: "Name", 
        required: true,
      },
      {
        model: "address",
        type: "input",
        inputType: "text",
        label: "Address",
        required: true,
      },
      {
        model: "city",
        type: "input",
        inputType: "text",
        label: "City",
      },
      {
        model: "pincode",
        type: "input",
        inputType: "text",
        validator: "regexp",
        pattern: "^[1-9]{1}[0-9]{2}\\s{0,1}[0-9]{3}$",
        label: "Pincode",
        required: true,
      },
      {
        model: "state",
        type: "input",
        inputType: "text",
        label: "State",
      },
      {
        model: "contact",
        type: "input",
        inputType: "tel",
        label: "Contact",
      },
    ],
  },
}
