import gql from "graphql-tag";

export const accountFragment = gql`
  fragment Account on Account {
    id
    orgId
    orgEntity {
      id
      name
    }
    type
    balance
    minBalance
    insurerId
    bankDetails
  }
`;
