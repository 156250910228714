import gql from "graphql-tag";
import { benefitFragment } from "./benefit";

export const orgEntityBenefitFragment = gql`
  fragment OrgEntityBenefitEdge on OrgEntityBenefitEdge {
    node {
      ...Benefit
    }
    meta
    userCount
  }
  ${benefitFragment}
`;

export const orgEntityFragment = gql`
  fragment OrgEntity on OrgEntity {
    id
    org {
      id
      name
    }
    name
    address
    pincode
    meta
    accounts
    stats
    benefits {
      ...OrgEntityBenefitEdge
    }
    featureFlags
    brandCustomizations
    preferences
    status
    updatedAt
  }
  ${orgEntityBenefitFragment}
`;

export const orgEntityIdSelectorFragment = gql`
  fragment OrgEntity on OrgEntity {
    id
    org {
      id
      name
    }
    name
  }
`;

export const orgEntityResourceListFragment = gql`
  fragment OrgEntity on OrgEntity {
    id
    org {
      id
      name
    }
    name
    address
    pincode
    meta
    accounts
    stats
    featureFlags
    brandCustomizations
    preferences
    status
    updatedAt
  }
`;
