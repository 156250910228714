<template lang="pug">
div
  mobile-assessment-reports(v-if="isMobileApp()")
  desktop-assessment-reports(v-else)
</template>

<script>
import DesktopAssessmentReports from "./DesktopAssessmentReports.vue";
import MobileAssessmentReports from "./MobileAssessmentReports.vue";
import mobileApp from "../../../../mobileApp";

export default {
  name: "AssessmentReports",
  components: {
    DesktopAssessmentReports,
    MobileAssessmentReports,
  },
  methods: {
    isMobileApp() {
      return mobileApp.isApp;
    },
  },
};
</script>
