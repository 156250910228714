export const OPEN_QUERIES_AND_MUTATIONS = [
  "fetchLoginConfig",
  "LoginMutation",
  "sendMagicLink",
  "getUserEmailFromToken",
  "AuthenticateWithRefreshToken",
  "VerifyToken",
  "magicLinkValidation",
  "setPassword",
  "ResetPassword",
  "fetchAuthTokens",
];
