import gql from "graphql-tag";

export const echoPostFragment = gql`
  fragment EchoPost on EchoPost {
    id
    echoPostTemplate {
      id
      category
      subject
      org {
        id
        name
      }
    }
    user {
      id
      name
      email
      org {
        id
        name
      }
    }
    recipients {
      node {
        id
        name
        email
      }
    }
    platform
    message
    status
    reactionsCount
    repliesCount
    meta
  }
`;
