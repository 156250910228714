<template lang="pug">
n-contact-consent(v-bind="schema", v-model="value")
</template>

<script>
import { abstractField } from "vue-form-generator";
import NContactConsent from "../NovaContactConsent.vue";
export default {
  components: {
    NContactConsent,
  },
  mixins: [abstractField],
};
</script>
