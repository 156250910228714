import gql from "graphql-tag";
import { strapiContentFragment } from "./strapi";
import { tpaFragment } from "./tpa";
import { tpaOfficeFragment } from "./tpaOffice";

export const coverageParametersFragment = gql`
  fragment CoverageParameters on CoverageParameters {
    inclusions {
      parameter {
        ...StrapiContent
      }
    }
    exclusions {
      parameter {
        ...StrapiContent
      }
    }
  }
  ${strapiContentFragment}
`;
export const benefitFragment = gql`
  fragment Benefit on Benefit {
    id
    name
    section
    type
    meta
    status
    coverageParameters {
      ...CoverageParameters
    }
    getCoveredDependents
    isPolicy
    restrictedFields
    category
    subCategory
    chargesConfig
    insurerDetails
    startDate
    endDate
    redirectUrl
    documents
    tpaClaimsLastSync
    claimFormsLink
    createdBy {
      id
      name
    }
    updatedBy {
      id
      name
    }
    retailPolicyId
    createdAt
    updatedAt
    insurer {
      id
      slug
      name
      photoUrl
      s3Url
    }
    tpa {
      ...Tpa
    }
    tpaOffice {
      ...TpaOffice
    }
    content {
      ...StrapiContent
    }
    coverageText {
      ...StrapiContent
    }
    cashlessContent {
      ...StrapiContent
    }
    reimbursementContent {
      ...StrapiContent
    }
    emergencyContent {
      ...StrapiContent
    }
    policyClaimContent {
      ...StrapiContent
    }
  }
  ${tpaFragment}
  ${tpaOfficeFragment}
  ${strapiContentFragment}
  ${coverageParametersFragment}
`;

// TODO Add GraphQL type for coverageParameters to fetch limited details
export const benefitFragmentForCliTools = gql`
  fragment Benefit on Benefit {
    id
    name
    coverageParameters {
      inclusions {
        parameter {
          id
          slug
          strapiId
        }
      }
      exclusions {
        parameter {
          id
          slug
          strapiId
        }
      }
    }
    startDate
    endDate
    tpa {
      name
    }
    type
    insurer {
      name
    }
    status
  }
`;

export const benefitFragmentWithoutContent = gql`
  fragment BenefitWithoutContent on Benefit {
    id
    name
    type
    meta
    isPolicy
    restrictedFields
    getCoveredDependents
    category
    subCategory
    section
    chargesConfig
    insurerDetails
    retailPolicyId
    endDate
    insurer {
      id
      slug
      name
      photoUrl
    }
    tpa {
      ...Tpa
    }
  }
  ${tpaFragment}
`;

export const userChangeBenefitFragment = gql`
  fragment UserChangeBenefit on Benefit {
    id
    name
    type
    meta
    isPolicy
    restrictedFields
    getCoveredDependents
    category
    subCategory
    section
    chargesConfig
    insurerDetails
    endDate
    insurer {
      id
      slug
      name
      photoUrl
    }
    tpa {
      ...Tpa
    }
    content {
      ...StrapiContent
    }
    coverageText {
      ...StrapiContent
    }
  }
  ${tpaFragment}
  ${strapiContentFragment}
`;

export const dependentBenefitFragment = gql`
  fragment DependentBenefitEdge on DependentBenefitEdge {
    node {
      ...Benefit
    }
    meta
  }
  ${benefitFragment}
`;

export const dependentBenefitFragmentWithoutContent = gql`
  fragment DependentBenefitEdgeWithoutContent on DependentBenefitEdge {
    node {
      ...BenefitWithoutContent
    }
    meta
  }
  ${benefitFragmentWithoutContent}
`;

export const benefitIdSelectorFragment = gql`
  fragment Benefit on Benefit {
    id
    name
  }
`;
