<template lang="pug">
b-button(v-bind="{...$props, ...$attrs}", :class="isDarkBG ? 'white-bg' : ''", @click="onClick")
  .d-flex.align-items-center.justify-content-center
    n-icon.align-sub(
      v-if="imageIcon",
      :name="imageIcon",
      :size="iconSize",
      :pathCount="imageIconPathCount",
      :class="[buttonText ? `mr-2` : '']")
    div.text-box(:class="{ 'text-decoration-underline': textUnderline, 'text-box--small': size === 'sm' }")
      span.font-weight-medium {{ buttonText }}
    n-icon.align-sub(
      v-if="rightImageIcon",
      :name="rightImageIcon",
      :size="iconSize",
      :class="[buttonText ? `ml-2` : '']")
</template>

<script>
import NIcon from "./NovaIcon.vue";
export default {
  name: "NButton",
  components: {
    NIcon,
  },
  props: {
    buttonText: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    variant: {
      type: String,
      default: null,
    },
    imageIcon: {
      type: String,
      default: null,
    },
    imageIconPathCount: {
      type: Number,
      default: 0,
    },
    rightImageIcon: {
      type: String,
      default: null,
    },
    to: {
      type: [String, Object],
      default: null,
    },
    type: {
      type: String,
      default: null,
    },
    size: {
      type: String,
      default: null,
    },
    isDarkBG: {
      type: Boolean,
      default: false,
    },
    textUnderline: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    iconSize() {
      if (this.size === "xs") return 0.8;
      if (this.size === "sm") return 1;
      return 1.3;
    },
  },
  methods: {
    onClick(event) {
      if (this.disabled) return;
      this.$emit("click", event);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
.white-bg {
  &:hover,
  &:active,
  &.active,
  &:focus,
  &.focus,
  &.active:focus,
  &.active.focus,
  &:active.focus,
  &:active:focus {
    background: $white !important;
    border-color: $white !important;
    & > * {
      color: $gray-900 !important;
    }
  }
}

.text-box {
  display: flex;
  align-items: center;
  height: 1.3rem;

  &--small {
    height: 1rem;
  }
}
</style>
