// vueform.config.js

import { defineConfig } from "@vueform/vueform";
import CustomListElement from "../components/VueForm/CustomListElement.vue";
import en from "@vueform/vueform/locales/en";
import bootstrap from "@vueform/vueform/dist/bootstrap.vue2";

export default defineConfig({
  elements: [CustomListElement],
  displayErrors: false,
  theme: bootstrap,
  locales: { en },
  locale: "en",
});
