import gql from "graphql-tag";
import adminDefs from "../admin/definitions";
import {
  userFragment,
  integrationFragment,
  stagedUserFragment,
  jobFragment,
  claimOrgAdminFragment,
} from "../admin/fragments";
import { getIdSelectorQuery, getListQuery, getResourceListQuery } from "../admin/queries";
import utils from "../../../utils";
import { validateDateRange } from "@/utils/date";
import misc from "@/utils/misc";

const rowStyling = ["align-middle"];

export default {
  users: {
    gqlType: "User",
    displayName: "Member",
    fields: [
      {
        key: "email",
        label: "Email",
        class: rowStyling,
      },
      {
        key: "name",
        label: "Name",
        class: rowStyling,
      },
      {
        key: "gender",
        label: "Gender",
        class: rowStyling,
      },
      {
        key: "status",
        label: "Status",
        class: rowStyling,
      },
    ],
    listQuery: getResourceListQuery("User"),
    singleQuery: adminDefs.users.singleQuery,
    schema: ((_schema) => {
      const schema = misc.deepClone(_schema);
      schema.fields.find((f) => f.model === "org").visible = false;
      schema.fields.find((f) => f.model === "password").visible = false;
      schema.fields.find((f) => f.model === "gender").validator = "required";
      const meta = schema.fields.find((f) => f.model === "meta");
      meta.schema.fields.find((f) => f.model === "dateOfJoining").required = true;
      meta.schema.fields.find((f) => f.model === "priority").visible = false;
      return schema;
    })(adminDefs.users.schema),
    insertUserWithEndorsements: adminDefs.users.insertUserWithEndorsements,
    deleteUser: adminDefs.users.deleteUser,
    deleteUsersByIds: adminDefs.users.deleteUsersByIds,
    deleteUserWithEndorsements: gql`
      mutation deleteUserWithEndorsements($id: ID!, $orgId: ID!, $dateOfExit: String!) {
        deleteUserWithEndorsements(input: { id: $id, orgId: $orgId, dateOfExit: $dateOfExit }) {
          user {
            ...User
          }
        }
      }
      ${userFragment}
    `,
    bulkDeleteUser: gql`
      mutation bulkDeleteUser($userData: [JSONObject]!, $orgId: ID!) {
        bulkDeleteUser(input: { userData: $userData, orgId: $orgId }) {
          job {
            ...Job
          }
        }
      }
      ${jobFragment}
    `,
    undoUserSuspension: gql`
      mutation undoUserSuspension($id: ID!) {
        undoUserSuspension(input: { id: $id }) {
          user {
            ...User
          }
        }
      }
      ${userFragment}
    `,
    addBenefitConnectionMutation: adminDefs.users.addBenefitConnectionMutation,
    deleteBenefitConnectionMutation: adminDefs.users.deleteBenefitConnectionMutation,
    transformAddBenefit: (data) => data.addUserToBenefit.userBenefitEdge,
    transformDeleteBenefit: (data) => data.deleteUserFromBenefit.deletedCount,
    benefitsFragment: adminDefs.users.benefitsFragment,
    benefitsFragmentName: adminDefs.users.benefitsFragmentName,
    transform: ({ node }) => {
      return { ...node, orgId: node.org.id };
    },
    getNodeFromInsertUserData: (data) => data.insertUserWithEndorsements.user,
    getEdgesFromData: (data) => data.users.edges,
    getTotalCountFromData: (data) => data.users.totalCount,
    exportEmployeesData: gql`
      mutation exportEmployeeData($orgId: ID, $meta: JSONObject, $filter: JSONObject) {
        exportEmployeeData(input: { orgId: $orgId, meta: $meta, filter: $filter }) {
          message
        }
      }
    `,
    insertDependentWithEndorsements: adminDefs.users.insertDependentWithEndorsements,
    transformInsertDependentWithEndorsements: adminDefs.users.transformInsertDependentWithEndorsements,
    deleteDependentMutation: adminDefs.users.deleteDependentMutation,
    dependentsFragment: adminDefs.users.dependentsFragment,
    dependentsFragmentName: adminDefs.users.dependentsFragmentName,
    resyncUserBenefits: adminDefs.users.resyncUserBenefits,
    authorizeAndinviteUsers: gql`
      mutation authorizeAndinviteUsers($orgId: ID!) {
        authorizeAndinviteUsers(input: { orgId: $orgId }) {
          users {
            ...User
          }
        }
      }
      ${userFragment}
    `,
    reconsiderAdditionUser: gql`
      mutation reconsiderAdditionUser($id: ID!, $orgId: ID!) {
        reconsiderAdditionUser(input: { id: $id, orgId: $orgId }) {
          user {
            ...StagedUser
          }
        }
      }
      ${stagedUserFragment}
    `,
    ignoreUser: gql`
      mutation ignoreUser($id: ID!, $orgId: ID!) {
        ignoreUser(input: { id: $id, orgId: $orgId }) {
          user {
            ...StagedUser
          }
        }
      }
      ${stagedUserFragment}
    `,
    ignoreUsers: gql`
      mutation ignoreUsers($userIds: [ID]!, $orgId: ID!, $orgEntityId: ID) {
        ignoreUsers(input: { userIds: $userIds, orgId: $orgId, orgEntityId: $orgEntityId }) {
          success
        }
      }
    `,
    reconsiderAdditionUsers: gql`
      mutation reconsiderAdditionUsers($userIds: [ID]!, $orgId: ID!, $orgEntityId: ID) {
        reconsiderAdditionUsers(input: { userIds: $userIds, orgId: $orgId, orgEntityId: $orgEntityId }) {
          success
        }
      }
    `,
    // TODO: This can be removed and merged into sendInviteToUsersByOrgId
    sendInvitesToFetchedUsers: gql`
      mutation sendInvitesToFetchedUsers($orgId: ID!, $orgEntityId: ID) {
        sendInvitesToFetchedUsers(input: { orgId: $orgId, orgEntityId: $orgEntityId }) {
          users {
            ...User
          }
        }
      }
      ${userFragment}
    `,
    sendOnboardingInvites: adminDefs.users.sendOnboardingInvites,
    sendInvitesToUsers: gql`
      mutation sendInvitesToUsers($userIds: [ID]!) {
        sendInvitesToUsers(input: { userIds: $userIds }) {
          users {
            ...User
          }
        }
      }
      ${userFragment}
    `,
    addUsersToNova: gql`
      mutation addUsersToNova($users: [JSONObject]!, $orgId: ID!, $orgEntityId: ID, $impersonatedUserId: ID) {
        addUsersToNova(
          input: { users: $users, orgId: $orgId, orgEntityId: $orgEntityId, impersonatedUserId: $impersonatedUserId }
        ) {
          users {
            ...User
          }
        }
      }
      ${userFragment}
    `,
    mapUserAndDependentsToBenefits: gql`
      mutation mapUserAndDependentsToBenefits($userId: ID!) {
        mapUserAndDependentsToBenefits(input: { userId: $userId }) {
          success
        }
      }
    `,
  },
  userChanges: {
    ...adminDefs.userChanges,
    getClubbedUserChanges: gql`
      query getClubbedUserChanges($limit: Int, $offset: Int, $filter: JSONObject) {
        getClubbedUserChanges(offset: $offset, limit: $limit, filter: $filter) {
          edges {
            node {
              changes
            }
          }
          totalCount
          segregatedCount
          obPendingCount
        }
      }
    `,
    getClubbedUserChangesCount: gql`
      query getClubbedUserChangesCount($filter: JSONObject) {
        getClubbedUserChangesCount(filter: $filter) {
          countByBatchStage
        }
      }
    `,
    transformGetClubbedUserChanges(data) {
      const changes = data.getClubbedUserChanges?.edges?.map(({ node }) => ({
        ...node.changes,
      }));

      return {
        changes,
        totalCount: data.getClubbedUserChanges.totalCount,
        segregatedCount: data.getClubbedUserChanges.segregatedCount,
        obPendingCount: data.getClubbedUserChanges.obPendingCount,
      };
    },
    transformGetClubbedUserChangesCount(data) {
      return { countByBatchStage: data.getClubbedUserChangesCount?.countByBatchStage };
    },
    // FIXIT: Move to constants file
    popovers: {
      expired: {
        heading: "Your {policyType} policy is expired",
        title: "Please contact Nova’s support team for further assistance.",
      },
      add: {
        draft: {
          heading: "Approval Pending",
          title:
            " has been enabled for the employee but the endorsement request hasn’t been approved by the org admin.",
          subTitle: "Needs to be approved before ",
          description: "Employee may be eligible for a claim if there is enough CD balance with the insurer",
        },
        "org-ok": {
          heading: "Submission Pending",
          title:
            " has been enabled for the employee but the endorsement batch hasn’t been submitted to the insurer yet.",
          subTitle: "Will be submitted on ",
          description: "Employee may be eligible for a claim if there is enough CD balance with the insurer",
        },
        "nova-ok": {
          heading: "Insurer Approval Pending",
          title: " has been enabled for the employee and the addition request has been forwarded to the insurer.",
          subTitle: "Submitted on ",
          description: "Employee may be eligible for a claim if there is enough CD balance with the insurer",
        },
        "provider-ok": {
          heading: "Approved by Insurer",
          title: " has been approved by the insurer and is awaiting verification from internal team.",
          subTitle: "Submitted on ",
          description: "Employee may be eligible for a claim if there is enough CD balance with the insurer",
        },
        done: {
          heading: "Coverage Active",
          title: "Coverage is active and the details are up to date with the insurer",
        },
        archive: {
          heading: "Rejected",
          title: "",
        },
        rejected: {
          heading: "Rejected",
          title: " has been rejected. Please contact your HR for more information.",
        },
      },
      delete: {
        draft: {
          heading: "Approval Pending",
          title:
            " has been disabled for the employee but the endorsement request hasn’t been approved by the org admin.",
          subTitle: "Needs to be approved before ",
        },
        "org-ok": {
          heading: "Submission Pending",
          title:
            " has been disabled for the employee but the endorsement batch hasn’t been submitted to the insurer yet.",
          subTitle: "Will be submitted on ",
        },
        "nova-ok": {
          heading: "Insurer Approval Pending",
          title: " has been disabled for the employee and the deletion request has been forwarded to the insurer.",
          subTitle: "Submitted on ",
        },
        "provider-ok": {
          heading: "Approved by Insurer",
          title: " has been approved by the insurer and is awaiting verification from internal team.",
          subTitle: "Submitted on ",
          description: "Employee may be eligible for a claim if there is enough CD balance with the insurer",
        },
        done: {
          heading: "Coverage Active",
          title: "Coverage is disabled and the details are up to date with the insurer",
        },
        archive: {
          heading: "Rejected",
          title: "",
        },
        rejected: {
          heading: "Rejected",
          titile: " has been rejected for the employee, please reach out to your HR for more information.",
        },
      },
    },
    dependentPopovers: {
      add: {
        draft: {
          heading: "Approval Pending",
          title: " policy coverage for this dependent is under approval.",
        },
        "org-ok": {
          heading: "Submission Pending",
          title: " policy coverage for this dependent is under approval.",
        },
        "nova-ok": {
          heading: "Insurer Approval Pending",
          title: " policy coverage for this dependent is under approval.",
        },
        "provider-ok": {
          heading: "Approved by Insurer",
          title: " has been approved by the insurer and is awaiting verification from internal team.",
        },
        done: {
          heading: "Coverage Active",
          title: "Policy coverage has been approved and enabled for the dependent.",
        },
        archive: {
          heading: "Rejected",
          title: " policy coverage for this dependent has been rejected. Please contact your HR for more information.",
        },
        rejected: {
          heading: "Rejected",
          title: " policy coverage for this dependent has been rejected. Please contact your HR for more information.",
        },
      },
    },
  },
  orgEntity: {
    gqlType: "OrgEntity",
    upsertMutation: adminDefs.orgEntities.upsertMutation,
    transform: ({ node }) => node.orgEntity,
    singleQuery: adminDefs.orgEntities.singleQuery,
    listQuery: adminDefs.orgEntities.listQuery,
    fields: [
      {
        title: "Brand Name",
        subtitle: `Your employees will see this name on all the email communication
          we’ll share with them during onboarding.<br>Ex - NovaX`,
        icon: "star-curved-4",
        color: "#826BE5",
        schema: {
          fields: [
            {
              model: "meta",
              type: "object",
              schema: {
                fields: [
                  {
                    model: "displayName",
                    type: "inline-input",
                    inputType: "text",
                    placeholder: "Display Name",
                  },
                ],
              },
            },
          ],
        },
      },
      {
        title: "Organization Name",
        subtitle: `This legal name will be utilised in invoicing, agreements,
          and other legal documents shared between Nova Benefits and your organisation.<br>
          Ex - NovaX Insurance Brokers Pvt Ltd`,
        icon: "office",
        color: "#39aba3",
        schema: {
          fields: [
            {
              model: "name",
              type: "inline-input",
              placeholder: "Org Name",
              validator: "string",
              disabled: true,
              required: true,
            },
          ],
        },
      },
      {
        title: "Office Address",
        subtitle: `This address will be used as the billing address and sometimes
          as a mailing address for sending documents if the need arises`,
        icon: "location",
        color: "#DDAA33",
        schema: {
          fields: [
            {
              model: "address",
              type: "text-area",
              inputType: "text",
              rows: 4,
              placeholder: "Enter your office address here",
              validator: "string",
              required: true,
              fieldClasses: ["input-wrapper"],
            },
            {
              model: "pincode",
              type: "inline-input",
              inputType: "text",
              placeholder: "Area Pin",
              validator: "regexp",
              pattern: "^[1-9]{1}[0-9]{2}\\s{0,1}[0-9]{3}$",
              required: true,
            },
          ],
        },
      },
      {
        title: "GSTIN",
        subtitle: "This will be used while generating invoices during monthly endorsement cycles",
        icon: "hashtag",
        color: "#826BE5",
        schema: {
          fields: [
            {
              model: "meta",
              type: "object",
              schema: {
                fields: [
                  {
                    model: "gstin",
                    type: "inline-input",
                    inputType: "text",
                    placeholder: "Enter your GSTIN here",
                  },
                  {
                    model: "bank_acc",
                    type: "inline-input",
                    inputType: "text",
                    placeholder: "Bank Account Number",
                  },
                  {
                    model: "bank_ifsc",
                    type: "inline-input",
                    inputType: "text",
                    placeholder: "Bank IFSC Code",
                  },
                  {
                    model: "bank_bene",
                    type: "inline-input",
                    inputType: "text",
                    placeholder: "Beneficiary Name",
                  },
                ],
              },
            },
          ],
        },
      },
      {
        title: "Primary Contact Person",
        subtitle: `All communications related to insurance and benefits will be relayed to this person.
          We don't usually call unless it's absolutely necessary`,
        icon: "user-circle",
        color: "#60C0E2",
        schema: {
          fields: [
            {
              model: "meta",
              type: "object",
              schema: {
                fields: [
                  {
                    model: "contact_person",
                    type: "inline-input",
                    inputType: "text",
                    placeholder: "Contact Person's Name",
                  },
                  {
                    model: "contact_number",
                    type: "inline-input",
                    inputType: "tel",
                    placeholder: "Phone Number",
                  },
                ],
              },
            },
          ],
        },
      },
    ],
  },
  org: {
    gqlType: "Org",
    listQuery: adminDefs.orgs.listQuery,
    fields: [
      {
        title: "Brand Name",
        subtitle: `Your employees will see this name on all the email communication
          we’ll share with them during onboarding.<br>Ex - NovaX`,
        icon: "star-curved-4",
        color: "#826BE5",
        schema: {
          fields: [
            {
              model: "meta",
              type: "object",
              schema: {
                fields: [
                  {
                    model: "displayName",
                    type: "inline-input",
                    inputType: "text",
                    placeholder: "Display Name",
                  },
                ],
              },
            },
          ],
        },
      },
      {
        title: "Organization Name",
        subtitle: `This legal name will be utilised in invoicing, agreements,
          and other legal documents shared between Nova Benefits and your organisation.<br>
          Ex - NovaX Insurance Brokers Pvt Ltd`,
        icon: "office",
        color: "#39aba3",
        schema: {
          fields: [
            {
              model: "name",
              type: "inline-input",
              placeholder: "Org Name",
              validator: "string",
              disabled: true,
              required: true,
            },
          ],
        },
      },
      {
        title: "Office Address",
        subtitle: `This address will be used as the billing address and sometimes
          as a mailing address for sending documents if the need arises`,
        icon: "location",
        color: "#DDAA33",
        schema: {
          fields: [
            {
              model: "address",
              type: "text-area",
              inputType: "text",
              rows: 4,
              placeholder: "Enter your office address here",
              validator: "string",
              required: true,
              fieldClasses: ["input-wrapper"],
            },
            {
              model: "pincode",
              type: "inline-input",
              inputType: "text",
              placeholder: "Area Pin",
              validator: "regexp",
              pattern: "^[1-9]{1}[0-9]{2}\\s{0,1}[0-9]{3}$",
              required: true,
            },
          ],
        },
      },
      {
        title: "GSTIN",
        subtitle: "This will be used while generating invoices during monthly endorsement cycles",
        icon: "hashtag",
        color: "#826BE5",
        schema: {
          fields: [
            {
              model: "meta",
              type: "object",
              schema: {
                fields: [
                  {
                    model: "gstin",
                    type: "inline-input",
                    inputType: "text",
                    placeholder: "Enter your GSTIN here",
                  },
                  {
                    model: "bank_acc",
                    type: "inline-input",
                    inputType: "text",
                    placeholder: "Bank Account Number",
                  },
                  {
                    model: "bank_ifsc",
                    type: "inline-input",
                    inputType: "text",
                    placeholder: "Bank IFSC Code",
                  },
                  {
                    model: "bank_bene",
                    type: "inline-input",
                    inputType: "text",
                    placeholder: "Beneficiary Name",
                  },
                ],
              },
            },
          ],
        },
      },
      {
        title: "Primary Contact Person",
        subtitle: `All communications related to insurance and benefits will be relayed to this person.
          We don't usually call unless it's absolutely necessary`,
        icon: "user-circle",
        color: "#60C0E2",
        schema: {
          fields: [
            {
              model: "meta",
              type: "object",
              schema: {
                fields: [
                  {
                    model: "contact_person",
                    type: "inline-input",
                    inputType: "text",
                    placeholder: "Contact Person's Name",
                  },
                  {
                    model: "contact_number",
                    type: "inline-input",
                    inputType: "tel",
                    placeholder: "Phone Number",
                  },
                ],
              },
            },
          ],
        },
      },
    ],
    schema: adminDefs.orgs.schema,
    upsertMutation: adminDefs.orgs.upsertMutation,
    singleQuery: adminDefs.orgs.singleQuery,
    transform: ({ node }) => node,
  },
  benefits: {
    gqlType: "Benefit",
    fields: ["name", "type"],
    schema: adminDefs.benefits.schema,
    listQuery: adminDefs.benefits.listQuery,
    getEdgesFromData: (data) => data.benefits.edges,
    getTotalNumberOfPolicies: adminDefs.benefits.getTotalNumberOfPolicies,
    getTotalNumberOfBenefits: adminDefs.benefits.getTotalNumberOfBenefits,
    getTotalCountFromData: (data) => data.benefits.totalCount,
    transform: (n) => {
      n = n.node;
      return {
        ...n,
      };
    },
  },
  claims: {
    gqlType: "Claim",
    fields: [
      { key: "user.name", label: "User Name", class: rowStyling },
      { key: "dependent.relation", label: "Patient", class: rowStyling },
      { key: "type", label: "Claim Type", class: rowStyling },
      { key: "createdAt", label: "Claim Initiated", class: rowStyling },
      { key: "status", label: "Status", class: rowStyling },
      { key: "updatedAt", label: "Last Updated", class: rowStyling },
      { key: "meta.claimAmount", label: "Claim Amount", class: rowStyling },
      { label: "Actions", key: "actions", class: rowStyling },
    ],
    filterSchema: {
      fields: [
        {
          model: "policy",
          type: "id-selector",
          queryName: "benefits",
          gqlQuery: getIdSelectorQuery("Benefit"),
          label: "Policy",
          placeholder: "None selected",
          required: true,
        },
      ],
    },
    listQuery: gql`
      query ClaimList($query: String, $offset: Int, $limit: Int, $filter: JSONObject) {
        claims(query: $query, offset: $offset, limit: $limit, filter: $filter) {
          edges {
            node {
              ...Claim
            }
          }
          totalCount
        }
      }
      ${claimOrgAdminFragment}
    `,
    singleQuery: adminDefs.claims.singleQuery,
    schema: adminDefs.claims.schema,
    transform: (n) => {
      n = n.node;
      return {
        ...n,
        userId: n.user.id,
        userName: n.user.name,
        orgId: n.user.org.id,
        policyId: n.policy.id,
        policyType: n.policy.type,
        policyName: n.policy.name,
      };
    },
    getTotalCountFromData: (data) => data.claims.totalCount,
    getEdgesFromData: (data) => data.claims.edges,
  },
  integrations: {
    gqlType: "Integration",
    fields: [],
    listQuery: gql`
      query IntegrationsList($orgId: ID!) {
        integrations(orgId: $orgId) {
          edges {
            node {
              ...Integration
            }
          }
        }
      }
      ${integrationFragment}
    `,
    singleQuery: adminDefs.integrations.singleQuery,
    schema: ((_schema) => {
      const schema = misc.deepClone(_schema);
      schema.fields.find((f) => f.model === "org").visible = false;
      schema.fields.find((f) => f.model === "integrationType").visible = false;
      return schema;
    })(adminDefs.integrations.schema),
    upsertMutation: adminDefs.integrations.upsertMutation,
    authorizeGsuiteIntegration: adminDefs.integrations.authorizeGsuiteIntegration,
    fetchHRMSDataMutation: adminDefs.integrations.fetchHRMSDataMutation,
    unauthorizeIntegration: adminDefs.integrations.unauthorizeIntegration,
    transform: ({ node }) => node.integrations,
    getLatestSyncDetails: gql`
      query getLatestSyncDetails($orgId: ID!, $integrationType: IntegrationWithEnum!) {
        getLatestSyncDetails(orgId: $orgId, integrationType: $integrationType) {
          integrationId
          syncStatus
          activityLogs
          syncDuration
          invokedBy {
            name
          }
          startedAt
          completedAt
        }
      }
    `,
  },
  stagedUsers: {
    gqlType: "StagedUser",
    fields: [
      { label: "Emp ID", key: "employeeId" },
      { label: "Work Email", key: "email" },
      { label: "Emp Name", key: "name" },
      { label: "Gender", key: "gender" },
      { label: "Date of Birth", key: "dob" },
      { label: "Relation", key: "relation" },
      { label: "Job Grade", key: "grade" },
      { label: "Joining Date", key: "doj" },
      { label: "Contact", key: "contact" },
      { label: "Exit Date", key: "doe" },
      { label: "Date of Marriage", key: "dom" },
      { label: "GMC_SI", key: "gmcSi" },
      { label: "GPA_SI", key: "gpaSi" },
      { label: "GTL_SI", key: "gtlSi" },
    ],
    listQuery: gql`
      query StagedUsers($query: String, $offset: Int, $limit: Int, $filter: JSONObject) {
        stagedUsers(query: $query, offset: $offset, limit: $limit, filter: $filter) {
          totalCount
          actualCount
          edges {
            node {
              ...StagedUser
            }
            cursor
          }
        }
      }
      ${stagedUserFragment}
    `,
    upsertMutation: gql`
      mutation upsertStagedUsers($file: Upload, $orgId: ID!, $orgEntityId: ID, $meta: JSONObject) {
        upsertStagedUsers(input: { file: $file, orgId: $orgId, orgEntityId: $orgEntityId, meta: $meta }) {
          stagedUsers {
            ...StagedUser
          }
          totalCount
          actualCount
        }
      }
      ${stagedUserFragment}
    `,
    deleteMutation: gql`
      mutation deleteStagedUser($employeeId: ID!, $orgId: ID!, $orgEntityId: ID, $meta: JSONObject) {
        deleteStagedUser(input: { employeeId: $employeeId, orgId: $orgId, orgEntityId: $orgEntityId, meta: $meta }) {
          stagedUsers {
            ...StagedUser
          }
          totalCount
          actualCount
        }
      }
      ${stagedUserFragment}
    `,
    discardMutation: gql`
      mutation discardBulkUpload($orgId: ID!, $orgEntityId: ID) {
        discardBulkUpload(input: { orgId: $orgId, orgEntityId: $orgEntityId }) {
          status
        }
      }
    `,
    bulkUploadMutation: gql`
      mutation BulkUpload($orgId: ID!, $orgEntityId: ID) {
        bulkUpload(input: { orgId: $orgId, orgEntityId: $orgEntityId }) {
          job {
            ...Job
          }
        }
      }
      ${jobFragment}
    `,
    exportMutation: gql`
      mutation ExportStagedUsers($orgId: ID!, $orgEntityId: ID) {
        exportStagedUsers(input: { orgId: $orgId, orgEntityId: $orgEntityId }) {
          data {
            exportFile
          }
        }
      }
    `,
    transform: (result) =>
      result.data.stagedUsers.edges.map((edge) => {
        delete edge.node.__typename;
        delete edge.node.org?.__typename;
        return edge.node;
      }),
  },
  bloodGroups: ["A+ve", "A-ve", "B+ve", "B-ve", "O+ve", "O-ve", "AB+ve", "AB-ve"],
  files: {
    gqlType: "File",
    fields: [
      { label: "File Name", key: "name" },
      { label: "Type", key: "fileGroupNames" },
      { label: "Created At", key: "createdAt" },
      { label: "Actions", key: "actions" },
    ],
    listQuery: adminDefs.files.listQuery,
    // TODO: Add date-picker filter
    filterSchema: {
      fields: [
        {
          model: "dates",
          type: "date-range",
          label: "Creation Date",
          min: "1900-01-01",
          max: "2100-12-31",
          value: null,
          required: false,
          validator: [validateDateRange],
        },
        {
          model: "fileGroups",
          type: "multi-select-dropdown",
          queryName: "fileGroups",
          gqlQuery: getListQuery("FileGroup"),
          nodesFromData: utils.getOptionsMapperForFileGroups("fileGroups"),
          label: `Select file groups<span style="color: red">*</span>`,
          required: true,
          multiple: true,
          showSelected: false,
        },
      ],
    },
    upsertFile: adminDefs.files.upsertMutation,
    upsertOrgGroupFileEdges: adminDefs.files.upsertOrgGroupFileEdges,
    deleteOrgGroupFileEdges: adminDefs.files.deleteOrgGroupFileEdges,
    getTotalCountFromData: (data) => data.files.totalCount,
    getEdgesFromData: (data) => data.files.edges,
    transform: (n) => {
      n = n.node;
      return {
        ...n,
        fileGroupNames: n.fileGroups.map(({ node }) => node.name).sort(),
      };
    },
  },
  orgOnboardingStageTasks: {
    gqlType: "OrgOnboardingStageTask",
    listQuery: adminDefs.orgOnboardingStageTasks.listQuery,
  },
  metabase: {
    generateMetabaseIframeUrl: gql`
      mutation generateMetabaseIframeUrl($dashboardId: Int, $analyticsType: String) {
        generateMetabaseIframeUrl(input: { dashboardId: $dashboardId, analyticsType: $analyticsType }) {
          iframeUrl
        }
      }
    `,
    metabaseCollections: gql`
      query metabaseCollections($filter: JSONObject) {
        metabaseCollections(filter: $filter)
      }
    `,
  },
  locations: {
    addressSuggestions: gql`
      query addressSuggestions($query: String) {
        addressSuggestions(query: $query)
      }
    `,
    getCoordinates: gql`
      query getCoordinates($placeId: String) {
        getCoordinates(placeId: $placeId)
      }
    `,
    getAddressFromCoordinates: gql`
      query getAddressFromCoordinates($latitude: Float, $longitude: Float) {
        getAddressFromCoordinates(latitude: $latitude, longitude: $longitude)
      }
    `,
  },
  sendEcardMail: gql`
    mutation sendEcardMail($id: ID!) {
      sendEcardMail(input: { id: $id }) {
        success
      }
    }
  `,
  echoPostTemplates: {
    ...adminDefs.echoPostTemplates,
    fields: [
      "category",
      "subject",
      { label: "Last Updated By", key: "lastUpdatedBy" },
      { label: "Actions", key: "actions" },
    ],
    schema: {
      fields: adminDefs.echoPostTemplates.schema.fields.filter((field) => field.model !== "org"),
    },
    getTotalCountFromData: (data) => data.echoPostTemplates.totalCount,
    getEdgesFromData: (data) => data.echoPostTemplates.edges.map((n) => n.node),
    transform: (n) => {
      return {
        ...n,
        orgId: n.org?.id,
        lastUpdatedBy: n.updatedBy?.name,
        imageFileId: n.image?.fileId,
        image: {
          ...(n.image || {}),
          fileId: n.image?.id,
          fileUrl: n.image?.url,
        },
        logoFileId: n.logo?.fileId,
        logo: {
          ...(n.logo || {}),
          fileId: n.logo?.id,
          fileUrl: n.logo?.url,
        },
      };
    },
  },
};
