import { getListQuery, getSingleQuery, getResourceListQuery } from "../queries";
import { claimStatusList } from "../constants";
import claimsSchema from "../schemas/claims";
import claimsMutations from "../mutations/claims";

export default {
  gqlType: "Claim",
  fields: [
    { key: "user.name", label: "User Name" },
    { key: "dependent.relation", label: "Patient" },
    { key: "type", label: "Claim Type" },
    { key: "createdAt", label: "Claim Initiated" },
    "status",
    { key: "updatedAt", label: "Last Updated" },
    { key: "meta.claimAmount", label: "Claim Amount" },
  ],
  resourceListQuery: getResourceListQuery("Claim"),
  listQuery: getResourceListQuery("Claim"),
  singleQuery: getSingleQuery("Claim"),
  statusList: claimStatusList,
  ...claimsSchema,
  ...claimsMutations,
  transform: (n) => {
    if (n.hasOwnProperty("patient")) {
      n.dependentId = n.patient.id === n.user.id ? null : n.patient.id;
    } else {
      n.patient = n.dependent ? n.dependent : n.user;
    }
    return {
      ...n,
      org: n.user.org,
      user: n.user,
      userId: n.user.id,
      type: n.type,
      userName: n.user.name,
      orgName: n.user.org.name,
      orgId: n.user.org.id,
      policyId: n.policy.id,
      policyType: n.policy.type,
      policyName: n.policy.name,
    };
  },
  transformUpsertMutationVariables: (n) => {
    if (n.hasOwnProperty("patient")) {
      n.dependentId = n.patient.id === n.user.id ? null : n.patient.id;
    } else {
      n.patient = n.dependent ? n.dependent : n.user;
    }
    const { id, userId, policyId, dependentId, meta, status, type } = { ...n };

    return {
      id,
      userId,
      policyId,
      dependentId,
      meta,
      status,
      type,
    };
  },
};
