<template lang="pug">
b-modal(
  :id="`add-members-modal`"
  centered,
  hide-footer,
  title="Add Members")
  template(slot="modal-header")
    .d-flex.align-items-center
      img.image-border.mr-3.border-rounded(
        :src="require('@/assets/images/user.svg')")
      div.font-lg.font-weight-semibold Add Member
  b-form(@submit.prevent="addMember")
      vue-form-generator(
        :schema="schema",
        :options="formOptions",
        ref="formData",
        :model="model")
      n-alert.mb-3(alertMessage="This member will only be added to your current health check-up booking and not to your insurance policy.", variant="warning-2", :hideDismissButton="true")
      n-button.w-100(buttonText="Add member", variant="primary", imageIcon="plus-circle", type="submit")
</template>

<script>
import { mapGetters } from "vuex";
import { nanoid } from "nanoid";
import addMemberSchema from "../schema/addMember";
import { getAge } from "@/utils/date";
import NButton from "@/components/NovaButton.vue";
import NAlert from "@/components/NovaAlertBanner.vue";
import { validateMemberAgeAhc } from "@/utils/validators";
export default {
  name: "AddMembersModal",
  components: { NButton, NAlert },
  props: {
    packageAvailableFor: { type: Object, default: null },
    removeRelations: { type: Array, default: null },
  },
  data() {
    return {
      schema: addMemberSchema.schema,
      model: {},
      formOptions: {
        validateAfterLoad: false,
        validateAfterChanged: true,
        validateAsync: true,
      },
    };
  },
  computed: {
    ...mapGetters(["ahcProspectState", "isCheckupAdmin"]),
  },
  watch: {
    "model.dob": function (dob) {
      this.$set(this.model, "age", getAge(dob));
    },
  },
  created() {
    const relationField = this.schema.fields.find((field) => field.model === "relation");
    relationField.options = relationField.options.filter((option) => !this.removeRelations.includes(option.value));
    const dobField = this.schema.fields.find((field) => field.model === "dob");
    dobField.validator = [this.validateAge];
    if (!(this.packageAvailableFor?.male && this.packageAvailableFor?.female)) {
      const genderField = this.schema.fields.find((field) => field.model === "gender");
      if (this.packageAvailableFor?.female) {
        genderField.cardsData = [
          {
            name: "gender",
            icon: "female",
            label: "Female",
            card_value: "female",
          },
        ];
      } else {
        genderField.cardsData = [
          {
            name: "gender",
            icon: "male",
            label: "Male",
            card_value: "male",
          },
        ];
      }
    }
  },
  methods: {
    validateAge(dob) {
      return validateMemberAgeAhc(dob, Number(this.packageAvailableFor.minAge), this.packageAvailableFor.maxAge);
    },
    addMember() {
      if (!this.ahcProspectState.extraMembers) {
        this.ahcProspectState.extraMembers = [];
      }
      // nano Id has underscore and hypens 
      this.ahcProspectState.extraMembers.push({ ...this.model, id: nanoid() });
      this.model = {};
      this.$bvModal.hide("add-members-modal");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
@import "@/assets/styles/mixins/_breakpoints.scss";
.image-border {
  width: 31px;
  height: 31px;
  border: 2px solid $blue-300;
  border-radius: 50%;
}
</style>
