import { claimDocumentsList } from "../../constants";
export default {
  upload: {
    schema: {
      fields: [
        {
          model: "files",
          type: "files-uploader",
          modal: true,
          styleClasses: "float-right mr-4 mb-1",
          options: claimDocumentsList,
          name: "Upload Documents",
          icon: "cloud-upload",
          variant: "outline-dark",
          productType: "claims",
        },
      ],
    },
  },
  uploaded: {
    schema: {
      fields: [
        {
          model: "files",
          type: "files-uploader",
          modal: true,
          styleClasses: "float-right mr-4 mb-1",
          options: claimDocumentsList,
          name: "View Uploaded Documents",
          icon: "eye",
          productType: "claims",
        },
      ],
    },
  },
};
