<template lang="pug">
.checkup-dependents-selection
  template(v-if="membersWithEligibleCheckupPackage?.length")
    .d-none.d-md-flex.align-items-center.mb-3
      n-icon(name="add-user", :size="1")
      .text-gray-900.font-lg.font-weight-semibold.ml-2 Add Members for Health Checkup Booking
    .d-flex.align-items-center.border.border-gray-200.shadow-sm.user-card.mb-3(
      v-for="member of membersWithEligibleCheckupPackage",
      :class="{ 'selected': Boolean(selectedMemberIds[member.id]), 'bg-gray-100': member.isMemberDisabledToBook, 'cursor-pointer': !member.isMemberDisabledToBook }",
      @click.prevent="updateSelectedMembers(member.id, member.isMemberDisabledToBook)")
      .d-flex.align-items-center.flex-grow-1
        b-form-checkbox.mr-1.checkup-checkbox.cursor-pointer(:id="'member-' + member.id + '-checkbox'",
          :disabled="member.isMemberDisabledToBook"
          :checked="selectedMemberIds[member.id]",
          :class="{ 'cursor-pointer': !member.isMemberDisabledToBook }")
        .d-flex.flex-column.ml-1
          .text-gray-900.font-weight-semibold.font-lg.text-capitalize.mb-1 {{ truncateName(member.name) }}
          .text-gray-700.font-weight-medium.font-sm {{ member.formattedRelation }} • {{ member.gender }} • {{ member.age }} Years
      template(v-if="!(member.isMemberDisabledToBook && isSinglePaymentLinearPackageBooking(member.checkupPackage))")
        .d-flex.flex-column.align-items-end(v-if="member.isPaidAppointment")
          .text-gray-700.font-xs.font-weight-medium.strikethrough(v-if="member.checkupPackage.meta.maximumRetailPrice") {{ formatAmountWithRupee(member.checkupPackage.meta.maximumRetailPrice) }}
          .text-gray-900.font-md.font-weight-semibold {{ formatAmountWithRupee(member.checkupPackage.novaProduct.netCost) }}
        span.text-teal-800.font-sm.font-weight-semibold(v-else-if="isSinglePaymentLinearPackageBooking(member.checkupPackage) && !member.isPaidAppointment")
          span Payment
          br.d-block.d-md-none
          span.d-none.d-md-inline-block &nbsp;
          span received
        .text-teal-800.font-md.font-weight-semibold(v-else) Free
    .add-extra-members.d-flex.align-items-center.text-blue-500.cursor-pointer.mt-1(v-if="!isDisabled", @click="showAddMemberModal")
      n-icon.mr-1(name="plus")
      span.font-lg.font-weight-semibold Add a member
    .d-flex.align-items-center.mt-3.border.border-mustard-200.bg-mustard-100.rounded-8.p-2(v-if="isLinearPackagePending && linearPackageName")
      n-icon.mr-2(name="info", :size="0.8", :pathCount="2", variant="mustard-700")
      .text-gray-900.font-sm.font-weight-medium You will be able to book this package after reports of {{ linearPackageName }} are available.
    checkup-footer.d-block(variant="success",
      buttonText="Confirm Selection",
      :isButtonDisabled="isConfirmButtonDisabled",
      @goBack="goBack",
      @continueCheckupFlow="goToNextStep")
    //- add-members-modal(v-if="selectedGroupPackage.packageAvailableFor", :packageAvailableFor="selectedGroupPackage.packageAvailableFor", :removeRelations="removeRelations")
</template>

<script>
import { mapState, mapGetters } from "vuex";
import * as checkupUtils from "../checkupUtils";
import { formatAmountWithRupee } from "../../../../utils/misc";
import CheckupFooter from "./CheckupFooter.vue";
import AddMembersModal from "./AddMembersModal.vue";
import NButton from "@/components/NovaButton.vue";
import NCheckboxInput from "@/components/NovaCheckbox.vue";

export default {
  name: "CheckupDependentsSelection",
  components: {
    NButton,
    NCheckboxInput,
    CheckupFooter,
    AddMembersModal,
  },
  props: {},
  data() {
    return {
      isDisabled: true,
      selectedMemberIds: {},
    };
  },
  computed: {
    ...mapState(["user"]),
    ...mapGetters(["ahcProspectState", "isCheckupAdmin"]),
    removeRelations() {
      const relations = [];
      const relationCount = {};
      this.membersWithEligibleCheckupPackage.forEach((member) => {
        if (member.relation === "spouse") {
          relations.push(member.relation);
        } else if (member.relation === "parent" || member.relation === "parent-in-law") {
          if (!relationCount[member.relation]) {
            relationCount[member.relation] = 1;
          } else {
            relations.push(member.relation);
          }
        }
      });

      return relations;
    },
    bookingUser() {
      if (this.isCheckupAdmin) return this.$route.params?.bookingUser;
      return this.user;
    },
    selectedGroupPackage() {
      return this.ahcProspectState.selectedGroupPackage || {};
    },
    selectedMembers() {
      return this.ahcProspectState.selectedMembers || [];
    },
    isConfirmButtonDisabled() {
      return !this.selectedMembers.length;
    },
    isLinearPackagePending() {
      return this.membersWithEligibleCheckupPackage.some(({ isMemberDisabledToBook }) => isMemberDisabledToBook);
    },
    linearPackageName() {
      const { linearPackage = {} } = this.selectedGroupPackage?.checkupPackages[0] || {};
      return linearPackage?.groupName || linearPackage?.name;
    },
    membersWithEligibleCheckupPackage() {
      return (
        checkupUtils.getUsersWithEligibleCheckupPackage(
          this.bookingUser,
          this.ahcProspectState.extraMembers,
          this.selectedGroupPackage
        ) || []
      );
    },
  },
  mounted() {
    this.selectedMembers?.forEach(({ id }) => this.$set(this.selectedMemberIds, id, true));
  },
  methods: {
    showAddMemberModal() {
      this.$bvModal.show("add-members-modal");
    },
    formatAmountWithRupee,
    truncateName(name) {
      if (name.length > 16 && window.innerWidth < 500) {
        return name.slice(0, 16 - 2) + "...";
      }
      return name;
    },
    updateSelectedMembers(memberId, isMemberDisabledToBook) {
      if (isMemberDisabledToBook) return;

      this.$set(this.selectedMemberIds, memberId, !this.selectedMemberIds[memberId]);
      const selectedMembers = this.membersWithEligibleCheckupPackage.filter(({ id }) => this.selectedMemberIds[id]);
      this.$store.commit("updateAhcProspectStateMeta", { selectedMembers });
    },
    goToNextStep() {
      this.$emit("goToNextStep");
    },
    goBack() {
      this.$emit("backHandler");
    },
    isSinglePaymentLinearPackageBooking({ meta } = {}) {
      return Boolean(meta?.isSinglePaymentLinearPackage);
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/_variables.scss";
.checkup-dependents-selection {
  .checkup-checkbox > input[type="checkbox"] {
    min-height: 1rem !important;
    cursor: pointer;
  }
  .user-card {
    padding: 1rem 1.25rem !important;
    border-radius: 0.5rem !important;
    &.selected {
      background-color: $teal-100 !important;
      border-color: $teal-700 !important;
    }
  }
  .custom-control-label {
    cursor: pointer;
  }
  .custom-checkbox .custom-control-label::before {
    border-width: 1px !important;
    border-radius: 0.25rem !important;
    border-color: #e2e8f0 !important;
    width: 1rem !important;
    height: 1rem !important;
  }
  .custom-control-input:checked ~ .custom-control-label::before {
    border-color: $teal-700 !important;
  }
}
</style>
