<template lang="pug">
.cookie-wrapper.d-flex.flex-column.w-100.rounded-2.mb-3.py-2.px-3
  .d-flex.justify-content-between.w-100.mb-2.mt-1.text-nowrap
    .label.font-weight-semibold {{ label }}
    toggle-button(v-model="localIsAccepted", :disabled="isToggleDisabled")
  .description.d-flex.text-wrap {{  description }}
  .error.text-danger.mt-1.font-weight-medium(v-if="isRequired && !localIsAccepted")
    | {{ errorMessage }}
</template>

<script>
export default {
  name: "CookieCard",
  props: {
    label: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    isAccepted: {
      type: Boolean,
      default: false,
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: String,
      default: "You must accept these cookie to continue.",
    },
    isToggleDisabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    localIsAccepted: {
      get() {
        return this.isAccepted;
      },
      set(newValue) {
        this.$emit("update:isAccepted", newValue);
      },
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/_variables.scss";
@import "@/assets/styles/_typography.scss";

.vue-js-switch.toggled {
  .v-switch-core {
    width: 1rem;
    background-color: rgba(57, 171, 163, 1) !important;
    .v-switch-button {
      transform: translate3d(1.4rem, 2.5px, 0px) !important;
    }
  }
}
.vue-js-switch {
  .v-switch-core {
    width: 2.5rem !important;
  }
}
</style>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
@import "@/assets/styles/_typography.scss";

.cookie-wrapper {
  border: 1px solid $gray-300;
}
.label {
  font-size: 1rem;
}
.text-danger {
  color: $red-500 !important;
}
</style>
