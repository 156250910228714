<template lang="pug">
  n-overlay
    router-view(:key="$route.path")
</template>

<script>
import NOverlay from "@/components/Overlay.vue";
export default {
  components: {
    NOverlay,
  },
};
</script>

<style lang="scss">
@media (min-width: 576px) {
  .super-topup-modal-dialog {
    max-width: 525px;
  }
}
</style>
