<template lang="pug">
n-overlay(v-if="$store.state.majorVersionMismatch && isSwLoading")
  div
    .nav-bar.bg-stone
      .container.d-flex.justify-content-between.border-bottom.h-100.align-items-center
        b-navbar-brand
          img.main-logo-style.my-3.text-tiber(:src="logoUrl",alt="nova main logo",to="/")
        b-skeleton(width="3rem", height="3rem")
    .hero-section.bg-stone.pt-5
      .container.text-center.text-md-left
        .mb-md-3
          n-icon.text-mustard-400(name="star-curved-4")
        .hb1.text-tiber.mb-2 Welcome {{userDisplayName}}
        .font-weight-medium.text-tiber.font-lg {{subText}}
    .container
      .quick-action-cards
        .row
          .col-6.col-md-3.mb-.pl-2.pl-md-0.pr-0
            .bg-white.mr-2
              b-skeleton.card-style
          .col-6.col-md-3.mb-3.p-0
            .bg-white.mr-2
              b-skeleton.card-style
          .col-6.col-md-3.pl-2.pl-md-0.pr-0
            .bg-white.mr-2
              b-skeleton.card-style
          .col-6.col-md-3.p-0
            .bg-white.mr-2
              b-skeleton.card-style
      .d-none.d-md-block.mb-4(v-for="index in 2" :key="index" )
        .row
          .col-8
            .bg-stone.rounded.px-4.py-3
              .row
                .col-4
                  b-skeleton-img.img-style.mr-0.w-100
                .col-8
                  .d-flex
                    b-skeleton.text-style.w-25.mr-2
                    b-skeleton.text-style.w-25
                  b-skeleton.text-style.w-100
                  b-skeleton.text-style.w-100
                  b-skeleton.text-style.w-100
          .col-4.bg-stone.rounded
            .row.py-3.px-4
              .col-2.pl-0
                b-skeleton.avatar-style(type="avatar")
              .col-10
                b-skeleton.w-100.h-100
            .row.py-3.px-4
              .col-2.pl-0
                b-skeleton.avatar-style(type="avatar")
              .col-10
                b-skeleton.w-100.h-100
      .d-md-none.d-block.bg-stone.p-2.rounded
        .mb-2
          b-skeleton-img.w-100.mb-4.rounded
        template
          b-skeleton.text-style.w-50
          b-skeleton.w-75
          b-skeleton.w-75
</template>

<script>
import { mapGetters } from "vuex";
import NOverlay from "../../../components/Overlay.vue";
export default {
  components: {
    NOverlay,
  },
  data() {
    return {
      subText: "Sit back, relax, and prepare for full coverage",
    };
  },

  computed: {
    ...mapGetters(["isLoggedIn", "isSwLoading"]),
    userDisplayName() {
      return this.$store.state.user.displayName;
    },
    logoUrl() {
      return require("@/assets/images/logo-main-tiber.svg");
    },
  },
  async mounted() {
    this.interval = setInterval(this.deleteBundleCacheWithoutReload, 10000);
    console.log("Triggering skip waiting every 10 sec");
    await this.oneSecDelay();
    this.subText = "Your updated health benefits are loading in 3...";
    await this.oneSecDelay();
    this.subText = "Your updated health benefits are loading in 3..2..";
    await this.oneSecDelay();
    this.subText = "Your updated health benefits are loading in 3..2..1";
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  methods: {
    async deleteBundleCacheWithoutReload() {
      try {
        window.posthog.capture("skip_waiting_triggered");
        const registration = await navigator?.serviceWorker?.getRegistration();
        if (this.$store.state.majorVersionMismatch) {
          if (registration?.waiting) {
            registration.waiting.postMessage({ type: "SKIP_WAITING" });
          }
        } else {
          clearInterval(this.interval);
        }
      } catch (err) {
        console.error(err);
      }
    },
    async oneSecDelay() {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve();
        }, 1000);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/mixins/_breakpoints.scss";
.hero-section {
  height: 16rem;
}
.nav-bar {
  height: 4.75rem;
}
.card-style {
  height: 5.75rem;
}
.quick-action-cards {
  position: relative;
  top: -3rem;
}
.img-style {
  height: 12.75rem;
}
.text-style {
  height: 2rem;
}
.avatar-style {
  height: 2.75rem;
  width: 2.75rem;
}
.main-logo-style {
  height: 2rem;
}
@include media-breakpoint-down(sm) {
  .card-style {
    height: 7rem;
  }
}
</style>
