<template lang="pug">
.row.no-gutters
  template(v-if="sortedDependents.length > 0")
    .col-md-6.mb-2.p-0(v-for="(dependent, index) in sortedDependents",:key="index")
      dependent-details-card(:cardData="dependent", @clicked="showData(dependent)")
</template>

<script>
import { mapGetters } from "vuex";
import { AcceptedRelations } from "../../../../../common/enums";
import DependentDetailsCard from "./DependentDetailsCard.vue";
import utils from "@/utils";

export default {
  name: "DependentDetailsWrapper",
  components: {
    DependentDetailsCard,
  },
  props: {
    dependentsModel: {
      type: Array,
      default: () => [],
    },
    policies: {
      type: Array,
      default: () => [],
    },
    coverPOrPil: {
      type: String,
      default: () => "",
    },
    shouldSortDependents: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    const depPolicyDetail = {};
    this.policies.map((policy) => {
      const depObj = policy.getCoveredDependents;
      depPolicyDetail[policy.type] = depObj;
    });
    return {
      depPolicyDetail,
      dependentsData: new Set(),
      totalDepCount: {},
      familyDefinitionObject: {},
    };
  },
  computed: {
    ...mapGetters(["getFeatureFlags"]),
    sortedDependents() {
      const sortedDependents = Array.from(this.dependentsData);
      const relations = [
        AcceptedRelations.SPOUSE,
        AcceptedRelations.CHILD,
        AcceptedRelations.SIBLING,
        AcceptedRelations.PARENT,
        AcceptedRelations.PARENT_IN_LAW,
      ];
      if (!this.shouldSortDependents) {
        return sortedDependents.filter((dep) => !dep?.isPlaceholder);
      }

      return sortedDependents.sort((dep1, dep2) => {
        if (dep1.isPlaceholder && !dep2.isPlaceholder) {
          return 1;
        }

        // if relation is lower in priority list
        if (relations.indexOf(dep1.relation) < relations.indexOf(dep2.relation)) {
          return -1;
        }
        // if relation is equal in priority, sort by name
        if (relations.indexOf(dep1.relation) === relations.indexOf(dep2.relation) && dep1.name < dep2.name) {
          return -1;
        }

        return 1;
      });
    },
  },
  watch: {
    dependentsModel() {
      this.dependentsData = new Set();
      this.updateDependentsData();
    },
  },
  mounted() {
    this.dependentsData = new Set();
    this.updateDependentsData();
  },
  methods: {
    getCardData(dependent) {
      const depPolicies = [];
      for (const [key, value] of Object.entries(this.depPolicyDetail)) {
        const relation = dependent.relation;
        if (value.coveredDependents.includes(relation)) {
          if (
            (relation === AcceptedRelations.PARENT || relation === AcceptedRelations.PARENT_IN_LAW) &&
            !value.pOrILFlag
          ) {
            depPolicies.push(key);
          }
          if (
            (relation === AcceptedRelations.CHILD || relation === AcceptedRelations.SPOUSE) &&
            dependent.meta?.isPreferred
          ) {
            depPolicies.push(key);
          }
        } else if (relation === this.coverPOrPil && value.pOrILFlag && dependent.meta?.isPreferred) {
          depPolicies.push(key);
        }
      }
      return { ...dependent, depPolicies };
    },
    showData(dependent) {
      this.$emit("clicked", utils.deepClone(dependent));
    },
    pushDependentPlaceholders(relation) {
      let count = 0;
      switch (relation) {
        case AcceptedRelations.CHILD:
          count = Math.max(this.totalDepCount.childrenCount, this.totalDepCount.groupedChildSiblingCount);
          break;
        case AcceptedRelations.PARENT:
          count = this.familyDefinitionObject.groupedParentsCount
            ? Math.min(this.totalDepCount.parentCount, this.totalDepCount.groupedParentsCount)
            : this.totalDepCount.parentCount;
          break;
        case AcceptedRelations.PARENT_IN_LAW:
          count = this.familyDefinitionObject.groupedParentsCount
            ? Math.min(this.totalDepCount.parentInLawCount, this.totalDepCount.groupedParentsCount)
            : this.totalDepCount.parentInLawCount;
          break;
        case AcceptedRelations.SIBLING:
          count = this.totalDepCount.groupedChildSiblingCount;
          break;
        case AcceptedRelations.SPOUSE:
          count = this.totalDepCount.coveredDependents.includes("spouse") ? 1 : 0;
          break;
      }
      while (count > 0) {
        count--;
        this.dependentsData.add({ isPlaceholder: true, relation });
      }
    },
    // TODO: Update to set, to avoid duplicacy
    updateDependentsData() {
      this.totalDepCount = utils.totalCoveredDependents(this.policies);
      this.familyDefinitionObject = utils.totalCoveredDependents(this.policies);
      if (this.totalDepCount.pOrILFlag || this.totalDepCount.coveredDependents.includes("parent")) {
        this.totalDepCount.parentCount = 2;
      }
      if (this.totalDepCount.pOrILFlag || this.totalDepCount.coveredDependents.includes("parent-in-law")) {
        this.totalDepCount.parentInLawCount = 2;
      }
      this.dependentsModel.forEach((dependent) => {
        switch (dependent.relation) {
          case AcceptedRelations.CHILD:
            this.totalDepCount.childrenCount--;
            if (this.totalDepCount.groupedChildSiblingCount > 0) {
              this.totalDepCount.groupedChildSiblingCount--;
            }
            break;
          case AcceptedRelations.PARENT_IN_LAW:
            if (this.totalDepCount.pOrILFlag) {
              this.totalDepCount.parentCount = 0;
            }
            this.totalDepCount.parentInLawCount--;
            if (this.totalDepCount.groupedParentsCount > 0) {
              this.totalDepCount.groupedParentsCount--;
            }
            break;
          case AcceptedRelations.PARENT:
            if (this.totalDepCount.pOrILFlag) {
              this.totalDepCount.parentInLawCount = 0;
            }
            this.totalDepCount.parentCount--;
            if (this.totalDepCount.groupedParentsCount > 0) {
              this.totalDepCount.groupedParentsCount--;
            }
            break;
          case AcceptedRelations.SPOUSE:
            this.totalDepCount.coveredDependents.splice(this.totalDepCount.coveredDependents.indexOf("spouse"), 1);
            break;
          case AcceptedRelations.SIBLING:
            if (this.totalDepCount.groupedChildSiblingCount > 0) {
              this.totalDepCount.groupedChildSiblingCount--;
              this.totalDepCount.childrenCount--;
            }
            break;
        }
        this.dependentsData.add(this.getCardData(dependent));
      });
      // push placeholder data
      const relations = [
        AcceptedRelations.CHILD,
        AcceptedRelations.PARENT,
        AcceptedRelations.PARENT_IN_LAW,
        AcceptedRelations.SPOUSE,
        AcceptedRelations.SIBLING,
      ];
      relations.forEach((relation) => {
        this.pushDependentPlaceholders(relation);
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
