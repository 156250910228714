export const DataCheckComparisonSource = {
  TPA: "TPA",
  INSURER: "Insurer",
  RENEWAL: "Renewal",
};
export const PolicyDataCheckErrorType = {
  USER_NOT_FOUND_IN_PORTAL: "User not found in portal",
  USER_NOT_FOUND_IN_FILE: "User not found in file",
  GENDER_MISMATCH: "Gender mismatch",
  DOB_MISMATCH: "DOB mismatch",
  RELATION_MISMATCH: "Relation mismatch",
  NAME_MISMATCH: "Name mismatch",
  EMPLOYEEID_MISMATCH: "EmployeeId mismatch",
  DEPENDENT_NOT_FOUND_IN_PORTAL: "Dependent not found in portal",
  DEPENDENT_NOT_FOUND_IN_FILE: "Dependent not found in file",
  POLICY_NOT_LINKED_TO_USER: "Policy not linked to the user",
  POLICY_NOT_LINKED_TO_DEPENDENT: "Policy not linked to the dependent",
  EXTRA_CHILD_NOT_COVERED: "Extra child not covered",
  RELATION_NOT_COVERED: "Relation not covered",
  MORE_THAN_TWO_PARENTS_OR_IN_LAWS: "More than two parents/ in laws",
  MORE_THAN_ONE_SPOUSE: "More than one spouse",
};
