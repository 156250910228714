import { getListQuery, getSingleQuery } from "../queries";
import { CXAccountOwnerSchema } from "../schemas/CXEntities";
import CXAccountOwnerMutations from "../mutations/CXAccountOwners";

export default {
  gqlType: "CXAccountOwner",
  fields: ["accountOrgName", "podName", "userEmail", "role", "status"],
  searchPlaceholder: "Search by Pod Name",
  singleQuery: getSingleQuery("CXAccountOwner"),
  listQuery: getListQuery("CXAccountOwner"),
  ...CXAccountOwnerSchema,
  ...CXAccountOwnerMutations,
  transform: (n, write = false) => {
    return {
      ...n,
      accountOrgName: n.org?.name,
      podMemberId: n.podMember?.id,
      role: n.podMember?.role,
      status: n.podMember?.status,
      podName: n.pod?.name,
      podId: n.pod?.id,
      orgId: n.org?.id,
      userEmail: n.user?.email,
    };
  },
  transform: (n, write = false) => {
    const payload = {
      ...n,
      accountOrgName: n.org?.name,
      podMemberId: n.podMember?.id,
      role: n.podMember?.role,
      status: n.podMember?.status,
      podName: n.pod?.name,
      podId: n.pod?.id,
      orgId: n.org?.id,
      userEmail: n.user?.email,
    };
    if (write) {
      delete payload.user;
      delete payload.org;
      delete payload.pod;
      delete payload.podMember;
    }
    return payload;
  },
};
