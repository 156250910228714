<template lang="pug">
.schedule-booking-container
  packages-list(v-if="benefitId", :benefitId="benefitId")
</template>

<script>
import PackagesList from "./components/PackagesList.vue";
export default {
  name: "ScheduleBooking",
  components: {
    PackagesList,
  },
  props: {},
  data() {
    return {};
  },
  computed: {
    benefitId() {
      return this.$route.params.benefitId;
    },
  },
  methods: {},
};
</script>

<style lang="scss">
@import "@/assets/styles/_variables.scss";
.border-1-dashed {
  border: 1px dashed rgba(0, 0, 0, 0.12);
}
.border-top-none {
  border-top: none !important;
}
</style>
