<template lang="pug">
.n-draggable-items
  template(v-for="(item, index) in items")
    div(:id="item.id"
      :class="`grabbable ${index === draggedOver ? dragOverClass : ''}`"
      :draggable="true",
      @dragstart="dragStart(index)",
      @dragenter="dragEnter($event, index)",
      @dragover="dragOver",
      @drop="drop(index)")
      slot(name="item", :item="item", :index="index")
</template>

<script>
export default {
  name: "NDraggableItems",
  data() {
    return {
      cursorClass: "cursor-grab",
      draggedItem: null,
      draggedOver: null,
    };
  },
  props: {
    items: { type: Array, default: () => [] },
    dragOverClass: { type: String, default: "bg-gray-300" },
  },
  methods: {
    dragStart(index) {
      this.cursorClass = "cursor-grabbing";
      this.draggedItem = index;
    },
    dragEnter(event, index) {
      this.draggedOver = index;
      event?.preventDefault();
    },
    dragOver(event) {
      event?.preventDefault();
    },
    drop(index) {
      this.draggedOver = null;
      this.cursorClass = "cursor-grab";
      this.$emit("drop", {
        draggedItem: this.draggedItem,
        droppedOn: index,
      });
    },
  },
};
</script>

<style lang="scss">
.grabbable {
  cursor: grab !important;
}
.grabbable:active {
  cursor: grabbing !important;
}
</style>
