import gql from "graphql-tag";
import { userFragment } from "./user";
export const retailPolicyFragment = gql`
  fragment RetailPolicy on RetailPolicy {
    id
    status
    startDate
    endDate
    meta
    user {
      name
    }
    policy {
      name
    }
    documents
    createdAt
    updatedAt
  }
`;
