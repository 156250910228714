import _ from "lodash";
export default {
  isAnyFilterApplied(filterModel) {
    for (const filter in filterModel) {
      if (filterModel[filter]) {
        return true;
      }
    }
    return false;
  },

  getFilterCountText(filterModel) {
    let count = 0;
    for (const filter in filterModel) {
      if (filter === "dates") {
        if (filterModel[filter].startDate && filterModel[filter].endDate) {
          count++;
        }
      } else if (filterModel[filter] && filterModel[filter] != "None selected") {
        count++;
      }
    }
    return `${count || "No"} Filters Applied`;
  },

  getFilterValue(filterKey, filterValue) {
    let finalFilterValue = null;
    if (filterValue.name) {
      finalFilterValue = filterValue.name;
    } else if (filterValue.label) {
      finalFilterValue = filterValue.label;
    } else if (filterKey === "dates") {
      if (filterValue.startDate && filterValue.endDate)
        finalFilterValue = `${filterValue.startDate} to ${filterValue.endDate}`;
      else return null;
    } else {
      finalFilterValue = filterValue;
    }
    return finalFilterValue;
  },
  getFilters(filterModel) {
    const filtersArray = [];
    for (const filterKey of Object.keys(filterModel)) {
      const finalFilterValues = [];
      const filterValue = filterModel?.[filterKey];
      if (filterValue && filterValue !== "None selected") {
        if (Array.isArray(filterValue)) {
          filterValue.forEach((value) => {
            const finalFilterValue = this.getFilterValue(filterKey, value);
            if (finalFilterValue) finalFilterValues.push(finalFilterValue);
          });
        } else {
          const finalFilterValue = this.getFilterValue(filterKey, filterValue);
          if (finalFilterValue) finalFilterValues.push(finalFilterValue);
        }
        if (finalFilterValues.length > 0)
          filtersArray.push({ key: filterKey, name: _.startCase(filterKey), value: finalFilterValues });
      }
    }
    return filtersArray;
  },
};
