<template lang="pug">
n-toast(
  position="b-toaster-bottom-left",
  :noAutoHide="true",
  :showIcon="false",
  :addHoverState="true",
  )
  template(v-slot:toast-content)
    span
      b-spinner.mr-2.align-sub(variant="light", small)
    span.font-md(v-if="state === $options.JobStates.WAITING") Waiting...
    span.font-md(v-else) {{ `${progress}% ${operationName} done` }}
    .progress.progress-bar-container
      .progress-bar.pb(
        role="'progressbar'",
        :aria-valuenow="progress",
        :aria-valuemax="100",
        :style="'width: '+progress+'%;'")
  </template>

<script>
import { JobStates } from "../common/enums";
import NToast from "./NovaToast.vue";

export default {
  name: "NProgressToastWrapper",
  components: {
    NToast,
  },
  props: {
    state: {
      type: String,
      validator: (value) => {
        return !value || Object.values(JobStates).indexOf(value) !== -1;
      },
      default: () => {
        return JobStates.WAITING;
      },
    },
    progress: {
      type: Number,
      validator: (value) => {
        if (value >= 0 && value <= 100) return true;
        return "progress value must be between 0 and 100";
      },
      default: 0,
    },
    operationName: {
      type: String,
      default: "",
    },
  },
  created() {
    this.$options.JobStates = JobStates;
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/_variables.scss";

.progress-bar-container {
  width: 12rem;
  height: 5px;
  margin: 1.25rem 0 0.625rem 0;
  .pb {
    background-color: $teal-700;
    border-radius: 2rem;
  }
}
</style>
