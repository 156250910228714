import gql from "graphql-tag";
import { dependentFragment } from "./dependent";
import { queryFragment } from "./query";
import { userBenefitFragmentWithoutContent } from "./userBenefit";

// CommonClaim fragments
const commonclaimUserFragment = gql`
  fragment CommonClaimUser on User {
    id
    name
    gender
    org {
      id
      name
    }
    meta
  }
`;

const commonclaimDependentFragment = gql`
  fragment CommonClaimDependent on Dependent {
    id
    name
    gender
    relation
  }
`;

const commonclaimPolicyFragment = gql`
  fragment CommonClaimPolicy on Benefit {
    id
    type
    name
  }
`;

// Main claim fragment
export const claimFragment = gql`
  fragment Claim on Claim {
    id
    meta
    type
    user {
      ...CommonClaimUser
      displayName
      email
      dob
      dependents {
        ...Dependent
      }
      benefits {
        ...UserBenefitEdgeWithoutContent
      }
    }
    policy {
      ...CommonClaimPolicy
      meta
      insurer {
        id
        name
      }
      tpa {
        id
        name
      }
    }
    dependent {
      ...Dependent
    }
    queries {
      ...ClaimQuery
    }
    status
    updatedAt
    createdAt
    displayContent
    timeline
    adminTimeline
    notesTimeline
    internalNotesTimeline
    csat
    claimOwner {
      id
      name
      email
    }
    nbNumber
    createdBy {
      id
      name
    }
    updatedBy {
      id
      name
    }
    createSource
    updateSource
    isClaimsToTpaIntegrationActive
    isMandatoryDocsEnabled
    claimOwnerExotelNumber
  }
  ${commonclaimUserFragment}
  ${commonclaimPolicyFragment}
  ${dependentFragment}
  ${userBenefitFragmentWithoutContent}
  ${queryFragment}
`;

export const claimOrgAdminFragment = gql`
  fragment Claim on Claim {
    id
    meta
    type
    user {
      ...CommonClaimUser
    }
    policy {
      ...CommonClaimPolicy
    }
    dependent {
      ...CommonClaimDependent
    }
    status
    displayContent
    updatedAt
    createdAt
    timeline
  }
  ${commonclaimUserFragment}
  ${commonclaimPolicyFragment}
  ${commonclaimDependentFragment}
`;

export const claimOrgMemberFragment = gql`
  fragment Claim on Claim {
    id
    meta
    type
    user {
      ...CommonClaimUser
    }
    policy {
      ...CommonClaimPolicy
      insurer {
        id
        name
      }
      tpa {
        id
        name
      }
    }
    dependent {
      ...CommonClaimDependent
    }
    queries {
      ...ClaimQuery
    }
    status
    updatedAt
    createdAt
    displayContent
  }
  ${commonclaimUserFragment}
  ${commonclaimPolicyFragment}
  ${commonclaimDependentFragment}
  ${queryFragment}
`;

export const claimResourceListFragment = gql`
  fragment Claim on Claim {
    id
    meta
    type
    user {
      ...CommonClaimUser
      displayName
      email
      dob
    }
    policy {
      id
    }
    dependent {
      ...CommonClaimDependent
      dob
      status
      meta
    }
    queries {
      ...ClaimQuery
    }
    createdAt
    updatedAt
    status
    displayContent
  }
  ${commonclaimUserFragment}
  ${commonclaimDependentFragment}
  ${queryFragment}
`;
