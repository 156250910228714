import gql from "graphql-tag";
import { getListQuery, getSingleQuery } from "../queries";
import echoPostTemplatesSchema from "../schemas/echoPostTemplates";
import echoPostTemplatesMutations from "../mutations/echoPostTemplates";

export default {
  gqlType: "EchoPostTemplate",
  label: "Echo Post Template",
  fields: ["orgName", "category", "subject", "lastUpdatedBy"],
  listQuery: getListQuery("EchoPostTemplate"),
  singleQuery: getSingleQuery("EchoPostTemplate"),
  ...echoPostTemplatesSchema,
  ...echoPostTemplatesMutations,
  transform: (n, write = false) => {
    const payload = {
      ...n,
      orgId: n.org?.id,
      orgName: n.org?.name,
      lastUpdatedBy: n.updatedBy?.name,
      imageFileId: n.image?.fileId,
      image: {
        ...(n.image || {}),
        fileId: n.image?.id,
        fileUrl: n.image?.url,
      },
      logoFileId: n.logo?.fileId,
      logo: {
        ...(n.logo || {}),
        fileId: n.logo?.id,
        fileUrl: n.logo?.url,
      },
    };
    if (write) {
      delete payload.org;
      delete payload.image;
      delete payload.logo;
    }
    return payload;
  },
};
