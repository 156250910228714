<template lang="pug">
  n-modal(
    v-bind="{...$props, ...$attrs}",
    centered,
    isScrollable=true,
    no-close-on-backdrop,
    modal-class="batch-delay-modal")
    template(v-slot:modal-header)
      .hb5.text-gray-900.mx-auto.my-1 {{ getDelayModalHeading }}
    template(v-slot:default)
      .mx-2
        .info-container.px-4.my-3.bg-red-100.rounded-8.d-flex.align-items-center.justify-content-start.position-relative(v-if="currentStep === 2")
            .icon-container.align-self-start.py-3
              i.text-red-500.icon-error
            .info-details.d-flex.align-items-start.justify-content-start.flex-column.py-3
              .info-title.font-md.font-inter.font-weight-semibold.text-gray-900.pl-2.pr-5.pb-1
                | {{ model.reason }}
              .info-text.font-sm.font-inter.font-weight-medium.text-gray-900.px-2
                span This batch is delayed 
                span(v-if="model.remark") because “{{ model.remark }}” 
        b-form
          vue-form-generator(
            :schema="getBatchDelaySchema()",
            :options="$options.formOptions",
            ref="batchDelayFormData",
            :model="model")
        n-button.w-100.my-3(v-if="isDelayed", variant="outline-danger", buttonText="Remove Delay Status", @click="removeDelayStatusConfig")
    template(v-slot:modal-footer)
      step-counter.mr-auto(v-if="!isDelayed", :totalSteps="2", :currentStep="currentStep-1")
      n-button.col-md-4(variant="light", :buttonText="getButtonTextBatchDelayModal('cancel')", @click="handleBatchDelayCancel")
      n-button.col-md-4(variant="dark", :buttonText="getButtonTextBatchDelayModal('submit')", @click="handleBatchDelayContinue", @keydown.enter="validateForm()")
</template>

<script>
import NModal from "../../../../components/NovaModal.vue";
import adminDefs from "../../admin/definitions";
import StepCounter from "@/components/StepCounter.vue";
import { validateInlineSelect, isVfgErrorPresent, validateConfirmation } from "@/utils";
import NButton from "@/components/NovaButton.vue";

const DELAY_REASONS = [
  "Endorsement not processed from Insurer's end",
  "Cd balance not updated",
  "Active data not shared",
  "Active data not updated",
  "Insufficient CD balance",
  "ECard not ready",
  "CD payment pending from client",
  "Awaiting documents from insurer",
  "Awaiting response from insurer",
  "Query pending to be resolved by client",
];

export default {
  name: "BatchDelayModal",
  components: {
    NButton,
    NModal,
    StepCounter,
  },
  props: {
    batchId: {
      type: String,
      required: true,
    },
    delayStatusConfig: {
      type: Object,
      default: () => {
        return {
          isDelayed: false,
          reason: "",
          remark: "",
        };
      },
    },
  },
  data() {
    return {
      currentStep: 1,
      isDelayed: false,
      model: {
        reason: "",
        remark: "",
        confirmation: false,
      },
    };
  },
  computed: {
    getDelayModalHeading() {
      if (this.currentStep === 2) {
        return "Preview";
      }
      return "Batch Endorsement Delay";
    },
  },
  mounted() {
    this.isDelayed = this.delayStatusConfig.isDelayed;
    this.model.reason = this.delayStatusConfig.reason;
    this.model.remark = this.delayStatusConfig.remark;
  },
  created() {
    this.$options.formOptions = {
      validateAfterLoad: false,
      validateAfterChanged: true,
      validateAsync: true,
    };
  },
  methods: {
    getBatchDelaySchema() {
      const reasonAndRemarkFields = [
        {
          model: "reason",
          type: "inline-select",
          label: "Select a reason for delay<span style='color:red'>*</span>",
          options: DELAY_REASONS,
          clearable: false,
          required: true,
          validator: ["required", validateInlineSelect],
        },
        {
          model: "remark",
          type: "inline-text-area",
          inputType: "text",
          label: "Additional remark",
          rows: 4,
          validator: ["string"],
          min: 2,
          max: 150,
        },
      ];
      const confirmationField = {
        model: "confirmation",
        type: "checkbox",
        label: "I confirm that this information is accurate as per the recent updates.",
        default: false,
        required: true,
        validator: [validateConfirmation],
      };

      if (this.isDelayed) {
        return {
          fields: [...reasonAndRemarkFields, confirmationField],
        };
      } else if (this.currentStep === 1) {
        return { fields: reasonAndRemarkFields };
      } else {
        return { fields: [confirmationField] };
      }
    },
    getButtonTextBatchDelayModal(type) {
      switch (type) {
        case "cancel": {
          return this.currentStep === 1 ? "Cancel" : "<- Go Back";
        }
        case "submit": {
          if (this.isDelayed) return "Update";
          return this.currentStep === 1 ? "Continue ->" : "Submit";
        }
      }
    },
    handleBatchDelayCancel() {
      if (this.currentStep === 1) {
        this.$emit("close");
      } else {
        this.currentStep--;
      }
    },
    async handleBatchDelayContinue() {
      await this.validateForm();
      if (isVfgErrorPresent(this.$refs.batchDelayFormData?.errors)) {
        this.$store.commit("addToast", {
          variant: "danger",
          message: this.$refs.batchDelayFormData?.errors[0]?.error,
        });
        return;
      }
      this.$store.commit("clearToasts");
      if (this.isDelayed || this.currentStep === 2) {
        await this.updateDelayStatusConfig(true);
        this.currentStep = 1;
        this.$emit("close");
      } else {
        this.currentStep++;
      }
    },
    async validateForm() {
      await this.$refs?.batchDelayFormData?.validate();
    },
    async updateDelayStatusConfig(status) {
      let result;
      try {
        result = await this.$apollo.mutate({
          mutation: adminDefs.userChangeBatches.updateDelayStatusConfig,
          variables: {
            batchId: this.batchId,
            isDelayed: status,
            reason: status ? this.model.reason : "",
            remark: status ? this.model.remark : "",
          },
        });
      } catch (err) {
        console.error(err);
      }
      if (result?.data?.updateDelayStatusConfig?.success) {
        const change = status ? "updated" : "removed";
        this.$store.commit("addToast", {
          variant: "success",
          message: `Successfully ${change} batch delay status`,
        });
        this.postUpdate(status);
      } else {
        this.$store.commit("addToast", {
          variant: "danger",
          message: "Error occurred while updating batch delay status",
        });
      }
    },
    async removeDelayStatusConfig() {
      await this.updateDelayStatusConfig(false);
      this.$emit("close");
    },
    postUpdate(status) {
      this.isDelayed = status;
      // reset states
      this.model.confirmation = false;
      if (!status) {
        this.model.reason = "";
        this.model.remark = "";
      }
      // emit to reset states of parent
      // needed in case of reason/remark update and remove status
      this.$emit("setBatchDelayStatusFlag", status);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
@import "@/assets/styles/_typography.scss";
.info-container {
  border: 1px solid $red-500;
}
.icon-container {
  width: max-content;
  border-radius: 50%;
}
</style>
