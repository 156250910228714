import gql from "graphql-tag";
import { pgPaymentFragment } from "./pgPayment";
import { novaProductFragment } from "./novaProduct";

export const novaOrderFragment = gql`
  fragment NovaOrder on NovaOrder {
    id
    orderAmount
    billingAddress
    stateOfResidence
    status
    meta
    createdAt
    updatedAt
    user {
      id
      name
      email
      meta
    }
    pgPayments {
      ...PgPayment
    }
    novaOrderItems {
      quantity
      node {
        ...NovaProduct
      }
    }
  }
  ${pgPaymentFragment}
  ${novaProductFragment}
`;
