export function isSamePerson(p1, p2) {
  if (p1.id && p2.id) return p1.id === p2.id;
  else if (p1.tempId && p2.tempId) return p1.tempId === p2.tempId;
  return p1.name === p2.name && p1.dob === p2.dob && p1.gender === p2.gender && p1.relation === p2.relation;
}

export function extractMetaFields(meta, fields) {
  return fields.reduce((result, field) => {
    if (meta && meta[field]) {
      result[field] = meta[field];
    }
    return result;
  }, {});
}
