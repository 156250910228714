const pgPaymentStatus = [
  "created",
  "authorized",
  "captured",
  "refunded",
  "failed",
  "reversed",
  "pending",
  "blocked",
  "disputed",
];

export const PaymentStatus = {
  CREATED: "created",
  AUTHORIZED: "authorized",
  CAPTURED: "captured",
  REFUNDED: "refunded",
  FAILED: "failed",
  REVERSED: "reversed",
  PENDING: "pending",
  BLOCKED: "blocked",
  DISPUTED: "disputed",
};

export const PgPaymentsSchema = {
  filterSchema: {
    fields: [
      {
        model: "status",
        type: "select",
        values: pgPaymentStatus,
        label: "Status",
      },
    ],
  },
};
