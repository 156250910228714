<template lang="pug">
.summary-card.m-2.border-top-gray-400
  .container.bg-teal-100.p-3.w-100
    .h4(v-if="header") {{  header }}
    .hb5.mb-1 {{  title  }}
    .font-xs {{ bodyText1 }}
    div(v-if="bodyText2")
      br
      .font-xs {{ bodyText2 }}
      div(v-if="showDeductibleSideSheet")
        br
        n-button.py-1(size="xs", variant="outline-secondary", buttonText="See how deductible works ->", @click="toggleSidesheet")

  n-button.mt-2.pb-2.mb-8.px-auto.desk-view(v-if="showButton", :buttonText="buttonText", variant="dark", @click="$router.push(`${nextRoute}`)")
  

</template>
<script>
import NIcon from "../../../../../components/NovaIcon.vue";
import NButton from "../../../../../components/NovaButton.vue";
import mobileApp from "../../../../mobileApp";
import utils from "@/utils";
export default {
  name: "SummaryCard",
  components: {
    NIcon,
    NButton,
    NIcon,
  },
  data() {
    return {};
  },
  props: {
    header: {
      type: String,
    },
    title: {
      type: String,
      default: "",
    },
    bodyText1: {
      type: String,
      default: "",
    },
    bodyText2: {
      type: String,
      default: "",
    },
    buttonText: {
      type: String,
    },
    nextRoute: {
      type: String,
    },
    showButton: {
      type: Boolean,
      default: false,
    },
    showDeductibleSideSheet: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    isApp() {
      return mobileApp.isApp;
    },
    isMobileBrowser() {
      return this.isMobileView();
      return (utils.mobileCheck() || utils.tabCheck()) && !this.isApp;
    },
    isMobileView() {
      // checking for lg
      return window.innerWidth < 992;
    },
    toggleSidesheet() {
      this.$root.$emit("bv::toggle::collapse", "deductible-sidesheet");
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
.desk-view {
  width: 100%;
}
@media (max-width: 767px) {
  .fixed-bottom {
    position: fixed;
    bottom: -12%;
  }
}
</style>
