import gql from "graphql-tag";

export const novaProductFragment = gql`
  fragment NovaProduct on NovaProduct {
    id
    name
    netCost
    taxRate
    taxType
    type
    status
    meta
  }
`;
