import gql from "graphql-tag";
import { CXAccountOwnerFragment } from "../fragments";

export default {
  upsertMutation: gql`
    mutation upsertCXAccountOwner($id: ID, $podMemberId: ID!, $orgId: ID!, $meta: JSONObject!) {
      upsertCXAccountOwner(input: { id: $id, podMemberId: $podMemberId, orgId: $orgId, meta: $meta }) {
        accountOwner {
          ...CXAccountOwner
        }
      }
    }
    ${CXAccountOwnerFragment}
  `,
  deleteMutation: gql`
    mutation deleteAccountOwner($id: ID!) {
      deleteAccountOwner(input: { id: $id }) {
        status
      }
    }
  `,
};
