<template lang="pug">
.start-claim
  .overlay
    .container.my-4
      .row.mt-4(v-if="hasPolicies")
        .col-sm-11.col-lg-8(:class="showBalanceSI ? '' : 'offset-md-2'")
          .n-card
            .d-flex.w-99.p-3.justify-content-between.align-items-center
              i.pr-3
              .text-gray-600.font-hc.mt-3 Starting a claim
              i.text-gray-600.icon-cross.cursor-pointer(@click="onClose()")
            .offset-md-2.col-md-8.d-flex.flex-column.justify-content-center.align-items-center.px-2
              b-avatar.mt-2.bg-blue-100(variant="none", size="3rem")
                n-icon(name="claims-1", variant="blue-400", :size="1.5")
              h3.hb3.my-4.text-center Select the option that applies to you
                img.align-top.ml-2(:src="require('@/assets/emojis/wave.svg')", width="25")
              card-link.mb-2.p-2(
                v-for="card in claimCards",
                :key="card.claimForm",
                :icon="card.icon",
                :variant="card.variant",
                :to="{name: card.claimForm, query:{id: policyId}}")
                template(v-slot:header)
                  | {{ card.header }}
                template(v-slot:body)
                  | {{ card.body }}
              
              .text-gray-700.font-weight-medium.font-sm.mt-3.cursor-pointer(@click="showSalesIqChat")
                | None of these options apply to me
                n-icon.ml-1.align-middle(name="chevron-right", :size="1.1")

            .quick-links-bar.font-weight-medium.py-3.mt-5.mt-xl-8.w-100.text-center
              .d-inline-block.p-2 Learn more about Claims ->
              b-link.py-3(
                v-for="(type, index) in claimContentTypes",
                :key="type",
                :to="{name: 'claim-process', params: getQuickLinkParams(type.toLowerCase())}")
                | {{ type }}
                span.px-2.text-blue-200(v-if="index < claimContentTypes.length - 1") |
        .col-md-4(v-if="showBalanceSI")        
          .n-card.d-none.d-md-block.px-3.pt-4.pb-4
            balance-si(v-if="policyId" :policyId="policyId", :userId="$store.state.user.id") 
      .row.mt-4(v-else)
        .col-sm-11.offset-md-2.col-md-8
          .n-card
            .d-flex.w-99.p-3.justify-content-between.align-items-center
              i.pr-3
              h5.text-gray-600.font-hc.mt-3 Starting a claim
              i.text-gray-600.icon-cross.cursor-pointer(@click="$router.go(-1)")
            .offset-md-2.col-md-8.d-flex.flex-column.justify-content-center.align-items-center.mt-3
              h3.h3
                | You don't have any policies to continue
              .d-flex.align-items-center.mt-3.mb-5
                n-button.mr-2(:buttonText="goBackText", variant="outline-primary", @click="$router.go(-1)")
                n-button(buttonText="Need Help", @click="showSalesIqChat")
    .position-fixed.bottom-0.bg-white.border.p-3.d-block.d-sm-none.justify-content-center.align-items-center.w-100(v-if="showBalanceSI")
      balance-si(v-if="policyId" :policyId="policyId", :userId="$store.state.user.id" collapsible)
    .d-block.d-sm-none.mt-5.py-4(v-if="showBalanceSI")
</template>

<script>
import { mapGetters } from "vuex";
import CardLink from "./components/CardLink.vue";
import utils from "@/utils";
import NButton from "@/components/NovaButton.vue";
import BalanceSI from "@/portal/pages/components/Policy/BalanceSI.vue";

export default {
  components: {
    CardLink,
    NButton,
    "balance-si": BalanceSI,
  },
  data() {
    return {
      policyId: null,
      goBackText: "<- Go Back",
      contentId: null,
      claimCards: [
        {
          claimForm: "emergency-claim-form",
          icon: "error",
          variant: "danger",
          header: "This is an emergency",
          body: "Select this if you're already at a hospital and are undergoing treatment",
        },
        {
          claimForm: "claim-intimation-form",
          icon: "checkup",
          variant: "success",
          header: "I'm planning to undergo a treatment",
          body: "Select this if you're planning to get treated at a hospital in the near future",
        },
        {
          claimForm: "reimbursement-form",
          icon: "hospital",
          variant: "secondary2",
          header: "I've been discharged from a hospital",
          body: "Select this if you've recently been discharged and have paid any hospital bills",
        },
      ],
      claimContentTypes: ["Cashless", "Reimbursement", "Emergency"],
    };
  },
  computed: {
    ...mapGetters(["getFeatureFlags"]),
    hasPolicies() {
      const policies = [...this.$store.state.user.benefits];
      return policies.length;
    },
    isOpdClaimsEnabled() {
      return this.getFeatureFlags.ENABLE_OPD_CLAIMS;
    },
    showBalanceSI() {
      return this.hasPolicies && this.getFeatureFlags.ENABLE_BALANCE_SI && this.isUserBenefitLinked;
    },
    isUserBenefitLinked() {
      return utils.isUserBenefitLinked(this.$store.state.user?.benefits, this.policyId);
    },
  },
  mounted() {
    let policyId = this.$route.query?.id;
    const policies = [...this.$store.state.user.benefits];
    if (!policies.length) {
      this.$store.commit("addToast", {
        variant: "danger",
        message:
          "Your account does not have any active policies at the moment. Please reach out to our chat support for any assistance",
      });
    } else {
      const gmcPolicy = policies.find((p) => p.node.type === "gmc");
      if (gmcPolicy) {
        this.contentId = gmcPolicy.node?.id;
        if (!policyId) policyId = gmcPolicy.node?.id;
      }
    }
    if (policyId) {
      this.policyId = policyId;
    }
    if (this.isOpdClaimsEnabled) {
      this.claimCards.push({
        claimForm: "opd-form",
        icon: "hospital",
        variant: "warning",
        header: "OPD Reimbursement",
        body: "Select this if you are visiting or have visited a hospital for OPD consultations",
      });
    }
  },
  methods: {
    showSalesIqChat() {
      this.$store.commit("toggleSupportContainer");
    },
    getQuickLinkParams(type) {
      return {
        contentId: this.contentId,
        claimType: type,
      };
    },
    onClose() {
      if (this.$route.params?.fromCg) {
        this.$router.push("/dashboard");
      } else {
        this.$router.go(-1);
      }
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/_variables.scss";
.start-claim {
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    background-color: #fbfbfb;
    height: 100%;
    width: 100%;
    overflow: auto;
    z-index: 1000;
  }
  .n-card {
    background: #ffffff;
    border: 1px solid #d8e3f8;
    box-sizing: border-box;
    box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
    border-radius: 12px;
  }
  .quick-links-bar {
    background-color: rgba($blue-100, 0.3);
  }
}
</style>
