import gql from "graphql-tag";
import { getListQuery, getSingleQuery } from "../queries";
import echoPostsSchema from "../schemas/echoPosts";
import echoPostsMutations from "../mutations/echoPosts";

export default {
  gqlType: "EchoPost",
  label: "Echo Post",
  fields: ["orgName", "echoPostTemplateSlug", "userName"],
  listQuery: getListQuery("EchoPost"),
  singleQuery: getSingleQuery("EchoPost"),
  ...echoPostsSchema,
  ...echoPostsMutations,
  transform: (n) => {
    return {
      ...n,
      userId: n.user?.id,
      orgName: n.user?.org?.name,
      userName: n.user?.name,
      echoPostTemplateSlug: n.echoPostTemplate?.category + "-" + n.echoPostTemplate?.subject,
      org: n.user?.org,
      echoPostTemplate: {
        ...n.echoPostTemplate || {},
        name: n.echoPostTemplate?.category + "-" + n.echoPostTemplate?.subject,
      },
      echoPostTemplateId: n.echoPostTemplate?.id,
      recipients: n.recipients?.map(r => r.node) || [],
    };
  },
}
