<template lang="pug">
  .summary-container.pt-2.pb-3.d-flex.justify-content-between.align-items-center
    .title.d-flex.flex-column
      .d-flex.text-nowrap.align-items-center
        .hb4.d-inline-block.mb-1.font-weight-bold.org-name.text-truncate(id="org") {{`${orgName}`}}
        .hb4.d-inline-block.mb-1.font-weight-bold.org-name.text-truncate(v-if="orgName") {{`\&nbsp-\&nbsp`}}
        .hb4.d-inline-block.mb-1.font-weight-bold {{`${getMonthYear(batchInfo?.startingAt)} Batch`}}
      b-tooltip(
        target="org",
        triggers="hover")
        | {{ orgName }}
      .info-text.mt-1 {{ batchStartAndEndDate }}
    .ml-auto.mr-2.d-flex.text-right.h-100.align-items-center
      .subtitle.mr-3.d-flex.flex-column.justify-content-around
        .d-flex.align-items-center
          p.m-0.text-gray-700.mr-3 Premium Paid 
          p.hb5.m-0 {{ premiumAmount | inr }}
        p.hb6.m-0.text-blue-600.cursor-pointer.d-flex.align-items-center.font-weight-semibold.mt-1(
          @click="$router.push({ name: `org_admin_premium_details`, params: { batchId: batchInfo.id }})"
        ) View Breakdown  
          i.icon-chevron-right
      .vertical-separator
      
      b-dropdown.py-1.ml-2(
      no-caret,
      right,
      variant="dark",
        )
        template(v-slot:button-content)
          .d-flex.align-items-center
            span.font-weight-medium Download Documents
            i.ml-2.icon-chevron-down
        template(v-for="(policyName, index) in policyNames")
          b-dropdown-group(:header="policyName")
            b-dropdown-item.font-sm.font-inter.font-weight-medium.text-gray-900(
              v-for="(docType) in Object.values($options.EndorsementDocumentsEnum)",
              v-if="isDocumentPresent(policyName, docType)",
              @click="downloadDocument(policyName, docType)",
            ) Download {{ getDropdownHeading(docType) }}
            b-dropdown-divider.text-gray-400(v-if="index < (Object.values($options.EndorsementDocumentsEnum).length -1)")
  </template>

<script>
import NButton from "@/components/NovaButton.vue";
import NChip from "@/components/NovaChip.vue";
import utils from "@/utils";
import { EndorsementDocuments } from "@/common/enums";

const endorsementDocsDisplayText = {
  active_list: "Active List",
  endorsement_copy: "Endorsement Copy",
  cd_statement: "CD Statement",
  annexure: "Annexure",
  tax_invoice: "Tax Invoice",
};

export default {
  name: "SummaryCard",
  components: {
    NButton,
    NChip,
  },
  props: {
    batchInfo: {
      type: Object,
      required: true,
    },
    policyNames: {
      type: Array,
      default: () => [],
      required: true,
    },
    documents: {
      type: Object,
      required: true,
      default: () => {},
    },
    premiumAmount: {
      type: String,
      required: true,
    },
    orgName: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      getMonthYear: utils.getMonthYear,
    };
  },
  computed: {
    batchStartAndEndDate() {
      return `Start date: ${utils.getDateWithSuffix(
        this.batchInfo?.startingAt,
        "Do MMMM"
      )}  |  End date: ${utils.getDateWithSuffix(this.batchInfo?.endingAt, "Do MMMM")}`;
    },
  },
  created() {
    this.$options.EndorsementDocumentsEnum = EndorsementDocuments;
  },
  methods: {
    downloadDocument(policyName, docType) {
      const document = this.documents.endorsement.find(
        (file) => file.policyName === policyName && file.docType === docType
      );
      if (!document) {
        this.$store.commit("addToast", {
          variant: "danger",
          message: "Error downloading file",
        });
      }
      this.$store.commit("addToast", {
        variant: "success",
        message: "Downloading",
      });
      window.open(document.url);
    },
    isDocumentPresent(policyName, docType) {
      return !!this.documents.endorsement.find((file) => file.docType === docType && file.policyName === policyName);
    },
    getDropdownHeading(docType) {
      return endorsementDocsDisplayText[docType];
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
@import "@/assets/styles/_typography.scss";
.summary-container {
  height: 5.5rem;
  background-color: transparent;
  .org-name {
    max-width: 250px;
  }
  .info-text-container {
    border: 0;
    padding-right: 0;
  }

  .info-text {
    color: $gray-700;
    @extend .font-md;
    @extend .font-weight-medium;
  }
}
.vertical-separator {
  border-left: 1px solid $gray-500;
  height: 60%;
}
</style>
