<template lang="pug">
  b-modal(:id="id"
   centered)
    template(#modal-header)
      .w-100.font-lg.text-gray-900.font-weight-semibold
        span {{header}}
    template(#modal-footer)
      n-button.px-4(variant="light", :buttonText="'Cancel'", @click="$bvModal.hide('change-confirmation-modal')")
      n-button.px-4(variant="primary", :buttonText="submitButtonText", @click="handleSubmit")
    .info-container
      .div(if="warningText").warning
        .icon-container.p-3.d-flex.align-items-center.justify-content-center
          i(:class="[`icon-warning`, `text-mustard-600`]")
        .p-3.info-details.d-flex.align-items-start.justify-content-start.flex-column(style="flex: 1;")
          strong.font-lg.text-gray-900 {{ warningText }}
      .py-4
          .font-lg.text-gray-900(v-html="primaryText")
      .py-4
        .font-lg.text-gray-900 {{secondaryText}}
</template>

<script>
import NButton from "@/components/NovaButton.vue";
export default {
  name: "ChangeConfirmationModal",
  components: {
    NButton,
  },
  props: {
    id: {
      type: String,
      default: "",
    },
    warningText: {
      type: String,
      default: "yes",
    },
    secondaryText: {
      type: String,
      default: "",
    },
    primaryText: {
      type: String,
      default: "",
    },
    submitButtonText: {
      type: String,
      default: "Submit",
    },
    header: {
      type: String,
      default: "Confirm",
    },
  },
  methods: {
    handleSubmit() {
      this.$emit("confirm");
      this.$bvModal.hide("change-confirmation-modal");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
@import "@/assets/styles/_typography.scss";
.info-container {
  border: 1px $red-500;
}
.icon-container {
  width: max-content;
  border-radius: 50%;
}

.warning {
  background-color: $mustard-100;
}
</style>
