<template lang="pug">
  .h-100(v-if="variant === 'addOnCards'")
    .bg-white.rounded-md.shadow-xs.h-100.overflow-hidden(:class="previewMode?'':'cursor-pointer'")
      .addon-card-bg(
        :style="{'background-image': 'url(' + require(`@/assets/images/${cardData.image}`) + ')'}",
        data-cy="policy-addon-card"
        :class="policy.id")
        .mx-5.w-75
          span.text-gray-900.font-xl.font-weight-bold.break-spaces {{cardData.title}}
          .text-gray-900.font-lg.font-weight-medium.break-spaces.mt-2.mb-4 {{cardData.subtitle}}
          template(v-if="policy.infoCard")
            .text-gray-700.font-weight-medium.font-sm {{ policy.infoCard.title }}
            plan-info.mt-1.mb-4.w-25(
              :icon="policy.infoCard.icon",
              :inrValue="policy.infoCard.value")
          n-button.rounded-lg(:buttonText="cardData.btnText", variant="dark", data-cy="policy-addon-link" @click="viewPolicyDetails")
  .policy-card.shadow-sm.col.d-flex.flex-column.justify-content-left.bg-white.rounded-md.px-0.overflow-hidden.ph-policy-card(
    v-else,
    :class="[(to && !previewMode) ? 'cursor-pointer' : 'preview-card']",
    data-cy="dashboard-benefit-card",
    @click="viewPolicyDetails")
    .px-3.px-sm-4.py-3 
      span.mx-2.px-2.py-1.rounded-4.font-hc(v-if="isSuperTopup",
      :class=" policy.status === 'active' ? 'bg-gray-200' :'bg-mustard-400'")
        n-icon.align-middle.mr-1(
            :size="1",
            :name="getStatusPillClass")
        | {{superTopupStatusPill}}

      img.product-img.d-none.d-sm-block.d-md-none.d-xl-block.object-fit-contain(
        v-if="cardData.image && isPolicyCardImageVisible", :src="require(`@/assets/images/${cardData.image}.png`)")
      .d-flex.w-100.justify-content-left.align-items-center.my-4
        .plan-type-icon-container.rounded-lg.d-flex.align-items-center.justify-content-center.mr-1(:class="`${policy.type}`")
          n-icon.d-none.d-lg-block(:name="cardData.icon", :size="2.25")
          n-icon.d-lg-none(:name="cardData.icon", :size="1.875")
        .d-flex.flex-column.justify-content-center.ml-2
          h4.hb4.mb-1.text-gray-900 {{cardData.title}}
            n-icon.info-icon.ml-1.cursor-pointer(
              name="info",
              variant="gray-600",
              :pathCount="2",
              :size="1",
              v-b-tooltip.hover.top="cardData.subtitle")
          .text-gray-800.font-weight-medium {{policyDetails.insurerName}}
      template(v-if="showAvailableSumInsured")
        balance-si(v-if="policy.id && user.id" :policyId="policy.id", :userId="user.id") 
        .policy-details-divider.my-3
        .coverage-type-container.d-flex.align-items-center.mb-1
          img.font-lg.coverage-type-icon(:src="policyDetails.coverageIcon")
          .coverage-definition.font-md.text-gray-900.font-weight-medium.ml-1 {{policyDetails.coverageType}}
      template(v-else)
          .plan-si-container.d-flex.align-items-center
            .mr-1.icon-money.font-lg.text-teal-800
            .text-gray-700.font-sm.font-weight-medium Sum Insured
          h3.hb3.d-flex.text-gray-900 {{toINR(policyDetails.inrValue)}}
          .policy-details-divider.my-3
          .coverage-type-container.d-flex.align-items-center.mb-1
            img.font-lg.coverage-type-icon.mr-1(:src="policyDetails.coverageIcon")
            .coverage-type-text.text-gray-700.font-sm.font-weight-medium Coverage Type
          .coverage-definition.font-md.text-gray-900.font-weight-medium.pb-2 {{policyDetails.coverageType}}
    
    .view-btn.row.no-gutters.mt-auto.align-items-center.justify-content-center(
      v-if="!previewMode",
      :class="cardData.isPurchased ? 'personal-plan' : 'group-plan'")
      .shadow-none.rounded-top-none.d-flex
      span.mx-2.px-2.py-1.rounded-4.bg-mustard-400.font-hc.text-mustard-900(v-if="cardData.isPurchased && !isSuperTopup") Purchased
      span.font-sm.font-weight-medium.ph-policy-card-view-details-button(data-cy="dashboard-policy-view-details-btn") View Details ->
</template>

<script>
import { mapGetters } from "vuex";
import { isEmpty } from "lodash-es";
import PlanInfo from "../components/PlanInfo.vue";
import { policyTypes } from "../admin/constants";
import { SuperTopupPolicyStatus } from "../user/superTopups/constants";
import NIcon from "../../../components/NovaIcon.vue";
import BalanceSI from "./Policy/BalanceSI.vue";
import NButton from "@/components/NovaButton.vue";
import utils from "@/utils";
import { getActivePolicyByType } from "@/utils/user";
export default {
  name: "PolicyCard",
  components: {
    PlanInfo,
    NButton,
    NIcon,
    "balance-si": BalanceSI,
  },
  props: {
    index: {
      type: Number,
      default: 0,
    },
    policy: {
      type: Object,
      default: () => {
        return { type: "no-policy-defined" };
      },
    },
    edgeMeta: {
      type: Object,
      default: null,
    },
    isSuperTopup: {
      type: Boolean,
      default: false,
    },
    formData: {
      type: Object,
      default: null,
    },
    to: {
      type: [String, Object],
      default: null,
    },
    buttonUrl: {
      type: String,
      default: null,
    },
    variant: {
      type: String,
      default: "",
    },
    previewMode: {
      type: Boolean,
      default: false,
    },
    user: {
      type: Object,
      default: () => {},
    },
    userChanges: {
      type: Array,
      default: null,
    },
  },
  computed: {
    ...mapGetters(["getFeatureFlags"]),
    cardData() {
      if (this.variant === "addOnCards") {
        return policyTypes[this.policy.type][this.policy.id];
      }
      return policyTypes[this.policy.type];
    },
    policyDetails() {
      const data = {};
      if (this.policy?.insurer?.id) {
        data.insurerImage = this.policy?.insurer?.s3Url ?? "";
        data.insurerName = this.policy.insurer.name;
      }
      data.inrValue = this.inrValue;

      if (this.edgeMeta?.familyDefinition) {
        let shortName = this.edgeMeta.familyDefinition.replace(/\d+/g, "").replace(/\//g, "_");

        if (utils.isCustomFamilyDefinition(this.edgeMeta.familyDefinition)) {
          shortName = this.edgeMeta.familyDefinition
            .replace(/\d+/g, "")
            .replace(/_/g, "")
            .replace(/[()]/g, "")
            .replace(/\//g, "_")
            .replace(/\+W/g, "")
            .replace(/\$/, "");
        }

        data.coverageIcon = require(`@/assets/images/coverage${shortName}.svg`);
        data.coverageType = utils.getFamilyDefinition(this.edgeMeta.familyDefinition);
      } else if (this.formData?.coveredMembers?.length) {
        data.coverageType = utils.getSuperTopupFamilyDefination(this.formData.coveredMembers);
        data.coverageIcon =
          this.formData.coveredMembers.length == 1
            ? require("@/assets/images/coverageE.svg")
            : require("@/assets/images/coverageESC.svg");
      }
      return data;
    },
    isPolicyCardImageVisible() {
      return !this.$route.fullPath.startsWith("/dashboard") || this.user.org?.meta?.servicesOffered === "WELLNESS_ONLY";
    },
    superTopupStatusPill() {
      if (this.policy?.status === SuperTopupPolicyStatus.ACTIVE) {
        return "Policy Documents Available";
      }
      return "Allotment under process";
    },
    inrValue() {
      if (this.policy?.meta?.hideSi) {
        return "-";
      }

      if (this.edgeMeta?.isVariableCtc && !this.edgeMeta?.isSumInsuredFixed && this.edgeMeta?.variableSiFactor === 1) {
        return this.edgeMeta.variableSiText;
      } else if (this.edgeMeta?.isVariableCtc && !this.edgeMeta?.isSumInsuredFixed) {
        return this.edgeMeta.variableSiFactor + this.edgeMeta.variableSiText;
      } else if (this.formData) {
        return this.formData?.si;
      }

      return this.edgeMeta?.si;
    },
    showAvailableSumInsured() {
      return this.policy?.type === "gmc" && this.getFeatureFlags.ENABLE_BALANCE_SI && this.isUserBenefitLinked;
    },
    isUserBenefitLinked() {
      return utils.isUserBenefitLinked(this.$store.state.user?.benefits, this.policy?.id);
    },
  },
  methods: {
    toINR(val) {
      return utils.getFormattedINRValue(val);
    },
    viewPolicyDetails() {
      if (this.previewMode) return;
      if (this.policy?.type === "addOnCards") {
        if (this.policy?.id === "topup-card") {
          const gmcPolicy = getActivePolicyByType(this.user.benefits, "gmc");
          const coverage = gmcPolicy?.meta?.familyDefinition || "ESC";

          window.posthog.capture("topup_card_click", {
            base_policy_coverage: coverage,
            base_policy_amount: parseInt(gmcPolicy?.meta?.si),
          });
        }
        if (this.policy?.id === "flexi-wallet-card") {
          window.posthog.capture("flexi_wallet_card_click", {});
        }
      }
      if (this.to) this.$router.push(this.to);
      else if (this.buttonUrl) window.open(this.buttonUrl, "_blank");
    },
    getStatusPillClass() {
      if (this.policy.status == "active") return "star";
      return "clock";
    },
    isInsurerOrSP(givenText) {
      const cases = ["service provider", "insurer"];
      return cases.indexOf(givenText.toLowerCase()) !== -1;
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/_variables.scss";
@import "@/assets/styles/mixins/_breakpoints.scss";
@import "@/assets/styles/_misc.scss";

.addon-card-bg {
  background-repeat: no-repeat;
  &.topup-card {
    background-position: right bottom;
    padding: 3rem 0;
  }
  &.flexi-wallet-card {
    background-position: 20rem 0;
    padding: 1.5rem 0;
  }
  @include media-breakpoint-down(md) {
    &.flexi-wallet-card {
      background-position: 28rem 0;
    }
  }
  @include media-breakpoint-down(sm) {
    &.topup-card {
      background-size: cover;
    }
    &.flexi-wallet-card {
      background-position: 18rem 0;
    }
  }
  background-size: contain;
}
.policy-card {
  .gmc,
  .ppc {
    background: $red-100;
    color: $red-500;
  }
  .opd {
    background: $malibu-100;
    color: $malibu-800;
  }
  .gpa {
    background: $blue-100;
    color: $blue-500;
  }
  .gtl,
  .dcl {
    background: $teal-100;
    color: $teal-800;
  }
  .covid {
    background: $purple-100;
    color: $purple-800;
  }
  .workmen {
    background: $gray-100;
    color: $gray-800;
  }
  .topup,
  .super-topup {
    background: $mustard-100;
    color: $mustard-700;
  }
  .gratuity {
    background: $malibu-100;
    color: $malibu-800;
  }
  .dental {
    background: $blue-100;
    color: $blue-800;
  }

  width: 34.125rem;
  min-height: 18.625rem;
  &.preview-card {
    min-height: 15.625rem;
  }

  .plan-type-icon-container {
    width: 3.875rem;
    height: 3.875rem;
    @include media-breakpoint-down(md) {
      width: 3.125rem;
      height: 3.125rem;
    }
  }
  .info-icon:hover {
    color: $gray-900;
  }
  .policy-details-divider {
    border-bottom: 0.075rem dashed rgba($black, 0.12);
  }
  .view-btn {
    height: 3rem;
    transition: all 0.3s ease;
  }

  .product-img {
    position: absolute;
    top: 0;
    right: 0;
    height: 6.25rem;
    width: 6.25rem;
    transition: all 0.3s ease;
  }
  .group-plan {
    color: $blue-700;
    background: rgba($blue-100, 0.3);
  }
  .personal-plan {
    color: $mustard-900;
    background: rgba($mustard-100, 0.3);
  }
  &:hover {
    .product-img {
      right: -0.625rem;
    }
    .group-plan {
      background: $blue-100;
    }
    .personal-plan {
      background: $mustard-100;
    }
  }
  .coverage-type-icon {
    height: 1rem;
  }
}
</style>
