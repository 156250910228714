import gql from "graphql-tag";
import { orgBenefitFragment, orgFragment } from "../fragments";

export default {
  upsertMutation: gql`
    mutation upsertOrg(
      $id: ID
      $address: String!
      $name: String!
      $pincode: String!
      $meta: JSONObject!
      $batchConfig: JSONObject
      $status: String
      $preferences: JSONObject
    ) {
      upsertOrg(
        input: {
          id: $id
          name: $name
          address: $address
          pincode: $pincode
          meta: $meta
          batchConfig: $batchConfig
          status: $status
          preferences: $preferences
        }
      ) {
        org {
          ...Org
        }
      }
    }
    ${orgFragment}
  `,
  exportDataMutation: gql`
    mutation exportEmployeeData($orgId: ID, $meta: JSONObject) {
      exportEmployeeData(input: { orgId: $orgId, meta: $meta }) {
        message
      }
    }
  `,
  bulkEmailSyncMutation: gql`
    mutation bulkEmailSync($orgIds: [ID], $meta: JSONObject) {
      bulkEmailSync(input: { orgIds: $orgIds, meta: $meta }) {
        status
      }
    }
  `,
  addBenefitConnectionMutation: gql`
    mutation m1($benefitId: ID!, $orgId: ID!, $meta: JSONObject, $orgEntities: [ID]) {
      addOrgToBenefit(input: { benefitId: $benefitId, orgId: $orgId, meta: $meta, orgEntities: $orgEntities }) {
        orgBenefitEdge {
          ...OrgBenefitEdge
        }
      }
    }
    ${orgBenefitFragment}
  `,
  deleteBenefitConnectionMutation: gql`
    mutation m2($benefitId: ID!, $orgId: ID!) {
      deleteOrgFromBenefit(input: { benefitId: $benefitId, orgId: $orgId }) {
        deletedCount
      }
    }
  `,
  unlinkBenefitConnectionMutation: gql`
    mutation unlinkBenefitConnection($benefitId: ID!, $orgId: ID!) {
      unlinkBenefitConnection(input: { benefitId: $benefitId, orgId: $orgId }) {
        success
        userBenefitRemoveCount
        dependentBenefitRemoveCount
      }
    }
  `,
  resyncOrgOnboardingStageTasks: gql`
    mutation resyncOrgOnboardingStageTasks($orgId: ID!, $orgEntityId: ID) {
      resyncOrgOnboardingStageTasks(input: { orgId: $orgId, orgEntityId: $orgEntityId }) {
        success
      }
    }
  `,
  syncOrgJobGrades: gql`
    mutation syncOrgJobGrades($orgId: ID!, $userId: ID) {
      syncOrgJobGrades(input: { orgId: $orgId, userId: $userId }) {
        success
      }
    }
  `,
  upsertOrgJobGrades: gql`
    mutation upsertOrgJobGrades($orgId: ID!, $jobGrades: [JSONObject]!) {
      upsertOrgJobGrades(input: { orgId: $orgId, jobGrades: $jobGrades }) {
        success
      }
    }
  `,
  deleteOrgJobGrade: gql`
    mutation deleteOrgJobGrade($orgId: ID!, $jobGrade: String!) {
      deleteOrgJobGrade(input: { orgId: $orgId, jobGrade: $jobGrade }) {
        success
      }
    }
  `,

  startEducationEmailsJourney: gql`
    mutation startEducationEmailsJourney($orgId: ID!) {
      startEducationEmailsJourney(input: { orgId: $orgId }) {
        success
      }
    }
  `,

  cancelEducationEmailsJourney: gql`
    mutation cancelEducationEmailsJourney($orgId: ID!) {
      cancelEducationEmailsJourney(input: { orgId: $orgId }) {
        success
      }
    }
  `,
};
