import gql from "graphql-tag";
import { policyDataCheckBatchFragment } from "./policyDataCheckBatch";

export const policyDataCheckErrorFragment = gql`
  fragment PolicyDataCheckError on PolicyDataCheckError {
    id
    org {
      id
      name
    }
    benefit {
      id
      name
      status
    }
    user {
      id
      name
      email
      meta
    }
    dependent {
      id
      name
    }
    policyDataCheckBatch {
      ...PolicyDataCheckBatch
    }
    relation
    errorType
    status
    meta
    createdAt
    updatedAt
  }
  ${policyDataCheckBatchFragment}
`;
