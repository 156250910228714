export const ComplianceConsentType = {
  COOKIE: "cookie",
  POLICY: "policy",
};

export const ComplianceConsentSource = {
  PRIVACY_PAGE: "PrivacyPage",
  PROFILE_PAGE: "ProfilePage",
};

export const UserComplianceConsentStatus = {
  ACCEPTED: "Accepted",
  REJECTED: "Rejected",
};
