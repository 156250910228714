<template lang="pug">
b-popover(
  v-bind="$attrs",
  v-on="$listeners",
  :target="target",
  :show.sync="showPopover",
  @shown="popoverTimeout",
  :triggers="triggers",
  custom-class="nova-popover")
  template(v-slot:title, v-if="showHeader")
    slot(name="title")
      .font-weight-medium {{ title }}
  template(v-slot:default)
    slot(name="default")
      template(v-if="!showHeader && message")
        .d-flex.mt-3
          n-icon.mr-1(:name="icon", :size="1")
          .span.text-gray-900 {{ message }}
</template>

<script>
export default {
  name: "NPopover",
  props: {
    title: {
      type: String,
      default: "Mandatory Title",
    },
    popoverVariant: {
      // TODO: dark variant to be implemented when required
      type: String,
      default: "light",
    },
    triggers: {
      type: String,
      default: "",
      validator: (propValue) => {
        const isValidTrigger = ["hover", "click"].includes(propValue);
        return isValidTrigger || !propValue;
      },
    },
    target: {
      type: String,
      default: "",
    },
    message: {
      type: String,
      default: "",
    },
    showHeader: {
      type: Boolean,
      default: true,
    },
    icon: {
      type: String,
      default: "",
    },
    timeout: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      showPopover: false,
    };
  },
  methods: {
    popoverTimeout() {
      if (this.timeout != null) {
        setTimeout(() => {
          this.showPopover = false;
        }, this.timeout);
      }
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/_variables.scss";
.nova-popover {
  &.popover {
    border: none !important;
    border-radius: $border-radius-md;
    box-shadow: $box-shadow-lg;
    max-width: 25rem;
    .arrow::before {
      border: none !important;
    }
  }

  .popover-header {
    font-family: Inter;
    font-size: 0.875rem;
    font-weight: 500 !important;
    color: $gray-900;
    border: none !important;
    background-color: white !important;
    padding: 1rem;
    padding-bottom: 0.5rem !important;
  }

  .popover-body {
    font-size: 0.75rem;
    color: $gray-800;
    padding: 1rem;
    padding-top: 0 !important;
    overflow: hidden;
    border-radius: $border-radius-md;
  }
}
</style>
