import { mapGetters } from "vuex";
import mobileApp from "../../../mobileApp";
import resDefs from "../../admin/definitions";
import { TopupRoutes } from "../../../../common/enums/topupRoutes.enum";
import { AcceptedRelations } from "../../../../common/enums";
import { validateSpouseAgeSuperTopup, validateParentAgeSuperTopup } from "../../../../utils/validators";
import { getDeductibleToSIMap } from "./utils";
import utils from "@/utils";

export default {
  data() {
    return {
      isValid: false,
      retailPolicyList: [],
      deductibleToSiMap: {},
    };
  },
  async created() {
    this.$apollo.addSmartQuery("retailPolicies", {
      query: resDefs.benefits.listQuery,
      skip: () => !this.retailPolicyList,
      variables: {
        filter: {
          orgId: this.$store.state.user.org.id,
          type: "super-topup",
          includeGlobalBenefits: true,
          skipRetailPolicies: true,
          fromUserPage: true,
        },
      },
      update: (data) => {
        this.retailPolicyList = data.benefits?.edges?.map((benefit) => benefit.node);
        this.deductibleToSiMap = getDeductibleToSIMap(this.retailPolicyList);
      },
      fetchPolicy: "no-cache",
    });
  },
  watch: {},
  methods: {
    isPullUpDisabled() {
      return !(
        [TopupRoutes.POLICY_SUMMARY, TopupRoutes.POLICY_DETAILS].includes(this.currentStep) &&
        (this.isNovaPlusSelected || this.isNovaExtendedSelected)
      );
    },
    getFormattedINRWithFloatingPoint(value, withRupeeSym) {
      return utils.getFormattedINRWithFloatingPoint(value, withRupeeSym);
    },
    getPlusLives() {
      const members = [];
      const user = this.$store.state.user;
      members.push({
        name: user.name,
        relation: AcceptedRelations.SELF,
        dob: user.dob,
        id: user.id,
      });
      return members;
    },
    getSpouseFromStore() {
      const spouse = this.$store.state.user.dependents?.find((dep) => dep.relation === AcceptedRelations.SPOUSE);
      if (spouse) {
        return {
          name: spouse.name,
          relation: AcceptedRelations.SPOUSE,
          dob: spouse.dob,
          id: spouse.id,
          gender: spouse.gender,
        };
      }
    },
    getChildrenFromStore() {
      const children = this.$store.state.user.dependents
        ?.filter((dep) => dep.relation === AcceptedRelations.CHILD)
        .slice(0, 2);
      return children.map((child) => ({
        name: child.name,
        relation: AcceptedRelations.CHILD,
        dob: child.dob,
        id: child.id,
        gender: child.gender,
      }));
    },
    getParentsFromStore() {
      const members = [];
      const parents = this.$store.state.user.dependents?.filter((dep) => dep.relation === AcceptedRelations.PARENT);
      for (const parent of parents) {
        members.push({
          name: parent.name,
          relation: AcceptedRelations.PARENT,
          dob: parent.dob,
          id: parent.id,
          gender: parent.gender,
        });
      }
      return members;
    },
    getNextStage(currentStep) {
      if (currentStep === TopupRoutes.ADD_MEMBERS) return TopupRoutes.POLICY_DETAILS;
      else if (currentStep === TopupRoutes.POLICY_DETAILS) return TopupRoutes.NOMINEE_DETAILS;
      else if (currentStep === TopupRoutes.NOMINEE_DETAILS) return TopupRoutes.CONTACT_DETAILS;
      else if (currentStep === TopupRoutes.CONTACT_DETAILS) return TopupRoutes.POLICY_SUMMARY;
      else if (currentStep === TopupRoutes.POLICY_SUMMARY) return TopupRoutes.PAYMENT_CALLBACK;
    },
    getPreviousStep(currentStep) {
      if (currentStep === TopupRoutes.POLICY_DETAILS) return TopupRoutes.ADD_MEMBERS;
      else if (currentStep === TopupRoutes.NOMINEE_DETAILS) return TopupRoutes.POLICY_DETAILS;
      else if (currentStep === TopupRoutes.CONTACT_DETAILS) return TopupRoutes.NOMINEE_DETAILS;
      else if (currentStep === TopupRoutes.POLICY_SUMMARY) return TopupRoutes.CONTACT_DETAILS;
      else if (currentStep === TopupRoutes.PAYMENT_CALLBACK) return TopupRoutes.POLICY_SUMMARY;
    },
    isApp() {
      return mobileApp.isApp;
    },
    isMobileBrowser() {
      return this.isMobileView();
      return (utils.mobileCheck() || utils.tabCheck()) && !this.isApp;
    },
    isMobileView() {
      // checking for lg
      return window.innerWidth < 992;
    },
    isMobileBrowsing() {
      return this.isApp() || this.isMobileBrowser();
    },
    isParentValid(parent) {
      const isValid = parent.name && parent.dob && parent.gender;
      if (!isValid) {
        return false;
      }
      const ageValidationErrors = validateParentAgeSuperTopup(parent.dob);
      if (ageValidationErrors.length > 0) {
        return false;
      }
      return true;
    },
    isPlusDependentValid(dep) {
      const isValid = dep.name && dep.dob && dep.gender;
      if (!isValid) {
        return false;
      }
      if (dep.relation === AcceptedRelations.SPOUSE) {
        const ageValidationErrors = validateSpouseAgeSuperTopup(dep.dob);
        if (ageValidationErrors.length > 0) {
          return false;
        }
      }
      return true;
    },
    allMembersSelected() {
      return (
        this.superTopupProspectState.plusDependents.every((life) => life.checked) &&
        this.superTopupProspectState.parents.every((parent) => parent.checked) &&
        this.superTopupProspectState.selectedLives.length === this.getPlusLives().length
      );
    },
    getAllSelectedPlusMembers() {
      return [
        ...this.superTopupProspectState.selectedLives.map((life) => life.name),
        ...this.superTopupProspectState.plusDependents.filter((life) => life.checked).map((life) => life.name),
      ];
    },
    getAllSelectedExtendedMembers() {
      return [...this.superTopupProspectState.parents.filter((parent) => parent.checked).map((parent) => parent.name)];
    },
  },
  computed: {
    ...mapGetters(["superTopupProspectState"]),
    novaPlusPremiumDetails() {
      return this.superTopupProspectState.novaPlusPremiumDetails;
    },
    novaExtendedPremiumDetails() {
      return this.superTopupProspectState.novaExtendedPremiumDetails;
    },
    getPlusPolicy() {
      return this.retailPolicyList.find((item) =>
        item.chargesConfig.rater.config.some((configItem) => configItem.superTopUpFamilyDefinition.includes("A"))
      );
    },
    getExtendedPolicy() {
      return this.retailPolicyList.find((item) =>
        item.chargesConfig.rater.config.some((configItem) => configItem.superTopUpFamilyDefinition.includes("P"))
      );
    },
    isNovaPlusSelected() {
      return this.superTopupProspectState.isNovaPlusSelected;
    },
    isNovaExtendedSelected() {
      return this.superTopupProspectState.isNovaExtendedSelected;
    },
    currentStep() {
      const parts = this.$route.path.split("/");
      return parts.pop();
    },
    currentStepIndex() {
      const parts = this.$route.path.split("/");
      const lastPart = parts.pop();
      return Object.values(TopupRoutes).indexOf(lastPart);
    },
    totalPremium() {
      const totalCost =
        (this.novaPlusPremiumDetails?.premium || 0) +
        (this.novaPlusPremiumDetails?.gst || 0) +
        (this.novaExtendedPremiumDetails?.premium || 0) +
        (this.novaExtendedPremiumDetails?.gst || 0);
      this.$store.commit("updateSuperTopupProspectStateMeta", { totalCost });
      return totalCost;
    },
  },
};
