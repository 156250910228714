import { onError as onApolloError } from "apollo-link-error";
import { deleteBundleCache } from "../../utils";
import { logoutActiveUser, promiseToObservable } from "./../auth";
import { store } from "./../../portal/store";
import utils, { sleep } from "@/utils";
import router from "@/portal/router";
const SUPPRESSED_ERROR_CALLS = ["authenticateWithRefreshToken"];
const NO_ERROR_TOAST_MUTATIONS = ["getShortenedEcardUrl"];
export const errorMiddleware = onApolloError((error) => {
  if (store.state.majorVersionMismatch) {
    deleteBundleCache();
  }
  console.error(`got apollo error: `, error);
  if (error.networkError?.message === "Failed to fetch") {
    // Retry api call after 500ms
    // this is helpful in cases where
    // multiple api calls were made and accesstoken were expired
    // it'll do retry after fail
    return promiseToObservable(sleep(100)).flatMap(() => error.forward(error.operation));
  } else if (error.networkError?.response?.status === 401) {
    console.error("Force logging out (1)");
    error.accessTokenTimestamp = store?.state?.accessTokenExpiry?.toString();
    error.currentTimestamp = new Date().toString();
    window.posthog.capture("force_logout_1", {
      error,
    });
    handleInvalidToken();
  } else if (error.networkError?.response?.status === 409) {
    store.commit("updateMajorVersionMismatch", true);
    navigator.serviceWorker.getRegistration().then((registration) => {
      if (registration?.waiting) {
        registration.waiting.postMessage({ type: "SKIP_WAITING" });
      }
    });
  } else if (error.networkError?.response?.status === 451) {
    // set the flag to false to redirect to Privacy settings Page
    store.commit("setCookieAndConsentFlag", false);
    router.push({ path: "/privacy-settings" });
  } else {
    /*
      There're some network calls we don't want to throw
      error for, for example :- refreshToken, refreshToken either
      feels like nothing happened under the hood, if everything is good
      or it just redirects you to login page
    */
    const operation = error?.response?.data ? Object.keys(error.response.data)[0] : "";
    if (!SUPPRESSED_ERROR_CALLS.includes(operation)) {
      console.error("handling apollo errors");
      if ([...store.getters.getNoErrorToastMutations, ...NO_ERROR_TOAST_MUTATIONS].includes(operation)) return;
      utils.handleApolloErrors(error, store);
    } else {
      console.error("Force logging out (2)");
      window.posthog.capture("force_logout_2", {
        error,
      });
      return promiseToObservable(logoutActiveUser(false)).flatMap(() => error.forward(error.operation));
    }
  }
});

const handleInvalidToken = () => {
  router.push({ path: "/login/logout" });
};
