export const acceptedProductTypes = [{ id: "checkup_packages", name: "Checkup Packages" }];

export const acceptedTaxTypes = [
  { id: "none", name: "None" },
  { id: "cgst-sgst", name: "CGST, SGST" },
  { id: "igst", name: "IGST" },
  { id: "ugst", name: "UGST" },
];

export const acceptedTaxRate = [
  { id: 0, name: "0%" },
  { id: 5, name: "5%" },
  { id: 12, name: "12%" },
  { id: 18, name: "18%" },
  { id: 28, name: "28%" },
];

export const acceptedProductStatus = [
  { id: "active", name: "Active" },
  { id: "out-of-stock", name: "Out of Stock" },
  { id: "deleted", name: "Deleted" },
  { id: "inactive", name: "Inactive" },
];

export const NovaProductType = {
  CHECKUP_PACKAGES: "checkup_packages",
  SUPER_TOPUP: "super-topup",
};

export const NovaProductStatus = {
  ACTIVE: "active",
  INACTIVE: "inactive",
  OUT_OF_STOCK: "out-of-stock",
  DELETED: "deleted",
};
