import gql from "graphql-tag";
import { jobFragment } from "../fragments";

export default {
  resyncInsurerBatchesStatus: gql`
    mutation resyncInsurerBatchesStatus($batchId: ID!) {
      resyncInsurerBatchesStatus(input: { batchId: $batchId }) {
        job {
          ...Job
        }
      }
    }
    ${jobFragment}
  `,
};
