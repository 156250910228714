<template lang="pug">
n-text-area(
    v-model="value",
    v-bind="schema",
    :state="state",
    :validationMessage="validationMessage")
</template>

<script>
import { abstractField } from "vue-form-generator";
import NTextArea from "@/components/NovaTextArea.vue";

export default {
  components: {
    NTextArea,
  },
  mixins: [abstractField],
  computed: {
    validationMessage() {
      if (this.errors.length == 0) {
        return "";
      } else {
        for (const err of this.errors) {
          if (typeof err === "string") return err;
          else if (typeof err === "object" && !err.isWarning) return err.message;
        }
      }
    },
    state() {
      let hasWarning = false;
      for (const err of this.errors) {
        if (typeof err === "string" || (typeof err === "object" && !err.isWarning)) return "error";
        else if (typeof err === "object" && err.isWarning) hasWarning = true;
      }
      return hasWarning ? "warning" : "";
    },
  },
};
</script>
