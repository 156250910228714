import { getIdSelectorQuery } from "../../queries";

export default {
  schema: {
    fields: [
      {
        model: "user",
        type: "id-selector",
        queryName: "users",
        gqlQuery: getIdSelectorQuery("User"),
        styleClasses: "d-inline-flex col-md-6 flex-column",
        queryVariables: () => ({
          limit: 10,
          offset: 0,
          filter: {
            fetchDeletedUsers: true,
          },
        }),
        label: `<span style="user-select:none;">User Name & ID<span style="color:red">*</span></span>`,
        placeholder: "-None-",
        required: true,
        validator: "required",
        showSelected: true,
        getSubTitle: (value) => (value.status === "deleted" ? "deleted" : null),
        getNameExtension: (value) => `(${value.email})`,
      },
      {
        model: "user.name",
        type: "input",
        inputType: "text",
        styleClasses: "d-inline-flex col-md-6 flex-column",
        label: `Primary Insured Name <span style="color:red">*</span>`,
        placeholder: "-",
        required: true,
        readonly: true,
      },
      {
        model: "org",
        type: "id-selector",
        queryName: "orgs",
        styleClasses: "d-inline-flex col-md-6 flex-column",
        gqlQuery: getIdSelectorQuery("Org"),
        getSubTitle: (o) => o.name,
        label: `Brand & Organisation Name<span style="color:red">*</span>`,
        placeholder: "-None-",
        validator: "required",
        required: true,
      },
      {
        model: "user.meta.contactNumber",
        type: "inline-input",
        label: "Registered Phone Number",
        inputType: "tel",
        styleClasses: "d-inline-flex col-md-6 flex-column",
        readonly: true,
        required: true,
      },
      {
        model: "user.dob",
        type: "datepicker",
        label: "Date of Birth",
        min: "1900-01-01",
        max: "2100-12-31",
        styleClasses: "d-inline-block col-md-6 flex-column",
        readonly: true,
        required: true,
      },
      {
        model: "user.meta.employeeId",
        type: "input",
        inputType: "text",
        styleClasses: "d-inline-block col-md-6 flex-column",
        label: `Employee ID<span style="color:red">*</span>`,
        placeholder: "-",
        required: true,
        readonly: true,
      },
      {
        model: "uhid",
        type: "input",
        inputType: "text",
        styleClasses: "d-inline-flex col-md-6 flex-column",
        label: `UHID of Primary Insured`,
        placeholder: "-",
        readonly: true,
      },
      {
        model: "meta.endorsementNote",
        type: "textArea",
        rows: 2,
        label: "Notes for Nova Endorsement team",
        styleClasses: "d-inline-flex col-md-6 flex-column",
        required: false,
        placeholder: "-",
      },
      {
        model: "orgEntity",
        type: "id-selector",
        queryName: "orgEntities",
        styleClasses: "d-inline-flex col-md-6 flex-column",
        gqlQuery: getIdSelectorQuery("OrgEntity"),
        queryVariables: () => ({ filter: {} }),
        getSubTitle: (o) => o.name,
        label: `Organisation Entity Name`,
        placeholder: "-None-",
        visible: false,
      },
    ],
  },
};
