import { ClaimCategory } from "../claimCategory";
import { ClaimType } from "../claimType";

export const claimTypeToClaimCategory = new Map();

claimTypeToClaimCategory.set(ClaimType.CASHLESS, [ClaimCategory.HC]);

claimTypeToClaimCategory.set(ClaimType.REIMBURSEMENT, [
  ClaimCategory.HC,
  ClaimCategory.HC_PPHC,
  ClaimCategory.OPD,
  ClaimCategory.PARTIAL,
  ClaimCategory.PPHC,
]);
