<template lang="pug">
div
  span.font-xs Showing
    n-button.ml-2.mr-1(buttonText="All", :variant="allFilters['all'] ?'dark':'outline-dark'", size="sm",
      @click="handleFilterClick({type:'all', apply: !allFilters['all'] })")
    n-button.mx-1(buttonText="Additions", imageIcon="add-user",
      :variant="allFilters['add'] ?'dark':'outline-dark'", size="sm",
      @click="handleFilterClick({type:'add', apply: !allFilters['add']})")
    n-button.mx-1(buttonText="Deletions", imageIcon="remove-user",
      :variant="allFilters['delete'] ?'dark':'outline-dark'", size="sm",
      @click="handleFilterClick({type:'delete', apply: !allFilters['delete']})")
    n-button.mx-1(buttonText="Data Changes", imageIcon="rule", :variant="allFilters['update'] ?'dark':'outline-dark'",
      size="sm", @click="handleFilterClick({type:'update', apply: !allFilters['update']})")
    //- span.mx-3.text-gray-400 |
    //- span.mr-2.text-gray-700.font-sm.font-weight-medium No filters applied
    //- n-button.mx-1(buttonText="Filters", imageIcon="filter", variant="outline-dark" size="sm")
    //- n-button.mx-1(imageIcon="more", variant="outline-dark" size="sm")
</template>

<script>
import NButton from "@/components/NovaButton.vue";
export default {
  name: "FiltersPanel",
  components: {
    NButton,
  },
  data() {
    return {
      allFilters: {
        all: true,
        add: false,
        delete: false,
        update: false,
      },
    };
  },
  methods: {
    handleFilterClick(filter) {
      this.allFilters.all = false;
      this.allFilters.add = false;
      this.allFilters.delete = false;
      this.allFilters.update = false;

      this.allFilters[filter.type] = filter.apply;
      if (
        Object.keys(this.allFilters).every(
          (filter) => this.allFilters[filter] === false
        )
      ) {
        this.allFilters.all = true;
      }
      this.$emit("filterChange", this.allFilters);
    },
  },
};
</script>
