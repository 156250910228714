<template lang="pug">
.wrapper
  .main-panel.full-page(:class="isStoneBackground ? 'bg-stone' : ''", id="main-panel")
    contact-support(v-if="showContactSupport", :isSidebarExpanded="isSidebarExpanded")
    n-top-navbar#nova-navbar.sticky-md.top-navbar(v-if="showTopNav", @go-back="backHandler")
      template(v-slot:left, v-if="isLoggedIn")
        template(v-if="isCheckupAdmin && !showProgressIndicator")
          b-nav-item.d-none.d-md-block(
            to="/external-checkup-booking",
            :class="$route.path.includes('/external-checkup-booking') ? 'nav-active-dashboard' : ''")
            .nav-item-text.p-lg-3.p-sm-2(:class="itemColor")
              i.icon-star
              span.ml-2 AHC Booking Module
        template(v-else-if="showProgressIndicator")
          progress-indicator.my-2.pb-0.flex-grow-1(:currentStepNumber="getCurrentStep()")
        template(v-else-if="!isPrivacySettingsPage")
          b-nav-item.d-none.d-md-block(
            to="/dashboard",
            v-if="isLoggedIn",
            :class="$route.path.includes('/dashboard') ? 'nav-active-dashboard' : ''")
            .nav-item-text.p-lg-3.p-sm-2(:class="itemColor")
              i.icon-home-1
              span.ml-2 Home
          b-nav-item.d-none.d-md-block(
            to="/fitness",
            v-if="isLoggedIn && getFeatureFlags['FITNESS']"
            :class="$route.path.startsWith('/fitness')?'nav-active':''")
            .nav-item-text.p-lg-3.p-sm-2(:class="itemColor")
              i.icon-running
              span.ml-2 Fitness
          b-nav-item.d-none.d-md-block(
            to="/user/claims",
            v-if="isLoggedIn && isClaimsTabAllowed"
            :class="$route.path.startsWith('/user/claims')?'nav-active':''")
            .nav-item-text.p-lg-3.p-sm-2(:class="itemColor")
              i.icon-claims-1
              span.ml-2 Claims
          b-nav-item.d-none.d-md-block(
            v-if="isLoggedIn",
            :class="$route.path==='/support'?'nav-active':''",
            @click="handleSupport")
            .nav-item-text.p-lg-3.p-sm-2(:class="itemColor")
              i.icon-support-1
              span.ml-2 Support
          b-nav-item.d-none.d-md-block(
            to="/org-admin",
            v-if="isOrgAdmin || isOrgEntityAdmin",
            :class="$route.path.startsWith('/org-admin')?'nav-active':''")
            .nav-item-text.p-lg-3.p-sm-2(:class="itemColor")
              i.icon-city
              span.ml-2 Admin
          b-nav-item.d-none.d-md-block(to="/admin", v-if="isAdmin" :class="$route.path.startsWith('/admin')?'nav-active':''")
            .nav-item-text.p-lg-3.p-sm-2(:class="itemColor")
              i.icon-star
              span.ml-2 Super Admin
      template(v-slot:middle, v-if="$route.path!=='/dashboard'")
        b-navbar-nav.d-md-inline-flex.my-3(:class="getCurrentNavItem.icon ? 'mx-auto': 'mr-auto'")
          b-nav-item.d-block.d-md-none
            .navbar-header-text.font-weight-semibold.text-gray-900
              n-icon.align-bottom(:name="getCurrentNavItem.icon", :size="1.4")
              span.font-lg(:class="getCurrentNavItem.icon ? 'ml-2': ''") {{getCurrentNavItem.name}}

      template(v-slot:right, v-if="!showProgressIndicator && !isPrivacySettingsPage")
        .d-flex
          n-button.font-xs.shadow-none.ph-user-nav-open-app-button(
            v-if="isLoggedIn && isMobileBrowser && !$route.path.includes('/checkup')",
            variant="light",
            size="xs",
            buttonText="Open App",
            imageIcon="download",
            @click="openAppOrContinue('app')")
          .align-items-center(v-else-if="isLoggedIn && isApp && $route.path.includes('/fitness')", @click="gotoProfileSection")
            n-avatar(
              :size="2.25",
              :name="user.name",
              :image="user.photoFile?user.photoFile.url:null")

        .nav-item-text.ml-lg-4.ml-2.py-2.d-none.d-md-block.shadow-none(v-if="isLoggedIn", :class="itemColor")
          .bg-mustard-200.rounded-circle
            .font-md.font-weight-semibold.text-mustard-800.dropdown.font-inter
              b-nav-item-dropdown.profile-dropdown-container.shadow-none(
                v-if="isLoggedIn",
                right,
                toggle-class="p-0",
                no-caret)
                template(slot="button-content")
                  n-avatar.profile-dropdown-container(
                    :size="2.2",
                    :name="user.name",
                    :image="user.photoFile?user.photoFile.url:null",
                    )
                b-dropdown-header#dd-header.font-hc Account
                b-dropdown-item.px-2(to="/user/profile")
                  n-icon.dropdown-item-icon.align-middle(name="user-id")
                  span.dropdown-item-label Your Details
                b-dropdown-item.px-2(to="/user/dependents")
                  n-icon.dropdown-item-icon.align-middle(name="users")
                  span.dropdown-item-label Dependents
                b-dropdown-divider.px-2
                b-dropdown-item.px-2(to="/login/logout")
                  n-icon.dd-danger-icon.align-middle(name="sign-out")
                  span.dd-danger-text Sign Out
                b-dropdown-item.px-2(v-if="isImposter", @click="revertToOriginalAccount")
                  n-icon.dd-danger-icon.align-middle(name="sign-out")
                  span.dd-danger-text Switch Back
                .dropdown-triangle
        n-button.px-4(v-else, variant="dark", size="sm", to="/login", buttonText="Login")
    // side nav
    div(v-if="!isTopNav && isLoggedIn")
      nova-side-navbar(
        :sideNavbarState="isSidebarExpanded",
        :navType="isAdminPage ? sideNavbarType.ADMIN : sideNavbarType.ORG_ADMIN",
        :currentNavName="getCurrentNavItem.name"
        @openSideNavbar="isSidebarExpanded = true",
        @closeSideNavbar="isSidebarExpanded = false",
        @toggleSideNavbar="isSidebarExpanded = !isSidebarExpanded")
      .heading(
        v-if="!isAdminPage && getCurrentNavItem.name!=='Analytics'",
        :class="{'content-margin': !isTopNav && !isSidebarExpanded, 'content-margin-open': !isTopNav && isSidebarExpanded}")
        .container.d-flex.align-items-center.my-5.text-tiber.position-relative(v-if="!$route.path.includes('org-admin/integrations')")
          n-icon.pr-2.pt-2(:name="getCurrentNavItem.icon", :pathCount="4", :size="2")
          h2.hb2.pt-2.mb-0 {{getCurrentNavItem.name}}
          .tpa-integration(v-if="$route.path.startsWith('/org-admin/claims') && tpaImageUrl && getFeatureFlags.DISPLAY_TPA_INTEGRATION_CUES")
            span.text-gray-900.font-md.font-weight-semibold Integration with
            img.tpa-image(:src="tpaImageUrl")
        .container.d-flex.align-items-center.my-5.text-tiber(v-else)
          span.h3.hb3.mb-0.text-gray-700 {{getCurrentNavItem.name}}
          n-icon.pr-2(name="chevron-right", :size="2")
          n-icon.pr-2.align-sub(
            :name="$options.integrationConstants[getIntegrationType]?.icon",
            :pathCount="$options.integrationConstants[getIntegrationType]?.pathCount", :size="2")
          h3.hb3.mb-0.text-gray-900 {{ $options.integrationConstants[getIntegrationType]?.title }}
    slot
      n-content(:class="{'cancel-content-spacer' : !isLoggedIn, 'content-margin': !isTopNav && !isSidebarExpanded, 'content-margin-open': !isTopNav && isSidebarExpanded, 'content-margin-small': showSmallTopMargin}")
    template(v-if="showFooter")
      n-footer(:class="{'content-margin': !isTopNav && !isSidebarExpanded, 'content-margin-open': !isTopNav && isSidebarExpanded, 'bg-white': isWhiteBackground, 'bg-stone': isStoneBackground, }")
    .d-block.d-md-none
      n-bottom-nav-bar(v-if="showBottomNav")
    bottom-sheet-modal(
      crossVariant="light",
      :id="appLinkingModalId",
      height="medium",
      hide-header,
      hide-footer,
      no-fade)
      template(v-slot:content)
        .px-4.pt-4.mt-2.text-center
          img.d-inline.ml-2.align-middle(
            :src="require('@/assets/images/insignia-grey-tiber.svg')",
            width="32")
          .mt-2.pt-2.text-gray-900.font-weight-semibold.font-lg We look better in our app!
          .mt-2.px-2.text-gray-700.font-weight-medium.font-sm
            | Your one stop solution to access all health and wellness benefits provided by your company
          n-button.mt-4.w-100.ph-user-drawer-open-in-app-button(
            variant="tiber",
            buttonText="Open in app",
            @click="openAppOrContinue('app')")
          .mt-3.w-100.p-3.cursor-pointer.ph-user-drawer-continue-in-browser-button(
            @click="openAppOrContinue('web')")
            .text-gray-900.font-weight-medium Not now
</template>

<script>
import { mapGetters, mapState } from "vuex";
import gql from "graphql-tag";
import moment from "moment";
import mobileApp from "../mobileApp";
import { BenefitStatus, OrgServicesOffered, PolicyTypes } from "../../common/enums";
import { MY_INFO } from "../../common/definitions/auth";
import privacyPageMixin from "../../mixins/privacyPage";
import ContactSupport from "./components/ContactSupport.vue";
import { benefitFragmentWithoutContent } from "./admin/fragments";
import { logoutImpersonatedUser } from "./../../common/auth";
import { sideNavbarType } from "./admin/constants";
import ProgressIndicator from "./checkup/components/ProgressIndicator.vue";
import utils, { setBackgroundScroll } from "@/utils";
import NTopNavbar from "@/layout/NovaTopNavbar.vue";
import NFooter from "@/layout/Footer.vue";
import NContent from "@/layout/Content.vue";
import NBottomNavBar from "@/layout/BottomNavBar.vue";
import NovaSideNavbar from "@/layout/NovaSideNavbar.vue";
import NAvatar from "@/components/Avatar.vue";
import NButton from "@/components/NovaButton.vue";
import BottomSheetModal from "@/components/BottomSheetModal.vue";
import { CheckupRoutes } from "@/common/enums";
export default {
  components: {
    NTopNavbar,
    NFooter,
    NContent,
    NAvatar,
    NBottomNavBar,
    NButton,
    NovaSideNavbar,
    ContactSupport,
    BottomSheetModal,
    ProgressIndicator,
  },
  mixins: [privacyPageMixin],
  data() {
    return {
      sideNavbarType: sideNavbarType,
      unreadNotifications: false,
      isSidebarExpanded: true, // set to false if SA sidenav needs to be closed by default
      appLinkingModalId: "app-linking-modal",
      tpaImageUrl: null,
    };
  },
  computed: {
    ...mapState(["user"]),
    ...mapGetters([
      "isLoggedIn",
      "isAdmin",
      "isOrgAdmin",
      "isOrgEntityAdmin",
      "ahcProspectState",
      "getFeatureFlags",
      "getBrandCustomizations",
      "isTopNav",
      "isImposter",
      "getDeepLinkTarget",
      "showSupportContainer",
    ]),
    showFooter() {
      return !this.$route.fullPath.includes("analytics");
    },
    showProgressIndicator() {
      return this.isCheckupBookingFormPage;
    },
    itemColor() {
      if (this.$route.path.includes("/dashboard")) {
        return "tiber-top-navbar";
      }
      return "white-top-navbar";
    },
    showTopNav() {
      if (
        this.isTopNav &&
        (this.isLoggedIn || this.$route.path.includes("/reset-password")) &&
        !this.$route.path.includes("/user/get-started") &&
        !this.$route.path.includes("/claim-process")
      ) {
        return true;
      }
      return false;
    },
    showBottomNav() {
      return (
        this.isLoggedIn &&
        !this.$route.path.startsWith("/org-admin") &&
        !this.$route.path.startsWith("/admin") &&
        !this.$route.path.startsWith("/checkup") &&
        !this.$route.path.includes("/claim-process")
      );
    },
    showContactSupport() {
      return !this.$route.path.startsWith("/checkup");
    },
    isClaimsTabAllowed() {
      return (
        this.getFeatureFlags?.CLAIMS_PORTAL_EXPERIENCE &&
        this.user?.org?.meta?.servicesOffered !== OrgServicesOffered.WELLNESS_ONLY
      );
    },
    isCheckupAdmin() {
      if (!this.user?.roles) return false;
      return this.user.roles.includes("checkup_admin");
    },
    getNameInitials: function () {
      return utils.getNameInitials(this.user?.name || "");
    },
    getCurrentNavItem: function () {
      let nav = { icon: "", name: "" };
      if (this.$route.path.startsWith("/dashboard")) {
        nav = { icon: "home-1", name: "Home" };
      } else if (this.$route.path.startsWith("/fitness")) {
        nav = { icon: "running", name: "Fitness" };
      } else if (this.$route.path.startsWith("/checkup")) {
        nav = { icon: "", name: "Annual Health Checkup" };
        if (this.$route.path.includes("booking-form")) {
          nav = { icon: "", name: "Book package for" };
          if (this.currentStep === CheckupRoutes.ADDRESS_SELECTION) {
            nav = { icon: "", name: "Select Address" };
          } else if (this.currentStep === CheckupRoutes.PROVIDER_SELECTION) {
            nav = { icon: "", name: "Select Provider & Slot" };
          } else if (this.currentStep === CheckupRoutes.CHECKOUT) {
            nav = { icon: "", name: "Review Details" };
          }
        }
      } else if (this.$route.path.startsWith("/user/claims")) {
        nav = { icon: "claims-1", name: "Claims" };
      } else if (this.$route.path.startsWith("/support")) {
        nav = { icon: "support-1", name: "Support" };
      } else if (this.$route.path.startsWith("/admin")) {
        nav = { icon: "star", name: "Super Admin" };
      } else if (this.$route.path.startsWith("/policy")) {
        nav = { icon: "hospital", name: "Health Insurance" };
      } else if (this.$route.path.includes("profile")) {
        nav = { name: "Profile" };
      } else if (this.$route.path.includes("topups")) {
        nav = { name: "Super Topups" };
      } else if (this.$route.path.includes("risk-assessments/report")) {
        nav = { name: "Report" };
      } else if (this.$route.path.includes("risk-assessments")) {
        nav = { name: "Assessments for you" };
      } else if (this.$route.path.startsWith("/org-admin")) {
        nav = { icon: "opacity-home-2", name: "Admin Dashboard" };
        if (this.$route.path.includes("org-admin/org-hierarchy")) {
          nav = { icon: "office", name: "Organization Hierarchy" };
        } else if (this.$route.path.includes("org-admin/org")) {
          nav = { icon: "opacity-organization", name: "Organization" };
        } else if (this.$route.path.includes("org-admin/integrations")) {
          nav = { icon: "opacity-integerations", name: "Integrations" };
        } else if (this.$route.path.includes("org-admin/benefits")) {
          nav = { icon: "opacity-benefits", name: "Benefits" };
        } else if (this.$route.path.includes("org-admin/files")) {
          nav = { icon: "document", name: "Documents" };
        } else if (this.$route.path.includes("org-admin/analytics")) {
          nav = { icon: "analytics", name: "Analytics" };
        } else if (this.$route.path.includes("org-admin/claims")) {
          nav = { icon: "opacity-claims-2", name: "Claims" };
        } else if (this.$route.path.includes("org-admin/auth")) {
          nav = { icon: "login", name: "Login Options" };
        } else if (this.$route.path.includes("org-admin/cd-accounts")) {
          nav = { icon: "opacity-endorsements", name: "CD Account" };
        } else if (this.$route.path.includes("org-admin/review-user-changes")) {
          nav = { icon: "opacity-endorsements", name: "Changes" };
        } else if (this.$route.path.includes("org-admin/members")) {
          nav = { icon: "opacity-employee-id", name: "Team" };
        } else if (this.$route.path.includes("org-admin/changes")) {
          nav = { icon: "opacity-endorsements", name: "Endorsements Overview" };
        } else if (this.$route.path.includes("org-admin/onboarding")) {
          nav = { icon: "opacity-endorsements", name: "Onboarding Overview" };
        } else if (this.$route.path.includes("org-admin/premium-details")) {
          nav = { icon: "", name: "" };
        } else if (this.$route.path.includes("org-admin/echoPostTemplates")) {
          nav = { icon: "opacity-engineering-2", name: "Echo Post Templates" };
        }
      } else {
        nav = this.$store.state.navigation.navHeader;
      }
      return nav;
    },
    getIntegrationType() {
      return this.$route.path.split("/").pop();
    },
    clientIntroVersion: function () {
      return utils.getClientIntroScreenVersion();
    },
    isGsuitePage: function () {
      return this.$route.path.includes("org-admin/integrations/gsuite");
    },
    currentStep() {
      return this.ahcProspectState?.currentStep;
    },
    isStoneBackground: function () {
      return (
        this.$route.path.includes("org-admin/integrations/gsuite") ||
        this.$route.path.includes("org-admin/members") ||
        this.$route.path.includes("dashboard")
      );
    },
    isWhiteBackground: function () {
      return this.$route.path.includes("external-checkup-booking") || this.isCheckupPage;
    },
    isCheckupPage() {
      return this.$route.path.includes("/checkup");
    },
    isCheckupBookingFormPage() {
      return this.$route.path.includes("/booking-form");
    },
    isApp() {
      return mobileApp.isApp;
    },
    isMobileBrowser() {
      return (utils.mobileCheck() || utils.tabCheck()) && !this.isApp;
    },
    showSmallTopMargin() {
      return this.$route.path.includes("/integrations/");
    },
    isAdminPage() {
      return this.$route.path.startsWith("/admin");
    },
  },
  watch: {
    isSidebarExpanded() {
      this.$store.commit("toggleNovaSideNavState", this.isSidebarExpanded);
      if (utils.mobileCheck()) {
        setBackgroundScroll(this.showSupportContainer || this.isSidebarExpanded);
      }
    },
    // use below hook instead if SA sidenav needs to be closed by default
    // $route(to, from) {
    // if (to.path.includes("/admin") && !from.path.includes("/admin")) this.isSidebarExpanded = false;
    // else if (to.path === "/org-admin") this.isSidebarExpanded = true;
    // },
  },
  created() {
    this.$options.integrationConstants = {
      gsuite: {
        icon: "google",
        title: "Google Workspace",
        pathCount: 4,
      },
      darwinbox: {
        icon: "darwinbox",
        title: "DarwinBox",
        pathCount: 10,
      },
      keka: {
        icon: "keka",
        title: "KEKA",
        pathCount: 6,
      },
      zohopeople: {
        icon: "zoho",
        title: "Zoho People",
        pathCount: 6,
      },
      zing: {
        icon: "zing",
        title: "ZingHR",
        pathCount: 10,
      },
      bamboohr: {
        icon: "bamboohr",
        title: "BambooHR",
        pathCount: 2,
      },
      greythr: {
        icon: "greythr",
        title: "greythr",
        pathCount: 2,
      },
    };
    if (this.$route?.query?.goToStore) {
      this.$router.replace({ query: null });
      if (utils.isUsingSafari() && !this.isApp) utils.redirectToStore();
    }
    if (this.isLoggedIn) {
      this.$apollo.addSmartQuery("profile", {
        query: MY_INFO,
        update(data) {
          const user = { ...data.me };
          if (data.me?.org) {
            user.orgId = data.me.org.id;
          }
          this.$store.commit("updateUser", user);

          /* profile object is not required as user from store is used instead.
          reason being the profile object is not updated during login resulting in
          a broken navbar if the user doesn't exist already in the cache. */
          return null;
        },
        fetchPolicy: "no-cache",
      });

      this.$apollo.addSmartQuery("MyOrg", {
        query: gql`
          query MyOrg {
            me {
              id
              org {
                id
                meta
                isAssessmentAvailable
                benefits {
                  node {
                    ...BenefitWithoutContent
                  }
                }
                orgEntities {
                  id
                  name
                  meta
                  address
                  pincode
                }
              }
            }
          }
          ${benefitFragmentWithoutContent}
        `,
        update(data) {
          const org = data.me.org;

          /** Setting the TPA image url to store */
          const gmcPolicy = org.benefits?.filter(
            (policy) =>
              policy.node.type === PolicyTypes.GMC &&
              policy.node.status !== BenefitStatus.DELETED &&
              policy.node.tpaClaimsLastSync
          );
          this.tpaImageUrl = gmcPolicy[0]?.node?.tpa?.s3Url;
          this.tpaClaimsSyncDate = moment(gmcPolicy[0]?.node?.tpaClaimsSyncDate).format("D MMM YYYY");
          this.$store.commit("setTpaImageUrl", this.tpaImageUrl);
          this.$store.commit("setTpaClaimsSyncDate", this.tpaClaimsSyncDate);
        },
      });
    }
  },
  mounted() {
    this.$nextTick(function () {
      window.addEventListener("resize", this.openSidebar);
      this.openSidebar();
      // use below line instead if SA sidenav needs to be closed by default
      // if (!this.$route.path.includes("/admin")) this.openSidebar();
    });
    if (
      !["onboarding_nova_email_link", "reset_password_verify"].includes(this.$route?.name) &&
      this.isMobileBrowser &&
      this.getDeepLinkTarget !== "web"
    ) {
      this.$bvModal.show(this.appLinkingModalId);
    }
    this.setNavBackground();
  },
  updated() {
    this.setNavBackground();
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.openSidebar);
    window.lottie?.destroy();
  },
  methods: {
    setNavBackground() {
      if (this.getFeatureFlags?.CUSTOM_STYLING) {
        const el = document.getElementById("nova-navbar");
        const brandColor = this.getBrandCustomizations?.brandColor || "#000";
        if (el?.className.includes("bg-tiber")) {
          el?.style.setProperty("background-color", brandColor, "important");
        } else {
          el?.style.removeProperty("background-color");
        }
      }
    },
    getCurrentStep() {
      switch (this.currentStep) {
        case CheckupRoutes.DEPENDENT_SELECTION:
          return 1;
        case CheckupRoutes.ADDRESS_SELECTION:
          return 2;
        case CheckupRoutes.PROVIDER_SELECTION:
          return 3;
        case CheckupRoutes.CHECKOUT:
          return 4;
        default:
          return 1;
      }
    },
    showSalesIqChat() {
      this.$store.commit("toggleSupportContainer");
    },
    handleSupport() {
      if (this.getFeatureFlags.SUPPORT_TICKET) {
        window.posthog.capture("support_button_clicked", {});
        this.$router.push("/support");
      } else {
        this.showSalesIqChat();
      }
    },
    openSidebar() {
      this.isSidebarExpanded = document.documentElement.clientWidth > 992;
    },
    async revertToOriginalAccount() {
      await logoutImpersonatedUser();
    },

    openAppOrContinue(target) {
      this.$store.commit("setDeepLinkTarget", { deepLinkTarget: target });
      this.$bvModal.hide(this.appLinkingModalId);
      if (target === "app") utils.redirectToApp(this.$route?.fullPath);
    },
    backHandler() {
      const previousStageMap = {
        [CheckupRoutes.ADDRESS_SELECTION]: CheckupRoutes.DEPENDENT_SELECTION,
        [CheckupRoutes.PROVIDER_SELECTION]: CheckupRoutes.ADDRESS_SELECTION,
        [CheckupRoutes.CHECKOUT]: CheckupRoutes.PROVIDER_SELECTION,
      };

      if (this.currentStep === CheckupRoutes.DEPENDENT_SELECTION) {
        this.$store.commit("resetAhcProspectState");
        window.history.length > 1 ? this.$router.go(-1) : this.$router.push("/");
      } else {
        const previousStage = previousStageMap[this.currentStep];
        const prospectState = { currentStep: previousStage };
        if (this.currentStep === CheckupRoutes.PROVIDER_SELECTION) {
          prospectState.selectedDateTimeSlot = {};
          prospectState.selectedProvider = {};
        }
        this.$store.commit("updateAhcProspectStateMeta", prospectState);
      }
    },
    gotoProfileSection() {
      this.$router.push("/user/profile");
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/_variables.scss";
@import "@/assets/styles/mixins/_breakpoints.scss";
.sticky-md {
  z-index: 1020;
  @include media-breakpoint-down(md) {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
.full-page {
  background: $portal-bg;
  .cancel-content-spacer {
    margin-bottom: 0;
  }
}
.nav-padding {
  padding: 0;
  margin-right: auto;
  margin-bottom: 0 !important;
  border-bottom: 1px solid $gray-300;
}
.nav-active-dashboard {
  border-bottom: 2px solid $white;
  .nav-item-text {
    color: $white !important;
  }
}
.nav-active {
  border-bottom: 2px solid $tiber;
  .nav-item-text {
    color: $tiber;
  }
}
.nav-item-text {
  color: $gray-700;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
}
.nav-notif-bg {
  position: relative;
  background: $gray-200;
  width: 2.25rem;
  height: 2.25rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.unread-notif {
  position: absolute;
  background: $red-500;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  top: 0px;
  right: 0px;
}
#dd-header {
  color: $gray-500;
  letter-spacing: 0.08em;
  padding: 0.2rem 0.7rem;
  font-size: 12px;
}
.profile-dropdown-container {
  .dropdown-item {
    padding: 0.5rem 0px;
    &:hover {
      background: $gray-200;
      .dropdown-item-icon,
      .dropdown-item-label {
        color: $gray-900;
      }
      .dd-danger-icon,
      .dd-danger-text {
        color: $red-900;
      }
    }
    &.active {
      background: $gray-300;
      .dropdown-item-label,
      .dropdown-item-icon {
        color: $gray-900;
      }
    }
  }
  .show {
    position: absolute;
    top: 45px;
    right: -5px;
  }
  &.notif .show {
    top: 2.5rem;
  }
}
.dropdown-item-icon {
  color: $gray-600;
  font-size: 1rem;
  padding: 0 0.5rem;
}
.dropdown-item-label {
  color: $gray-800;
  display: inline;
  padding: 0;
  font-weight: 500;
}
.dd-danger-icon {
  font-size: 1rem;
  padding: 0 0.5rem;
  color: $red-600;
}
.dd-danger-text {
  color: $red-600;
  font-weight: 500;
}
.dropdown-triangle {
  width: 0;
  height: 0;
  position: absolute;
  top: -0.5rem;
  right: 1rem;
  border-width: 0 8px 10px 8px;
  border-color: transparent transparent #ffffff transparent;
  border-style: solid;
  filter: drop-shadow(0px -2px 0px rgba(163, 163, 163, 0.29));
  &.notif {
    top: -0.5rem;
    right: 0.5rem;
  }
}
.content-margin {
  padding-left: 70px !important;
  transition: padding-left 0.3s ease;
}
.content-margin-open {
  padding-left: 220px !important;
  transition: padding-left 0.3s ease;
}
.tooltip-margin {
  margin-right: -12rem !important;
}
@media (max-width: 992px) {
  .nav-item-text {
    padding-bottom: 0rem;
  }
  .content-margin,
  .content-margin-open {
    padding-left: 70px !important;
  }
}
@media (max-width: 768px) {
  .navbar-header-text {
    display: flex;
    justify-content: center;
    align-items: center;
    color: $gray-900;
    font-weight: 600;
  }
  .content-margin,
  .content-margin-open {
    padding-left: 0 !important;
    margin-top: 7.5rem;
  }
  .content-margin-small {
    margin-top: 5.5rem !important;
  }
  .heading {
    display: none;
  }
}
.grecaptcha-badge {
  position: relative;
  z-index: 20;
  visibility: hidden !important;
}
</style>

<style lang="scss" scoped>
@import "@/assets/styles/mixins/_breakpoints.scss";
.full-page {
  @include media-breakpoint-down(md) {
    height: 100%;
  }
}
#pill-new {
  height: 1.4rem;
  width: 3.2rem;
}
.top-navbar {
  position: fixed;
  z-index: 10;
  margin-bottom: 0 !important;
  width: 100%;
}

.tiber-top-navbar {
  color: rgba($white, 0.6);
  &:hover {
    color: $white;
  }
}
.white-top-navbar {
  &:hover {
    color: $gray-900;
  }
}

.tpa-integration {
  position: absolute;
  right: 4rem;
}

.tpa-image {
  height: 3rem;
  margin-left: 1rem;
}
</style>
