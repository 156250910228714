<template lang="pug">
.device-health-integrations
  n-spinner(
    :active="loading",
    is-full-screen,
    spinner="ring",
    size="50",
    background-color="rgba(11,57,72,0.3)",
    duration="1")

  .px-3.pt-4.mt-2
    .font-weight-semibold.font-lg Connect Wearables/Apps
    .font-weight-medium.text-gray-800.pt-2
      | Select a fitness service listed below to connect or manage your fitness devices/apps that you use to track your fitness data.
    .font-weight-semibold.text-gray-800.pt-4 Wearables
    .integration-provider-card.bg-white.pt-3
      .d-flex.justify-content-between.align-items-center.py-3.cursor-pointer(
        v-for="integration in finalIntegrationsList",
        :key="integration.id",
        @click="openDetailsModal(integration)")
        .d-flex.align-items-center
          img.device-integration-image.mr-3(:src="require(`@/assets/images/${integration.image}`)")
          .text-gray-900.font-weight-semibold {{ integration.name }}
            n-chip.ml-2(
              v-if="integration.connected",
              textColor="teal-700",
              pill,
              :class="`bg-teal-100`") Connected
            n-chip.ml-2(
              v-else-if="!integration.active",
              textColor="gray-700",
              pill,
              :class="`bg-gray-200`") Coming Soon
        n-icon(name="chevron-right", :size="1.5")
          
  bottom-sheet-modal(
    crossVariant="light",
    :id="detailsModalId",
    size="xl",
    height="large",
    hide-header,
    hide-footer,
    no-fade)
    template(v-slot:content)
      .d-flex.w-100
        .p-4.w-100.w-md-50(v-if="integrationDetails.name")
          .font-weight-semibold.text-gray-900.font-lg.mb-4 Manage {{ integrationDetails.name }}
          img.mb-4(:src="require(`@/assets/images/${integrationDetails.image}`)", height="64", width="64")
          .mb-2(v-if="integrationDetails.connected")
            .d-flex.py-3.align-items-center
              n-icon(name="check", :size="1.5", variant="gray-900")
              .ml-3.font-weight-medium.text-gray-900 Your accounts are connected
          .font-weight-medium.text-gray-900 {{ integrationDetailsModalContent[integrationDetails.id].header }}
          .mt-2(v-for="point in integrationDetailsModalContent[integrationDetails.id].connectionPoints", :key="point.title")
            .d-flex.py-3.align-items-center
              n-icon(:name="point.icon", :size="1.5", variant="gray-900")
              .ml-3
                .font-weight-medium.text-gray-900.mb-2 {{ point.title }}
                .font-weight-medium.text-gray-800.font-sm {{ point.text }}
          n-button.w-100.mt-2(v-if="!integrationDetails.connected", variant="dark", buttonText="Connect", rightImageIcon="chevron-right", @click="clickHandler(integrationDetails)")
          .row.mt-2(v-else)
            .col-6
              n-button.w-100(variant="primary", buttonText="Go To Steps", rightImageIcon="chevron-right", @click="$router.push({ name: 'my_fitness' })")
            .col-6
              n-button.w-100(variant="dark", buttonText="Reconnect", rightImageIcon="chevron-right", @click="clickHandler(integrationDetails)")
            .col-12.mt-2
              n-button.w-100(variant="danger", buttonText="Disconnect", rightImageIcon="chevron-right", @click="disconnect(integrationDetails)")

  b-modal(
    :id="confirmationModalId",
    hide-header,
    hide-footer,
    no-close-on-backdrop,
    centered)
    .font-weight-medium Were you able to provide the requested permissions?
    .font-weight-semibold.py-2 OR
    .font-weight-medium Have you provided permissions before?
    .d-flex.w-100.justify-content-end.mt-3
      n-button.mr-2(variant="secondary", buttonText="No", size="sm", @click="performIntegration(integrationDetails)")
      n-button(variant="tiber", buttonText="Yes", size="sm", @click="performIntegration(integrationDetails)")
</template>

<script>
import moment from "moment";
import { mapState } from "vuex";
import resDefs from "../../../fitness/definitions";
import mobileApp from "../../../../mobileApp";
import { ngageDeviceIntegrationProvidersConfig } from "../../../admin/constants";
import { NgageDeviceIntegrationProviders } from "../../../../../common/enums/ngageDeviceIntegrationProviders.enum";
import NButton from "@/components/NovaButton.vue";
import NChip from "@/components/NovaChip.vue";
import BottomSheetModal from "@/components/BottomSheetModal.vue";
import utils from "@/utils";

export default {
  name: "NgageDeviceProviders",
  components: {
    NButton,
    NChip,
    BottomSheetModal,
  },
  props: {},
  data() {
    const daysToBeChecked = 3;
    const startDate = moment().startOf("day").subtract(daysToBeChecked, "days").format("YYYY-MM-DDTHH:mm:ssZ");
    const endDate = moment().format("YYYY-MM-DDTHH:mm:ssZ");
    return {
      ngageUserDetailsResDef: resDefs.ngageUserDetails,
      loading: false,
      ngageActivitiesByDate: [],
      startDate,
      endDate,
      daysToBeChecked,
      resDef: resDefs.ngageActivities,
      steps: 0,
      integrationsModalId: "integrations-modal-id",
      detailsModalId: "details-modal-id",
      confirmationModalId: "confirmation-modal-id",
      integrationsList: Object.values(ngageDeviceIntegrationProvidersConfig),
      downloadSteps: [
        {
          num: 1,
          text: "Download the Nova Benefits App",
        },
        {
          num: 2,
          text: "Tap on your Profile on top-right corner",
        },
        {
          num: 3,
          text: "Select the Wearables tab",
        },
      ],
      integrationDetails: {},
      integrationDetailsModalContent: {
        [NgageDeviceIntegrationProviders.IOS_HEALTH_KIT]: {
          header: "Nova Benefits can automatically add data from Apple Health to help you track your fitness.",
          connectionPoints: [
            {
              icon: "syncalt",
              title: "Connect",
              text: "To establish a connection tap Connect. Next, In the Health Access Page, you can choose what health data Nova Benefits has access for.",
            },
            {
              icon: "Cloud-sync",
              title: "Sync",
              text: "Only new activities finished after you enable the feature will be imported. Apps who have a custom integration with Nova Benefits will not be synced from Apple Health.",
            },
            {
              icon: "track-changes",
              title: "Track",
              text: "Track workouts on third-party apps and import them to your activity history in Nova Benefits.",
            },
          ],
        },
        [NgageDeviceIntegrationProviders.ANDROID_HEALTH_CONNECT]: {
          header:
            "Health Connect centralises health and fitness data and allows users to manage and view health metrics from different apps in one place.",
          connectionPoints: [
            {
              icon: "syncalt",
              title: "Connect",
              text: "Tap Connect to integrate Heath Connect with your NovaBenefits account.",
            },
            {
              icon: "Cloud-sync",
              title: "Sync",
              text: "Once connected, all your new activities will be automatically synced with Health Connect.",
            },
          ],
        },
      },
    };
  },
  computed: {
    ...mapState(["user"]),
    isApp() {
      return mobileApp.isApp;
    },
    platform() {
      return this.isApp ? (utils.isIos() ? "ios" : "android") : "web";
    },
    finalIntegrationsList() {
      let list = this.integrationsList.filter((integration) => {
        if (integration.os) {
          return this.platform === integration.os;
        } else {
          return true;
        }
      });
      if (this.user?.ngageUserDetail?.deviceIntegrationProvider) {
        list = list.map((integration) => {
          integration.connected = this.user.ngageUserDetail.deviceIntegrationProvider === integration.id;
          return integration;
        });
      }
      return list;
    },
  },
  created() {},
  methods: {
    openModal() {
      this.$bvModal.show(this.integrationsModalId);
    },
    openDetailsModal(integration) {
      if (!integration.active) {
        this.$store.commit("addToast", {
          variant: "success",
          message: `The ${integration.name} integration is coming soon!`,
        });
        return;
      }
      this.integrationDetails = integration;
      this.$bvModal.show(this.detailsModalId);
    },
    async initiateDeviceHealthIntegrationTemp() {
      const { status } = await mobileApp.request("INITIATE_INTEGRATION");
      return status;
    },

    async initiateDeviceHealthIntegration() {
      return new Promise((resolve) => {
        const requestPromise = mobileApp.request("INITIATE_INTEGRATION");

        const timeoutId = setTimeout(() => {
          resolve(false);
        }, 1000);

        requestPromise.then((result) => {
          clearTimeout(timeoutId);
          resolve(result.status || false);
        });
      });
    },
    async clickHandler(integration) {
      const status = await this.initiateDeviceHealthIntegration();
      if (status) {
        await this.performIntegration(integration);
      } else {
        this.$bvModal.hide(this.detailsModalId);
        this.loading = true;
        setTimeout(() => {
          this.$bvModal.show(this.confirmationModalId);
        }, 1000);
      }
    },
    async performIntegration(integration, preStatus = false) {
      this.loading = true;
      this.$bvModal.hide(this.confirmationModalId);
      let status = false;
      if (!preStatus) {
        status = await this.initiateDeviceHealthIntegration();
      } else {
        status = preStatus;
      }
      if (status) {
        const res = await this.$apollo.mutate({
          mutation: this.ngageUserDetailsResDef.upsertMutation,
          variables: {
            userId: this.$store.state.user.id,
            deviceIntegrationProvider: integration.id,
            meta: {},
          },
        });

        if (this.$store.state.user && res.data?.upsertNgageUserDetail?.ngageUserDetail?.id) {
          this.$store.commit("updateUser", {
            ...this.$store.state.user,
            ngageUserDetail: {
              id: res.data.upsertNgageUserDetail.ngageUserDetail.id,
              deviceIntegrationProvider: res.data.upsertNgageUserDetail.ngageUserDetail.deviceIntegrationProvider,
            },
          });

          this.integrationsList = this.integrationsList.map((integration) => {
            integration.connected =
              res.data.upsertNgageUserDetail.ngageUserDetail.deviceIntegrationProvider === integration.id;
            return integration;
          });
        }

        this.$store.commit("addToast", {
          variant: "success",
          message: `The ${integration.name} integration is complete!`,
        });
      } else {
        // handle unsuccesful case
      }
      this.loading = false;
    },

    async disconnect(integration) {
      this.loading = true;
      await this.$apollo.mutate({
        mutation: this.ngageUserDetailsResDef.deleteMutation,
        variables: {
          userId: this.$store.state.user.id,
          deviceIntegrationProvider: integration.id,
        },
      });

      if (this.$store.state.user) {
        this.$store.commit("updateUser", {
          ...this.$store.state.user,
          ngageUserDetail: null,
        });
      }

      this.integrationsList = this.integrationsList.map((integration) => {
        integration.connected = false;
        return integration;
      });

      this.$store.commit("addToast", {
        variant: "success",
        message: `The ${integration.name} integration is disconnected`,
      });

      this.$bvModal.hide(this.detailsModalId);
      this.loading = false;
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/_variables.scss";
@import "@/assets/styles/mixins/_breakpoints.scss";

.device-health-integrations {
  .integrations-card {
    position: relative;
    border-radius: $border-radius-lg;
    border: 1px solid $gray-300;
    background: $white;
    box-shadow: $box-shadow-sm;
    padding: 1rem;
  }
  .ngage-integrations-watch-image {
    position: absolute;
    top: 0;
    height: 100%;
    right: 0;
    @include media-breakpoint-up(md) {
      right: 1rem;
    }
  }
}

@include media-breakpoint-up(md) {
  .w-md-50 {
    width: 50% !important;
  }
}

.device-integration-image {
  height: 3rem;
  width: 3rem;
}

#nudge-modal-id {
  .download-app-qr-codes {
    margin: 1rem;
    display: flex;
    justify-content: space-between;
    .img {
      width: 7rem;
      height: 7rem;
    }
  }
  .download-app-stores {
    display: block;
    justify-content: space-between;
    padding-top: 1rem;
    padding-right: 1.5rem;
    text-align: center;
    .a {
      display: block;
    }
    .img {
      width: 6.5rem;
      height: 2rem;
    }
  }

  .download-app-detail {
    display: flex;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .app-detail {
    justify-content: space-between;
    display: flex;
    flex-direction: column;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
}
</style>
