import gql from "graphql-tag";
import { claimFilesFragment } from "../fragments";

export default {
  getClaimFiles: gql`
    query claimFiles($claimId: ID) {
      claimFiles(claimId: $claimId) {
        ...ClaimFiles
      }
    }
    ${claimFilesFragment}
  `,
};
