import { ApolloClient } from "apollo-client";
import { ApolloLink } from "apollo-link";
import "./../common/auth"; // to initiate apolloClient for refresh token
import { RetryLink } from "@apollo/client/link/retry";
import { store } from "./store";
import { errorMiddleware } from "./../common/middlewares/apolloError";
import { versioningMiddleware } from "./../common/middlewares/versioning";
import { authMiddleware, fetchNewAccessToken, refreshTokenMiddleware } from "./../common/middlewares/auth";
import { httpLink } from "./../common/middlewares/httpLink";
import { cache } from "./../common/apolloCache";
import { promiseToObservable } from "./../common/auth";
import { OPEN_QUERIES_AND_MUTATIONS } from "./../common/constants/auth";

const afterwareLink = new ApolloLink((operation, forward) => {
  return forward(operation).map((response) => {
    const context = operation.getContext();
    const xVersion = context?.response?.headers?.get("x-version");
    store.commit("setApiVersion", xVersion);
    const clientVersion = process.env.VUE_APP_VERSION;
    if (xVersion && clientVersion && clientVersion !== xVersion && clientVersion?.[0] !== xVersion?.[0]) {
      store.commit("updateMajorVersionMismatch", true);
      navigator.serviceWorker.getRegistration().then((registration) => {
        if (registration?.waiting) {
          registration.waiting.postMessage({ type: "SKIP_WAITING" });
        }
      });
    }
    return response;
  });
});

const refetchAcessTokenAndRetryLink = new ApolloLink((operation, forward) => {
  if (operation.getContext().isRetriableError) {
    operation.setContext((prevCtx) => ({ ...prevCtx, isRetry: true }));
    return promiseToObservable(fetchNewAccessToken()).flatMap(() => forward(operation));
  }
  return forward(operation);
});
const retryLink = new RetryLink({
  attempts: {
    max: 2,
    retryIf: (error, operation) => {
      const isRetriableError = error?.statusCode === 401;
      operation.setContext((prevCtx) => ({ ...prevCtx, isRetriableError }));
      return isRetriableError;
    },
  },
}).split(
  (op) => {
    if (OPEN_QUERIES_AND_MUTATIONS.includes(op.operationName)) return true;
    return op.getContext().isRetry;
  },
  errorMiddleware,
  refetchAcessTokenAndRetryLink
);
export const apolloClient = new ApolloClient({
  // Important: Please note the order here
  // If the order changes some of the middleware won't work
  link: ApolloLink.from([
    errorMiddleware,
    retryLink,
    refreshTokenMiddleware,
    authMiddleware,
    versioningMiddleware,
    afterwareLink,
    httpLink,
  ]),
  cache,
  connectToDevTools: true,
});
