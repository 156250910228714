const Assessments = () => import(/* webpackChunkName: "Assessments" */ "./Assessments.vue");

export const Base = require("./Base.vue").default;
export const routes = [
  {
    path: "",
    component: Assessments,
    name: "riskAssessments",
  },
  {
    path: "take-assessment",
    component: () => import(/* webpackChunkName: "TakeAssessment" */ "./TakeAssessment.vue"),
    name: "takeAssessment",
  },
  {
    path: "report",
    component: () => import(/* webpackChunkName: "Report" */ "./Report.vue"),
    name: "assessmentReport",
  },
];
