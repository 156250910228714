import gql from "graphql-tag";
import { userFragment } from "./user";

export const pgPaymentFragment = gql`
  fragment PgPayment on PgPayment {
    id
    pgPaymentId
    pgOrderId
    novaOrder {
      id
      orderAmount
    }
    user {
      id
      name
      email
    }
    status
    pgName
    meta
    createdAt
    updatedAt
  }
`;
