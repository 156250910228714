<template lang="pug">
div
  .tabs-container.justify-content-start.align-items-center.border-bottom.mb-3
    .tab-heading.d-inline-block.cursor-pointer.font-weight-semibold.text-center.text-gray-700.p-3.mr-2(
      v-for="tab in tabs",
      :key="tab.route",
      @click="$router.push(tab.route)",
      :class="{ 'active-tab': $route.path === tab.route }") {{tab.title}}
</template>

<script>
export default {
  name: "TopNavTabs",
  props: {
    tabs: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
@import "@/assets/styles/mixins/_breakpoints.scss";
.active-tab {
  color: $tiber;
  border-bottom: 2px solid $tiber;
}
.border-bottom {
  border-bottom: 1px solid $gray-300;
}
@include media-breakpoint-down(sm) {
  .tabs-container {
    background: $tiber;
    position: absolute;
    top: 0;
    left: 0;
    // stretch the container to cover whole screen width on mobile
    margin-left: calc(50% - 50vw);
    margin-right: calc(50% - 50vw);
    margin-top: 4.5rem;
    width: 100vw;
    align-items: flex-end !important;
    overflow: auto;
    white-space: nowrap;
  }
  .active-tab {
    color: $white;
    border-bottom: 2px solid $white;
  }
}
</style>
