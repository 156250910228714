import { TopupRoutes } from "../../../../common/enums/topupRoutes.enum";
export const Base = require("./Base.vue").default;

export const routes = [
  {
    path: "",
    name: "super-topups",
    component: Base,
    children: [
      { path: TopupRoutes.ADD_MEMBERS, name: "addMembers", component: Base },
      { path: TopupRoutes.POLICY_DETAILS, name: "policyDetails", component: Base },
      { path: TopupRoutes.NOMINEE_DETAILS, name: "nomineeDetails", component: Base },
      { path: TopupRoutes.CONTACT_DETAILS, name: "contactDetails", component: Base },
      {
        path: TopupRoutes.POLICY_SUMMARY,
        name: "policySummary",
        component: Base,
      },
    ],
  },
];
