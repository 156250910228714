export const PodMemberRoles = {
  KAM: "Key Account Manager",
  RM: "Relationship Manager",
  EA: "Endorsements Associate",
  CA: "Claims Associate",
  OA: "Onboarding Associate",
  SA: "Support Associate",
  SM: "Support Manager",
  CM: "Claims Manager",
  OM: "Onboarding Manager",
  IRM: "Insurer Relationship Manager",
  CXPgM: "CX Program Manager",
  CXHead: "CX Head",
  CEO: "CEO",
  Other: "Other",
  Wellness: "Wellness",
};
