import gql from "graphql-tag";
import { benefitFragment } from "../fragments";

export default {
  upsertMutation: gql`
    mutation upsertBenefit(
      $id: ID
      $insurerId: ID
      $tpaId: ID
      $tpaOfficeId: ID
      $isPolicy: Boolean
      $restrictedFields: [String]
      $type: String
      $name: String
      $meta: JSONObject
      $chargesConfig: JSONObject
      $insurerDetails: JSONObject
    ) {
      upsertBenefit(
        input: {
          id: $id
          insurerId: $insurerId
          isPolicy: $isPolicy
          tpaId: $tpaId
          tpaOfficeId: $tpaOfficeId
          restrictedFields: $restrictedFields
          type: $type
          name: $name
          meta: $meta
          chargesConfig: $chargesConfig
          insurerDetails: $insurerDetails
        }
      ) {
        benefit {
          ...Benefit
        }
      }
    }
    ${benefitFragment}
  `,
};
