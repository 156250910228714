<template lang="pug">
  div
    .d-flex.justify-content-between.align-items-center(v-b-toggle.balance-si)
      .d-flex.align-items-center
        n-icon(name="money", variant="teal-800", :size="1")
        .text-gray-700.font-sm.font-weight-medium.ml-1 Available Sum Insured
        n-icon.ml-1(name="info", variant="gray-600", :pathCount="2", :size="1", v-b-tooltip.hover.html="hoverData")
      n-icon(v-if="collapsible" :name="balanceVisible ? 'chevron-down' : 'chevron-up'", :size="1.3", variant="blue-500")
    b-collapse(id="balance-si", v-model="balanceVisible")
      h3.hb3.d-flex.text-gray-900.mt-2 {{balanceSI | inr }}
      .d-flex.mt-2
        span.font-hergon.font-weight-medium.text-gray-900.font-md Your total sum insured is
        span.font-hergon.font-weight-bold.text-gray-900.font-md.ml-1 {{totalSI | inr}}
      .d-flex.mt-2
        n-icon(name="error" variant="warning" :size="1")
        span.font-sm.font-weight-medium.text-gray-700.ml-1 Available Sum Insured is subject to changes based on Ongoing Claims 
      .d-block.display.mt-4(v-if="showHistory")
        b-collapse(id="history", v-model="historyVisible")
          .border.rounded.shadow-sm
            b-table.rounded.mb-0.pb-0(:items="history", thead-class="bg-alabaster text-gray-800 font-sm")
              //- TODO ADD thead for all columns at once modoifying their font-weight 
              template(#head()="data")
                  span.text-gray-800.font-weight-semibold {{ data.label }}
              template(v-slot:cell(nbNumber)="{ item }")
                span.font-sm {{ item.nbNumber }}
              template(v-slot:cell(dateOfCompletion)="{ item }")
                span.font-sm {{ item.dateOfCompletion | date }}
              template(v-slot:cell(approvedAmount)="{ item }")
                span.font-sm {{ item.approvedAmount | inr }}
              template(v-slot:cell(claimed)="{ item }")
                span.font-sm {{ item.claimed | inr }}
              template(v-slot:cell(balance)="{ item }")
                span.font-sm {{ item.balance | inr }}
              template(v-slot:cell(balanceSI)="{ item }")
                span.font-sm {{ item.balanceSI | inr }}
        .d-flex.align-items-center.justify-content-center.text-decoration-none.mt-3(v-b-toggle.history, v-if="history.length > 0") 
          span.font-md.font-weight-semibold.text-blue-500 {{ historyVisible ? 'Close' : 'Open' }} Balance History
          n-icon.ml-1(:name="historyVisible ? 'chevron-up' : 'chevron-down'", :size="1.3", variant="blue-500")
        
</template>
<script>
import { mapGetters } from "vuex";
import NButton from "@/components/NovaButton.vue";
import gql from "graphql-tag";

export default {
  name: "BalanceSI",
  components: {
    NButton,
  },
  props: {
    policyId: {
      type: String,
      required: true,
    },
    userId: {
      type: String,
      required: true,
    },
    showHistory: {
      type: Boolean,
      default: false,
    },
    collapsible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const showBalanceByDefault = !this.collapsible;
    return {
      hoverData: {
        title: `
          <div class="text-left">
            <span class="font-md d-block font-weight-semibold font-hergon text-gray-900 mb-1"> Available/ Balance Sum Insured </span>
            <span class="font-sm font-weight-normal text-gray-800"> 
              When you file a claim the insurer pays the <br />
              eligible amount from the sum insured (Sum <br />
              Insured is the maximum amount the insurer is <br />
              obligated to cover). <br />
              The balance sum insured is what remains <br />
              after deducting the claim amount.
            </span>
          </div>`,
        html: true,
        position: "top",
      },
      historyVisible: false,
      balanceVisible: showBalanceByDefault,
      balanceSI: 0,
      totalSI: 0,
      history: [],
    };
  },
  mounted() {
    this.getBalanceSI();
  },
  computed: {
    ...mapGetters(["user"]),
    isMobileBrowser() {
      return window.innerWidth <= 990;
    },
  },
  methods: {
    async getBalanceSI() {
      const GET_BALANCE_SI = gql`
        query getBalanceSI($policyId: ID!, $userId: ID!, $withHistory: Boolean) {
          getBalanceSi(policyId: $policyId, userId: $userId, withHistory: $withHistory)
        }
      `;
      try {
        const balanceSI = await this.$apollo.query({
          query: GET_BALANCE_SI,
          variables: {
            policyId: this.policyId,
            userId: this.userId,
            withHistory: this.showHistory,
          },
        });
        this.totalSI = balanceSI.data.getBalanceSi.totalSi;
        this.balanceSI = balanceSI.data.getBalanceSi.balanceSi;
        if (this.showHistory) {
          this.history = balanceSI.data.getBalanceSi.history;
        }
      } catch (error) {
        console.log("Error fetching balance SI", error);
      }

      if (this.isMobileBrowser) {
        this.history = this.history.map((item) => {
          delete item.dateOfCompletion;
          (item.claimed = item.approvedAmount), delete item.approvedAmount;
          item.balanceSI = item.balance;
          delete item.balance;
          return item;
        });
      }
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/_variables.scss";
</style>
