<template lang="pug">
  .bottom-navbar-container(v-if="!isPrivacySettingsPage")
    b-nav-item.btm-nav-item(
      to="/dashboard",
      @click="scrollToTop",
      v-if="isLoggedIn",
      :class="$route.path==='/dashboard'?'btm-nav-item-active':''")
      n-icon(name="home-1", :size="1.5")
      span.font-xs.pt-1 Home
    b-nav-item.btm-nav-item(
      to="/fitness",
      @click="scrollToTop",
      v-if="isLoggedIn && getFeatureFlags.FITNESS",
      :class="$route.path.startsWith('/fitness')?'btm-nav-item-active':''")
      n-icon(name="running", :size="1.5")
      span.font-xs.pt-1 Fitness
    b-nav-item.btm-nav-item(
      to="/user/claims",
      @click="scrollToTop",
      v-if="isLoggedIn && isClaimsTabAllowed",
      :class="$route.path.startsWith('/user/claim')?'btm-nav-item-active':''")
      n-icon(name="claims-1", :size="1.5")
      span.font-xs.pt-1 Claims
    b-nav-item.btm-nav-item(
      @click="scrollToBenefits",
      v-if="isLoggedIn && !isOrgInsuranceOnly",
      :class="isBenefitSection?'btm-nav-item-active':''")
      n-icon(name="star-curved-4", :size="1.5")
      span.font-xs.pt-1 Benefits
    b-nav-item.btm-nav-item(
      @click="showSalesIqChat",
      v-if="isLoggedIn",
      :class="$route.path==='/support'?'btm-nav-item-active':''")
      n-icon(name="support-1", :size="1.5")
      span.font-xs.pt-1 Support
    b-nav-item.btm-nav-item(
      to="/org-admin",
      @click="scrollToTop"
      v-if="isOrgAdmin || isOrgEntityAdmin",
      :class="$route.path.startsWith('/org-admin')?'btm-nav-item-active':''")
      n-icon(name="city", :size="1.5")
      span.font-xs.pt-1 Admin
    b-nav-item.btm-nav-item.d-none.d-sm-block(
      to="/admin", v-if="isAdmin",
      :class="$route.path.startsWith('/admin')?'btm-nav-item-active':''")
      n-icon(name="star", :size="1.5")
      span.font-xs.pt-1 Super Admin
</template>

<script>
import { mapState, mapGetters } from "vuex";
import { OrgServicesOffered } from "../common/enums";
import privacyPageMixin from "../mixins/privacyPage";
import NAvatar from "@/components/Avatar.vue";
export default {
  name: "BottomNavBar",
  components: {
    NAvatar,
  },
  mixins: [privacyPageMixin],
  data() {
    return {
      isBenefitSection: false,
      timeout: null
    };
  },
  computed: {
    ...mapGetters(["isLoggedIn", "isAdmin", "isOrgAdmin", "isOrgEntityAdmin", "getFeatureFlags"]),
    ...mapState(["user"]),
    isOrgInsuranceOnly() {
      return this.user?.org?.meta?.servicesOffered === OrgServicesOffered.INSURANCE_ONLY;
    },
    isClaimsTabAllowed() {
      return (
        this.getFeatureFlags?.CLAIMS_PORTAL_EXPERIENCE &&
        this.user?.org?.meta?.servicesOffered !== OrgServicesOffered.WELLNESS_ONLY
      );
    },
  },
  methods: {
    async scrollToTop() {
      if(this.$route.fullPath.startsWith("/dashboard")) {
        if(!this.timeout) {
          this.timeout = setTimeout(() => {
            window.scrollTo({ top: 0, behavior: "smooth" });
            this.timeout = null;
          }, 300);
        }
      } else {
        window.scrollTo({ top: 0, behavior: "smooth" });
      }
    },
    showSalesIqChat() {
      this.$store.commit("toggleSupportContainer");
    },
    async scrollToBenefits() {
      this.isBenefitSection = true;
      setTimeout(() => {
        this.isBenefitSection = false;
      }, 500);
      if (this.$route.fullPath.startsWith("/dashboard")) {
        document.getElementById("wellness-benefits")?.scrollIntoView({ behavior: "smooth" });
      } else {
        this.$router.push({ path: "/dashboard", hash: "#wellness-benefits" });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
.bottom-navbar-container {
  width: 100%;
  padding: 0;
  margin-bottom: 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: fixed;
  bottom: 0;
  min-height: 68px;
  background: $white;
  white-space: nowrap;
  z-index: 100;
}
.btm-nav-item {
  min-width: 50px;
  list-style-type: none;
  & > a {
    color: inherit;
    display: flex;
    flex-direction: column;
    align-items: center;

    text-decoration: none;
    color: $gray-600;
  }
}
.btm-nav-item-active {
  & > a {
    color: $gray-900;
  }
}
#pill-new-mobile {
  height: 2rem;
  width: 2.75rem;
  position: absolute;
  top: -1rem;
}
</style>
