import { getIdSelectorQuery } from "../queries";

const novaOrderStatus = ["completed", "partially-completed", "pending", "cancelled", "on-hold", "deleted"];

export const NovaOrdersSchema = {
  schema: {
    fields: [
      {
        model: "name",
        type: "input",
        inputType: "text",
        label: "Name",
        disabled: true,
      },
      {
        model: "email",
        type: "input",
        inputType: "text",
        label: "Email",
        disabled: true,
      },
      {
        model: "orderId",
        type: "input",
        inputType: "text",
        label: "Order Id",
        disabled: true,
      },
      {
        model: "orderAmount",
        type: "input",
        inputType: "number",
        label: "Order Amount",
        disabled: true,
      },
      {
        model: "status",
        type: "input",
        inputType: "text",
        label: "Status",
        disabled: true,
      },
      {
        model: "billingAddress",
        type: "input",
        inputType: "text",
        label: "Billing Address",
        disabled: true,
      },
      {
        model: "stateOfResidence",
        type: "input",
        inputType: "text",
        label: "State of Residence",
        disabled: true,
      },
      {
        model: "createdAt",
        type: "inline-input",
        inputType: "text",
        label: "Created At",
        styleClasses: "d-inline-block w-50 pr-1",
        disabled: true,
      },
      {
        model: "updatedAt",
        type: "inline-input",
        inputType: "text",
        label: "Last Updated At",
        styleClasses: "d-inline-block w-50 pr-1",
        disabled: true,
      },
    ],
  },
  filterSchema: {
    fields: [
      {
        model: "org",
        label: "Organization",
        type: "id-selector",
        queryName: "orgs",
        gqlQuery: getIdSelectorQuery("Org"),
        placeholder: "Organization filter (none)",
        showSelected: false,
      },
      {
        model: "orgEntity",
        type: "id-selector",
        queryName: "orgEntities",
        gqlQuery: getIdSelectorQuery("OrgEntity"),
        queryVariables: () => ({ filter: {} }),
        label: "Org Entity",
        placeholder: "Org Entity filter (none)",
        visible: false,
      },
      {
        model: "status",
        type: "select",
        values: novaOrderStatus,
        label: "Status",
      },
    ],
  },
};
