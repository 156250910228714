export const UserChangeStatus = {
  DRAFT: "draft",
  ARCHIVE: "archive",
  REJECTED: "rejected",
  ORG_OK: "org-ok",
  NOVA_OK: "nova-ok",
  PROVIDER_OK: "provider-ok",
  DONE: "done",
  DELETED: "deleted",
  EXPIRED: "expired",
};
