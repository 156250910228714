export const TOPUP_CONSTANTS = [
  {
    title: "Super Top-up your current policy",
    subtitle:
      "You can top-up policies of all the below mentioned members of your family, and get coverage upto 50 Lakhs!",
  },
  {
    title: "Policy Details",
    subtitle: "Customise your policy according to your convinience",
  },
  {
    title: "Nominee and Contact Details",
    subtitle:
      "This ensures a safe and smooth process for your claim requests during emergencies. Rest assured, we're here to support you.",
  },
  {
    title: "Contact Details",
    subtitle: "You’ll receive your policy details and documents here",
  },
  { title: "Policy Summary", subtitle: "Please review the policy details carefully before proceeding forward" },
];

export const SUMMARY_CONTENT_WEB = {
  "add-members": {
    title: "Expand your health safety net with Nova's Super Top-Up insurance. 💪",
    bodyText1:
      "Start by adding yourself first so you can include your spouse and children. Your parents can be added separately.",
    buttonText: "Continue to add policy details  ->",
  },
  "policy-details": {
    header: "💡",
    title: "How to select Deductible Amount?",
    bodyText1:
      "If your existing policy coverage is 5L, we suggest selecting 5L as your deductible. This ensures you can avoid any out-of-pocket expenses",
    bodyText2: "Remember, the super top-up activates only after the deductible amount is used up ☘️",
    buttonText: "Confirm and continue  ->",
  },
  "nominee-details": {
    header: "👋",
    title: "Nominee and Contact Verification",
    bodyText1: "Make sure your Nominee and contact details match your Government ID and are accurate.",
    bodyText2: "Only one nominee is needed for all members covered by this top-up policy.",
    buttonText: "Confirm & Continue",
  },
  "contact-details": {
    header: "👋",
    title: "Contact Verification",
    bodyText1:
      "Please make sure to add the correct address and contact details of yourself. We need these details to send over your policy documents.",
    buttonText: "Confirm & Continue",
  },
};

export const SUMMARY_CONTENT_MOBILE = {
  "add-members": {
    bodyText1:
      "Start by adding yourself first so you can include your spouse and children. Your parents can be added separately.",
    buttonText: "Continue to add policy details  ->",
  },
  "policy-details": {
    bodyText1: "Select coverage and deductible amount to view the premium details",
    buttonText: "Confirm and Continue  ->",
  },
  "nominee-details": {
    bodyText1: "Make sure your Nominee and contact details match your Government ID and are accurate.",
    bodyText2: "Only one nominee is needed for all members covered by this top-up policy.",
    buttonText: "Confirm and Continue  ->",
  },
  "contact-details": {
    bodyText1:
      "Please make sure to add the correct address and contact details of yourself. We need these details to send over your policy documents.",
    buttonText: "Confirm and Continue  ->",
  },
  "policy-summary": {
    buttonText: "Continue to Payment  ->",
  },
};

export const SuperTopupPolicyStatus = {
  CREATED: "created",
  DELETED: "deleted",
  EXPIRED: "expired",
  ACTIVE: "active",
  SUSPENDED: "suspended",
  CANCELED: "canceled",
  PAYMENT_SUCCESS: "payment-success",
};

export const INFO_CONTENT = [
  {
    title: "Example 1 - Single claim of  ₹15 Lakh",
    subContent: [
      {
        subTitle: "Primary Policy or you pay ₹5 Lakhs",
        subText:
          "Since your selected deductible amount is ₹5 Lakhs, the first ₹5 Lakhs must either be covered by your primary policy or you.",
      },
      {
        subTitle: "Super Top-up Kicks in",
        subText: "Your super top-up policy will cover the rest of ₹10 Lakhs, which is above the deductible amount",
      },
      {
        subTitle: "Super Top-up Coverage",
        subText: "You balance sum insured or coverage would then be ₹40 Lakhs",
      },
    ],
  },
  {
    title: "Example 2 - Two claims of  ₹3 Lakh each",
    subContent: [
      {
        subTitle: "First claim",
        subText:
          "Since your selected deductible amount is ₹5 Lakhs, you or your primary policy must cover the entire ₹3 Lakhs from 1st claim",
      },
      {
        subTitle: "Second Claim",
        subText:
          "Your primary policy or you will cover ₹2 Lakhs from the 2nd claim, as your deductible amount was not used up. The remaining ₹1 Lakh will be covered by your super top-up policy",
      },
      {
        subTitle: "Super Top-up Coverage",
        subText: "You balance sum insured or coverage would then be ₹49 Lakhs",
      },
    ],
  },
  {
    title: "Example 3 - Two claims of  ₹10 Lakh and ₹3 Lakhs",
    subContent: [
      {
        subTitle: "First claim",
        subText:
          "Since your selected deductible amount is ₹5 Lakhs, you or your primary policy must cover ₹5 Lakhs from 1st claim. Super top-up policy gets activated and covers the rest of ₹5 Lakhs",
      },
      {
        subTitle: "Second Claim",
        subText:
          "The entire ₹3 Lakhs will be covered by your super top-up policy, as your deductible amount is already used up.",
      },
      {
        subTitle: "Super Top-up Coverage",
        subText: "You balance sum insured or coverage would then be ₹42 Lakhs",
      },
    ],
  },
];
