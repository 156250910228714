import { getListQuery, getSingleQuery } from "../queries";
import fileGroupsSchema from "../schemas/fileGroups";
import fileGroupsMutations from "../mutations/fileGroups";
export default {
  gqlType: "FileGroup",
  fields: ["name", "createdAt"],
  singleQuery: getSingleQuery("FileGroup"),
  listQuery: getListQuery("FileGroup"),
  ...fileGroupsSchema,
  ...fileGroupsMutations,
  transform: (n) => {
    return {
      ...n,
    };
  },
};
