export const Status = {
  CLAIM_INTIMATED : "CLAIM_INTIMATED",
  DOCS_REQUESTED : "DOCS_REQUESTED",
  DORMANT : "DORMANT",
  DOCS_RECIEVED : "DOCS_RECIEVED",
  CLAIM_REGISTERED : "CLAIM_REGISTERED",
  UNDER_QUERY_BY_TPA : "UNDER_QUERY_BY_TPA",
  UNDER_PROGRESS_WITH_TPA : "UNDER_PROGRESS_WITH_TPA",
  UNDER_INVESTIGATION : "UNDER_INVESTIGATION",
  APPROVED_BY_TPA : "APPROVED_BY_TPA",
  APPROVED_DOCS_AWAITED : "APPROVED_DOCS_AWAITED",
  PAYMENT_IN_PROGRESS : "PAYMENT_IN_PROGRESS",
  CLAIM_SETTLED : "CLAIM_SETTLED",
  CLAIM_REOPENED : "CLAIM_REOPENED",
  REJECTED : "REJECTED",
  INVALID : "INVALID",
  UPCOMING : "UPCOMING",
  REQUEST_RECIEVED : "REQUEST_RECIEVED",
  REQUEST_IN_PROGRESS : "REQUEST_IN-PROGRESS",
  PREAUTH_APPROVED : "PRE-AUTH_APPROVED",
  ENHANCEMENT_ONGOING : "ENHANCEMENT_ONGOING",
  ENHANCEMENT_APPROVED : "ENHANCEMENT_APPROVED",
  APPROVED : "APPROVED",
  CANCELLED : "CANCELLED",
  DELETED : "DELETED",
}