<template lang="pug">
div
  .booking-overview-card.no-gutters.mt-3.mt-md-0.mb-3.p-3.bg-white.d-flex.flex-column.justify-content-center.border.rounded.border-gray-300.shadow-xs(
    :class="[bookingStatus.toLowerCase() + '-booking', 'cursor-pointer']",
    @click="redirectToBookingDetails")
    .row.align-items-center
      .d-none.pl-3.booking-info.d-lg-block.col-lg-11
        p.text-gray-700.font-sm.m-0.font-weight-medium(v-if="bookingDetails.partnerBookingId") Booking ID {{ bookingDetails.partnerBookingId }}
      .d-lg-none.col-11
        p.text-gray-700.font-sm.m-0.font-weight-medium(v-if="bookingDetails.partnerBookingId") Booking ID {{ bookingDetails.partnerBookingId }}
      .d-lg-none.col-1.p-0.pr-3.d-flex.justify-content-end
        n-icon.chevron-right.text-gray-900(name="chevron-right")
    .row.checkup-details-row.mt-3
      .col-12.d-lg-none.mb-3
        hr.border-top-dashed.m-0
      .col-lg-8.col-12
        p.text-gray-900.font-weight-semibold.font-md.mb-1.font-weight-medium {{ bookingDetails.product.name }}
        p.text-gray-700.mb-2.font-sm Booked for: {{ formattedMemberNames }}
      .col-4.pr-2.pl-0.d-none.d-lg-block
        p.text-gray-900.font-weight-semibold.font-sm.d-flex.mb-2.font-weight-medium.justify-content-end
          n-icon.align-middle.mr-1(
            :size="1",
            :name="sampleCollectionDetailsMap.iconName")
          | {{ sampleCollectionDetailsMap.sampleCollectionTitle }}
        p.d-none.text-gray-700.mb-2.font-sm.d-flex.justify-content-end
          package-tests-count-chip(
            :checkupPackage="bookingDetails.checkupPackage",
            :testCount="bookingDetails.checkupPackage.testCount",
            :customSideSheetId="`sidesheet-tests-${bookingDetails.checkupPackage.id}-${bookingDetails.id}`",
            :shouldScrollToTests="true",,
            :viewOnly="true")
    .row.provider-details-row.mt-3.align-items-center(v-if="shouldShowProviderDetails")
      .col-12.mb-3
        hr.border-top-dashed.m-0
      .col-lg-12.d-none.d-lg-flex.mb-0
        p.text-gray-700.mb-1.font-sm Provider
      .col-lg-7
        p.text-gray-900.font-weight-semibold.mb-2.font-md {{ bookingDetails.provider.name }}
        p.text-gray-700.mb-2.font-sm {{ bookingDetails.provider.address }}
      .col-lg-5.checkup-action-btn2.d-none.d-lg-flex.flex-column.align-items-end

    .row.checkup-timings-row.mt-3.d-lg-none(v-if="bookingStatus !== 'Upcoming'")
      .col-12.mb-3
        hr.border-top-dashed.m-0
      .col-6.d-flex.justify-content-start
        p.text-gray-900.font-weight-semibold.font-sm.d-flex.mb-1.font-weight-medium
          n-icon.align-middle.mr-1(
            :size="1",
            :name="sampleCollectionDetailsMap.iconName")
          | {{ sampleCollectionDetailsMap.sampleCollectionTitle }}
  //- reschedule-booking-sidesheet(
    v-if="this.isStatusActive(bookingStatus)",
    :sidesheetId="sidesheetId",
    :modalId="modalId",
    :bookingDetails="bookingDetails",
    @modal-closed="$emit('modal-closed')")
</template>

<script>
import { getSampleCollectionTitleAndIcon, isAppointmentStatusActive, getCalIconColorClass } from "../checkupUtils";
import PackageTestsCountChip from "./PackageTestsCountChip.vue";
import RescheduleBookingSidesheet from "./RescheduleBookingSidesheet.vue";
import CheckupReportSidesheet from "./CheckupReportSidesheet.vue";
import NButton from "@/components/NovaButton.vue";
import NAvatar from "@/components/Avatar.vue";
import NChip from "@/components/NovaChip.vue";

export default {
  name: "BookingOverviewCard",
  components: {
    NButton,
    NAvatar,
    RescheduleBookingSidesheet,
    NChip,
    PackageTestsCountChip,
    CheckupReportSidesheet,
  },
  props: {
    bookingDetails: {
      type: Object,
      default: null,
    },
    appointments: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      memberNames: [],
    };
  },
  computed: {
    benefitId() {
      return this.$route.params.benefitId;
    },
    bookingStatus() {
      return this.bookingDetails.bookingStatus;
    },
    sidesheetId() {
      return `reschedule-booking-sidesheet-${this.bookingDetails.partnerBookingId}`;
    },
    modalId() {
      return `reschedule-booking-modal-${this.bookingDetails.partnerBookingId}`;
    },
    badgeIcon() {
      switch (this.bookingStatus) {
        case "Completed":
          return "check-circle";
        case "Cancelled":
          return "minus-circle";
        case "Upcoming":
          return "clock";
        case "Confirmed":
          return "check-circle";
        default:
          return "";
      }
    },
    calIconColorClass() {
      return getCalIconColorClass(this.bookingStatus);
    },
    badgeColorVariant() {
      switch (this.bookingStatus) {
        case "Completed":
          return "light-blue";
        case "Cancelled":
          return "light-danger";
        case "Upcoming":
          return "light-secondary";
        case "Confirmed":
          return "success";
        default:
          return "";
      }
    },
    sampleCollectionDetailsMap() {
      return getSampleCollectionTitleAndIcon(this.sampleCollectionType);
    },
    formattedMemberNames() {
      return this.memberNames.join(", ");
    },
    sampleCollectionType() {
      // All appointments within a booking will be of the same type, therefore taking the first
      return this.bookingDetails.appointments[0].sampleCollectionType;
    },
    shouldShowProviderDetails() {
      return (
        this.bookingStatus !== "Cancelled" &&
        !this.bookingDetails.appointments.some(({ meta } = {}) => meta?.providerId)
      );
    },
  },
  created() {
    this.memberNames = this.bookingDetails.memberNames;
  },
  methods: {
    isStatusActive(status) {
      return isAppointmentStatusActive(status);
    },
    stopPropagation(event) {
      event.stopPropagation();
    },
    redirectToBookingDetails() {
      if (this.$route.path.includes(this.bookingDetails.id)) {
        return;
      }
      window.posthog.capture("checkup_user_clicked_booking_details");
      this.$router.push({
        name: "bookingDetails",
        params: {
          benefitId: this.benefitId,
          bookingUser: this.$route.params.bookingUser,
          bookingUserBenefit: this.$route.params.bookingUserBenefit,
          bookingId: this.bookingDetails.id,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
@import "@/assets/styles/mixins/_breakpoints.scss";
.booking-overview-card {
  .chevron-right {
    transition: transform 0.3s ease;
  }
  &:hover {
    .chevron-right {
      transform: translateX(0.625rem) !important;
    }
  }
}
.upcoming-booking,
.confirmed-booking {
  &:hover {
    border: 1px solid $teal-300 !important;
    background: $teal-100 !important;
  }
}
.completed-booking {
  &:hover {
    border: 1px solid $blue-200 !important;
    background: rgba($color: $blue-100, $alpha: 0.3) !important;
  }
}
.cancelled-booking {
  &:hover {
    border: 1px solid $red-200 !important;
    background: rgba($color: $red-100, $alpha: 0.3) !important;
  }
}
.booking-info p {
  @media (min-width: 992px) and (max-width: 1200px) {
    padding-left: 0 !important;
  }
}
.border-top-dashed {
  border-top: 1px dashed rgba(0, 0, 0, 0.12);
}
.checkup-action-btn button {
  padding: 0.625rem 0.75rem !important;
}
.provider-details-row {
  .checkup-action-btn button {
    width: 11rem !important;
  }
}
@include media-breakpoint-down(md) {
  .checkup-timings {
    padding-right: 0;
    padding-left: 1.75rem !important;
  }
  .checkup-timings-row {
    order: 4;
  }
}
</style>
