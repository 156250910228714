<template lang="pug">
.policy-summary(:class="{ 'pb-8': isMobileBrowser() || isApp() }")
  .text-gray-800.font-md.font-weight-medium.align-items-center.pt-4(v-if="getPlusLives()?.length")
    n-icon.mr-1(
      name="color-family",
      :size=1
    )
    | Nova Plus: Coverage for you, your spouse &amp; children

  </br>
  .self-cards(v-for="member in getPlusLives()")
    member-summary-card(
      :name="member.name"
      :relation="member.relation",
      :dob="member.dob",
      :si="novaPlusPremiumDetails.si",
      :deductible="novaPlusPremiumDetails.deductible"
    )
  </br>
  .text-gray-800.font-md.font-weight-medium.align-items-center.pt-4(v-if="getExtendedLives()?.length")
    n-icon.mr-1(
      name="color-family",
      :size=1
    )
    | Nova Extended: Top-up options for your parents 
  </br>
  .extended-cards(v-for="member in getExtendedLives()")
    member-summary-card(
      :name="member.name"
      :relation="member.relation",
      :dob="member.dob",
      :deductible="novaExtendedPremiumDetails?.deductible",
      :si="novaExtendedPremiumDetails.si"
    )

      
</template>
<script>
import NovaIcon from "../../../../../components/NovaIcon.vue";
import NAvatar from "../../../../../components/Avatar.vue";
import MemberSummaryCard from "./MemberSummaryCard.vue";
import mixin from "./../mixin";
import { AcceptedRelations } from "../../../../../common/enums";
export default {
  name: "PolicySummary",
  mixins: [mixin],
  components: {
    NovaIcon,
    NAvatar,
    MemberSummaryCard,
  },
  created() {
    if (this.superTopupProspectState.novaPlusPremiumDetails) {
      this.novaPlusPremiumDetails = this.superTopupProspectState.novaPlusPremiumDetails;
    }
    if (this.superTopupProspectState.novaExtendedPremiumDetails) {
      this.novaExtendedPremiumDetails = this.superTopupProspectState.novaExtendedPremiumDetails;
    }
  },
  methods: {
    getPlusLives() {
      return [
        ...this.superTopupProspectState.selectedLives.filter((member) =>
          [AcceptedRelations.SELF, AcceptedRelations.SPOUSE].includes(member.relation)
        ),
        ...this.superTopupProspectState.plusDependents
          .filter((life) => life.checked)
          .map((life) => ({ relation: AcceptedRelations.CHILD, ...life })),
      ];
    },

    getExtendedLives() {
      return this.superTopupProspectState.policyDetail?.[this.getExtendedPolicy?.id]?.coveredMembers;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";

.vertical-separator {
  border-left: 1px solid $gray-400;
}
</style>
