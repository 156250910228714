<template lang="pug">
.w-100
  .d-flex.flex-column
    .d-flex.justify-content-between.align-items-center.w-100
      h3.font-weight-medium Your Policies
        template(v-if="policies.length > 0")
          .my-3
            .row
              .col-md-12.mb-3(v-for="policy in policies", :key="policy.id")
                n-policy-card(:policy="policy", :user="user", :userChanges="userChanges", :edgeMeta="policy.meta", :previewMode="true")
        template(v-else)
          h3.policies-wrapper No benefits found that you are linked to currently.
</template>

<script>
import NPolicyCard from "../../../components/PolicyCard.vue";

import mixin from "../mixin";
export default {
  name: "SummaryLeft",
  components: {
    NPolicyCard,
  },
  mixins: [mixin],
};
</script>
