export default {
  schema: {
    fields: [
      {
        model: "csat.date",
        type: "datepicker",
        inputType: "text",
        styleClasses: "d-inline-block col-md-6",
        label: "CSAT Date",
        min: "1900-01-01",
        max: "2100-12-31",
        placeholder: "DD/MM/YYYY",
        required: false,
      },
      {
        model: "csat.rating",
        type: "input",
        inputType: "number",
        step: "1",
        min: "0",
        max: "10",
        styleClasses: "d-inline-block col-md-6",
        label: "CSAT",
        placeholder: "-",
        required: false,
      },
      {
        model: "csat.feedback",
        type: "textArea",
        inputType: "text",
        rows: 2,
        styleClasses: "d-inline-block col-md-6",
        label: "Client Feedback",
        placeholder: "Click here and start typing...",
        required: true,
      },
      {
        model: "csat.linkedIn",
        type: "select-cards",
        inputType: "radio",
        rows: 2,
        default: true,
        styleClasses: "d-inline-block col-md-6 align-top",
        label: "Share on LinkedIn",
        placeholder: "",
        required: true,
        cardsData: [
          {
            label: "Yes",
            card_value: true,
          },
          {
            label: "No",
            card_value: false,
          },
        ],
      },
    ],
  },
};
