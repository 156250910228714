<template lang="pug">
.zero-state-container.rounded-md.w-100.p-6.text-center
  img.my-2(:src="require(`@/assets/images/${currentState.image}`)")
  h5.hb5.pt-2.text-gray-900 {{currentState.title}}
  .pb-1.font-weight-medium.text-gray-700 {{currentState.subtitle}}
</template>

<script>
export default {
  name: "ListZeroState",
  props: {
    subject: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      stateConfig: {
        dashboardBenefitsList: {
          title: "Exciting benefits coming soon",
          subtitle: "Watch this space for some curated benefits.",
          image: "zs-benefits.svg",
        },
        dashboardPoliciesList: {
          title: "Your new insurance policy will be with you in no time",
          subtitle: "We will be right back with your insurance details.",
          image: "zs-policies.svg",
        },
      },
    };
  },
  computed: {
    currentState() {
      return this.stateConfig[this.subject];
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
.zero-state-container {
  border: 1px dashed rgba($black, 0.12);
  img {
    height: 5.625rem;
    width: 5.625rem;
  }
}
</style>
