<template lang="pug">
.border.shadow-2.rounded.px-0.overflow-hidden.p-0
  .d-flex.align-items-center.p-3(:class="'bg-gray-200'")
    .d-flex.flex-column
      span.font-weight-semibold(:class="'text-gray-800'") CLAIM {{ claimLetterState.docType }} LETTER
  .d-flex.bg-white.mb-4.flex-column.flex-md-row
    .d-flex.flex-column.col-12.col-md-4.pt-3
      span.text-gray-700.d-block.font-sm Received on
      span.text-gray-900.font-md {{ getFormattedDate(claimLetterState.createdAt) }}
      span.text-gray-700.d-block.font-sm {{ getFormattedTime(claimLetterState.createdAt) }}
      .d-none.d-md-flex.flex-column.mt-4.cursor-pointer(@click="preview(claimLetterState.letterLink)")
        .text-gray-700.font-sm.font-weight-medium.mb-2 View Letter
        .d-flex.border.rounded.justify-content-center.align-items-center.w-75
          .d-flex.flex-column.align-items-center.justify-content-center
            .d-flex.align-items-center.justify-content-center.rounded.bg-gray-500.py-2.px-5.mt-2
              n-icon(name="eye" variant="gray-900")
            span.text-tiber.font-xs.font-weight-light.mt-2.mb-1 {{ claimLetterState.docType }}.pdf
    .d-flex.flex-column.col-12.col-md-8.p-2
      p.text-gray-800.font-weight-inter.font-sm.mx-1.mt-2 {{ claimLetterState.content }}
    .d-flex.flex-column.col-12.d-md-none.mt-2(@click="preview(claimLetterState.letterLink)")
      .cursor-pointer
        .text-gray-700.font-sm.font-weight-medium.mb-2 View Letter
        .d-flex.border.rounded.justify-content-center.align-items-center.w-50
          .flex-column.align-items-center.justify-content-center
            .align-items-center.justify-content-center.rounded.bg-gray-500.py-2.px-5.mt-2
              n-icon(name="eye" variant="gray-900")
            span.text-tiber.font-xs.font-weight-light.mt-2.mb-1 {{ claimLetterState.docType }}.pdf
  template(v-if="claimLetterState.docType === 'REJECTION'")
    .border-top.mt-4.mb-4.mx-3
    p.text-gray-800.font-weight-inter.font-sm.mt-2.mb-2.mx-3 {{ supportState.content }}
    .bg-alabaster.d-inline-flex.flex-column.flex-md-row.py-2.px-2.rounded.mx-3.mb-3
      .d-flex.mb-2.mb-md-0
        n-icon(name="call" variant="grey-800")
        span.text-gray-800.font-md {{ 'Call your claim associate' }}&nbsp;
      template(v-if="claimOwner")
      strong.ml-4.ml-md-0.my-md-0.d-block.d-md-inline.font-md.text-gray-800  {{ claimOwner }}&nbsp;
      .d-flex.mb-2.mb-md-0.mt-1.mt-md-0.my-md-0.d-block.d-md-inline
        span.ml-4.ml-md-0.my-md-0.d-block.d-md-inline.font-md.text-gray-800  {{ 'at' }}&nbsp;
        a(:href="`tel:${exotelNumber || '040-49174207'}`").d-block.d-md-inline.font-md.text-gray-800  {{ exotelNumber || '040-49174207' }}
    .d-flex.mx-3.mb-4
      n-button(
        variant="outline-secondary"
        size="sm",
        buttonText="Get Support",
        @click="showSalesIqChat")
</template>

<script>
import moment from "moment";
import { ClaimDocument } from "@/common/enums/claims/claimDocument";
import NIcon from "@/components/NovaIcon.vue";
import utils from "@/utils";
import NButton from "@/components/NovaButton";

export default {
  name: "ClaimLetters",
  components: {
    NIcon,
    NButton,
  },
  props: {
    letters: {
      type: Array,
      default: () => [],
    },
    claimOwner: {
      type: String,
      default: null,
    },
    exotelNumber: {
      type: String,
      default: null,
    },
  },
  computed: {
    claimLetterState() {
      const letter = this.letters?.filter(
        (letter) =>
          letter.docType === ClaimDocument.SETTLEMENT_LETTER || letter.docType === ClaimDocument.REJECTION_LETTER
      );
      const docType = letter?.docType === ClaimDocument.SETTLEMENT_LETTER ? "APPROVAL" : "REJECTION";
      const letterLink = letter[0]?.fileDetails.url;
      const createdAt = letter?.createdAt;
      const content =
        docType === "APPROVAL"
          ? "Great news! Your claim has been validated and approved, and we're thrilled to confirm its acceptance. Your reimbursement is on its way!"
          : "You’ve received a rejection letter from your Insurer/TPA for your claim. The letter contain reasons for which your claim was denied.We understand this may be disappointing and we are here to assist you with any inquiries or concerns.";
      return { docType, letterLink, createdAt, content };
    },
    supportState() {
      return {
        content: "Want to figure out what went wrong? No worries. Call us and we will answer your questions asap!",
      };
    },
  },
  methods: {
    preview(url) {
      window.open(url, "_blank");
    },
    getFormattedDate(date) {
      return utils.getFormattedDate(date, "D MMMM YYYY");
    },
    getFormattedTime(date) {
      return moment(date).format("h:mm A");
    },
    showSalesIqChat() {
      this.$store.commit("toggleSupportContainer");
    },
  },
};
</script>
