<template lang="pug">
div
  | {{  minutes  }}:{{  seconds < 10 ? '0' + seconds : seconds  }}
</template>

<script>
export default {
  props: {
    initialTime: {
      type: String,
      required: true,
    },
    resetSignal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      time: 0,
      minutes: 0,
      seconds: 0,
      intervalId: null,
    };
  },
  watch: {
    initialTime() {
      this.initializeTimer();
    },
    resetSignal() {
      this.resetTimer();
    },
  },
  created() {
    this.initializeTimer();
  },
  beforeDestroy() {
    this.stopTimer();
  },
  methods: {
    initializeTimer() {
      this.$nextTick(() => {
        if (this.intervalId) {
          this.stopTimer();
        }
        const parts = this.initialTime.split(":");
        this.minutes = parseInt(parts[0], 10);
        this.seconds = parseInt(parts[1], 10);
        this.time = this.minutes * 60 + this.seconds;
        this.startTimer();
      });
    },
    startTimer() {
      this.intervalId = setInterval(() => {
        if (this.time === 0) {
          this.$emit("time-up", this.initialTime);
          this.stopTimer();
        } else {
          this.time--;
          this.minutes = Math.floor(this.time / 60);
          this.seconds = this.time % 60;
        }
      }, 1000);
    },
    stopTimer() {
      clearInterval(this.intervalId);
    },
    resetTimer() {
      this.stopTimer();
      this.initializeTimer();
    },
  },
};
</script>
