export const ClaimSourceTypes = {
  PORTAL_USER: "PORTAL_USER",
  PORTAL_SUPER_ADMIN: "PORTAL_SUPER_ADMIN",
  MEDIASSIST_API: "MEDIASSIST_API",
  MEDIASSIST_DUMP: "MEDIASSIST_DUMP",
  WHATSAPP_BOT: "WHATSAPP_BOT",
  CRM_WEBHOOK: "CRM_WEBHOOK",
  CRM_EXPORT: "CRM_EXPORT",
  BULK_UPDATE: "BULK_UPDATE",
};
