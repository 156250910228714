<template lang="pug">
n-color-picker(
  v-bind="schema",
  v-model="value",
  v-on="$listeners")
</template>

<script>
import { abstractField } from "vue-form-generator";
import NColorPicker from "../NovaColorPicker.vue";
export default {
  name: "FieldColorPicker",
  components: {
    NColorPicker,
  },
  mixins: [abstractField],
};
</script>
