<template lang="pug">
.summary-container.px-4.mt-4.pt-4.pb-3.d-flex.justify-content-between
  .title
    .d-flex.text-nowrap.align-items-center
      .hb4.d-inline-block.mb-1.font-weight-bold.org-name.text-truncate(id="org") {{`${orgName}`}}
      .hb4.d-inline-block.mb-1.font-weight-bold.org-name.text-truncate(v-if="orgName") {{`\&nbsp-\&nbsp`}}
      .hb4.d-inline-block.mb-1.font-weight-bold {{title || ""}}
    b-tooltip(
      target="org",
      triggers="hover")
      | {{ orgName }}
    n-chip.badge-light-danger.ml-2.py-1(v-if="isBatchDelayed", variant="danger", :iconWhite="false", chipIcon="error", pill) Delayed
    .info-text {{ subtext || "" }}
  .ml-auto.mr-2.d-flex.text-right(v-if="isPremiumBreakupEnabled")
    .subtitle.mr-3.d-flex.flex-column
      template(v-if="batchPremiumStatus().isPaid")
        n-chip.info-text-container.ml-1(variant="light", :isMinimal="true",)
          span.info-text Premium Paid
          template(v-slot:icon)
            i.icon-check-circle.mr-1.text-success
      template(v-else)
        .info-text {{ premiumHeader }}
      div.hb4.text-gray-900
        span {{ getUpdatedPremiumAmount() }}
    .vertical-separator
      .wrapper-element(v-b-popover.hover.focus.top="!isRaterBaseConfigPresent ? 'Please Update Rater Details to continue' : ''")
        n-button.px-3.ml-3(
          :disabled="!isRaterBaseConfigPresent",
          buttonText="View Details",
          rightImageIcon="chevron-right",
          variant="light",
          @click="$router.push({ name: getPremiumDetailsRoute, params: { batchId: batch?.id }})"
        )

  .d-flex
    b-dropdown.super-admin-dropdown.ml-2(
      v-if="isSuperAdmin && !isBatchRejected",
      no-caret,
      right,
      variant="light",
    )
      template(v-slot:button-content)
        img.img-fluid.mr-3(:src="require(`@/assets/images/three-vertical-dots.svg`)")
        span.font-weight-medium More
      b-dropdown-item-button(
        :disabled="!isMarkNoEndorsementFromHREnabled",
        @click="showModalHelper($options.entitySelectionOperations.MARK_NO_ENDORSEMENT, 'basic-modal', true)") Mark No Endorsements
      b-dropdown-item-button(@click="openPremiumUpdateModal" :disabled="isMarkedPaid") Update Premium
      b-dropdown-item-button(v-b-modal="'resync-premium-modal'", :disabled="!isDynamicPremiumEnabled || isMarkedPaid") Resync Premium
      b-dropdown-item-button(
        :disabled="isCurrentBatch",
        v-b-tooltip.hover.left,
        :title="isCurrentBatch ? 'Not available for current batch' : ''"
        @click="showModalHelper($options.entitySelectionOperations.DOC_UPLOAD, 'endorsement-document-upload-modal', true)"
      ) Upload Documents
      b-dropdown-item-button(
        @click="showModalHelper($options.entitySelectionOperations.SEND_ESTIMATES, 'entity-selection-modal', true)"
      ) Send CD Estimate Mail
      b-dropdown-item-button(
        :disabled="isCurrentBatch || isBatchCompleted",
        v-b-tooltip.hover.left,
        :title="getBatchDelayTooltipText"
        @click.prevent="$bvModal.show('batch-delay-modal')"
      ) {{ getDelayButtonText }}
        batch-delay-modal#batch-delay-modal(
          :batchId="batch.id",
          :delayStatusConfig="batch.meta.delayStatusConfig",
          @setBatchDelayStatusFlag="setBatchDelayStatusFlag",
          @close="$bvModal.hide('batch-delay-modal')",
        )
      b-dropdown-item-button(
        @click="resyncBatchStatus()"
      ) Resync Batch Status
      b-dropdown-item-button(
        :disabled="!insurerEndorsementBatches.length",
        v-b-toggle.insurer-batch-side-sheet,
      ) Show Insurer Batches Status
      b-dropdown-item-button(
        :disabled="transferBatchDisable",
        v-b-tooltip.hover.left,
        :title="transferBatchDisable ? 'Not available for batch in current stage' : ''",
        @click="handleTransferModal('show')") Transfer Batches
      transfer-batch-modal(
        :id="'transfer-batch-modal-for-batch'",
        :v-if="!this.$route.path.includes(`org-admin/`)",
        @update-batch="$emit('update-batch', $event)",
        @modal-closed="handleTranferBatchModal('hide')",
        :batches="getTransferrableToBatches"
    )

    b-dropdown.endorsement-documents-dropdown.ml-2(
      v-if="!isCurrentBatch && !isBatchRejected",
      no-caret,
      right,
      variant="light",
    )
      template(v-slot:button-content)
        span.font-weight-medium Download Documents
        i.ml-2.icon-chevron-down
      template(v-for="(policyName, index) in getUserPolicyNames()")
        b-dropdown-group(:header="policyName")
          b-dropdown-item.font-sm.font-inter.font-weight-medium.text-gray-900(
            v-for="(docType) in Object.values($options.EndorsementDocumentsEnum)",
            v-if="isDocumentPresent(policyName, docType)",
            @click="downloadDocument(policyName, docType)",
          ) Download {{ getDropdownHeading(docType) }}
          b-dropdown-divider.text-gray-400(v-if="index < (Object.values($options.EndorsementDocumentsEnum).length -1)")
  Premium-update-modal(
    v-if="isSuperAdmin && orgId && batch",
    :orgId="orgId",
    :batch="batch",
    @modal-closed="closePremiumUpdateModal",
    @refetchBatch="$emit('refetchBatch', $event)",
    :isDynamicPremiumEnabled="isDynamicPremiumEnabled",
  )
  insurer-batch-side-sheet(
    id="sidesheet",
    :insurer-batches="insurerEndorsementBatches",
    :user-change-batch-id="getSelectedBatchId",
  )
  basic-modal(
    title="Hold on!",
    :description="`Are you sure you don't have any new employee or dependent related additions, deletions or changes for ${monthOnCalendar} ${yearOfBatch} batch?`",
    proceedBtnText="I am sure ->",
    @cancelBtnClicked="handleBasicModalClose()",
    @proceedBtnClicked="handleModalSwitch('basic-modal')"
  )
  n-modal(
    id="endorsement-document-upload-modal",
    centered,
    title="Uploaded Files",
    size="lg",
    :hide-footer="false",
    hide-backdrop,
    no-close-on-backdrop,
    no-close-on-esc,
  )
    template(v-slot:modal-header)
      span Uploaded Files
    file-upload.pt-1.pb-3(
      v-model="documents.endorsement",
      :accept="$options.acceptedEndorsementDocsFormat",
      :options="Object.values($options.EndorsementDocumentsEnum)",
      :secondaryOptions="getUserPolicyNames()",
      :allowSecondarySelector="true",
      :allowZipDownload="false",
      :allowFilePreview="false",
      :uploadFileAction="$options.FileActionEnum.EndorsementDoc",
      @delete-file="deleteUploadedEndorsementDocument",
      @file-uploaded="linkFileToOrg")
    template(v-slot:modal-footer)
      .d-flex.justify-content-end
        n-button.font-sm.mr-2(
          variant="light",
          buttonText="Close",
          @click="closeDocUploadModal",)
        n-button.font-sm(
          variant="dark",
          buttonText="Continue",
          type="submit",
          :disabled="isDocUploadContinueDisabled()",
          @click="handleModalSwitch('endorsement-document-upload-modal')")

  special-modal(
    id="resync-premium-modal",
    headerIcon="icon",
    :bgColor="'mustard-200'",
    :iconColor="'mustard-600'",
    logoIcon="warning",
    centered)
    template(v-slot:title)
      h3.hb3.d-inline.font-weight-semibold.text-gray-900 Confirm Premium Resync
    template(v-slot:modal-content)
      .text-center
        | Are you sure you want to resync the premium for this batch?
        br
        b-spinner.mt-3(v-if="loading", background-color="rgba(255,255,255,0.5)")
    template(v-slot:buttons-area)
      .d-flex.align-items-center.mt-3.mb-2
        n-button.px-3(variant="light", button-text="Cancel", @click="$bvModal.hide('resync-premium-modal')")
        n-button.px-5.ml-2(variant="dark", button-text="Continue ->", @click="resyncBatchPremium()")

  entity-selection-modal(
    v-if="isSuperAdmin && orgId && batch && $options.userSelectionModalStaticData[currentUserSelection]"
    :entities="getEntitiesData",
    @close="handleUserSelectionBack",
    @submit="handleUserSelectionContinue",
    :entityName="$options.userSelectionModalStaticData[currentUserSelection].entityName",
    :selectionRequired="$options.userSelectionModalStaticData[currentUserSelection].selectionRequired",
    :closeBtnText="entityModalCloseButtonText",
    :continueBtnText="$options.userSelectionModalStaticData[currentUserSelection].continueBtnText",
    hide-backdrop,
    no-close-on-backdrop,
    no-close-on-esc,
    hide-header-close
  )
    template(v-slot:description)
      span.text-gray-700.font-sm-font-weight-medium {{ $options.userSelectionModalStaticData[currentUserSelection].operations[currentEntityOperation].description }}
  .endorsement-doc-upload-backdrop.modal-backdrop(v-if="showDocUploadBackdrop")
</template>

<script>
import { mapGetters } from "vuex";
import gql from "graphql-tag";
import moment from "moment/moment";
import NModal from "../../../../components/NovaModal.vue";
import adminDefs from "../../admin/definitions";
import SpecialModal from "../../../../components/SpecialModal.vue";
import orgDef from "../../admin/definitions/orgs";
import { UserChangeBatchStatus } from "../../../../common/enums/userChangeBatchStatus.enum";
import mixin from "../mixin";
import PremiumUpdateModal from "./PremiumUpdateModal.vue";
import InsurerBatchSideSheet from "./InsurerBatchSideSheet.vue";
import BatchDelayModal from "./BatchDelayModal.vue";
import TransferBatchModal from "./TransferBatchModal.vue";
import EntitySelectionModal from "@/components/EntitySelectionModal.vue";
import NButton from "@/components/NovaButton.vue";
import utils from "@/utils";
import NChip from "@/components/NovaChip.vue";
import FileUpload from "@/components/FileUpload.vue";
import { EndorsementDocuments, FileAction, FileGroupId, EndorsementBatchStatus } from "@/common/enums";
import resDefs from "@/portal/pages/admin/definitions";
import BasicModal from "@/components/BasicModal.vue";

const endorsementDocsDisplayText = {
  active_list: "Active List",
  endorsement_copy: "Endorsement Copy",
  cd_statement: "CD Statement",
  annexure: "Annexure",
  tax_invoice: "Tax Invoice",
};

export default {
  name: "SummaryCard",
  components: {
    NButton,
    PremiumUpdateModal,
    InsurerBatchSideSheet,
    EntitySelectionModal,
    TransferBatchModal,
    NChip,
    NModal,
    FileUpload,
    SpecialModal,
    BasicModal,
    BatchDelayModal,
  },
  mixins: [mixin],
  props: {
    title: {
      type: String,
      default: null,
    },
    subtext: {
      type: String,
      default: null,
    },
    orgName: {
      type: String,
      required: true,
    },
    orgId: {
      type: String,
      default: null,
    },
    batch: {
      type: Object,
      default: null,
    },
    isCurrentBatch: {
      type: Boolean,
    },
    batches: {
      type: Array,
      default: () => [],
    },
    isDynamicPremiumEnabled: {
      type: Boolean,
    },
    isPremiumBreakupEnabled: {
      type: Boolean,
    },
    selectedBatchId: {
      type: String,
    },
    transferBatchDisable: {
      type: Boolean,
      default: false,
    },
    isEndorsementPremiumPresent: {
      type: Boolean,
      default: false,
    },
    insurerSubmissionCounter: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      documents: {
        endorsement: [],
      },
      benefitNameToIdMapping: {},
      entitiesData: {
        orgAdmins: [],
        CXAccountOwners: [],
      },
      insurerEndorsementBatches: [],
      currentUserSelection: "org-admins",
      showDocUploadBackdrop: false,
      loading: false,
      isDocUploadProcessing: false,
      isSendEstimatesProcessing: false,
      isMarkNoEndorsementProcessing: false,
      isBatchDelayed: false,
    };
  },
  computed: {
    ...mapGetters(["selectedOrgEntity", "getFeatureFlags", "isOrgEntityAdmin"]),
    orgEntityId() {
      if (this.isOrgEntityAdmin) return this.$store.state.user?.orgEntity?.id;

      if (this.selectedOrgEntity?.id) return this.selectedOrgEntity?.id;

      return null;
    },
    getTransferrableToBatches() {
      return this.batches.filter(
        (batch) =>
          batch.id !== this.selectedBatchId &&
          ![
            UserChangeBatchStatus.PROVIDER_OK,
            UserChangeBatchStatus.DONE,
            UserChangeBatchStatus.NOVA_OK,
            UserChangeBatchStatus.REJECTED,
          ].includes(batch.dbStatus)
      );
    },
    premiumHeader() {
      return this.isEndorsementPremiumPresent ? "Total Premium" : "Estimated Premium";
    },
    isSuperAdmin() {
      return !this.$route.path.includes("org-admin/");
    },
    currentEntityOperation() {
      if (this.isDocUploadProcessing) return this.$options.entitySelectionOperations.DOC_UPLOAD;
      else return this.$options.entitySelectionOperations.SEND_ESTIMATES;
    },
    getSelectedOrgAdmins() {
      return this.entitiesData.orgAdmins
        .filter((orgAdmin) => orgAdmin.isSelected)
        .map((orgAdmin) => ({ name: orgAdmin.name, email: orgAdmin.email, user_id: orgAdmin.userId }));
    },
    getSelectedCXAccountOwners() {
      return this.entitiesData.CXAccountOwners.filter((cxOwner) => cxOwner.isSelected).map((cxOwner) => ({
        name: cxOwner.name,
        user_id: cxOwner.user?.id,
        email: cxOwner.user?.email,
      }));
    },
    getPremiumDetailsRoute() {
      return this.isSuperAdmin ? "admin_premium_details" : "org_admin_premium_details";
    },
    getSelectedBatchId() {
      return this.$route.params.batchId || this.selectedBatchId;
    },
    getEntitiesData() {
      if (this.currentUserSelection === "cx-owners") {
        // if users selected already, pass them to modal
        if (!this.entitiesData.CXAccountOwners.length) {
          return this.CXAccountOwners || [];
        }
        return this.entitiesData.CXAccountOwners;
      }

      if (!this.entitiesData.orgAdmins.length) {
        return this.orgAdmins || [];
      }
      return this.entitiesData.orgAdmins;
    },
    endingDate() {
      return this.batch?.endingAt || utils.getLastDateOfMonth(new Date());
    },
    monthOnCalendar() {
      return utils.getMonthShortForm(utils.getMonth(this.endingDate).toLowerCase());
    },
    yearOfBatch() {
      return moment(this.endingDate).year();
    },
    isMarkNoEndorsementFromHREnabled() {
      return !this.batch?.meta?.markedNoEndorsementByHR && this.batch?.status === EndorsementBatchStatus.EMPTY;
    },
    isBatchCompleted() {
      // corresponds to "done" in backend
      return this.batch?.status === "completed";
    },
    isBatchRejected() {
      return this.batch?.status === "rejected";
    },
    entityModalCloseButtonText() {
      if (this.isSendEstimatesProcessing && this.currentUserSelection === "org-admins") return "Cancel";
      else return this.$options.userSelectionModalStaticData[this.currentUserSelection].closeBtnText;
    },
    getDelayButtonText() {
      if (this.isBatchDelayed) {
        return "Edit Delay Status";
      }
      return "Mark as Delayed";
    },
    getBatchDelayTooltipText() {
      if (this.isBatchCompleted) {
        return "Can't mark as delayed once a batch is completed";
      }
      if (this.isCurrentBatch) {
        return "Not available for current batch";
      }
      return "";
    },
    isMarkedPaid() {
      return this.batch.meta?.premiumData?.hasOwnProperty("isPaid") && this.batch.meta.premiumData.isPaid;
    },
  },
  watch: {
    "batch.meta.delayStatusConfig.isDelayed"(isDelayed) {
      this.isBatchDelayed = isDelayed;
    },
    insurerSubmissionCounter: function () {
      this.$apollo.queries.insurerBatches.refetch();
    },
  },
  created() {
    this.$options.entitySelectionOperations = {
      DOC_UPLOAD: "doc_upload",
      SEND_ESTIMATES: "send_estimates",
      MARK_NO_ENDORSEMENT: "mark_no_endorsement",
    };
    this.$options.userSelectionModalStaticData = {
      "org-admins": {
        entityName: "Org Admins",
        selectionRequired: true,
        closeBtnText: "<- Go Back",
        continueBtnText: "Continue ->",
        operations: {
          doc_upload: {
            description:
              "Document related emails will be sent to the selected org admins. At least one org admin should be selected.",
          },
          send_estimates: {
            description:
              "Premium related emails will be sent to the selected org admins. At least one org admin should be selected.",
          },
          mark_no_endorsement: {
            description:
              "Email notification for marking this batch as no endorsements would be sent to the selected org admins. At least one org admin should be selected.",
          },
        },
      },
      "cx-owners": {
        entityName: "CX Owners",
        selectionRequired: false,
        closeBtnText: "<- Go Back",
        continueBtnText: "Submit",
        operations: {
          doc_upload: {
            description: "Document related emails will be sent to the selected org CX owners.",
          },
          send_estimates: {
            description: "Premium related emails will be sent to the selected org CX owners.",
          },
        },
      },
    };
    this.$options.acceptedEndorsementDocsFormat =
      ".pdf, .csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel";
    this.$options.FileActionEnum = FileAction;
    this.$options.EndorsementDocumentsEnum = EndorsementDocuments;
  },
  mounted() {
    if (this.batch?.meta?.delayStatusConfig?.isDelayed) {
      this.isBatchDelayed = this.batch.meta.delayStatusConfig.isDelayed;
    }
  },
  methods: {
    handleTranferBatchModal(changeType = "hide") {
      if (changeType === "show") {
        this.$bvModal.show("transfer-batch-modal-for-batch");
        return;
      }

      this.$bvModal.hide("transfer-batch-modal-for-batch");
    },
    getUpdatedPremiumAmount() {
      if (!this.isRaterBaseConfigPresent || (this.isBatchCompleted && !this.isEndorsementPremiumPresent)) {
        return "N/A";
      }
      return this.totalPremiumAcrossInsurers(true);
    },
    totalPremiumAcrossInsurers(withRupeeSym) {
      const premiumData = this.isEndorsementPremiumPresent
        ? this.batch.endorsementPremium
        : this.batch?.meta?.premiumData?.policyWisePremiumEstimation;
      return utils.getTotalPremiumAcrossInsurers(premiumData, withRupeeSym);
    },
    setProcessState(operationType, toggle) {
      switch (operationType) {
        case this.$options.entitySelectionOperations.DOC_UPLOAD: {
          this.isDocUploadProcessing = toggle || !this.isDocUploadProcessing;
          break;
        }
        case this.$options.entitySelectionOperations.SEND_ESTIMATES: {
          this.isSendEstimatesProcessing = toggle || !this.isSendEstimatesProcessing;
          break;
        }
        case this.$options.entitySelectionOperations.MARK_NO_ENDORSEMENT: {
          this.isMarkNoEndorsementProcessing = toggle || !this.isMarkNoEndorsementProcessing;
          break;
        }
      }
    },
    batchPremiumStatus() {
      const status = {};
      if (this.batch?.meta?.premiumData) {
        status.amount = this.totalPremiumAcrossInsurers();
        status.isPaid = this.batch.meta.premiumData.isPaid;
      }
      return status;
    },
    getDropdownHeading(docType) {
      return endorsementDocsDisplayText[docType];
    },
    isDocumentPresent(policyName, docType) {
      return !!this.documents.endorsement.find((file) => file.docType === docType && file.policyName === policyName);
    },
    handleBasicModalClose() {
      this.toggleEntitySelectionBackdrop(false);
      this.$root.$emit("bv::hide::modal", "basic-modal");
    },
    closeDocUploadModal() {
      this.toggleEntitySelectionBackdrop(false);
      this.setProcessState(this.$options.entitySelectionOperations.DOC_UPLOAD, false);
      this.$bvModal.hide("endorsement-document-upload-modal");
    },
    downloadDocument(policyName, docType) {
      const document = this.documents.endorsement.find(
        (file) => file.policyName === policyName && file.docType === docType
      );
      if (!document) {
        this.$store.commit("addToast", {
          variant: "danger",
          message: "Error downloading file",
        });
      }
      this.$store.commit("addToast", {
        variant: "success",
        message: "Downloading",
      });
      window.open(document.url);
    },
    openPremiumUpdateModal() {
      this.$bvModal.show("update-premium-modal");
    },
    closePremiumUpdateModal() {
      this.$bvModal.hide("update-premium-modal");
    },
    toggleEntitySelectionBackdrop(toggle) {
      this.showDocUploadBackdrop = toggle || !this.showDocUploadBackdrop;
    },
    showModalHelper(operationType, modalName, toggle = true) {
      this.toggleEntitySelectionBackdrop(true);
      switch (operationType) {
        case this.$options.entitySelectionOperations.DOC_UPLOAD: {
          this.setProcessState(this.$options.entitySelectionOperations.DOC_UPLOAD, toggle);
          break;
        }
        case this.$options.entitySelectionOperations.SEND_ESTIMATES: {
          this.setProcessState(this.$options.entitySelectionOperations.SEND_ESTIMATES, toggle);
          break;
        }
        case this.$options.entitySelectionOperations.MARK_NO_ENDORSEMENT: {
          this.setProcessState(this.$options.entitySelectionOperations.MARK_NO_ENDORSEMENT, toggle);
          break;
        }
      }
      this.$bvModal.show(modalName);
    },
    isDocUploadContinueDisabled() {
      // at least one file with docType is required
      return !(
        this.documents.endorsement.length &&
        !this.documents.endorsement.find((file) => !file.docType || !file.policyName)
      );
    },
    handleModalSwitch(prevModal) {
      this.$bvModal.hide(prevModal);
      this.openUserSelectionModal();
    },
    openUserSelectionModal() {
      this.$bvModal.show("entity-selection-modal");
    },
    async deleteUploadedEndorsementDocument(File) {
      try {
        // if the document isn't linked to the batch as endorsement doc, simply delete from backend
        const policyId = this.benefitNameToIdMapping[File.policyName];
        if (!this.addedEndorsementDocuments?.[policyId]?.[File.docType]) {
          const result = await this.$apollo.mutate({
            mutation: adminDefs.files?.deleteOrgGroupFileEdges,
            variables: {
              fileId: File.id,
              orgId: this.orgId,
              fileGroupIds: [utils.toGlobalId("FileGroup", FileGroupId.ENDORSEMENT)],
            },
          });
          if (!result?.data?.deleteOrgGroupFileEdges?.success) {
            throw new Error("Could not delete file");
          }
        } else {
          // remove the document by deleting and de-linking from the batch
          const result = await this.$apollo.mutate({
            mutation: adminDefs.userChanges.deleteEndorsementDocument,
            variables: {
              batchId: this.batch.id,
              policyId: policyId,
              docType: File.docType,
              fileId: File.id,
            },
          });
          if (!result?.data?.deleteEndorsementDocuments?.success) {
            throw new Error("Could not delete file");
          }
        }
        this.$store.commit("addToast", {
          variant: "success",
          message: "Endorsement document deleted.",
        });
      } catch (err) {
        console.error(err);
        this.$store.commit("addToast", {
          variant: "danger",
          message: "Expected error occurred while deleting the endorsement document.",
        });
      }
    },
    async resyncBatchStatus() {
      this.loading = true;
      try {
        await this.$apollo.mutate({
          mutation: adminDefs.userChanges.resyncBatchStatus,
          variables: {
            batchId: this.batch.id,
          },
        });
        this.$emit("refetchBatch", this.batch.id);
        this.$store.commit("addToast", {
          variant: "success",
          message: `Successfully re-synced batch status for the batch`,
        });
        this.loading = false;
      } catch (err) {
        this.loading = false;
        this.$store.commit("addToast", {
          variant: "danger",
          message: `Error while re-syncing batch status for the batch`,
        });
        throw err;
      }
    },
    async resyncBatchPremium() {
      this.loading = true;
      try {
        await this.$apollo.mutate({
          mutation: adminDefs.userChanges.resyncPremiumForBatch,
          variables: {
            batchId: this.batch.id,
          },
        });
        this.$emit("refetchBatch", this.batch.id);
        this.$store.commit("addToast", {
          variant: "success",
          message: `Successfully re-synced premium for the batch`,
        });
        this.loading = false;
        this.$bvModal.hide("resync-premium-modal");
      } catch (err) {
        this.loading = false;
        this.$store.commit("addToast", {
          variant: "danger",
          message: `Error while re-syncing premium for the batch`,
        });
        throw err;
      }
    },
    async linkFileToOrg(file) {
      if (!this.orgId) {
        this.$store.commit("addToast", {
          variant: "danger",
          message: "Error uploading endorsement files, please try again",
        });
      }
      try {
        await this.$apollo.mutate({
          mutation: adminDefs.files.upsertOrgGroupFileEdges,
          variables: {
            fileId: file.id,
            orgId: this.orgId,
            orgEntityId: this.orgEntityId || null,
            fileGroupIds: [utils.toGlobalId("FileGroup", FileGroupId.ENDORSEMENT)],
          },
          update: (store, { data }) => {
            const oldUser = store.readFragment({
              id: this.orgId,
              fragment: adminDefs.orgs.filesFragment,
              fragmentName: adminDefs.orgs.filesFragmentName,
            });
            const fileEdge = data.upsertOrgGroupFileEdges.orgGroupFileEdge;
            oldUser.files.push(fileEdge);
            store.writeFragment({
              id: this.orgId,
              fragment: adminDefs.orgs.filesFragment,
              data: oldUser,
              fragmentName: adminDefs.orgs.filesFragmentName,
            });
          },
        });
      } catch (err) {
        console.error(err);
      }
    },
    handleUserSelectionBack() {
      if (this.currentUserSelection === "org-admins") {
        this.$bvModal.hide("entity-selection-modal");
        if (this.isDocUploadProcessing) this.$bvModal.show("endorsement-document-upload-modal");
        else if (this.isMarkNoEndorsementProcessing) this.$bvModal.show("basic-modal");
        else this.toggleEntitySelectionBackdrop(false);
      }
      this.currentUserSelection = "org-admins";
    },
    handleUserSelectionContinue(updatedEntitiesData) {
      if (this.currentUserSelection === "cx-owners") {
        this.entitiesData.CXAccountOwners = updatedEntitiesData;
        if (this.isDocUploadProcessing) {
          this.upsertEndorsementData();
          this.setProcessState(this.$options.entitySelectionOperations.DOC_UPLOAD, false);
        } else if (this.isMarkNoEndorsementProcessing) {
          this.markNoEndorsementFromHR();
          this.setProcessState(this.$options.entitySelectionOperations.MARK_NO_ENDORSEMENT, false);
        } else {
          this.sendEstimatesMail();
          this.setProcessState(this.$options.entitySelectionOperations.SEND_ESTIMATES, false);
        }
        this.$bvModal.hide("entity-selection-modal");
        this.toggleEntitySelectionBackdrop(false);
        return;
      }
      this.entitiesData.orgAdmins = updatedEntitiesData;
      this.currentUserSelection = "cx-owners";
    },
    async sendEstimatesMail() {
      let result;
      try {
        // filter and format the orgAdmin data
        const selectedOrgAdmins = this.getSelectedOrgAdmins;

        // filter and format the cxOwner data
        const selectedCXOwners = this.getSelectedCXAccountOwners;
        result = await this.$apollo.mutate({
          mutation: adminDefs.userChangeBatches.sendPremiumEstimateMails,
          variables: {
            batchId: this.batch.id,
            orgAdmins: selectedOrgAdmins,
            cxEntities: selectedCXOwners,
          },
        });
        this.$bvModal.hide("entity-selection-modal");
      } catch (err) {
        console.error(err);
      }
      if (result?.data?.sendPremiumEstimateMails?.success) {
        this.$store.commit("addToast", {
          variant: "success",
          message: "Successfully sent emails to selected entities",
        });
      } else {
        this.$store.commit("addToast", {
          variant: "danger",
          message: "Unable to send emails to selected entities",
        });
      }
    },
    async upsertEndorsementData() {
      const endorsementDocuments = {};
      this.documents.endorsement.forEach((file) => {
        if (!file.policyName || !file.docType) {
          return;
        }
        const policyId = this.benefitNameToIdMapping[file.policyName];
        endorsementDocuments[policyId] = { ...endorsementDocuments[policyId] };
        endorsementDocuments[policyId][file.docType] = file.id;
      });
      // filter and format the orgAdmin data
      const selectedOrgAdmins = this.getSelectedOrgAdmins;

      // filter and format the cxOwner data
      const selectedCXOwners = this.getSelectedCXAccountOwners;

      try {
        await this.$apollo.mutate({
          mutation: adminDefs.userChanges.upsertEndorsementDocuments,
          variables: {
            batchId: this.batch.id,
            endorsementDocuments,
            orgAdmins: selectedOrgAdmins,
            cxEntities: selectedCXOwners,
          },
        });
        this.$store.commit("addToast", {
          variant: "success",
          message: "Successfully uploaded.",
        });
        this.$bvModal.hide("endorsement-document-upload-modal");
        this.$emit("refetchBatch", this.batch.id);
      } catch (err) {
        console.error(err);
      }
    },
    getUserPolicyNames() {
      this.org?.benefits?.forEach((benefit) => {
        if (benefit.node.isPolicy) {
          this.benefitNameToIdMapping[`[${benefit.node.type}]-${benefit.node.name}`] = benefit.node.id;
        }
      });
      return Object.keys(this.benefitNameToIdMapping);
    },
    async markNoEndorsementFromHR() {
      let result;
      // filter and format the orgAdmin data
      const selectedOrgAdmins = this.getSelectedOrgAdmins;

      // filter and format the cxOwner data
      const selectedCXOwners = this.getSelectedCXAccountOwners;
      try {
        result = await this.$apollo.mutate({
          mutation: resDefs.userChangeBatches.markNoEndorsementFromHR,
          variables: {
            batchId: this.batch?.id,
            orgAdmins: selectedOrgAdmins,
            cxEntities: selectedCXOwners,
          },
        });
      } catch (e) {
        console.error("API returned with an error", e);
      }
      if (result?.data?.markNoEndorsementFromHR?.success) {
        this.$store.commit("addToast", {
          variant: "success",
          message: `"No endorsement" marked for the ${this.monthOnCalendar} ${this.yearOfBatch} batch`,
        });
      } else {
        this.$store.commit("addToast", {
          variant: "danger",
          message: `Unable to mark "No Endorsement" for the ${this.monthOnCalendar} ${this.yearOfBatch} batch`,
        });
      }
      this.$bvModal.hide("basic-modal");
      setTimeout(() => {
        this.$router.go();
      }, 2000);
    },
    setBatchDelayStatusFlag(isDelayed) {
      this.isBatchDelayed = isDelayed;
    },
    handleTransferModal(changeType = "hide") {
      if (changeType === "show") {
        this.$bvModal.show("transfer-batch-modal-for-batch");
        return;
      }
      this.$bvModal.hide("transfer-batch-modal-for-batch");
    },
    getEndoDocsWithPolicyName(endorsementDocs) {
      const uploadedDocuments = [];
      if (endorsementDocs) {
        if (Object.keys(this.benefitNameToIdMapping)?.length === 0) this.getUserPolicyNames();

        Object.keys(endorsementDocs || {}).forEach((policyId) => {
          for (const [docType, file] of Object.entries(endorsementDocs[policyId])) {
            file.policyName = Object.keys(this.benefitNameToIdMapping).find(
              (name) => this.benefitNameToIdMapping[name] === policyId
            );
            file.docType = docType;
            file.createdAt = file.created_at;
            uploadedDocuments.push(file);
          }
        });
      }
      return uploadedDocuments;
    },
  },
  apollo: {
    orgAdmins: {
      skip() {
        return !this.orgId || !this.isSuperAdmin;
      },
      query: gql`
        query getActiveOrgAdmins($orgIds: [ID]!) {
          getActiveOrgAdmins(orgIds: $orgIds) {
            edges {
              node {
                userId
                name
                email
                orgId
              }
            }
            totalCount
          }
        }
      `,
      variables() {
        return {
          orgIds: [this.orgId],
        };
      },
      update(data) {
        return data.getActiveOrgAdmins.edges.map(({ node }) => node);
      },
    },
    insurerBatches: {
      skip() {
        return !this.isSuperAdmin || !this.batch.id;
      },
      query: adminDefs.insurerBatches?.getInsurerEndorsementBatches,
      variables() {
        return {
          batchId: this.batch.id,
        };
      },
      update(data) {
        this.insurerEndorsementBatches = data.getInsurerEndorsementBatches.edges.map(({ node }) => node) || [];
        return this.insurerEndorsementBatches;
      },
    },
    CXAccountOwners: {
      skip() {
        return !this.orgId || !this.isSuperAdmin;
      },
      query: adminDefs.CXAccountOwners.listQuery,
      variables() {
        return {
          filter: {
            orgId: this.orgId,
          },
        };
      },
      update(data) {
        return data.CXAccountOwners.edges.map(({ node }) => node);
      },
    },
    org: {
      query: orgDef.singleQuery,
      skip() {
        return !this.orgId;
      },
      variables() {
        return {
          id: this.orgId,
        };
      },
      update(data) {
        return data.node;
      },
    },
    addedEndorsementDocuments: {
      skip() {
        return !this.batch.id;
      },
      query: adminDefs.userChangeBatches.getEndorsementBatchDocuments,
      fetchPolicy: "no-cache",
      variables() {
        return {
          batchId: this.batch.id,
        };
      },
      update(data) {
        this.documents.endorsement = this.getEndoDocsWithPolicyName(data.getEndorsementBatchDocuments);
        return data.getEndorsementBatchDocuments;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
@import "@/assets/styles/_typography.scss";
.summary-container {
  height: 5.5rem;
  background-color: $white;
  border-top-left-radius: $border-radius;
  border-top-right-radius: $border-radius;
  border: 1px solid $gray-300;

  .info-text-container {
    border: 0;
    padding-right: 0;
  }
  .info-title {
    color: $gray-800;
    @extend .font-weight-semibold;
  }

  .org-name {
    max-width: 15rem;
  }

  .info-text {
    color: $gray-700;
    @extend .font-md;
    @extend .font-weight-medium;
  }
}
.vertical-separator {
  border-left: 1px solid $gray-500;
}
</style>

<style lang="scss">
.endorsement-documents-dropdown,
.super-admin-dropdown {
  .btn {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.super-admin-dropdown {
  .dropdown-item {
    height: 2.625rem;
  }
}
</style>
