const app = {
  isApp: !!window.ReactNativeWebView,
  request(reqType, data) {
    const request = {
      action: reqType,
      payload: data || {},
    };
    if (window.ReactNativeWebView) {
      return new Promise((resolve, reject) => {
        const handleMessage = function (event) {
          const response = JSON.parse(event.data);
          resolve(response);
          window.removeEventListener("message", handleMessage, false);
        };

        window.addEventListener("message", handleMessage);
        window.ReactNativeWebView.postMessage(JSON.stringify(request));
      });
    } else {
      return null;
    }
  },
  send(action, payload = {}) {
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(JSON.stringify({ action, payload }));
    }
  }
};

export default app;
