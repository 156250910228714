import gql from "graphql-tag";
import moment from "moment";
import { mapGetters } from "vuex";
import { apolloClient } from "../../../apollo";
import adminDefs from "../../admin/definitions";
import { orgBenefitFragment } from "../../admin/fragments";
import userDefs, { getCitiesQuery } from "../definitions";
import { DependentStatus, AcceptedRelations, AcceptedGenders } from "../../../../common/enums";
import { MY_INFO } from "../../../../common/definitions/auth";
import utils, { isVfgErrorPresent } from "@/utils";
import { validateEmployeeAge, validateName, validateIndianMobileNumbersForVfg } from "@/utils/validators";
import NButton from "@/components/NovaButton.vue";

export default {
  beforeRouteUpdate(to, from, next) {
    this.resetCurrentStep();
    this.computeObSteps();
    this.handleRouteChange(to, from, next);
  },
  mounted() {
    if (this.dependentDetails.length > 0) {
      this.updatedDependents = this.dependentDetails;
    }
  },
  components: {
    NButton,
  },
  data() {
    return {
      currentStep: 1,
      selectedDependent: {},
      areUserDetailsBeingProcessed: false,
      updatedJobGradeFromCustomOnboarding: null,
      user: null,
      benefitsRelatedToUser: [],
      benefitMappingDetails: {},
      profileModel: {},
      updatedDependents: [],
      avoidExtractingDependents: false,
    };
  },
  methods: {
    isVfgErrorPresent,
    resetForm() {
      this.model = {};
      this.setDataToModel();
    },
    computeObSteps() {
      const steps = [];
      steps.push({
        label: "About You",
        path: "/about",
        title: "Your Details",
        description: "These are details required by the insurer to activate your coverage benefits.",
        note: "Please complete all the steps required for your onboarding. If you exit in between, your information will be deleted.",
      });
      if (this.isCustomOnboardingEnabled) {
        steps.push({
          label: "Custom Onboarding",
          path: "/custom-onboarding",
          title: "Custom Onboarding",
          description: "Provide custom onboarding details specific to your needs.",
        });
      }
      if (this.isDependentsScreenAdded) {
        steps.push({
          label: "Add Family",
          path: "/dependents",
          title: "Add your Family",
          description: this.areDependentsCovered
            ? `All together, your policies cover your ${this.dependentContent.replace(
                /Children/g,
                "Children (upto the age of 25)"
              )}`
            : "Ensure your family is covered by adding your dependents; it is not possible to add them later.",
          note: "We strongly recommend adding dependents at the earliest",
        });
      }
      steps.push({
        label: "Review",
        path: "/review",
        title: "Review Details",
        description:
          "Please review your details thoroughly before proceeding. No changes can be made once you submit the details",
      });

      this.$store.commit("getStarted/setSessionProps", {
        onboardingScreens: steps,
      });
    },
    async fetchUserData() {
      const newGrade = this.$store.state.getStarted.sessionProps.updatedJobGradeFromCustomOnboarding;
      if (newGrade) {
        await this.$apollo.queries.benefitsRelatedToUser.refetch({
          userId: this.user.id,
          jobGrade: newGrade,
        });
      } else await this.$apollo.queries.benefitsRelatedToUser.refetch();
      this.avoidExtractingDependents = false;
      const userData = await this.$apollo.queries.user.refetch();
      if (userData?.data) {
        this.user = utils.deepClone({
          ...userData.data.me,
          dependents: userData.data.me.dependents?.filter((dep) => dep.status !== DependentStatus.DELETE_PENDING),
        });
        this.profileModel = utils.deepClone(userData?.data?.me);
        if (![AcceptedGenders.MALE, AcceptedGenders.FEMALE, AcceptedGenders.OTHER].includes(this.profileModel?.gender))
          delete this.profileModel.gender;
      }

      this.dependentsModel?.forEach((dep) => {
        if (dep.meta?.isPreferred && dep.relation.includes(AcceptedRelations.PARENT)) {
          this.$store.commit("getStarted/setSessionProps", {
            coverPOrPil: dep.relation,
          });
        }
      });
      if (!this.updatedDependents.length) {
        this.updatedDependents = this.user?.dependents;
      }
      this.profileModel.meta.whatsappUserConsent =
        this.profileModel.meta?.whatsappUserConsent === false ? this.profileModel.meta?.whatsappUserConsent : true;
      if (!this.$store.state.getStarted.formData.selfDetails.id) {
        this.$store.commit(
          "getStarted/setSelfDetails",
          (({ id, name, email, contactNumber, dob, displayName, gender, meta, insuranceStatus }) => ({
            id,
            name,
            email,
            contactNumber,
            dob,
            displayName,
            gender,
            meta,
            insuranceStatus,
          }))(this.profileModel)
        );
      }
      this.$store.commit("getStarted/setSessionProps", {
        isOtpVerified: this.user.meta.isNumberVerified,
      });
      const isCustomOnboardingEnabled = this.user?.org?.meta?.customOnboardingUrl && !this.restrictDependentAddition;
      this.$store.commit("getStarted/setDependentDetails", this.updatedDependents);
      this.$store.commit("getStarted/setSessionProps", {
        isCustomOnboardingEnabled,
      });
    },
    resetCurrentStep() {
      this.currentStep = this.currentStepIndex + 1;
    },
    updateCoveredPOrPil(coverPOrPil) {
      this.$store.commit("getStarted/setSessionProps", {
        coverPOrPil: coverPOrPil,
      });
    },
    handleRouteChange(to, from, next) {
      next();
    },
    extractCoveredDependents(policies) {
      this.$store.commit("getStarted/setSessionProps", {
        depObj: utils.totalCoveredDependents(policies || this.policies) || {},
      });
    },
    transformLabel(label) {
      if (this.isUserOrgAdmin) {
        return `${label}<span style="color:red">*</span>`;
      }
      return label;
    },
    async nextStep(value) {
      const currentStepIndex = this.currentStepIndex + value;
      if (currentStepIndex < 0) {
        return;
      } else if (currentStepIndex >= this.onboardingScreens.length) {
        await this.commitUserData();
        return;
      }

      this.$router.push("/user/get-started" + this.onboardingScreens[currentStepIndex].path);
    },

    getDepNameWithMissingData() {
      const dependentDetails = utils.deepClone(this.$store.state.getStarted.formData.dependentDetails);
      const invalidDependent = dependentDetails.find(
        (dependent) =>
          !dependent.dob ||
          !dependent.gender ||
          !dependent.name ||
          !dependent.relation ||
          dependent.gender === "NOT ASSIGNED"
      );
      return invalidDependent ? invalidDependent.name : "";
    },
    async commitUserData() {
      this.areUserDetailsBeingProcessed = true;
      const userToken = this.$store.getters.getUserLoginToken || "";

      try {
        await this.$apollo.queries.user.refetch();
        if (!this.isDependentsScreenAdded) {
          this.$store.commit("getStarted/setDependentDetails", []);
          this.$store.commit("getStarted/setIsDependentScreenAdded", false);
        } else {
          this.$store.commit("getStarted/setIsDependentScreenAdded", true);
        }
        await this.$apollo.mutate({
          mutation: userDefs.users.upsertOnboardingFlowData,
          variables: {
            ...this.$store.state.getStarted.formData,
            token: userToken,
          },
        });

        const userData = await this.$apollo.queries.user.refetch();
        this.$store.commit("updateUserProfileData", userData.data.me);

        this.$store.commit("addToast", {
          variant: "success",
          message: "Successfully updated the dependent details.",
        });
        this.$store.commit("updateUser", this.user);
        apolloClient.cache.data.clear();
        this.$bvModal.show("onboarding-success-modal");
        // Wait for 3 seconds before redirecting
        setTimeout(() => {
          this.$bvModal.hide("onboarding-success-modal");
          this.$router.push({ name: "dashboard" });
        }, 3000);
      } catch (err) {
        throw err;
      } finally {
        this.areUserDetailsBeingProcessed = false;
      }
    },
  },
  computed: {
    ...mapGetters(["getFeatureFlags"]),
    isOtpVerified() {
      return this.$store.state.getStarted.sessionProps.isOtpVerified;
    },
    dependentDetails() {
      return this.$store.state.getStarted.formData.dependentDetails;
    },
    onboardingScreens() {
      return this.$store.state.getStarted.sessionProps.onboardingScreens || [];
    },
    isMobileView() {
      return utils.mobileCheck();
    },
    arePoliciesLoaded() {
      return this.$store.state.getStarted.sessionProps.arePoliciesLoaded;
    },
    isCustomOnboardingScreen() {
      if (!this.isCustomOnboardingEnabled) {
        return false;
      }
      return this.$route.path.includes("/custom-onboarding");
    },
    coverPOrPil() {
      return this.$store.state.getStarted.sessionProps.coverPOrPil;
    },
    restrictDependentAddition() {
      return this.user?.restrictDependentAddition;
    },
    isCustomOnboardingEnabled() {
      return this.$store.state.getStarted.sessionProps.isCustomOnboardingEnabled;
    },
    isOtpEnabled() {
      return !!this.getFeatureFlags?.ENABLE_OTP;
    },
    currentStepIndex() {
      return this.onboardingScreens?.findIndex((step) => this.$route.path.includes(step.path)) || 0;
    },
    dependentContent() {
      return this.$store.state.getStarted.sessionProps.dependentContent;
    },
    dependentsModel() {
      if (this.updatedDependents.length) return this.updatedDependents;
      else return [];
    },
    depObj() {
      return this.$store.state.getStarted.sessionProps.depObj;
    },
    policies() {
      if (this.user) {
        return this.$store.state.getStarted.sessionProps.policies;
      } else {
        return [];
      }
    },
    userState() {
      return this.$store.state.getStarted.formData.selfDetails;
    },
    formSchema() {
      if (this.currentStep === 1) return this.profileSchema;
    },
    areDependentsCovered() {
      return Boolean(this.depObj.coveredDependents?.length || this.depObj.pOrILFlag);
    },
    isDependentsScreenAdded() {
      return this.$store.state.getStarted.sessionProps.isDependentsScreenAdded;
    },
    profileSchema() {
      const phnoValidators = [validateIndianMobileNumbersForVfg];
      let phnoLabel = "Phone Number";
      if (!this.getFeatureFlags.ONBOARDING_PHNO_OPTIONAL) {
        phnoValidators.push("required");
        phnoLabel += `<span style="color:red">*</span>`;
      }
      return {
        fields: [
          {
            model: "name",
            type: "inline-input",
            inputType: "text",
            label: `Full Name<span style="color:red">*</span>`,
            disabled: false,
            required: true,
            min: 2,
            max: 50,
            validator: ["required", "string", validateName],
          },
          {
            model: "email",
            type: "inline-input",
            styleClasses: "d-inline-block w-100",
            inputType: "email",
            label: "Work Email",
            disabled: true,
          },
          {
            model: "meta.contactNumber",
            type: "inline-input",
            inputType: "tel",
            label: phnoLabel,
            validator: phnoValidators,
            required: !this.getFeatureFlags.ONBOARDING_PHNO_OPTIONAL,
            ...(this.isOtpEnabled && { subText: "You will receive an OTP, on clicking ‘Continue’" }),
            placeholder: "Add a phone number",
          },
          {
            model: "meta.whatsappUserConsent",
            type: "contact-consent",
            isHighlighted: true,
          },
          {
            model: "dob",
            type: "datepicker",
            label: `Date of Birth<span style="color:red">*</span>`,
            min: "1900-01-01",
            max: moment().format("YYYY-MM-DD"),
            hint: "Your date of birth and age is required by your insurer",
            disabled: false,
            validator: [validateEmployeeAge, "required"],
            styleClasses: "d-inline-block w-50 pr-2",
            dataCy: "user-onboarding-dob-input",
            shouldFormat: true,
          },
          {
            model: "meta.dateOfJoining",
            type: "datepicker",
            label: this.transformLabel("Date of Enrollment"),
            min: "1900-01-01",
            max: moment().format("YYYY-MM-DD"),
            hint: "This is the date when you were enrolled into the insurance plan",
            disabled: !this.isUserOrgAdmin,
            required: true,
            styleClasses: "d-inline-block w-50 pl-2 align-top",
            validator: ["required"],
            shouldFormat: true,
          },
          {
            model: "gender",
            type: "select-cards",
            label: `Gender<span style="color:red">*</span>`,
            disabled: false,
            hint:
              "We understand there are more gender identities. At present however, " +
              "we are still learning and trying to understand the impact of other gender identities " +
              "on health and come up with more friendly policies.",
            cardsData: [
              {
                name: "gender",
                icon: "female",
                label: "Female",
                card_value: "female",
              },
              {
                name: "gender",
                icon: "male",
                label: "Male",
                card_value: "male",
              },
            ],
            required: true,
            validator: "required",
          },
          {
            model: "meta.employeeId",
            type: "inline-input",
            styleClasses: "d-inline-block w-100",
            inputType: "text",
            label: this.transformLabel("Employee ID"),
            disabled: !this.isUserOrgAdmin,
            required: true,
            validator: ["required"],
          },
          {
            model: "meta.city",
            type: "editable-dropdown",
            label: "Where do you live?",
            imageIcon: "city",
            placeholder: "Select a city",
            gqlQuery: getCitiesQuery(),
            transform: (value) => value.name,
            queryName: "dropdownCities",
            taggable: true,
            required: true,
            dataCy: "user-onboarding-live-select",
          },
          {
            model: "meta.pincode",
            type: "inline-input",
            inputType: "text",
            placeholder: "Pincode",
            imageIcon: "pin",
            validator: "regexp",
            pattern: "^[1-9]{1}[0-9]{2}\\s{0,1}[0-9]{3}$",
            hint: "City and Pincode helps us direct you to the nearest hospital that accepts your insurance",
          },
          {
            model: "meta.altEmail",
            type: "inline-input",
            inputType: "email",
            label: "Secondary Email",
            imageIcon: "at-the-rate",
            placeholder: "Enter a secondary mail",
            hint: "Adding a secondary mail allows you to receive updates related to your claims or policy on a non-work mail in an emergency situation when you can’t access your work mail.",
            validator: "email",
          },
        ],
      };
    },
  },
  apollo: {
    /** TODO: @sanath: fix this, check if userChanges is needed. */
    user: {
      query: MY_INFO,
      update(data) {
        return {
          ...data.me,
          dependents: data.me.dependents?.filter((dep) => dep.status !== DependentStatus.DELETE_PENDING),
        };
      },
    },
    userChanges: {
      query: userDefs.users.userChanges,
      update(data) {
        return data.me.userChanges;
      },
    },
    benefitsRelatedToUser: {
      skip() {
        if (
          this.user &&
          (!this.$store.state.getStarted.sessionProps.arePoliciesLoaded ||
            this.$store.state.getStarted.sessionProps.updatedJobGradeFromCustomOnboarding)
        ) {
          return false;
        } else {
          return true;
        }
      },
      fetchPolicy: "no-cache",
      query: gql`
        query getBenefitsRelatedToUser($userId: ID!, $jobGrade: String) {
          getBenefitsRelatedToUser(userId: $userId, jobGrade: $jobGrade) {
            ...OrgBenefitEdge
          }
        }
        ${orgBenefitFragment}
      `,
      variables() {
        const vars = {
          userId: this.user.id,
        };
        if (this.$store.state.getStarted.sessionProps.updatedJobGradeFromCustomOnboarding) {
          vars.jobGrade = this.$store.state.getStarted.sessionProps.updatedJobGradeFromCustomOnboarding;
        }
        return vars;
      },
      update(data) {
        const benefitsRelatedToUser = data.getBenefitsRelatedToUser.map((orgBenefitEdge) =>
          adminDefs.benefits.transform(orgBenefitEdge.node)
        );
        // Initializing keys-value slots for storing the mapping
        // done by benefitMapper
        benefitsRelatedToUser.forEach((benefit) => {
          this.benefitMappingDetails[benefit.id] = {};
        });
        this.extractCoveredDependents(benefitsRelatedToUser?.filter((benefit) => benefit.isPolicy === true));
        this.$store.commit("getStarted/setSessionProps", {
          areDependentsCovered: this.areDependentsCovered,
          policies: benefitsRelatedToUser?.filter((benefit) => benefit.isPolicy === true) || [],
        });
        const isDependentsScreenAdded =
          !this.getFeatureFlags.DISABLE_ADDITION_AND_UPDATION_FOR_USER_AND_DEPENDENTS &&
          this.$store.state.getStarted.sessionProps.areDependentsCovered &&
          !this.restrictDependentAddition &&
          (this.user?.meta?.source !== "onboard-cli" ||
            !this.getFeatureFlags.HIDE_DEPENDENT_ADDITION_FOR_CLI_ADDED_USERS);
        this.$store.commit("getStarted/setSessionProps", {
          arePoliciesLoaded: true,
          dependentContent: utils.getContent(this.depObj),
          isDependentsScreenAdded,
        });
        this.computeObSteps();
        return benefitsRelatedToUser;
      },
    },
  },
};
