const ClaimProcess = () => import(/* webpackChunkName: "claims" */ "../pages/ClaimProcess.vue");
const ErrorPage = () => import(/* webpackChunkName: "misc" */ "../pages/ErrorPage.vue");
const InviteExpired = () => import(/* webpackChunkName: "invite-expired" */ "../pages/InviteExpired.vue");
const FAQ = () => import(/* webpackChunkName: "misc" */ "../pages/FAQ.vue");
const LinkExpired = () => import(/* webpackChunkName: "misc" */ "../pages/LinkExpired.vue");
const Login = () => import(/* webpackChunkName: "login" */ "../pages/Login.vue");
const NewUserOnboarding = () => import(/* webpackChunkName: "user-onboarding" */ "../pages/NewUserOnboarding.vue");
const Policy = () => import(/* webpackChunkName: "policy" */ "../pages/Policy");
const ResetPasswordEmail = () => import(/* webpackChunkName: "login" */ "../pages/ResetPasswordEmail.vue");
const ResetPasswordVerify = () => import(/* webpackChunkName: "login" */ "../pages/ResetPasswordVerify.vue");
const TopupsContent = () => import(/* webpackChunkName: "topups" */ "../pages/user/topups/TopupsContent.vue");
const Dashboard = () => import(/* webpackChunkName: "dashboard" */ "../pages/Dashboard.vue");
const Hospitals = () => import(/* webpackChunkName: "hospitals" */ "../pages/Hospitals.vue");
const HospitalStats = () => import(/* webpackChunkName: "hospitals" */ "../pages/HospitalStats.vue");
const AssessmentsBase = () => import(/* webpackChunkName: "Assessments" */ "../pages/assessments/Base.vue");
const TopupsBase = () => import(/* webpackChunkName: "topups" */ "../pages/user/topups/Base.vue");
const SuperTopupsBase = () => import(/* webpackChunkName: "superTopups" */ "../pages/user/superTopups/Base.vue");
const BenefitsBase = () => import(/* webpackChunkName: "benefits" */ "../pages/benefits/Base.vue");
const UserBase = () => import(/* webpackChunkName: "user" */ "../pages/user/Base.vue");
const AdminBase = () => import(/* webpackChunkName: "admin" */ "../pages/admin/Base.vue");
const SupportBase = () => import(/* webpackChunkName: "support" */ "../pages/support/Base.vue");
const OrgAdminBase = () => import(/* webpackChunkName: "org-admin" */ "../pages/orgAdmin/Base.vue");
const FitnessBase = () => import(/* webpackChunkName: "fitness" */ "../pages/fitness/Base.vue");
const ProspectBase = () => import(/* webpackChunkName: "prospect" */ "../pages/orgAdmin/OnboardUsers/Base.vue");
const FormEmbed = () => import(/* webpackChunkName: "misc" */ "../pages/forms/Embed.vue");
const EcardRedirect = () => import(/* webpackChunkName: "misc" */ "../pages/EcardRedirect.vue");
const CheckupBase = () => import(/* webpackChunkName: "checkup" */ "../pages/checkup/Base.vue");
const CheckupRedirect = () => import(/* webpackChunkName: "checkup" */ "../pages/checkup/CheckupRedirect.vue");
const BookingDetailsBase = () =>
  import(/* webpackChunkName: "booking-details" */ "../pages/checkup/BookingDetails.vue");
const ExternalCheckupBookingBase = () =>
  import(/* webpackChunkName: "external-checkup-booking" */ "../pages/checkup/ExternalCheckupHome.vue");
const PrivacySettings = () => import(/* webpackChunkName: "misc" */ "../pages/PrivacySettings.vue");

const routes = [
  {
    path: "/",
    component: require("../pages/Base.vue").default,
    children: [
      {
        path: "",
        redirect: (to) =>
          to.fullPath.startsWith("/#/") ? { path: to.fullPath.slice(2), hash: "" } : { name: "login" },
        meta: { allowedRoles: ["*"] },
      },
      {
        path: "login/new-user",
        name: "login_new_user",
        component: Login,
        meta: { allowedRoles: ["*"] },
      },
      {
        path: "dashboard",
        name: "dashboard",
        component: Dashboard,
        meta: { allowedRoles: ["org_member"] },
      },
      {
        path: "privacy-settings",
        name: "privacy-settings",
        component: PrivacySettings,
        meta: { allowedRoles: ["org_member"] },
      },
      {
        path: "dashboard/benefit/:benefitId",
        name: "dashboard_benefit",
        component: Dashboard,
        meta: { allowedRoles: ["org_member"] },
      },
      {
        path: "reset-password",
        name: "reset_password_email",
        component: ResetPasswordEmail,
        meta: { allowedRoles: ["*"] },
      },
      {
        path: "reset-password/:token/:email",
        name: "reset_password_verify",
        component: ResetPasswordVerify,
        meta: { allowedRoles: ["*"] },
      },
      {
        path: "onboarding-nova/:token/:email",
        name: "onboarding_nova_email_link",
        component: NewUserOnboarding,
        meta: { allowedRoles: ["*"] },
      },
      {
        path: "policy/:policyId?",
        name: "policy",
        component: Policy,
        meta: {
          allowedRoles: ["org_member"],
          disableForWellnessOnlyOrgs: true,
        },
      },
      {
        path: "e-card",
        name: "ecard-redirect",
        component: EcardRedirect,
        meta: {
          allowedRoles: ["org_member"],
          disableForWellnessOnlyOrgs: true,
        },
      },
      {
        path: "faq",
        name: "faq",
        component: FAQ,
        meta: { allowedRoles: ["org_member"] },
      },
      {
        path: "claim-process/:contentId?/:claimType?",
        name: "claim-process",
        component: ClaimProcess,
        meta: {
          allowedRoles: ["org_member"],
          featureFlags: ["CLAIMS_PORTAL_EXPERIENCE"],
          disableForWellnessOnlyOrgs: true,
        },
      },
      {
        path: "external-checkup-booking",
        name: "external-checkup-booking",
        component: ExternalCheckupBookingBase,
        meta: {
          allowedRoles: ["checkup_admin"],
        },
      },
      {
        path: "login/:logout?",
        name: "login",
        component: Login,
        meta: { allowedRoles: ["*"] },
      },
      {
        path: "hospitals",
        name: "hospitalsRoot",
        component: Hospitals,
        meta: { allowedRoles: ["*"] },
      },
      {
        path: "hospitals/:insurer",
        name: "hospitals",
        component: Hospitals,
        meta: { allowedRoles: ["*"] },
      },
      {
        path: "hospital-stats",
        name: "hospital-stats",
        component: HospitalStats,
        meta: { allowedRoles: ["*"] },
      },
      {
        path: "risk-assessments",
        component: AssessmentsBase,
        children: require("../pages/assessments").routes,
        meta: { allowedRoles: ["*"] },
      },
      {
        path: "user",
        component: UserBase,
        children: require("../pages/user").routes,
        meta: { allowedRoles: ["org_member"] },
      },
      {
        path: "admin",
        component: AdminBase,
        children: require("../pages/admin").routes,
        meta: { allowedRoles: ["admin", "account_admin", "cx_manager", "customer_support", "batman"] },
      },
      {
        path: "support",
        component: SupportBase,
        children: require("../pages/support").routes,
        meta: { allowedRoles: ["org_member"] }, // Org_Member level permission to access support tickets page
      },
      {
        path: "checkup/schedule-booking",
        component: CheckupRedirect,
        meta: {
          allowedRoles: ["org_member", "checkup_admin"],
          featureFlags: ["CHECKUP_BOOKING"],
          redirectType: "schedule-booking",
        },
      },
      {
        path: "checkup/booking-history",
        component: CheckupRedirect,
        meta: {
          allowedRoles: ["org_member", "checkup_admin"],
          featureFlags: ["CHECKUP_BOOKING"],
          redirectType: "booking-history",
        },
      },
      {
        path: "checkup/:benefitId",
        component: CheckupBase,
        children: require("../pages/checkup").routes,
        meta: {
          allowedRoles: ["org_member", "checkup_admin"],
          featureFlags: ["CHECKUP_BOOKING"],
        },
      },
      {
        path: "checkup/:benefitId/schedule-booking/packages",
        name: "external-checkup-schedule-booking-packages",
        component: CheckupBase,
        children: require("../pages/checkup").routes,
        meta: {
          allowedRoles: ["org_member", "checkup_admin"],
          featureFlags: ["CHECKUP_BOOKING"],
        },
      },
      {
        path: "checkup/:benefitId/schedule-booking/address-selection",
        name: "external-checkup-address-selection",
        component: CheckupBase,
        children: require("../pages/checkup").routes,
        meta: {
          allowedRoles: ["org_member", "checkup_admin"],
          featureFlags: ["CHECKUP_BOOKING"],
        },
      },
      {
        path: "checkup/:benefitId/booking-history/:bookingId",
        name: "bookingDetails",
        component: BookingDetailsBase,
        meta: {
          allowedRoles: ["org_member", "checkup_admin"],
          featureFlags: ["CHECKUP_BOOKING"],
        },
      },
      {
        path: "prospect",
        component: ProspectBase,
        children: require("../pages/prospect").routes,
        meta: { allowedRoles: ["prospect"] },
      },
      {
        path: "org-admin",
        component: OrgAdminBase,
        children: require("../pages/orgAdmin").routes,
        meta: { allowedRoles: ["org_admin", "org_entity_admin"] },
      },
      {
        path: "fitness",
        component: FitnessBase,
        children: require("../pages/fitness").routes,
        meta: {
          allowedRoles: ["org_member"],
          featureFlags: ["FITNESS"],
        },
      },
      {
        path: "forms/employee-onboarding/:slug/:familyDefinition?/:responseId?",
        name: "oldEmployeeOnboarding",
        component: LinkExpired,
        meta: { allowedRoles: ["*"] },
      },
      {
        path: "forms/:slug?/:responseId?",
        name: "formEmbed",
        component: FormEmbed,
        meta: { allowedRoles: ["*"] },
      },
      {
        path: "benefits",
        name: "benefits",
        component: BenefitsBase,
        children: require("../pages/benefits").routes,
      },
      {
        path: "topups",
        name: "topups",
        component: TopupsContent,
        meta: {
          allowedRoles: ["org_member"],
          featureFlags: ["SUPER_TOPUPS"],
        },
      },
      {
        path: "topup-purchase",
        component: TopupsBase,
        children: require("../pages/user/topups").routes,
        meta: {
          allowedRoles: ["org_member"],
          featureFlags: ["SUPER_TOPUPS"],
        },
      },
      {
        path: "super-topups",
        name: "super-topups",
        component: SuperTopupsBase,
        children: require("../pages/user/superTopups").routes,
        meta: {
          allowedRoles: ["org_member"],
          featureFlags: ["RETAIL_SUPER_TOPUP_FLOW", "SUPER_TOPUPS"],
        },
      },
      {
        path: "claim-assist",
        name: "claim-assist",
        redirect: "user/claims/start-claim",
        meta: {
          featureFlags: ["CLAIMS_PORTAL_EXPERIENCE"],
          disableForWellnessOnlyOrgs: true,
        },
      },
      {
        path: "payments/:productType/:pgOrderId",
        name: "payment_check",
        component: require("../pages/payments/components/StatusPage.vue").default,
      },
    ],
  },
  { path: "/error/:type", name: "error", component: ErrorPage },
  { path: "/invite-expired", name: "invite-expired", component: InviteExpired },
  { path: "*", name: "error404", component: ErrorPage },
];

export default routes;
