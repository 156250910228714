import gql from "graphql-tag";

export default {
  retryStagedClaim: gql`
    mutation retryStagedClaim($id: ID!) {
      retryStagedClaim(input: { id: $id }) {
        status
        failingReason
        skippedReason
      }
    }
  `,
  exportStagedClaims: gql`
    mutation exportStagedClaims($orgId: ID, $errorReason: String, $status: String, $searchQuery: String) {
      exportStagedClaims(
        input: { orgId: $orgId, errorReason: $errorReason, status: $status, searchQuery: $searchQuery }
      ) {
        message
      }
    }
  `,
};
