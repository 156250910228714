<template lang="pug">
div
  ul.m-0.p-0.timeline.ml-3
    template(v-for="(history, i) in claim.timeline")
      li.row.position-relative(v-if="history.note")
        .col-md-3.time.text-right.pr-5.pt-5.d-none.d-md-block
          span.text-gray-900.font-md {{ getFormattedDate(history.createdAt) }}
          span.text-gray-700.d-block.font-sm {{ getFormattedTime(history.createdAt) }}
        .col-md-9.content.border-left.px-3.pt-5
          .clock-icon
            i.icon-clock
          .d-flex.justify-content-between.align-items-center.d-md-none.pb-2
            span.text-gray-900.font-md {{ getFormattedDate(history.createdAt) }}
            span.text-gray-700.d-block.font-sm {{ getFormattedTime(history.createdAt) }}
          span.text-gray-900.font-lg.font-weight-semibold Note
          p.text-gray-700.font-sm.mt-2 {{history.note}}

      li.row.position-relative(v-if= "showStage(history, i, claim.timeline)" :key="history.id")
        .col-md-3.time.text-right.pr-5.pt-5.d-none.d-md-block
          span.text-gray-900.font-md {{ getFormattedDate(history.createdAt) }}
          span.text-gray-700.d-block.font-sm {{ getFormattedTime(history.createdAt) }}
        .col-md-9.content.border-left.px-3.pt-5
          .clock-icon
            i.icon-clock
          .d-flex.justify-content-between.align-items-center.d-md-none.pb-2
            span.text-gray-900.font-md {{ getFormattedDate(history.createdAt) }}
            span.text-gray-700.d-block.font-sm {{ getFormattedTime(history.createdAt) }}
          span.text-gray-900.font-lg.font-weight-semibold {{history.stage}}
          p.text-gray-700.font-sm.m-0.mt-2 {{history.subheading}}
          n-button.mr-2(
            v-if="hasQueries && isQueryStage(history.stage)",
            buttonText="View Query",
            variant="light",
            size="sm",
            @click="$emit('goToQueriesTab')"
          )
          p.text-gray-700.font-sm.m-0.mt-1(v-if="history.updates.length > 0")
            span Updates -
          p.text-gray-700.font-sm.m-0.mt-1(v-for="changes in history.updates.slice(0,3)")
            span(v-if="changes[0] == 'queries'")
              | {{changes[2][0].status === "submitted"?'Query was Responded':'Query was Raised'}}
            span(v-else-if="changes[0] == 'files'") Updated Documents
            span(v-else) {{changes[0] }} was updated from {{changes[1]?changes[1]:"empty"}} to {{changes[2]}}
          p.text-gray-700.font-sm.m-0.mt-1(v-if="history.updates.length > 3")
            span and more...
</template>

<script>
import moment from "moment";
import utils from "../utils";
import NButton from "@/components/NovaButton";
import { ClaimStage } from "../common/enums/claims/claimStage";

export default {
  components: {
    NButton,
  },
  props: {
    claim: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    showStage(currTimelineInstance, index, timeline) {
      if (index < timeline.length - 1 && currTimelineInstance.stage === timeline[index + 1].stage) {
        return false;
      }
      return true;
    },
    getFormattedDate(date) {
      return utils.getFormattedDate(date);
    },
    getFormattedTime(date) {
      return moment(date).format("h:mm A");
    },
    isQueryStage(stage) {
      return [
        ClaimStage.CLAIM_UNDER_QUERY,
        ClaimStage.CLAIM_UNDER_QUERY_2,
        ClaimStage.CLAIM_UNDER_QUERY_REMINDER_1,
        ClaimStage.CLAIM_UNDER_QUERY_REMINDER_2,
      ].includes(stage);
    },
  },
  computed: {
    hasQueries() {
      return this.claim.queries?.length !== 0;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";

.claims .claim-process-timeline {
  margin: 0;
  padding: 0;
  margin-top: 2rem;
  background: inherit;
  border-radius: 12px;
  position: relative;
  list-style: none;
}
.claim-process-timeline li {
  padding-bottom: 1.5rem;
  border-left: 1px solid #abaaed;
  position: relative;
  padding-left: 20px;
  margin-left: 10px;
  &:last-child {
    border: 0px;
    padding-bottom: 0;
  }
  &:before {
    content: "\2713";
    background: $blue-400;
    color: white;
    padding: 0 2px;
    border-radius: 5px;
    position: absolute;
    left: -10px;
    top: 0px;
  }
}

.timeline {
  li {
    list-style: none;
    .clock-icon {
      position: absolute;
      left: 0;
      transform: translateX(-50%);
      color: $gray-500;
      background-color: white;
    }
  }
}
</style>
