import gql from "graphql-tag";

export const tpaFragment = gql`
  fragment Tpa on Tpa {
    id
    name
    photoUrl
    meta
    s3Url
  }
`;
