export const UserInsuranceStatus = {
  OnboardingPending: "onboarding-pending",
  ApprovalPending: "approval-pending",
  AdditionPending: "addition-pending",
  UpdationPending: "updation-pending",
  DeletionPending: "deletion-pending",
  Active: "active",
  Inactive: "inactive",
  Error: "error",
};
