import { DataCheckComparisonSource } from "../../../../common/enums";
import { getIdSelectorQuery } from "../queries";

export default {
  schema: {
    fields: [
      {
        model: "org",
        type: "id-selector",
        queryName: "orgs",
        gqlQuery: getIdSelectorQuery("Org"),
        label: "Org",
        showSelected: false,
        required: true,
        validator: "required",
      },
      {
        model: "orgEntity",
        type: "id-selector",
        queryName: "orgEntities",
        gqlQuery: getIdSelectorQuery("OrgEntity"),
        queryVariables: () => ({ filter: {} }),
        label: "Org Entity",
        placeholder: "Org Entity (none)",
        visible: false,
      },
      {
        model: "comparisonSource",
        type: "select",
        values: () => {
          return [DataCheckComparisonSource.TPA, DataCheckComparisonSource.INSURER, DataCheckComparisonSource.RENEWAL];
        },
        label: "Comparison Source",
        required: true,
        validator: "required",
      },
      {
        model: "policy",
        type: "id-selector",
        label: "Policy",
        hint: "(Showing expired policies only for renewal cases)",
      },
    ],
  },
  transformArgs: ({ org, orgEntity, inputFile, policy, ...args }) => ({
    ...args,
    orgId: org?.id,
    orgEntityId: orgEntity?.id || null,
    policyId: policy?.id,
  }),
};
