<template lang="pug">
.reschedule-booking-sidesheet(v-if="availableDates?.length")
  n-sidesheet(
    :ssId="sidesheetId",
    :onChange="toggleBodyScrollbar",
    :headerName=`rescheduleType==='appointment' ? 'Reschedule Appointment' : 'Reschedule Booking'`,
    @hidden="closeHandler",
    ref="rescheduleBookingSidesheet",
    shadow,
    backdrop)
    template(v-slot:content)
      .row.no-gutters.m-3.font-hc.text-gray-700
        .col-9 Select new date
        .col-3.text-right {{ formattedShortDate }}
      .row.no-gutters.mx-3.my-2(v-if="isSideSheetVisible")
        .col.date-picker
          horizontal-date-picker(v-model="selectedDate", :availableDates="availableDates")
      .row.no-gutters.mx-3.my-2(v-if="provider?.meta?.fastingAlert")
        .col.test-instruction-border.rounded.p-3.bg-white
          .d-flex.align-items-centers
            n-icon.mr-1.text-blue-400.text-center.align-self-center(name="info", :size="0.8")
            span.font-hc.text-blue-500 Test instructions
          .text-gray-800.font-sm.font-weight-medium.mt-2 {{ provider?.meta?.fastingAlert }}
      .row.no-gutters.m-3(v-if="availableTimeSlots?.length")
        .col.font-hc.text-gray-700 Select new time
      .row.no-gutters.m-3(v-if="availableTimeSlots?.length")
        .col
          horizontal-time-picker(v-model="selectedTimeSlot", :availableTimeSlots="availableTimeSlots")
    template(v-slot:footer)
      .d-flex.justify-content-between.m-3(v-if="isRescheduleFooterDisabled")
        n-icon.text-center.align-self-center.rounded-circle.bg-teal-200.calendar-icon(name="calendar-day", variant="teal-800", :size="1")
        .d-flex.flex-column.justify-content-around.font-sm.font-weight-semibold.flex-grow-1.ml-2
          .text-gray-800 {{ formattedRescheduleDate }}
          .text-gray-900 {{ formattedRescheduleTime }}
        n-button(
          buttonText="Reschedule",
          @click="reschedule",
          variant="dark",
          size="sm")
  checkup-reschedule-modal(
    :id="modalId",
    :checkupDate="selectedDate",
    :checkupTime="selectedTimeSlot",
    @modal-closed="closeHandler",
  )
</template>

<script>
import moment from "moment";
import { mapState } from "vuex";
import checkupResDefs from "../definitions";
import resDefs from "../../admin/definitions";
import { isAppointmentStatusActive } from "../checkupUtils";
import CheckupRescheduleModal from "./CheckupRescheduleModal.vue";
import NButton from "@/components/NovaButton.vue";
import NSidesheet from "@/components/NovaSideSheet";
import NTabItem from "@/components/NovaTabItem";
import NChip from "@/components/NovaChip.vue";

export default {
  name: "RescheduleBookingSidesheet",
  components: {
    NButton,
    NSidesheet,
    NTabItem,
    NChip,
    CheckupRescheduleModal,
  },
  props: {
    sidesheetId: {
      type: String,
      default: "",
    },
    modalId: {
      type: String,
      default: "reschedule-checkup-modal",
    },
    bookingDetails: {
      type: Object,
      default: () => {},
    },
    rescheduleType: {
      type: String,
      default: "booking",
    },
    appointmentDetails: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      selectedDate: "",
      selectedTimeSlot: {},
      provider: {},
      isSideSheetVisible: false,
    };
  },
  computed: {
    ...mapState(["user"]),
    availableDates() {
      const dates = [];
      if (!this.provider?.providerSlots) {
        return dates;
      }

      Object.keys(this.provider.providerSlots).forEach((date) => {
        if (date && this.provider.providerSlots[date].slots.length > 0) {
          dates.push(date);
        }
      });
      return dates;
    },
    availableTimeSlots() {
      return this.selectedDate ? this.provider.providerSlots[this.selectedDate].slots : [];
    },
    isRescheduleFooterDisabled() {
      if (this.selectedDate && Object.keys(this.selectedTimeSlot).length > 0) return true;
      return false;
    },
    formattedRescheduleDate() {
      if (this.selectedDate) return moment(this.selectedDate).format("DD MMM YYYY");
      return "";
    },
    formattedShortDate() {
      if (this.selectedDate) return moment(this.selectedDate).format("MMM YYYY");
      return "";
    },
    formattedRescheduleTime() {
      if (Object.keys(this.selectedTimeSlot).length > 0) {
        const startTime = moment(this.selectedTimeSlot.startTime, "HH:mm");
        const endTime = moment(this.selectedTimeSlot.endTime, "HH:mm");
        return `${startTime.format("hh:mm")} - ${endTime.format("hh:mm A")}`;
      }
      return "";
    },
  },
  watch: {
    selectedDate() {
      this.selectedTimeSlot = {};
    },
    bookingDetails: {
      handler: "fetchProviderData",
      immediate: true,
    },
  },
  async created() {
    const checkupPackageIds = this.bookingDetails?.appointments
      ?.filter(({ appointmentStatus }) => isAppointmentStatusActive(appointmentStatus))
      ?.map((appointment) => appointment?.checkupPackage?.id);
    if ((this.user || this.$route.params?.bookingUser) && checkupPackageIds?.length) {
      await this.setProvider(checkupPackageIds);
    }
  },
  methods: {
    async fetchProviderData() {
      const checkupPackageIds = this.bookingDetails?.appointments
        ?.filter(({ appointmentStatus }) => isAppointmentStatusActive(appointmentStatus))
        .map((appointment) => appointment?.checkupPackage?.id);
      if ((this.user || this.$route.params?.bookingUser) && checkupPackageIds?.length) {
        await this.setProvider(checkupPackageIds);
      }
    },
    async setProvider(checkupPackageIds) {
      const { latitude, longitude, pincode } = this.bookingDetails.userAddress;
      const { data } = await this.$apollo.query({
        query: resDefs.checkupProviders.listQuery,
        variables: {
          filter: {
            orgId: this.$route.params.bookingUser?.org_id || null,
            geolocation: { latitude, longitude },
            pincode,
            packageIds: checkupPackageIds,
          },
        },
      });
      this.provider = data.checkupProviders.edges
        ?.map(({ node }) => node)
        ?.find((provider) => provider.partnerId.toString() === this.bookingDetails.partnerProviderId.toString());
    },
    resetData() {
      this.selectedDate = "";
      this.selectedTimeSlot = {};
    },
    async reschedule() {
      // Reschedule based on the type: appointment or booking.
      this.rescheduleType === "appointment" ? await this.rescheduleAppointment() : await this.rescheduleBooking();
    },
    async rescheduleBooking() {
      const [hour, minute] = this.selectedTimeSlot?.startTime?.split(":");
      const rescheduledAt = moment(`${this.selectedDate}`).set({ hour, minute });
      const result = await this.$apollo.mutate({
        mutation: checkupResDefs.checkupBookings.rescheduleCheckupBooking,
        variables: {
          id: this.bookingDetails?.id,
          userId: this.$route.params?.bookingUser?.id || this.user?.id,
          rescheduledAt,
        },
      });
      if (result?.data?.rescheduleCheckupBooking?.success) {
        this.$bvModal.show(this.modalId);
        setTimeout(() => {
          this.resetData();
          this.$refs.rescheduleBookingSidesheet.$refs.novaSidesheet.hide();
          this.$bvModal.hide(this.modalId);
          this.$emit("modal-closed");
        }, 3000);
      }
    },
    async rescheduleAppointment() {
      const [hour, minute] = this.selectedTimeSlot?.startTime?.split(":");
      const rescheduledAt = moment(`${this.selectedDate}`).set({ hour, minute });
      const result = await this.$apollo.mutate({
        mutation: checkupResDefs.checkupAppointments.rescheduleCheckupAppointment,
        variables: {
          id: this.appointmentDetails?.id,
          userId: this.$route.params?.bookingUser?.id || this.user?.id,
          rescheduledAt,
        },
      });
      if (result?.data?.rescheduleCheckupAppointment?.success) {
        this.$bvModal.show(this.modalId);
        setTimeout(() => {
          this.resetData();
          this.$refs.rescheduleBookingSidesheet.$refs.novaSidesheet.hide();
          this.$bvModal.hide(this.modalId);
          this.$emit("modal-closed");
        }, 3000);
      }
    },
    closeHandler() {
      this.resetData();
      this.$refs.rescheduleBookingSidesheet.$refs.novaSidesheet.hide();
    },
    toggleBodyScrollbar(visible) {
      const body = document.getElementsByTagName("body")?.[0];
      if (visible) {
        this.isSideSheetVisible = true;
        body.classList.add("overflow-hidden");
      } else {
        this.isSideSheetVisible = false;
        body.classList.remove("overflow-hidden");
      }
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/_variables.scss";
.reschedule-booking-sidesheet {
  position: relative !important;
  z-index: 6001 !important;
  .date-picker {
    height: 5.375rem !important;
  }
  .calendar-icon {
    border: 0.8rem solid $teal-200;
  }
  .b-sidebar-body {
    .tabs {
      display: none !important;
    }
  }
  .b-sidebar-footer {
    border-top: 1px solid $gray-300;
    background-color: $white;
  }
}
</style>
