import gql from "graphql-tag";
import { benefitFragment } from "./benefit";
import { fileFragment } from "./file";

export const orgGroupFileFragment = gql`
  fragment OrgGroupFileEdge on OrgGroupFileEdge {
    node {
      ...File
    }
    meta
  }
  ${fileFragment}
`;

export const orgBenefitFragment = gql`
  fragment OrgBenefitEdge on OrgBenefitEdge {
    node {
      ...Benefit
    }
    meta
    userCount
    orgEntities {
      id
      name
    }
  }
  ${benefitFragment}
`;

export const orgBenefitsFragment = gql`
  fragment OrgBenefits on Org {
    id
    benefits {
      ...OrgBenefitEdge
    }
  }
  ${orgBenefitFragment}
`;

export const orgFilesFragment = gql`
  fragment OrgFiles on Org {
    id
    files {
      ...OrgGroupFileEdge
    }
  }
  ${orgGroupFileFragment}
`;

export const assessmentFragment = gql`
  fragment AssessmentResource on AssessmentResource {
    assessmentId
    type
    name
  }
`;

export const orgAssessmentFragment = gql`
  fragment OrgAssessmentEdge on OrgAssessmentEdge {
    node {
      ...AssessmentResource
    }
    orgEntities {
      id
      name
    }
  }
  ${assessmentFragment}
`;

export const orgFragment = gql`
  fragment Org on Org {
    id
    name
    address
    pincode
    isInsurerSpecificAvailable
    meta
    batchConfig
    status
    accounts
    featureFlags
    brandCustomizations
    preferences
    benefits {
      ...OrgBenefitEdge
    }
    files {
      ...OrgGroupFileEdge
    }
    assessments {
      ...OrgAssessmentEdge
    }
    orgEntities {
      id
      name
      meta
      address
      pincode
      featureFlags
      brandCustomizations
      preferences
    }
  }
  ${orgBenefitFragment}
  ${orgAssessmentFragment}
  ${orgGroupFileFragment}
`;

export const orgFragmentForCliTools = gql`
  fragment Org on Org {
    id
    name
    featureFlags
    meta
    benefits {
      node {
        id
        name
        isPolicy
      }
    }
    orgEntities {
      id
      name
    }
  }
`;

export const orgResourceListFragment = gql`
  fragment Org on Org {
    id
    name
    address
    pincode
    isInsurerSpecificAvailable
    meta
    batchConfig
    status
    accounts
    featureFlags
    stats
    brandCustomizations
    preferences
    orgEntities {
      id
      name
      featureFlags
      brandCustomizations
      preferences
    }
  }
`;

export const orgIdSelectorFragment = gql`
  fragment Org on Org {
    id
    name
    orgEntities {
      id
      name
    }
  }
`;

export const orgWithEndorsementStatsFragment = gql`
  fragment Org on Org {
    id
    name
    batchConfig
    OrgEndorsementStats {
      additions
      deletions
      updates
    }
    orgEntities {
      id
      name
      orgEntityEndorsementStats {
        additions
        deletions
        updates
      }
    }
  }
`;
