<template lang="pug">
.from-card
  .from-card-details.my-1.pt-3.pl-3.pr-0
    .d-flex.align-items-center.cursor-pointer
      div.w-100.pr-3
        .font-md.font-weight-semibold(v-if="title") {{ title }}
        hr
        b-form
          vue-form-generator(
            :schema="schemaPart1"
            :options="formOptions"
            ref="formData1"
            :model="model",
            @validated="validateForm('part1', $event)")
        template(v-if="schemaPart2")
          hr.border-top-dashed
          b-form.mt-4
            vue-form-generator(
              :schema="schemaPart2"
              :options="formOptions"
              ref="formData2"
              :model="model",
              @validated="validateForm('part2', $event)")
    
</template>

<script>
import NovaIcon from "../../../../../components/NovaIcon.vue";
import { isVfgErrorPresent } from "@/utils";
export default {
  name: "FormCard",
  components: {
    NovaIcon,
  },
  props: {
    model: {
      type: Object,
      default: () => {},
    },
    title: {
      type: String,
      default: "",
    },
    schemaPart1: {
      type: Object,
      default: () => {},
    },
    schemaPart2: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      formOptions: {
        validateAfterLoad: false,
        validateAfterChanged: true,
        validateAsync: true,
      },
      formPart1Valid: false,
      formPart2Valid: false,
    };
  },
  async mounted() {
    this.$root.$on("validateForm", async () => {
      this.validate();
    });
  },
  methods: {
    async validate() {
      let isValid = false;
      if (
        (this.$route.path.includes("/nominee-details") || this.$route.path.includes("/contact-details")) &&
        this.$refs.formData1 &&
        this.$refs.formData2 &&
        this.schemaPart2
      ) {
        await Promise.all([this.$refs.formData1.validate(), this.$refs.formData2.validate()]);
        isValid = !isVfgErrorPresent(this.$refs.formData1.errors) && !isVfgErrorPresent(this.$refs.formData2.errors);
      } else if (this.$route.path.includes("/nominee-details") && this.$refs.formData1 && !this.schemaPart2) {
        await this.$refs.formData1.validate();
        isValid = !isVfgErrorPresent(this.$refs.formData1.errors);
      }

      this.$emit("validated", isValid);
    },
    validateForm(part, isValid) {
      if (part === "part1") {
        this.formPart1Valid = isValid;
      } else if (part === "part2") {
        this.formPart2Valid = isValid;
      }
      this.$store.commit("updateSuperTopupProspectStateMeta", {
        isFormValid: this.formPart1Valid && this.formPart2Valid,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
.from-card {
  .from-card-details {
    background-color: white;
    border: 0.125rem solid $gray-300;
    border-radius: 0.375rem;

    &.selected {
      background-color: $teal-100;
      border: 0.125rem solid $teal-700;
      box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.04);
    }
  }
}
</style>
