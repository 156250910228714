export const CheckupRoutes = Object.freeze({

  SCHEDULE_BOOKING: "schedule-booking",

  BOOKING_FORM: "booking-form",
  BOOKING_HISTORY: "booking-history",

  SAMPLE_COLLECTION: "sample-collection",
  PROVIDER_SELECTION: "provider-selection",
  ADDRESS_SELECTION: "address-selection",
  DEPENDENT_SELECTION: "dependent-selection",
  
  CHECKOUT: "checkout",
  PACKAGES: "packages"
  
})

