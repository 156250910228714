import { ApolloClient } from "apollo-client";
import { ApolloLink } from "apollo-link";
import { cache } from "./apolloCache";
import { httpLink } from "./middlewares/httpLink";
import { versioningMiddleware } from "./middlewares/versioning";
import { errorMiddleware } from "./middlewares/apolloError";
/*
  This client is used to refresh token
*/
export const apolloClientRefresh = new ApolloClient({
  link: ApolloLink.from([errorMiddleware, versioningMiddleware, httpLink]),
  cache,
  connectToDevTools: true,
});
