<template lang="pug">
div
  b-form#selfUserOnboardingForm(@submit.prevent="nextStep(+1)").w-80
    vue-form-generator(
      :schema="formSchema",
      :options="formOptions",
      :model="profileModel",
      ref="formData")
  
  special-modal(
    id="otp-verification-modal",
    title="otp-verification-modal",
    logoIcon="messaging",
    iconColor="gray-600",
    :enableCross="true",
    centered,:close="hideOtpModal",
    customContentStyle="pt-0 otp-special-modal"
  )
    template(v-slot:modal-content)
      .overflow-hidden.mt-5
        otp-modal(:profileModel="profileModel", :user="user", @otp-verified="onOtpVerified",@edit-clicked="focusOnContactNumberField()",customContentStyle="pt-4")
    template(v-slot:buttons-area)
      .mb-1
</template>

<script>
import mixin from "../mixin";
import SpecialModal from "../../../../../components/SpecialModal.vue";
import OtpModal from "../OtpModal.vue";
import { SELF_META_FIELDS } from "../constants";
import { extractMetaFields } from "./../utils";
export default {
  name: "SelfDetailsRight",
  components: {
    SpecialModal,
    OtpModal,
  },
  mixins: [mixin],
  async beforeRouteLeave(to, from, next) {
    await this.$refs.formData?.validate();
    if (this.isVfgErrorPresent(this.$refs.formData?.errors)) {
      this.$store.commit("addToast", {
        variant: "danger",
        message: "Please enter valid details before continuing to the next step",
      });
      return;
    }
    if (this.shouldShowOtpModal()) {
      this.$bvModal.show("otp-verification-modal");
      return;
    }
    this.$bvModal.hide("otp-verification-modal");
    this.$store.commit(
      "getStarted/setSelfDetails",
      (({ id, name, email, contactNumber, dob, displayName, gender, meta, insuranceStatus }) => ({
        id,
        name,
        email,
        contactNumber,
        dob,
        displayName,
        gender,
        meta: extractMetaFields(meta, SELF_META_FIELDS),
        insuranceStatus,
      }))(this.profileModel)
    );
    this.handleRouteChange(to, from, next);
  },
  data() {
    return {
      formOptions: {
        validateAfterLoad: false,
        validateAfterChanged: true,
        validateAsync: true,
      },
    };
  },
  watch: {
    "$store.state.getStarted.formData.selfDetails": {
      handler() {
        this.populateProfileModelFromStore();
      },
      deep: true,
    },
  },
  mounted() {
    this.populateProfileModelFromStore();
  },
  methods: {
    populateProfileModelFromStore() {
      this.profileModel = this.$store.state.getStarted.formData.selfDetails;
    },
    shouldShowOtpModal() {
      if (!this.isOtpEnabled || this.isOtpVerified) return false;
      return true;
    },
    async onOtpVerified() {
      this.$store.commit("getStarted/setSessionProps", {
        isOtpVerified: true,
      });
      await this.nextStep(+1);
    },
    hideOtpModal() {
      this.$bvModal.hide("otp-verification-modal");
    },
    focusOnContactNumberField() {
      const contactNumberField = document.getElementById("selfUserOnboardingForm");
      setTimeout(() => {
        contactNumberField?.scrollIntoView({ behavior: "smooth" });
      }, 100);
    },
  },
};
</script>

<style lang="scss" scoped>
.otp-special-modal {
  padding-bottom: 0rem !important;
}
</style>
