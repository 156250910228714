<template lang="pug">
n-popover.w-100.border-gray-200(
  :id="popoverId",
  :target="popoverId",
  :placement="'top'",
  :triggers="trigger"
)
  template(v-slot:title)
    .px-1.py-1
      .font-weight-medium.text-gray-700.font-sm Includes
  template
    .w-100
      div(v-for="(life, index) in lives", :key="index")
        .d-flex.align-items-center.w-100
          .d-flex.align-items-top
            n-avatar.text-blue-800.font-weight-semibold(
              :name="life",
              :size="1.5"
            )
          .d-flex.flex-column.align-items-baseline.ml-1
            span.font-weight-semibold.text-gray-900.text-capitalize {{ life }}
        template(v-if="index < lives.length - 1")
          hr.border-gray-600.w-100
  
  </template>
<script>
import NIcon from "../../../../../components/NovaIcon.vue";
import NButton from "../../../../../components/NovaButton.vue";
import mobileApp from "../../../../mobileApp";
import NPopover from "@/components/NovaPopover.vue";
import NAvatar from "@/components/Avatar.vue";
import mixin from "./../mixin";
export default {
  name: "PremiumPopover",
  mixins: [mixin],
  components: {
    NIcon,
    NButton,
    NIcon,
    NAvatar,
    NPopover,
  },
  data() {
    return {};
  },
  props: {
    popoverId: {
      type: String,
    },
    lives: {
      type: Array,
      default: () => [],
    },
    trigger: {
      type: String,
      default: "hover",
    },
  },
  computed: {},
  methods: {},
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
</style>
