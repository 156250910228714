export default {
  schema: {
    fields: [
      {
        model: "meta.crm.escalatedToHr",
        type: "select-cards",
        label: `Escalated to HR`,
        styleClasses: "d-block col-md-12 h-100",
        style: "margin-bottom: -1rem;",
        cardsData: [
          {
            label: "Yes",
            card_value: true,
          },
          {
            label: "No",
            card_value: false,
          },
        ],
      },
      {
        model: "meta.crm.escalatedToFounder",
        type: "select-cards",
        label: `Escalated to Founder`,
        styleClasses: "d-block col-md-12 h-100",
        style: "margin-bottom: -1rem;",
        cardsData: [
          {
            label: "Yes",
            card_value: true,
          },
          {
            label: "No",
            card_value: false,
          },
        ],
      },
    ],
  },
};
