import { GET_CHECKUP_VERIFIED_REPORTS, GET_CHECKUP_VOUCHER } from "../definitions";
import { apolloClient } from "@/portal/apollo";

export async function getCheckupVerifiedReports(appointmentId) {
  return apolloClient.query({
    query: GET_CHECKUP_VERIFIED_REPORTS,
    variables: { appointmentId },
  });
}
export async function getCheckupVoucher(appointmentId) {
  return apolloClient.query({
    query: GET_CHECKUP_VOUCHER,
    variables: { appointmentId },
  });
}
