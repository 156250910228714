<template lang="pug">
  b-modal#send-reminder-modal(
    size="lg",
    centered,
    ok-title="Send Reminders >",
    header-class="py-2",
    ok-variant="dark",
    cancel-variant="light",
    button-size="sm",
    @ok="sendReminders",
    body-class="reminder-modal-body"
  )
    template(#modal-header)
      .w-100.font-lg.text-gray-900.font-weight-semibold.text-center.py-0
        span Sending Reminders
    .row.mx-7
      h3.hb3.col-12.mt-3.px-0 {{ employeeCount }} Employees are missing critical data!
      .font-lg.font-weight-medium.text-gray-800.col-12.px-0 We will send them a reminder to fill the missing data to complete the enrollment process.
      .invite-container.w-100.rounded-8.p-4.mt-4
        .d-flex.align-items-center.justify-content-center
          b-img(:src="require('@/assets/images/notification-bell.svg')")
        h1.hb1.text-center.pt-2.pb-4 A gentle reminder!
        .font-lg.font-weight-medium.pt-2.pb-2 Hi There,
        .font-lg.font-weight-medium.py-2
          | {{`Your HR wants to remind you to provide your personal and your dependent's details ${getEnrollmentDueDateText}. If you miss this deadline, you won't be able to enroll in health insurance until the next month.`}}
          br
        .font-lg.font-weight-medium.py-2
          | You can start your coverage by completing your profile and verifying your contact details in a few simple steps.
        .text-center.my-2
          .btn.btn-activate.text-white Activate Coverage ->
</template>

<script>
import { mapGetters } from "vuex";

import orgAdminResDefs from "../../orgAdmin/definitions";
import utils from "@/utils";

export default {
  name: "ReminderModal",
  props: {
    employeeCount: {
      type: Number,
      default: 0,
      required: true,
    },
    isSelectedBatchOverdue: {
      type: Boolean,
      default: false,
    },
    batchId: {
      type: String,
      default: "",
    },
    orgId: {
      type: String,
      default: "",
    },
  },
  methods: {
    async sendInvitationsToOrgMembers(invitationType) {
      try {
        await this.$apollo.mutate({
          mutation: orgAdminResDefs.users.sendOnboardingInvites,
          variables: {
            inviteBy: "orgId",
            orgId: this.orgId,
            batchId: this.batchId,
            invitationType,
            isSelectedBatchOverdue: this.isSelectedBatchOverdue,
          },
        });
        let message;
        if (invitationType === "ACTIVATE_COVERAGE") {
          message = "Successfully sent onboarding emails";
        } else {
          message = "Successfully sent missing data reminders";
        }
        this.$store.commit("addToast", {
          variant: "success",
          message,
        });
      } catch (err) {
        this.$store.commit("addToast", {
          variant: "danger",
          message: "Unable to send emails",
        });
        throw err;
      }
    },
    async sendReminders() {
      window.posthog.capture("endorsements_approve_changes", {
        source: "top_card",
        number_of_employees: this.employeeCount,
        day_of_month: new Date().getDate(),
        org_name: this.user?.org?.name,
        email: this.user.email,
      });
      await this.sendInvitationsToOrgMembers("MISSING_DATA");
      this.$emit("resetFloatingBar");
      this.$emit("refreshTable");
    },
  },
  computed: {
    ...mapGetters(["user"]),
    getBatchDateString() {
      const dueDate = utils.getLastDateOfMonth(new Date());
      return utils.getDateWithSuffix(dueDate);
    },
    getEnrollmentDueDateText() {
      if (this.isSelectedBatchOverdue) {
        return "as soon as possible as your enrollment period is past due";
      }
      return "before the enrollment period ends on " + this.getBatchDateString;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
.invite-container {
  border-top: 0.5rem solid #196761;
  background: white;
}
.btn-activate {
  width: 58%;
  background: $teal-700;
  cursor: default !important;
}
</style>
<style lang="scss">
@import "@/assets/styles/_variables.scss";
.reminder-modal-body {
  background: $alabaster;
}
</style>
