import gql from "graphql-tag";

export default {
  upsertComment: gql`
    mutation upsertComment($entityId: ID!, $userId: ID!, $content: String!) {
      upsertComment(input: { entityId: $entityId, userId: $userId, content: $content }) {
        comment {
          content
          status
          user {
            name
          }
          createdAt
          updatedAt
        }
      }
    }
  `,
};
