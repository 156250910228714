<template lang="pug">
  n-sidesheet(:ssId="id", :headerName="`Status Details`")
    template(v-slot:tabs)
      template(v-for="batch in batches")
        .details-card.my-3.mx-1.p-3.shadow-xl.rounded-8
          .d-flex.flex-column
            div.my-1
              span.font-md.font-weight-semibold {{ batch.policyName }}
            span.font-sm.font-weight-medium.my-1 {{ batch.insurerName }}
            .d-flex.justify-content-between.align-items-center.px-2.py-3
              span.font-sm.font-weight-medium.text-gray-700 SRN number
              span.font-sm.font-weight-medium {{ batch.endorsementId }}
            .d-flex.justify-content-between.align-items-center.px-2.py-3.border-top
              span.font-sm.font-weight-medium.text-gray-700 Status
              n-chip(:variant="getVariantColor(batch.insurerStatus.toLowerCase())", pill) {{ batch.insurerStatus }}
            .d-flex.flex-column.align-items-flex-start.px-2.py-3.border-top(v-if="batch.insurerComment")
              span.font-sm.font-weight-medium.text-gray-700.mb-1 Comment From Insurer
              span.font-sm.font-weight-medium {{ batch.insurerComment }}
            .d-flex.justify-content-between.align-items-center.px-2.py-3.border-top
              span.font-sm.font-weight-medium.text-gray-700 Updated on
              span.font-sm.font-weight-medium {{ batch.updatedAt }}
            .d-flex.px-2.py-3.border-top
              .d-flex.flex-column.justify-content-start.align-items-start.w-50
                n-button.align-self-start(
                  v-if="showEndorsementCopyUrl(batch)",
                  buttonText="Endorsement Copy",
                  variant="dark",
                  size="sm",
                  :href="batch.endorsementCopyUrl",
                  imageIcon="download",
                  target="_blank")
                n-button.align-self-start.mt-1(
                  v-if="showActiveListUrl(batch)",
                  buttonText="Active-List.xlsx",
                  variant="dark",
                  size="sm",
                  :href="batch.activeListUrl",
                  imageIcon="download",
                  target="_blank")
                n-button.align-self-start(
                  v-if="showInformationDocUrl(batch)",
                  buttonText="Download-Comments.xlsx",
                  variant="dark",
                  size="sm",
                  :href="batch.informationRequiredDocUrl",
                  target="_blank")
              .d-flex.flex-column.justify-content-end.align-items-end.w-50
                n-button.align-items-end(
                  v-if="showSubmittedFile(batch)",
                  buttonText="Submitted File",
                  variant="dark",
                  size="sm",
                  :href="batch.submittedFileUrl",
                  imageIcon="download",
                  target="_blank")
                n-button.align-items-end(
                  v-if="batch.infoDetails",
                  buttonText="Show Details",
                  variant="dark",
                  size="sm",
                  v-b-toggle="`show-details-${batch.endorsementId}`")
            b-collapse.w-100.mt-1(v-if="batch.infoDetails", :id="`show-details-${batch.endorsementId}`")
              n-text-area.font-xs(
                :value="batch.infoDetails.message",
                :state="batch.infoDetails.state",
                :subtext="batch.infoDetails.title",
                :maxLength="1000000",
                fontSize="xs",
                readonly)
    template(v-slot:footer)
      div.py-1.d-flex.bg-white.px-3
        n-button.mb-2(
          type="button",
          buttonText="Resync Insurer Batches",
          variant="dark",
          imageIcon="refresh",
          @click="resyncInsurerBatchesStatus")

</template>

<script>
import EmptyStatesEndorsements from "@/components/Cards/EmptyStates/Endorsements.vue";
import NSidesheet from "@/components/NovaSideSheet";
import NChip from "@/components/NovaChip.vue";
import NButton from "@/components/NovaButton.vue";
import adminDefs from "@/portal/pages/admin/definitions";
import NTextArea from "@/components/NovaTextArea.vue";

export default {
  name: "InsurerBatchSidesheet",
  components: {
    EmptyStatesEndorsements,
    NSidesheet,
    NChip,
    NButton,
    NTextArea,
  },
  props: {
    insurerBatches: {
      type: Array,
      default: () => [],
    },
    userChangeBatchId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      id: "insurer-batch-side-sheet",
      batches: [],
    };
  },
  watch: {
    insurerBatches(newVal) {
      this.batches = [...newVal];
    },
  },
  methods: {
    getVariantColor(insurerStatus) {
      const dangerStatus = ["nova error", "information required", "funds required", "reject", "process failed"];
      const successStatus = ["resolved", "process completed"];
      const secondaryStatus = ["open", "nova created"];
      if (dangerStatus.includes(insurerStatus)) {
        return "danger";
      } else if (successStatus.includes(insurerStatus)) {
        return "success";
      } else if (secondaryStatus.includes(insurerStatus)) {
        return "secondary";
      }
      return "warning";
    },
    showInformationDocUrl(batch) {
      return Boolean(
        ["information required", "funds required"].includes(batch.insurerStatus?.toLowerCase()) &&
          batch.informationRequiredDocUrl
      );
    },
    showSubmittedFile(batch) {
      return Boolean(batch.submittedFileUrl);
    },
    showEndorsementCopyUrl(batch) {
      return Boolean(batch.endorsementCopyUrl);
    },
    showActiveListUrl(batch) {
      return Boolean(batch.activeListUrl);
    },
    async resyncInsurerBatchesStatus() {
      try {
        const result = await this.$apollo.mutate({
          mutation: adminDefs.insurerBatches?.resyncInsurerBatchesStatus,
          variables: {
            batchId: this.userChangeBatchId,
          },
        });
        const acceptableJobStatus = ["completed", "waiting", "active", "delayed", "paused"];
        if (!acceptableJobStatus.includes(result?.data?.resyncInsurerBatchesStatus?.job?.status)) {
          throw new Error("Could not re-sync insurer status");
        }
        this.$store.commit("addToast", {
          variant: "success",
          message: "Update initiated, you can check the status on Engineering Jobs Page",
        });
        await this.getUpdatedInsurerDetails();
      } catch (err) {
        console.error(err);
        this.$store.commit("addToast", {
          variant: "danger",
          message: "Error occurred while re-syncing insurer status.",
        });
      }
    },
    async getUpdatedInsurerDetails() {
      const result = await this.$apollo.mutate({
        mutation: adminDefs.insurerBatches?.getInsurerEndorsementBatches,
        variables: {
          batchId: this.userChangeBatchId,
        },
      });
      const insurerBatches = result?.data?.getInsurerEndorsementBatches?.edges?.map(({ node }) => node) || [];
      if (insurerBatches?.length) {
        this.batches = insurerBatches;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
@import "@/assets/styles/mixins/_breakpoints.scss";

.details-card {
  background: $white;
}
</style>
