<template lang="pug">
.n-search-bar
  n-inline-input(
              v-model="ctrlValue"
              :placeholder="placeholder",
              :customClasses="['font-sm']",
              imageIcon="search",
              data-cy="nova-search-bar-input",
              :rightImageIcon="rightImageIcon",
              @click-right-icon="clearSearchQueryAndResults"
            )
  .search-results.rounded.mt-1.shadow-sm(v-if="showSearchResults && searchResults.length > 0")
    template(v-for="(searchResult,index) in searchResults")
      .search-result.text-gray-900.p-2.cursor-pointer.border-bottom(:class="[index===0 ? 'rounded-top': '', index === searchResults.length-1 ? 'rounded-bottom' : '' ]", @click="updateSelectedResultIndex(index)") {{searchResult}}
</template>

<script>
import NInlineInput from "./NovaInlineInput.vue";

export default {
  Name: "NovaSearchBar",
  components: {
    NInlineInput,
  },
  props: {
    value: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: null,
    },
    searchResults: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      showSearchResults: true,
      selectedResultIndex: null,
    };
  },
  computed: {
    rightImageIcon() {
      if (this.ctrlValue && this.ctrlValue !== "") {
        return "cross";
      }
      return "";
    },
    ctrlValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  watch: {
    searchResults() {
      if (this.searchResults.length > 0) {
        this.showSearchResults = true;
      }
    },
  },
  methods: {
    updateSelectedResultIndex(index) {
      this.selectedResultIndex = index;
      this.showSearchResults = false;
      this.$emit("select-result", index);
      this.ctrlValue = this.searchResults[index];
    },
    clearSearchQueryAndResults() {
      this.ctrlValue = null;
      this.showSearchResults = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
@import "@/assets/styles/mixins/_breakpoints.scss";
.border-bottom {
  border-bottom: 1px solid $gray-300;
}
.search-result:hover {
  background-color: $gray-300;
}
.search-result:active {
  background-color: $blue-500;
  color: white;
}
</style>
