import moment from "moment";
import { isEmpty } from "lodash-es";
import { UserAddSource, AcceptedRelations, DependentStatus } from "../common/enums";
import miscUtils from "./misc";

const userStatus = {
  UP_TO_DATE: "up-to-date",
  DELETE_PENDING: "delete-pending",
  SUSPENDED: "suspended",
  UPDATE_PENDING: "update-pending",
  ADDITION_PENDING: "addition-pending",
};

const adminUserStatus = {
  FETCHED: "fetched",
  UNAUTHORIZED: "unauthorized",
  ACTIVE: "active",
  DELETED: "deleted",
  INVITED: "invited",
  ENROLLMENT_PENDING: "enrollment-pending",
  // The user has not accepted the invitation but a 3rd
  // party has filled the required data
  ENROLLED_AT_SOURCE: "enrolled-at-source",
  SUSPENDED: "suspended",
  DELETE_PENDING: "delete-pending",
  DISABLED: "disabled",
};

const userPriority = {
  STANDARD: "standard",
  HIGH: "high",
};

export default {
  userStatus,
  adminUserStatus,
  userPriority,
  simplifiedUserStatusMapping: {
    [userStatus.ACTIVE]: adminUserStatus.ACTIVE,
    [userStatus.ENROLLED_AT_SOURCE]: adminUserStatus.ADDITION_PENDING,
    [userStatus.DISABLED]: adminUserStatus.INACTIVE,
    [userStatus.SUSPENDED]: adminUserStatus.INACTIVE,
    [userStatus.UNAUTHORIZED]: adminUserStatus.INACTIVE,
    [userStatus.DELETE_PENDING]: adminUserStatus.DELETE_PENDING,
    [userStatus.INVITED]: adminUserStatus.ADDITION_PENDING,
    [userStatus.FETCHED]: adminUserStatus.ADDITION_PENDING,
    [userStatus.ENROLLMENT_PENDING]: adminUserStatus.ADDITION_PENDING,
  },
  getMembersCoveredForBenefit(user, benefitId, includeInReviewPolicies = false) {
    const membersCovered = [];
    const isSelfCovered =
      user.benefits?.find((benefit) => benefit.node.id === benefitId) ||
      user.userChanges?.find(
        (userChange) => userChange.benefit?.id === benefitId && this.isUserChangeAppropriate(userChange)
      );

    if (isSelfCovered) {
      membersCovered.push({
        id: user.id,
        name: user.name,
        dob: user.dob,
        gender: user.gender,
        relation: "self",
        meta: {},
      });
    }
    if (user.dependents) {
      membersCovered.push(
        ...user.dependents.filter((dependent) => {
          for (let i = 0; i < dependent.benefits.length; i++) {
            if (dependent.status !== DependentStatus.DELETE_PENDING && dependent.benefits[i].node.id === benefitId) {
              return true;
            }
          }
          return false;
        })
      );
    }

    if (!includeInReviewPolicies) {
      return membersCovered;
    }

    user.userChanges?.forEach((userChange) => {
      if (userChange.benefit?.id !== benefitId) {
        return;
      }
      if (
        userChange.dependent &&
        userChange.dependent.status !== DependentStatus.DELETE_PENDING &&
        this.isUserChangeAppropriate(userChange)
      ) {
        // get dependent data from user object as we only fetch dependent id in user changes
        const memberData = {
          ...userChange.dependent,
          ...(user.dependents?.find((dependent) => dependent.id === userChange.dependent.id) || {}),
        };
        membersCovered.push(memberData);
      }
    });

    return membersCovered;
  },
  getDependentsCoveredForBenefit(user, benefitId) {
    if (benefitId) {
      const dependentsCovered =
        user.dependents?.filter((dependent) => {
          for (let i = 0; i < dependent.benefits.length; i++) {
            if (dependent.benefits[i].node.id === benefitId) {
              return true;
            }
          }
          return false;
        }) | [];
      return dependentsCovered;
    }
    return [];
  },
  getCoverageValue(user, userChanges, policy, includeInReviewPolicies = false) {
    let coverageValue = null;
    if (user && policy) {
      let numDeps = 0;
      user.dependents?.forEach((dependent) => {
        dependent.benefits.forEach((benefit) => {
          if (benefit.node.id === policy.id) {
            numDeps += 1;
          }
        });
      });
      // include inreview policies
      if (includeInReviewPolicies && userChanges) {
        userChanges.forEach((userChange) => {
          if (
            userChange.benefit?.id === policy.id &&
            userChange.dependent &&
            this.isUserChangeAppropriate(userChange)
          ) {
            numDeps += 1;
          }
        });
      }
      let isSelfCovered = false;
      if (user.benefits) {
        isSelfCovered =
          user.benefits.findIndex((benefit) => benefit.node.id === policy.id) !== -1 ||
          userChanges.find(
            (userChange) => userChange.benefit?.id === policy.id && this.isUserChangeAppropriate(userChange)
          );
      }

      if (isSelfCovered !== false && policy?.type !== "ppc") {
        if (numDeps) coverageValue = `You + ${numDeps} dependent${numDeps > 1 ? "s" : ""}`;
        else coverageValue = "Just You";
      } else {
        coverageValue = `${numDeps} dependents`;
      }
    }
    return coverageValue;
  },
  requiresEndorsement(user) {
    if (user.benefits.length > 0 || user.userChanges.length > 0) {
      return true;
    }
    if (user.dependents.some((dependent) => dependent.benefits.length > 0)) {
      return true;
    }
  },
  extractUserDependentBenefits(me, isPolicy = false) {
    const combinedUserDependentBenefits = {};
    const today = moment(new Date()).format("YYYY-MM-DD");
    // getting dependent benefits
    me.dependents?.forEach((dependent) => {
      dependent.benefits.forEach((benefit) => {
        // TODO: refactor expiry to decide on status instead of end date
        if (benefit.node?.isPolicy === isPolicy && moment(today).isSameOrBefore(benefit.node.meta?.endDate)) {
          combinedUserDependentBenefits[benefit.node.id] = benefit;
        }
      });
    });
    // getting user benefits
    me.benefits.forEach((benefit) => {
      // TODO: refactor expiry to decide on status instead of end date
      if (benefit.node?.isPolicy === isPolicy && moment(today).isSameOrBefore(benefit.node.meta?.endDate)) {
        combinedUserDependentBenefits[benefit.node.id] = benefit;
      }
    });

    return Object.values(combinedUserDependentBenefits);
  },
  getPolicyType(policyType) {
    switch (policyType) {
      case "gmc":
        return "Group Health Insurance";
      case "opd":
        return "OPD Insurance";
      case "gpa":
        return "Group Personal Accident Coverage";
      case "gtl":
        return "Group Term Life Insurance";
      case "covid":
        return "COVID-19 Insurance";
      case "topup":
        return "Top-up Insurance";
      case "super-topup":
        return "Super Top-up Insurance";
      case "ppc":
        return "Parental Policy";
      case "gratuity":
        return "Future Service Gratuity";
    }
  },
  getCoverageType(coverageType) {
    switch (coverageType) {
      case "E":
        return "Employee Only";
      case "ES":
        return "Employee & Spouse";
      case "ESC":
        return "Employee, Spouse\n& Children";
      case "ESCP":
        return "Employee, Spouse,\nChildren & Parents";
      default:
        return "Data not found";
    }
  },
  // Returns obj with covered dependents' details when a set of policies/ benefits is passed to it
  totalCoveredDependents(policies) {
    const depObj = {
      childrenCount: 0,
      coveredDependents: [],
      pOrILFlag: false,
      // for custom family definition, 0 means there is no group present
      groupedParentsCount: 0,
      groupedChildSiblingCount: 0,
    };
    if (!policies?.length) return depObj;
    policies.forEach((policy) => {
      depObj.childrenCount = Math.max(policy.getCoveredDependents.childrenCount, depObj.childrenCount);
      depObj.groupedChildSiblingCount = Math.max(
        policy.getCoveredDependents.groupedChildSiblingCount,
        depObj.groupedChildSiblingCount
      );
      depObj.groupedParentsCount = Math.max(
        policy.getCoveredDependents.groupedParentsCount,
        depObj.groupedParentsCount
      );
      const coveredDependentsForPolicy = miscUtils.deepClone(policy.getCoveredDependents.coveredDependents);
      // coveredDependentsForPolicy is array but deepclone on array of observer objects make it object
      if (Object.keys(coveredDependentsForPolicy)?.length || coveredDependentsForPolicy?.length) {
        depObj.coveredDependents = [...new Set([].concat(depObj.coveredDependents, coveredDependentsForPolicy))];
      }
      depObj.pOrILFlag = depObj.pOrILFlag || policy.getCoveredDependents.pOrILFlag;
    });
    return depObj;
  },
  // Returns text in the format `Rel1, Rel2, and Rel3` when an obj with covered dependents' details is passed to it
  getContent(depObj) {
    let content = "";
    if (depObj && depObj?.coveredDependents?.length) {
      // Capitalizing the first character for every string in the array
      let depArray = depObj.coveredDependents.map((rel) => rel.charAt(0).toUpperCase() + rel.substr(1));
      // If it is a case of `Parent` or `Parent-in-law` being covered
      // Pushing this string into the array
      if (depObj.pOrILFlag) depArray.push("Parents/ Parents-in-law");
      // If there exists a `childrenCount` i.e. children in the covered relation set
      // Appending the count value before the `Children` string in the array
      if (depObj.childrenCount) {
        depArray = depArray.map((rel) => (rel === "Child" ? `${depObj.childrenCount} ${rel}ren` : rel));
      }
      // Finally adding `,` and `, and` to right positions and where needed
      content =
        depArray.length > 1
          ? `${depArray.slice(0, depArray.length - 1).join(", ")}, and ${depArray.slice(-1)}.`
          : `${depArray[0]}.`;
    }
    return content;
  },
  getInReviewBenefitsFromUserChanges(userChanges, isPolicy) {
    if (!userChanges) {
      return [];
    }

    return userChanges
      .filter((userChange) => userChange.benefit?.isPolicy === isPolicy && this.isUserChangeAppropriate(userChange))
      .map((userChange) => ({
        node: userChange.benefit,
        meta: {
          ...(userChange.changedBenefitInfo?.meta || []),
          ...userChange.meta,
        },
      }));
  },
  getFamilyDefinition(familyDefinition) {
    // custom family definition only for TATA Captial
    // TODO: refactor this into switch condition.
    if (familyDefinition === "ES8CP+IL" || familyDefinition === "ES8CP/IL") {
      return "Self, Spouse, Children & Parent";
    }

    if (familyDefinition) {
      let shortName = familyDefinition.replace(/\d+/g, "");

      if (this.isCustomFamilyDefinition(familyDefinition)) {
        shortName = shortName.replace(/\$/, "").replace(/_/g, "");
      }

      switch (shortName) {
        case "E":
          return "Just You";
        case "ES":
          return "Your Spouse & You";
        case "ESC":
          return "Your Spouse, Children & You";
        case "ESCP":
          return "Your Parents, Spouse,\nChildren & You";
        case "ESCP/IL":
          return "Parents or Parents-in-law, Spouse,\nChildren & You";
        case "ESCP+IL":
          return "Parents, Parents-in-law, Spouse,\nChildren & You";
        case "EP":
          return "Your Parents & You";
        case "EP/IL":
          return "Parents or Parents-in-law, & You";
        case "P":
          return "Your Parents only";
        case "P/IL":
          return "Your Parents or Parents-in-law only";
        case "ES(C+W)P/IL":
          return "Parents or Parents-in-law, Spouse,\nChildren, Sibling & You";
        case "ESC(P+IL)":
          return "Parents, Parents-in-law, Spouse,\nChildren & You";
        default:
          return "Data not found";
      }
    }
    return "";
  },

  isCustomFamilyDefinition(famDef) {
    return famDef?.[0] === "$";
  },
  getSuperTopupFamilyDefination(coveredMembers) {
    let hasSpouse = false;

    for (const member of coveredMembers) {
      if (member.relation === AcceptedRelations.PARENT) {
        return "Father & Mother";
      } else if (member.relation === AcceptedRelations.CHILD) {
        return "You, Spouse and 2 Children";
      } else if (member.relation === AcceptedRelations.SPOUSE) {
        hasSpouse = true;
      }
    }

    if (hasSpouse) {
      return "Self and Spouse";
    }

    return "Self";
  },

  bundlePoliciesWithEdgeMeta(policies) {
    if (!policies.length) return policies;

    return policies.map(({ node, meta }) => {
      return {
        ...node,
        meta: {
          ...node.meta,
          ...meta,
        },
      };
    });
  },
  isUserChangeAppropriate(userChange) {
    const unwantedUserChangeStatus = ["rejected", "deleted", "archive"];
    return userChange.type === "add" && !unwantedUserChangeStatus.includes(userChange.status);
  },
  /* Showing dom field when -
  1. the Dep already has a dom present
  2. Relations is Spouse, and is a new dep addition, user has already linked benefits or has isMidTermAddition flag as true */
  showDOMField(dependent, isMidTerm) {
    return (
      (dependent.relation === AcceptedRelations.SPOUSE &&
        ((!dependent.id && isMidTerm) || dependent?.meta?.isMidTermAddition)) ||
      dependent?.meta?.dom
    );
  },
  isUserBenefitLinked(userLinkedBenefits, policyId) {
    return !isEmpty(userLinkedBenefits.find((benefit) => benefit?.node?.id === policyId));
  },
};

export function getActivePolicyByType(allPolicies, policyType) {
  let policy = {};
  if (!allPolicies) return policy;

  policy = allPolicies.find(
    (p) => p.node.type === policyType && (!p.node?.meta?.endDate || moment(p.node.meta.endDate).isAfter(moment()))
  );
  return policy;
}

// TODO: Move to yarn workspaces common function between portal and API
export function getDependentCountForEachRelation(familyDefinition) {
  const relationCountMapping = {
    S: { spouse: 1 },
    P: { parent: 2 },
    "P/IL": { parent: 2, parentInLaw: 2, isParentOrPIL: true },
    "P+IL": { parent: 2, parentInLaw: 2 },
  };
  let relations = {};
  const familyDefinitionMatcher = /(P\/?\+?IL|\d?[SCP])/gi;
  const parsedFamilyDefinitions = familyDefinition.match(familyDefinitionMatcher) || [];

  parsedFamilyDefinitions.forEach((parsedFamilyDefinition) => {
    if (parsedFamilyDefinition.indexOf("C") !== -1) {
      relations.child = parseInt(parsedFamilyDefinition[0]);
    } else if (parsedFamilyDefinition in relationCountMapping) {
      relations = {
        ...relations,
        ...relationCountMapping[parsedFamilyDefinition],
      };
    }
  });
  return relations;
}
