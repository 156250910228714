import { getIdSelectorQuery } from "../queries";

export default {
  schema: {
    fields: [
      {
        model: "org",
        type: "id-selector",
        queryName: "orgs",
        gqlQuery: getIdSelectorQuery("Org"),
        label: "Org",
        showSelected: false,
        required: true,
        validator: "required",
      },
      {
        model: "orgEntity",
        type: "id-selector",
        queryName: "orgEntities",
        gqlQuery: getIdSelectorQuery("OrgEntity"),
        queryVariables: () => ({ filter: {} }),
        label: "Org Entity",
        visible: false,
        required: true,
      },
    ],
  },
  transformArgs: ({ org, orgEntity, ...args }) => ({
    ...args,
    orgId: org?.id,
    orgEntityId: orgEntity?.id || null,
  }),
};
