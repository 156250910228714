import gql from "graphql-tag";

export default {
  upsertMutation: gql`
    mutation upsertUserAddress(
      $id: ID
      $userId: ID!
      $city: String
      $state: String
      $country: String
      $pincode: String
      $addressLineOne: String
      $addressLineTwo: String
      $meta: JSONObject
      $isPrimary: Boolean
      $status: String
    ) {
      upsertUserAddress(
        input: { 
          id: $id,
          userId: $userId,
          city: $city,
          state: $state,
          country: $country,
          pincode: $pincode,
          addressLineOne: $addressLineOne,
          addressLineTwo: $addressLineTwo,
          meta: $meta,
          isPrimary: $isPrimary,
          status: $status
        }
      ) {
        id
        city
        state
        country
        pincode
        addressLineOne
        addressLineTwo
        isPrimary
        meta
      }
    }
  `,
};
