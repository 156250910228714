import "core-js";
import "regenerator-runtime/runtime";
import Vue from "vue";
import Vuex from "vuex";
import VueApollo from "vue-apollo";
import VueGtag from "vue-gtag";

import VueProgressBar from "vue-progressbar";
import Vue2TouchEvents from "vue2-touch-events";
import Vueform from "@vueform/vueform";
import { captureError } from "../utils";
import App from "./App";
import { store } from "./store";
import router from "./router/index";
import { apolloClient } from "./apollo";
import NovaTheme from "@/plugins/novaTheme";
import "../registerServiceWorker";
import vueformConfig from "./vueform.config";

Vue.config.errorHandler = function (err, vm, info) {
  captureError(err, info);
};

window.onerror = function (message, url, lineNo, columnNo, error) {
  captureError({
    ...error,
    message,
    // capture possible information loss
    stack: `url: ${url}, lineNo: ${lineNo}, columnNo: ${columnNo}`,
  });
};

const vueProgressBarOptions = {
  color: "#78f440",
  failedColor: "#874b4b",
  thickness: "4px",
};

Vue.use(VueProgressBar, vueProgressBarOptions);

Vue.use(Vue2TouchEvents, {
  disableClick: true,
});

Vue.use(Vuex);
Vue.use(NovaTheme);
if (process.env.VUE_APP_PORTAL_GA_ID) Vue.use(VueGtag, { config: { id: process.env.VUE_APP_PORTAL_GA_ID } }, router);
Vue.use(VueApollo);

Vue.use(Vueform, vueformConfig);
const apolloProvider = new VueApollo({
  defaultClient: apolloClient,
  defaultOptions: {
    $loadingKey: "loading",
  },
});

/* eslint-disable no-new */
new Vue({
  router,
  store,
  apolloProvider,
  render: (h) => h(App),
}).$mount("#app");
