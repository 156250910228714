import gql from "graphql-tag";

export const stagedUserFragment = gql`
  fragment StagedUser on StagedUser {
    id
    org {
      id
      name
    }
    orgEntity {
      id
      name
    }
    employeeId
    email
    relation
    name
    gender
    dob
    doj
    doe
    contact
    grade
    errors
    meta
    status
    createdAt
    updatedAt
  }
`;
