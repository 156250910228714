import gql from "graphql-tag";

export const queryFragment = gql`
  fragment ClaimQuery on ClaimQuery {
    id
    claimId
    queryText
    queryLetterLink
    claimantResponse
    status
    meta
    createSource
    raisedAt
    claimantRespondedAt
    caRespondedAt
    resolvedAt
    createdAt
    updatedAt
  }
`;
