export function getDeductibleToSIMap(retailPolicyList) {
  return retailPolicyList.reduce((acc, policy) => {
    const retailPolicyId = policy.id; // Adjust this line to match the actual ID property name
    acc[retailPolicyId] = policy.chargesConfig.rater.config.reduce((innerAcc, configItem) => {
      const { deductible, si } = configItem;
      innerAcc[deductible] = Array.from(new Set([...(innerAcc[deductible] || []), si]));
      return innerAcc;
    }, {});
    return acc;
  }, {});
}
