import gql from "graphql-tag";
import { echoPostFragment } from "../fragments/echoPost";

export default {
  upsertMutation: gql`
    mutation upsertEchoPost(
      $id: ID
      $echoPostTemplateId: ID!
      $userId: ID!
      $platform: String
      $message: String
      $status: String
      $meta: JSONObject!
    ) {
      upsertEchoPost(
        input: {
          id: $id
          userId: $userId
          echoPostTemplateId: $echoPostTemplateId
          platform: $platform
          message: $message
          status: $status
          meta: $meta
        }
      ) {
        echoPost {
          ...EchoPost
        }
      }
    }
    ${echoPostFragment}
  `,
}
