<template lang="pug">
span
  b-badge.n-popover-chip.d-inline-flex.align-items-center(
    :id="chipId",
    v-bind="{ ...$props, ...$attrs }",
    :class="[{'minimal-chip': isMinimal}, borderVariantClass]",
    v-on="$listeners",
    @click="$bvModal.show(`${chipId}-modal`)")
    template
      slot(name="icon")
    template.font-weight-medium.font-xs
      slot(name="default")
        span {{ chipText }}
    template(v-if="$slots.secondary")
      span.mx-2.text-gray-900 |
      slot(name="secondary")

  template(v-if="!disablePopover")
    n-popover(
      v-if="!isMobileView()",
      :target="chipId",
      :triggers="triggers")
      template(v-slot:title)
        .d-flex.align-items-center
          n-icon.mr-1.m-1(
            :name="chipIcon",
            :pathCount="2")
          div {{ popoverHeading }}
      slot(name="popover-body")
        .p-1(v-if="status !== 'archive'")
          .text-gray-800.font-weight-medium.font-sm {{ popoverTitle }}
          span.text-gray-700.font-weight-medium.font-sm.my-3(v-if="popoverDescription")
            span(
              v-if="status !== 'done' && status !== 'archive'") Part of&nbsp;
            b-link
              span.d-inline-flex
                span.font-sm {{ popoverDate }} Endorsement
                i.icon-arrow.font-xs.rotated
            .text-gray-700.font-weight-medium.font-xs {{ popoverSubTitle }}
          .text-gray-900.font-weight-medium.font-xs.mt-2 {{ popoverDescription }}

    n-modal(
      v-else,
      :id="`${chipId}-modal`",
      centered,
      isScrollable=true,
      no-close-on-backdrop,
      ok-only)
      template(v-slot:modal-header)
        .d-flex.align-items-center
          n-icon.mr-1.m-1(
            :name="chipIcon",
            :pathCount="2")
          div {{ popoverHeading }}

      template.justify-content-center.align-items-center.flex-column(v-slot:default)
        .p-2(v-if="status !== 'archive'")
          .text-gray-900.font-weight-medium.font-sm {{ popoverTitle }}
          span.text-gray-700.font-weight-medium.font-sm.my-3(v-if="popoverDescription")
            span(v-if="status !== 'done' && status !== 'archive'") Part of&nbsp;
            b-link
              span.d-inline-flex
                span.font-sm {{ popoverDate }} Endorsement
                i.icon-arrow.font-xs.rotated
            .text-gray-700.font-weight-medium.font-xs {{ popoverSubTitle }}
          .text-gray-900.font-weight-medium.font-xs.mt-2 {{ popoverDescription }}
      template(v-slot:modal-footer)
        n-button.float-right(variant="light", size="sm", buttonText="Close", @click="$bvModal.hide(`${chipId}-modal`)")
</template>

<script>
import NModal from "./NovaModal.vue";
import NButton from "./NovaButton.vue";
import NPopover from "./NovaPopover.vue";
import utils from "@/utils";
export default {
  name: "NPopoverChip",
  components: {
    NModal,
    NButton,
    NPopover,
  },
  props: {
    chipIcon: {
      type: String,
    },
    chipText: {
      type: String,
    },
    isMinimal: {
      type: Boolean,
      default: false,
    },
    chipId: {
      type: String,
    },
    popoverTitle: {
      type: String,
    },
    popoverSubTitle: {
      type: String,
    },
    popoverDescription: {
      type: String,
    },
    popoverHeading: {
      type: String,
    },
    popoverDate: {
      type: String,
    },
    status: {
      type: String,
    },
    disablePopover: {
      type: Boolean,
      default: false,
    },
    borderVariant: {
      type: String,
      default: "",
    },
    triggers: {
      type: String,
      default: "",
      validator: (propValue) => {
        const isValidTrigger = ["hover", "click"].includes(propValue);
        return isValidTrigger || !propValue;
      },
    },
  },
  computed: {
    borderVariantClass() {
      return `border-color-${this.borderVariant}`;
    },
  },
  methods: {
    isMobileView() {
      return utils.mobileCheck();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
@import "@/assets/styles/_typography.scss";

.n-popover-chip {
  padding: 0.25rem 0.625rem;
  border-radius: 0.375rem;
  max-width: max-content;
  cursor: pointer;

  .chip-icon {
    color: $white;
  }
}
.badge-pill {
  border-radius: 10rem;
}

.minimal-chip {
  border: 1px solid $gray-300;
}

.border-color-default {
  border-color: $gray-300;
}
.border-color-danger {
  border-color: $red-200;
}

.icon-minus-circle:before {
  color: #ee6161 !important;
}
.icon-error:before {
  color: $red-600;
}
</style>
