import gql from "graphql-tag";

export const CXPodFragment = gql`
  fragment CXPod on CXPod {
    id
    name
    meta
    updatedAt
    createdAt
  }
`;

export const CXPodMemberFragment = gql`
  fragment CXPodMember on CXPodMember {
    id
    name
    user {
      id
      email
      name
      meta
    }
    pod {
      ...CXPod
    }
    role
    status
    meta
    updatedAt
    createdAt
  }
  ${CXPodFragment}
`;

export const CXAccountOwnerFragment = gql`
  fragment CXAccountOwner on CXAccountOwner {
    id
    name
    podMember {
      ...CXPodMember
    }
    pod {
      ...CXPod
    }
    user {
      id
      email
      name
      meta
    }
    org {
      id
      name
      meta
    }
    meta
    updatedAt
    createdAt
  }
  ${CXPodMemberFragment}
  ${CXPodFragment}
`;
