<template lang="pug">
b-card.p-3(no-body)
  .row
    .col-md-3.col-sm-12.border-right.p-0.pl-3.d-none.d-md-block.pt-3
      h5.font-hc.text-gray-600 Edit Details
      profile-nav
    .col-md-9.col-sm-12.pt-3
      add-dependents-wrapper(
        :dependentsModel="selfDependents",
        :user="restUser",
        :userChanges="userChanges",
        :policies="orgPolicies",
        :isMidTermAddition="isMidTermAddition",
        :restrictDependentAddition="restrictDependentAddition",
        @dependentsUpdated="saveDependents")
      success-modal(
        :relation="getTargetDepRelation",
        :policies="getRelevantPolicies()",
        :isNewAddition="checkIfNewAddition",
        :allDependentsAllowed="isMidTermAddition || restrictDependentAddition")

      .modal-backdrop.bg-gray-100(v-if="isLoading")
      n-spinner(
        :active="isLoading",
        is-full-screen,
        spinner="ring",
        size="50",
        duration="1")
</template>

<script>
import gql from "graphql-tag";
import resDefs from "../definitions";
import { orgBenefitFragment } from "../../admin/fragments";
import { BenefitTypes, DependentAddSource, DependentStatus } from "../../../../common/enums";
import userDefs from "../../../pages/user/definitions";
import { MY_INFO } from "../../../../common/definitions/auth";
import ProfileNav from "./components/ProfileNav.vue";
import SuccessModal from "./components/SuccessModal.vue";
import utils, { isWellnessOnlyOrg } from "@/utils";
import NButton from "@/components/NovaButton.vue";
import NAvatar from "@/components/Avatar.vue";
import AddDependentsWrapper from "@/components/AddDependents/AddDependentsWrapper.vue";

const ACCEPTED_FIELDS = ["name", "dob", "relation", "gender"];
const ACCEPTED_META_FIELDS = ["dom"];
export default {
  components: {
    NButton,
    NAvatar,
    AddDependentsWrapper,
    ProfileNav,
    SuccessModal,
  },
  data() {
    return {
      formOptions: {
        validateAfterLoad: false,
        validateAfterChanged: false,
        validateAsync: true,
      },
      restUser: {},
      policies: [],
      targetDepRelation: null,
      isNewAddition: false,
      isLoading: false,
    };
  },
  computed: {
    restrictDependentAddition() {
      return this.restUser?.restrictDependentAddition;
    },
    selfDependents() {
      // We don't show delete-pending dependents in profile screen
      return utils
        .deepClone(this.restUser?.dependents || [])
        .filter((dep) => dep.status !== DependentStatus.DELETE_PENDING);
    },
    orgPolicies() {
      if (this.restUser) return this.benefitsRelatedToUser;
      return [];
    },
    getTargetDepRelation() {
      return this.targetDepRelation;
    },
    checkIfNewAddition() {
      return this.isNewAddition;
    },
    getPolicySet() {
      const benefits = this.benefitsRelatedToUser || [];
      return benefits.map((benefit) => {
        return {
          type: benefit.type,
          coveredDependentsWrapper: benefit.getCoveredDependents,
        };
      });
    },
    isMidTermAddition() {
      return this.restUser?.isMidTerm;
    },
  },
  created() {
    this.$apollo.addSmartQuery("profileModel", {
      query: MY_INFO,
      update(data) {
        const user = { ...data.me };
        if (user.org) {
          user.orgId = user.org.id;
        }
        const { policies, __typename, benefits, ...restUser } = user;
        this.policies = utils.bundlePoliciesWithEdgeMeta(benefits);
        this.restUser = restUser;
        return this.restUser;
      },
    });
  },
  mounted() {
    this.$store.commit("updateSectionHeader", "Your Profile");
    this.$store.commit("updateNavHeader", {
      name: "Basic Details",
      back: true,
    });
  },
  methods: {
    getRelevantPolicies() {
      return this.getPolicySet.filter((benefit) => {
        if (
          ["parent", "parent-in-law"].includes(this.getTargetDepRelation) &&
          benefit.coveredDependentsWrapper.pOrILFlag
        ) {
          return true;
        } else {
          return benefit.coveredDependentsWrapper.coveredDependents.includes(this.getTargetDepRelation);
        }
      });
    },
    async saveDependents(dependents, targetDepRelation, isNewAddition) {
      this.isLoading = true;
      this.targetDepRelation = targetDepRelation;
      this.isNewAddition = isNewAddition;
      try {
        await Promise.all(dependents.map((dependent) => this.upsertDependent(this.restUser, dependent)));
        this.$store.commit("addToast", {
          variant: "success",
          message: "Successfully requested add/update for your dependent(s) information",
        });
        if (!isWellnessOnlyOrg(this.restUser?.org, this.restUser?.org?.benefitsDisplayOrder))
          this.$bvModal.show("success-modal");
      } catch (err) {
        this.$store.commit("addToast", {
          variant: "danger",
          message: "Error occurred while adding/updating dependent's information",
        });
      }
      this.isLoading = false;
      // Refetching user details to reflect the correct UI.
      await this.$apollo.queries.profileModel.refetch();
    },
    // TODO: Merge this function with that for orgAdmin and move to a common file/ place
    async upsertDependent(user, dependent) {
      if (dependent.id) {
        const changedDependentInfo = {};
        const changedBenefitInfo = {};
        changedBenefitInfo.overrides = {};
        changedBenefitInfo.benefits = dependent.benefits.map((item) => item.node.id);
        const oldDependentIdx = user.dependents.findIndex((p) => p.id === dependent.id);
        for (const key in dependent) {
          if (key === "meta") {
            for (const subKey in dependent[key]) {
              if (
                ACCEPTED_META_FIELDS.includes(subKey) &&
                dependent.meta[subKey] !== user.dependents[oldDependentIdx].meta[subKey]
              ) {
                changedDependentInfo.meta = {
                  [subKey]: dependent.meta[subKey],
                };
                changedBenefitInfo.overrides.meta = {
                  [subKey]: user.dependents[oldDependentIdx].meta[subKey],
                };
              }
            }
          } else if (ACCEPTED_FIELDS.includes(key) && dependent[key] !== user.dependents[oldDependentIdx][key]) {
            changedDependentInfo[key] = dependent[key];
            changedBenefitInfo.overrides[key] = user.dependents[oldDependentIdx][key];
          }
        }
        await this.$apollo.mutate({
          mutation: resDefs.userChanges.updateUserOrDependentInfo,
          variables: {
            orgId: user.orgId,
            userId: user.id,
            dependentId: dependent.id,
            changedDependentInfo,
            changedBenefitInfo,
            type: "update",
            status: "draft",
          },
          update: (store, { data }) => {
            // TODO: Fix in v3, when refactoring updateUserOrDependentInfo.
            this.updateUserChangesInStore(user.id, store, data);
          },
        });
      } else {
        const dependentClone = utils.deepClone(dependent);
        if (dependentClone.benefits) {
          dependent.benefits = utils.extractDependentBenefitEdge(dependent.benefits);
        }
        dependentClone.meta = {
          ...dependentClone.meta,
          source: dependentClone?.meta?.source || DependentAddSource.USER_PROFILE,
        };
        // FIXME: Remove nesting, clean up code
        if (this.isMidTermAddition) {
          dependentClone.meta = {
            ...dependentClone.meta,
            isMidTermAddition: true,
          };
        }
        await this.$apollo.mutate({
          mutation: resDefs.users.insertDependentWithEndorsements,
          variables: {
            userId: user.id,
            ...dependentClone,
          },
          update: (store, { data }) => {
            const oldUser = store.readFragment({
              id: user.id,
              fragment: resDefs.users.dependentsFragment,
              fragmentName: resDefs.users.dependentsFragmentName,
            });
            const dependent = resDefs.users.transformInsertDependentWithEndorsements(data);
            const oldDependent = oldUser.dependents.find((p) => p.id === dependent.id);
            if (oldDependent) oldDependent.meta = dependent.meta;
            else oldUser.dependents.push(dependent);
            store.writeFragment({
              id: user.id,
              fragment: resDefs.users.dependentsFragment,
              data: oldUser,
              fragmentName: resDefs.users.dependentsFragmentName,
            });
          },
        });
      }
    },
    // TODO: Merge this function with that for orgAdmin and move to a common file/ place
    updateUserChangesInStore(userId, store, data) {
      const oldUser = store.readFragment({
        id: userId,
        fragment: resDefs.userChanges.userChangesFragment,
        fragmentName: resDefs.userChanges.userChangesFragmentName,
      });
      const oldUserChanges = new Object();
      const userChanges = resDefs.userChanges.transformUpdateUserOrDependentInfo(data);
      const userChangeIds = userChanges.map((userChange) => userChange.id);
      oldUser.userChanges.forEach((userChange) => {
        if (userChangeIds.includes(userChange.id)) {
          oldUserChanges[userChange.id] = userChange;
        }
      });
      userChanges.forEach((userChange) => {
        const oldUserChange = oldUserChanges[userChange.id];
        if (oldUserChange) {
          oldUserChange.changedBenefitInfo = userChange.changedBenefitInfo;
          oldUserChange.changedUserInfo = userChange.changedUserInfo;
        } else oldUser.userChanges.push(userChange);
      });
      store.writeFragment({
        id: userId,
        fragment: resDefs.userChanges.userChangesFragment,
        data: oldUser,
        fragmentName: resDefs.userChanges.userChangesFragmentName,
      });
    },
    async mapUserAndDependentsToBenefits(user) {
      try {
        await this.$apollo.mutate({
          mutation: resDefs.users.mapUserAndDependentsToBenefits,
          variables: {
            userId: user.id,
          },
        });
        this.$store.commit("addToast", {
          variant: "success",
          message: "Successfully requested sync to the policies/ benefits",
        });
      } catch (err) {
        console.log(err);
      }
    },
  },
  apollo: {
    benefitsRelatedToUser: {
      skip() {
        return !this.restUser.id;
      },
      fetchPolicy: "no-cache",
      query: gql`
        query getBenefitsRelatedToUser($userId: ID!) {
          getBenefitsRelatedToUser(userId: $userId) {
            ...OrgBenefitEdge
          }
        }
        ${orgBenefitFragment}
      `,
      variables() {
        return { userId: this.restUser.id };
      },
      update(data) {
        let benefits =
          data.getBenefitsRelatedToUser.map((orgBenefitEdge) => resDefs.benefits.transform(orgBenefitEdge.node)) || [];

        if (!isWellnessOnlyOrg(this.restUser?.org, this.restUser?.org?.benefitsDisplayOrder)) {
          benefits = benefits.filter((benefit) => benefit.isPolicy === true) || [];
        }
        return benefits;
      },
    },
    userChanges: {
      query: userDefs.users.userChanges,
      update(data) {
        return data.me.userChanges;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  backdrop-filter: blur(4px);
  overflow-y: auto;
  z-index: 1000;
}
</style>
