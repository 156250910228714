import moment from "moment";
import _ from "lodash";
import {
  CheckupAppointmentStatus,
  CheckupRoutes,
  CheckupPackageSampleCollectionType,
  SimplifiedCheckupAppointmentStatus,
} from "@/common/enums";

export const memberRelationOptions = [
  { label: "Spouse", value: "spouse" },
  { label: "Child", value: "child" },
  { label: "Parent", value: "parent" },
  { label: "Parent in Law", value: "parent-in-law" },
  { label: "Sibling", value: "sibling" },
  { label: "Other", value: "other" },
  { label: "Friend", value: "friend" },
];

export function fillUserAppointmentData(node) {
  const {
    id,
    partnerBookingId,
    partnerProviderId,
    meta: { userAddress },
    user: { name: userName, gender: userGender, dob: userDob },
    partnerProviderMeta: { fasting_required, payment_type },
  } = node;

  const userBooking = {
    id,
    partnerBookingId,
    partnerProviderId,
    userAddress,
    fastingRequired: fasting_required,
    provider: {
      name: node.meta.providerName,
      address: node.meta.providerAddress,
    },
    user: {
      name: userName,
      gender: userGender,
      dob: userDob,
    },
    appointments: [],
  };
  for (const appointment of node.checkupAppointments) {
    const {
      id: appointmentId,
      partnerAppointmentId,
      status,
      scheduledAt,
      verifiedReportLink,
      checkupPackage: {
        id: checkupPackageId,
        sampleCollectionType,
        meta: { cost },
        packageName,
      },
      checkupLabResults,
    } = appointment?.node || {};
    const testCount = appointment?.node?.checkupPackage?.checkupTestComponents?.length;
    const userAppointment = {
      id: appointmentId,
      partnerAppointmentId,
      appointmentStatus: getSimplifiedAppointmentStatus(status),
      partnerAppointmentStatus: status,
      scheduledAt,
      verifiedReportLink,
      sampleCollectionType,
      packageCost: cost,
      packageName,
      checkupPackageId,
      checkupPackage: { ...(appointment?.node?.checkupPackage || {}), testCount },
      checkupLabResults,
    };
    userBooking.appointments.push(userAppointment);
  }
  return userBooking;
}
export function getSimplifiedAppointmentStatus(apiStatus) {
  const statusMap = {
    Upcoming: [
      CheckupAppointmentStatus.NEW,
      CheckupAppointmentStatus.REQUEST_RESCHEDULE,
      CheckupAppointmentStatus.RESCHEDULED,
      CheckupAppointmentStatus.PARTNER_RESCHEDULED,
    ],
    Confirmed: [CheckupAppointmentStatus.CONFIRMED],
    Cancelled: [
      CheckupAppointmentStatus.REJECTED,
      CheckupAppointmentStatus.NO_SHOW,
      CheckupAppointmentStatus.CANCELLED,
      CheckupAppointmentStatus.PARTNER_CANCELLED,
    ],
    Completed: [
      CheckupAppointmentStatus.PARTIALLY_RECEIVED,
      CheckupAppointmentStatus.RECEIVED,
      CheckupAppointmentStatus.COMPLETED,
      CheckupAppointmentStatus.VERIFICATION_REQUIRED,
    ],
  };
  for (const [simplifiedStatus, apiStatuses] of Object.entries(statusMap)) {
    if (apiStatuses.includes(apiStatus)) {
      return simplifiedStatus;
    }
  }
  return apiStatus;
}

export function getProspectEventData(currentStep, ahcProspectState) {
  const eventProspectData = {
    eventName: `ahc-${currentStep}`,
    eventParams: {},
  };
  switch (currentStep) {
    case CheckupRoutes.DEPENDENT_SELECTION: {
      const { selectedMembers } = ahcProspectState;
      eventProspectData.eventParams = { selectedMembers: selectedMembers.map(({ id, name }) => ({ id, name })) };
      break;
    }
    case CheckupRoutes.SAMPLE_COLLECTION: {
      const { selectedPackage } = ahcProspectState;
      eventProspectData.eventParams = { ...selectedPackage };
      break;
    }
    case CheckupRoutes.PROVIDER_SELECTION: {
      const { selectedProvider, scheduledAt, selectedDateTimeSlot } = ahcProspectState;
      eventProspectData.eventParams = { selectedProvider, scheduledAt, selectedDateTimeSlot };
      break;
    }
    case CheckupRoutes.ADDRESS_SELECTION: {
      const { selectedAddress } = ahcProspectState;
      eventProspectData.eventParams = { selectedAddress };
      break;
    }
    case CheckupRoutes.CHECKOUT: {
      const { contactDetails } = ahcProspectState;
      eventProspectData.eventParams = { ...contactDetails };
      break;
    }
  }
  return eventProspectData;
}

export function getSampleCollectionTitleAndIcon(sampleCollectionType) {
  const sampleCollectionDetailsMap = {
    [CheckupPackageSampleCollectionType.HOME_COLLECTION]: {
      iconName: "home-1",
      sampleCollectionTitle: "At - Home",
      variant: "purple-600",
      class: "bg-purple-100 text-purple-600",
      icon: "home-collection",
    },
    [CheckupPackageSampleCollectionType.ONSITE]: {
      iconName: "office",
      sampleCollectionTitle: "At - Camp",
      variant: "teal-600",
      class: "bg-teal-100 text-teal-600",
      icon: "clinic-collection",
    },
    [CheckupPackageSampleCollectionType.INCLINIC]: {
      iconName: "clinic",
      sampleCollectionTitle: "At - Center",
      variant: "blue-600",
      class: "bg-blue-100 text-blue-600",
      icon: "clinic-collection",
    },
  };

  return (
    sampleCollectionDetailsMap[sampleCollectionType] ||
    sampleCollectionDetailsMap[CheckupPackageSampleCollectionType.INCLINIC]
  );
}

export function isAppointmentStatusActive(status) {
  // TODO: Some difference in Simplefied appointment status and actual status need a code clean up
  const activeAppointmentStatusList = ["Upcoming", "confirmed", "rescheduled"];
  return activeAppointmentStatusList.includes(status);
}
export function getAllAppointments(node) {
  const appointments = [];
  for (const appointment of node.checkupAppointments) {
    const {
      id: appointmentId,
      partnerAppointmentId,
      status,
      scheduledAt,
      verifiedReportLink,
      checkupPackage: {
        id: checkupPackageId,
        sampleCollectionType,
        meta: { cost, packageGroupName },
      },
      checkupLabResults,
      user,
      meta,
    } = appointment?.node || {};
    const testCount = appointment?.node?.checkupPackage?.checkupTestComponents?.length;

    let member = { ...appointment?.node?.user, relation: "self" };
    if (appointment?.node?.isExtraMember) {
      member = appointment?.node?.meta.extraMember;
    } else if (appointment?.node?.dependent) {
      member = appointment?.node?.dependent;
    }
    const userAppointment = {
      id: appointmentId,
      partnerAppointmentId,
      appointmentStatus: status,
      partnerAppointmentStatus: status,
      scheduledAt,
      verifiedReportLink,
      sampleCollectionType,
      packageCost: cost,
      packageName: appointment.node.checkupPackage.novaProduct.name,
      packageGroupName,
      checkupPackageId,
      checkupPackage: { ...(appointment?.node?.checkupPackage || {}), testCount },
      checkupLabResults,
      user,
      member,
      meta,
    };
    appointments.push(userAppointment);
  }
  return appointments;
}

export function getBookingDetails(node) {
  const {
    id,
    partnerBookingId,
    partnerProviderId,
    meta: { userAddress },
    user: { name: userName, gender: userGender, dob: userDob },
    partnerProviderMeta: { fasting_required, payment_type },
    novaOrder,
    status,
    novaOrderItems,
  } = node;

  const appointments = getAllAppointments(node);
  let checkupPackage;
  const memberNames = [];
  const testCount = 0;
  for (const appointment of appointments) {
    if (appointment.member && !memberNames.includes(appointment.member.name)) {
      memberNames.push(appointment.member.name);
    }
    checkupPackage = appointment.checkupPackage;
  }

  const bookingStatus = getLatestStatusForBooking(appointments);
  const latestScheduledAt = getLatestScheduledAt(appointments);
  const booking = {
    id,
    partnerBookingId,
    partnerProviderId,
    userAddress,
    fastingRequired: fasting_required,
    provider: {
      name: node.meta.providerName,
      address: node.meta.providerAddress,
    },
    user: {
      name: userName,
      gender: userGender,
      dob: userDob,
    },
    memberNames,
    checkupPackage,
    bookingStatus,
    appointments,
    latestScheduledAt,
    status,
    novaOrder,
    novaOrderItems,
  };
  return booking;
}
export async function getProductDetails(node, checkupPackage) {
  const orderItems = node?.novaOrderItems;
  let novaProduct;

  // older booking, without order details, it will have latest product details
  if (!orderItems || orderItems?.length === 0) {
    novaProduct = checkupPackage.novaProduct;
  } else {
    novaProduct = orderItems[0]?.node;
  }

  return {
    name: novaProduct.name,
    netCost: novaProduct.netCost,
    taxRate: novaProduct.taxRate,
  };
}
export function getLatestStatusForBooking(appointments) {
  let hasUpcoming = false;
  let allCancelled = true;
  let bookingStatus = "Completed";
  for (const appointment of appointments) {
    const appointmentStatus = getSimplifiedAppointmentStatus(appointment.appointmentStatus);

    if (appointmentStatus === "Upcoming" || appointmentStatus === "Confirmed") {
      hasUpcoming = true;
    } else if (appointmentStatus !== "Cancelled") {
      allCancelled = false;
    }
  }
  if (hasUpcoming) {
    bookingStatus = "Upcoming";
  } else if (allCancelled) {
    bookingStatus = "Cancelled";
  }

  return bookingStatus;
}
export function getLatestScheduledAt(appointments) {
  appointments.sort((a, b) => moment(b.scheduledAt) - moment(a.scheduledAt));
  return appointments[appointments.length - 1].scheduledAt;
}
export function getBadgeIconAndColor(status) {
  const badgeIconMap = {
    [SimplifiedCheckupAppointmentStatus.CONFIRMATION_PENDING]: {
      icon: "clock-filled",
      bgColor: "mustard-100",
      textColor: "mustard-700",
      hover: {
        bgColor: "goldenrod",
        textColor: "gray-900",
      },
    },
    [SimplifiedCheckupAppointmentStatus.CONFIRMED]: {
      icon: "check-circle",
      bgColor: "teal-100",
      textColor: "teal-700",
      hover: {
        bgColor: "teal-700",
        textColor: "white",
      },
    },
    [SimplifiedCheckupAppointmentStatus.TEST_DONE]: {
      icon: "check-circle",
      bgColor: "malibu-100",
      textColor: "blue-600",
      hover: {
        bgColor: "blue-600",
        textColor: "white",
      },
    },
    [SimplifiedCheckupAppointmentStatus.SAMPLE_COLLECTED]: {
      icon: "check-circle",
      bgColor: "malibu-100",
      textColor: "blue-600",
      hover: {
        bgColor: "blue-600",
        textColor: "white",
      },
    },
    [SimplifiedCheckupAppointmentStatus.AWAITING_REPORTS]: {
      icon: "clock-filled",
      bgColor: "malibu-100",
      textColor: "blue-600",
      hover: {
        bgColor: "blue-600",
        textColor: "white",
      },
    },
    [SimplifiedCheckupAppointmentStatus.PARTIAL_REPORTS_AVAILABLE]: {
      icon: "check-circle",
      bgColor: "mustard-100",
      textColor: "mustard-700",
      hover: {
        bgColor: "goldenrod",
        textColor: "gray-900",
      },
    },
    [SimplifiedCheckupAppointmentStatus.REPORTS_AVAILABLE]: {
      icon: "check-circle",
      bgColor: "teal-100",
      textColor: "teal-700",
      hover: {
        bgColor: "teal-700",
        textColor: "white",
      },
    },
    [SimplifiedCheckupAppointmentStatus.CANCELLED]: {
      icon: "minus-circle",
      bgColor: "red-100",
      textColor: "red-600",
      hover: {
        bgColor: "red-500",
        textColor: "white",
      },
    },
    [SimplifiedCheckupAppointmentStatus.RESCHEDULED]: {
      icon: "check-circle",
      bgColor: "malibu-100",
      textColor: "blue-600",
      hover: {
        bgColor: "blue-600",
        textColor: "white",
      },
    },
    [SimplifiedCheckupAppointmentStatus.RESCHEDULED_BY_PARTNER]: {
      icon: "check-circle",
      bgColor: "malibu-100",
      textColor: "blue-600",
      hover: {
        bgColor: "blue-600",
        textColor: "white",
      },
    },
    [SimplifiedCheckupAppointmentStatus.CANCELLED_BY_PARTNER]: {
      icon: "minus-circle",
      bgColor: "red-100",
      textColor: "red-600",
      hover: {
        bgColor: "red-500",
        textColor: "white",
      },
    },
    [SimplifiedCheckupAppointmentStatus.REJECTED]: {
      icon: "minus-circle",
      bgColor: "red-100",
      textColor: "red-600",
      hover: {
        bgColor: "red-500",
        textColor: "white",
      },
    },
    [SimplifiedCheckupAppointmentStatus.NO_SHOW_RECORDED]: {
      icon: "error",
      bgColor: "red-100",
      textColor: "red-600",
      hover: {
        bgColor: "red-500",
        textColor: "white",
      },
    },
    [SimplifiedCheckupAppointmentStatus.PENDING]: {
      icon: "clock-filled",
      bgColor: "malibu-100",
      textColor: "blue-600",
      hover: {
        bgColor: "blue-600",
        textColor: "white",
      },
    },
    [SimplifiedCheckupAppointmentStatus.AWAITING_PAYMENT]: {
      icon: "clock-filled",
      bgColor: "mustard-100",
      textColor: "mustard-700",
      hover: {
        bgColor: "goldenrod",
        textColor: "gray-900",
      },
    },
  };

  return badgeIconMap[status];
}
export function getBadgeColorVariant(status) {
  switch (status) {
    case SimplifiedCheckupAppointmentStatus.COMPLETED:
      return "light-blue";
    case SimplifiedCheckupAppointmentStatus.CANCELLED:
      return "light-danger";
    case SimplifiedCheckupAppointmentStatus.UPCOMING:
      return "light-secondary";
    case SimplifiedCheckupAppointmentStatus.CONFIRMED:
      return "success";
    default:
      return "";
  }
}
export function getCalIconColorClass(status) {
  switch (status) {
    case SimplifiedCheckupAppointmentStatus.COMPLETED:
      return { iconColor: "blue-500", bgColor: "bg-blue-100" };
    case SimplifiedCheckupAppointmentStatus.CANCELLED:
      return { iconColor: "red-500", bgColor: "bg-red-100" };
    case SimplifiedCheckupAppointmentStatus.UPCOMING:
      return { iconColor: "teal-800", bgColor: "bg-teal-200" };
    case SimplifiedCheckupAppointmentStatus.CONFIRMED:
      return { iconColor: "teal-800", bgColor: "bg-teal-200" };
    default:
      return "";
  }
}
export function freeForDescription(packageFreeFor) {
  if (!packageFreeFor) return "";

  const packageFreeForRelations = packageFreeFor?.map((member) => member.relation);
  let desc = "";
  if (!packageFreeForRelations.length) {
    return desc;
  }
  if (packageFreeForRelations.includes("self")) {
    desc = desc + "you, ";
  }
  if (packageFreeForRelations.includes("spouse")) {
    desc = desc + "spouse, ";
  }
  if (packageFreeForRelations.includes("child")) {
    desc = desc + "children" + ", ";
  }
  if (packageFreeForRelations.includes("parent") && packageFreeForRelations.includes("parent-in-law")) {
    desc = desc + "parents and parents in law, ";
  } else {
    if (packageFreeForRelations.includes("parent")) {
      desc = desc + "parents, ";
    }
    if (packageFreeForRelations.includes("parent-in-law")) {
      desc = desc + "parents in law, ";
    }
  }

  return desc.slice(0, -2);
}

export function isCancelPossibleByTimestamp(timestamp) {
  const currentTime = moment();
  const appointmentTime = moment(timestamp);

  // cancel button is disabled if less than 2 hours left before the appointment
  return appointmentTime && appointmentTime.diff(currentTime, "hours") > 3;
}

function formatGroupCheckPackage(groupedCheckupPackage) {
  const fieldsToExclude = ["maxAge", "minAge", "male", "female", "None"];
  const { checkupPackages } = groupedCheckupPackage;
  const mergedPackageAvailableFor = mergePackageAvailableFor(checkupPackages);
  groupedCheckupPackage.packageAvailableFor = mergedPackageAvailableFor;
  groupedCheckupPackage.updatedPackageFreeFor = mergeUpdatedPackageFreeFor(checkupPackages);
  groupedCheckupPackage.isLinearPackageGroup = checkupPackages?.some(
    (checkupPackage) => checkupPackage?.meta?.isLinearPackage
  );
  groupedCheckupPackage.linearPackageCompletedUsers =
    checkupPackages?.flatMap((checkupPackage) => checkupPackage?.linearPackage?.linearPackageCompletedUsers || []) ||
    [];
  groupedCheckupPackage.isSelfOnlyPackage =
    mergedPackageAvailableFor.self &&
    Object.keys(mergedPackageAvailableFor).every((key) =>
      key === "self" ? mergedPackageAvailableFor.self : fieldsToExclude.includes(key) || !mergedPackageAvailableFor[key]
    );
  return groupedCheckupPackage;
}

export function groupCheckupPackages(checkupPackages) {
  if (!checkupPackages?.length) return [];
  const groupedCheckupPackagesMap = checkupPackages.reduce((accumulator, { node: checkupPackage = {} }) => {
    const packageGroupName = checkupPackage?.packageGroupName || checkupPackage?.novaProduct?.name;
    if (!accumulator[packageGroupName]) {
      accumulator[packageGroupName] = { packageGroupName, checkupPackages: [], testCount: 0 };
    }
    const testCount = checkupPackage?.checkupTestComponents?.length || 0;
    const checkupTestComponents = checkupPackage?.checkupTestComponents?.map((e) => e?.node || {}) || [];
    const checkupPackages = accumulator[packageGroupName].checkupPackages;
    accumulator[packageGroupName].testCount = testCount;
    checkupPackages.push({ ...(checkupPackage || {}), testCount, checkupTestComponents });
    return accumulator;
  }, {});

  const groupedCheckupPackages = Object.values(groupedCheckupPackagesMap || {}).map(formatGroupCheckPackage);
  return groupedCheckupPackages
    .filter(({ isLinearPackageGroup }) => !isLinearPackageGroup)
    .sort((a, b) => {
      if (a.updatedPackageFreeFor.length === 0 && b.updatedPackageFreeFor.length !== 0) return 1;
      else if (b.updatedPackageFreeFor.length === 0 && a.updatedPackageFreeFor.length !== 0) return -1;
      return (a?.packageGroupName || "")?.localeCompare(b?.packageGroupName || "");
    })
    .flatMap((groupedCheckupPackage) => {
      const result = [groupedCheckupPackage];
      const matchedLinearPackage = groupedCheckupPackages.find(
        ({ checkupPackages }) =>
          groupedCheckupPackage?.packageGroupName === checkupPackages?.[0]?.meta?.linearPackage?.name ||
          groupedCheckupPackage?.packageGroupName === checkupPackages?.[0]?.meta?.linearPackage?.groupName
      );
      if (matchedLinearPackage) result.push(matchedLinearPackage);
      return result;
    });
}

export function mergeUpdatedPackageFreeFor(checkupPackages) {
  if (!checkupPackages?.length) return [];

  return checkupPackages.flatMap((checkupPackage) => checkupPackage.updatedPackageFreeFor || []);
}

export function mergePackageAvailableFor(checkupPackages) {
  const fieldsToExclude = ["None"];
  if (!checkupPackages?.length) return {};

  return Object.assign(
    {},
    ...(checkupPackages.map((checkupPackage) =>
      _.pickBy(checkupPackage.packageAvailableFor || {}, (val, key) => Boolean(val) && !fieldsToExclude.includes(key))
    ) || [])
  );
}

export function mergeCheckupTestComponents(checkupPackages) {
  if (!checkupPackages?.length) return [];

  return _.uniqBy(
    checkupPackages.flatMap((checkupPackage) => checkupPackage.checkupTestComponents || []),
    "id"
  );
}

export function availableForDescription(relations) {
  if (!relations?.length) return "";

  const uniqueRelations = [...new Set(relations)];
  const priorityOrder = {
    self: 1,
    spouse: 2,
    child: 3,
    parent: 4,
    parentInLaw: 5,
  };
  const relationMap = {
    self: "You",
    spouse: "Spouse",
    child: "Child",
    parent: "Parent",
    parentInLaw: "Parent In Law",
    "parent-in-law": "Parent In Law",
  };

  if (Object.values(priorityOrder).length === uniqueRelations?.length) {
    return "All dependents";
  }

  uniqueRelations.sort((a, b) => priorityOrder[a] - priorityOrder[b]);

  const arrayFormatter = (arr) => {
    if (!arr?.length) return "";
    else if (arr.length === 1) return arr[0]?.toString();
    else return `${_.initial(arr || []).join(", ")} & ${_.last(arr)}`;
  };
  return arrayFormatter(uniqueRelations.map((relation) => relationMap[relation] || "You"));
}

function isMemberDisabledToBook(userId, dependentId, groupedCheckupPackage) {
  if (!groupedCheckupPackage?.isLinearPackageGroup) return false;

  return !groupedCheckupPackage?.linearPackageCompletedUsers?.some(
    (member) => member.userId === userId && member.dependentId === dependentId
  );
}

const getAge = (dob) => (dob ? moment().diff(dob, "years", false) : 0);

export const isEligibleForPackage = (member, packageDetails) => {
  const age = getAge(member.dob);
  const relation = member.relation === "parent-in-law" ? "parentInLaw" : (member.relation || "self").toLowerCase();
  return (
    (packageDetails.packageAvailableFor[relation] || ["friend", "other", "sibling"].includes(relation)) &&
    packageDetails.packageAvailableFor[member.gender] &&
    (packageDetails.packageAvailableFor.minAge || 0) <= age &&
    (packageDetails.packageAvailableFor.maxAge || 100) >= age
  );
};

export function getUsersWithEligibleCheckupPackage(user, extraMembers, groupedCheckupPackage, selectedMembers) {
  if (!user || !groupedCheckupPackage?.checkupPackages?.length) return [];

  const usersWithEligibleCheckupPackage = [];
  const relationMap = {
    self: "You",
    spouse: "Spouse",
    child: "Child",
    parent: "Parent",
    parentInLaw: "Parent In Law",
    "parent-in-law": "Parent In Law",
    friend: "Friend",
    other: "Other",
    sibling: "Sibling",
  };

  const isMemberEligibleForFreePackage = (member, packageFreeFor) => {
    if (!packageFreeFor || packageFreeFor === "None selected" || packageFreeFor?.toLowerCase() === "none") {
      return false;
    }
    const relation = member.relation === "parent-in-law" ? "parentInLaw" : (member.relation || "self").toLowerCase();
    const relationToFamilyDefMap = {
      self: "E",
      spouse: "S",
      child: "C",
      parent: "P",
      parentInLaw: "IL",
    };
    return packageFreeFor.includes(relationToFamilyDefMap[relation]);
  };

  const validateAndFormatData = (member, checkupPackage, options = {}) => ({
    id: member.id,
    userId: user.id,
    name: member.displayName || member.name,
    relation: member?.relation || null,
    formattedRelation: relationMap[member.relation?.toLowerCase()] || "You",
    gender: _.capitalize(member.gender),
    age: getAge(member.dob),
    isPaidAppointment: !checkupPackage?.updatedPackageFreeFor?.some(({ id }) => id === member?.id),
    isMemberDisabledToBook: isMemberDisabledToBook(user.id, options?.dependentId || null, groupedCheckupPackage),
    isMemberEligibleForFreePackage: isMemberEligibleForFreePackage(
      member,
      checkupPackage.packageFreeFor[user.meta.jobGrade]
    ),
    checkupPackage,
    ...options,
  });

  groupedCheckupPackage.checkupPackages.forEach(
    (checkupPackage) =>
      isEligibleForPackage(user, checkupPackage) &&
      (!selectedMembers?.length || selectedMembers.some(({ id }) => id === user.id)) &&
      usersWithEligibleCheckupPackage.push(validateAndFormatData(user, checkupPackage))
  );

  user.dependents?.forEach((dependent) => {
    groupedCheckupPackage.checkupPackages.forEach((checkupPackage) => {
      if (
        isEligibleForPackage(dependent, checkupPackage) &&
        (!selectedMembers?.length || selectedMembers.some(({ id }) => id === dependent.id))
      ) {
        usersWithEligibleCheckupPackage.push(
          validateAndFormatData(dependent, checkupPackage, { dependentId: dependent.id })
        );
      }
    });
  });

  extraMembers?.forEach((extraMember) => {
    groupedCheckupPackage.checkupPackages.forEach((checkupPackage) => {
      // generate id for each member
      if (
        isEligibleForPackage(extraMember, checkupPackage) &&
        (!selectedMembers?.length || selectedMembers.some(({ id }) => id === extraMember.id))
      ) {
        usersWithEligibleCheckupPackage.push(
          validateAndFormatData(extraMember, checkupPackage, { extraMemberId: extraMember.id, dob: extraMember.dob })
        );
      }
    });
  });
  return usersWithEligibleCheckupPackage;
}
