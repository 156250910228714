import moment from "moment";
import { AcceptedGenders, AcceptedRelations, OrgPreferences } from "../common/enums";
import { DEFAULT_DOJ_FOR_ORG, FUTURE_MAX_LIMIT_DOJ } from "../portal/pages/admin/constants";
import { postalApi } from "./misc";

const IND_MOBILE_NUMBER_REGEXP = /^[6-9]\d{9}$/;
const PASSWORD_REGEXP = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[@$!%*#&^]).{8,32}$/g;
const EMPLOYEE_ID_REGEX = /^[a-zA-Z0-9_@\-\/ ]+$/;
const SPECIAL_CHARS_ALLOWED_IN_NAME_REGEX = /['.]/;
const SPECIAL_CHARS_NOT_ALLOWED_IN_NAME_REGEX = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?~]/;
const DEFAULT_AGE_VALIDATIONS = {
  parentAgeMin: 18,
  parentAgeMax: 81,
  minSelfParentAgeDifference: 15,
  maxSelfParentAgeDifference: 40,
  spouseAgeMin: 18,
  spouseAgeMax: 81,
  maxSelfSpouseAgeDifference: 40,
  childAgeMin: 0,
  childAgeMax: 24,
  minSelfChildAgeDifference: 15,
  maxSelfChildAgeDifference: 40,
};
export default {
  validateIndianMobileNumbers(number) {
    return number.toString().match(IND_MOBILE_NUMBER_REGEXP);
  },
  validateIndianMobileNumbersForVfg(num) {
    if (!num || num.toString().match(IND_MOBILE_NUMBER_REGEXP)) return [];
    return ["Invalid phone number!"];
  },
  // This function is a validator for VFG. That fixes the validation issue with vfg nested fields
  // To use this make sure validateAsync is true in form options.
  async validateChildFields(value) {
    const errors = [];
    const nestedVFG = this.$children[0];
    await nestedVFG.validate();
    if (nestedVFG.errors.length > 0) {
      // This error message wont be visible anywhere
      errors.push("Subfields are invalid!");
    }
    return errors;
  },
  validateInlineSelect(value) {
    if (value === "None selected") return ["None Selected"];
    return [];
  },
  validateDynamicInlineSelect(value) {
    if (value === "None selected" || value === "Search your Address") return ["None Selected"];
    return [];
  },
  validateDateOfAdmissionDischargeForVfg(dod, _self, formValues) {
    if (!dod) return [];
    if (!formValues.doa) return ["Add Date of Admission first"];
    if (moment(dod).isBefore(moment(formValues.doa))) return ["Date of Discharge can't be before Date of Admission"];
    return [];
  },
};

export function validateDOE(doe, orgPreferences) {
  if (!doe) return ["No Date of exit provided"];
  const ignoreAdditionRule = orgPreferences && orgPreferences[OrgPreferences.IGNORE_ADDITION_RULE];
  if (!ignoreAdditionRule && Math.ceil(Math.abs(moment(doe).diff(moment(), "days"))) > 45)
    return ["Date of exit should be within 45 days from current date."];
  return [];
}
export function validateIndianMobileNumbersForVfg(num) {
  if (!num || num.toString().match(IND_MOBILE_NUMBER_REGEXP)) return [];
  return ["Invalid phone number!"];
}

export function validateOtpVerification(value, field, model) {
  if (model.isNumberVerified) return [];
  if (!value) return ["Invalid phone number! Please update and click on “Save Changes”."];
  else return ["Please check the number and click on ‘Save Changes’"];
}

export function validateInlineSelect(value) {
  if (value === "None selected") return ["None Selected"];
  return [];
}

export function validateEditableDropdown(value) {
  if (value === "None selected" || value === "" || value?.name === "None selected") return ["None Selected"];
  return [];
}

// This function is a validator for VFG. That fixes the validation issue with vfg nested fields
// To use this make sure validateAsync is true in form options.
export async function validateChildFields(value) {
  const errors = [];
  const nestedVFG = this.$children[0];
  await nestedVFG?.validate();
  if (nestedVFG?.errors?.length > 0) {
    // This error message wont be visible anywhere
    errors.push("Subfields are invalid!");
  }
  return errors;
}

export function validateEmployeeAge(dob) {
  if (!dob) return ["This field is required"];
  if (moment(dob).isAfter(moment().subtract(18, "years")) || moment(dob).isBefore(moment().subtract(60, "years")))
    return [{ isWarning: true, message: "Are you sure you’ve selected your date of birth correctly?" }];
  return [];
}

function validateNameString(name, field) {
  if (name.trim().length < 2) return [`The length of ${field} is too small! Minimum: 2`];
  if (name.trim().length > 50) return [`The length of ${field} is too long! Maximum: 50`];
  if (SPECIAL_CHARS_ALLOWED_IN_NAME_REGEX.test(name)) {
    return [
      {
        isWarning: true,
        message: `You've added a special character (. or ') in your ${field}, are you sure its correct?`,
      },
    ];
  } else if (SPECIAL_CHARS_NOT_ALLOWED_IN_NAME_REGEX.test(name)) {
    return [`Special Characters are not allowed in the name.`];
  }
  return [];
}

export function validateName(name) {
  if (!name || !name.trim()) return ["Name field can't be empty!"];
  return validateNameString(name, "name");
}

export function validateDisplayName(name) {
  if (name && name.trim()) {
    return validateNameString(name, "display name");
  }
  return [];
}

export function validateDateOfJoining(doj, orgData) {
  let { orgPreferences, maxDojLimitFromOrg } = orgData;
  if (!doj) {
    return ["Date of joining can't be empty!"];
  }
  if (!maxDojLimitFromOrg) {
    maxDojLimitFromOrg = DEFAULT_DOJ_FOR_ORG;
  }
  const ignoreAdditionRule = orgPreferences && orgPreferences[OrgPreferences.IGNORE_ADDITION_RULE];
  const today = moment();
  const pastDateLimit = today.clone().subtract(maxDojLimitFromOrg, "days");
  const futureDateLimit = today.clone().add(FUTURE_MAX_LIMIT_DOJ, "days");

  if (!ignoreAdditionRule && (moment(doj).isBefore(pastDateLimit) || moment(doj).isAfter(futureDateLimit))) {
    return [
      `Date of Joining should be within the last ${maxDojLimitFromOrg} days or the next ${FUTURE_MAX_LIMIT_DOJ} days.`,
    ];
  }

  return [];
}

// this function can be used to validate the form field.
export function validateUser(
  formData,
  isOrgAdmin = false,
  isOrgEntityAdmin = false,
  orgPreferences,
  orgMeta,
  customDependentValidations
) {
  const errorMessage = [];
  const nameFieldValidation = validateName(formData.name);
  if (nameFieldValidation.length) {
    const msg =
      typeof nameFieldValidation[0] === "string"
        ? nameFieldValidation[0]
        : "We noticed you used special character in name";
    errorMessage.push(msg);
  }
  const displayNameFieldValidation = validateDisplayName(formData.displayName);
  if (displayNameFieldValidation.length) {
    const msg =
      typeof displayNameFieldValidation[0] === "string"
        ? displayNameFieldValidation[0]
        : "We noticed you used special character in Display name";
    errorMessage.push(msg);
  }
  if (formData.dob && !validateDate(formData.dob)) {
    errorMessage.push("Date of birth cannot be a future date");
  }
  if (formData.dependents && formData.dependents.length) {
    // relation validation
    if (!formData.dependents.every((dependentDetail) => dependentDetail.relation)) {
      errorMessage.push("Dependent relation cannot be empty");
    }

    // gender validation
    if (!formData.dependents.every((dependentDetail) => dependentDetail.gender)) {
      errorMessage.push("Dependent gender cannot be empty");
    }

    // name validation
    const dependentNameFieldValidation = formData.dependents
      .map((dependentDetail) => validateName(dependentDetail.name))
      .flat();
    errorMessage.push(...dependentNameFieldValidation);

    // age validation
    const dependentAgeFieldValidation = formData.dependents
      .map((dependentDetail) =>
        validateDependentAge(
          dependentDetail.dob,
          {},
          { userDob: formData.dob, relation: dependentDetail.relation },
          customDependentValidations
        )
      )
      .flat();
    errorMessage.push(...dependentAgeFieldValidation);
  }

  if (validateEmployeeId(formData.meta.employeeId).length) {
    errorMessage.push("We noticed you used special characters in your employee id.");
  }

  const orgData = { orgPreferences, maxDojLimitFromOrg: orgMeta?.doj };
  const dojValidationError = validateDateOfJoining(formData.meta.dateOfJoining, orgData);
  if ((isOrgAdmin || isOrgEntityAdmin) && dojValidationError.length) {
    errorMessage.push(...dojValidationError);
  }
  // removing warnings
  return errorMessage.filter((validation) => typeof validation !== "object");
}

export function validateDate(dob) {
  try {
    if (new Date(dob) > new Date()) {
      return false;
    }
  } catch (error) {
    return false;
  }
  return true;
}

export function validateEmployeeId(employee_id) {
  if (!employee_id) return [];
  if (!EMPLOYEE_ID_REGEX.test(employee_id))
    return [
      {
        isWarning: true,
        message: "We noticed you used special characters in your employee id.",
      },
    ];
  return [];
}

export function validateDateOfMarriage(dom, _self, formValues) {
  if (typeof dom === "undefined") return [];
  if (!dom) {
    return ["Date of marriage can't be empty!"];
  }
  const ignoreAdditionRule =
    (formValues.isOrgAdmin || formValues.isOrgEntityAdmin) &&
    formValues.orgPreferences &&
    formValues.orgPreferences[OrgPreferences.IGNORE_ADDITION_RULE];
  if (formValues.isMidTerm && !ignoreAdditionRule && moment(dom).isBefore(moment().subtract(30, "days"))) {
    if (formValues.isOrgAdmin || formValues.isOrgEntityAdmin) {
      return ["Date of marriage cant be before 30 days."];
    }
    return ["Date of marriage cant be before 30 days, Please contact your HR for more info."];
  }
}

export function validateDependentAge(dob, _self, formValues, customValidations = {}) {
  const {
    childAgeMax,
    minSelfChildAgeDifference,
    maxSelfChildAgeDifference,
    spouseAgeMin,
    spouseAgeMax,
    maxSelfSpouseAgeDifference,
    parentAgeMax,
    parentAgeMin,
    minSelfParentAgeDifference,
    maxSelfParentAgeDifference,
  } = getAgeValidationParams(customValidations);

  if (!formValues.userDob) return ["Self user date of birth is required."];
  if (!dob) return ["Date of birth is required."];

  if (moment(dob).isAfter(moment())) {
    return [`Future Date of Birth is not allowed.`];
  }

  if (["parent", "parent-in-law"].includes(formValues.relation)) {
    if (moment(dob).isAfter(moment().subtract(parentAgeMin, "years"))) {
      return [`${formValues.relation} must be at least ${parentAgeMin} years of age.`];
    }
    if (moment(dob).isBefore(moment().subtract(parentAgeMax, "years"))) {
      return [`${formValues.relation} age should not exceed ${parentAgeMax}`];
    }
    if (moment(formValues.userDob).diff(moment(dob), "years") < minSelfParentAgeDifference) {
      return [
        {
          isWarning: true,
          message: `Are you sure the age difference between you and your ${formValues.relation} is less than ${minSelfParentAgeDifference} years.`,
        },
      ];
    }
    if (moment(formValues.userDob).diff(moment(dob), "years") > maxSelfParentAgeDifference) {
      return [
        {
          isWarning: true,
          message: `Are you sure the age difference between you and your ${formValues.relation} is over ${maxSelfParentAgeDifference} years.`,
        },
      ];
    }
  }
  if (formValues.relation === "spouse") {
    if (moment(dob).isAfter(moment().subtract(spouseAgeMin, "years"))) {
      return [`Spouse must be at least ${spouseAgeMin} years of age.`];
    }
    if (moment(dob).isBefore(moment().subtract(spouseAgeMax, "years"))) {
      return [`You've selected your spouse's age more than ${spouseAgeMax} years, please check before proceeding`];
    }
    if (Math.abs(moment(formValues.userDob).diff(moment(dob), "years")) > maxSelfSpouseAgeDifference) {
      return [
        {
          isWarning: true,
          message: `Age gap between you and your spouse is greater than ${maxSelfSpouseAgeDifference} years, please check before proceeding`,
        },
      ];
    }
  }
  if (formValues.relation === "child") {
    const ignoreAdditionRule =
      (formValues.isOrgAdmin || formValues.isOrgEntityAdmin) &&
      formValues.orgPreferences &&
      formValues.orgPreferences[OrgPreferences.IGNORE_ADDITION_RULE];
    // This first condition will work only when it's a midterm addition and it's a new dependent addition and ignoreAdditionFlag is turned off(only for org admins)
    if (
      formValues.isMidTerm &&
      !formValues.id &&
      !ignoreAdditionRule &&
      moment(dob).isBefore(moment().subtract(30, "days"))
    ) {
      return ["Children over 30 days of age are not allowed under mid term additions."];
    } else if (moment(dob).isBefore(moment().subtract(childAgeMax, "years"))) {
      return [{ isWarning: true, message: "Are you sure you’ve selected the child’s date of birth correctly?" }];
    }

    if (moment(dob).diff(moment(formValues.userDob), "years") < minSelfChildAgeDifference) {
      return [{ isWarning: true, message: "Are you sure you’ve selected the child’s date of birth correctly?" }];
    }
    if (moment(dob).diff(moment(formValues.userDob), "years") > maxSelfChildAgeDifference) {
      return [{ isWarning: true, message: "Are you sure you’ve selected the child’s date of birth correctly?" }];
    }
  }
  return [];
}
export function validateDependentGender(gender, _self, formValues) {
  if (!gender || !formValues.userGender) return [];
  if (formValues.relation === "spouse" && gender === formValues.userGender) {
    return [
      {
        isWarning: true,
        message: "We found same gender for you and your spouse, please check before proceeding",
      },
    ];
  }
  if (formValues.relation === "parent" && formValues.addedParentData) {
    const addedParentData = formValues.addedParentData?.find((parentData) => parentData.tempId !== formValues.tempId);
    if (addedParentData && addedParentData.gender === formValues.gender) {
      return ["We found same gender for both of your parents, please check before proceeding"];
    }
  }
  if (formValues.relation === "parent-in-law" && formValues.addedPilData) {
    const addedPilData = formValues.addedPilData?.find((pilData) => pilData.tempId !== formValues.tempId);
    if (addedPilData && addedPilData.gender === formValues.gender) {
      return ["We found same gender for both of your parent-in-laws, please check before proceeding"];
    }
  }
}

export function validateDependentCount(relation, _self, formValues) {
  // Checks for the current model relation and the count of existing set of dep for the same relation for the user
  if (!relation || !formValues.dependentsByRelationCount) return [];
  let errMsg = "";
  if (
    formValues.relation === AcceptedRelations.SPOUSE &&
    formValues.dependentsByRelationCount[AcceptedRelations.SPOUSE] >= 1
  ) {
    errMsg = "1 spouse";
  } else if (
    formValues.relation === AcceptedRelations.PARENT &&
    formValues.dependentsByRelationCount[AcceptedRelations.PARENT] >= 2
  ) {
    errMsg = "2 parents";
  } else if (
    formValues.relation === AcceptedRelations.PARENT_IN_LAW &&
    formValues.dependentsByRelationCount[AcceptedRelations.PARENT_IN_LAW] >= 2
  ) {
    errMsg = "2 parents-in-law";
  } else if (
    formValues.relation === AcceptedRelations.CHILD &&
    formValues.dependentsByRelationCount[AcceptedRelations.CHILD] >= 20
  ) {
    errMsg = "20 children";
  } else if (
    formValues.relation === AcceptedRelations.SIBLING &&
    formValues.dependentsByRelationCount[AcceptedRelations.SIBLING] >= 20
  ) {
    errMsg = "20 siblings";
  }
  if (errMsg) return [`Employee cannot have more than ${errMsg}`];
  return [];
}

export function validateNovaPasswordForVfg(password) {
  if (!password || password.toString().match(PASSWORD_REGEXP)) return [];
  return ["Invalid password!"];
}

export function validateJobGrade(value, _self, formValues) {
  if (!value || value === "None selected") return [`Job Grade is required!`];
}

export function validateFileGroups(fileGroups) {
  if (fileGroups.length > 0) return [];
  return ["Please select an file group "];
}

export function validateConfirmation(relation, _self, formValues) {
  if (!formValues.confirmation) {
    return ["Please check the confirmation box"];
  }
  return [];
}

export function validateGender(gender) {
  if (!gender || gender === AcceptedGenders.NOT_ASSIGNED) {
    return ["gender is required"];
  }
  return [];
}

export function validateEmail(email) {
  const emailRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (!emailRegex.test(email)) return ["Please enter a valid email address"];
  return [];
}

export async function validatePostalCode(postalCode) {
  const address = await postalApi(postalCode);
  if (!address?.result) {
    return ["Please enter a valid postal code"];
  }
  return [];
}

export function isNumber(number) {
  return /^\d+$/.test(number);
}

export function validateAmount(value) {
  if (typeof value === "number" && isNaN(value)) return ["Amount is Required"];
}

export function getAgeValidationParams(customValidations) {
  const childAgeMax = customValidations?.childAgeMax || DEFAULT_AGE_VALIDATIONS.childAgeMax;
  const minSelfChildAgeDifference =
    customValidations?.minSelfChildAgeDifference || DEFAULT_AGE_VALIDATIONS.minSelfChildAgeDifference;
  const maxSelfChildAgeDifference =
    customValidations?.maxSelfChildAgeDifference || DEFAULT_AGE_VALIDATIONS.maxSelfChildAgeDifference;

  const spouseAgeMin = customValidations?.spouseAgeMin || DEFAULT_AGE_VALIDATIONS.spouseAgeMin;
  const spouseAgeMax = customValidations?.spouseAgeMax || DEFAULT_AGE_VALIDATIONS.spouseAgeMax;
  const maxSelfSpouseAgeDifference =
    customValidations?.maxSelfSpouseAgeDifference || DEFAULT_AGE_VALIDATIONS.maxSelfSpouseAgeDifference;

  const parentAgeMax = customValidations?.parentAgeMax || DEFAULT_AGE_VALIDATIONS.parentAgeMax;
  const parentAgeMin = customValidations?.parentAgeMin || DEFAULT_AGE_VALIDATIONS.parentAgeMin;
  const minSelfParentAgeDifference =
    customValidations?.minSelfParentAgeDifference || DEFAULT_AGE_VALIDATIONS.minSelfParentAgeDifference;
  const maxSelfParentAgeDifference =
    customValidations?.maxSelfParentAgeDifference || DEFAULT_AGE_VALIDATIONS.maxSelfParentAgeDifference;
  return {
    childAgeMax,
    minSelfChildAgeDifference,
    maxSelfChildAgeDifference,
    spouseAgeMin,
    spouseAgeMax,
    maxSelfSpouseAgeDifference,
    parentAgeMax,
    parentAgeMin,
    minSelfParentAgeDifference,
    maxSelfParentAgeDifference,
  };
}

export function validateParentAgeSuperTopup(dob) {
  if (!dob) return ["Parent date of birth is required."];
  if (moment(dob).isAfter(moment().subtract(51, "years"))) {
    return [`Parent must be at least 51 years of age.`];
  } else if (moment(dob).isBefore(moment().subtract(75, "years"))) {
    return [`Parent must be at max 75 years of age.`];
  }
  return [];
}

export function validateMemberAgeAhc(dob, minAge, maxAge) {
  if (!dob) return ["Date of birth is required."];
  if (moment(dob).isAfter(moment().subtract(minAge, "years"))) {
    return [`Age must be more than ${minAge}`];
  } else if (moment(dob).isBefore(moment().subtract(maxAge, "years"))) {
    return [`Age must be less than ${maxAge}`];
  }
  return [];
}

export function validateSpouseAgeSuperTopup(dob) {
  if (!dob) return ["Spouse date of birth is required."];
  if (moment(dob).isAfter(moment().subtract(18, "years"))) {
    return [`Spouse must be at least 18 years of age.`];
  } else if (moment(dob).isBefore(moment().subtract(65, "years"))) {
    return [`Spouse must be at max 65 years of age.`];
  }
  return [];
}
