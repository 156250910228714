<template lang="pug">
div(:class="containerClass")
  transition(name="fade", mode="out-in")
    router-view(:key="$route.path")

</template>

<script>
export default {
  computed: {
    containerClass() {
      if (this.$route?.fullPath?.includes("analytics")) return ["container-fluid", "px-0", "h-100"];
      return "container";
    },
  },
};
</script>

<style scoped>
.active > .home-nav-text {
  color: white !important;
}
</style>
