export const OPDDocument = {
  CLAIM_FORM_PART_A: "Claim form: Part A",
  AADHAAR_PRIMARY: "Aadhaar Card of Primary Insured",
  AADHAAR_PATIENT: "Aadhaar Card of the Patient",
  PAN_PRIMARY: "PAN Card of Primary Insured",
  E_CARD: "E-Card",
  CANCELLED_CHEQUE: "Cancelled cheque with name of primary insured",
  DOC_CONSULTATION_PAPERS: "Doctor Consultation Papers",
  MED_INV_PRESCRIPTION: "Prescriptions for medicines & investigations",
  REPORTS: "Investigation & Test Reports",
  PHARMACY_BILL: "Pharmacy bills for medicines",
  INVESTIGATION_BILL: "Investigation and Test Bills",
  OPD_BILL: "OPD Bills",
  PAYMENT_RECEIPT: "Receipts of Payments",
  OTHER_DOCUMENTS: "Other Documents",
  QUERY_DOCUMENTS: "Query Documents",
};
