import gql from "graphql-tag";
import { integrationFragment, jobFragment } from "../fragments";

export default {
  upsertMutation: gql`
    mutation upsertIntegration(
      $id: ID
      $orgId: ID!
      $orgEntityId: ID
      $integrationType: IntegrationWithEnum!
      $meta: JSONObject!
      $secrets: JSONObject!
    ) {
      upsertIntegration(
        input: {
          id: $id
          orgId: $orgId
          orgEntityId: $orgEntityId
          integrationType: $integrationType
          meta: $meta
          secrets: $secrets
        }
      ) {
        integration {
          ...Integration
        }
      }
    }
    ${integrationFragment}
  `,
  fetchHRMSDataMutation: gql`
    mutation fetchHrmsData($orgId: ID!, $orgEntityId: ID, $integrationType: String!) {
      fetchHrmsData(input: { orgId: $orgId, orgEntityId: $orgEntityId, integrationType: $integrationType }) {
        job {
          ...Job
        }
      }
    }
    ${jobFragment}
  `,
  authorizeGsuiteIntegration: gql`
    mutation authorizeGsuiteIntegration($id: ID!, $orgId: ID!, $code: String) {
      authorizeGsuiteIntegration(input: { id: $id, orgId: $orgId, code: $code }) {
        authUrl
        integration {
          ...Integration
        }
      }
    }
    ${integrationFragment}
  `,
  unauthorizeIntegration: gql`
    mutation unauthorizeIntegration($id: ID!, $orgId: ID!, $orgEntityId: ID) {
      unauthorizeIntegration(input: { id: $id, orgId: $orgId, orgEntityId: $orgEntityId }) {
        integration {
          ...Integration
        }
      }
    }
    ${integrationFragment}
  `,
};
