import gql from "graphql-tag";

export const fileGroupFragment = gql`
  fragment FileGroup on FileGroup {
    id
    name
    meta
    createdAt
  }
`;
