<template lang="pug">
  .py-2(v-if="types.length > 0")
    batch-uploader.mt-2(
      v-for="type of types",
      :type="type.name",
      :description="type.description",
      v-model="segmentedFiles[type.name]",
      :isErrorState="type.isError"
      :key="type.name",
      :requiredDetails="requiredDetails",
      @input="$emit('update')",
      )
</template>

<script>
import BatchUploader from "./BatchUploader.vue";
export default {
  components: {
    BatchUploader,
  },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    types: {
      type: Array,
      default: () => [],
    },
    requiredDetails: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      filesArray: [],
    };
  },
  computed: {
    segmentedFiles() {
      const files = {};
      this.value.forEach((file) => {
        if (!file.docType) file.docType = "Other";
        if (!(file.docType in files)) files[file.docType] = [];
        files[file.docType].push(file);
      });
      this.filesArray = files;
      return files;
    },
  },
  watch: {
    filesArray: {
      handler(newVal, oldVal) {
        let allFiles = [];
        Object.keys(newVal).forEach((key) => {
          allFiles = [...allFiles, ...newVal[key]];
        });
        if (JSON.stringify(this.value) !== JSON.stringify(allFiles)) {
          this.$emit("input", allFiles);
        }
      },
      deep: true,
    },
  },
};
</script>
