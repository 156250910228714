import gql from "graphql-tag";
import { checkupPackageFragment } from "../../checkup/definitions";

export default {
  upsertMutation: gql`
    mutation upsertCheckupPackage(
      $id: ID
      $partnerPackageId: String
      $sampleCollectionType: String
      $isDependentPackage: Boolean
      $checkupTestComponentIds: [Int]
      $homeOptionForCheckupPackageId: ID
      $meta: JSONObject!
      $orgId: ID
      $orgEntityId: ID
      $benefitId: ID
      $packageFreeFor: JSONObject
      $packageAvailableFor: JSONObject
      $productName: String
      $netCost: Int
      $taxRate: Int
      $taxType: String
      $productStatus: String
      $productType: String
    ) {
      upsertCheckupPackage(
        input: {
          id: $id
          partnerPackageId: $partnerPackageId
          sampleCollectionType: $sampleCollectionType
          isDependentPackage: $isDependentPackage
          checkupTestComponentIds: $checkupTestComponentIds
          homeOptionForCheckupPackageId: $homeOptionForCheckupPackageId
          meta: $meta
          orgId: $orgId
          orgEntityId: $orgEntityId
          benefitId: $benefitId
          packageFreeFor: $packageFreeFor
          packageAvailableFor: $packageAvailableFor
          productName: $productName
          netCost: $netCost
          taxRate: $taxRate
          taxType: $taxType
          productStatus: $productStatus
          productType: $productType
        }
      ) {
        checkupPackage {
          ...CheckupPackage
        }
      }
    }
    ${checkupPackageFragment}
  `,
};
