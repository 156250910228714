import gql from "graphql-tag";

export const prospectFragment = gql`
  fragment Prospect on Prospect {
    id
    email
    uploaded_file_name
    link
    pricing_table_state
    meta
    addons
    timestamp
  }
`;

export const prospectEventFragment = gql`
  fragment ProspectEvent on ProspectEvent {
    id
    prospect_id
    event_name
    event_params
    timestamp
  }
`;
