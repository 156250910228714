import gql from "graphql-tag";
import { benefitFragmentWithoutContent } from "./benefit";
import { dependentFragment } from "./dependent";

export const userChangeFragment = gql`
  fragment UserChange on UserChange {
    id
    user {
      id
      email
      name
      displayName
      gender
      dob
      status
      roles
      org {
        id
        name
      }
    }
    changedUserInfo
    org {
      id
    }
    status
    type
    meta
    dependent {
      ...Dependent
    }
    changedDependentInfo
    benefit {
      ...BenefitWithoutContent
    }
    changedBenefitInfo
  }
  ${dependentFragment}
  ${benefitFragmentWithoutContent}
`;

export const userChangesFragment = gql`
  fragment UserChanges on User {
    id
    userChanges {
      ...UserChange
    }
  }
  ${userChangeFragment}
`;

export const userChangeBatchFragment = gql`
  fragment UserChangeBatch on UserChangeBatch {
    id
    status
    endorsementPremium
    meta
    startingAt
    endingAt
    users
    dependents
    premiumDetailsUrl
    dbStatus
    orgId
    orgEntityId
  }
`;

export const userChangeBatchResourceListFragment = gql`
  fragment UserChangeBatch on UserChangeBatch {
    id
    status
    endorsementPremium
    meta
    startingAt
    endingAt
    dbStatus
    orgId
    orgEntityId
  }
`;
