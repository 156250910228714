<template lang="pug">
.overlay
  .text-center.shadow-sm.p-3.d-flex.align-items-center
    i.icon-chevron-left.back-button.d-block.d-md-none(v-if="isMobileView", @click="$emit('back')")
    n-button.px-5(
      v-else
      buttonText="<- Back",
      type="button",
      variant="outline-dark",
      @click="$emit('back')")
    span.font-lg.font-weight-semibold.text-gray-900.Embed-heading {{ heading }}
    span(v-if="isMobileView")
  n-spinner(
    :active="loading",
    is-full-screen,
    spinner="ring",
    size="50",
    background-color="rgba(11,57,72,0.3)",
    duration="1")
  iframe.embed-page.h-100.w-100(:src="link", @load="showEmbedApp")
</template>

<script>
import NButton from "@/components/NovaButton.vue";
export default {
  components: {
    NButton,
  },
  data() {
    return {
      loading: true,
    };
  },
  props: {
    heading: {
      type: String,
      default: "Embeded page",
    },
    link: {
      type: String,
      required: true,
    },
  },
  created() {
    // add event listner to embeded page for any communication
    window.addEventListener("message", this.emitMessageEvent, false);
  },
  beforeDestroy() {
    window.removeEventListener("message", this.emitMessageEvent, false);
  },
  methods: {
    emitMessageEvent(e) {
      this.$emit("messageFromEmbededPage", e.data);
    },
    showEmbedApp() {
      this.loading = false;
    },
  },
  computed: {
    isMobileView() {
      // checking for lg
      return window.innerWidth < 992;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
.overlay {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background: $white;
  overflow: auto;
}
.Embed-heading {
  position: absolute;
  left: 50%;
  transform: translate(-50%);
}
</style>
