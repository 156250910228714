import gql from "graphql-tag";
import { relationshipManagerFragment } from "./relationshipManager";
import { segmentFragment } from "./segment";


export const orgPropertyFragment = gql`
  fragment OrgProperty on OrgProperty {
    id
    org {
      id
      name
    }
    orgEntity {
      id
      name
    }
    name
    isGlobal
    meta
    node {
      ... on RelationshipManager {
        ...RelationshipManager
      }
      ... on Segment {
        ...Segment
      }
    }
    nodes {
      node {
        ... on Segment {
          ...Segment
        }
      }
    }
    updatedAt
  }
  ${relationshipManagerFragment}
  ${segmentFragment}
`;
