<template lang="pug">
div
  .search-field
    n-search-bar.col-md-6(
      v-model="searchQuery",
      :placeholder="searchFieldPlaceholder",
      @input="$emit('searchQuery', $event)")
  template(v-if="isLoading")
    b-skeleton-wrapper()
      div
        b-skeleton-table(type="input", :rows=5, :columns=5, :table-props="{bordered: true}")
  n-table.endorsement-table-container(v-else :items="getItems", :fields="getFields", hover , @row-clicked="item =>handleModalOrSidesheetOpening(item)", tbody-tr-class="cursor-pointer")
    template(v-slot:selected="{ data }")
      div(
        v-if="[$options.tableTypes.ENROLLMENT_PENDING,\
        $options.tableTypes.UNAPPROVED,\
        $options.tableTypes.ORG_OK,\
        $options.tableTypes.NOVA_OK,\
        $options.tableTypes.PROVIDER_OK].includes(tableType)"
      )
        b-checkbox(
          v-model="data.item.selected",
          :class="data.item.selected && !isDepRow(data) ? 'endors-dep-checkbox' : ''",
          button-variant="dark",
          :indeterminate="getIndeterminateState(data)",
          @change="addToSelected($event, data.item, getOriginalIndex(data.item))",
          :data-cy="`endorsement-table-checkbox-${(data.item.name||'').split(' ').join('-')}`"
        )

    template(v-slot:email="{ data }")
      .d-flex.align-items-center.email-width-max.flex-nowrap
        n-icon.mr-2(v-if="showObState(data.item)", :name="getObParamsForType(data.item, $options.obColumnConstants.ICON)", :variant="getObParamsForType(data.item, $options.obColumnConstants.VARIANT)", :size="1", :id="`employee-${data.item.user_id}-${data.item.type}`")
        span.font-sm.font-weight-medium.text-gray-800(
          :class="{'deleted-user-email': [$options.adminUserStatus.DELETED, $options.adminUserStatus.SUSPENDED].includes(data?.item?.status)}",
          :id="`email-${data.item.user_id}-${data.item.email}`") {{ data.item.email }}
        n-popover.w-100.p-0.border-gray-200(
          v-if="[$options.adminUserStatus.DELETED, $options.adminUserStatus.SUSPENDED].includes(data?.item?.status)",
          :id="`email-popup-${data.item.user_id}-${data.item.email}`",
          :target="`email-${data.item.user_id}-${data.item.email}`",
          triggers="hover",
          placement="right")
          template(v-slot:title)
            .d-flex.align-items-center
              n-icon.mr-1(
                name="remove-user",
                :pathCount="2",
                variant="red-600")
              div Deleted user
          template(v-slot:default)
            span.font-sm.font-weight-semibold.text-gray-900 {{ data.item.email }}
            | &nbsp; has already been removed from Nova Benefits platform.
            br
            br
            span This entry seems to be wrong, please flag this to your relationship manager. Our associates will get it fixed for you.
        n-popover.w-100.p-0.border-gray-200(
          v-if="![$options.adminUserStatus.DELETED, $options.adminUserStatus.SUSPENDED].includes(data?.item?.status)",
          :id="`email-popup-${data.item.user_id}-${data.item.type}`",
          :target="`email-${data.item.user_id}-${data.item.email}`",
          triggers="hover",
          placement="top")
          template(v-slot:title)
            span.font-weight-lighter {{ data.item.email }}
      n-popover.w-100.p-0.border-gray-200(
        v-if="showObState(data.item)",
        :id="`employee-popup-${data.item.user_id}-${data.item.type}`",
        :target="`employee-${data.item.user_id}-${data.item.type}`",
        triggers="hover",
        placement="right")
        template(v-slot:title)
          .d-flex.align-items-center
            n-icon.mr-1(
              :name="getObParamsForType(data.item, $options.obColumnConstants.ICON)",
              :pathCount="2",
              :variant="getObParamsForType(data.item, $options.obColumnConstants.VARIANT)")
            div(v-if="isObPendingForUser(data.item)") Onboarding Pending
            div(v-else) Onboarding Complete
        template(v-slot:default)
          span.font-sm.font-weight-semibold.text-gray-900(v-if="isObPendingForUser(data.item)") {{ data.item.email }} is yet to complete the onboarding
          span.font-sm.font-weight-semibold.text-gray-900(v-else) {{ data.item.email }} has completed onboarding.
          br
          br
          span(v-if="isObPendingForUser(data.item)") We recommend to fill in employee and dependent details at the same time.
          span(v-else) Please double check if employee have filled the dependent details.
          br
          br
          span Employees will not be able to add dependents once the changes are approved!

    template(v-slot:doj="{ data }")
          span(:id="`employee-${data.item.user_id}-${data.item.type}`").font-sm.font-weight-medium.text-gray-800
            | {{getDateFormat(data.item?.userMeta?.dateOfJoining)}}

    template(v-slot:name="{ data }")
      .d-flex.align-items-center.flex-nowrap.name-width-max
        n-avatar.text-blue-800.font-weight-semibold.mr-1.ml-n1(
          :name="isDepRow(data) ? getDependentContext(data) + data.item.name : data.item.name",
          :variant="isDepRow(data) ? 'warning' : 'light'",
          :size="2"
          )
        span.font-sm.font-weight-semibold.text-gray-900.text-nowrap.text-truncate {{ isDepRow(data) ? data.item.dep_name : data.item.name }}

    template(v-if="tableType!==$options.tableTypes.ENROLLMENT_PENDING", v-slot:dependents="{ data }")
      template(
        v-if="getDependentCount(data.item)",
      )
        n-chip.border.additional-benefits(
          :isMinimal="true"
          :id="`dependent-${data.item.user_id}-${data.item.type}`") {{ getDependentCount(data.item) }}
          template(v-slot:icon)
            n-icon.mr-1(name="family", :size="0.8")
        n-popover.dependent-popup.w-100.p-0.border-gray-200(
          :id="`dependent-popup-${data.item.user_id}-${data.item.type}`",
          :target="`dependent-${data.item.user_id}-${data.item.type}`",
          triggers="hover",
          :placement="getDepPopoverPlacement",
          :custom-class="`dependent-popup dependent-popup-${getDepPopoverPlacement}`")
          template(v-slot:title)
            .px-3.pt-2
              .font-weight-medium Dependents Details
          template(v-for="change in getDependentChanges(data.item)")
            .w-100.p-3
              .d-flex.justify-content-between.align-items-center.w-100
              .d-flex.align-items-top
                n-avatar.text-blue-800.font-weight-semibold(
                  :name="change.dep_name",
                  variant="warning",
                  :size="2"
                )
                .d-flex.flex-column.align-items-baseline.ml-2.ml-md-3
                  span.font-weight-semibold.text-gray-900.text-capitalize {{ change.dep_name }}
                  .d-flex.flex-row.flex-wrap.pt-1.text-gray-700.text-capitalize.font-weight-medium.font-sm
                    span.text-nowrap {{ change.relation }}
                  .d-flex.flex-row.flex-wrap.pt-1.text-gray-700.text-capitalize.font-weight-medium.font-sm(v-if="change.type")
                    coverage-pills-group-wrapper(
                      :item="change",
                    )
            hr
          n-button.btn-block.border-0.border-radius-inherit(
            type="button",
            buttonText="View Details",
            variant="outline-primary",
            size="xl",
            v-b-toggle="[`dependent-popup`]",
            @click="updateSidesheetData(data.item, 'dependents')",
          )

    template(v-slot:type="{ data }")
      .d-flex.align-items-center
        template(v-if="tableType !== $options.tableTypes.ENROLLMENT_PENDING")
          template(v-if="data.item?.type")
            coverage-pills-group-wrapper(
              :item = "data.item",
              triggers="hover",
            )
        template(v-else)
          span(
            v-if="data.item.user_changes",
            v-for="missingField in getMissingDataForMember(data.item, ['self', 'placeholder'].includes(data.item.user_type), checkIfHasNoCoverageStart(data.item?.user_changes))")
            n-chip.mr-1.border(
              :id="data.item.id + missingField",
              :isMinimal="true") {{ missingField }}
              template(v-slot:icon)
                i.mr-1(:class="['icon-question', 'text-gray-500']")
    template(v-slot:changeType="{ data }")
      template(v-if="tableType !== $options.tableTypes.ENROLLMENT_PENDING")
        div(v-if="data.item.type === 'add'")
          n-chip(chipIcon="add-user", :variant="(isDepRow(data) || isPlaceholderRow(data)) ? 'secondary2' : 'success'", pill) {{ getPillContent(data) }}
        div(v-else-if="data.item.type === 'delete'")
          n-chip(chipIcon="remove-user", :variant="(isDepRow(data) || isPlaceholderRow(data)) ? 'secondary2' : 'danger'", pill) {{ getPillContent(data) }}
        div(v-else-if="data.item.type === 'update'")
          n-chip(chipIcon="rule", :variant="(isDepRow(data) || isPlaceholderRow(data)) ? 'secondary2' : 'dark'", pill) {{ getPillContent(data) }}
      template(v-else)
        template(v-if="data.item.user_changes")
          div(v-if="data.item.user_type !== 'dependent'")
            n-chip(chipIcon="add-user", variant="success", pill) Employee Addition
          div(v-else)
            n-chip(chipIcon="add-user", variant="primary", pill) Dependent Addition
    template(v-slot:chevron="{ data }")
      template(
        v-if="tableType === 'enrollment-pending' && data.item.user_changes && getMissingDataForMember(data.item, ['self', 'placeholder'].includes(data.item.user_type), checkIfHasNoCoverageStart(data.item?.user_changes)).length"
      )
        i.icon-edit.text-gray-500.p-2.cursor-pointer(
          @click="openMissingDataModal(data.item)"
        )
      template(v-else)
        i.icon-chevron-right.text-gray-500.p-2.cursor-pointer(
        )
    template(v-slot:custom-foot="data")
      tr
        td.text-center(:colspan="getFields.length")
          div(v-if="items && items.length > 0")
            slot(name="pagination")
          div(v-else)
            empty-states(type="Endorsements" :tableType="tableType" :hasNoSearchResults="searchQuery?.length > 0")

  employee-missing-data-modal(
    :employee-data="missingModalData.employeeData",
    :missing-fields="missingModalData.missingFields",
    :org-id="orgId",
    @employee-updated="refreshActiveTable()",
    :initial-active-tab="missingDataModalActiveTab",
    @modal-closed="handleMissingDataModalClose()"
  )
  endorsement-step-modal-wrapper(
    id="endorsement-table-step-modal",
    :showDepositSteps="tableType === $options.tableTypes.PROVIDER_OK ? true : false",
    :insurers="insurers",
    :policies="policies",
    :currentBatchId="selectedBatchId",
    :insurerPolicyMap="insurerPolicyMap",
    :changesData="changesData",
    :items="items",
    :is-selected-batch-overdue="isSelectedBatchOverdue",
    :policiesWithPremium="policiesWithPremium",
    @submit="handleStepModalSubmitChanges",
    @insurer-submission-updated="$emit('insurer-submission-updated')",
  )
  transfer-batch-modal(
    :id="'transfer-batch-modal-for-changes'",
    :v-if="!this.$route.path.includes(`org-admin/`)",
    @update-batch="updateBatch",
    @modal-closed="handleTransferBatchModal('hide')",
    :batches="getTransferrableToBatches"
  )
  employee-details-sidesheet(
    id="sidesheet",
    :employeeData="sidesheetData",
    :tableType="tableType",
    :org-id="orgId",
    :resetSelected="resetSelected",
    :hasObPendingUsers="!!obPendingUsersChanges.length",
    @approve="approveChanges",
    @sendReminders="sendRemindersToSelectedUsers",
    @reject="rejectChanges",
  )
  div(v-if="tableType === $options.tableTypes.UNAPPROVED && items.length > 0")
    // Check where we need this
    review-changes-modal(
      :orgId="orgId",
      :changes-data="items",
      :date-of-interest="getDueDate.dateString",
      :total-changes="totalCount",
      :unapproved-data="unapprovedData",
      :current-batch-id="getCurrentBatchId",
      @refreshTable="refreshActiveTable()",
      @resetFloatingBar="resetFloatingToolbar()",
      @insurer-submission-updated="$emit('insurer-submission-updated')",
    )
  .toolbar-position-endorsements.position-fixed.w-75.d-flex.justify-content-center(
    v-if="selectedUserChangesCount > 0 && !isSidesheetOpen()"
  )
    floating-toolbar.z-7.floating-bar-width(
      :tableType="tableType",
      :number-of-changes-selected="selectedUserChangesCount",
      :hasObPendingUsers="!!obPendingUsersChanges.length",
      @approve=" showChangeConfirmation()? $emit('open-confirmation-modal', 'endorsementTable') : approveChanges()",
      @sendReminders="sendRemindersToSelectedUsers",
      @reject="rejectChanges",
      @transferBatch="handleTransferBatchModal('show')"
    )
</template>

<script>
import { mapState } from "vuex";
import { capitalize, flatten, groupBy } from "lodash";
import blitz from "blitzllama-js";
import resDefs from "../../orgAdmin/definitions";
import adminResDefs from "../../admin/definitions";
import { AcceptedGenders, AcceptedRelations, UserInsuranceStatus } from "../../../../common/enums";
import NSearchBar from "../../../../components/NovaSearchBar.vue";
import { UserChangeStatus } from "../../../../common/enums/userChangeStatus.enum";
import { UserChangeBatchStatus } from "../../../../common/enums/userChangeBatchStatus.enum";
import ReviewChangesModal from "./ReviewChangesModal.vue";
import FloatingToolbar from "./FloatingToolbar.vue";
import EmployeeMissingDataModal from "./EmployeeMissingDataModal.vue";
import TransferBatchModal from "./TransferBatchModal.vue";
import EmployeeDetailsSidesheet from "./EmployeeDetailsSidesheet.vue";
import PlusNChip from "./PlusNChip";
import CoveragePillsGroupWrapper from "./CoveragePillsGroupWrapper.vue";
import EndorsementStepModalWrapper from "./EndorsementStepModalWrapper.vue";
import ChangeConfirmationModal from "./ChangeConfirmationModal.vue";
import EmptyStates from "@/components/Cards/EmptyStates/EmptyStateCard.vue";
import utils, { setBackgroundScroll } from "@/utils";
import NInlineInput from "@/components/NovaInlineInput.vue";
import NAvatar from "@/components/Avatar.vue";
import NTable from "@/components/NovaTable.vue";
import NChip from "@/components/NovaChip.vue";
import NButton from "@/components/NovaButton.vue";
import NPopover from "@/components/NovaPopover.vue";

export default {
  name: "EndorsementTable",
  components: {
    NButton,
    NChip,
    NTable,
    NAvatar,
    ReviewChangesModal,
    FloatingToolbar,
    EmployeeMissingDataModal,
    TransferBatchModal,
    EmployeeDetailsSidesheet,
    EndorsementStepModalWrapper,
    PlusNChip,
    EmptyStates,
    NInlineInput,
    CoveragePillsGroupWrapper,
    NPopover,
    NSearchBar,
    ChangeConfirmationModal,
  },
  props: {
    policiesWithPremium: {
      type: Array,
      default: () => [],
    },
    tableType: {
      type: String,
      default: "",
      required: true,
    },
    draftChangesCount: {
      type: Number,
      default: 0,
    },
    selectedBatchId: {
      type: String,
      default: "",
    },
    items: {
      type: Array,
      default: () => [],
      required: true,
    },
    totalCount: {
      type: Number,
      default: 0,
      required: true,
    },
    batches: {
      type: Array,
      default: () => [],
    },
    selectedBatch: {
      type: Object,
      default: () => {},
    },
    isSelectedBatchOverdue: {
      type: Boolean,
      default: false,
    },
    unapprovedData: {
      type: Object,
      default: () => {},
    },
    orgId: {
      type: String,
      default: null,
    },
    insurers: {
      type: Array,
      default: () => [],
    },
    policies: {
      type: Array,
      default: () => [],
    },
    insurerPolicyMap: {
      type: Object,
      default: () => {},
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    shortFallAmount: {
      type: Number,
      default: 0,
    },
    bufferAmount: {
      type: Number,
      default: 0,
    },
    currentOrg: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    const resName = "userChanges";
    const resDef = resDefs[resName];
    const popoversData = resDef.popovers;
    return {
      searchQuery: "",
      searchFieldPlaceholder: "Search for an employee or their dependent by name or email ID",
      resName,
      resDef,
      popoversData,
      pageSize: 10,
      perPage: 10,
      currentPage: 1,
      selectedUserChangesMap: {},
      selectedUserChangesCount: 0,
      selectedUserChanges: [],
      batchesMap: {},
      batchId: null,
      currentBatch: null,
      getDateWithSuffix: utils.getDateWithSuffix,
      getSingularOrPlural: utils.getSingularOrPlural,
      learnMoreLink: process.env.VUE_APP_UNDERSTANDING_ENDORSEMENT_WITH_NOVA_LINK,
      indeterminateMap: {},
      missingDataModalActiveTab: "user",
      missingModalData: {
        employeeData: {
          user: {},
          dependents: [],
        },
        missingFields: {
          user: [],
          dependents: [],
        },
      },
      sidesheetData: {
        user: {},
        dependents: [],
      },
      fields: [
        {
          key: "name",
          label: "Name",
          tdClass: this.dependentRowStyling(),
        },
        {
          key: "email",
          label: "Employee Email",
          tdClass: this.dependentRowStyling(),
        },
        {
          key: "doj",
          label: "Date of joining",
          tdClass: this.dependentRowStyling(),
        },
        {
          key: "dependents",
          label: "Dependents",
          tdClass: this.dependentRowStyling(),
        },
        {
          key: "type",
          label: "Endorsement Type",
          tdClass: this.dependentRowStyling(),
        },
        {
          key: "changeType",
          label: "Endorsement",
          tdClass: this.dependentRowStyling(),
        },
        {
          key: "date",
          label: "",
          tdClass: this.dependentRowStyling(),
        },
        {
          key: "chevron",
          label: "",
          tdClass: this.dependentRowStyling(),
        },
      ],
      getMissingDataForMember: utils.getMissingDataForMember,
      checkIfHasNoCoverageStart: utils.checkIfHasNoCoverageStart,
      changesMap: {},
      subChangesMap: {},
      changesData: [],
    };
  },
  computed: {
    ...mapState(["novaSideNavExpanded"]),
    getSearchQueryLength() {
      return this.searchQuery ? this.searchQuery.length : 0;
    },
    isSidesheetOpenWatcher() {
      return this.isSidesheetOpen();
    },
    getDepPopoverPlacement() {
      return this.isSidesheetOpen() ? "left" : "right";
    },
    getFields() {
      if (this.tableType === this.$options.tableTypes.ENROLLMENT_PENDING) {
        return this.fields.filter((field) => !["dependents"].includes(field.key));
      }
      if (this.tableType !== this.$options.tableTypes.UNAPPROVED) {
        return this.fields.filter((field) => !["obState"].includes(field.key));
      }
      return this.fields;
    },
    getGroupedChanges() {
      return groupBy(this.items, (change) => {
        return change.user_id + "," + change.type;
      });
    },
    getTransformedItems() {
      return flatten(Object.values(this.changesMap));
    },
    getItems() {
      return this.tableType === this.$options.tableTypes.ENROLLMENT_PENDING ? this.items : this.getTransformedItems;
    },
    nextMonthDetails() {
      return utils.getNextMonthDetails();
    },
    getDueDate() {
      const dueDate = this.selectedBatch?.endingAt;
      return {
        dateString: this.getDateWithSuffix(dueDate),
        month: utils.getMonth(dueDate),
        year: utils.getMonthYear(dueDate),
      };
    },
    getCurrentBatchId() {
      return this.$route.params.batchId || this.selectedBatch?.id;
    },
    obPendingUsersChanges() {
      return this.selectedUserChanges?.filter(
        (item) => item.type === "add" && item.insuranceStatus === UserInsuranceStatus.OnboardingPending
      );
    },
    obCompletedUsersChanges() {
      return this.selectedUserChanges?.filter((item) => !this.obPendingUsersChanges.includes(item));
    },
    getTransferrableToBatches() {
      return this.batches.filter(
        (batch) =>
          batch.id !== this.getCurrentBatchId &&
          ![
            UserChangeBatchStatus.PROVIDER_OK,
            UserChangeBatchStatus.NOVA_OK,
            UserChangeBatchStatus.DONE,
            UserChangeBatchStatus.REJECTED,
          ].includes(batch.dbStatus)
      );
    },
  },
  watch: {
    items: {
      handler: function () {
        if (this.tableType === this.$options.tableTypes.ENROLLMENT_PENDING) {
          return;
        }

        this.changesMap = {};
        this.subChangesMap = {};
        this.organiseChangesToLevels();
      },
      deep: true,
    },
    tableType() {
      this.toggleSelectedColumn();
      this.updateTableFields();
    },
    batches() {
      if (this.batches.length)
        this.batches.forEach((batch) => {
          this.batchesMap[batch.id] = batch;
        });
    },
    isSidesheetOpenWatcher(open) {
      setBackgroundScroll(open);
      this.$store.commit("hideSupportContainer");
    },
  },
  mounted() {
    this.$root.$on("rejectDependent", (args) => {
      if (args.reject) {
        setTimeout(() => {
          this.rejectChanges(args.reject);
        }, 100);
      }
    });
  },
  async created() {
    this.$options.obColumnConstants = {
      ICON: "icon",
      VARIANT: "variant",
    };
    this.$options.tableTypes = {
      ONGOING: "ongoing",
      OVERDUE: "overdue",
      COMPLETED: "completed",
      ENROLLMENT_PENDING: "enrollment-pending",
      UNAPPROVED: "unapproved",
      APPROVED: "approved",
      ORG_OK: "org-ok",
      NOVA_OK: "nova-ok",
      PROVIDER_OK: "provider-ok",
      DONE: "done",
      REJECTED: "rejected",
    };
    this.$options.adminUserStatus = utils.adminUserStatus;
    this.updateTableFields();
    this.toggleSelectedColumn();
    if (
      [this.$options.tableTypes.ONGOING, this.$options.tableTypes.OVERDUE, this.$options.tableTypes.COMPLETED].includes(
        this.tableType
      )
    ) {
      this.batchId = this.$route.params.batchId;
      const { data } = await this.$apollo.query({
        query: adminResDefs.userChangeBatches.resourceListQuery,
      });
      const allBatches = data.userChangeBatches.edges.map((n) => n.node);
      this.currentBatch = allBatches.filter((batch) => batch.id === this.batchId && batch.status === this.tableType)[0];
    }
  },
  methods: {
    handleModalOrSidesheetOpening(item) {
      const missingDataFields = this.getMissingDataForMember(
        item,
        ["self", "placeholder"].includes(item.user_type),
        this.checkIfHasNoCoverageStart(item?.user_changes)
      ).length;
      if (this.tableType === "enrollment-pending" && item.user_changes && missingDataFields) {
        this.openMissingDataModal(item);
      } else {
        const userType = item.user_type === "self" ? "user" : "dependents";
        this.updateSidesheetData(item, userType);
      }
    },
    getDateFormat(date, format = "DD/MM/YYYY") {
      return date ? utils.getFormattedDate(date, format) : "NA";
    },
    async handleStepModalSubmitChanges({ changeIds }) {
      if (this.tableType === this.$options.tableTypes.PROVIDER_OK) {
        await this.bulkUpdateUserChangesStatus(UserChangeStatus.PROVIDER_OK, UserChangeStatus.DONE, changeIds);
      } else if (this.tableType === this.$options.tableTypes.NOVA_OK) {
        await this.bulkUpdateUserChangesStatus(UserChangeStatus.NOVA_OK, UserChangeStatus.PROVIDER_OK, changeIds);
      } else {
        await this.bulkUpdateUserChangesStatus(UserChangeStatus.ORG_OK, UserChangeStatus.NOVA_OK, changeIds);
      }
    },
    showObState(item) {
      return this.tableType === this.$options.tableTypes.UNAPPROVED && item.type === "add";
    },
    getObParamsForType(item, type) {
      const showObScreenFlag = item.insuranceStatus === UserInsuranceStatus.OnboardingPending;
      switch (type) {
        case this.$options.obColumnConstants.ICON: {
          if (showObScreenFlag) return "warning";
          else return "check-circle";
        }
        case this.$options.obColumnConstants.VARIANT: {
          if (showObScreenFlag) return "warning";
          else return "success";
        }
      }
    },
    isObPendingForUser(item) {
      return Boolean(item.insuranceStatus === UserInsuranceStatus.OnboardingPending);
    },
    getCommonBenefits(arr) {
      // Initialize an array to store the common meta change objects
      const commonMeta = [];
      // Initialize a map to store the count of each common key
      const countMap = new Map();

      // Loop through all change objects for the first dependent
      arr[0].user_changes.forEach((change) => {
        // Create a new change template object for the common meta for this benefit
        const commonChange = { benefit_id: change.benefit_id };

        // Check if all changes in the dependents array (excluding the first) have meta for this benefit
        const hasMetaForAll = arr.slice(1).every((dep) => {
          // Find the change object for this dependent having same benefit as first
          const depChange = dep.user_changes.find((uc) => uc.benefit_id === change.benefit_id);
          // If no change object is found, exit the loop
          if (!depChange) return false;

          // For each common key in change object, add the key-value pair to the common change object
          Object.keys(change).forEach((key) => {
            if (key !== "benefit_id") {
              if (countMap.has(key)) {
                countMap.set(key, countMap.get(key) + 1);
              } else {
                countMap.set(key, 1);
              }
              if (countMap.get(key) === arr.length - 1) {
                commonChange[key] = change[key];
              }
            }
          });

          // Continue looping through changes in the array
          return true;
        });

        // If all changes in the dependents array have meta for this benefit, add it to the common meta array
        if (hasMetaForAll) {
          commonMeta.push(commonChange);
        }

        // Reset the count map for the next iteration
        countMap.clear();
      });

      // Return the array of common meta objects
      return commonMeta;
    },
    isDepRow(data) {
      return utils.isDependentRow(data);
    },
    isPlaceholderRow(data) {
      return data.item.user_type === "placeholder";
    },
    getOriginalIndex(data) {
      return this.items.findIndex((item) => data === item);
    },
    showDependentContext(data) {
      return (
        this.tableType !== this.$options.tableTypes.ENROLLMENT_PENDING &&
        ["dependent", "placeholder"].includes(data.item.user_type)
      );
    },
    getDependentContext(data) {
      return capitalize(this.getDependentsSubject(data) + " of ");
    },
    getDependentsSubject(data) {
      if (data.item.user_type === "placeholder") return "Dependents";
      switch (data.item.relation) {
        case AcceptedRelations.CHILD: {
          return data.item.dep_gender === AcceptedGenders.MALE ? "Son" : "Daughter";
        }
        case AcceptedRelations.PARENT: {
          return data.item.dep_gender === AcceptedGenders.MALE ? "Father" : "Mother";
        }
        case AcceptedRelations.PARENT_IN_LAW: {
          return data.item.dep_gender === AcceptedGenders.MALE ? "Father In Law" : "Mother In Law";
        }
      }
      return data.item.relation;
    },
    getKeyByValue(object, value) {
      return Object.keys(object).find((key) => object[key].includes(value));
    },
    getDependentCount(item) {
      return this.subChangesMap[this.getKeyByValue(this.changesMap, item)]?.length;
    },
    getDependentChanges(item) {
      return this.subChangesMap[this.getKeyByValue(this.changesMap, item)];
    },
    getSubjectName(data) {
      if (this.tableType !== this.$options.tableTypes.ENROLLMENT_PENDING) return data.item.name;
      return this.isDepRow(data) ? data.item.dep_name : data.item.name;
    },
    getPillContent(data) {
      switch (data.item.type) {
        case "add":
          return this.isDepRow(data) || this.isPlaceholderRow(data) ? "Dependent Addition" : "Addition";
        case "delete":
          return this.isDepRow(data) || this.isPlaceholderRow(data) ? "Dependent Deletion" : "Deletion";
        case "update":
          return this.isDepRow(data) || this.isPlaceholderRow(data) ? "Dependent Update" : "Data Changes";
      }
    },
    getDataChangeString(data) {
      return utils.getUserChangeStringForUpdate(data);
    },
    organiseChangesToLevels() {
      Object.entries(this.getGroupedChanges).forEach((group) => {
        const [key, value] = group;
        if (value.some((val) => val.user_type === "self")) {
          this.changesMap[key] = value.filter((val) => val.user_type === "self");
          this.subChangesMap[key] = value.filter((val) => val.user_type === "dependent");
        } else if (value.length > 1) {
          const userData = this.getUserData(value[0]);
          userData.meta = this.getCommonBenefits(value);
          this.changesMap[key] = [userData];
          this.subChangesMap[key] = value;
        } else {
          this.changesMap[key] = value;
        }
      });
    },
    openReviewChangesModal() {
      this.$bvModal.show("endorsement-table-step-modal");
    },
    refreshActiveTable() {
      this.$emit("row-updated");
    },
    openMissingDataModal(data) {
      this.calculateMissingModalData(data);
      this.$bvModal.show("employee-missing-data-modal");
    },
    resetSidesheetData() {
      this.sidesheetData.user = {};
      this.sidesheetData.dependents = [];
    },
    resetFloatingToolbar() {
      this.selectedUserChangesMap = {};
      this.selectedUserChanges = [];
      this.selectedUserChangesCount = 0;
    },
    isSidesheetOpen() {
      return Object.keys(this.sidesheetData.user).length;
    },
    getUserData(data) {
      return {
        user_id: data?.user_id,
        user_type: "placeholder",
        name: data?.name,
        email: data?.email,
        gender: data?.gender,
        dob: data?.dob,
        userMeta: data?.userMeta,
        user_changes: data?.user_changes,
        type: data?.type,
        selected: data?.selected,
      };
    },
    calculateMissingModalData(data) {
      const userId = data.user_id;

      // reset the data
      this.missingModalData.employeeData = { user: {}, dependents: [] };
      this.missingModalData.missingFields = { user: [], dependents: [] };

      if (["self", "placeholder"].includes(data.user_type) && data.user_changes) {
        this.missingModalData.employeeData.user = data;
        this.missingModalData.missingFields.user = this.getMissingData(data);
        this.missingDataModalActiveTab = "user";
      }

      this.items
        .filter((item) => item.user_id === userId)
        .forEach((item, index) => {
          if (
            !["self", "placeholder"].includes(data.user_type) &&
            item.user_changes &&
            item.user_changes[0]?.dependentId === data.user_changes[0].dependentId
          ) {
            this.missingDataModalActiveTab = `dependent${index}`;
          }
          if (["self", "placeholder"].includes(item.user_type)) {
            this.missingModalData.employeeData.user = item;
            if (item.user_changes) {
              this.missingModalData.missingFields.user = this.getMissingData(item);
            }
          } else if (item.user_type === "dependent") {
            this.missingModalData.employeeData.dependents.push(item);
            this.missingModalData.missingFields.dependents.push(this.getMissingData(item));
          }
        });
    },
    updateSidesheetData(data, activeTab = "user") {
      window.posthog.capture("employee_detail_click", {
        org_name: this.user?.org?.name,
        email: this.user?.email,
      });
      const userId = data.user_id;
      // reset the data
      this.resetSidesheetData();
      if (["self", "placeholder"].includes(data.user_type) && data.user_changes) {
        this.sidesheetData.user = data;
      } else {
        this.sidesheetData.user = this.getUserData(data);
      }
      this.items.forEach((item, index) => {
        if (item.user_id === userId && item.type === data.type) {
          if (this.tableType === this.$options.tableTypes.UNAPPROVED) this.addToSelected(true, item, index);
          if (["self", "placeholder"].includes(item.user_type)) {
            if (!this.sidesheetData.user?.user_changes) this.sidesheetData.user = item;
            else {
              // compile all details of each benefit for the user for each row
              this.sidesheetData.user.user_changes = this.sidesheetData.user.user_changes.map((existingMeta) => {
                return {
                  ...existingMeta,
                  benefit_obj: {
                    ...existingMeta.benefit_obj,
                    ...(item.user_changes.find((itemMeta) => itemMeta.benefit_id === existingMeta.benefit_id)
                      ?.benefit_obj || {}),
                  },
                };
              });
            }
          } else if (item.user_type === "dependent") this.sidesheetData.dependents.push(item);
        }
      });
      this.$root.$emit("bv::toggle::collapse", "employee-details-sidesheet", { tab: activeTab });
      setTimeout(() => {
        this.$root.$emit("bv::hide::popover", `dependent-popup-${data.user_id}-${data.type}`);
      }, 100);
    },
    getMissingData(data) {
      return this.getMissingDataForMember(
        data,
        ["self", "placeholder"].includes(data.user_type),
        this.checkIfHasNoCoverageStart(data.item?.user_changes)
      );
    },
    handleMissingDataModalClose() {
      this.refreshActiveTable();
      this.missingDataModalActiveTab = "user";
      this.missingModalData = {
        employeeData: {
          user: {},
          dependents: [],
        },
        missingFields: {
          user: [],
          dependents: [],
        },
      };
      this.$bvModal.hide("employee-missing-data-modal");
    },
    getDateOfJoining(user) {
      if (user?.userMeta?.dateOfJoining) {
        const dateOfJoining = user.userMeta.dateOfJoining;
        return `${utils.getDate(dateOfJoining)} ${utils.getMonth(dateOfJoining)}, ${utils.getYear(dateOfJoining)}`;
      }
      return "";
    },
    getFilteredUserTypeByUserId(userChangeId, userChangeType) {
      if (userChangeType === "self") {
        return this.items.findIndex(
          (item) => item.user_id === userChangeId && (item.user_type === "self" || item.user_type === "placeholder")
        );
      } else if (userChangeType === "dependent") {
        return this.items.filter((item) => item.user_id === userChangeId && item.user_type === userChangeType);
      }
    },
    // FIXME: Simplify method
    addToSelected(checked, userChange, index) {
      this.addOrRemoveCheckedRow(checked, index, userChange);
      if (userChange.user_type === "self" || userChange.user_type === "placeholder") {
        this.markAllRows(checked, userChange);
      } else {
        const filteredDepByUserId = this.getFilteredUserTypeByUserId(userChange.user_id, "dependent");
        if (filteredDepByUserId.every((dep) => dep.selected === checked)) {
          this.indeterminateMap[userChange.user_id] = false;
          this.addOrRemoveCheckedRow(checked, index, userChange);
        } else {
          this.indeterminateMap[userChange.user_id] = true;
          this.markAllRows(checked, userChange);
        }
      }
      this.selectedUserChangesCount = Object.values(this.selectedUserChangesMap).length;
      this.selectedUserChanges = Object.values(this.selectedUserChangesMap);
    },
    resetSelected(userId) {
      this.selectedUserChangesMap = {};
      this.selectedUserChanges = [];
      this.selectedUserChangesCount = 0;
      this.resetSidesheetData();
      this.items
        .filter((item) => item.user_id === userId && ["self", "placeholder", "dependent"].includes(item.user_type))
        .forEach((item, index) => {
          this.addToSelected(false, item, index);
        });
    },
    addOrRemoveCheckedRow(isChecked, index, userChange) {
      if (isChecked) {
        this.selectedUserChangesMap[index] = userChange;
      } else {
        delete this.selectedUserChangesMap[index];
      }
    },
    markAllRows(isChecked, userChange) {
      this.items.forEach((item, index) => {
        if (
          item.user_id === userChange.user_id &&
          item.type === userChange.type &&
          ["self", "dependent"].includes(item.user_type)
        ) {
          if (isChecked) {
            this.selectedUserChangesMap[index] = item;
            item.selected = true;
          } else {
            delete this.selectedUserChangesMap[index];
            item.selected = false;
          }
        }
      });
    },
    getIndeterminateState(data) {
      return !this.isDepRow(data) ? this.indeterminateMap[data.item.user_id] : false;
    },
    async approveChanges() {
      try {
        this.resetSidesheetData();
        if (this.tableType === this.$options.tableTypes.UNAPPROVED) {
          const allAreDeleteChanges = this.selectedUserChanges.every((change) => change.type === "delete");
          // Approval Pending
          if (this.obCompletedUsersChanges.length || this.obPendingUsersChanges.length) {
            if (
              this.shortFallAmount > 0 &&
              !this.currentOrg?.preferences?.ignoreCDCheck &&
              this.currentOrg?.featueFlags?.PREMIUM_BREAKUP &&
              this.currentOrg?.featueFlags?.RATER_BASED_PREMIUM_ESTIMATION &&
              !allAreDeleteChanges
            ) {
              this.$bvModal.show("cash-deposit-modal");
              window.posthog.capture("cash_deposit_alert_modal_shown", {
                org_name: this.currentOrg?.name,
              });
            } else {
              blitz.triggerEvent("send_to_ready_for_nova");
              await this.bulkUpdateUserChangesStatus(UserChangeStatus.DRAFT, UserChangeStatus.ORG_OK);
            }
          }
        } else if (this.tableType === this.$options.tableTypes.ENROLLMENT_PENDING) {
          // Missing Data
          await this.sendRemindersToSelectedUsers();
        } else if (
          [
            this.$options.tableTypes.ORG_OK,
            this.$options.tableTypes.NOVA_OK,
            this.$options.tableTypes.PROVIDER_OK,
          ].includes(this.tableType)
        ) {
          this.changesData = Object.values(this.selectedUserChangesMap);
          this.$bvModal.show("endorsement-table-step-modal");
        }
        this.resetFloatingToolbar();
        this.refreshActiveTable();
      } catch (err) {
        console.error(err);
        throw err;
      }
    },
    showChangeConfirmation() {
      return this.tableType == "org-ok" && this.selectedUserChangesCount === this.items.length;
    },
    updateSelectedForCheckedRow() {
      this.selectedUserChangesMap = Object.assign(
        {},
        Object.values(this.selectedUserChangesMap).map((item) => {
          return {
            ...item,
            selected: true,
          };
        })
      );
    },
    async rejectChanges(dependent) {
      this.resetSidesheetData();
      let filterType;
      if ([this.$options.tableTypes.ENROLLMENT_PENDING, this.$options.tableTypes.UNAPPROVED].includes(this.tableType)) {
        filterType = "draft";
      } else {
        filterType = this.tableType;
      }
      if (dependent) {
        this.resetSelected();
        this.addOrRemoveCheckedRow(true, this.items.indexOf(dependent), dependent);
        this.updateSelectedForCheckedRow();
      }
      await this.bulkUpdateUserChangesStatus(filterType, UserChangeStatus.REJECTED);
      this.resetFloatingToolbar();
      window.posthog.capture("endorsements_delete", {
        number_of_employees: this.selectedUserChangesCount,
        org_name: this.user?.org?.name,
        email: this.user?.email,
      });
      this.refreshActiveTable();
    },
    async updateUserChangeStatus(currentStatus, newStatus, userChangeIds) {
      return this.$apollo.mutate({
        mutation: this.resDef.updateUserChangeStatus,
        variables: {
          orgId: this.orgId,
          batchId: this.getCurrentBatchId,
          userChangeIds,
          currentStatus,
          newStatus,
          onboardingFilters: {
            addOnboardingCompletedUsers: !!this.obCompletedUsersChanges.length,
            addOnboardingPendingUsers: !!this.obPendingUsersChanges.length,
          },
        },
      });
    },
    async bulkUpdateUserChangesStatus(currentStatus, newStatus, changeIds = []) {
      this.$store.commit("clearToasts");
      let userChangeIds;
      if (this.tableType === this.$options.tableTypes.UNAPPROVED && newStatus !== UserChangeStatus.REJECTED) {
        userChangeIds = utils.getUserChangeIds([...this.obCompletedUsersChanges, ...this.obPendingUsersChanges]);
      } else if (changeIds.length) {
        userChangeIds = utils.deepClone(changeIds);
      } else {
        userChangeIds = utils.getUserChangeIds(Object.values(this.selectedUserChangesMap));
      }
      try {
        userChangeIds = [...new Set(userChangeIds)];
        const result = await this.updateUserChangeStatus(currentStatus, newStatus, utils.deepClone(userChangeIds));
        if (result?.data?.updateUserChangeStatus?.success) {
          if (newStatus === UserChangeStatus.NOVA_OK) this.$emit("insurer-submission-updated");
          if (newStatus === UserChangeStatus.ORG_OK) this.$emit("submitted-to-org-ok");
          this.$store.commit("addToast", {
            variant: "success",
            message: `Successfully ${
              newStatus === UserChangeStatus.REJECTED ? "deleted" : "approved"
            } the selected changes`,
          });
        } else {
          this.$store.commit("addToast", {
            variant: "danger",
            message: result?.data?.updateUserChangeStatus?.errorMessage,
          });
        }
        this.refreshActiveTable();
      } catch (err) {
        console.error(err);
        throw err;
      }
    },
    async sendRemindersToSelectedUsers() {
      const userIds = new Set();
      const changes =
        this.tableType === this.$options.tableTypes.UNAPPROVED
          ? this.obPendingUsersChanges
          : Object.values(this.selectedUserChangesMap);
      changes.forEach((row) => {
        userIds.add(row.user_id);
      });
      await this.$apollo.mutate({
        mutation: adminResDefs.users.sendOnboardingInvites,
        variables: {
          inviteBy: "userIds",
          userIds: [...userIds],
          invitationType: this.$options.tableTypes.UNAPPROVED ? "ACTIVATE_COVERAGE" : "MISSING_DATA",
          isSelectedBatchOverdue: this.isSelectedBatchOverdue,
        },
      });
      this.$store.commit("addToast", {
        variant: "success",
        message: "Successfully sent reminders to the selected users",
      });
    },
    async search(e) {
      if (e.preventDefault) e.preventDefault();
      this.$apollo.queries.groupedUserChanges.refetch();
    },
    pageChanged(pageNumber, tableType) {
      this.$emit("pageChange", tableType, pageNumber, this.pageSize);
    },
    dependentRowStyling() {
      return (value, key, item) => {
        const data = { item };
        const styles = ["align-middle"];
        const depStyles = ["bg-alabaster"];
        if (key === "selected") styles.push("checkbox-width1");
        if (utils.isDependentRow(data)) styles.push(...depStyles);
        return styles;
      };
    },
    toggleSelectedColumn() {
      // TODO: Send from prop, or make global
      if (
        [
          this.$options.tableTypes.ENROLLMENT_PENDING,
          this.$options.tableTypes.UNAPPROVED,
          this.$options.tableTypes.ORG_OK,
          this.$options.tableTypes.NOVA_OK,
          this.$options.tableTypes.PROVIDER_OK,
        ].includes(this.tableType)
      ) {
        this.fields.unshift({
          key: "selected",
          label: "",
          tdClass: this.dependentRowStyling(),
        });
      }
    },
    updateTableFields() {
      const field = this.fields.find((field) => field.key === "type");
      field.label =
        this.tableType === this.$options.tableTypes.ENROLLMENT_PENDING ? "Missing Data" : "Endorsement Type";
    },
    filterBenefitsByType(benefitsArray = []) {
      const benefitsAndPolicies = {
        policies: [],
        benefits: [],
      };
      benefitsArray.forEach((benefit) => {
        const type = benefit.isPolicy ? "policies" : "benefits";
        benefitsAndPolicies[type].push(benefit);
      });
      return benefitsAndPolicies;
    },
    handleTransferBatchModal(changeType = "hide") {
      if (changeType === "show") {
        this.$bvModal.show("transfer-batch-modal-for-changes");
        return;
      }

      this.$bvModal.hide("transfer-batch-modal-for-changes");
    },
    async updateBatch(newBatchId) {
      const userChangeIds = utils.getUserChangeIds(Object.values(this.selectedUserChangesMap));

      await this.$apollo.mutate({
        mutation: adminResDefs.userChanges.transferUserChangeBetweenBatches,
        variables: {
          for: "userChangeIds",
          newBatchId,
          userChangeIds,
        },
      });
      // reset floating toolbar
      this.resetFloatingToolbar();
      this.refreshActiveTable();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
@import "@/assets/styles/_mixins.scss";

.endors-dep-checkbox {
  .custom-control-input:checked ~ .custom-control-label::before,
  .custom-control-input:indeterminate ~ .custom-control-label::before {
    color: #fff;
    border-color: $blackpearl;
    background-color: $blackpearl;
  }
  & > input.custom-control-input {
    min-height: 0 !important;
  }
}

.deleted-user-email {
  color: $red-600;
}
.search-field {
  padding: 1rem 0.5rem 0.5rem;
  width: 100%;
  border-left: 1px solid $gray-300;
  border-right: 1px solid $gray-300;
}
</style>

<style lang="scss">
@import "@/assets/styles/_variables.scss";
@import "@/assets/styles/mixins/_breakpoints.scss";

.prog-banner-icon {
  position: absolute;
  top: 28%;
  left: 2.3%;
}
.left-block-w {
  width: 4.5%;
}
.name-width-max {
  max-width: 200px;
}
.email-width-max {
  max-width: 250px;
}

.floating-bar-width {
  max-width: 700px;
}
.toolbar-position-endorsements {
  bottom: 10vh;
}
.rotated {
  transform: rotate(135deg);
}

.endorsement-table-container {
  .nova-table-wrapper {
    border-top: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}

.dependent-popup {
  width: 100% !important;
  min-width: fit-content !important;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04) !important;
  border-radius: $border-radius;
  hr {
    width: 100%;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    border-color: $gray-300;
  }
  .popover-header,
  .popover-body {
    padding: 0 !important;
  }
}
/* For popover notch border */
.dependent-popup-right > .arrow::after {
  left: 0.3px;
}
.dependent-popup-left > .arrow::after {
  right: 0.3px;
}
</style>
