export const CliToolsGroup = {
  BENEFITS: "Benefits",
  CLAIMS: "Claims",
  CRM: "CRM",
  ECARD: "E-Card",
  ONBOARDING: "Onboarding",
  USER_DETAILS: "User Details",
  OTHER: "Other",
  ACCOUNTING: "Accounting",
};
