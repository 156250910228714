<template lang="pug">
.checkup-booking-review
  .d-none.d-sm-flex.justify-content-between.mt-3.mt-sm-0
    .d-flex.align-items-center
      n-icon(name="calendar-day", :size="1")
      .text-gray-900.font-lg.font-weight-semibold.ml-2 Review Details
    .d-flex.justify-content-center.align-items-center.rounded-4.font-xs.font-weight-semibold.px-2.py-1(
      v-if="sampleCollectionConfig?.sampleCollectionTitle", :class="sampleCollectionConfig.class")
      n-icon.mr-1(:name="sampleCollectionConfig.iconName", :size="0.7", :variant="sampleCollectionConfig.variant")
      | {{ sampleCollectionConfig?.sampleCollectionTitle }}
  .review-booking-details-wrapper.mt-5.mt-md-3
    package-card(:groupedCheckupPackage="selectedGroupPackage", :isReviewPage="true")
  .review-booking-details-wrapper.mt-3
    .d-flex.align-items-center.justify-content-between
      .font-hc.letter-spacing-md.text-gray-700(v-if="isHomeCollection") Sample Collection
      .font-hc.letter-spacing-md.text-gray-700(v-else) Tests will be done at
      .d-flex.d-sm-none.justify-content-center.align-items-center.rounded-4.font-xs.font-weight-semibold.px-2.py-1(
        v-if="sampleCollectionConfig?.sampleCollectionTitle", :class="sampleCollectionConfig.class")
        n-icon.mr-1(:name="sampleCollectionConfig.iconName", :size="0.7", :variant="sampleCollectionConfig.variant")
        | {{ sampleCollectionConfig?.sampleCollectionTitle }}
    .d-flex.flex-column.my-3
      .d-flex.align-items-center
        n-icon(name="location", :size="1", variant="gray-800")
        .text-gray-800.font-md.font-weight-semibold.ml-3(v-if="isHomeCollection") {{ formattedAddress.addressLineOne }}
        .text-gray-800.font-md.font-weight-semibold.ml-3(v-else) {{ selectedProvider?.name }}
      .d-flex.align-items-center.mt-1
        n-icon.invisible(name="location", :size="1")
        .address-truncate.text-gray-700.font-sm.font-weight-medium.ml-3(v-if="isHomeCollection") {{ formattedAddress.partialAddress }}
        .address-truncate.text-gray-700.font-sm.font-weight-medium.ml-3(v-else) {{ selectedProvider?.address }}
    .d-flex.align-items-center
      n-icon(name="calendar-day", :size="0.9", variant="gray-800")
      .text-gray-800.font-md.font-weight-semibold.ml-3 {{ formattedDateTime }}
  .review-booking-details-wrapper.mt-3
    .font-hc.letter-spacing-md.text-gray-700 Your reports will be sent to
    vue-form-generator.mt-3(:schema="userDetailsForm.schema", :model="userDetailsForm.model", :options="userDetailsForm.options")
  .review-booking-details-wrapper.mt-3(v-if="bookingCost.totalWithTax")
    .font-hc.letter-spacing-md.text-gray-700 Billing Address
    .d-flex.align-items-center.mt-3(v-if="isHomeCollection")
      b-form-checkbox(v-model="isSameAsCollectionAddressSelected")
      .font-md.font-weight-medium.text-gray-900.pt-1 Use my sample collection address
      n-icon.d-none.d-md-block.ml-2(name="question", variant="gray-600", :size="1", v-b-tooltip.hover.right="displayAddress")
      n-icon.d-md-none.ml-2(name="question", variant="gray-600", :size="1", v-b-tooltip.hover.left="displayAddress")
    vue-form-generator.mt-3(v-if="!isHomeCollection || !isSameAsCollectionAddressSelected"
      :schema="billingAddressForm.schema",
      :model="billingAddressForm.model",
      :options="billingAddressForm.options",
      ref="billingAddressForm")
  .review-booking-details-wrapper.my-3
    .font-hc.letter-spacing-md.text-gray-700 Payment Details
    .d-flex.align-items-center.justify-content-between.mt-3(v-for = "member of selectedMembers")
      .d-flex.flex-column
        .text-gray-900.font-md.font-weight-semibold.text-capitalize {{ truncateName(member.name) }}
        .text-gray-700.font-sm.font-weight-medium {{ member.formattedRelation }} • {{ member.gender }} • {{ member.age }} Years
      .text-gray-900.font-md.font-weight-semibold(v-if="member.isPaidAppointment") {{ formatAmountWithRupee(member.checkupPackage.novaProduct.netCost) }}
      span.text-teal-800.font-sm.font-weight-semibold(v-else-if="isSinglePaymentLinearPackageBooking(member.checkupPackage) && !member.isPaidAppointment")
        span Payment
        br.d-block.d-md-none
        span.d-none.d-md-inline-block &nbsp;
        span received
      .text-teal-800.font-md.font-weight-semibold(v-else)
        | Free
        span.text-gray-700.font-xs.font-weight-medium.strikethrough.ml-2 {{ formatAmountWithRupee(member.checkupPackage.novaProduct.netCost) }}
    hr.border-top-dashed
    .d-flex.justify-content-between.text-gray-800.mb-2
      .font-sm Total
      .font-md.font-weight-medium {{ formatAmountWithRupee(bookingCost.total) }}
    .d-flex.justify-content-between.text-gray-800
      .font-sm Tax
      .font-md.font-weight-medium {{ formatAmountWithRupee(bookingCost.tax) }}
    .review-booking-details-divider.my-3
    hr.border-top-dashed
    .d-flex.justify-content-between.text-gray-900
      .font-md.font-weight-medium To Pay
      .font-lg.font-weight-semibold {{ formatAmountWithRupee(bookingCost.totalWithTax) }}
  br
  checkup-footer(variant="success",
    :buttonText="confirmButtonText",
    :disableButtonTextOverride="true",
    :isBackButtonDisabled="isCheckupBookingProcessActive",
    :isButtonDisabled="isCheckupBookingProcessActive",
    @goBack="$emit('backHandler')",
    @continueCheckupFlow="createBooking")
</template>

<script>
import { mapState, mapGetters } from "vuex";
import moment from "moment";
import { getIndianStatesQuery } from "../../admin/definitions/misc";
import { postalApi, formatAmountWithRupee } from "../../../../utils/misc";
import * as checkupUtils from "../checkupUtils";
import CheckupFooter from "./CheckupFooter.vue";
import PackageCard from "./PackageCard.vue";
import utils, { isVfgErrorPresent } from "@/utils";
import { CheckupPackageSampleCollectionType } from "@/common/enums";

export default {
  name: "ReviewBookingDetails",
  components: {
    CheckupFooter,
    PackageCard,
  },
  props: {},
  data() {
    return {
      isSameAsCollectionAddressSelected: true,
      userDetailsForm: {
        model: {},
        options: { validateAfterLoad: true, validateAfterChanged: true, validateAsync: true },
        schema: {
          fields: [
            {
              model: "email",
              inputType: "email",
              type: "inline-input",
              label: "<span class='text-gray-700 font-sm'>Email Id <span style='color:red'>*</span></span>",
              required: true,
              imageIcon: "mail",
              placeholder: "Enter your email address",
              labelClasses: "font-sm font-weight-medium",
              styleClasses: "d-inline-flex col-sm-6 flex-column pl-0",
            },
            {
              model: "phoneNumber",
              inputType: "tel",
              type: "inline-input",
              label: "<span class='text-gray-700 font-sm'>Phone Number <span style='color:red'>*</span></span>",
              validator: [utils.validateIndianMobileNumbersForVfg, this.infoMessageOnPhoneNumber],
              required: true,
              imageIcon: "mobile",
              placeholder: "Enter your mobile number",
              labelClasses: "font-sm font-weight-medium text-gray-700",
              styleClasses: "d-inline-flex col-sm-6 flex-column pl-0",
            },
          ],
        },
      },
      // TODO: update billing address to use same table and structure as userAddress does.
      billingAddressForm: {
        model: {},
        options: { validateAfterLoad: false, validateAfterChanged: true, validateAsync: true },
        schema: {
          fields: [
            {
              model: "address",
              type: "inline-input",
              inputType: "text",
              label: "<span class='text-gray-700 font-sm'>Address <span style='color:red'>*</span></span>",
              placeholder: "Your complete address",
              min: 6,
              max: 250,
              validator: ["string"],
              required: true,
            },
            {
              model: "pincode",
              type: "inline-input",
              inputType: "text",
              placeholder: "Pincode",
              label: "<span class='text-gray-700 font-sm'>Pincode <span style='color:red'>*</span></span>",
              validator: "regexp",
              pattern: "^[1-9]{1}[0-9]{2}\\s{0,1}[0-9]{3}$",
              required: true,
              styleClasses: "d-inline-flex col-md-6 flex-column pl-0 responsive-padding",
            },
            {
              model: "state",
              type: "editable-dropdown",
              label: "<span class='text-gray-700 font-sm'>State <span style='color:red'>*</span></span>",
              placeholder: "Select State",
              gqlQuery: getIndianStatesQuery(),
              queryName: "dropdownIndianStates",
              transform: (value) => value.name,
              styleClasses: "d-inline-flex col-md-6 flex-column px-0",
              required: true,
            },
          ],
        },
      },
    };
  },
  computed: {
    ...mapState(["user"]),
    ...mapGetters(["ahcProspectState", "isCheckupAdmin", "isCheckupBookingProcessActive"]),
    isMobile() {
      return utils.mobileCheck();
    },
    bookingUser() {
      if (this.isCheckupAdmin) return this.$route.params?.bookingUser;
      return this.user;
    },
    selectedGroupPackage() {
      return this.ahcProspectState.selectedGroupPackage || {};
    },
    selectedMembers() {
      return this.ahcProspectState.selectedMembers || [];
    },
    checkupPackages() {
      return this.selectedGroupPackage?.checkupPackages || [];
    },
    sampleCollectionConfig() {
      let sampleCollectionConfig = checkupUtils.getSampleCollectionTitleAndIcon(
        this.checkupPackages[0]?.sampleCollectionType || ""
      );
      if (this.checkupPackages?.length > 1) {
        sampleCollectionConfig = { ...sampleCollectionConfig, icon: "grouped" };
      }
      return sampleCollectionConfig;
    },
    bookingCost() {
      const bookingCost = { total: 0, tax: 0, totalWithTax: 0 };
      if (!this.selectedMembers?.length) return bookingCost;

      this.selectedMembers
        .filter(({ isPaidAppointment }) => isPaidAppointment)
        .forEach((member) => {
          const { netCost = 0, taxRate = 0 } = member?.checkupPackage?.novaProduct || {};
          const tax = netCost * Number(taxRate) * 0.01;
          bookingCost.total += netCost;
          bookingCost.tax += tax;
          bookingCost.totalWithTax += netCost + tax;
        });
      return bookingCost;
    },
    benefitId() {
      return this.$route.params.benefitId;
    },
    isHomeCollection() {
      return this.checkupPackages[0]?.sampleCollectionType === CheckupPackageSampleCollectionType.HOME_COLLECTION;
    },
    selectedAddress() {
      return this.ahcProspectState?.selectedAddress || {};
    },
    selectedProvider() {
      return this.ahcProspectState?.selectedProvider || {};
    },
    selectedContactDetails() {
      return this.ahcProspectState?.contactDetails || {};
    },
    selectedDateTimeSlot() {
      return this.ahcProspectState?.selectedDateTimeSlot || {};
    },
    scheduledAt() {
      return this.ahcProspectState?.scheduledAt || "";
    },
    formattedAddress() {
      const addressLineOne = this.selectedAddress?.address_line_one || "Home";
      const partialAddress = this.formattedPartialAddress(this.selectedAddress) || "";
      return { addressLineOne, partialAddress };
    },
    displayAddress() {
      return `${
        this.selectedAddress?.address_line_one ? `${this.selectedAddress.address_line_one}, ` : ""
      }${this.formattedPartialAddress(this.selectedAddress)}`;
    },
    formattedDateTime() {
      const { date, time } = this.selectedDateTimeSlot;

      if (!date || !time) return "";

      const formattedDate = moment(date).format("Do MMMM");
      const startTime = moment(time.startTime, "HH:mm").format("hh:mm A");
      const endTime = moment(time.endTime, "HH:mm").format("hh:mm A");
      return `${formattedDate} | ${startTime} - ${endTime}`;
    },
    confirmButtonText() {
      return this.bookingCost.totalWithTax
        ? `Pay ${formatAmountWithRupee(this.bookingCost.totalWithTax)}`
        : "Confirm Booking";
    },
  },
  watch: {
    "userDetailsForm.model": {
      handler(value) {
        this.$store.commit("updateAhcProspectStateMeta", { contactDetails: { ...value } });
      },
      deep: true,
    },
    isSameAsCollectionAddressSelected(newValue) {
      this.$store.commit("updateAhcProspectStateMeta", { isSameAsCollectionAddressSelected: newValue });
    },
    "billingAddressForm.model": {
      handler(value) {
        this.$store.commit("updateAhcProspectStateMeta", { billingAddress: { ...value } });
      },
      deep: true,
    },
    "billingAddressForm.model.pincode": {
      handler: async function (pincode, oldPincode) {
        if (pincode?.length !== 6) return;

        const location = await postalApi(pincode);
        if (!location || location.status === "Error") {
          return;
        }
        this.billingAddressForm.model.state = location?.result?.State;
      },
    },
  },
  mounted() {
    this.userDetailsForm.model = {
      email: this.selectedContactDetails?.email || this.user?.email || "",
      phoneNumber: this.selectedContactDetails?.phoneNumber || this.user?.meta?.contactNumber || "",
    };
    this.isSameAsCollectionAddressSelected = this.ahcProspectState?.isSameAsCollectionAddressSelected;
    this.billingAddressForm.model = this.ahcProspectState?.billingAddress || {};
  },
  methods: {
    formatAmountWithRupee,
    truncateName(name) {
      if (name.length > 16 && window.innerWidth < 500) {
        return name.slice(0, 16 - 2) + "...";
      }
      return name;
    },
    infoMessageOnPhoneNumber(value) {
      return [
        {
          isInfo: true,
          message: `Ensure the correct number is added; all check-up communication will be done here.`,
        },
      ];
    },
    async createBooking() {
      const { model: userDetailsModel } = this.userDetailsForm;
      if (!userDetailsModel?.email || !userDetailsModel?.phoneNumber) {
        this.$store.commit("addToast", {
          variant: "danger",
          message: "Please add email and phone number to proceed!",
        });
        this.$store.commit("endCheckupBookingProcess");
        return;
      }

      if (this.bookingCost.totalWithTax && (!this.isHomeCollection || !this.isSameAsCollectionAddressSelected)) {
        await this.$refs.billingAddressForm?.validate();
        if (isVfgErrorPresent(this.$refs.billingAddressForm.errors)) {
          this.$store.commit("addToast", {
            variant: "danger",
            message: "Please enter valid billing address details before booking",
          });
          this.$store.commit("endCheckupBookingProcess");
          return;
        }
      }

      this.$store.commit("updateAhcProspectStateMeta", { contactDetails: { ...userDetailsModel } });
      this.$store.commit("startCheckupBookingProcess");
      this.$emit("create-checkup-booking");
    },
    formattedPartialAddress(address) {
      const { address_line_two: addressLineTwo, city, country, pincode, state } = address;
      let addressLine = "";
      if (addressLineTwo) addressLine += `${addressLineTwo}, `;
      if (city) addressLine += `${city}, `;
      if (state) addressLine += `${state}, `;
      if (pincode) addressLine += `${pincode}`;
      if (country) addressLine += `, ${country}`;
      return addressLine?.trim()?.replace(/,$/, "");
    },
    isSinglePaymentLinearPackageBooking({ meta } = {}) {
      return Boolean(meta?.isSinglePaymentLinearPackage);
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/_variables.scss";
@import "@/assets/styles/_mixins.scss";
.checkup-booking-review {
  .responsive-padding {
    @include media-breakpoint-down(md) {
      padding-right: 10px;
    }
    @include media-breakpoint-down(sm) {
      padding-right: 0px;
    }
  }
}
.border-top-dashed {
  border-top: 1px dashed $gray-400;
}
.address-truncate {
  max-height: 2rem;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.review-booking-details-wrapper {
  box-shadow: $box-shadow-sm;
  padding: 1rem !important;
  border: 1px solid $gray-200;
  border-radius: 0.5rem;
}
</style>
