export const CheckupResultEvaluationType = {
  NORMAL: "normal",
  MILD: "mild",
  ABNORMAL: "abnormal",
  UNDETERMINED: "undetermined",
};

export const CheckupResultColorCode = {
  NORMAL: "text-teal-700",
  MILD: "text-mustard-600",
  ABNORMAL: "text-red-500",
  UNDETERMINED: "text-gray-900",
};

export const CheckupResultColorCodeMap = {
  [CheckupResultEvaluationType.NORMAL]: CheckupResultColorCode.NORMAL,
  [CheckupResultEvaluationType.MILD]: CheckupResultColorCode.MILD,
  [CheckupResultEvaluationType.ABNORMAL]: CheckupResultColorCode.ABNORMAL,
  [CheckupResultEvaluationType.UNDETERMINED]: CheckupResultColorCode.UNDETERMINED,
};

export const CheckupResultColorCodesPriority = {
  [CheckupResultColorCode.ABNORMAL]: 1,
  [CheckupResultColorCode.MILD]: 2,
  [CheckupResultColorCode.NORMAL]: 3,
  [CheckupResultColorCode.UNDETERMINED]: 4,
};
