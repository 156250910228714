<template lang="pug">
  n-sidesheet(:ssId="id", :headerName="employeeData.user.name", :onChange="handleChange", shadow, backdrop)
    template(v-slot:tabs)
      n-tab-item.sidesheet-tab.p-2(navContent="Details", :active="isActive('user')", @click="handleTabClick('user')")
        .details-card.m-1.p-3.shadow-xs.rounded-8
          .font-lg.font-weight-semibold General Details
          .user-container.d-flex.justify-content-between.align-items-center.py-2
            .d-flex.align-items-center
              n-avatar(
                :size="3",
                :name="employeeData.user.name",
                :image="employeeData.user.photoFile?employeeData.user.photoFile.url:null",
              )
              .user-detail.pl-1
                .font-md.font-weight-semibold {{ employeeData.user.name }}
                .font-sm.font-weight-semibold.text-gray-700 Joined on {{ getDateOfJoining(employeeData.user) }}
            .d-flex
              n-chip(v-if="tableType === 'unapproved'", variant="warning", chipIcon="refresh", pill) Approval Pending
              n-chip(v-else-if="tableType === 'rejected'", variant="danger", chipIcon="cancel", pill) Rejected
              n-chip(v-else, variant="success", chipIcon="check-circle", pill) Ready for Nova
          .buttons-container.d-flex.justify-content-start.align-items-center.my-2(v-if="tableType === 'unapproved'")
            b-button.text-teal-800.mr-1(
              v-if="tableType === 'unapproved' && hasObPendingUsers",
              v-b-toggle.employee-details-sidesheet,
              variant="outline-dark",
              size="sm",
              @click="$emit('sendReminders')"
            )
              i.font-sm.icon-check-circle.mr-1
              span.font-sm.font-weight-medium Send Reminders
            b-button.text-teal-800.mr-1(v-b-toggle.employee-details-sidesheet, variant="outline-dark", size="sm", @click="$emit('approve')")
              i.font-sm.icon-check-circle.mr-1
              span.font-sm.font-weight-medium Approve Employee
            b-button.text-red-700.mr-1(v-b-toggle.employee-details-sidesheet, variant="outline-dark", size="sm", @click="$emit('reject')")
              span.font-sm.font-weight-medium Remove Employee
          .details-container.d-flex.flex-column
            .d-flex.justify-content-between.align-items-center.px-2.py-3.border-top
              span.font-sm.font-weight-medium.text-gray-700 Employee ID
              span.font-sm.font-weight-medium {{ getEmployeeId(employeeData.user) }}
            .d-flex.justify-content-between.align-items-center.px-2.py-3.border-top
              span.font-sm.font-weight-medium.text-gray-700 Email ID
              span.font-sm.font-weight-medium {{ employeeData.user.email }}
            .d-flex.justify-content-between.align-items-center.px-2.py-3.border-top
              span.font-sm.font-weight-medium.text-gray-700 Gender
              span.font-sm.font-weight-medium {{ employeeData.user.gender || "NA" }}
            .d-flex.justify-content-between.align-items-center.px-2.py-3.border-top
              span.font-sm.font-weight-medium.text-gray-700 Phone Number
              span.font-sm.font-weight-medium {{ getContactNumber(employeeData.user) }}
            .d-flex.justify-content-between.align-items-center.px-2.py-3.border-top
              span.font-sm.font-weight-medium.text-gray-700 Date of Birth
              span.font-sm.font-weight-medium {{ getDate(employeeData.user.dob) }}
            .d-flex.justify-content-between.align-items-center.px-2.py-3.border-top
              span.font-sm.font-weight-medium.text-gray-700 Job Grade
              span.font-sm.font-weight-medium {{ getJobGrade(employeeData.user) }}
            .d-flex.justify-content-between.align-items-center.px-2.py-3.border-top
              span.font-sm.font-weight-medium.text-gray-700 {{ showExitData(employeeData.user) ? "Exit": "Joining" }} Date
              span.font-sm.font-weight-medium {{ showExitData(employeeData.user) ? getDateOfExit(employeeData.user): getDateOfJoining(employeeData.user) }}
        .details-card.mx-2.my-3.p-3.shadow-xs.rounded-8(v-if="isOverridesPresent(employeeData.user.user_changes) && employeeData.user.user_type!=='placeholder'")
          .d-flex.align-items-center.justify-content-between.mb-2
              .d-flex.align-items-center
                n-icon.mr-1(
                  name="swap-horizontal",
                  :size='1.5'
                  variant="blue-600")
                span.font-weight-bold Update Endorsement
              span.font-weight-medium.font-sm.text-gray-700 {{ getTZFormattedDate(employeeData.user.user_changes[0].created_at) }}
          .d-flex.flex-column
            p.mb-1
              span.font-normal.font-weight-medium.text-gray-800 {{ getKeysChanged(getUniqueOverrides(employeeData.user.user_changes)) }}
              span.font-normal.text-gray-700 &nbsp;updated for {{ employeeData.user.name }}
            p.mb-0(v-for="change in getUniqueOverrides(employeeData.user.user_changes)")
              span.font-normal.font-weight-light.text-gray-700 from&nbsp;
              span.font-normal.font-weight-bold.text-black-700 {{ getValueBeforeAndAfter(change, "before", "user", employeeData.user) + ' ->' }}
              span.font-normal.font-weight-light.text-gray-700 &nbsp;to&nbsp;
              span.font-normal.font-weight-bold.text-black-700 {{ getValueBeforeAndAfter(change, "after", "user", employeeData.user) }}

        .details-card.mx-2.my-3.p-3.shadow-xs.rounded-8
          .font-md.font-weight-semibold.border-bottom.py-2 {{ getPolicyHeading(employeeData.user) }}
          policy-card-wrapper.policies-cards.mb-2(:all-policies="policies", :user="employeeData.user", :previewMode="true", :is-top-up="false")

      n-tab-item.sidesheet-tab.p-2.pb-5(navContent="Dependents", :active="isActive('dependents')", @click="handleTabClick('dependents')" )
        .dependents-container(v-if="employeeData.dependents && employeeData.dependents.length !== 0")
          h4.my-2.px-1 {{ getSingularOrPlural("Dependent", employeeData.dependents.length) }}
          template(v-for="dependent in employeeData.dependents")
            .details-card.mx-1.my-3.p-3.shadow-xs.rounded-8
              .user-container.d-flex.justify-content-between.align-items-center.py-2
                .d-flex.align-items-center
                  n-avatar(
                    :size="3",
                    :name="dependent.dep_name",
                    :image="dependent.photoFile?dependent.photoFile.url:null",
                  )
                  .user-detail.pl-1
                    .font-md.font-weight-semibold {{ dependent.dep_name }}
              .buttons-container.d-flex.justify-content-start.align-items-center.my-2(v-if="tableType === 'unapproved'")
                b-button.text-red-700.mr-1(v-b-toggle.employee-details-sidesheet, v-if="!isRejectionDisabledForDependent", variant="outline-dark", size="sm", @click="handleDependentReject(dependent)")
                  span.font-sm.font-weight-medium Reject Dependent
              .details-container.d-flex.flex-column
                .d-flex.justify-content-between.align-items-center.px-2.py-3.border-top
                  span.font-sm.font-weight-medium.text-gray-700 Date of birth
                  span.font-sm.font-weight-medium {{ getDate(dependent.dep_dob) }}
                .d-flex.justify-content-between.align-items-center.px-2.py-3.border-top(v-if="dependent.depMeta.dom")
                  span.font-sm.font-weight-medium.text-gray-700 Date of Marriage
                  span.font-sm.font-weight-medium {{ getDate(dependent.depMeta.dom) }}
                .d-flex.justify-content-between.align-items-center.px-2.py-3.border-top
                  span.font-sm.font-weight-medium.text-gray-700 Relationship
                  span.font-sm.font-weight-medium {{ dependent.relation || "NA" }}
                .d-flex.justify-content-between.align-items-center.px-2.py-3.border-top
                  span.font-sm.font-weight-medium.text-gray-700 Gender
                  span.font-sm.font-weight-medium {{ dependent.dep_gender || "NA" }}
                .d-flex.justify-content-between.align-items-center.px-2.py-3.border-top
                  span.font-sm.font-weight-medium.text-gray-700 Coverage
                  coverage-pills-group-wrapper.ml-5.text-right(
                    :item = "dependent",
                    triggers="hover",
                  )
            .details-card.mx-2.my-3.p-3.shadow-xs.rounded-8(v-if="isOverridesPresent(dependent.meta)")
              .d-flex.align-items-center.justify-content-between.mb-2
                .d-flex.align-items-center
                  n-icon.mr-1(
                    name="swap-horizontal",
                    :size='1.5'
                    variant="blue-600")
                  span.font-weight-bold Update Endorsement
                span.font-weight-medium.font-sm.text-gray-700 {{ getTZFormattedDate(dependent.meta[0].created_at) }}
              .d-flex.flex-column
                p.mb-1
                  span.font-normal.font-weight-medium.text-gray-800 {{ getKeysChanged(getUniqueOverrides(dependent.meta)) }}
                  span.font-normal.text-gray-700 &nbsp;updated for {{ dependent.dep_name }}
                p.mb-0(v-for="change in getUniqueOverrides(dependent.meta)")
                  span.font-normal.font-weight-light.text-gray-700 from&nbsp;
                  span.font-normal.font-weight-bold.text-black-700 {{ getValueBeforeAndAfter(change, "before", "dependent", dependent) + ' ->' }}
                  span.font-normal.font-weight-light.text-gray-700 &nbsp;to&nbsp;
                  span.font-normal.font-weight-bold.text-black-700 {{ getValueBeforeAndAfter(change, "after", "dependent", dependent) }}
        .dependents-container.empty-container(v-else)
          empty-states-endorsements(tableType= "empty-dependent")
</template>

<script>
import { mapState } from "vuex";
import resDefs from "../../orgAdmin/definitions";
import PolicyCardWrapper from "../../components/PolicyCardWrapper.vue";
import CoveragePillsGroupWrapper from "./CoveragePillsGroupWrapper.vue";
import utils from "@/utils";
import EmptyStatesEndorsements from "@/components/Cards/EmptyStates/Endorsements.vue";
import NButton from "@/components/NovaButton.vue";
import NSidesheet from "@/components/NovaSideSheet";
import NTabItem from "@/components/NovaTabItem.vue";
import NAvatar from "@/components/Avatar.vue";
import NChip from "@/components/NovaChip.vue";
import { UserChangeStatus } from "../../../../common/enums/userChangeStatus.enum";
export default {
  name: "EmployeeDetailsSidesheet",
  components: {
    NButton,
    EmptyStatesEndorsements,
    NSidesheet,
    NTabItem,
    NAvatar,
    NChip,
    PolicyCardWrapper,
    CoveragePillsGroupWrapper,
  },
  props: {
    employeeData: {
      type: Object,
      default: {},
    },
    tableType: {
      type: String,
      default: "",
    },
    orgId: {
      type: String,
      default: "",
    },
    resetSelected: {
      type: Function,
      default: () => null,
    },
    hasObPendingUsers: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const resDef = resDefs.benefits;
    return {
      id: "employee-details-sidesheet",
      resDef,
      getSingularOrPlural: utils.getSingularOrPlural,
      policies: [],
      items: null,
      activeTab: "user",
    };
  },
  computed: {
    ...mapState(["user"]),
    isRejectionDisabledForDependent() {
      return this.employeeData.user?.type === "delete";
    },
  },
  watch: {
    employeeData: {
      handler() {
        this.$apollo.addSmartQuery("items", {
          query: this.resDef.listQuery,
          variables: () => ({
            filter: {
              orgId: this.orgId,
            },
          }),
          update: (data) => {
            if (this.employeeData.user.user_changes) {
              // Getting list of policies Ids employee will be added to
              const employeePolicyIds = this.employeeData.user.user_changes
                .filter(
                  (change) =>
                    ![UserChangeStatus.ARCHIVE, UserChangeStatus.DELETED, UserChangeStatus.REJECTED].includes(
                      change.status
                    )
                )
                .map((u) => u.benefit_id);
              // Creating filtered list of policies assigned to employee from the org policies
              const items = this.resDef
                .getEdgesFromData(data)
                .map(this.resDef.transform)
                .filter((item) => item.isPolicy && employeePolicyIds.includes(item.id));
              this.policies = items.map((item) => {
                const benefitEdge = this.employeeData.user.user_changes.find(
                  (benefit) => benefit.benefit_id === item.id
                );
                return {
                  meta: {
                    ...item.meta,
                    ...(benefitEdge?.benefit_obj?.meta || {}),
                  },
                  node: item,
                };
              });
            }
          },
        });
      },
      deep: true,
    },
  },
  mounted() {
    this.$root.$on("bv::toggle::collapse", (target, data) => {
      if (data && data.tab) {
        this.activeTab = data.tab;
      }
    });
  },
  methods: {
    handleDependentReject(dependent) {
      this.$root.$emit("rejectDependent", {
        reject: dependent,
        "bv::toggle::collapse": "sidesheet",
      });
    },
    statusToIcon(status) {
      return utils.statusToIconDetails(status);
    },
    handleChange(visible) {
      if (!visible) {
        // Unselecting the userChange on closing sidesheet
        this.resetSelected(this.employeeData.user.user_id);
        this.policies = [];
      }
    },
    getDate(date) {
      if (!date) return "NA";
      return `${utils.getDate(date)} ${utils.getMonth(date)}, ${utils.getYear(date)}`;
    },
    getDateOfJoining(user) {
      return this.getDate(user?.userMeta?.dateOfJoining);
    },
    getFormattedDateTime(date) {
      return utils.getFormattedDateTime(date);
    },
    getTZFormattedDate(date) {
      const tzFormattedDate = utils.getTZFormattedDate(date);
      return this.getFormattedDateTime(tzFormattedDate);
    },
    getDateOfExit(user) {
      if (!this.showExitData(user)) {
        return "NA";
      }
      return this.getDate(user.meta[0].benefit_obj.dateOfExit);
    },
    showExitData(user) {
      return user.type === "delete" && user?.meta?.length && user?.meta[0]?.benefit_obj?.dateOfExit;
    },
    getPolicyHeading(user) {
      if (user.type === "update") {
        return "Employee will be updated in the following policies";
      } else if (user.type === "delete") {
        return "Employee will be removed from the following policies";
      }
      return "Employee will be added to the following policies";
    },
    getJobGrade(user) {
      if (!user?.userMeta?.jobGrade) {
        return "NA";
      }
      return user.userMeta.jobGrade;
    },
    getContactNumber(user) {
      if (!user?.userMeta?.contactNumber) {
        return "NA";
      }
      return user.userMeta.contactNumber;
    },
    getEmployeeId(user) {
      if (!user?.userMeta?.employeeId) {
        return "NA";
      }
      return user.userMeta.employeeId;
    },
    getUniqueOverrides(meta) {
      if (!meta) return;
      const override = meta[0]?.benefit_obj?.overrides;
      if (override) {
        return Object.entries(override).map(([key, value]) => ({ [key]: value }));
      }
    },
    isOverridesPresent(meta) {
      return !!this.getUniqueOverrides(meta)?.length;
    },
    getKeysChanged(arr) {
      const keys = Array.from(
        new Set(arr.flatMap((obj) => Object.keys(obj).map((key) => key.charAt(0).toUpperCase() + key.slice(1))))
      ).join(", ");

      return keys;
    },
    getValueBeforeAndAfter(change, type, userType, currentData) {
      const key = Object.keys(change)[0];
      const value = Object.values(change)[0];

      if (type === "before") {
        return key === "dob" ? this.getDate(value) : value;
      }

      if (type === "after") {
        const prefix = userType === "dependent" ? "dep_" : "";
        return key === "dob" ? this.getDate(currentData[prefix + key]) : currentData[prefix + key];
      }
    },
    handleTabClick(activeTab) {
      this.activeTab = activeTab;
    },
    isActive(currentTab) {
      return this.activeTab === currentTab;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
@import "@/assets/styles/mixins/_breakpoints.scss";
.sidesheet-tab {
  background: rgba(229, 229, 229, 0.2);
  height: 100%;
}
.details-card {
  background: $white;
}
.policies-cards {
  flex-direction: column;
}
.empty-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
