<template lang="pug">
b-modal(
    v-bind="{...$props, ...$attrs}",
    v-on="$listeners",
    id="entity-selection-modal",
    size="md",
    centered,
    header-class="py-2",
    cancel-variant="light",
    button-size="sm",
  )
  template(v-slot:modal-title)
    .w-100.font-lg.text-gray-900.font-weight-semibold.text-center.py-0
      span Select {{ entityName }}

  .modal-body-container.p-3
    .modal-description.mb-3 
      template(v-if="!entitiesData.length")
        span.text-gray-700.font-sm-font-weight-medium {{ `No ${entityName} found for the selected org` }}
      template(v-else)
        slot(name="description")
    .entity-container.d-flex.align-items-center(v-for="(entity, index) in entitiesData")
      b-checkbox(
          v-model="entity.isSelected",
          button-variant="success",
          size="lg"
        )
      n-avatar.text-blue-800.font-weight-semibold(
        :name="entity.name",
        variant="success",
        :size="3"
      )
      .name-email-container.d-flex.flex-column.ml-2
        span.text-gray-900.font-sm.font-weight-semibold {{ entity.name }}
        span.text-gray-800.font-sm.font-weight-medium {{ entity.email }}

  template(v-slot:modal-footer)
    .d-flex.justify-content-end
      n-button.font-sm.mr-3(
        variant="light-secondary",
        :buttonText="closeBtnText",
        @click="handleClose",
      )
      n-button.font-sm(
        variant="dark",
        :buttonText="continueBtnText",
        :disabled="isContinueDisabled",
        @click="handleContinue"
      )
</template>

<script>
import NButton from "@/components/NovaButton.vue";
import utils from "@/utils";
import NAvatar from "@/components/Avatar.vue";

export default {
  name: "EntitySelectionModal",
  components: {
    NButton,
    NAvatar,
  },
  props: {
    entities: {
      type: Array,
      default: () => [],
    },
    closeBtnText: {
      type: String,
      default: "Close",
    },
    continueBtnText: {
      type: String,
      default: "Continue",
    },
    entityName: {
      type: String,
      default: "Users",
    },
    selectionRequired: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      entitiesData: [],
    };
  },
  computed: {
    isContinueDisabled() {
      if (this.selectionRequired && !this.entitiesData.find((entityData) => entityData.isSelected === true)) {
        return true;
      }
      return false;
    },
  },
  watch: {
    entities: {
      deep: true,
      handler(entities) {
        if (!entities.length) {
          return;
        }
        this.entitiesData = utils.deepClone(entities);
      },
    },
  },
  mounted() {
    this.entitiesData = utils.deepClone(this.entities);
  },
  methods: {
    handleClose() {
      this.$emit("close");
    },
    handleContinue() {
      this.$emit("submit", this.entitiesData);
      this.entitiesData = [];
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/_variables.scss";
</style>
