import gql from "graphql-tag";

export default {
  exportEnrollmentDataDump: gql`
    mutation exportEnrollmentDataDump($filter: JSONObject) {
      exportEnrollmentDataDump(input: { filter: $filter }) {
        message
      }
    }
  `,
};
