import { render, staticRenderFns } from "./PolicySummary.vue?vue&type=template&id=0b700d48&scoped=true&lang=pug"
import script from "./PolicySummary.vue?vue&type=script&lang=js"
export * from "./PolicySummary.vue?vue&type=script&lang=js"
import style0 from "./PolicySummary.vue?vue&type=style&index=0&id=0b700d48&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0b700d48",
  null
  
)

export default component.exports