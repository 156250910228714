import gql from "graphql-tag";
import { orgGroupFileFragment } from "../fragments";
export default {
  upsertMutation: gql`
    mutation ($id: ID, $file: Upload, $action: String, $meta: JSONObject, $name: String) {
      upsertFile(input: { id: $id, upload: $file, action: $action, meta: $meta, name: $name }) {
        file {
          id
          name
          url
          meta
          action
          createdAt
          uploadingUser {
            org {
              name
            }
          }
        }
      }
    }
  `,

  upsertOrgGroupFileEdges: gql`
    mutation upsertOrgGroupFileEdges(
      $fileId: ID!
      $orgId: ID!
      $orgEntityId: ID
      $fileGroupIds: [ID]
      $meta: JSONObject
    ) {
      upsertOrgGroupFileEdges(
        input: { fileId: $fileId, orgId: $orgId, orgEntityId: $orgEntityId, fileGroupIds: $fileGroupIds, meta: $meta }
      ) {
        orgGroupFileEdge {
          ...OrgGroupFileEdge
        }
      }
    }
    ${orgGroupFileFragment}
  `,

  deleteOrgGroupFileEdges: gql`
    mutation deleteOrgGroupFileEdges($fileId: ID!, $orgId: ID!, $orgEntityId: ID, $fileGroupIds: [ID]) {
      deleteOrgGroupFileEdges(
        input: { fileId: $fileId, orgId: $orgId, orgEntityId: $orgEntityId, fileGroupIds: $fileGroupIds }
      ) {
        success
      }
    }
  `,
};
