import { OrgServicesOffered } from "../common/enums";

export function getOrgWithOrgEntitiesFromData(queryName) {
  return (data) => {
    const orgsWithOrgEntities = [];
    data[queryName]?.edges?.forEach((item) => {
      const org = item.node;
      orgsWithOrgEntities.push(org);
      org.orgEntities?.forEach((orgEntity) => {
        orgsWithOrgEntities.push({ ...orgEntity, org, isSubElement: true });
      });
    });
    return orgsWithOrgEntities;
  };
}
export function isWellnessOnlyOrg(org, orgBenefits) {
  const policiesLinkedWithOrg = orgBenefits?.some((orgBenefit) => orgBenefit?.is_policy);

  return org?.meta?.servicesOffered === OrgServicesOffered.WELLNESS_ONLY && !policiesLinkedWithOrg;
}
