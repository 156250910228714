import gql from "graphql-tag";
import { getListQuery, getSingleQuery } from "../queries";
import { orgPropertyFragment } from "../fragments";
import orgPropertiesSchema from "../schemas/orgProperties";
import orgPropertiesMutations from "../mutations/orgProperties";
import { groupSegmentsByType } from "@/utils";

export default {
  gqlType: "OrgProperty",
  fields: ["name", "orgName", "orgEntityName", "updatedAt", "property"],
  listQuery: getListQuery("OrgProperty"),
  singleQuery: getSingleQuery("OrgProperty"),
  singleQueryByName: gql`
    query GlobalOrgPropertyByName($name: String!) {
      globalOrgPropertyByName(name: $name) {
        ...OrgProperty
      }
    }
    ${orgPropertyFragment}
  `,
  singleQueryByNameAndOrgId: gql`
    query GetOrgPropertyByNameAndOrgId($name: String!, $orgId: ID!, $orgEntityId: ID) {
      getOrgPropertyByNameAndOrgId(name: $name, orgId: $orgId, orgEntityId: $orgEntityId) {
        ...OrgProperty
      }
    }
    ${orgPropertyFragment}
  `,
  ...orgPropertiesSchema,
  ...orgPropertiesMutations,
  validate: (n) => {
    // returns an error message if error exists, else returns false
    switch (n.name) {
      case "RELATIONSHIP_MANAGER_MAPPING": {
        if (!n?.node) return `Please select an option`;
        break;
      }
      case "KENKO_ORG_MAPPING": {
        if (!n?.meta.kenkoOrgId) return `Please enter the Kenko org id for the selected org!`;
        break;
      }
      case "MEDIBUDDY_ORG_ENTITY_ID": {
        if (!n?.meta.medibuddyOrgEntityId) return `Please enter the Medibuddy entity id for the selected org!`;
        break;
      }
      case "MEDIBUDDY_API_ENTITY_ID": {
        if (!n?.meta.medibuddyApiEntityId) return `Please enter the Medibuddy API entity id for the selected org!`;
        break;
      }
      case "XOXO_STORE_API_TOKEN": {
        if (!n?.meta.xoxoStoreApiToken) return `Please enter the Xoxo Store API token for the selected org!`;
        break;
      }
      case "EKINCARE_PWA_ORG_ENTITY_ID": {
        if (!n?.meta.ekincarePwaOrgEntityId) return `Please enter the Ekincare PWA entity id for the selected org!`;
        break;
      }
      case "EKINCARE_API_ORG_ENTITY_KEYS": {
        if (!n?.meta.ekincareApiOrgEntityId || !n?.meta.ekincareApiOrgEntityPassword) {
          return `Please enter the Ekincare API credentials for the selected org!`;
        }
        break;
      }
      case "YOURDOST_ORG_ID": {
        if (!n?.meta.yourdostOrgId) {
          return `Please enter the Yourdost org id for the selected org!`;
        }
        break;
      }
      case "ORG_SEGMENT": {
        if (!n?.nodes) return `Please select at least one org segment configuration`;
        for (const segmentNode of n?.nodes) {
          if (Object.keys(segmentNode).length === 0) return `Org Segment field cannot be empty`;
        }
        const segmentsByType = groupSegmentsByType(n?.nodes);
        for (const type in segmentsByType) {
          if (segmentsByType[type].length > 1 && type === "FITNESS")
            return `Org cannot belong to multiple segments of the same type: ${type}`;
        }
        break;
      }
      case "ORG_ONBOARDING_DEFAULT_ETA": {
        if (!n.meta.etaType) return `Please select an ETA type option`;
        break;
      }
      case "INSURER_TPA_HANDBOOK_MAPPING": {
        const insurerTpaMapping = new Set();
        for (const mapping of n.meta.insurerTpaHandbookMappings) {
          const tpa = mapping.tpa;
          const insurer = mapping.insurer;
          if (!mapping.file || !tpa || !insurer) return `Required fields cannot be empty!`;
          if (insurerTpaMapping.has(`${insurer.id}<>${tpa.id}`)) {
            return `Duplicate mapping found for - ${insurer.name}<>${tpa.name}`;
          } else {
            insurerTpaMapping.add(`${insurer.id}<>${tpa.id}`);
          }
        }
        break;
      }
      case "BRAND_CUSTOMIZATIONS": {
        if (!n.meta.brandColor || !n.meta.logoImage || !n.meta.brandImage) {
          return `Required fields cannot be empty!`;
        }
        if (!n.meta.brandColor?.startsWith("#") || n.meta.brandColor?.length !== 7) {
          return `Please use hex value for brand color`;
        }
        break;
      }
      case "EMPLOYEE_ASSESSMENTS": {
        if (n.meta?.onboardingQuiz?.enable && !n.meta?.onboardingQuiz?.url) {
          return `Please provide the Onboarding Quiz URL`;
        }
        if (
          n.meta?.novaAssessment?.enable &&
          (!n.meta?.novaAssessment?.url || !n.meta?.novaAssessment?.heading || !n.meta?.novaAssessment?.buttonText)
        ) {
          return `Please provide all field of Nova Assessment`;
        }
        if (n.meta?.finny?.enable && (!n.meta?.finny?.url || !n.meta?.finny?.heading || !n.meta?.finny?.buttonText)) {
          return `Please provide all field of the Finny Announcement`;
        }
        let enabledCount = 0;
        if (n.meta?.novaAssessment?.enable) enabledCount++;
        if (n.meta?.onboardingQuiz?.enable) enabledCount++;
        if (n.meta?.finny?.enable) enabledCount++;
        if (enabledCount > 1) {
          return `Only one assessment can be active.`;
        }

        break;
      }
      case "CUSTOM_BANNERS": {
        let bannersCount = n.meta?.customBanners?.length;
        if (n.meta?.allowGlobalOrgBanners && n.meta?.globalBannerCount) {
          bannersCount += n.meta?.globalBannerCount;
        }

        if (bannersCount > 4) {
          return `Only maximum of 4 banners including global banners can be added to an org`;
        }

        break;
      }
      default:
        return false;
    }
    return false;
  },
  transform: (n, write = false) => {
    if (write) {
      switch (n.name) {
        case "ORG_SEGMENT": {
          n.nodeIds = n?.nodes?.map((item) => item.node.id);
          break;
        }
        case "RELATIONSHIP_MANAGER_MAPPING": {
          n.nodeId = n.node?.id;
          break;
        }
        case "ORG_ONBOARDING_DEFAULT_ETA": {
          if (n.meta.etaType !== "custom") {
            n.meta = {
              etaType: n.meta.etaType,
            };
          }
          break;
        }
        case "CUSTOM_BANNERS": {
          n.meta = {
            ...n.meta,
            customBanners: n.meta.customBanners.map((banner) => ({
              ...banner,
              ...(banner.benefit ? { benefit: { id: banner.benefit.id, name: banner.benefit?.name } } : {}),
            })),
          };

          break;
        }
      }
    }
    let property = "Check Metadata ->";
    if (n.node) property = n.node.name;
    if (n.nodes) property = n?.nodes?.map((item) => item?.node?.name);
    const payload = {
      ...n,
      property,
      orgId: n.org?.id,
      orgName: n.org?.name,
      orgEntityId: n?.orgEntity?.id,
      orgEntityName: n?.orgEntity?.name || "-",
    };
    if (write) {
      delete payload.org;
    }
    return payload;
  },
};
