<template lang="pug">
div(:class="showSubtext ? 'pt-7' : 'py-7'")
  .d-flex.justify-content-center
    img(:src="require(`@/assets/images/magnifying-glass.svg`)")
  div
    .empty-states-msg1.font-weight-semibold.font-xl No results found for the selected filters 😐
  div(v-if="showSubtext")
    .empty-states-msg2.font-lg.font-weight-medium.mx-auto  Try adjusting the filters or remove them.
</template>

<script>
export default {
  name: "Default",
  props: {
    showSubtext: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
