import gql from "graphql-tag";
import { policyDataCheckErrorFragment } from "../fragments";
export default {
  upsertMutation: gql`
    mutation ($id: ID, $data: JSONObject) {
      upsertPolicyDataCheckError(input: { id: $id, data: $data }) {
        policyDataCheckError {
          ...PolicyDataCheckError
        }
      }
    }
    ${policyDataCheckErrorFragment}
  `,
  bulkDeletePolicyDataCheckMutation: gql`
    mutation bulkDeletePolicyDataCheckError($filter: JSONObject) {
      bulkDeletePolicyDataCheckError(input: { filter: $filter }) {
        success
        deleted
      }
    }
  `,
  exportPolicyDataCheckDump: gql`
    mutation exportPolicyDataCheckDump($filter: JSONObject) {
      exportPolicyDataCheckDump(input: { filter: $filter }) {
        message
      }
    }
  `,
};
