<template lang="pug">
div.py-7
  .d-flex.justify-content-center
    img.image-size(:src="imagePath")
  div
    .empty-states-msg1.font-weight-semibold.font-lg {{message1}}
    .empty-states-msg2.font-md.font-weight-medium.mx-auto {{ message2 }}
      b-link(v-if="supportMessage",  @click="showSalesIqChat") &nbsp;{{ supportMessage }}
  .text-center.w-100.mt-4
    slot(name="content")
</template>

<script>
export default {
  name: "EmptyState",
  props: {
    imagePath: {
      type: String,
      default: require("@/assets/images/magnifying-glass.svg"),
    },
    message1: {
      type: String,
      default: "Nothing to see here!",
    },
    message2: {
      type: String,
      default: "",
    },
    supportMessage: {
      type: String,
      default: "",
    },
  },
  methods: {
    showSalesIqChat(event) {
      event.stopPropagation();
      this.$store.commit("toggleSupportContainer");
    },
  },
};
</script>

<style scoped>
.image-size {
  height: 4rem;
}
</style>
