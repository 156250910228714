<template lang="pug">
  .rm-card-container.shadow-xs.d-flex.rounded-2.p-3(
    :class="variant==='vertical' ? 'flex-column rm-card-background rm-card-container-large-screen' \
    : 'card-horizontal rm-card-container-smaller-sreen flex-row w-100 p-3'")
    .rm-card-title.d-flex.font-inter.font-weight-medium.flex-column(
      :class="variant === 'vertical' ? 'font-lg' : 'font-md'")
      div
        | Got any questions on your mind?
      div
        span Talk to me
        span 
          img.ml-2(:src="require('@/assets/images/wave.svg')", width="18")
    .rm-wrapper.d-flex(
      :class="variant==='vertical' ? 'mt-3 rm-wrapper-medium-screen' : ''")
      div.d-flex.rm-name-wrapper
        n-avatar(
          :size="2.2"
          :name="name",
          :image="photoFile ? photoFile.url : null")
        .rm-name.pl-2
          .font-weight-semibold
            | {{ name }}
          .font-weight-medium.text-gray-700.font-sm
            | Relationship Manager
      .ask-me-button-wrapper
        n-button(variant="dark", buttonText="Ask Me Anything", @click="showSalesIqChat", size="sm")
</template>

<script>
import NButton from "@/components/NovaButton";
import NAvatar from "@/components/Avatar";

export default {
  name: "RMCard",
  components: {
    NButton,
    NAvatar,
  },
  props: {
    name: {
      type: String,
      default: "Nova Admin",
    },
    photoFile: {
      type: String,
      default: "",
    },
    variant: {
      type: String,
      default: "vertical",
    },
  },
  methods: {
    showSalesIqChat() {
      this.$store.commit("toggleSupportContainer");
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/_variables.scss";
.rm-card-container {
  background-color: #fff;
  border: 1px solid $gray-200;
  box-sizing: border-box;
  position: relative;
  top: 0;
  overflow: hidden;
  min-height: 80px;
  width: 100%;

  @media (min-width: 768px) {
    justify-content: center;
    align-items: center;
  }

  .rm-name-wrapper {
    flex: 1;
  }

  .rm-card-title {
    align-items: flex-start;
  }

  .rm-name {
    width: 100%;
    flex-direction: column;
  }
}

.rm-card-background {
  background-image: url("~@/assets/images/rm-card-background.svg");
  background-repeat: no-repeat;
  background-position: right top;
}

.card-horizontal {
  .rm-card-title {
    width: 40%;
  }

  .rm-wrapper {
    width: 60%;
  }
}

@media (max-width: 576px) {
  .rm-card-container-smaller-sreen {
    flex-direction: column !important;
    background-color: #fff;
    border: 1px solid $gray-200;
    box-sizing: border-box;
    position: relative;
    overflow: hidden;
    background-image: url("~@/assets/images/rm-card-background.svg");
    background-repeat: no-repeat;
    background-position: right top;
    margin-bottom: 24px;

    .rm-card-title {
      padding-bottom: 14px;
      width: 100%;
    }

    .rm-wrapper {
      width: 100%;
    }
  }
}

@media (min-width: 576px) and (max-width: 992px) {
  .rm-card-container-smaller-sreen {
    align-items: center;
    justify-content: center;
    margin-bottom: 24px;
  }

  .rm-wrapper {
    align-items: center;
    justify-content: center;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .rm-name-wrapper {
    margin-bottom: 14px;
  }

  .rm-wrapper-medium-screen {
    flex-direction: column;
  }
}

@media (min-width: 1200px) {
  .rm-card-container-large-screen {
    align-items: flex-start;
  }

  .rm-wrapper {
    width: 100%;
  }
}
</style>
