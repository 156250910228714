import { getListQuery, getSingleQuery } from "../../checkup/definitions";
import checkupPackagesMutations from "../mutations/checkupPackages";
import checkupPackagesSchema from "../schemas/checkupPackages";
import { CheckupPackageSampleCollectionType } from "@/common/enums";

export default {
  gqlType: "CheckupPackage",
  fields: ["packageName", "netCost", "partnerPackageId", "collectionType", "groupName", "orgName"],
  singleQuery: getSingleQuery("CheckupPackage"),
  listQuery: getListQuery("CheckupPackage"),
  ...checkupPackagesSchema,
  ...checkupPackagesMutations,
  validate: (n) => {
    // returns an error message if error exists, else returns false
    if (!n?.org) return `Please select an Org`;
    if (!n?.benefit) return `Please select a Benefit`;
    if (!n?.sampleCollectionType || n?.sampleCollectionType === "None selected")
      return `Please select the sampleCollectionType`;

    if (n?.org?.meta?.grades) {
      for (const grade of n?.org?.meta?.grades) {
        if (!n["packageFreeFor" + grade] || n["packageFreeFor" + grade] === "None selected")
          return `Package free for not selected for Grade : ${grade}`;
      }
    }

    if (!n?.partnerPackageId) return `Please add the partnerPackageId`;
    if (!n?.productName) return `Please add the Product Name`;
    if (!n?.netCost) return `Please add the net cost`;
    return false;
  },
  transformUpsertMutationVariables: (variables) => {
    const packageFreeFor = {};
    variables.org.meta.grades.forEach((grade) => {
      packageFreeFor[grade] = variables["packageFreeFor" + grade];
    });
    const { meta = {} } = variables;
    if (!meta?.isLinearPackage) {
      delete meta?.linearPackage;
      delete meta?.isSinglePaymentLinearPackage;
    }
    return {
      ...variables,
      meta: { ...(meta || {}), packageGroupName: (meta.packageGroupName || "").trim() },
      homeOptionForCheckupPackageId:
        variables.sampleCollectionType === CheckupPackageSampleCollectionType.HOME_COLLECTION
          ? variables?.homeOptionForCheckupPackage?.id || null
          : null,
      orgId: variables?.org?.id,
      orgEntityId: variables?.orgEntity?.id,
      benefitId: variables?.benefit?.id,
      org: null,
      benefit: null,
      packageFreeFor,
      packageAvailableFor: variables.packageAvailableFor,
      taxRate: Number(variables.taxRate),
    };
  },
  transform: (n, write = false) => {
    const sampleCollectionMap = {
      [CheckupPackageSampleCollectionType.HOME_COLLECTION]: "Home",
      [CheckupPackageSampleCollectionType.INCLINIC]: "Inclinic",
      [CheckupPackageSampleCollectionType.ONSITE]: "Onsite",
    };
    const checkupTestComponentIds = n.checkupTestComponents.map((test) => parseInt(test?.node?.partnerComponentId));
    if (n.homeOptionForCheckupPackage) {
      n.homeOptionForCheckupPackage.name = n.homeOptionForCheckupPackage.packageName;
    }
    const novaProduct = {
      productName: n.novaProduct?.name,
      packageName: n.novaProduct?.name,
      productType: n.novaProduct?.type,
      productStatus: n.novaProduct?.status,
      netCost: n.novaProduct?.netCost,
      taxRate: n.novaProduct?.taxRate,
      taxType: n.novaProduct?.taxType,
    };
    const packageFreeFor = {};
    Object.keys(n.packageFreeFor).forEach((grade) => {
      packageFreeFor["packageFreeFor" + grade] = n?.packageFreeFor[grade];
    });
    return {
      ...n,
      collectionType: sampleCollectionMap[n.sampleCollectionType] || "",
      groupName: n.meta?.packageGroupName || "",
      checkupTestComponentIds,
      orgName: n?.org?.name,
      orgId: n?.org?.id,
      benefitId: n?.benefit?.id,
      homeOptionForCheckupPackageId: n?.homeOptionForCheckupPackage?.id,
      ...packageFreeFor,
      packageAvailableFor: n?.packageAvailableFor,
      ...novaProduct,
    };
  },
};
