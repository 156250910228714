import gql from "graphql-tag";
import { CXPodFragment } from "../fragments";

export default {
  upsertMutation: gql`
    mutation upsertCXPod($id: ID, $name: String!, $meta: JSONObject!) {
      upsertCXPod(input: { id: $id, name: $name, meta: $meta }) {
        pod {
          ...CXPod
        }
      }
    }
    ${CXPodFragment}
  `,
};
