import Vue from "vue";
import VueRouter from "vue-router";

import { store } from "../store";
import { OrgServicesOffered } from "../../common/enums";
import { shouldShowOnboardingFlow } from "../../utils/misc";
import routes from "./routes";

Vue.use(VueRouter);

// configure router
const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  linkActiveClass: "active",
  scrollBehavior(to, from) {
    // once persistent scroll behaviour is finalised from design, remove the if guards
    if (["dashboard", "topups", "claims", "team_fitness", "my_fitness"].includes(from.name)) {
      store.commit("setScrollPositions", { routeName: from.name, scrollPosition: Math.round(window.pageYOffset) });
    }
    if (from.name === "dashboard" && to.name === "dashboard_benefit") {
      window.scrollTo(0, store.state.scrollPositions[from.name]);
    } else if (
      !(["policy", "dashboard_benefit", "claim-process", "topups"].includes(from.name) && to.name === "dashboard") &&
      !(from.fullPath.includes("topup-purchase") && to.name === "topups") &&
      !(from.fullPath.includes("claim-details") && to.name === "claims") &&
      !(["org_challenge_details"].includes(from.name) && to.name === "team_fitness") &&
      !(["challenge_template_details", "user_challenge_details"].includes(from.name) && to.name === "my_fitness")
    ) {
      document.getElementById("page-top")?.scrollIntoView();
    } else if (to.name === "dashboard" && from.name === "dashboard_benefit") {
      // do nothing
    } else {
      window.scrollTo(0, store.state.scrollPositions[to.name]);
    }
  },
});

/*
 Check role and feature flag
 before forwarding each route
*/
router.beforeEach(async (to, from, next) => {
  if (to.path.startsWith("/login")) {
    next();
  }

  // if path is not login, load store values
  await loadLocalStoreValues();
  // if user has not given the consent/cookie permissions redirect to privacy settings page
  if (
    store.getters.isLoggedIn &&
    !store.getters.areUserPermissionsGranted &&
    !to.fullPath?.startsWith("/privacy-settings") &&
    !store.getters.isImposter
  ) {
    router.push({ name: "privacy-settings" });
  }

  // user needs to complete the onboarding flow to access the portal
  if (
    store.getters.isLoggedIn &&
    shouldShowOnboardingFlow(store?.state || {}) &&
    store.getters.areUserPermissionsGranted &&
    !to.fullPath?.startsWith("/user/get-started") &&
    !store.getters.isImposter
  ) {
    router.push({ name: "user_onboarding" });
  }

  // if hrms integration sync is in progress show a dialogue box first
  if (store.state?.hrmsSyncWarningModal?.modalRequired && from.name === "org_admin_hrms_integration_data") {
    store.commit("openHrmsSyncWarningModal", to);
    return false;
  }

  // if bulk upload is in progress show a dialogue box first
  if (store.state?.bulkUserOperationModal?.modalRequired && from.name === "org_admin_members_list") {
    store.commit("openBulkUserOperationWarningModal", to);
    return false;
  }

  if (store.state?.novaSideNavExpanded && !to.path.includes("org-admin")) {
    store.commit("toggleNovaSideNavState", false);
  }
  // check if user passes all the passed evaluation middlewares
  forwardOnPass([hasRequiredRole, hasRequiredFeatureFlag, areClaimsPagesAllowed], store.getters.isLoggedIn, to, next);
});

/*
  Save onLoginRedirect path
*/
function saveOnLoginRedirectPath(to) {
  store.commit("addRedirectUrl", to.fullPath);
}

/*
  Checks logged in user's role
  with the allowedRoles of the pat
*/
function hasRequiredRole(to, next) {
  return to.matched.every((route) => {
    // side nav or top nav
    setNavType(to);

    if (!route.meta.allowedRoles) return true;

    /*
      If user is not logged in
      don't throw them to unauthorized
      throw them to login page
    */
    if (
      (!store.getters.isLoggedIn || !store.state?.user?.roles) &&
      !(route.meta.allowedRoles.includes("*") || route.meta.allowedRoles.includes("guest"))
    ) {
      saveOnLoginRedirectPath(to);
      console.error("Logging out as unauthorized route accessed");
      next("/login/logout");
    }

    /*
     If user logs in and there're redirectTo set
    */
    if (store.getters.isLoggedIn && store.state.redirectTo) {
      next(useRedirectUrl());
    }

    let presentRoles = ["guest"];
    if (store.state?.user?.roles) {
      presentRoles = store.state.user.roles;
    }

    for (const role of route.meta.allowedRoles) {
      if (role === "*" || presentRoles.indexOf(role) !== -1) {
        return true;
      }
      if (role[0] === "!" && presentRoles.indexOf(role.substr(1)) !== -1) {
        break;
      }
    }
    return false;
  });
}

/*
  Checks if feature flag is enabled for org
  for the route
*/
function hasRequiredFeatureFlag(to) {
  return to.matched.every((route) => {
    if (!route.meta?.featureFlags) return true;
    for (const flag of route.meta.featureFlags) {
      if (!store.getters.getFeatureFlags[flag]) return false;
    }
    return true;
  });
}

// Checks if claims are relevant for the user's org
function areClaimsPagesAllowed(to) {
  return to.matched.every((route) => {
    if (
      route.meta?.disableForWellnessOnlyOrgs &&
      store.state?.user?.org?.meta?.servicesOffered === OrgServicesOffered.WELLNESS_ONLY
    ) {
      return false;
    }
    return true;
  });
}

/*
  Forward if condition fails or passed
*/
function forwardOnPass(evaluators, isLoggedIn, to, next) {
  for (const evaluate of evaluators) {
    const passed = evaluate(to, next);

    if (!passed) {
      if (isLoggedIn) next("/error/unauthorized");
      else next("/login");
      break;
    }
  }

  next();
}

/*
  set nav type based on the path
  for org-admin its sidenav
  and for others its top nav
*/
function setNavType(to) {
  if (to.fullPath.startsWith("/org-admin") || to.fullPath.startsWith("/admin")) {
    store.commit("setNavType", "side");
  } else {
    store.commit("setNavType", "top");
  }
}

// get and delete redirectTo from localStorage
function useRedirectUrl() {
  const redirectUrl = store.state.redirectTo;
  store.commit("removeRedirectUrl");

  return redirectUrl;
}

async function loadLocalStoreValues() {
  await store.dispatch("loadLocalStore");
  await store.dispatch("loadTopupLocalStore");
}

export default router;
