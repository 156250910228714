<template lang="pug">
multiselect.dropdown(select-label="" selected-label="" deselect-label="" v-model='value' :options="options" :multiple="true" track-by="name" :custom-label="customLabel" :close-on-select="false")
  template(v-slot:option="scope")
    .d-flex.align-items-center.dropdown-options
      input.mr-1(type="checkbox" :checked="isSelected(scope.option)" @focus.prevent="" :key="scope.option.library")
      span.font-md {{ scope.option.name }}
</template>

<script>
import Multiselect from "vue-multiselect";
import { abstractField } from "vue-form-generator";
export default {
  components: {
    Multiselect,
  },
  mixins: [abstractField],
  data() {
    return {
      options: [
        {
          language: "JavaScript",
          library: "Vue.js",
        },
        {
          language: "JavaScript",
          library: "Vue-Multiselect",
        },
        {
          language: "JavaScript",
          library: "Vuelidate",
        },
      ],
    };
  },
  computed: {
    fieldQueryVariables() {
      return this.schema?.queryVariables?.();
    },
  },
  async created() {
    await this.getSuggestions("", () => null);
  },
  methods: {
    async getSuggestions(query, loading) {
      loading(true);
      try {
        let variables = {
          query: query.trim(),
        };
        if (this.schema.queryVariables) {
          variables = { ...variables, ...this.fieldQueryVariables };
        }
        const { data } = await this.$apollo.query({
          query: this.schema.gqlQuery,
          variables,
        });
        if (this.schema.nodesFromData) {
          this.options = this.schema.nodesFromData(data);
        } else if (data[this.schema.queryName]?.edges) {
          this.options = data[this.schema.queryName].edges.map(({ node }) => node);
        } else {
          throw new Error("Please use nodesFromData() mapper for non-connection-of-edges style queries in id-selector");
        }
      } catch (err) {
        console.log(err);
      } finally {
        loading(false);
      }
    },
    customLabel(option) {
      return `${option.name}`;
    },
    isSelected(option) {
      return this.value?.some((op) => op.name === option.name);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../node_modules/vue-multiselect/dist/vue-multiselect.min.css";
</style>

<style lang="scss">
@import "@/assets/styles/_variables.scss";
.dropdown .multiselect__option--highlight {
  background-color: $malibu-100;
  color: $blackpearl;
}
.dropdown .multiselect__option {
  padding-top: 0.1rem;
  padding-bottom: 0.1rem;
  border-block-end: 1px solid $gray-300;
}
.dropdown .multiselect__tag {
  position: relative;
  display: inline-block;
  padding: 0.25rem 1.625rem 0.25rem 0.625rem;
  background-color: $gray-100;
  color: $blackpearl;
  margin-right: 0.625rem;
  border: 1px solid $gray-300;
  line-height: 1;
  border-radius: $border-radius-pill;
  margin-bottom: 0.5rem;
}
.dropdown .multiselect__tag-icon {
  background-color: $gray-100;
  border-radius: $border-radius-pill;
}
.dropdown .multiselect__tag-icon::after {
  color: $gray-600;
}
.dropdown .multiselect__tag-icon:hover {
  background-color: $gray-200;
}
.dropdown .multiselect__option--selected {
  background-color: $malibu-100;
}
</style>
