<template lang="pug">
div
  div.info-card-details.my-3(v-for="cardData of content")
    .d-flex.info-card.align-items-center.pt-3.pb-2.cursor-pointer.info-header.bg-mustard-200
      .details.text-gray-900.font-weight-semibold.font-md.mx-3
        h5 📎&nbsp;&nbsp;{{ cardData.title }}
    .info-card.info-body.px-3
      div(v-for="bodyContent of cardData.subContent")
        p
          h5.font-weight-semibold {{ bodyContent.subTitle }}
          span.text-gray-800 {{ bodyContent.subText }}

</template>

<script>
import NButton from "@/components/NovaButton.vue";
import NSidesheet from "@/components/NovaSideSheet";
import NIcon from "../../../../../components/NovaIcon.vue";
export default {
  name: "InfoCard",
  components: {
    NButton,
    NSidesheet,
    NIcon,
  },
  props: {
    content: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {};
  },
  computed: {},
  watch: {},
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
@import "@/assets/styles/mixins/_breakpoints.scss";
.info-card {
  background-color: white;
  border: 0.125rem solid $gray-300;
  border-radius: 0.375rem;
}
.info-header {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom: 0;
}
.info-body {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
</style>
