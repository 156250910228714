import gql from "graphql-tag";

export default {
  upsertSuperTopup: gql`
    mutation upsertSuperTopup(
      $prospectId: ID
      $formData: JSONObject
      $id: ID
      $startDate: String
      $documents: JSONObject
      $endDate: String
    ) {
      upsertSuperTopup(
        input: {
          prospectId: $prospectId
          formData: $formData
          id: $id
          startDate: $startDate
          endDate: $endDate
          documents: $documents
        }
      ) {
        pgOrderId
        novaOrderId
      }
    }
  `,
};
