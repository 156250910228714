<template lang="pug">
div
  new-version-strip
  dashboard-skeleton-loader
  router-view
  n-toast-wrapper
  bulk-user-operation-toaster
  vue-progress-bar
</template>

<script>
import { mapGetters } from "vuex";

import blitz from "blitzllama-js";
import VersioningModal from "../components/VersioningModal";
import BulkUserOperationToaster from "../components/BulkUserOperationToaster.vue";
import { apolloClient } from "./apollo";
import DashboardSkeletonLoader from "./pages/components/DashboardSkeletonLoader.vue";
import mobileApp from "./mobileApp";
import utils, { onWheelNumInputBlur, blitzInitializeUser } from "@/utils";

export default {
  components: {
    VersioningModal,
    BulkUserOperationToaster,
    DashboardSkeletonLoader,
  },
  data() {
    return {
      zoho: {},
    };
  },
  computed: {
    ...mapGetters(["isLoggedIn", "getFeatureFlags", "getBrandCustomizations"]),
  },
  async created() {
    if (navigator.onLine) {
      await apolloClient.clearStore();
    }
  },
  mounted() {
    window.$zoho = {};
    window.$zoho.salesiq = {
      widgetcode: process.env.VUE_APP_ZOHO_SALESIQ_WIDGET_CODE,
      values: {},
      ready: function () {},
      afterReady: function () {},
    };
    const d = document;
    const s = d.createElement("script");
    s.type = "text/javascript";
    s.id = "zsiqscript";
    s.defer = true;
    s.src = "https://salesiq.zoho.in/widget";
    window.document.body.append(s);

    const lottieScript = document.createElement("script");
    lottieScript.setAttribute("src", "https://cdnjs.cloudflare.com/ajax/libs/lottie-web/5.7.6/lottie.min.js");
    lottieScript.setAttribute(
      "integrity",
      "sha512-BB7rb8ZBAxtdJdB7nwDijJH9NC+648xSzviK9Itm+5APTtdpgKz1+82bDl4znz/FBhd0R7pJ/gQtomnMpZYzRw=="
    );
    lottieScript.setAttribute("crossorigin", "anonymous");
    lottieScript.defer = true;
    document.head.appendChild(lottieScript);

    const zsiqInterval = setInterval(() => {
      if (window.$zoho.salesiq.visitor) {
        utils.saveVisitorInfoZoho(this.$store.state.user);
        clearInterval(zsiqInterval);
      }
    }, 500);

    // initializing calendly
    const calendlyScript = document.createElement("script");
    calendlyScript.setAttribute("src", "https://calendly.com/assets/external/widget.js");
    calendlyScript.setAttribute("type", "text/javascript");
    calendlyScript.defer = true;
    document.head.appendChild(calendlyScript);

    const calendlyStyles = document.createElement("link");
    calendlyStyles.setAttribute("href", "https://calendly.com/assets/external/widget.css");
    calendlyStyles.setAttribute("rel", "stylesheet");
    document.head.appendChild(calendlyStyles);

    // to disable numeric input changes on scroll
    document.addEventListener("wheel", onWheelNumInputBlur);

    // blitzllama init
    blitz.init(process.env.VUE_APP_BLITZLLAMA_KEY);
    if (this.$store?.state?.user?.id && !this.$store?.state?.majorVersionMismatch) {
      blitzInitializeUser(this.$store.state.user);
    }
    const brandCustomizations = this.getFeatureFlags?.CUSTOM_STYLING ? this.getBrandCustomizations : null;
    mobileApp.send("USER_DATA", {
      user: {
        displayName: this.$store.state?.user?.displayName || null,
        email: this.$store.state?.user?.email || null,
        contactNumber: this.$store.state?.user?.meta?.contactNumber || null,
        brandCustomizations,
      },
    });
  },
  beforeDestroy() {
    document.removeEventListener("wheel", onWheelNumInputBlur);
  },
};
</script>

<style lang="scss">
@media only screen and (max-width: 555px) and (min-width: 100px) {
  body div.zsiq-mobhgt[embedtheme].siqembed {
    height: 90% !important;
  }
}
</style>
