import { store } from "@/portal/store";

export function loadScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
}
// call this function on button click
export async function displayRazorpay(order, onSuccessCallback) {
  const res = await loadScript("https://checkout.razorpay.com/v1/checkout.js");

  if (!res) {
    alert("Razorpay failed to load!!");
    return;
  }

  const options = {
    key: process.env.VUE_APP_RAZORPAY_KEY,
    currency: "INR",
    name: "Nova Benefits",
    description: order.description,
    image: "https://s3.ap-south-1.amazonaws.com/nova-iris-public.s3.novabenefits.com/nova-logo-square.png",
    order_id: order.pgOrderId,
    notes: order.notes,
    prefill: {
      contact: order.contactNumber,
    },
    method: { netbanking: true, card: true, wallet: true, upi: true, qr: false },
    theme: {
      color: "#0b3948",
    },
    handler: function (response) {
      onSuccessCallback(response);
      store.commit("endCheckupBookingProcess");
    },
    modal: {
      ondismiss: function () {
        store.commit("endCheckupBookingProcess");
      },
    },
  };
  const paymentObject = new window.Razorpay(options);
  paymentObject.open();
}
