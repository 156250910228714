<template lang="pug">
.booking-history-container.mt-3
  n-spinner(
    :active="isLoading",
    spinner="ring",
    size="50",
    duration="1")
  .col-12.p-0(v-if="userBookings.length > 0")
    booking-details-card(
      v-for="booking in userBookings",
      :key="booking.partnerBookingId",
      :bookingDetails="booking",
      @modal-closed="refetchBookings")
  .col-12.p-0(v-else)
    empty-state.border-1-dashed.rounded-lg(
      :imagePath="require(`@/assets/images/countdown.svg`)",
      message1="You have no past bookings",
      message2="The active/past bookings of you and your dependents will appear here.")
</template>

<script>
import moment from "moment";
import blitz from "blitzllama-js";
import resDefs from "./definitions";
import BookingDetailsCard from "./components/BookingDetailsCard.vue";
import { getBookingDetails, getProductDetails } from "./checkupUtils";
import EmptyState from "@/components/Cards/EmptyStates/EmptyState.vue";
export default {
  name: "BookingHistory",
  components: {
    EmptyState,
    BookingDetailsCard,
  },
  data() {
    const resName = "checkupBookings";
    return {
      resDef: resDefs[resName],
      pageSize: 10,
      currentPage: 1,
      userBookings: [],
      isLoading: true,
    };
  },
  async created() {
    this.$apollo.addSmartQuery("checkupBookings", {
      query: this.resDef.listQuery,
      variables: () => ({
        limit: this.pageSize,
        offset: (this.currentPage - 1) * this.pageSize,
        filter: {
          userId: this.$route.params?.bookingUser?.id || this.$store.state?.user?.id,
        },
      }),
      update: async (data) => {
        this.isLoading = false;
        this.userAppointments = [];
        await this.fillUserAppointmentsData(data);
        return [];
      },
    });
    if (this.$route.query.bookingComplete) {
      this.refetchBookings();
      blitz.triggerEvent("ahc_booking_completion");
      this.$router.replace({ query: null });
    }
  },
  mounted() {
    this.refetchBookings();
  },
  methods: {
    refetchBookings() {
      this.$apollo.queries.checkupBookings.refetch();
    },
    async fillUserAppointmentsData(data) {
      const userBookings = new Set();
      const { edges } = data.checkupBookings;
      for (let index = 0; index < edges.length; index++) {
        const node = edges[index].node;
        const userBooking = getBookingDetails(node);
        const productDetails = await getProductDetails(node, userBooking.checkupPackage);
        userBookings.add({ ...userBooking, product: productDetails });
      }

      this.userBookings = Array.from(userBookings);
      const statusOrder = { Upcoming: 1, Completed: 2, Cancelled: 3 };
      this.userBookings.sort((a, b) => {
        const statusComparison = statusOrder[a.bookingStatus] - statusOrder[b.bookingStatus];
        if (statusComparison === 0) {
          return moment(b.latestScheduledAt) - moment(a.latestScheduledAt);
        }
        return statusComparison;
      });
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/_variables.scss";
</style>
