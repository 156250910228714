import gql from "graphql-tag";

export const teamFragment = gql`
  fragment Team on Team {
    id
    name
    status
    createdAt
    updatedAt
  }
`;
