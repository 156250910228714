<template lang="pug">
b-card(
  :class="[ \
    'text-decoration-none', \
    'rounded', \
    'pt-1', \
    'px-2', \
    'd-flex', \
    'flex-row', \
    'justify-content-between', \
    'align-items-center', \
    'w-100', \
    'cursor-pointer', \
    `${state?`${$options.stateStyleMap[state]}-card`:''}` \
  ]"
)
  .row.align-center
    .col-md-7.col-sm-12.pt-3.px-0
      div
        slot(name="header")
        slot(name="body")
    .offset-md-1.col-md-4.col-sm-12.p-0.align-self-center
      slot(name="button")
    .input-sub-text.text-left.pt-2(v-if="state", :class="state ? $options.stateStyleMap[state] : ''")
      n-icon.pr-1.sub-text-icon.align-middle(name="error", :size="1")
      span.align-middle {{ validationMessage }}
</template>

<script>
import NButton from "@/components/NovaButton.vue";
export default {
  name: "CardButton",
  components: {
    NButton,
  },
  props: {
    state: {
      type: String,
      default: "error",
    },
    validationMessage: {
      type: String,
      default: null,
    },
  },
  created() {
    this.$options.stateStyleMap = {
      "error": "danger",
    };
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
.danger {
  background-color: $red-100;
  color: $red-500;
}
.danger-card {
  border: 2px solid $red-300 !important;
  background-color: $red-100;
  @extend .danger;
}
.b-card {
  border: 1px solid #e5ecfb;
  box-shadow: 0px 2px 0px rgba(0.04, 0.04, 0.04, 0.04);
}
</style>
