<template lang="pug">
div(:class="{ 'pb-8': isMobileBrowser() || isApp() }")
  form-card(
    :title="contactDetailsStage.title",
    :schemaPart1="contactDetailsStage.schemaTop",
    :schemaPart2="contactDetailsStage.schemaBottom"
    :model="superTopupProspectState.contactDetail",
     @validated="handleValidation"
  )
</template>

<script>
import FormCard from "./FormCard.vue";
import { contactDetailsStage } from "./../definitions";
import mixin from "./../mixin";
export default {
  name: "ContactDetails",
  components: {
    FormCard,
  },
  mixins: [mixin],
  data() {
    return {
      contactDetailsStage,
      validationResults: [],
      resolveValidation: null,
    };
  },
  watch: {
    "superTopupProspectState.contactDetail": {
      deep: true,
      handler(newVal, oldVal) {
        if (newVal && newVal !== oldVal) {
          if (!this.superTopupProspectState.nomineeContactInfo) {
            this.$set(this.superTopupProspectState, "contactDetail", {});
          }
          this.$store.commit("updateSuperTopupProspectStateMeta", { contactDetail: newVal });
        }
      },
    },
  },
  created() {},
  mounted() {
    if (!this.superTopupProspectState.contactDetail["work-email"]) {
      this.superTopupProspectState.contactDetail["work-email"] = this.$store.state.user.email;
    }
  },
  methods: {
    async validateForms() {
      return new Promise((resolve) => {
        this.$root.$emit("validateForm");
        this.resolveValidation = resolve;
      });
    },
    handleValidation(isValid) {
      this.validationResults.push(isValid);
      if (this.validationResults.length === 1) {
        const allValid = this.validationResults.every((result) => result);
        this.$emit("validated", allValid);
        this.resolveValidation(allValid);
        this.validationResults = [];
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
</style>
