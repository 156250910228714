<template lang="pug">
.package-tests-count-chip(@click="stopPropagation")
  n-chip.chip-border.bg-white.font-sm.font-weight-semibold.shadow-xs(
    chipIcon="medical-tests",
    pill,
    variant="light",
    :iconWhite="false",
    iconColor="red-600",
    :smallChip="true", 
    :iconSize="1",
    v-b-toggle="sideSheetId")
    span.text-gray-700.font-sm.font-weight-semibold.pr-1 Upto {{testCount}} Tests included
  package-details-sidesheet.package-sidesheet-style(
      :checkupPackage="checkupPackage",
      title="Package details for",
      :shouldScrollToTests="true",
      :viewOnly="viewOnly",
      :customSideSheetId="customSideSheetId")
</template>

<script>
import PackageDetailsSidesheet from "./PackageDetailsSidesheet.vue";
import NChip from "@/components/NovaChip.vue";
export default {
  name: "PackageTestsCountChip",
  components: {
    NChip,
    PackageDetailsSidesheet,
  },
  props: {
    checkupPackage: {
      type: Object,
      default: null,
    },
    testCount: {
      type: Number,
      default: null,
    },
    customSideSheetId: {
      type: String,
      default: null,
    },
    viewOnly: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    sideSheetId() {
      if (this.customSideSheetId) {
        return this.customSideSheetId;
      }
      return `sidesheet-tests-${this.checkupPackage.id}`;
    },
  },
  methods: {
    stopPropagation(event) {
      event.stopPropagation();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
.chip-border {
  border: 1px solid;
  color: $gray-200;
}
</style>
