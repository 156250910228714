<template lang="pug">
.checkups-page-container.p-0.mt-3.mt-md-0.bg-white(ref="checkupsPage")
  #hero-section-ahc.hero-section.w-100.d-none.d-md-block
    template(v-if="isCheckupAdmin")
      .d-flex.align-items-center
        n-icon.p-1(name="user", variant="white")
        .font-md.font-weight-semibold.text-white {{ bookingUser && bookingUser.email ? bookingUser.email : "" }}
  .checkups-container.mb-6.mb-md-0(:class="(isApp || isMobileBrowser) ? '': 'container'")
    .row.px-0.mx-0.justify-content-center
      .col-md-8.px-0
        .checkup-main-container.mt-3
          n-spinner(:active="isLoading")
          checkup-booking.checkup-booking.p-3.rounded(v-if="currentPage === 'checkup-booking'")
          booking-form.mx-3.mb-3.mt-5(v-else-if="currentPage === CheckupRoutes.BOOKING_FORM",
            ref="bookingForm",
            :user="bookingUser",
            :isProceedButtonDisabled="isProceedButtonDisabled",
            @skip-discard-modal="isDiscardModalShown=true",
            @sync-ahc-prospect-state="synchronizeProspectStateAndEvent")
  checkup-discard-modal(:redirectTo="modalRedirect", @close="isDiscardModalShown=!isDiscardModalShown")
</template>

<script>
import { mapState, mapGetters } from "vuex";
import { cloneDeep } from "lodash";
import resDefs from "../admin/definitions";
import mobileApp from "../../mobileApp";
import { CheckupPackageSampleCollectionType } from "../../../common/enums";
import CheckupBooking from "./CheckupBooking.vue";
import BookingForm from "./BookingForm.vue";
import CheckupDiscardModal from "./components/CheckupDiscardModal.vue";
import * as checkupUtils from "./checkupUtils";
import utils from "@/utils";
import { CheckupRoutes } from "@/common/enums";

export default {
  name: "CheckupsBase",
  components: {
    CheckupBooking,
    BookingForm,
    CheckupDiscardModal,
  },
  beforeRouteLeave(to, from, next) {
    // handles exits from the checkup pages
    this.handleRouteChange(to, from, next);
  },
  beforeRouteUpdate(to, from, next) {
    // handles exits to the schedule/history page
    this.handleRouteChange(to, from, next);
  },
  data() {
    return {
      isLoading: false,
      isDiscardModalShown: false,
      modalRedirect: "/dashboard",
    };
  },

  computed: {
    ...mapState(["user"]),
    ...mapGetters(["ahcProspectState", "getFeatureFlags", "getBrandCustomizations"]),
    isApp() {
      return mobileApp.isApp;
    },
    isMobileBrowser() {
      return (utils.mobileCheck() || utils.tabCheck()) && !this.isApp;
    },
    currentPage() {
      // Booking form will have all the steps of the booking for a package
      if (this.$route.path?.includes(CheckupRoutes.BOOKING_FORM)) return CheckupRoutes.BOOKING_FORM;
      // CheckupBooking should have schedule booking and booking history tabs
      return "checkup-booking";
    },
    CheckupRoutes: () => CheckupRoutes,
    getSelectedAddress() {
      return this.ahcProspectState?.selectedAddress || {};
    },
    // TODO: Rmove it as likely not being used now with step as data object
    isProceedButtonDisabled() {
      // TODo: can be moved to individual pages

      const isScheduleBookingScreen = this.$route.path?.includes(CheckupRoutes.SCHEDULE_BOOKING);
      const isSampleCollectionScreen = this.$route.path?.includes(CheckupRoutes.SAMPLE_COLLECTION);
      const isHouseNoPresent = this.ahcProspectState.selectedAddress.address_line_one;
      const isHomeCollection =
        this.ahcProspectState?.selectedPackage?.sampleCollectionType ===
        CheckupPackageSampleCollectionType.HOME_COLLECTION;
      const isSampleCollectionSelected = this.ahcProspectState?.selectedPackage?.sampleCollectionType;
      const isAddressSelectionScreen = this.$route.path?.includes(CheckupRoutes.ADDRESS_SELECTION);
      const isSlotSelected = this.ahcProspectState.scheduledAt;
      const isAddressSelected = this.ahcProspectState?.isAddressSelected;

      return (
        (isScheduleBookingScreen && !isAddressSelected) ||
        (isSampleCollectionScreen && !isSampleCollectionSelected) ||
        (isAddressSelectionScreen && isHomeCollection && !isHouseNoPresent) ||
        (isAddressSelectionScreen && !isSlotSelected)
      );
    },
    currentStep() {
      return this.ahcProspectState?.currentStep;
    },
    checkupPackageId() {
      return this.ahcProspectState?.selectedPackage?.id || this.$route.params?.checkupPackageId;
    },
    isCheckupAdmin() {
      if (!this.user?.roles) return false;
      return this.user.roles.includes("checkup_admin");
    },
    bookingUser() {
      if (this.isCheckupAdmin) return this.$route.params?.bookingUser;
      return this.user;
    },
    bookingUserBenefit() {
      return this.$route.params?.bookingUserBenefit;
    },
    brandCustomizations() {
      return this.getBrandCustomizations;
    },
  },
  watch: {
    "$route.params": {
      handler: function (newParams, oldParams) {
        if (this.isCheckupAdmin && !newParams?.bookingUser) {
          window.location.reload();
        }
      },
      deep: true,
    },
  },
  async created() {
    if (this.isCheckupAdmin && !this.bookingUser) {
      this.$router.push({ name: "external-checkup-booking" });
    }

    window.addEventListener("beforeunload", this.beforeUnload);
    if (this.$route.fullPath?.includes(CheckupRoutes.BOOKING_FORM)) {
      await this.fetchAhcProspectState({});
    } else if (
      this.$route.fullPath?.includes(CheckupRoutes.BOOKING_FORM) &&
      Object.keys(this.getSelectedAddress).length === 0
    ) {
      this.isDiscardModalShown = true;
      this.$router.push({
        name: "scheduleBooking",
        params: {
          benefitId: this.$route.params.benefitId,
          bookingUser: this.$route.params.bookingUser,
        },
      });
    }
  },
  mounted() {
    if (this.isCheckupAdmin && !this.bookingUser?.id) {
      this.$router.push({ name: "external-checkup-booking" });
    }

    window.scrollTo(0, 0);
    const el = document.getElementById("main-panel");
    el.classList.add("bg-white");
  },
  beforeDestroy() {
    window.removeEventListener("beforeunload", this.beforeUnload);
  },
  methods: {
    beforeUnload(e) {
      e.preventDefault();
      const confirmationMessage = "Are you sure you want to leave this page. All unsaved changes will be lost?";
      e.returnValue = confirmationMessage;
      const el = document.getElementById("main-panel");
      el.classList.add("bg-stone");
    },
    handleRouteChange(to, from, next) {
      const exceptionalRoutes = ["bookingForm", "login", "bookingHistory", "payment_check"];
      if (from?.name === "bookingForm" && !exceptionalRoutes.includes(to?.name)) {
        const previousStageMap = {
          [CheckupRoutes.ADDRESS_SELECTION]: CheckupRoutes.DEPENDENT_SELECTION,
          [CheckupRoutes.PROVIDER_SELECTION]: CheckupRoutes.ADDRESS_SELECTION,
          [CheckupRoutes.CHECKOUT]: CheckupRoutes.PROVIDER_SELECTION,
        };

        if (this.currentStep === this.CheckupRoutes.DEPENDENT_SELECTION) {
          this.$store.commit("resetAhcProspectState");
          next();
        } else {
          const previousStage = previousStageMap[this.currentStep];
          const prospectState = { currentStep: previousStage };
          if (this.currentStep === this.CheckupRoutes.PROVIDER_SELECTION) {
            prospectState.selectedDateTimeSlot = {};
            prospectState.selectedProvider = {};
          }
          window.scrollTo({ top: 0, behavior: "smooth" });
          this.$store.commit("updateAhcProspectStateMeta", prospectState);
          this.$router.go(1);
        }
      } else if (to?.name === "bookingForm") {
        next();
      } else {
        this.$store.commit("resetAhcProspectState");
        next();
      }
    },
    async fetchAhcProspectState() {
      this.$store.commit("resetAhcProspectState");
      if (this.$route.params.ahcNanoId) {
        this.isLoading = true;
        const ahcProspectId = await utils.fromNanoId(this, this.$route.params.ahcNanoId);
        const { data } = await this.$apollo.query({
          query: resDefs.prospects.singleQuery,
          variables: { id: ahcProspectId },
        });

        // push to booking screen, if we dont find checkpackages in prospect state
        if (!data?.node.meta?.ahcProspectState?.selectedGroupPackage?.checkupPackages?.length) {
          this.isLoading = false;
          this.$router.push({
            name: "scheduleBooking",
            params: {
              benefitId: this.$route.params.benefitId,
            },
          });
        }
        const populatedAHCData = this.populateAHCData({ ...(data?.node || {}) });
        this.$store.commit("saveAhcProspectState", populatedAHCData);
        this.isLoading = false;
      } else {
        this.$router.push({
          name: "scheduleBooking",
          params: {
            benefitId: this.$route.params.benefitId,
          },
        });
      }
    },
    async synchronizeProspectStateAndEvent(event) {
      if (this.$store.state.ahcProspectState?.id) {
        const sanitizedAHCData = this.sanitizeAHCData();
        await this.$apollo.mutate({
          mutation: resDefs.prospects.upsertMutation,
          variables: sanitizedAHCData,
        });
        if (!event?.skipProspectEvent) {
          const prospectEvent = checkupUtils.getProspectEventData(event.currentStep, this.ahcProspectState);
          await this.$apollo.mutate({
            mutation: resDefs.prospects.eventMutation,
            variables: { prospectId: this.$store.state.ahcProspectState?.id, ...prospectEvent },
          });
        }
      }
    },
    sanitizeAHCData() {
      const ahcData = cloneDeep(this.$store.state?.ahcProspectState || {});
      const { ahcProspectState } = ahcData?.meta || {};
      if (ahcProspectState?.selectedMembers?.length) {
        ahcProspectState.selectedMembers = ahcProspectState.selectedMembers.map(({ checkupPackage, ...others }) => ({
          ...others,
          checkupPackageId: checkupPackage.id,
        }));
      }
      return ahcData;
    },
    populateAHCData(ahcData) {
      const { ahcProspectState = {} } = ahcData?.meta || {};
      const { selectedGroupPackage = {}, selectedMembers = [] } = ahcProspectState;
      if (selectedMembers?.length && selectedGroupPackage?.checkupPackages?.length) {
        ahcProspectState.selectedMembers = selectedMembers.map((member) => ({
          ...member,
          checkupPackage: selectedGroupPackage.checkupPackages.find(({ id }) => id === member.checkupPackageId),
        }));
      }
      return ahcData;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
@import "@/assets/styles/mixins/_breakpoints.scss";

.checkups-page-container {
  .hero-section {
    @include media-breakpoint-up(md) {
      position: absolute;
      top: 4rem;
      height: 16rem;
    }
  }

  .checkups-container {
    @include media-breakpoint-down(sm) {
      margin-top: -2rem;
    }
  }

  .checkup-main-container {
    background-color: $white;
    min-height: fit-content;
    @include media-breakpoint-down(sm) {
      width: 100%;
    }
  }

  .checkup-details-container {
    background-color: $white;
    box-sizing: border-box;
    position: relative;
    top: 0;
    overflow: hidden;
    width: 100%;
  }
  .bottom-row {
    position: absolute;
    bottom: 0;
    border: 2px solid black;
  }
  .checkup-booking {
    @include media-breakpoint-up(md) {
      box-shadow: $box-shadow-sm;
    }
  }
}
</style>
