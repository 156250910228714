<template lang="pug">
  n-sidesheet(:ssId="id", :headerName="`Comments`", :centeredTabs="true", :showHeaderIcon="true", headerIcon="messaging")
    template(v-slot:tabs)
      template(v-for="item in items")
        .details-card.my-3.mx-1.p-3.shadow-sm
          .d-flex.flex-column
            div.my-1.d-flex.flex-column
              span.font-lg.font-weight-semibold.text-gray-900 {{ item.user.name }}
              span.font-md.font-weight-medium.my-1.text-gray-800 {{ getCommentersRole(item) }}
            .d-flex.justify-content-between.align-items-left.py-3.flex-column
              span.font-md.font-weight-medium.mb-3.text-gray-900 {{ item.content }}
              span.font-sm.font-weight-medium.text-gray-800 {{ item.createdAt }}
    template(v-slot:footer)
      div.py-4.bg-white.px-3.comments-footer
        n-text-area.font-xs.text-area.mr-3(
          v-model="content",
          :rows="1",
          :maxLength="1000",
          :maxRows="4",
          :cols="50",
          :dynamic-height="true",
          fontSize="md"
        )
        n-button.comment-submit(
          type="button",
          buttonText="Add Comment",
          variant="primary",
          @click="addComment()")

</template>

<script>
import EmptyStatesEndorsements from "@/components/Cards/EmptyStates/Endorsements.vue";
import NSidesheet from "@/components/NovaSideSheet";
import NChip from "@/components/NovaChip.vue";
import NButton from "@/components/NovaButton.vue";
import adminDefs from "@/portal/pages/admin/definitions";
import NTextArea from "@/components/NovaTextArea.vue";
import { PodMemberRoles } from "../../../../common/enums/podMemberRoles.enum";

export default {
  name: "CommentsSidesheet",
  components: {
    EmptyStatesEndorsements,
    NSidesheet,
    NChip,
    NButton,
    NTextArea,
  },
  props: {
    id: {
      type: String,
      default: "",
    },
    items: {
      type: Array,
      default: () => [],
    },
    batchId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      content: "",
    };
  },
  watch: {},
  methods: {
    async addComment() {
      try {
        const result = await this.$apollo.mutate({
          mutation: adminDefs.comments.upsertComment,
          variables: {
            content: this.content,
            entityId: this.batchId,
            userId: this.$store.state?.user?.id,
          },
        });
        if (!result?.data?.upsertComment?.comment?.status) {
          throw new Error("Could not add comment at the moment");
        }
        this.$store.commit("addToast", {
          variant: "success",
          message: "Successfully added comment",
        });
        this.content = "";
        await this.refetchBatchComments();
      } catch (err) {
        console.error(err);
        this.$store.commit("addToast", {
          variant: "danger",
          message: "Error occurred while adding a comment.",
        });
      }
    },
    refetchBatchComments() {
      this.$emit("refetchComments");
    },
    getCommentersRole(item) {
      return PodMemberRoles[item?.role] || PodMemberRoles.EA;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
@import "@/assets/styles/mixins/_breakpoints.scss";

.details-card {
  background: $white;
  border-radius: $border-radius-sm;
  border: 1px solid $gray-300;
}
.comments-footer {
  display: flex;
  align-items: end;
  .text-area {
    flex: 2;
  }
  .comment-submit {
    align-items: center;
    display: flex;
    max-height: 42px;
  }
}
</style>
