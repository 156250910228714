<template lang="pug">
  .mb-3.mt-0
    .d-flex.mb-4.align-items-center
      b-form-input#document-url(type="url", :value="fileUrl", @input="updateValue('fileUrl', $event.target.value)", readonly)
      n-button.w-50.mx-2(type="button", buttonText="Upload Document", @click="uploadDocHandler", :disabled="schema.disabled")
      input.d-none(ref="uploadDoc", type="file", :accept="accept", @change="handleFileUpload")
      n-icon.bg-gray-100.text-gray-900.border.rounded.p-2.mx-1.cursor-pointer(
        :class="fileUrl ? 'icon-active' : 'icon-inactive' ", 
        name="download", :size="1.25", 
        @click="downloadFile(schema.action, fileUrl)",)
    .text-center(v-if="isLoading")
      b-spinner(small)
      div Uploading Document Please Wait...
</template>

<script>
import { abstractField } from "vue-form-generator";
import { FileAction } from "../../common/enums";
import NButton from "@/components/NovaButton.vue";
import { uploadFile, getFileSignedUrl } from "@/common/queriesAndMutations/file";
export default {
  components: {
    NButton,
  },
  mixins: [abstractField],
  data() {
    return {
      isLoading: false,
      accept: "*",
    };
  },
  computed: {
    fileUrl() {
      return this.value?.fileUrl || "";
    },
  },
  mounted() {
    if (this.schema?.action === FileAction.InsurerTpaHandbook) {
      this.accept = "application/pdf";
    }
  },
  methods: {
    uploadDocHandler() {
      this.$refs.uploadDoc.click();
    },
    async downloadFile(action, url) {
      const res = await getFileSignedUrl(action, url);
      url = res.data.getFileSignedUrl;
      window.open(url);
    },
    async handleFileUpload(e) {
      const inputArgs = {
        action: this.schema.action,
        meta: {
          contentType: e.target?.files[0]?.type,
          folderName: this.schema.folderName,
        },
      };
      this.isLoading = true;
      try {
        const file = await e.target.files[0];
        if (file) {
          const result = await uploadFile({
            file,
            ...inputArgs,
          });
          this.value = {
            fileUrl: result.data.upsertFile.file.url,
            fileId: result.data.upsertFile.file?.id,
          };
          this.isLoading = false;
          this.$store.commit("addToast", {
            variant: "success",
            message: "Successfully uploaded.",
          });
        }
      } catch (e) {
        this.isLoading = false;
        this.$store.commit("addToast", {
          variant: "danger",
          message: "Error in file upload",
        });
        console.log(e);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.icon-inactive {
  pointer-events: none;
  opacity: 0.6;
}
.icon-active {
  pointer-events: all;
  opacity: 1;
}
</style>
