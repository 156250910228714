<template lang="pug">
.d-flex.mb-2.date-range.position-relative
  combo-datepicker.from.w-50(
    :max="schema.max",
    :min="schema.min",
    :disabled="schema.disabled",
    :readonly="schema.readonly",
    :required="schema.required",
    :subText="schema.subText",
    placeholder="From (DD/MM/YYYY)",
    v-model="range.startDate",
    :data-cy="schema.dataCy")
  .arrow.rounded-circle.bg-gray-200.d-flex.justify-content-center.align-items-center.py-0
    .font-weight-medium.text-gray-600 ->
  combo-datepicker.to.w-50(
    :max="schema.max",
    :min="schema.min",
    :disabled="schema.disabled",
    :readonly="schema.readonly",
    :required="schema.required",
    :subText="schema.subText",
    placeholder="To (DD/MM/YYYY)",
    v-model="range.endDate",
    :data-cy="schema.dataCy")
</template>

<script>
import { abstractField } from "vue-form-generator";

import comboDatepicker from "./comboDateTimepicker.vue";

export default {
  components: {
    comboDatepicker,
  },
  mixins: [abstractField],
  data() {
    return {
      range: {
        startDate: null,
        endDate: null,
      },
    };
  },
  watch: {
    "range.startDate": function () {
      this.value = this.range;
    },
    "range.endDate": function () {
      this.value = this.range;
    },
    value() {
      if (!this.value) {
        this.range = {
          startDate: null,
          endDate: null,
        };
      }
    },
  },
};
</script>

<style lang="scss">
.date-range {
  .from {
    .btn {
      border-right-width: 1px;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
  .to {
    input {
      border-left-width: 1px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
  .arrow {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 101;
  }
}
</style>
