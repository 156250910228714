import gql from "graphql-tag";
import { challengeTemplateFragment } from "./challenges";
import { dependentFragment } from "./dependent";
import { strapiContentFragment } from "./strapi";
import { userBenefitFragmentWithoutContent } from "./userBenefit";
import { userChangeFragment } from "./userChange";
import { userAddressFragment } from "./userAddress";

export const userResourceListFragment = gql`
  fragment User on User {
    id
    email
    name
    gender
    meta
    status
    insuranceStatus
    isMidTerm
    org {
      id
      name
      orgEntities {
        id
        name
      }
    }
    orgEntity {
      id
      name
    }
    stats {
      linkedPoliciesCount
      linkedBenefitsCount
      linkedDependentsCount
      isEcardPresent
      simplifiedStatus
    }
    restrictDependentAddition
  }
`;

export const userFragment = gql`
  fragment User on User {
    id
    email
    name
    displayName
    gender
    dob
    isMidTerm
    meta
    status
    insuranceStatus
    roles
    photoFile {
      id
      name
      url
      meta
    }
    org {
      id
      name
      status
      featureFlags
      brandCustomizations
      customDependentValidationParams
      meta
      preferences
    }
    orgEntity {
      id
      name
      status
      meta
    }
    userAddresses {
      ...UserAddress
    }
    dependents {
      ...Dependent
    }
    benefits {
      ...UserBenefitEdgeWithoutContent
    }
    userChanges {
      ...UserChange
    }
    ngageUserDetail {
      id
      meta
      deviceIntegrationProvider
      updatedAt
    }
    doesActiveStepCountChallengeExist
    inviteLogs
  }
  ${dependentFragment}
  ${userBenefitFragmentWithoutContent}
  ${userChangeFragment}
  ${userAddressFragment}
`;

export const userIdSelectorFragment = gql`
  fragment User on User {
    id
    name
    email
  }
`;

export const userWithChallengesFragment = gql`
  fragment UserWithChallenges on User {
    id
    email
    name
    displayName
    gender
    dob
    meta
    status
    roles
    photoFile {
      id
      name
      url
      meta
    }
    ngageUserDetail {
      id
      meta
      deviceIntegrationProvider
      updatedAt
    }
    doesActiveStepCountChallengeExist
    org {
      id
      name
      featureFlags
      orgEntities {
        id
        name
      }
      orgChallenges {
        id
        challengeTemplate {
          ...ChallengeTemplate
          challengeContent {
            ...StrapiContent
          }
        }
        org {
          id
        }
        user {
          id
          displayName
          photoFile {
            id
            meta
          }
        }
        parentChallenge {
          id
        }
        goalValue
        currentTotal
        startDate
        startTime
        expiryDate
        expiryTime
        challengeStatus
        points
        userContributions {
          id
          currentTotal
          user {
            id
            name
            photoFile {
              id
              url
            }
          }
        }
        meta
        updatedAt
      }
      weeksUserPoints {
        name
        points
        photoFile {
          id
          url
        }
      }
      monthsUserPoints {
        name
        points
        photoFile {
          id
          url
        }
      }
      allTimeUserPoints {
        name
        points
        photoFile {
          id
          url
        }
      }
    }
    userChallenges {
      id
      challengeTemplate {
        ...ChallengeTemplate
        challengeContent {
          ...StrapiContent
        }
      }
      user {
        id
        name
      }
      goalValue
      currentTotal
      startDate
      startTime
      expiryDate
      expiryTime
      challengeStatus
      points
      meta
      updatedAt
    }
    enrollableChallenges {
      id
      name
      duration
      baseGoalValue
      challengeContent {
        ...StrapiContent
      }
      activityTypes
      points
    }
  }
  ${challengeTemplateFragment}
  ${strapiContentFragment}
`;

export const userByEmployeeIdOrEmailFragment = gql`
  fragment UserByEmployeeIdOrEmail on User {
    email
    id
    gender
    name
    meta
    dob
    status
  }
`;
