<template lang="pug">
.d-flex.position-relative.align-items-center
  n-icon.d-none.d-md-inline-block.text-gray-900.rounded-circle.bg-white.scroll-btn.cursor-pointer(name="chevron-left", :size="1.5", @click="scrollLeft", v-if="showLeftScrollButton")
  .date-cards-wrapper.d-flex.position-relative(
    ref="dateCardsWrapper",
    @scroll="handleScroll",
    :class="fadeClass")
    .date-card.d-inline-block.position-relative.shadow-xs(
      v-for="date in dates",
      :key="date.id",
      @click="selectDate(date)",
      :class="{ 'date-card-selected': date.selected, 'date-card-blocked': !date.available }")
      .card-content.font-weight-semibold
        span.m-0.text-capitalize.font-xs(:class="date.available ? 'text-gray-800' : 'text-gray-600'") {{ date.dayOfWeek }}
        span.m-0.date-text.pt-1(:class="date.available ? 'text-gray-800' : 'text-gray-600'") {{ date.date }}
  n-icon.d-none.d-md-inline-block.text-gray-900.rounded-circle.bg-white.scroll-btn.cursor-pointer(name="chevron-right", :size="1.5", @click="scrollRight", v-if="showRightScrollButton")
</template>

<script>
import moment from "moment";
export default {
  props: {
    numDaysToCover: {
      type: Number,
      default: 30,
    },
    availableDates: {
      type: Array,
      default: () => [],
    },
    value: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      selectedMonthYear: "",
      showLeftScrollButton: false,
      showRightScrollButton: true,
      fadeClass: "",
    };
  },
  computed: {
    selectedDate: {
      get() {
        if (this.dates?.length && this.value) {
          return this.dates.find((date) => moment(date.fullDate).isSame(this.value));
        }
        return {};
      },
      set(value) {
        this.$emit("input", value.fullDate);
      },
    },
    dates() {
      const possibleDates = [];
      const today = moment();
      for (let i = 0; i < this.numDaysToCover; i++) {
        const currentDate = today.clone().add(i, "days");
        const isAvailable = this.availableDates.includes(currentDate.format("YYYY-MM-DD"));
        const fullDate = currentDate.format("YYYY-MM-DD");
        possibleDates.push({
          id: i,
          date: currentDate.date(),
          dayOfWeek: currentDate.format("ddd").toUpperCase(),
          fullDate,
          selected: moment(fullDate).isSame(moment(this.value)),
          available: isAvailable,
        });
      }
      return possibleDates;
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.handleScroll();
    });
  },
  methods: {
    scrollLeft() {
      this.$refs.dateCardsWrapper.scrollBy({
        left: -300,
        behavior: "smooth",
      });
    },
    scrollRight() {
      this.$refs.dateCardsWrapper.scrollBy({
        left: 300,
        behavior: "smooth",
      });
    },
    handleScroll() {
      const wrapper = this.$refs.dateCardsWrapper;
      const maxScrollLeft = wrapper.scrollWidth - wrapper.clientWidth;

      this.showLeftScrollButton = wrapper.scrollLeft > 0;
      this.showRightScrollButton = wrapper.scrollLeft < maxScrollLeft;
      this.fadeClass =
        this.showLeftScrollButton && this.showRightScrollButton
          ? "faded-left-right"
          : this.showLeftScrollButton
          ? "faded-left-only"
          : this.showRightScrollButton
          ? "faded-right-only"
          : "";
    },
    selectDate(date) {
      if (date.available) this.selectedDate = { ...date };
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
@import "@/assets/styles/mixins/_breakpoints.scss";

.scroll-btn {
  border: 2px solid $gray-200;
}

.date-cards-wrapper {
  overflow-x: auto;
  white-space: nowrap;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.date-cards-wrapper::-webkit-scrollbar {
  display: none;
}

.date-cards-wrapper.faded-left-only {
  mask-image: linear-gradient(to right, transparent 0%, black 5%) !important;
  -webkit-mask-image: linear-gradient(to right, transparent 0%, black 5%) !important;
}

.date-cards-wrapper.faded-right-only {
  -webkit-mask-image: linear-gradient(to right, black 95%, transparent 100%) !important;
  mask-image: linear-gradient(to right, black 95%, transparent 100%) !important;
}
.date-cards-wrapper.faded-left-right {
  mask-image: linear-gradient(to right, transparent 0%, black 5%, black 95%, transparent 100%);
  -webkit-mask-image: linear-gradient(to right, transparent 0%, black 5%, black 95%, transparent 100%);
}

.left-scroll-button,
.right-scroll-button {
  position: absolute;
  z-index: 1;
  background: $white;
  border: none;
  cursor: pointer;
}

.left-scroll-button {
  left: 0;
}

.right-scroll-button {
  right: 0;
}

.date-card {
  box-sizing: border-box;
  border: 1px solid $gray-300;
  border-radius: 6px;
  margin: 0 3px;
  cursor: pointer;
  background-color: $white;
  text-align: center;

  .card-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0.75rem;
    height: 4rem !important;
    width: 4rem !important;
    justify-content: center;
  }
}
.date-card:hover {
  box-shadow: $box-shadow-sm !important;
}
.date-card-selected {
  border-color: $teal-700;
  background-color: $teal-100;
}
.date-card-blocked {
  background-color: $gray-300;
}
.date-text {
  font-size: 1.25rem;
}
</style>
