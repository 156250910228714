<template lang="pug">
span(:class="[ \
    `bg-${bgColor}`, \
    'text-decoration-none', \
    'rounded', \
    'm-1', \
    'p-2', \
    'd-flex', \
    'flex-row', \
    'justify-content-between', \
    'align-items-center', \
    'w-100', \
  ]")
  .d-flex.flex-column.p-2
    .d-flex
      i.mr-1(:class="[`icon-${icon}`, `text-${color}`]")
      .text-gray-900.font-weight-semibold
        slot(name="header")
    .my-1.ml-4.font-weight-medium.font-sm.text-gray-800
      slot(name="body")
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      default: null,
    },
    color: {
      type: String,
      default: null,
    },
    bgColor: {
      type: String,
      default: null,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
.bg-red-100 {
  border: 1px solid $red-300;
}
.bg-mustard-100 {
  border: 1px solid $mustard-300;
}
.bg-malibu-100 {
  border: 1px solid $malibu-300;
}
.bg-purple-100 {
  border: 1px solid $purple-300;
}
.bg-teal-100 {
  border: 1px solid $teal-300;
}
.bg-blue-100 {
  border: 1px solid $blue-300;
}
.bg-gray-100 {
  border: 1px solid $gray-300;
}
</style>
