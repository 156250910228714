import { dependentDropdownOptions } from "../constants";
import {
  validateName,
  validateInlineSelect,
  validateIndianMobileNumbersForVfg,
  validateEmail,
  validatePostalCode,
} from "../../../../utils/validators";

export const nomineeDetailsStage = {
  nomineeDetails: {
    title: "Add a Nominee to your policy",
    schemaTop: {
      fields: [
        {
          model: "name",
          type: "inline-input",
          inputType: "text",
          label: `Full Name (as per Govt ID)`,
          placeholder: "Nominee’s Full Name",
          styleClasses: "d-inline-flex col-md-6 flex-column",
          labelClasses: "",
          validator: [validateName],
          required: true,
        },
        {
          model: "relation",
          type: "inline-select",
          options: dependentDropdownOptions,
          transform: (v) => v.value,
          label: "Relationship with Nominee",
          styleClasses: "d-inline-flex col-md-6 flex-column",
          validator: [validateInlineSelect, "required"],
          required: true,
        },
      ],
    },
  },
  nomineeAddress: {
    title: "Enter Nominee’s contact details",
    schemaTop: {
      fields: [
        {
          model: "mobile",
          type: "inline-input",
          inputType: "text",
          label: `Mobile Number`,
          placeholder: "Enter mobile number",
          styleClasses: "d-inline-flex col-md-6 flex-column",
          validator: ["required", validateIndianMobileNumbersForVfg],
          required: true,
        },
        {
          model: "personal-email",
          type: "inline-input",
          inputType: "text",
          label: `Personal Email Address`,
          placeholder: "Personal Email Address",
          styleClasses: "d-inline-flex col-md-6 flex-column",
          validator: ["required", validateEmail],
          required: true,
        },
      ],
    },
    schemaBottom: {
      fields: [
        {
          model: "address-line-one",
          type: "inline-input",
          inputType: "text",
          label: `Address Line 1`,
          placeholder: "House number and street name",
          styleClasses: "d-inline-flex col-md-6 flex-column",
          validator: ["required"],
          required: true,
        },
        {
          model: "address-line-two",
          type: "inline-input",
          inputType: "text",
          label: `Address Line 2`,
          placeholder: "Address Line 2",
          styleClasses: "d-inline-flex col-md-6 flex-column",
          validator: ["required"],
          required: true,
        },
        {
          model: "pincode",
          type: "inline-input",
          inputType: "text",
          label: `Pincode`,
          placeholder: "Area code",
          styleClasses: "d-inline-flex col-md-6 flex-column",
          validator: ["required", validatePostalCode],
          required: true,
        },
      ],
    },
  },
};

export const contactDetailsStage = {
  title: "Enter your contact details",
  schemaTop: {
    fields: [
      {
        model: "work-email",
        type: "inline-input",
        inputType: "text",
        label: `Work Email`,
        placeholder: "Your work email",
        styleClasses: "d-inline-flex col-md-6 flex-column",
        validator: ["required", validateEmail],
        required: true,
      },
      {
        model: "mobile",
        type: "inline-input",
        inputType: "text",
        label: `Mobile Number`,
        placeholder: "Enter mobile number",
        styleClasses: "d-inline-flex col-md-6 flex-column",
        validator: ["required", validateIndianMobileNumbersForVfg],
        required: true,
      },
      {
        model: "personal-email",
        type: "inline-input",
        inputType: "text",
        label: `Personal Email Address`,
        placeholder: "Your personal email",
        styleClasses: "d-inline-flex col-md-6 flex-column",
        validator: ["required", validateEmail],
        required: true,
      },
    ],
  },
  schemaBottom: {
    fields: [
      {
        model: "address-one",
        type: "inline-input",
        inputType: "text",
        label: `Address Line 1`,
        placeholder: "House number and street name",
        styleClasses: "d-inline-flex col-md-6 flex-column",
        validator: ["required"],
        required: true,
      },
      {
        model: "address-two",
        type: "inline-input",
        inputType: "text",
        label: `Address Line 2`,
        placeholder: "Address Line 2",
        styleClasses: "d-inline-flex col-md-6 flex-column",
        validator: ["required"],
        required: true,
      },
      {
        model: "pincode",
        type: "inline-input",
        inputType: "text",
        label: `Pincode`,
        placeholder: "Area code",
        styleClasses: "d-inline-flex col-md-6 flex-column",
        validator: ["required", validatePostalCode],
        required: true,
      },
    ],
  },
};

export const policyDetailsStage = {
  note: "👋 Your deductible amount is pre-selected based on the policy coverage provided by your company. You can change it as per your convinience",
  schemaExtended: {
    fields: [
      {
        model: "deductible",
        type: "select-cards",
        styleClasses: "",
        required: true,
        validator: ["required"],
        label: "Deductible Amount",
        cardsData: [
          {
            name: "deductible",
            label: "3 Lakhs",
            card_value: "300000",
          },
          {
            name: "deductible",
            label: "5 Lakhs",
            card_value: "500000",
          },
        ],
      },
    ],
  },
};
