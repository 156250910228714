import gql from "graphql-tag";

import adminDefs from "../admin/definitions";
import {
  orgFragment,
  userFragment,
  userChangeBenefitFragment,
  orgEntityFragment,
  claimOrgMemberFragment,
} from "../admin/fragments";
import { ClaimStage } from "../../../common/enums/claims/claimStage";
import orgAdminDefs from "../orgAdmin/definitions";
import { ClaimType } from "../../../common/enums/claims/claimType";
import {
  validateChildFields,
  validateIndianMobileNumbersForVfg,
  validateOtpVerification,
  validateNovaPasswordForVfg,
} from "@/utils/validators";
import misc from "@/utils/misc";

export const getCitiesQuery = () => {
  const query = gql`
    query DropdownCities($query: String) {
      dropdownCities(query: $query)
    }
  `;
  return query;
};
const claimSatusLine = new Map();
const reimbursementClaimStatusLine = [
  "Claim Intimation & Documents",
  "Claim Registration & Review",
  "Claim Resolution",
  "Claim Closed",
];
export const cashlessClaimStatusLine = ["Claim Intimation & Documents", "Claim Approval Ongoing", "Claim Closed"];
const noTypeClaimStatusLine = ["Claim Intimation & Documents"];
claimSatusLine.set(ClaimType.REIMBURSEMENT, reimbursementClaimStatusLine);
claimSatusLine.set(ClaimType.CASHLESS, cashlessClaimStatusLine);
export const claimStatusLine = (type) => {
  if (!type) return noTypeClaimStatusLine;
  return claimSatusLine.get(type);
};
const reimbursementClaimStatusLineMapping = {
  0: [
    ClaimStage.CLAIM_INTIMATION_BY_EMPLOYEE,
    ClaimStage.NO_DOCUMENT_RECEIVED_REMINDER_1,
    ClaimStage.NO_DOCUMENT_RECEIVED_REMINDER_2,
    ClaimStage.PARTIAL_DOCUMENTS_RECEIVED_REMINDER_1,
    ClaimStage.PARTIAL_DOCUMENTS_RECEIVED_REMINDER_2,
    ClaimStage.DOCS_HARD_COPY_RECEIVED_BY_TPA,
    ClaimStage.DOCS_SOFT_COPY_UPLOADED,
    ClaimStage.DOCS_HARD_COPY_COURIERED_BY_EMPLOYEE,
    ClaimStage.DORMANT,
    ClaimStage.COMPLETE_DOCUMENTS_RECEIVED_AFTER_DEFICIENCY_CHECK_COURIER_PENDING,
  ],
  1: [
    ClaimStage.CLAIM_REGISTERED,
    ClaimStage.DOCS_UNDER_VERIFICATION,
    ClaimStage.QUERY_DOCS_UNDER_VERIFICATION,
    ClaimStage.QUERY_DOCS_UNDER_VERIFICATION_2,
    ClaimStage.CLAIM_UNDER_QUERY,
    ClaimStage.CLAIM_UNDER_QUERY_2,
    ClaimStage.CLAIM_UNDER_QUERY_REMINDER_1,
    ClaimStage.CLAIM_UNDER_QUERY_REMINDER_2,
    ClaimStage.QUERY_RESPONDED_BY_EMPLOYEE,
    ClaimStage.CLAIM_UNDER_INVESTIGATION,
    ClaimStage.DORMANT_POST_REGISTRATION,
    ClaimStage.CLAIM_REOPENED,
  ],
  2: [
    ClaimStage.CLAIM_APPROVED_BY_TPA,
    ClaimStage.DOCS_HARD_COPY_COURIERED_BY_EMPLOYEE_POSTAPPROVAL,
    ClaimStage.DOCS_HARD_COPY_RECEIVED_BY_TPA_POSTAPPROVAL,
    ClaimStage.DOCS_HARD_COPY_VERIFICATION,
    ClaimStage.PAYMENT_UNDER_PROCESS,
    ClaimStage.CLAIM_REPUDIATED_BUT_WAITING_CONCURRENCE,
  ],
  3: [ClaimStage.CLAIM_SETTLED, ClaimStage.CLAIM_REPUDIATED, ClaimStage.INVALID],
};
const cashlessClaimStatusLineMapping = {
  0: [
    ClaimStage.UPCOMING,
    ClaimStage.INTIMATION,
    ClaimStage.PREAUTH_RECEIVED,
    ClaimStage.INFORMATION_AWAITED,
    ClaimStage.QUERY_RECEIVED_FROM_HOSPITAL,
    ClaimStage.QUERY_TO_THE_HOSPITAL,
  ],
  1: [ClaimStage.PREAUTH_APPROVED, ClaimStage.INTERIUM_APPROVAL_IN_PROGRESS, ClaimStage.INTERIUM_APPROVED],
  2: [ClaimStage.APPROVED, ClaimStage.CANCELLED, ClaimStage.DENIED],
};
export const getCurrStatusLineNo = (status, type) => {
  if (!type) return 0;
  let currentStatusNo = 0;
  let claimStatusLineMapping = {};
  if (type === ClaimType.REIMBURSEMENT) {
    claimStatusLineMapping = reimbursementClaimStatusLineMapping;
  } else {
    claimStatusLineMapping = cashlessClaimStatusLineMapping;
  }

  Object.keys(claimStatusLineMapping).map((idx) => {
    if (claimStatusLineMapping[idx].includes(status)) {
      currentStatusNo = idx;
    }
  });
  return +currentStatusNo;
};

export default {
  users: {
    gqlType: "User",
    displayName: "Member",
    singleQuery: gql`
      query LoggedInUser {
        node: me {
          ...User
        }
      }
      ${userFragment}
    `,
    schema: ((_schema) => {
      const schema = misc.deepClone(_schema);
      schema.fields.find((f) => f.model === "org").visible = false;
      schema.fields.splice(
        schema.fields.findIndex((f) => f.model === "roles"),
        1
      );
      const pwIndex = schema.fields.findIndex((f) => f.model === "password");
      schema.fields.splice(pwIndex, 0, {
        model: "oldPassword",
        type: "input",
        inputType: "password",
        label: "Old Password (Leave empty to keep unchanged)",
      });
      return schema;
    })(adminDefs.users.schema),
    upsertMutation: adminDefs.users.insertUserWithEndorsements,
    transform: (n) => {
      return { ...n, orgId: n.org.id };
    },
    deleteDependentMutation: adminDefs.users.deleteDependentMutation,
    dependentsFragment: adminDefs.users.dependentsFragment,
    dependentsFragmentName: adminDefs.users.dependentsFragmentName,
    mapUserAndDependentsToBenefits: gql`
      mutation mapUserAndDependentsToBenefits($userId: ID!) {
        mapUserAndDependentsToBenefits(input: { userId: $userId }) {
          success
        }
      }
    `,
    insertDependentWithEndorsements: orgAdminDefs.users.insertDependentWithEndorsements,
    transformInsertDependentWithEndorsements: adminDefs.users.transformInsertDependentWithEndorsements,
    upsertOnboardingFlowData: gql`
      mutation UpsertOnboardingFlowData(
        $selfDetails: SelfDetailsInput!
        $dependentDetails: [DependentDetailInput]!
        $skipDependentAddition: Boolean!
        $isDependentsScreenAdded: Boolean!
        $token: String
      ) {
        upsertOnboardingFlowData(
          input: {
            selfDetails: $selfDetails
            dependentDetails: $dependentDetails
            skipDependentAddition: $skipDependentAddition
            isDependentsScreenAdded: $isDependentsScreenAdded
            token: $token
          }
        ) {
          success
        }
      }
    `,
    resyncUserBenefits: adminDefs.users.resyncUserBenefits,
    updateUserMetadata: adminDefs.users.updateUserMetadata,
    sendSummaryEmailToUser: gql`
      mutation sendSummaryEmailToUser($userId: ID!) {
        sendSummaryEmailToUser(input: { userId: $userId }) {
          success
        }
      }
    `,
    userChanges: gql`
      query MyUserChanges {
        me {
          id
          userChanges {
            type
            status
            meta
            benefit {
              ...UserChangeBenefit
            }
            dependent {
              id
            }
            changedBenefitInfo
          }
        }
      }
      ${userChangeBenefitFragment}
    `,
  },
  org: {
    ...adminDefs.orgs,
    singleQuery: gql`
      query q1 {
        node: me {
          id
          org {
            ...Org
          }
          orgEntity {
            ...OrgEntity
          }
        }
      }
      ${orgFragment}
      ${orgEntityFragment}
    `,
    transform: (n) => n.org,
  },
  benefits: {
    transform: adminDefs.benefits.transform,
  },
  claims: {
    gqlType: "Claim",
    listQuery: gql`
      query ClaimList($userId: ID, $query: String, $offset: Int, $limit: Int) {
        claims(userId: $userId, query: $query, offset: $offset, limit: $limit) {
          edges {
            node {
              ...Claim
            }
          }
          totalCount
        }
      }
      ${claimOrgMemberFragment}
    `,
    singleQuery: adminDefs.claims.singleQuery,
    schema: ((_schema) => {
      const schema = misc.deepClone(_schema);
      schema.fields.find((f) => f.model === "user").visible = false;
      // TODO: Work needed Abhishek
      // schema.fields.find((f) => f.model === "policy").visible = false;
      schema.fields.find((f) => f.model === "status").visible = false;
      const meta = schema.fields.find((f) => f.model === "meta");
      meta.schema.fields.find((f) => f.model === "expectedApprovalDate").visible = false;
      meta.schema.fields.find((f) => f.model === "approvedAmount").visible = false;
      return schema;
    })(adminDefs.claims.schema),
    transform: (n) => {
      return {
        ...n,
        userId: n.user.id,
        userName: n.user.displayName,
        orgName: n.user.org.name,
        orgId: n.user.org?.id,
        policyId: n.policy?.id,
        policyType: n.policy.type,
        policyName: n.policy.name,
      };
    },
    upsertMutation: gql`
      mutation UpsertClaim(
        $meta: JSONObject
        $id: ID
        $userId: ID
        $status: ClaimStatusEnum
        $type: String
        $source: ClaimSourceEnum
      ) {
        upsertClaim(input: { meta: $meta, id: $id, userId: $userId, status: $status, type: $type, source: $source }) {
          claim {
            id
          }
        }
      }
    `,
    getTotalCountFromData: (data) => data.claims.totalCount,
    getEdgesFromData: (data) => data.claims.edges,
  },
  userChanges: orgAdminDefs.userChanges,
  payments: {
    paymentDetail: gql`
      query PaymentDetail($pgOrderId: ID!) {
        paymentDetail(pgOrderId: $pgOrderId) {
          status
          novaOrder {
            id
            status
          }
        }
      }
    `,
  },
  profile: {
    profileSchema: {
      fields: [
        {
          model: "name",
          type: "inline-input",
          inputType: "text",
          imageIcon: "label",
          label: "Name",
          "data-cy": "user-profile-name",
          subText: "The name that is associated with your legal identification documents",
        },
        {
          model: "dob",
          type: "datepicker",
          label: "Date of Birth",
          min: "1900-01-01",
          max: "2100-01-01",
          subText: "Your date of birth and age is required by your insurer",
          dataCy: "user-profile-dob-input",
        },
        {
          model: "gender",
          type: "select-cards",
          label: "Gender",
          hint: `We understand there are more gender identities. At present however, we are still learning and trying to understand the impact of other gender identities
on health and come up with more friendly policies.`,
          cardsData: [
            {
              name: "gender",
              icon: "female",
              label: "Female",
              card_value: "female",
            },
            {
              name: "gender",
              icon: "male",
              label: "Male",
              card_value: "male",
            },
            {
              name: "gender",
              icon: "other",
              label: "Other",
              card_value: "other",
            },
          ],
          required: true,
        },
      ],
    },
    pwdSchema: {
      fields: [
        {
          model: "currentPassword",
          type: "inline-input",
          inputType: "password",
          imageIcon: "password",
          label: "Current password",
          placeholder: "Enter your current password",
          "data-cy": "user-input-current-password",
        },
        {
          model: "newPassword",
          type: "inline-input",
          inputType: "password",
          imageIcon: "password",
          label: "New password",
          placeholder: "Enter a new password",
          validator: [validateNovaPasswordForVfg],
          autocomplete: "off",
          noPadding: true,
          "data-cy": "user-input-new-password",
        },
        {
          model: "confirmNewPassword",
          type: "inline-input",
          inputType: "password",
          imageIcon: "password",
          placeholder: "Confirm new password",
          autocomplete: "off",
          "data-cy": "user-input-confirm-new-password",
        },
      ],
    },
    aboutSchema: {
      fields: [
        {
          model: "displayName",
          type: "inline-input",
          imageIcon: "label",
          inputType: "text",
          label: "Display Name",
        },
        {
          model: "meta",
          type: "object",
          validator: [validateChildFields],
          schema: {
            fields: [
              {
                model: "city",
                type: "editable-dropdown",
                label: "Where are you located?",
                imageIcon: "city",
                placeholder: "Select a city",
                gqlQuery: getCitiesQuery(),
                transform: (value) => value.name,
                queryName: "dropdownCities",
                taggable: true,
                required: true,
              },
              {
                model: "pincode",
                type: "inline-input",
                inputType: "text",
                imageIcon: "pin",
                label: "PIN Code",
                placeholder: "Enter area pin code",
                validator: "regexp",
                pattern: "^[1-9]{1}[0-9]{2}\\s{0,1}[0-9]{3}$",
                subText: "Helps us direct you to the nearest hospital in cases of emergency",
              },
              {
                model: "bloodGroup",
                type: "inline-select",
                options: orgAdminDefs.bloodGroups.map((value) => ({
                  label: value,
                  value: value,
                })),
                transform: (v) => v.value,
                label: "Blood Group",
              },
            ],
          },
        },
      ],
    },
    contactSchema: {
      fields: [
        {
          model: "email",
          type: "inline-input",
          inputType: "email",
          imageIcon: "at-the-rate",
          label: "Primary Email",
          disabled: true,
          subText: "Account managed by Google & Nova Benefits",
        },
        {
          model: "meta",
          type: "object",
          validator: [validateChildFields],
          schema: {
            fields: [
              {
                model: "altEmail",
                type: "inline-input",
                inputType: "email",
                imageIcon: "at-the-rate",
                label: "Secondary Email",
                placeholder: "Add a secondary email",
                subText: "Add a secondary email to allow easy access and account recovery",
              },
              {
                model: "contactNumber",
                type: "inline-input",
                inputType: "tel",
                label: "Phone Number",
                validator: [validateOtpVerification, validateIndianMobileNumbersForVfg],
                placeholder: "Add a phone number",
              },
              {
                model: "whatsappUserConsent",
                type: "contact-consent",
              },
            ],
          },
        },
      ],
    },
  },
  compliance: {
    userConsents: gql`
      query GetUserConsents {
        getUserConsents {
          edges {
            node {
              id
              consentType
              title
              description
              url
              isMandatory
              userConsentStatus
            }
          }
        }
      }
    `,
    upsertUserConsent: gql`
      mutation UpsertUserConsents($timestamp: String!, $source: ConsentUpsertSource!, $consents: [ConsentInput]!) {
        upsertUserConsents(input: { timestamp: $timestamp, source: $source, consents: $consents }) {
          success
        }
      }
    `,
    getAllConsents: gql`
      query getAllConsents {
        getAllConsents {
          edges {
            node {
              id
              consentType
              title
              description
              url
              isMandatory
            }
          }
        }
      }
    `,
  },
  assessments: {
    getAllReport: gql`
      query FetchAllReports($assessmentId: ID, $offset: Int, $limit: Int) {
        reports(assessmentId: $assessmentId, offset: $offset, limit: $limit) {
          edges {
            node {
              title
              reports
            }
          }
          totalCount
        }
      }
    `,
  },
};
