import { getIdSelectorQuery, getListQuery } from "../queries";
import { Section } from "../../../../common/enums";
import { benefitCategoryList, subCategoryByBenefitCategory } from "@/utils/adminAndOrgAdmin";
import misc from "@/utils/misc";

export default {
  schema: {
    fields: [
      {
        model: "type",
        type: "select",
        label: "Benefit Type",
        values: ["other", "free-coupon", "partner-signup", "embeded-partners", "sso-partners", "checkup-partners"],
        fieldClasses: ["form-select"],
        required: true,
      },
      {
        model: "name",
        type: "input",
        inputType: "text",
        label: "Benefit Name",
        required: true,
      },
      {
        model: "section",
        type: "inline-select",
        label: "Section",
        reduce: (v) => v.value,
        options: [
          { value: Section.FREE_BY_ORG, label: "Free by Org" },
          { value: Section.OFFERS_BY_NOVA, label: "Offers by Nova" },
          { value: Section.MORE_BY_ORG, label: "More by Org" },
        ],
        fieldClasses: ["form-select"],
        required: true,
        validator: "required",
      },
      {
        model: "isPolicy",
        label: "Is Policy",
        type: "checkbox",
        default: false,
        visible: false,
      },
      {
        type: "checklist",
        model: "restrictedFields",
        label: "Restricted Fields",
        values: ["name", "dob", "gender"],
        listBox: true,
      },
      {
        model: "category",
        type: "inline-select",
        reduce: (v) => v.value,
        options: benefitCategoryList,
        required: true,
        fieldClasses: ["form-select"],
        label: "Category",
        validator: "required",
      },
      {
        model: "content",
        type: "id-selector",
        gqlQuery: getListQuery("StrapiContent"),
        queryVariables: () => ({
          filter: {
            collectionType: "Benefit",
          },
        }),
        label: "Benefit Details Content (from strapi)",
        nodesFromData: misc.getOptionsMapperForStrapi("strapiContents"),
        getLink: misc.getLinkMapperForStrapi("Benefit"),
      },
      {
        model: "coverageText",
        type: "id-selector",
        gqlQuery: getListQuery("StrapiContent"),
        queryVariables: () => ({
          filter: {
            collectionType: "CoverageParameter",
          },
          limit: 10,
          offset: 0,
        }),
        label: "Coverage Text (from strapi)",
        nodesFromData: misc.getOptionsMapperForStrapi("strapiContents", "coverageText"),
        getLink: misc.getLinkMapperForStrapi("CoverageParameter"),
        hint: "Default value will be based on the family definition",
      },
      {
        model: "meta",
        type: "object",
        label: "Benefit Meta",
        schema: {
          fields: [
            {
              model: "provider",
              label: "Provider Name",
              type: "input",
              inputType: "text",
              required: true,
            },
            {
              model: "couponCode",
              label: "Coupon Code (if applicable)",
              type: "input",
              inputType: "text",
            },
            {
              model: "providerApp",
              label: "Provider Website",
              type: "input",
              inputType: "text",
            },
            {
              model: "claimFormsLink",
              type: "input",
              inputType: "text",
              label: "Claims Forms Link",
              hint: "Publicly accessible claim form link(this will overwrite the default TPA claim form link)",
            },
          ],
        },
      },
      {
        model: "createdBy",
        type: "id-selector",
        queryName: "users",
        gqlQuery: getIdSelectorQuery("User"),
        queryVariables: () => ({
          limit: 1,
          offset: 0,
        }),
        label: "Created By",
        styleClasses: "d-inline-block w-50 pr-1",
        showSelected: false,
        disabled: true,
        required: true,
      },
      {
        model: "updatedBy",
        type: "id-selector",
        queryName: "users",
        gqlQuery: getIdSelectorQuery("User"),
        queryVariables: () => ({
          limit: 1,
          offset: 0,
        }),
        label: "Last Updated By",
        styleClasses: "d-inline-block w-50 pr-1",
        showSelected: false,
        disabled: true,
        required: true,
      },
      {
        model: "createdAt",
        type: "inline-input",
        inputType: "text",
        label: "Created At",
        styleClasses: "d-inline-block w-50 pr-1",
        disabled: true,
        required: true,
      },
      {
        model: "updatedAt",
        type: "inline-input",
        inputType: "text",
        label: "Last Updated At",
        styleClasses: "d-inline-block w-50 pr-1",
        disabled: true,
        required: true,
      },
    ],
  },
  filterSchema: {
    fields: [
      {
        model: "type",
        type: "select",
        label: "Policy Type",
        values: ["free-coupon", "partner-signup", "embeded-partners", "sso-partners", "checkup-partners", "other"],
      },
      {
        model: "org",
        type: "id-selector",
        queryName: "orgs",
        gqlQuery: getIdSelectorQuery("Org"),
        placeholder: "Organization filter (none)",
        label: "Linked Org",
        showSelected: false,
      },
      {
        model: "orgEntity",
        type: "id-selector",
        queryName: "orgEntities",
        gqlQuery: getIdSelectorQuery("OrgEntity"),
        queryVariables: () => ({ filter: {} }),
        label: "Org Entity",
        visible: false,
      },
      {
        model: "benefitCategory",
        type: "inline-select",
        reduce: (v) => v.value,
        options: benefitCategoryList,
        label: "Category",
      },
      {
        model: "benefitSubCategory",
        type: "inline-select",
        options: Object.values(subCategoryByBenefitCategory).flat(),
        label: "Sub-category",
      },
    ],
  },
};
