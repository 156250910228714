<template>
  <div class="row">
    <div class="col-12"> You haven't purchased anything. </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {},
};
</script>

<style></style>
