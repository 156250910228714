import { getListQuery, getSingleQuery } from "../queries";
import orgOnboardingStageTasksMutations from "../mutations/orgOnboardingStageTasks";

export default {
  gqlType: "OrgOnboardingStageTask",
  listQuery: getListQuery("OrgOnboardingStageTask"),
  singleQuery: getSingleQuery("OrgOnboardingStageTask"),
  ...orgOnboardingStageTasksMutations,
  transform: (n) => {
    return {
      ...n,
    };
  },
};
