export const PolicyTypes = {
  GMC: "gmc",
  GPA: "gpa",
  GTL: "gtl",
  COVID: "covid",
  TOPUP: "topup",
  SUPER_TOPUP: "super-topup",
  PPC: "ppc",
  OPD: "opd",
};
