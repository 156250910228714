<template lang="pug">
combo-datepicker(
  v-model="value",
  :includeTimePicker="schema.includeTimePicker"
  :id="schema.id",
  :max="schema.max",
  :min="schema.min",
  :disabled="schema.disabled",
  :readonly="schema.readonly",
  :required="schema.required",
  :subText="schema.subText",
  :fieldLabel="schema.fieldLabel",
  :chip="schema.chip",
  :placeholder="schema.placeholder",
  :shouldFormat="schema.shouldFormat",
  :state="state",
  :validationMessage="validationMessage",
  :data-cy="schema.dataCy")
</template>

<script>
import { abstractField } from "vue-form-generator";

import comboDatepicker from "./comboDateTimepicker.vue";

export default {
  components: {
    comboDatepicker,
  },
  mixins: [abstractField],
  computed: {
    validationMessage() {
      if (this.errors.length > 0) {
        for (const err of this.errors) {
          if (typeof err === "string") return err;
          else if (typeof err === "object" && !err.isWarning) return err.message;
        }
        return this.errors?.[0].message || "";
      }
      return "";
    },
    state() {
      let hasWarning = false;
      for (const err of this.errors) {
        if (typeof err === "string" || (typeof err === "object" && !err.isWarning)) return "error";
        else if (typeof err === "object" && err.isWarning) hasWarning = true;
      }
      return hasWarning ? "warning" : "";
    },
  },
};
</script>
