export const CheckupAppointmentStatus = {
  PENDING: "pending",
  NEW: "new",
  CONFIRMED: "confirmed",
  REJECTED: "rejected",
  NO_SHOW: "noshow",
  PARTIALLY_RECEIVED: "partially_received",
  RECEIVED: "received",
  REQUEST_RESCHEDULE: "request_reschedule",
  CANCELLED: "cancelled",
  RESCHEDULED: "rescheduled",
  COMPLETED: "completed",
  PARTNER_RESCHEDULED: "partner_rescheduled",
  PARTNER_CANCELLED: "partner_cancelled",
  INCOMPLETE: "incomplete",
  VERIFICATION_REQUIRED: "verification_required",
  ABANDONED: "abandoned",
  PAYMENT_PENDING: "payment_pending",
};

export const SimplifiedCheckupAppointmentStatus = {
  PENDING: "Confirmation Pending",
  CONFIRMATION_PENDING: "Awating Lab confirmation",
  CONFIRMED: "Confirmed",
  REJECTED: "Rejected",
  REJECTED_BY_PARTBER: "Rejected by partner",
  NO_SHOW_RECORDED: "No-show recorded",
  COMPLETED: "Completed",
  CANCELLED: "Cancelled",
  CANCELLED_BY_PARTNER: "Cancelled by partner",
  RESCHEDULED: "Rescheduled",
  RESCHEDULED_BY_PARTNER: "Rescheduled by partner",
  SAMPLE_COLLECTED: "Sample collected",
  TEST_DONE: "Test done",
  AWAITING_REPORTS: "Awaiting reports",
  PARTIAL_REPORTS_AVAILABLE: "Partial reports available",
  REPORTS_AVAILABLE: "Reports available",
  AWAITING_PAYMENT: "Awaiting payment",
};
