<template lang="pug">
.policy-details(:class="{ 'pb-8': isMobileBrowser() || isApp() }")
  div(v-if="getAllSelectedPlusMembers().length")
    .text-gray-800.font-md.font-weight-medium.align-items-center.pt-4
      n-icon.mr-1(
        name="color-family",
        :size=1
      )
      | Nova Plus: Coverage for you, your spouse &amp; children

    </br>
    policy-selection-card(
      :model="plusPremiumDetails",
      :schema="schemaPlus",
      :note="policyDetailsStage.note",
      :defaultDeductible="gmcPolicy?.si",
      type="plus")
    </br>
  div(v-if="getAllSelectedExtendedMembers().length")
    
    .text-gray-800.font-md.font-weight-medium.align-items-center.pt-4
      n-icon.mr-1(
        name="color-family",
        :size=1
      )
      | Nova Extended: Top-up options for your parents

    </br>
    policy-selection-card(
      :model="extendedPremiumDetails",
      :schema="schemaExtended",
      type="extended")
    
</template>

<script>
import NovaIcon from "../../../../../components/NovaIcon.vue";
import { policyDetailsStage } from "../definitions";
import { validateInlineSelect } from "../../../../../utils/validators";
import resDefs from "../../../admin/definitions";
import mixin from "./../mixin";
import PolicySelectionCard from "./PolicySelectionCard.vue";
export default {
  name: "PolicyDetails",
  components: {
    NovaIcon,
    PolicySelectionCard,
  },
  mixins: [mixin],
  data() {
    return {
      policyDetailsStage,
      plusPremiumDetails: {},
      extendedPremiumDetails: {},
    };
  },
  computed: {
    gmcPolicy() {
      return this.$store.state.user.benefits.find((benefit) => benefit.node.type === "gmc").meta;
    },

    novaPlusSiOptions() {
      return this.deductibleToSiMap?.[this.getPlusPolicy?.id]?.[this.plusPremiumDetails.deductible] || [];
    },
    novaExtendedSiOptions() {
      return this.deductibleToSiMap?.[this.getExtendedPolicy?.id]?.[this.extendedPremiumDetails.deductible] || [];
    },
    // TODO: Check and extend for extended as well if needed
    schemaPlus() {
      const deductibleOptions = Object.keys(this.deductibleToSiMap?.[this.getPlusPolicy?.id] || {})
        // Removing header for plus policy selection
        .filter((key) => key !== "deductible")
        .map((deductible) => {
          const label = parseInt(deductible / 100000).toString();
          return {
            name: "deductible",
            label: `${label} Lakhs`,
            card_value: deductible,
          };
        });
      return {
        fields: [
          {
            model: "deductible",
            type: "select-cards",
            styleClasses: "",
            required: true,
            validator: ["required"],
            label: "Deductible Amount",
            cardsData: deductibleOptions,
          },
          {
            model: "si",
            type: "inline-select",
            label: "Sum Insured <i class='font-sm icon-question' id='si-note-plus'></i>",
            imageIcon: "rupee",
            placeholder: "Select your policy coverage",
            taggable: false,
            options: this.novaPlusSiOptions,
            required: true,
            validator: [validateInlineSelect, "required"],
          },
        ],
      };
    },

    schemaExtended() {
      return {
        fields: [
          ...this.policyDetailsStage.schemaExtended.fields,
          {
            model: "si",
            type: "inline-select",
            label: "Sum Insured <i class='font-sm icon-question' id='si-note-extended'></i>",
            imageIcon: "rupee",
            placeholder: "Select your policy coverage",
            taggable: true,
            options: this.novaExtendedSiOptions,
            required: true,
            validator: [validateInlineSelect, "required"],
          },
        ],
      };
    },
  },
  watch: {
    "plusPremiumDetails.si": {
      async handler(value) {
        if (
          this.plusPremiumDetails.si &&
          this.plusPremiumDetails.si !== "None selected" &&
          this.plusPremiumDetails.deductible
        ) {
          this.$store.commit("updateSuperTopupProspectStateMeta", {
            isNovaPlusSelected: true,
            novaPlusPremiumDetails: { ...this.plusPremiumDetails },
          });
        } else {
          this.$store.commit("updateSuperTopupProspectStateMeta", {
            isNovaPlusSelected: false,
            novaPlusPremiumDetails: {},
          });
        }
        await this.fetchPremiumDetails("novaPlusPremiumDetails");
      },
      deep: true,
    },
    "extendedPremiumDetails.si": {
      async handler(value) {
        if (
          this.extendedPremiumDetails.si &&
          this.extendedPremiumDetails.si !== "None selected" &&
          this.extendedPremiumDetails.deductible
        ) {
          this.$store.commit("updateSuperTopupProspectStateMeta", {
            isNovaExtendedSelected: true,
            novaExtendedPremiumDetails: { ...this.extendedPremiumDetails },
          });
        } else {
          this.$store.commit("updateSuperTopupProspectStateMeta", {
            isNovaExtendedSelected: false,
            novaExtendedPremiumDetails: {},
          });
        }
        await this.fetchPremiumDetails("novaExtendedPremiumDetails");
      },
      deep: true,
    },
  },
  async created() {
    this.$store.commit("updateSuperTopupProspectStateMeta", {
      isFormValid: true,
    });
    this.loadState();
    this.$parent.$on("storeLoaded", () => {
      this.loadState();
    });
    await this.$apollo.queries.retailPolicies.refetch();
    await this.fetchPremiumDetails("novaPlusPremiumDetails");
    await this.fetchPremiumDetails("novaExtendedPremiumDetails");
  },
  methods: {
    async fetchPremiumDetails(policyType) {
      const policyDetails =
        policyType === "novaPlusPremiumDetails"
          ? this.superTopupProspectState.novaPlusPremiumDetails
          : this.superTopupProspectState.novaExtendedPremiumDetails;
      const benefitId = policyType === "novaPlusPremiumDetails" ? this.getPlusPolicy?.id : this.getExtendedPolicy?.id;
      const coveredMembers = this.superTopupProspectState.policyDetail[benefitId]?.coveredMembers;
      try {
        if (
          policyDetails?.si &&
          policyDetails?.deductible &&
          policyDetails?.si != "None selected" &&
          coveredMembers?.length
        ) {
          const { data } = await this.$apollo.query({
            query: resDefs.retailPolicies.getPremiumDetail,
            fetchPolicy: "no-cache",
            variables: {
              benefitId: benefitId,
              policyDetails: {
                deductible: parseInt(policyDetails.deductible),
                si: parseInt(policyDetails.si),
                coveredMembers: coveredMembers,
              },
            },
          });

          if (policyType === "novaPlusPremiumDetails") {
            this.plusPremiumDetails.premium = data.premiumDetail.premium;
            this.plusPremiumDetails.gst = data.premiumDetail.gstAmount;
            this.$store.commit("updateSuperTopupProspectStateMeta", {
              novaPlusPremiumDetails: this.plusPremiumDetails,
            });
          } else if (policyType === "novaExtendedPremiumDetails") {
            this.extendedPremiumDetails.premium = data.premiumDetail.premium;
            this.extendedPremiumDetails.gst = data.premiumDetail.gstAmount;
            this.$store.commit("updateSuperTopupProspectStateMeta", {
              novaExtendedPremiumDetails: this.extendedPremiumDetails,
            });
          }
        }
      } catch (error) {
        console.error("Error fetching premium details:", error);
      }
    },
    loadState() {
      if (this.superTopupProspectState.novaPlusPremiumDetails) {
        this.plusPremiumDetails = this.superTopupProspectState.novaPlusPremiumDetails;
      }
      if (this.superTopupProspectState.novaExtendedPremiumDetails) {
        this.extendedPremiumDetails = this.superTopupProspectState.novaExtendedPremiumDetails;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
.policy-details {
  .policy-card {
    background-color: white;
    border: 0.125rem solid $gray-300;
    border-radius: 0.375rem;

    &.selected {
      background-color: $teal-100;
      border: 0.125rem solid $teal-700;
      box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.04);
    }
  }
}
</style>
