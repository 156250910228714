export default {
  computed: {
    isRaterBaseConfigPresent() {
      if (!this.org || !this.org.benefits) {
        return false;
      }
      return this.org.benefits.reduce((result, benefit) => {
        // Check if it's a policy and configCharge.rater is present
        if (benefit?.node?.isPolicy && benefit?.node?.chargesConfig.rater) {
          return true;
        }
        return result;
      }, false);
    },
  },
};
