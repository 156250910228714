<template lang="pug">
n-progress-toast-wrapper(
  v-if="bulkUserOperationJob.jobId",
  :operationName=getOperationName,
  :state="bulkUserOperationJob.jobStatus",
  :progress="bulkUserOperationJob.jobProgress",
)
</template>

<script>
import { mapState } from "vuex";
import blitz from "blitzllama-js";
import { BulkUserOperations, JobStates } from "../common/enums";
import { getSingleQuery } from "../portal/pages/admin/queries";
import NProgressToastWrapper from "./NovaProgressToastWrapper.vue";

export default {
  components: {
    NProgressToastWrapper,
  },
  computed: {
    ...mapState(["bulkUserOperationJob"]),
    getOperationName() {
      return this.bulkUserOperationJob.jobType.replace("-", " ") || "operation";
    },
    getSuccessToastMessage() {
      if (this.bulkUserOperationJob.jobType === BulkUserOperations.BULK_UPLOAD) {
        return "Successfully requested addition for the users";
      }
      if (this.bulkUserOperationJob.jobType === BulkUserOperations.BULK_DELETE) {
        return "Successfully requested deletion for the users";
      }
      return "Success";
    },
    getFailureToastMessage() {
      if (this.bulkUserOperationJob.jobType === BulkUserOperations.BULK_UPLOAD) {
        return "Addition request unsuccessful";
      }
      if (this.bulkUserOperationJob.jobType === BulkUserOperations.BULK_DELETE) {
        return "Deletion request unsuccessful";
      }
      return "Unsuccessful";
    },
  },
  watch: {
    bulkUserOperationJob: {
      handler: function () {
        if (this.bulkUserOperationJob.jobId && !this.jobTimer) {
          this.jobTimer = setInterval(() => {
            this.$apollo
              .query({
                query: getSingleQuery("Job"),
                variables: {
                  id: this.bulkUserOperationJob.jobId,
                },
                fetchPolicy: "no-cache",
              })
              .then(({ data }) => {
                const { progress, status } = data.node;
                this.$store.commit("updateBulkUserOperationProgress", progress); // reset the progress and status
                this.$store.commit("updateBulkUserOperationStatus", status);
                if ([JobStates.COMPLETED, JobStates.FAILED].includes(status)) {
                  if (status === JobStates.COMPLETED) {
                    this.$store.commit("addToast", {
                      variant: "success",
                      message: this.getSuccessToastMessage,
                    });
                    if (this.bulkUserOperationJob.jobType === BulkUserOperations.BULK_UPLOAD) {
                      blitz.triggerEvent("employee_added_bulk_upload");
                    }
                  } else {
                    this.$store.commit("addToast", {
                      variant: "danger",
                      message: this.getFailureToastMessage,
                    });
                  }
                  this.clearJobTimer();
                }
              });
          }, 1000);
        } else if (!this.bulkUserOperationJob.jobId && this.jobTimer) {
          this.clearJobTimer();
        }
      },
      deep: true,
    },
  },
  methods: {
    clearJobTimer() {
      clearInterval(this.jobTimer);
      this.jobTimer = null;
      this.resetBulkUserOperationStateInStore();
      this.$store.commit("clearBulkUserOperationWarningModal");
    },
    resetBulkUserOperationStateInStore() {
      this.$store.commit("resetBulkUserOperation");
    },
  },
};
</script>
