<template lang="pug">
  .row.mx-0.px-0
    .link.text-left.pb-1.px-0(v-if="schema.fieldLabel", :class="'col-12'")
      template(v-if="schema.chip && schema.chip.text")
        div.d-flex.justify-content-between.align-items-center
          span.field-label(:class="'font-sm'") {{ schema.fieldLabel }}
          nova-chip( :variant="schema.chip.variant") {{ schema.chip.text }}
      template(v-else)
        span(:class="'font-sm'") {{ schema.fieldLabel }}
    vue-select.inline-select-dropdown.w-100(
      :taggable="schema.taggable",
      v-model="value",
      :options="options",
      :disabled="disabled",
      :placeholder="schema.placeholder",
      :filterable="true",
      :clearable="schema.clearable"
      :searchable="schema.searchable",
      :reduce="schema.reduce",
      :readonly="schema.readonly",
      :multiple="schema.multiple",
      :components="{OpenIndicator, Deselect}",
      :class="state",
      :data-cy="schema.dataCy")
      template(v-slot:option="opt")
        | {{opt.label || opt}}&nbsp;
        span.text-muted.small(v-if="schema.getSubTitle") {{ schema.getSubTitle(opt) }}

      template(#selected-option="{ label }")
        .d-inline-block.h-100.font-sm.px-2.py-0(v-if="schema.imageIcon")
          .text-center.vue-select-image-icon(:class="'icon-'+schema.imageIcon")
        span.selected-text(
          :class="value==='None selected'?`text-gray-600 font-weight-medium`:''") {{ label }}

      template(#search="{ attributes, events }")
        input(
          :class="['vs__search', schema.imageIcon ? 'offset-cursor': '']",
          v-bind="attributes",
          v-on="events")
    b-link.mt-1.font-sm(v-if="schema.getLink && schema.getLink(value)", :href="schema.getLink(value)", target="_blank")
      | {{ schema.linkName }}
      span(v-if="value.nameExtension")
        | {{ value.nameExtension }}
      | &nbsp;
    .input-sub-text.text-left.mt-2(v-if="validationMessage", :class="state")
      n-icon.pr-1.sub-text-icon.align-middle(v-if="state && state !== 'disabled'", :name="$options.stateIconMap[state] || ''", :size="1")
      span.align-middle {{ validationMessage }}

</template>

<script>
import VueSelect from "vue-select";
import { abstractField } from "vue-form-generator";
import "vue-select/dist/vue-select.css";
import { get } from "lodash";
import NIcon from "../NovaIcon.vue";

export default {
  components: {
    VueSelect,
    NIcon,
  },
  mixins: [abstractField],
  data() {
    return {
      options: [],
      OpenIndicator: {
        render: (createElement) => createElement("span", { class: { "icon-chevron-down": true } }),
      },
      Deselect: {
        render: (createElement) =>
          createElement("span", {
            class: { "icon-cross text-gray-700 ic-small": true },
          }),
      },
    };
  },
  computed: {
    // TODO: Move these from the field components to a common place preferably utils
    validationMessage() {
      if (this.errors.length > 0) {
        for (const err of this.errors) {
          if (typeof err === "string" && err !== "None Selected") return err;
          else if (typeof err === "string" && err === "None Selected" && this.schema.required)
            return "This field is required.";
          else if (typeof err === "object" && !err.isWarning) return err.message;
        }
        return this.errors?.[0].message || "";
      }
      return "";
    },
    state() {
      let hasWarning = false;
      for (const err of this.errors) {
        if (typeof err === "string" || (typeof err === "object" && !err.isWarning)) return "error";
        else if (typeof err === "object" && err.isWarning) hasWarning = true;
      }
      return hasWarning ? "warning" : "";
    },
  },
  watch: {
    value() {
      if (!this.value) {
        // handling single and multi value variations
        this.value = this.schema.multiple ? [] : "None selected";
      }
      if (this.schema.transform && typeof this.value === "object") {
        this.value = this.schema.transform(this.value);
      }
    },
    "schema.options": function () {
      this.setOptions();
    },
  },
  async created() {
    this.setOptions();
    if (this.model && !get(this.model, this.schema.model)) {
      this.value = this.schema.multiple ? [] : "None selected";
    }
    this.$options.stateIconMap = {
      success: "check-circle",
      error: "error",
      warning: "warning",
    };
  },
  methods: {
    setOptions() {
      if (typeof this.schema.options === "function") {
        this.options = this.schema.options();
      } else {
        this.options = this.schema.options;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";

.sub-text-icon {
  font-size: 1rem;
  vertical-align: text-bottom;
}

.inline-select-dropdown::v-deep.error .vs__dropdown-toggle {
  border-color: $red-400;
}
</style>
