<template lang="pug">
  n-modal(
  v-bind="{...$props, ...$attrs}",
  centered,
  isScrollable=true,
  close-on-backdrop,
  :modal-class="modalId",
  id=modalId)
    template(v-slot:modal-header)
      .hb5.text-gray-900.my-1 CD Details
    template(v-slot:default)
      slot(name="default")
        .d-flex.flex-column.p-2
          .d-flex.align-items-center.mt-1.mb-3
            n-icon(name="error", variant='red-600', :size="1.5" v-if="shortFallAmount > 0")
            n-icon(name="error", variant='mustard-600', :size="1.5" v-else)
            .ml-1.font-weight-bold(v-if="shortFallAmount > 0") Your CD balance is falling short!
            .ml-1.font-weight-bold(v-else) Your CD balance is low!
          template(v-if="shortFallAmount > 0")
            .mb-1.mt-3
              .mb-1 These endorsements can’t be send to the insurer due to the CD Balance shortfall.
              .mt-2 Please add the shortfall amount of {{ getFormattedINRWithFloatingPoint(shortFallAmount,true) }} to your CD account to continue. 
                template(v-if="minCdBalanceAmount > 0") For future emergency inclusions please add an additional amount of {{ getFormattedINRWithFloatingPoint(minCdBalanceAmount,true) }} as a buffer.
              .font-weight-light.mt-4 Please reach out to us once you update your CD Account or if you have any queries.
          template(v-else)
            .mt-2.mb-3 Kindly add {{ getFormattedINRWithFloatingPoint(minCdBalanceAmount,true) }} to your CD account as a buffer for future emergency inclusions.
            .font-weight-light.mt-1 You can still proceed with the endorsements for this batch.
    template(v-slot:modal-footer)
      n-button.col-md-4(variant="light", :buttonText="'View Details'", @click="$router.push({ name: getPremiumDetailsRoute, params: { batchId: batch?.id }})")
      n-button.col-md-4(v-if="shortFallAmount > 0" variant="primary", :buttonText="'Contact Nova'", @click="toggleContact")
      n-button.col-md-4(v-else variant="primary", :buttonText="'Continue'", @click="handleContinue")
      
      
</template>

<script>
import { mapGetters } from "vuex";
import utils from "@/utils";
import NModal from "../../../../components/NovaModal.vue";
import NButton from "@/components/NovaButton.vue";
export default {
  name: "CashDepositModal",
  components: {
    NModal,
    NButton,
  },
  props: {
    id: {
      type: String,
      default: "",
    },
    shortFallAmount: {
      type: Number,
      required: true,
    },
    minCdBalanceAmount: {
      type: Number,
      required: true,
    },
    batch: {
      type: Object,
      default: null,
    },
  },
  computed: {
    modalId() {
      return this.id ? this.id : "cash-deposit-modal";
    },
    getPremiumDetailsRoute() {
      return this.isSuperAdmin ? "admin_premium_details" : "org_admin_premium_details";
    },
    isSuperAdmin() {
      return !this.$route.path.includes("org-admin/");
    },
  },

  methods: {
    handleContinue() {
      this.$bvModal.show("banner-step-modal");
      this.$bvModal.hide("cash-deposit-modal");
    },
    getFormattedINRWithFloatingPoint(value, withRupeeSym) {
      return utils.getFormattedINRWithFloatingPoint(value, withRupeeSym);
    },
    async toggleContact() {
      this.$bvModal.hide("cash-deposit-modal");
      //to add delay
      await new Promise((resolve) => setTimeout(resolve, 200));
      this.$store.commit("toggleSupportContainer");
    },
  },
};
</script>
