import users from "./users";
import userChanges from "./userChanges";
import orgs from "./orgs";
import claims from "./claims";
import forms from "./forms";
import insurers from "./insurers";
import tpas from "./tpas";
import tpaOffices from "./tpaOffices";
import content from "./content";
import hospitals from "./hospitals";
import policies from "./policies";
import benefits from "./benefits";
import prospects from "./prospects";
import cliTools from "./cliTools";
import strapiContents from "./strapiContents";
import integrations from "./integrations";
import orgProperties from "./orgProperties";
import jobs from "./jobs";
import userChangeBatches from "./userChangeBatches";
import challengeTemplates from "./challengeTemplates";
import orgChallenges from "./orgChallenges";
import userChallenges from "./userChallenges";
import segments from "./segments";
import userSegments from "./userSegments";
import ngageCalendars from "./ngageCalendars";
import samlVendorConfigs from "./samlVendorConfigs";
import files from "./files";
import CXPods from "./CXPods";
import CXPodMembers from "./CXPodMembers";
import CXAccountOwners from "./CXAccountOwners";
import CXPodOnboarding from "./CXPodOnboarding";
import orgOnboardingStageTasks from "./orgOnboardingStageTasks";
import orgOnboardingStageTaskUpdates from "./orgOnboardingStageTaskUpdates";
import fileGroups from "./fileGroups";
import checkupPackages from "./checkupPackages";
import checkupProviders from "./checkupProviders";
import transactions from "./financialEntities/transactions";
import accounts from "./financialEntities/accounts";
import insurerBatches from "./insurerBatches";
import stagedClaims from "./stagedClaims";
import policyDataChecks from "./policyDataChecks";
import policyDataCheckBatches from "./policyDataCheckBatches";
import comments from "./comments";
import enrollmentData from "./enrollmentData";
import NovaOrders from "./NovaOrders";
import PgPayments from "./PgPayments";
import retailPolicies from "./retailPolicies";
import orgEntities from "./orgEntities";
import teams from "./teams";
import designations from "./designations";
import userTeamDesignations from "./userTeamDesignations";
import echoPostTemplates from "./echoPostTemplates";
import echoPosts from "./echoPosts";
import claimFiles from "./claimFiles";
import novaProducts from "./novaProducts";
import ngageUserDetails from "./ngageUserDetails";

export default {
  users,
  userChanges,
  orgs,
  claims,
  forms,
  insurers,
  tpas,
  tpaOffices,
  content,
  hospitals,
  policies,
  benefits,
  prospects,
  cliTools,
  strapiContents,
  integrations,
  orgProperties,
  jobs,
  userChangeBatches,
  challengeTemplates,
  orgChallenges,
  userChallenges,
  segments,
  userSegments,
  ngageCalendars,
  samlVendorConfigs,
  files,
  CXPods,
  CXPodMembers,
  CXAccountOwners,
  CXPodOnboarding,
  orgOnboardingStageTasks,
  orgOnboardingStageTaskUpdates,
  fileGroups,
  checkupPackages,
  checkupProviders,
  transactions,
  insurerBatches,
  stagedClaims,
  policyDataChecks,
  policyDataCheckBatches,
  comments,
  enrollmentData,
  accounts,
  NovaOrders,
  PgPayments,
  retailPolicies,
  orgEntities,
  teams,
  designations,
  userTeamDesignations,
  echoPostTemplates,
  echoPosts,
  claimFiles,
  novaProducts,
  ngageUserDetails,
};
