<template lang="pug">
footer.py-4.footer.d-none.d-md-block
  .container.text-center
    .row.align-items-center.my-0
      .col-md.text-md-left We’ve got you covered. Literally.
      .col-md
        i.icon-nova-basic
      .col-md.text-md-right &copy; Novabenefits Private Limited, {{ year }}
</template>

<script>
export default {
  computed: {
    year() {
      return new Date().getFullYear();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
div {
  color: $gray-600;
}
i {
  font-size: 1.6rem;
}
</style>
