import gql from "graphql-tag";
import { prospectFragment, prospectEventFragment } from "../fragments";

export default {
  upsertMutation: gql`
    mutation upsertProspectStateMutation(
      $id: ID
      $email: String
      $link: String
      $pricingTableState: JSONObject
      $addons: [String]
      $meta: JSONObject
      $calculatePremiums: Boolean
    ) {
      upsertProspect(
        input: {
          id: $id
          email: $email
          link: $link
          pricingTableState: $pricingTableState
          addons: $addons
          meta: $meta
          calculatePremiums: $calculatePremiums
        }
      ) {
        prospect {
          ...Prospect
        }
      }
    }
    ${prospectFragment}
  `,
  eventMutation: gql`
    mutation upsertProspectEventMutation($id: ID, $prospectId: ID, $eventName: String, $eventParams: JSONObject) {
      upsertProspectEvent(
        input: { id: $id, prospectId: $prospectId, eventName: $eventName, eventParams: $eventParams }
      ) {
        prospectEvent {
          ...ProspectEvent
        }
      }
    }
    ${prospectEventFragment}
  `,
};
