<template lang="pug">
n-sidesheet(
  :ssId="`deductible-sidesheet`",
  :headerName="'How do deductibles work?'",
  shadow,
  backdrop)
  template(v-slot:content)
    .w-100
      .p-4
        span.text-gray-800 Let’s say your deductible amount is 
        span.text-mustard-700 ₹5 lakhs&nbsp;
        span.text-gray-800 and your claims exhaust that limit. Nova's super top-up gets activated, providing extra coverage up to&nbsp;
        span.text-malibu-800 ₹50 lakhs.
        hr
        .d-flex.mb-2.mb-md-0
          n-icon(name="charge", variant="malibu-800")
          span.text-gray-800 The Deductible is what you or your primary insurance must pay before the Super Top-Up starts. You get to pick your deductible amount!
        br
        div
          info-card(:content="INFO_CONTENT")

</template>

<script>
import NButton from "@/components/NovaButton.vue";
import NSidesheet from "@/components/NovaSideSheet";
import NIcon from "../../../../../components/NovaIcon.vue";
import InfoCard from "./InfoCard.vue";
import { INFO_CONTENT } from "../constants";
export default {
  name: "DeductibleSidesheet",
  components: {
    NButton,
    NSidesheet,
    NIcon,
    InfoCard,
  },
  props: {},
  data() {
    return {
      id: "deductible-sidesheet",
      INFO_CONTENT,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
@import "@/assets/styles/mixins/_breakpoints.scss";
</style>
