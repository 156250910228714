import gql from "graphql-tag";
import enrollmentDataMutations from "../mutations/enrollmentData";

export default {
  fetchEnrollmentDataQuery: gql`
    query fetchEnrollmentData($query: String, $offset: Int, $limit: Int, $filter: JSONObject) {
      fetchEnrollmentData(query: $query, offset: $offset, limit: $limit, filter: $filter)
    }
  `,
  ...enrollmentDataMutations,
};
