import { premiumDetailFragment } from "../fragments";
import gql from "graphql-tag";
import retailPolicyMutations from "../mutations/superTopup";

export default {
  getPremiumDetail: gql`
    query premiumDetail($benefitId: ID, $policyDetails: JSONObject, $userChanges: [JSONObject]) {
      premiumDetail(benefitId: $benefitId, policyDetails: $policyDetails, userChanges: $userChanges) {
        ...PremiumDetail
      }
    }
    ${premiumDetailFragment}
  `,
  ...retailPolicyMutations,
};
