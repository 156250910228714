import { render, staticRenderFns } from "./EndosementCard.vue?vue&type=template&id=63509dec&scoped=true&lang=pug"
import script from "./EndosementCard.vue?vue&type=script&lang=js"
export * from "./EndosementCard.vue?vue&type=script&lang=js"
import style0 from "./EndosementCard.vue?vue&type=style&index=0&id=63509dec&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "63509dec",
  null
  
)

export default component.exports