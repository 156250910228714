<template lang="pug">
  .container.super-topups-page-container.p-0.mt-3.mt-md-0
    .drop-active(v-if="isPullUpOpen")
    .container.d-none.d-md-block.w-100.position-relative.mt-3
      p.font-weight-medium(v-if="!isPolicySummary")
        span
          n-icon.pr-1.align-sub(name="chevron-left", @click="goToPreviousStep()")
          | Step {{ formattedStep }}
  
    .row.mx-0(:class="getMarginBottomClass()")
      .col-md-8.col-12.px-auto
        .d-flex.flex-column
          .d-flex.justify-content-between.align-items-center
            .header
              h4 {{ getStepContent(currentStepIndex, "title") }}
              span.text-gray-700.font-sm {{ getStepContent(currentStepIndex, "subtitle") }}
            n-button.text-blue-800.ml-5(
              v-if="isPolicySummary",
              variant="outline-secondary",
              buttonText="Edit Details",
              imageIcon="edit",
              size="sm",
              @click="$router.push('/super-topups/add-members')",
              class="ml-auto")
        super-topup-form-container(ref="superTopupFormContainer" )
  
      .col-md-4.col-12.px-0.sticky.d-block(:class="{ 'open': isPullUpOpen }", :style="{ height: getCardHeight(), transition: 'height 0.5s ease-in-out' }", slot="right-side-slot")
        cart-panel(@toggleSummary="togglePullUp", :isPullUpOpen="isPullUpOpen", @finalConfirmationClicked="$bvModal.show('final-ack-modal')")
    
    b-modal(
      id="final-ack-modal",
      :showCross="true",
      centered,
      )
      template(v-slot:modal-header)
        h3.mx-3.modal-title Acknowledgement
      template(v-slot:default)
        .mx-3
          span I hereby agree to enroll in the policy with the understanding,&nbsp;
          span.font-weight-semibold that I am not suffering from any major/ chronic health problem(s), major disease/disorder&nbsp;
          span impacting vital organs (Heart, Brain, Kidneys, Lungs, Liver, Pancreas, Spleen, Intestine etc) or deformity other than minor ailment like Cold, Cough, Fever etc. I hereby also declare that&nbsp;
          span.font-weight-semibold I have never undergone or awaiting any major medical or surgical treatment/ procedure or follow-up.&nbsp;
          span I also understand any non-disclosure in respect to any disease(s), treatment(s) and/or duration of the disease(s) may result in denial of the claim and/or cancellation of my policy. Pre-existing medical condition(s) will be covered after the waiting period, as mentioned in the&nbsp;
          b-link Policy T&C
          |.
      template(v-slot:modal-footer)
        .d-flex
          n-button.font-sm.mr-3(
            variant="outline-dark",
            buttonText="Cancel",
            @click="$bvModal.hide('final-ack-modal')",
          )
          n-button.font-sm(
            variant="dark",
            buttonText="I Agree ->",
            @click="upsertSuperTopup()"
          )
  
    b-modal(
      id="member-add-modal",
      :showCross="true",
      centered,
      )
      template(v-slot:modal-header)
        h3.mx-3.modal-title Continue without adding all members?
      template(v-slot:default)
        .mx-3
          span Your policy currently covers your parents, spouse and child, and you won’t be able to add them until your renewal next year. 
          br
          br
          span Please confirm if you wish to proceed without including them. If this is not applicable to you, kindly ignore.
      template(v-slot:modal-footer)
        .d-flex
          n-button.font-sm.mr-3(
            variant="outline-dark",
            buttonText="Cancel",
            @click="$bvModal.hide('member-add-modal')",
          )
          n-button.font-sm(
            variant="dark",
            buttonText="I Agree ->",
            @click="proceedToNextStep()"
          )

    deductible-sidesheet
  </template>

<script>
import { mapState, mapGetters } from "vuex";
import gql from "graphql-tag";
import { TopupRoutes } from "../../../../common/enums/topupRoutes.enum";
import NButton from "../../../../components/NovaButton.vue";
import resDefs from "../../admin/definitions";
import { prospectFragment } from "../../admin/fragments";
import { ProspectType } from "../../../../common/enums/prospectType.enum";
import mobileApp from "../../../mobileApp";
import { displayRazorpay } from "../../payments/razorpay/config";
import { NovaProductType } from "../../novaProducts/enum";
import mixin from "./mixin";
import CartPanel from "./CartPanel";
import { TOPUP_CONSTANTS } from "./constants";
import SuperTopupFormContainer from "./SuperTopupFormContainer.vue";
import DeductibleSidesheet from "./components/DeductibleSidesheet.vue";
import utils from "@/utils";

export default {
  name: "SuperTopupsBase",
  components: {
    SuperTopupFormContainer,
    CartPanel,
    NButton,
    DeductibleSidesheet,
  },
  mixins: [mixin],
  beforeRouteLeave(to, from, next) {
    this.handleRouteChange(to, from, next);
  },
  beforeRouteUpdate(to, from, next) {
    this.$store.commit("updateSuperTopupProspectStateMeta", {
      isFormValid: true,
    });
    if (to.name === "nomineeDetails" && from.name === "policyDetails") {
      let isFormValid = true;
      if (this.getAllSelectedPlusMembers().length) {
        isFormValid = this.superTopupProspectState.isNovaPlusSelected;
      }
      if (this.getAllSelectedExtendedMembers().length) {
        isFormValid = isFormValid && this.superTopupProspectState.isNovaExtendedSelected;
      }
      this.$store.commit("updateSuperTopupProspectStateMeta", {
        isFormValid,
      });
    }
    const toPrecedence = Object.values(TopupRoutes).indexOf(this.getStep(to.path));
    const fromPrecedence = Object.values(TopupRoutes).indexOf(this.getStep(from.path));
    if (toPrecedence < fromPrecedence) {
      this.handleNavigation(to, from, next);
    }
    if (toPrecedence > fromPrecedence && !this.superTopupProspectState.isFormValid) {
      this.$store.commit("addToast", {
        variant: "danger",
        message: "Please fill in all the mandatory fields before proceeding!",
      });
      return;
    }
    if (to.name === "policyDetails" && from.name === "addMembers") {
      let areParentsValid = true;
      let arePlusDepsValid = true;
      this.superTopupProspectState.parents
        .filter((parent) => parent.checked)
        .forEach((parent) => {
          if (!this.isParentValid(parent)) {
            areParentsValid = false;
          }
        });
      if (!areParentsValid) {
        this.$store.commit("addToast", {
          variant: "danger",
          message: "Please enter correct data for parents",
        });
        return;
      }
      this.superTopupProspectState.plusDependents
        .filter((dep) => dep.checked)
        .forEach((dep) => {
          if (!this.isPlusDependentValid(dep)) {
            arePlusDepsValid = false;
          }
        });
      if (!arePlusDepsValid) {
        this.$store.commit("addToast", {
          variant: "danger",
          message: "Please fill in all mandatory fields for dependents",
        });
        return;
      }
      const hasSelfRelation = this.superTopupProspectState?.selectedLives?.some((user) => user.relation === "self");
      if (this.getAllSelectedExtendedMembers().length + this.getAllSelectedPlusMembers().length === 0) {
        this.$store.commit("addToast", {
          variant: "danger",
          message: "Please select atleast one member",
        });
        return;
      }
      if (this.getAllSelectedPlusMembers().length && !hasSelfRelation) {
        this.$store.commit("addToast", {
          variant: "danger",
          message: "Self user selection is mandatory!",
        });
        return;
      }
      if (!this.allMembersSelected() && !this.superTopupProspectState.acknowledgedStages["add-members"]) {
        this.$bvModal.show("member-add-modal");
        return;
      } else {
        this.$emit("pushDepStates");
      }
    }
    if (to.name === "policyDetails" && from.name === "addMembers") {
      this.$store.commit("updateSuperTopupProspectStateMeta", {
        acknowledgedStages: {
          ...this.superTopupProspectState.acknowledgedStages,
          "add-members": true,
          "policy-details": false,
        },
      });
      this.handleNavigation(to, from, next);
    } else if (to.name === "nomineeDetails" && from.name === "policyDetails") {
      this.$store.commit("updateSuperTopupProspectStateMeta", {
        acknowledgedStages: {
          ...this.superTopupProspectState.acknowledgedStages,
          "policy-details": true,
        },
      });
      this.handleNavigation(to, from, next);
    } else if (to.name === "contactDetails" && from.name === "nomineeDetails") {
      this.$refs.superTopupFormContainer
        .validateForms("nomineeDetails")
        .then((allValid) => {
          if (allValid) {
            this.$store.commit("updateSuperTopupProspectStateMeta", {
              acknowledgedStages: {
                ...this.superTopupProspectState.acknowledgedStages,
                "nominee-details": true,
              },
            });
            this.handleNavigation(to, from, next);
          } else {
            next(false);
          }
        })
        .catch((error) => {
          console.error(error);
          this.$store.commit("addAlert", {
            variant: "danger",
            message: `Something went wrong, please contact your org admin`,
          });
          next(false);
        });
    } else if (to.name === "policySummary" && from.name === "contactDetails") {
      this.$refs.superTopupFormContainer
        .validateForms("contactDetails")
        .then((allValid) => {
          if (allValid) {
            this.$store.commit("updateSuperTopupProspectStateMeta", {
              acknowledgedStages: {
                ...this.superTopupProspectState.acknowledgedStages,
                "contact-details": true,
              },
            });
            this.handleNavigation(to, from, next);
          } else {
            next(false);
          }
        })
        .catch((error) => {
          console.error(error);
          this.$store.commit("addAlert", {
            variant: "danger",
            message: `Something went wrong, please contact your org admin`,
          });
          next(false);
        });
    }
  },
  data() {
    return {
      isPullUpOpen: false,
      showFullCard: false,
      prospectState: null,
      prospectId: null,
    };
  },
  computed: {
    ...mapState(["user"]),
    ...mapGetters(["getFeatureFlags"]),
    formattedStep() {
      return `${this.currentStepIndex + 1} / 4`;
    },
    summaryCardClass() {
      return {
        "pull-up open": this.isMobileViewport && this.isPullUpOpen,
        sticky: this.isMobileViewport,
      };
    },
    isPolicySummary() {
      return this.$route.path.includes("/policy-summary");
    },
    isMobileViewport() {
      return utils.mobileCheck() || utils.tabCheck() || mobileApp.isApp;
    },
  },
  async created() {
    window.addEventListener("beforeunload", this.beforeUnload);
    await this.createSuperTopupProspectState({});
    this.$store.commit("updateSuperTopupProspectStateMeta", {
      isFormValid: true,
    });
    this.$emit("storeLoaded", this.$store.state.loadedData);
    window.removeEventListener("beforeunload", this.beforeUnload);
  },
  methods: {
    getMarginBottomClass() {
      if (this.isMobileBrowser() || this.isApp()) {
        return "mb-10";
      } else {
        return "mb-5";
      }
    },
    handleNavigation(to, from, next) {
      this.isPullUpOpen = false;
      this.handleRouteChange(to, from, next);
    },
    getCardHeight() {
      // full height for Desktop view
      if (!this.isMobileViewport) {
        return "100%";
      }
      // Mobile viewport logic
      if (this.isPullUpOpen) {
        return "30rem"; // Height when pull-up is open
      } else {
        // Height based on current step and viewport dimensions
        if (this.currentStep === TopupRoutes.POLICY_SUMMARY) {
          return "13rem";
        } else if (
          this.currentStep === TopupRoutes.NOMINEE_DETAILS &&
          window.innerHeight < 760 &&
          window.innerWidth < 380
        ) {
          return "25vh"; // Height for nominee details step on small screens
        } else {
          return "17vh";
        }
      }
    },
    getStep(route) {
      const parts = route.split("/");
      return parts.pop();
    },
    proceedToNextStep() {
      this.$emit("pushDepStates");
      this.$store.commit("updateSuperTopupProspectStateMeta", {
        acknowledgedStages: {
          ...this.superTopupProspectState.acknowledgedStages,
          "add-members": true,
        },
      });
      this.$bvModal.hide("member-add-modal");
      this.$router.push(`/super-topups/${this.getNextStage(this.currentStep)}`);
    },
    getStepContent(currentStepIndex, field) {
      return TOPUP_CONSTANTS[this.currentStepIndex][field];
    },
    async beforeUnload(e) {
      e.preventDefault();
      const confirmationMessage = "Are you sure you want to leave this page. All unsaved changes will be lost?";
      e.returnValue = confirmationMessage;
    },
    goToPreviousStep() {
      const previousStep = this.getPreviousStep(this.currentStep);
      const currentPath = this.$route.path;
      let destinationPath;
      if (this.currentStep === TopupRoutes.ADD_MEMBERS) {
        destinationPath = "/topups";
      } else {
        const basePath = currentPath.substring(0, currentPath.lastIndexOf("/") + 1);
        destinationPath = basePath + previousStep;
      }
      this.$router.push(destinationPath);
    },
    handleRouteChange(to, from, next) {
      this.upsertProspectState(from?.params?.currentStep, to?.params?.currentStep);
      next();
    },
    togglePullUp() {
      if (!this.isPullUpDisabled()) {
        this.isPullUpOpen = !this.isPullUpOpen;
      }
    },
    async getProspectByEmailAndType() {
      try {
        const { data } = await this.$apollo.query({
          query: gql`
            query getProspectByEmailAndType($email: String!, $type: String!) {
              getProspectByEmailAndType(email: $email, type: $type) {
                ...Prospect
              }
            }
            ${prospectFragment}
          `,
          variables: { email: this.$store.state.user.email, type: ProspectType.SUPER_TOPUP },
          fetchPolicy: "no-cache",
        });
        return data;
      } catch (error) {
        console.error(`Error while fetching the prospect detail by email.`);
        throw error;
      }
    },
    async createSuperTopupProspectState() {
      const data = await this.getProspectByEmailAndType();
      if (data?.getProspectByEmailAndType) {
        this.prospectState = data?.getProspectByEmailAndType;
        const { id } = this.prospectState;
        if (id) {
          this.prospectId = id;
          const acknowledgedStages = this.prospectState.meta.superTopupProspectState?.acknowledgedStages;
          const lastAcknowledgedStep = Object.values(TopupRoutes)
            .reverse()
            .find((route) => acknowledgedStages?.[route]);
          if (lastAcknowledgedStep) {
            this.$router.push(`/super-topups/${lastAcknowledgedStep}`);
          } else {
            this.$router.push(`/super-topups/add-members`);
          }
        }
      } else {
        const result = await this.$apollo.mutate({
          mutation: resDefs.prospects.upsertMutation,
          variables: {
            email: this.$store.state.user.email,
            meta: {
              prospectType: ProspectType.SUPER_TOPUP,
              userId: this.$store.state.user.id,
              superTopupProspectState: this.superTopupProspectState,
            },
          },
        });
        this.prospectState = { ...result.data.upsertProspect.prospect };
        this.prospectId = this.prospectState.id;
      }
      this.$store.commit("saveSuperTopupProspectState", this.prospectState);
    },
    async upsertProspectState(currentStep, previousStep) {
      await this.synchronizeProspectStateAndEvent();
    },
    async synchronizeProspectStateAndEvent(event) {
      await this.$apollo.mutate({
        mutation: resDefs.prospects.upsertMutation,
        variables: {
          ...this.prospectState,
          ...this.$store.state.superTopupProspectState,
          id: this.prospectId || this.$store.state.superTopupProspectState.id,
        },
      });
      if (this.$store.state.superTopupProspectState.id) {
        await this.$apollo.mutate({
          mutation: resDefs.prospects.eventMutation,
          variables: {
            prospectId: this.$store.state.superTopupProspectState.id,
            eventName: this.currentStep,
            eventParams: {
              proposalForm: { ...this.$store.state.superTopupProspectState },
            },
          },
        });
      }
    },
    async upsertSuperTopup() {
      const policyDetail = {};
      if (
        this.getPlusPolicy?.id &&
        this.superTopupProspectState.policyDetail[this.getPlusPolicy.id]?.coveredMembers.length
      ) {
        policyDetail[this.getPlusPolicy?.id] = {
          coveredMembers: this.superTopupProspectState.policyDetail[this.getPlusPolicy?.id].coveredMembers,
          si: this.superTopupProspectState?.novaPlusPremiumDetails?.si,
          deductible: this.superTopupProspectState?.novaPlusPremiumDetails?.deductible,
        };
      }
      if (
        this.getExtendedPolicy?.id &&
        this.superTopupProspectState.policyDetail[this.getExtendedPolicy.id]?.coveredMembers.length
      ) {
        policyDetail[this.getExtendedPolicy?.id] = {
          coveredMembers: this.superTopupProspectState.policyDetail[this.getExtendedPolicy?.id].coveredMembers,
          si: this.superTopupProspectState?.novaExtendedPremiumDetails?.si,
          deductible: this.superTopupProspectState?.novaExtendedPremiumDetails?.deductible,
        };
      }

      const result = await this.$apollo.mutate({
        mutation: resDefs.retailPolicies.upsertSuperTopup,
        variables: {
          prospectId: this.prospectId,
          formData: {
            ...this.superTopupProspectState,
            policyDetail,
            healthDeclarationTimeStamp: new Date(),
          },
        },
      });
      const order = {
        description: `Super Topup`,
        pgOrderId: result.data.upsertSuperTopup.pgOrderId,
      };
      this.$bvModal.hide("final-ack-modal");
      displayRazorpay(order, this.onSuccess);
    },
    onSuccess(res) {
      this.$router.push({
        name: "payment_check",
        params: { pgOrderId: res.razorpay_order_id, productType: NovaProductType.SUPER_TOPUP },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
@import "@/assets/styles/mixins/_breakpoints.scss";

.pull-up {
  position: fixed;
  bottom: 0;
  left: 0;
  height: 0;
  width: 100%;
  transition: height 0.5s ease-in-out;
  background: #ffffff;
  z-index: 100;
}

.open {
  height: calc(100% - 58px);
}

.super-topups-container {
  @include media-breakpoint-up(md) {
    margin-top: 1.5rem;
  }
  @include media-breakpoint-down(sm) {
    margin-top: -2rem;
  }
}

@media (max-width: 768px) {
  .sticky {
    position: fixed;
    bottom: 0;
    padding: bottom 2%;
    width: 100%;
    z-index: 768 !important;
    background-color: white;
    border-top: 1px solid $gray-400;
  }
}

.drop-active {
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  position: absolute;
  z-index: 99;
  opacity: 0.6;
  text-align: center;
  background: rgba(0, 0, 0, 0.5);
}
</style>
