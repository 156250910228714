<template lang="pug">
span.d-flex
  template(v-for="metaData in normalItems")
    n-popover-chip.mr-1.my-1(
      variant="white",
      :set="(popoverContent = getPopOverData(metaData.status, metaData.benefitsType.toUpperCase(), changesType, metaData.benefitStatus))",
      :chipIcon="statusToIcon(metaData.status, metaData.benefitStatus)",
      :isMinimal="true",
      :chipId="`${metaData.id}-${metaData.status}-${itemId}`",
      :popoverTitle="popoverContent.title",
      :popoverHeading="popoverContent.heading",
      :status="metaData.status",
      :chipText="getChipTextForNItem(metaData)",
      :popoverDate="getDueDate().year",
      :popoverDescription="popoverContent.description",
      :popoverSubTitle="popoverContent.subTitle",
      :triggers="triggers",
      :borderVariant="getPillBorderVariant(metaData.benefitStatus)")
      template(v-slot:icon)
        n-icon.mr-1(
          :name="statusToIcon(metaData.status, metaData.benefitStatus)",
          :pathCount="2",
          :size="iconSize")
  template(v-if="plusItems?.length > 0")
    plus-n-chip.my-1(
      :id="plusItems[0].id",
      :chipText="plusItemChipText",
      :remainingItemsCount="plusItems.length")
    n-popover.custom-popup(
      :showHeader="false",
      :target="plusItems[0].id",
      :triggers="triggers",
      :set="(popoverContent = plusItems)",
      custom-class="custom-popup")
      template(v-slot:default)
          .d-flex.flex-column(v-if="getPopoverPolicies().length")
            .d-flex.align-items-center.mb-1.mt-2
              n-icon.mr-1(
                name="health-shield",
                :pathCount="2",
                variant="purple-600")
              .text-gray-900.font-weight-medium.font-sm.pr-7 Policies
            .d-flex.mb-2.flex-wrap
              div(v-for="metaData in getPopoverPolicies()")
                n-popover-chip.mr-1.my-1(
                  variant="white",
                  :set="(popoverContent = getPopOverData(metaData.status, metaData.benefitsType.toUpperCase(), changesType, metaData.benefitStatus))",
                  :chipIcon="statusToIcon(metaData.status, metaData.benefitStatus)",
                  :isMinimal="true",
                  :chipId="`${metaData.id}-${metaData.status}-${itemId}`",
                  :popoverTitle="popoverContent.title",
                  :popoverHeading="popoverContent.heading",
                  :status="metaData.status",
                  :chipText="getChipTextForNItem(metaData)",
                  :popoverDate="getDueDate().year",
                  :popoverDescription="popoverContent.description",
                  :popoverSubTitle="popoverContent.subTitle",
                  :triggers="triggers",
                  :borderVariant="getPillBorderVariant(metaData.benefitStatus)")
                    template(v-slot:icon)
                      n-icon.mr-1(
                      :name="statusToIcon(metaData.status, metaData.benefitStatus)",
                      :pathCount="2",
                      :size="iconSize")
          template(v-if="getPopoverBenefits().length")
            hr.my-0.mx-0
            .d-flex.flex-column
              .d-flex.align-items-center.mb-1.mt-2
                n-icon.mr-1(
                  name="benefits",
                  :pathCount="2",
                  variant="purple-600")
                .text-gray-900.font-weight-medium.font-sm.pr-7 Benefits
              .d-flex.flex-column
                .text-gray-900.font-weight-medium.font-sm.ml-0(v-for="benefit in getPopoverBenefits()") {{ benefit.benefitName }}
  template(v-if="statusText")
    b-badge.status-badge.align-self-center.mr-1.my-1.p-2(pill, :variant="statusChipVariant") {{ statusText }}
</template>

<script>
import PlusNChip from "./PlusNChip";
import NPopoverChip from "@/components/NovaPopoverChip.vue";
import NButton from "@/components/NovaButton.vue";
import utils from "@/utils";
import NChip from "@/components/NovaChip.vue";
import NModal from "@/components/NovaModal.vue";
import NPopover from "@/components/NovaPopover.vue";
import { BenefitStatus } from "../../../../common/enums/benefits/benefitStatus.enum";
import { UserBenefitStatus } from "../../../../common/enums/benefits/userBenefits.enum";
import { INACTIVE_USER_BENEFIT_STATUS } from "../../../../common/constants/benefits.js";

export default {
  name: "PillsGroup",
  components: {
    NButton,
    PlusNChip,
    NChip,
    NModal,
    NPopoverChip,
    NPopover,
  },
  props: {
    itemId: {
      type: String,
    },
    statusChipVariant: {
      type: String,
    },
    statusText: {
      type: String,
    },
    popoversData: {
      type: Object,
      default: () => {},
    },
    normalItems: {
      type: Array,
      default: () => [],
    },
    plusItems: {
      type: Array,
      default: () => [],
    },
    plusItemChipText: {
      type: String,
      default: "Benefits",
    },
    triggers: {
      type: String,
      default: "",
      validator: (propValue) => {
        const isValidTrigger = ["hover", "click"].includes(propValue);
        return isValidTrigger || !propValue;
      },
    },
    iconSize: {
      type: Number,
      default: 1.0,
    },
    changesType: {
      type: String,
      default: "",
    },
  },
  data() {
    return {};
  },
  created() {
    this.$options.getDateWithSuffix = utils.getDateWithSuffix;
  },
  methods: {
    getPopoverBenefits() {
      return this.plusItems.filter((item) => !item.isPolicy);
    },
    getPopoverPolicies() {
      return this.plusItems.filter((item) => item.isPolicy);
    },
    isMobileView() {
      return utils.mobileCheck();
    },
    statusToIcon(status, benefitStatus) {
      return INACTIVE_USER_BENEFIT_STATUS.includes(benefitStatus)
        ? utils.statusToIconDetails(BenefitStatus.EXPIRED)
        : utils.statusToIconDetails(status);
    },
    getDueDate() {
      const dueDate = utils.getLastDateOfMonth(new Date());
      return {
        dateString: this.$options.getDateWithSuffix(dueDate),
        month: utils.getMonth(dueDate),
        year: utils.getMonthYear(dueDate),
      };
    },
    getChipTextForNItem(data) {
      return data.benefitsType.toUpperCase();
    },
    getPillBorderVariant(benefitStatus) {
      if (INACTIVE_USER_BENEFIT_STATUS.includes(benefitStatus)) {
        return "danger";
      }
    },
    getPopOverData(status, benefit, type, benefitStatus = UserBenefitStatus.CREATED) {
      let popover;
      if (INACTIVE_USER_BENEFIT_STATUS.includes(benefitStatus)) {
        popover = this.popoversData[BenefitStatus.EXPIRED];
        popover.heading = popover.heading.replace("{policyType}", benefit.toUpperCase());
        return popover;
      }
      type = type === "update" ? "add" : type;
      popover = this.popoversData[type][status];
      let title = popover?.title;
      let subTitle = popover?.subTitle;
      const description = popover?.description;
      if (status !== "done" && status !== "archive") {
        title = benefit + popover?.title;
        subTitle = subTitle + this.getDueDate().dateString;
      }
      return { heading: popover?.heading, title, description, subTitle };
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";

.custom-popup {
  text-align: center;
  hr {
    width: 100%;
    border-color: $gray-300;
  }
  .text-purple-600 {
    .path1:before {
      color: $purple-600 !important;
    }
    .path2:before {
      color: $purple-600 !important;
    }
  }
}

@media (max-width: 600px) {
  .status-badge {
    order: -1;
  }
}
</style>
