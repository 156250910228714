import gql from "graphql-tag";
import { claimFragment, claimFilesFragment } from "../fragments";

export default {
  upsertMutation: gql`
    mutation upsertClaim(
      $id: ID
      $userId: ID!
      $policyId: ID!
      $dependentId: ID
      $claimOwnerId: ID
      $meta: JSONObject!
      $csat: JSONObject
      $status: ClaimStatusEnum
      $type: String
    ) {
      upsertClaim(
        input: {
          id: $id
          userId: $userId
          policyId: $policyId
          dependentId: $dependentId
          claimOwnerId: $claimOwnerId
          meta: $meta
          csat: $csat
          type: $type
          status: $status
        }
      ) {
        claim {
          ...Claim
        }
      }
    }
    ${claimFragment}
  `,
  runIntimationMutation: gql`
    mutation intimateClaim($claimId: ID!, $tpaName: String) {
      intimateClaim(input: { claimId: $claimId, tpaName: $tpaName }) {
        isSuccess
      }
    }
  `,
  runQueryMutation: gql`
    mutation queryRespondedCA($claimId: ID!, $queryId: ID) {
      queryRespondedCA(input: { claimId: $claimId, queryId: $queryId }) {
        status
      }
    }
  `,
  upsertClaimFiles: gql`
    mutation upsertClaimFiles(
      $id: ID
      $claimId: ID
      $file: [JSONObject]
      $docType: String
      $createSource: String
      $isUploaded: Boolean
      $isRecentlyUploaded: Boolean
      $action: String
      $fileType: String
    ) {
      upsertClaimFiles(
        input: {
          id: $id
          claimId: $claimId
          file: $file
          docType: $docType
          createSource: $createSource
          isUploaded: $isUploaded
          isRecentlyUploaded: $isRecentlyUploaded
          action: $action
          fileType: $fileType
        }
      ) {
        file {
          ...ClaimFiles
        }
      }
    }
    ${claimFilesFragment}
  `,
};
