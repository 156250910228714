import { groupBy } from "lodash-es";
import moment from "moment";
import { AcceptedRelations } from "../common/enums";
import misc from "./misc";

export const genderList = [
  { id: "female", name: "Female" },
  { id: "male", name: "Male" },
  { id: "other", name: "Other" },
];
export const dependentRelationList = [
  { id: "child", name: "Child" },
  { id: "parent", name: "Parent" },
  { id: "parent-in-law", name: "Parent-in-law" },
  { id: "spouse", name: "Spouse" },
];
export const userPriorityList = [
  { id: "standard", name: "standard" },
  { id: "high", name: "high" },
];
export const orgOnboardingTasksEtaList = [
  {
    model: "TEAM_VERIFICATION",
    label: "TEAM_VERIFICATION (in days)",
    type: "input",
    inputType: "number",
  },
  {
    model: "TEAM_DOCUMENT_VERIFICATION",
    label: "TEAM_DOCUMENT_VERIFICATION (in days)",
    type: "input",
    inputType: "number",
  },
  {
    model: "BENEFIT_UNDER_PROCESS",
    label: "BENEFIT_UNDER_PROCESS (in days)",
    type: "input",
    inputType: "number",
  },
  {
    model: "BENEFIT_LIVE",
    label: "BENEFIT_LIVE (in days)",
    type: "input",
    inputType: "number",
  },
  {
    model: "ISSUE_POLICY_COPY",
    label: "ISSUE_POLICY_COPY (in days)",
    type: "input",
    inputType: "number",
  },
  {
    model: "ISSUE_ECARDS",
    label: "ISSUE_ECARDS (in days)",
    type: "input",
    inputType: "number",
  },
];

const benefitCategoryEnum = Object.freeze({
  PHYSICAL_WELLNESS: "physical_wellness",
  MENTAL_WELLNESS: "mental_wellness",
  SOCIAL_WELLNESS: "social_wellness",
  FINANCIAL_WELLNESS: "financial_wellness",
  PERSONAL_WELLNESS: "personal_wellness",
});

export const benefitCategoryList = [
  { value: benefitCategoryEnum.PHYSICAL_WELLNESS, label: "Physical Wellness" },
  { value: benefitCategoryEnum.MENTAL_WELLNESS, label: "Mental Wellness" },
  { value: benefitCategoryEnum.SOCIAL_WELLNESS, label: "Social Wellness" },
  { value: benefitCategoryEnum.FINANCIAL_WELLNESS, label: "Financial Wellness" },
  { value: benefitCategoryEnum.PERSONAL_WELLNESS, label: "Personal Wellness" },
];

export const subCategoryByBenefitCategory = {
  [benefitCategoryEnum.PHYSICAL_WELLNESS]: [
    "Telehealth",
    "OPD",
    "Diagnostic",
    "Pharmacy",
    "Health Check-ups",
    "Health Risk Assessment",
    "Dental",
    "Vision",
    "Women Care Program",
    "Fitness",
    "Disease management program",
    "Surgery & Emergency",
  ],
  [benefitCategoryEnum.MENTAL_WELLNESS]: [
    "Risk Assessment",
    "1-1 counselling and therapy",
    "Support Groups",
    "Suicide Prevention Protocols and Program",
    "Psychiatric Treatment",
  ],
  [benefitCategoryEnum.SOCIAL_WELLNESS]: [
    "Exclusive Engagement Program",
    "Corporate Gifting",
    "Rewards and Recognition",
    "Hobby Spaces",
    "Co-living and Co-working Spaces",
    "Meal Subscription",
  ],
  [benefitCategoryEnum.FINANCIAL_WELLNESS]: [
    "1-1 financial consultation",
    "Advance Salary Credit",
    "Healthcare credit line",
  ],
  [benefitCategoryEnum.PERSONAL_WELLNESS]: [
    "L&D",
    "POSH & DI",
    "Personal Care Marketplace",
    "Pet Care",
    "Child Care",
    "Elder Care",
    "Travel Expenses",
    "Work-device buyback",
    "Referral program",
    "Meals",
    "Engagement Spaces",
    "Internet and work-gadget reimbursements",
    "Leave Policy",
  ],
};

export default {
  isDependentRow(data) {
    return data.item.user_type === "dependent";
  },
  getMissingDataForMember(member, isUser = true, hasNoCoverageStart = false) {
    const missingFields = [];
    // check for profile fields
    const requiredPersonalInfoFields = ["name", "gender", "dob"];
    if (!isUser) {
      requiredPersonalInfoFields.push("relation");
      if (member.user_type !== "placeholder") {
        member.gender = member.dep_gender;
        member.dob = member.dep_dob;
        // FIXME: Remove nesting, clean up code
        if (member.depMeta?.isMidTermAddition === true && member.relation === AcceptedRelations.SPOUSE) {
          const requiredMidTermMetaFieldsForSpouse = ["dom"];
          requiredMidTermMetaFieldsForSpouse.forEach((field) => {
            if (!member.depMeta[field]) {
              missingFields.push(field);
            }
          });
        }
      }
    }

    requiredPersonalInfoFields.forEach((field) => {
      if (!member[field]) {
        missingFields.push(field);
      } else {
        // TODO: Outsource this validation logic to/from vfg validators
        // for identical fields
        switch (field) {
          case "gender":
            if (!genderList.find((genderObj) => genderObj.id === member[field])) {
              missingFields.push(field);
            }
            break;
          case "relation":
            if (!dependentRelationList.find((relationObj) => relationObj.id === member[field])) {
              missingFields.push(field);
            }
            break;
        }
      }
    });

    if (isUser) {
      // Employee specific validation
      const requiredEmployeeMetaFields = [
        "employeeId",
        // TODO: Confirm if contactNumber is a required field
        // "contactNumber",
      ];
      if (hasNoCoverageStart) requiredEmployeeMetaFields.push("dateOfJoining");
      requiredEmployeeMetaFields.forEach((field) => {
        if (!member.userMeta[field]) {
          missingFields.push(field);
        }
      });
    }

    return missingFields;
  },
  checkIfHasNoCoverageStart(meta) {
    return meta
      ?.filter((obj) => obj.isPolicy)
      ?.some(
        (obj) =>
          obj.changeMeta?.coverageStartDate === null ||
          obj.changeMeta?.coverageStartDate === "" ||
          !obj.changeMeta?.coverageStartDate
      );
  },
  getUserChangeIds(clubbedChanges) {
    const ids = [];

    clubbedChanges.forEach(
      (clubbedChange) =>
        clubbedChange.selected &&
        clubbedChange.user_type !== "placeholder" &&
        clubbedChange.user_changes.forEach((changeData) => ids.push(changeData.id))
    );
    return ids;
  },
  getUserChangeStringForUpdate(data) {
    return Object.keys(data?.user_obj || {})
      .map((value) => value.toUpperCase())
      .join(",");
  },
  getTotalPremiumAcrossInsurers(premiumBreakup, withRupeeSym = false) {
    if (premiumBreakup && Object.values(premiumBreakup).length) {
      return misc.getFormattedINRWithFloatingPoint(
        Object.values(premiumBreakup).reduce(
          (previousValue, currentValue) =>
            (previousValue += currentValue?.premiumAddition - currentValue?.premiumDeduction),
          0
        ),
        withRupeeSym
      );
    }
    return "_";
  },
  getTotal(arr, prop) {
    let sum = 0;
    arr.forEach((item) => {
      sum += item[prop];
    });
    return sum;
  },
  hasSufficientCDBalance(batch, org) {
    const insurers = {};
    const orgBenefits = {};
    if (org && org.benefits) {
      org.benefits.forEach((benefit) => {
        if (!["expired", "deleted"].includes(benefit.node.status)) {
          orgBenefits[benefit.node.id] = benefit.node;
        }
      });
    }
    if (
      batch?.meta?.premiumData?.policyWisePremiumEstimation &&
      Object.values(batch?.meta?.premiumData?.policyWisePremiumEstimation).length
    ) {
      Object.entries(batch.meta.premiumData.policyWisePremiumEstimation).forEach(([id, policyEstimate]) => {
        const insurer = orgBenefits[id]?.insurer;
        if (insurer) {
          if (!insurers[insurer.id]) {
            insurers[insurer.id] = {
              insurer,
              policies: [],
            };
          }
          if (org.meta.insurerWiseCDBalance && org.meta.insurerWiseCDBalance[insurer.id]) {
            insurers[insurer.id].insurer = {
              ...insurers[insurer.id].insurer,
              ...org.meta.insurerWiseCDBalance[insurer.id],
            };
          }
          insurers[insurer.id].policies.push({
            ...policyEstimate,
            ...orgBenefits[id],
          });
        }
      });
    }
    return Object.values(insurers).every((obj) => {
      const premiumEstimateForInsurer = obj.policies.reduce((previousValue, currentValue) => {
        return (previousValue += currentValue.premiumAddition - currentValue.premiumDeduction);
      }, 0);
      if (obj.insurer.cdBalance >= premiumEstimateForInsurer) {
        return true;
      } else {
        return false;
      }
    });
  },
  getBatchPremiumPaymentStatus(batch, org) {
    const _status = {};
    if (batch && batch.meta.premiumData) {
      if (batch.meta.premiumData.isPaid) {
        _status.text = "PAID";
        _status.textColor = "text-success";
      } else {
        // batch premium has not been paid
        if (this.hasSufficientCDBalance(batch, org)) {
          _status.text = "PENDING";
          _status.textColor = "text-mustard-600";
        } else {
          _status.text = "INSUFFICIENT CD BALANCE";
          _status.textColor = "text-red-600";
        }
      }
    } else {
      _status.text = "-";
      _status.textColor = "";
    }

    return _status;
  },
  extractDependentBenefitEdge(benefits) {
    const validBenefits = [];
    benefits.forEach((benefit) => {
      benefit.node?.id &&
        validBenefits.push({
          id: benefit.node?.id,
          meta: benefit.meta,
        });
    });
    return validBenefits;
  },
  getChangedDependentAndBenefitInfo(dependent, user) {
    const changedDependentInfo = {};
    const changedBenefitInfo = {};
    changedBenefitInfo.overrides = {};
    changedBenefitInfo.benefits = dependent?.benefits.map((item) => {
      return item.node.id;
    });
    const oldDependentIdx = user?.dependents.findIndex((p) => p.id === dependent.id);
    for (const key in dependent) {
      if (dependent[key] !== user?.dependents[oldDependentIdx][key]) {
        changedDependentInfo[key] = dependent[key];
        changedBenefitInfo.overrides[key] = user?.dependents[oldDependentIdx][key];
      }
    }
    return { changedDependentInfo, changedBenefitInfo };
  },
};

export function mapBenefitsToOrgGrades(org) {
  const orgGrades = {};
  org.meta?.grades?.forEach((grade) => {
    orgGrades[grade] = [];
  });
  // safety check till grade mapping is fully tested
  if (Object.keys(orgGrades).length === 0) orgGrades.DEFAULT = [];
  org.benefits.forEach((benefit) => {
    if (moment().isAfter(moment(benefit.node.endDate))) {
      return;
    }
    for (let [grade, gradeMapping] of Object.entries(benefit.meta.gradeMapping)) {
      // if the grade is named default, add the benefit as a default grade
      if (grade.toUpperCase() === "DEFAULT") grade = grade.toUpperCase();
      pushBenefitsToGrade(benefit, orgGrades, grade, gradeMapping);
    }
  });

  // push the benefits to the grade based on the grade type and mapping
  function pushBenefitsToGrade(benefit, orgGrades, grade = "DEFAULT", gradeMapping = {}) {
    if (orgGrades?.[grade] && benefit.node?.isPolicy) {
      orgGrades[grade].push({
        benefitName: benefit.node?.name,
        amount:
          benefit.meta?.isVariableCtc && !gradeMapping?.isSumInsuredFixed
            ? `${gradeMapping?.variableSiFactor}${gradeMapping?.variableSiText}`
            : gradeMapping?.si,
        familyDef: gradeMapping?.familyDefinition,
        isPolicy: benefit.node?.isPolicy,
        endDate: benefit.node?.meta?.endDate,
        isCustomGrade: !!gradeMapping.isCustomGrade,
        type: benefit.node.type,
        getCoveredDependents: benefit.node.getCoveredDependents,
      });
    } else if (orgGrades?.[grade]) {
      orgGrades[grade].push({
        benefitName: benefit.node?.name,
        familyDef: gradeMapping?.familyDefinition,
        isPolicy: benefit.node?.isPolicy,
        type: benefit.node?.type,
        isCustomGrade: !!gradeMapping.isCustomGrade,
        getCoveredDependents: benefit.node.getCoveredDependents,
      });
    }
  }
  return orgGrades;
}
export function groupSegmentsByType(segments) {
  return groupBy(segments, (arrObj) => {
    return arrObj.node?.type;
  });
}
