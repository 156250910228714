<template lang="pug">
.timeline.px-3.pb-4.pt-1
  b-container
    b-row
      b-col.px-1(v-for="state in states") 
        .timeline-item.d-flex.align-items-start.justify-content-start.flex-column
          hr.line.mb-2(:class="`text-${state.lineColor}`")
          .d-flex.align-items-center
            i.mr-2(:class="`icon-${state.icon} text-${state.color} color-${state.color}`")
            span.mr-1.font-lg.font-weight-semibold(:class="`text-${state.headingColor}`") {{ state.heading }}
            i.icon-question.ques-title.cursor-pointer(v-b-tooltip.hover.top="state.info")
          NovaChip.mt-2.ml-3.font-xs(:variant="state.chipVariant", pill) {{ state.status }}
</template>

<script>
import NovaChip from "./NovaChip.vue";

export default {
  name: "NProgressTimeline",
  components: { NovaChip },
  props: {
    states: {
      type: Array,
      required: true,
      // for states format, check out the story of the component in stories/NovaProgressTimeline.stories.js
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
@import "@/assets/styles/_typography.scss";

.timeline {
  background-color: $white;
  border: $border-width-custom-1 solid $gray-300;
  border-radius: $border-radius;

  .timeline-item {
    .line {
      width: 100%;
      border-top: $border-width-custom-5 solid;
      margin-top: 1rem;
      display: block;
      border-radius: $border-radius-pill;
    }

    .icon-progress-1-wider {
      border-radius: $border-radius-pill;
      width: 1.125rem;
      border: $border-width-custom-7 solid;
      height: 1.125rem;
    }
    .icon-progress-1-thinner {
      border-radius: $border-radius-pill;
      width: 1.125rem;
      border: $border-width-custom-2 solid;
      height: 1.125rem;
    }

    .ques-title {
      font-size: 13px;
      color: $gray-500;
    }
  }
}
</style>
