<template lang="pug"> 
  .status-card.d-flex.flex-column.bg-white.border.border-1.py-3.px-3.mb-3(v-cloak)
    .hb6.title ENDORSEMENT STATUS
    .h8.text-gray-700.mt-1.sub-title The policies will be activated once approved by the Insurer. Takes 1-3 weeks to complete the process.
    .d-flex.justify-content-center.flex-column.mt-2(@mouseleave="hideTooltip()")
      b-progress.progess-wrapper
        b-progress-bar.w-25(
          v-for="(currentStep, index) in getSteps",
          :key="index",
          :label="steps[currentStep].label",
          @mouseenter.native="showTooltip(index,currentStep,$event)",
        )
          .d-flex.align-items-center.flex-nowrap(:id="`status-${currentStep}`")
            n-icon.text-teal-700.mr-2(:name="steps[currentStep]?.progress", :pathCount="2", :size="1")
            .progress-indicator-label.p-0.m-0.d-md-none.d-lg-block(
              :class="index <= getActiveStepIndex ? 'active-label' : 'inactive-label'") {{ currentStep }}
            .progress-indicator-line.linear.mx-2(:class="index < 1 || getActiveStepIndex  > index ? 'active-line' : 'inactive-line'", v-if="index !== getSteps.length - 1")
      template(v-if="isSubStatusChannelOpen")
        .tooltip-wrapper.d-flex.mt-3.w-100.justify-content-center
          .arrow(v-if="tooltipTop && tooltipLeft" :style="{ top:tooltipTop + 'px', left: tooltipLeft + 'px' }")
            .left-edge 
            .right-edge
            .bottom-edge
          .d-flex.w-75(:class="[subStagesPosition]")
            .d-flex.flex-nowrap.sub-step
              .d-flex.m-2(v-for="(item, index) in steps[activeStep]?.subStages")
                .d-flex.align-items-top
                  n-icon.p-0.m-0.mr-1(:name="getIconName(item?.status)", :size="1", variant="teal-900")
                  .h6.m-0.text-wrap(:class="item.status === 'completed' ? 'active-substage-label': 'text-gray-600'") {{ item?.label }}   
</template>

<script>
import { UserChangeBatchSteps } from "../../../../../common/enums/userChangeBatchStatus.enum";
import NPopover from "@/components/NovaPopover.vue";

export default {
  name: "StatusCard",
  components: { NPopover },

  props: {
    steps: {
      type: Object,
      default: () => {},
      required: true,
    },
  },
  data() {
    return {
      showTooltipIndex: null,
      activeStep: this.getDefaultActiveStepKey(),
      tooltipTop: 0,
      tooltipLeft: 0,
    };
  },
  computed: {
    getActiveStepIndex() {
      let index = 0;
      for (const stepKey of this.getSteps) {
        index++;
        if (this.steps[stepKey].isActive) {
          break;
        }
      }
      return index - 1;
    },
    isSubStatusChannelOpen() {
      if (
        this.activeStep &&
        [UserChangeBatchSteps.APPROVED_BY_HR, UserChangeBatchSteps.REVIEWED_BY_NOVA].includes(this.activeStep)
      ) {
        return false;
      }
      return (this.steps[this.activeStep]?.subStages || []).length > 0;
    },
    getSteps() {
      return Object.keys(this.steps);
    },
    subStagesPosition() {
      if (this.showTooltipIndex === 2) {
        return "sub-stages-center";
      } else if (this.showTooltipIndex === 3) {
        return "sub-stages-right";
      } else {
        return "sub-stages-end";
      }
    },
  },
  mounted() {
    this.showTooltipIndex = this.getActiveStepIndex;
    this.defaultPositionForArrow();
  },
  methods: {
    showTooltip(index, hoveredStep, event) {
      if (
        hoveredStep !== this.activeStep &&
        hoveredStep &&
        ![UserChangeBatchSteps.APPROVED_BY_HR, UserChangeBatchSteps.REVIEWED_BY_NOVA].includes(hoveredStep)
      ) {
        this.showTooltipIndex = index;
        this.activeStep = hoveredStep;
        const progressBar = event.currentTarget;
        if (progressBar) {
          this.calculateArrowPosition(progressBar);
        }
      }
    },
    calculateArrowPosition(progressBar) {
      const progressBarLabel = progressBar.querySelector(".progress-indicator-label");
      let progressBarLabelWidth = 0;
      if (progressBarLabel) {
        progressBarLabelWidth = progressBarLabel.getBoundingClientRect()?.width;
      }
      const stepRect = progressBar.getBoundingClientRect();
      this.tooltipTop = stepRect?.bottom + window.scrollY + 8;
      this.tooltipLeft = stepRect?.left + progressBarLabelWidth / 2 + 10;
    },
    getIconName(status) {
      return status === "completed" ? "check" : "color-progress-0";
    },
    hideTooltip() {
      this.showTooltipIndex = this.getActiveStepIndex;
      this.activeStep = this.getDefaultActiveStepKey();
      this.defaultPositionForArrow();
    },
    getDefaultActiveStepKey() {
      return Object.keys(this.steps).find((step) => this.steps[step]?.isActive);
    },
    defaultPositionForArrow() {
      if (this.steps && Object.keys(this.steps).length) {
        const defaultStepIndex = Object.keys(this.steps).findIndex((step) => this.steps[step]?.isActive);
        if (defaultStepIndex !== -1) {
          const progressBarSelector = `.progess-wrapper .progress-bar:nth-child(${defaultStepIndex + 1})`;
          const defaultProgressBar = this.$el.querySelector(progressBarSelector);
          if (defaultProgressBar) {
            this.calculateArrowPosition(defaultProgressBar);
          }
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
@import "@/assets/styles/_typography.scss";
.status-card {
  box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.04);
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.06);
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
}
.title {
  letter-spacing: 0.2rem;
}
.sub-title {
  font-size: 12px;
}
.progess-wrapper {
  width: 100%;
  height: 2rem;
  .progress-bar {
    width: 100%;
    background-color: white;
    :hover {
      cursor: pointer;
    }
  }
  .active-label {
    font-weight: 500 !important;
    font-size: 12px;
    color: $gray-800;
  }
  .inactive-label {
    font-weight: 500 !important;
    font-size: 12px;
    color: $gray-600;
  }
  .progress-indicator-line {
    background-color: transparent;
    flex-grow: 1;
    &.active-line {
      border-top: 0.15rem dashed $teal-800 !important;
      background-color: transparent;
    }
    &.inactive-line {
      border-top: 0.15rem dashed $gray-400 !important;
      background-color: transparent;
    }
  }
}
.active-substage-label {
  font-weight: 600 !important;
  color: $teal-900;
}

.arrow {
  position: absolute;
  width: 10px;
  .right-edge {
    position: absolute;
    height: 10px;
    width: 1.5px;
    z-index: 6;
    background-color: $gray-400;
    transform: rotate(-45deg);
    top: 0;
    right: 1px;
  }
  .left-edge {
    position: absolute;
    height: 10px;
    width: 1.5px;
    z-index: 6;
    background-color: $gray-400;
    transform: rotate(45deg);
    top: 0;
    left: 1px;
  }
  .bottom-edge {
    position: absolute;
    width: 1.5px;
    z-index: 5;
    background-color: white;
    height: 10.5px;
    top: 3px;
    transform: rotate(90deg);
    left: 4.5px;
    right: 0;
    z-index: 5;
  }
}

.sub-step {
  font-size: 10px;
  border-top: 1px solid $gray-300;
}
.sub-stages-end {
  justify-content: flex-end !important;
  margin-right: -10%;
}
.sub-stages-right {
  justify-content: center !important;
  margin-right: -10%;
}
.sub-stages-center {
  justify-content: center !important;
  margin-left: -5%;
}
</style>
