import gql from "graphql-tag";

export default {
  generateOtpMutation: gql`
    mutation generateOtp($contactNumber: String!, $userId: ID!) {
      generateOtp(input: { contactNumber: $contactNumber, userId: $userId }) {
        status
      }
    }
  `,
  verifyOtpMutation: gql`
    mutation verifyOtp($contactNumber: String!, $otp: String!, $userId: ID!, $consent: JSONObject) {
      verifyOtp(input: { contactNumber: $contactNumber, otp: $otp, userId: $userId, consent: $consent }) {
        status
      }
    }
  `,
};
