<template lang="pug">
.checkup-address-selection
  template
    n-spinner(:active="loading", background-color="rgba(255,255,255,0.5)")
    .d-none.d-md-flex.align-items-center.justify-content-between.checkup-address-header
      .d-flex.align-items-center
        n-icon(name="location", :size="1")
        .text-gray-900.font-lg.font-weight-semibold.ml-2 {{ !isSelectingAddress ? "Select Address" : "Add Address" }}
      .d-flex.align-self-start.justify-content-center.align-items-center.rounded-4.font-xs.font-weight-semibold.px-2.py-1(
        v-if="sampleCollectionConfig?.sampleCollectionTitle", :class="sampleCollectionConfig.class")
        n-icon.mr-1(:name="sampleCollectionConfig.iconName", :size="0.7", :variant="sampleCollectionConfig.variant")
        | {{ sampleCollectionConfig?.sampleCollectionTitle }}
    .checkup-address-wrapper(v-if="!isSelectingAddress")
      .d-flex.align-items-start.flex-column
        .address-selection.d-flex.align-items-center.mb-1.cursor-pointer.w-100(v-for="address in userAddresses"
          @click="selectUserAddress(address)")
          .d-flex.flex-column
            .d-flex.align-items-center
              b-form-radio.mt-1(name="select-address", :value="true", v-model="address.isSelected")
              .font-md.font-weight-semibold.text-gray-900 {{ address.addressLineOne }}
            .d-flex
              b-form-radio.invisible(name="select-address-invisible", :value="true", v-model="address.isSelected")
              .font-sm.font-weight-medium.text-gray-700 {{ simplifiedAddress(address) }}
        span.add-address-wrapper.d-flex.align-items-center.pl-md-2.mt-2.w-100(@click="addNewAddressHandler")
          i.font-md.text-blue-600.font-weight-semibold.fa.fa-plus
          .font-md.text-blue-600.font-weight-semibold.ml-2.cursor-pointer Add new address
    .checkup-address-wrapper(v-else, :class="{ 'checkup-address-selection-wrapper': selectedAddress?.address_line_two }")
      .bg-white.rounded.d-block(v-if="selectedAddress?.address_line_two")
        .d-flex.justify-content-between.align-items-center.mb-2.px-2
          .d-flex.align-items-center
            span.font-sm.font-weight-medium.text-gray-700.pl-1.pr-5.pb-2.pt-1.pt-md-0
              | {{ selectedAddress.address_line_two }}
          .d-flex.align-items-center
            n-icon.align-middle.cursor-pointer(name="edit",
              variant="gray-700",
              :size="1",
              :pathCount="2",
              @click="handleResetAddress")
      .bg-white.rounded(v-else)
        n-search-bar.mb-3(v-model="searchText",
          @input="debouncedSearch",
          :placeholder="placeholder",
          :searchResults="searchResults",
          @select-result="updateSelectedAddress")
        .d-flex.px-2.justify-content-end.mt-2
          span.d-flex.align-items-center(@click="handleFetchLocation")
            n-icon.align-middle.cursor-pointer(
              name="location",
              variant="blue-600",
              :size="1",
              :pathCount="2")
            .text-blue-600.font-md.font-weight-medium.ml-1.cursor-pointer.location-link Use my current location
      .mb-2.mt-1(v-if="isSelectingAddress && selectedAddress?.address_line_two")
        hr.text-gray-600.mt-2.mx-2
        vue-form-generator(:schema="addressSchema.schema",
          :model="selectedAddress",
          :options="resOptions",
          ref="formData")
    .mt-3.mb-2(v-if="isHomeCollectionCheckupPackage")
      .info-banner.shadow-sm.border.border-mustard-200.rounded-2(:class="[alertBanner.bgColor]")
        .d-flex.justify-content-start.align-items-start
          .mr-1
            n-icon(:name="alertBanner.iconName", :class="alertBanner.iconClass", :pathCount="2", :size="0.9")
          .text-gray-900.font-sm.font-weight-medium {{ alertBanner.message }}
    b-modal(
      id="location-access-denied-modal",
      size="md",
      centered,
      hide-footer)
      template(v-slot:modal-title)
        n-icon.text-gray-900.align-middle(name="location")
        span.ml-2.text-gray-900.font-weight-semibold Location Access Required
      span.text-gray-900.font-md.font-weight-medium.text-center
        | {{ locationErrorMessage }}
    checkup-footer.d-block(
      variant="success",
      :disableButtonTextOverride="true",
      :buttonText="confirmButtonText",
      :isButtonDisabled="isButtonDisabled",
      @goBack="goBack"
      @continueCheckupFlow="goToNextStep")

</template>

<script>
import { mapState, mapGetters } from "vuex";
import debounce from "lodash.debounce";
import * as checkupUtils from "../checkupUtils";
import resDefs from "../../orgAdmin/definitions";
import userAddressesDefs from "../../admin/mutations/userAddresses";
import NSearchBar from "../../../../components/NovaSearchBar.vue";
import mobileApp from "../../../mobileApp";
import { MY_INFO } from "../../../../common/definitions/auth";
import CheckupFooter from "./CheckupFooter.vue";
import NAlert from "@/components/NovaAlertBanner.vue";
import NInlineInput from "@/components/NovaInlineInput.vue";
import NCheckboxInput from "@/components/NovaCheckbox.vue";
import NButton from "@/components/NovaButton.vue";
import { CheckupPackageSampleCollectionType } from "@/common/enums";

export default {
  name: "CheckupAddressSelection",
  components: {
    NButton,
    CheckupFooter,
    NInlineInput,
    NCheckboxInput,
    NAlert,
    NSearchBar,
  },
  data() {
    const alertBanner = {
      message:
        "The service provider will collect your samples from this address. Please ensure you add your complete address",
      variant: "warning",
      bgColor: "bg-mustard-100",
      iconClass: "text-mustard-700",
      iconName: "info",
    };
    const addressSchema = {
      schema: {
        fields: [
          {
            type: "inline-input",
            inputType: "text",
            label: `<span class='text-gray-700'>Address Line 1 <span style="color: red">*</span></span>`,
            placeholder: "House No/ Flat No",
            model: "address_line_one",
            required: true,
            min: 3,
            max: 160,
            validator: ["string", "required"],
            styleClasses: "d-inline-flex col-md-6 flex-column",
            labelClasses: "font-sm font-weight-medium",
            noPadding: true,
          },
          {
            type: "inline-input",
            inputType: "text",
            label: `<span class='text-gray-700'>Address Line 2 <span style="color: red">*</span></span>`,
            placeholder: "Landmark near your house",
            model: "address_line_two",
            styleClasses: "d-inline-flex col-md-6 flex-column",
            labelClasses: "font-sm font-weight-medium",
            noPadding: true,
            required: true,
            validator: ["string", "required"],
          },
          {
            type: "inline-input",
            inputType: "text",
            placeholder: "City",
            label: "<span class='text-gray-700'>City</span>",
            model: "city",
            disabled: true,
            styleClasses: "d-inline-flex col-md-6 flex-column",
            labelClasses: "font-sm font-weight-medium",
            noPadding: true,
          },
          {
            type: "inline-input",
            inputType: "text",
            placeholder: "State",
            label: "<span class='text-gray-700'>State</span>",
            model: "state",
            disabled: true,
            styleClasses: "d-inline-flex col-md-6 flex-column",
            labelClasses: "font-sm font-weight-medium",
            noPadding: true,
          },
          {
            type: "inline-input",
            inputType: "text",
            placeholder: "Country",
            label: "<span class='text-gray-700'>Country</span>",
            model: "country",
            disabled: true,
            styleClasses: "d-inline-flex col-md-6 flex-column",
            labelClasses: "font-sm font-weight-medium",
            noPadding: true,
          },
          {
            type: "inline-input",
            inputType: "text",
            placeholder: "Pincode",
            label: `<span class='text-gray-700'>Pincode</span>`,
            disabled: true,
            model: "pincode",
            styleClasses: "d-inline-flex col-md-6 flex-column",
            labelClasses: "font-sm font-weight-medium",
            noPadding: true,
          },
          // TODO: Uncomment if we want to save this data later
          // {
          //   model: "type",
          //   type: "select-cards",
          //   label: "Save address as",
          //   cardsData: [
          //     {
          //       name: "type",
          //       label: "Home",
          //       card_value: "home",
          //     },
          //     {
          //       name: "type",
          //       label: "Friends & Family",
          //       card_value: "friends_family",
          //     },
          //     {
          //       name: "type",
          //       label: "Others",
          //       card_value: "other",
          //     },
          //   ],
          //   required: true,
          // },
        ],
      },
    };
    return {
      isSelectingAddress: false,
      loading: false,
      placeholder: "Search your location",
      searchText: "",
      searchResults: [],
      addressSchema,
      resOptions: {
        validateAfterLoad: false,
        validateAfterChanged: true,
        validateAsync: true,
      },
      resModel: {},
      selectedAddress: null,
      locationErrorMessage:
        "To detect your location, please enable location access for NovaBenefits in your device settings.",
      alertBanner,
    };
  },
  computed: {
    ...mapState(["user"]),
    ...mapGetters(["ahcProspectState"]),
    isCheckupAdmin() {
      if (!this.user?.roles) return false;
      return this.user.roles.includes("checkup_admin");
    },
    bookingUserData() {
      return this.isCheckupAdmin ? this.$route.params?.bookingUser : this.user;
    },
    prospectStateSelectedAddress() {
      return this.ahcProspectState?.selectedAddress || {};
    },
    isButtonDisabled() {
      return !(Object.keys(this.selectedAddress || {}).length || Object.keys(this.prospectStateSelectedAddress).length);
    },
    selectedGroupPackage() {
      return this.ahcProspectState.selectedGroupPackage || {};
    },
    checkupPackages() {
      return this.selectedGroupPackage?.checkupPackages || [];
    },
    sampleCollectionConfig() {
      let sampleCollectionConfig = checkupUtils.getSampleCollectionTitleAndIcon(
        this.checkupPackages[0]?.sampleCollectionType || ""
      );
      if (this.checkupPackages?.length > 1) {
        sampleCollectionConfig = { ...sampleCollectionConfig, icon: "grouped" };
      }
      return sampleCollectionConfig;
    },
    userAddresses() {
      // We can only select from the userAddresses which have latitude longitude information
      return (
        (this.bookingUserData?.userAddresses || [])
          .filter((userAddress) => userAddress.meta?.latitude && userAddress.meta?.longitude)
          .map((userAddress) => {
            return { ...userAddress, isSelected: userAddress.id === this.prospectStateSelectedAddress?.id };
          }) || []
      );
    },
    shortenedAddress() {
      let shortenedAddress = this.selectedAddress?.address_line_two?.substring(0, 48);
      if (this.selectedAddress?.address_line_two?.length > 48) {
        shortenedAddress += "...";
      }
      return shortenedAddress;
    },
    isMobileView() {
      return window.innerWidth < 992;
    },
    resetAddressButtonText() {
      if (!this.isMobileView) return "Change Location";
      return "Change";
    },
    confirmButtonText() {
      return `Confirm${!this.isMobileView ? " Address" : ""}`;
    },
    isHomeCollectionCheckupPackage() {
      return this.checkupPackages[0]?.sampleCollectionType === CheckupPackageSampleCollectionType.HOME_COLLECTION;
    },
  },
  apollo: {
    userQuery: {
      query: MY_INFO,
      update(data) {
        data.me && this.$store.commit("updateUser", data.me);
        return null;
      },
      fetchPolicy: "no-cache",
    },
  },
  mounted() {
    if (!this.userAddresses?.length) {
      this.isSelectingAddress = true;
    }
    if (Object.keys(this.prospectStateSelectedAddress).length) {
      this.selectedAddress = this.prospectStateSelectedAddress;
    }
  },
  methods: {
    isApp() {
      return mobileApp.isApp;
    },
    addNewAddressHandler() {
      this.isSelectingAddress = true;
      this.selectedAddress = {};
    },
    selectUserAddress(userAddress) {
      const { id, city, state, country, pincode } = userAddress;
      this.userAddress = this.userAddresses.map((address) => {
        if (address?.id !== userAddress?.id) {
          address.isSelected = false;
        } else {
          address.isSelected = true;
        }
      });
      this.selectedAddress = {
        id,
        address: this.formattedAddressLine(userAddress),
        address_line_one: userAddress?.addressLineOne || "",
        address_line_two: userAddress?.addressLineTwo || "",
        city,
        state,
        country,
        pincode,
        ...userAddress.meta,
      };
    },
    async updateSelectedAddress(index) {
      const { id, name } = this.addressSuggestions[index];
      this.selectedAddress = { ...this.selectedAddress, address: name, placeId: id };
      await this.handleSelectAddress(this.selectedAddress);
    },
    formattedAddressLine(address) {
      const { addressLineOne, addressLineTwo, city, country, pincode, state } = address;
      let addressLine = "";
      if (addressLineOne) addressLine += `${addressLineOne}, `;
      if (addressLineTwo) {
        addressLine += `${addressLineTwo}`;
      } else {
        if (city) addressLine += `${city}, `;
        if (state) addressLine += `${state}, `;
        if (pincode) addressLine += `${pincode}`;
        if (country) addressLine += `, ${country}`;
      }
      return addressLine.trim().replace(/,$/, "");
    },
    simplifiedAddress(address) {
      const { addressLineTwo, city, country, pincode, state } = address;
      let simplifiedAddress = "";
      if (addressLineTwo) {
        simplifiedAddress += `${addressLineTwo}`;
      } else {
        if (city) simplifiedAddress += `${city}, `;
        if (state) simplifiedAddress += `${state}, `;
        if (pincode) simplifiedAddress += `${pincode}`;
        if (country) simplifiedAddress += `, ${country}`;
      }
      return simplifiedAddress.trim().replace(/,$/, "");
    },
    debouncedSearch: debounce(function () {
      if (!this.searchResults?.find((e) => this.searchText === e)) {
        this.getAddressSuggestions();
      } else {
        this.searchResults = [];
      }
    }, 500),
    async getAddressSuggestions() {
      const { data } = await this.$apollo.query({
        query: resDefs.locations.addressSuggestions,
        variables: {
          query: this.searchText,
        },
      });
      this.addressSuggestions = data.addressSuggestions;
      this.searchResults = data.addressSuggestions.map((addressSuggestion) => addressSuggestion.name);
    },
    async upsertUserAddress() {
      const { id, city, state, country } = this.selectedAddress;
      const variables = {
        id,
        addressLineOne: this.selectedAddress?.address_line_one,
        addressLineTwo: this.selectedAddress?.address_line_two,
        userId: this.bookingUserData?.id,
        meta: {
          latitude: this.selectedAddress?.latitude,
          longitude: this.selectedAddress?.longitude,
          placeId: this.selectedAddress?.placeId,
        },
        city,
        state,
        country,
        pincode: this.selectedAddress?.pincode?.toString(),
        isPrimary: this.selectedAddress?.isPrimary || false,
      };
      try {
        const result = await this.$apollo.mutate({
          mutation: userAddressesDefs.upsertMutation,
          variables,
        });
        if (!this.checkupAdmin) {
          await this.$apollo.queries.userQuery.refetch();
        }
        this.$store.commit("updateAhcProspectStateMeta", {
          selectedAddress: { ...this.selectedAddress, id: result?.data?.upsertUserAddress?.id || "" },
        });
      } catch (error) {
        this.$store.commit("addToast", {
          variant: "danger",
          message: "Something went wrong while adding user address.",
        });
        console.log(error);
      }
    },
    async handleSelectAddress(selectedAddress) {
      window.posthog.capture("checkup_user_clicked_find_providers_button");
      if (selectedAddress && selectedAddress?.placeId) {
        window.posthog.capture("checkup_user_entered_location_input_text");
        this.selectedAddress = await this.getCoordinates(selectedAddress.placeId);
      }
    },
    handleResetAddress() {
      window.posthog.capture("checkup_user_clicked_change_location");
      this.selectedAddress = {};
      this.searchText = "";
      this.$store.commit("updateAhcProspectStateMeta", { selectedAddress: {} });
    },
    async getCoordinates(placeId) {
      const { data } = await this.$apollo.query({
        query: resDefs.locations.getCoordinates,
        variables: {
          placeId,
        },
      });
      return data?.getCoordinates;
    },
    async getAddressFromCoordinates(longitude, latitude) {
      const { data } = await this.$apollo.query({
        query: resDefs.locations.getAddressFromCoordinates,
        variables: {
          longitude,
          latitude,
        },
      });
      return data?.getAddressFromCoordinates;
    },
    async handleFetchLocation() {
      this.loading = true;
      try {
        if (this.isApp()) {
          const locationServiceCheckResponse = await mobileApp.request("CHECK_LOCATION_SERVICES");

          if (!locationServiceCheckResponse?.success) {
            this.loading = false;
            this.handleLocationError({ code: "LOCATION_SERVICES_DISABLED" });
            return;
          }
        }
        const coordinates = await this.fetchCoordinatesFromUserLocation();

        if (!coordinates.latitude || !coordinates.longitude) {
          this.handleLocationError();
          this.loading = false;
          return;
        }
        this.loading = false;
        const response = await this.getAddressFromCoordinates(coordinates.longitude, coordinates.latitude);
        this.selectedAddress = { address: response.address, placeId: response.placeId, ...this.selectedAddress };
        await this.handleSelectAddress(this.selectedAddress);
      } catch (error) {
        this.handleLocationError(error);
        this.loading = false;
      } finally {
        this.loading = false;
      }
    },

    handleLocationError(error) {
      if (error && error.code === error.PERMISSION_DENIED) {
        this.locationErrorMessage =
          "To detect your location, please enable location access for NovaBenefits in your device settings.";
        this.openLocationAccessRequiredModal();
      } else if (error && error.code === "LOCATION_SERVICES_DISABLED") {
        this.locationErrorMessage =
          "Device location services are disabled. Please enable them from the settings and try again.";
        this.openLocationAccessRequiredModal();
      } else {
        this.$store.commit("addToast", {
          variant: "danger",
          message: "Something went wrong while fetching user location.",
        });
      }
    },

    async fetchCoordinatesFromUserLocation() {
      if ("geolocation" in navigator) {
        return new Promise((resolve, reject) => {
          navigator.geolocation.getCurrentPosition(
            (position) => {
              const { latitude, longitude } = position.coords;
              resolve({
                latitude: latitude,
                longitude: longitude,
              });
            },
            (error) => {
              reject(error);
            }
          );
        });
      } else {
        return { latitude: undefined, longitude: undefined };
      }
    },
    openLocationAccessRequiredModal() {
      this.$bvModal.show(`location-access-denied-modal`);
    },
    async validateForm() {
      let isValid = true;
      if (this.$refs.formData) {
        await this.$refs.formData.validate();
        isValid = this.$refs.formData.errors.length < 1;
      }
      return { isValid, errors: this.$refs.formData.errors };
    },
    async goToNextStep() {
      if (!this.isSelectingAddress && Object.keys(this.selectedAddress || {}).length) {
        this.$store.commit("updateAhcProspectStateMeta", { selectedAddress: { ...this.selectedAddress } });
        this.$emit("goToNextStep");
        return;
      }

      const { isValid } = await this.validateForm();
      if (isValid && this.bookingUserData && this.selectedAddress?.pincode) {
        window.posthog.capture("checkup_confirmed_selected_address");
        this.loading = true;
        await this.upsertUserAddress();
        this.loading = false;
        this.$emit("goToNextStep");
      } else {
        this.$store.commit("addToast", {
          variant: "danger",
          message: "Please enter a valid address to proceed.",
        });
      }
    },
    goBack() {
      if (this.isSelectingAddress && this.userAddresses?.length) {
        this.isSelectingAddress = false;
        this.handleResetAddress();
      } else {
        this.$emit("backHandler");
      }
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/_variables.scss";
@import "@/assets/styles/mixins/_breakpoints.scss";

.checkup-address-selection {
  .custom-radio .custom-control-label::before {
    border-width: 1px !important;
    border-radius: 1rem !important;
    width: 1rem !important;
    height: 1rem !important;
    cursor: pointer;
  }
  .custom-radio .custom-control-label {
    cursor: pointer;
  }
  .checkup-address-selection-wrapper {
    box-shadow: $box-shadow-sm;
    border-radius: 0.5rem !important;
    border: 1px solid $gray-300;
    padding: 0.5rem !important;
  }
  .checkup-address-wrapper {
    padding: 0rem;
    @include media-breakpoint-up(md) {
      box-shadow: $box-shadow-sm;
      border-radius: 0.5rem !important;
      border: 1px solid $gray-300;
      padding: 1rem !important;
    }
  }
  @include media-breakpoint-down(sm) {
    .address-selection,
    .add-address-wrapper {
      box-shadow: $box-shadow-sm;
      padding: 1rem;
      border: 1px solid $gray-300;
      border-radius: 0.5rem !important;
    }
  }
  .checkup-address-header {
    margin-bottom: 1.25rem !important;
  }
  .info-banner {
    padding: 0.75rem !important;
  }
}

.location-link {
  &:hover {
    color: $blue-700;
  }
}
</style>
