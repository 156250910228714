<template lang="pug">
.info-container.px-4.d-flex.align-items-center.justify-content-start.position-relative(:class="[`bg-${bannerBackground}`]")
  .icon-container.p-3.d-flex.align-items-center.justify-content-center
    i(:class="[`icon-${icon}`, `text-${iconColor}`]")
  .info-details.d-flex.align-items-start.justify-content-start.flex-column.w-75
    .info-title.font-md.font-inter.font-weight-semibold.text-gray-800.px-3.pb-1 {{title || ""}}
    .info-text.font-sm.font-inter.font-weight-medium.text-gray-700.px-3 {{subtext || ""}}
  .btn-group.ml-auto.mr-1.my-3
    n-button.text-nowrap.rounded-8.px-2.mr-2.h-full(
      v-if="currentTab === 'unapproved'",
      @click="openModal('reminder')"
      :buttonText="'Send Reminders'",
      :imageIcon="'calendar'",
      variant="outline-secondary",
      size="sm",
      data-cy="endorsements-send-reminder-button"
    )
    n-button.text-nowrap.rounded-8.px-2.mr-2(
      v-if="currentTab === 'approved' && this.$route.path.includes('org-admin/')",
      @click="toggleContact"
      :buttonText="'Contact Nova'",
      variant="dark",
      size="sm",
    ) 
    n-button.text-nowrap.rounded-8.px-2(
      v-if="buttonText",
      @click="currentTab  == 'org-ok' && ['current', 'overdue'].includes(selectedBatchStatus) && draftChangesCount > 0 ? $emit('open-confirmation-modal', 'banner') : openModal()",
      :buttonText="buttonText",
      :disabled="buttonDisabled",
      :rightImageIcon="rightImageIcon",
      variant="dark",
      size="sm",
      data-cy="endorsement-banner-modal-primary-button"
    )
    n-button.text-nowrap.rounded-8.ml-2(
      v-if="buttonTextSecondary && showSecondaryButton",
      @click="handleSecondaryModal('show')",
      :buttonText="buttonTextSecondary",
      :disabled="buttonDisabledSecondary",
      :rightImageIcon="rightImageIconSecondary",
      variant="outline-secondary",
      size="sm",
      data-cy="endorsements-approveAll"
      )
    b-dropdown(
      v-if="insurerSpecific && exportFlag && !this.$route.path.includes(`org-admin/`)",
      no-caret,
      right,
      :variant="exportButtonVariant",
    )
      template(v-slot:button-content)
        span.font-weight-medium Export changes  >
      b-dropdown-item-button(@click="openECModal('export-changes-modal', false)") Export changes
      b-dropdown-item-button(@click="openECModal('export-changes-modal', true)") Export changes in insurer format
    div(v-else)
      n-button.text-nowrap.rounded-8.ml-2(
        v-if="exportFlag && !this.$route.path.includes(`org-admin/`)",
        buttonText="Export changes",
        @click="openECModal('export-changes-modal', false)",
        :variant="exportButtonVariant",
        size="sm",
      )
  reminder-modal(
    :employeeCount="employeeCount",
    :is-selected-batch-overdue="isSelectedBatchOverdue",
    :batchId="selectedBatchId",
    :orgId="orgId",
    @resetFloatingBar="$emit('resetFloatingBar')",
    @refreshTable="$emit('refreshTable')",
  )
  export-changes-modal(:orgId="orgId", :currentTab="currentTab", :selectedBatchId="selectedBatchId", :isInsurerSpecificFormat="this.isInInsurerSpecificFormat")
  cash-deposit-modal(id="cash-deposit-modal", :minCdBalanceAmount="bufferAmount", :shortFallAmount="shortFallAmount", :batch="batch")
  endorsement-step-modal-wrapper(
    id="banner-step-modal"
    :changes-data="items",
    :total-changes="totalChanges",
    :unapproved-data="unapprovedData",
    :ob-pending-count="obPendingCount",
    :current-batch-id="selectedBatchId",
    :dateOfInterest="dateOfInterest",
    :showDepositSteps="showDepositSteps",
    :showReviewModal="true",
    :is-selected-batch-overdue="isSelectedBatchOverdue",
    :insurers="insurers",
    :policies="policies",
    :insurerPolicyMap="insurerPolicyMap",
    :modalVariant="modalVariant",
    :items="items",
    :policiesWithPremium="policiesWithPremium",
    @refreshTable="$emit('refreshTable')",
    @resetFloatingBar="$emit('resetFloatingBar')",
    @checkCDBalance="checkAndShowCDModal()",
    :dataCy="`endorsements-step-modal-${modalVariant}`",
    @submitted-to-org-ok="$emit('submitted-to-org-ok')"
  )
</template>

<script>
import ReminderModal from "./ReminderModal.vue";
import ExportChangesModal from "./ExportChangesModal.vue";
import TransferBatchModal from "./TransferBatchModal.vue";
import EndorsementStepModalWrapper from "./EndorsementStepModalWrapper.vue";
import ChangeConfirmationModal from "./ChangeConfirmationModal.vue";
import CashDepositModal from "./CashDepositModal.vue";
import { fromGlobalId } from "@/utils/misc";
import NButton from "@/components/NovaButton.vue";

export default {
  name: "Banner",
  components: {
    NButton,
    ReminderModal,
    ExportChangesModal,
    TransferBatchModal,
    EndorsementStepModalWrapper,
    ChangeConfirmationModal,
    CashDepositModal,
  },
  props: {
    policiesWithPremium: {
      type: Array,
      default: () => [],
    },
    exportFlag: {
      type: Boolean,
      default: false,
    },
    insurerSpecific: {
      type: Boolean,
      default: false,
    },
    insurers: {
      type: Array,
      default: () => [],
    },
    draftChangesCount: {
      type: Number,
      default: 0,
    },
    policies: {
      type: Array,
      default: () => [],
    },
    insurerPolicyMap: {
      type: Object,
      default: () => {},
    },
    variant: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
    subtext: {
      type: String,
      default: null,
    },
    icon: {
      type: String,
      default: "question",
    },
    buttonText: {
      type: String,
      default: null,
    },
    buttonDisabled: {
      type: Boolean,
      default: false,
    },
    iconColor: {
      type: String,
      default: "red-600",
    },
    bannerBackground: {
      type: String,
      default: "red-100",
    },
    employeeCount: {
      type: Number,
      default: 0,
    },
    orgId: {
      type: String,
      default: "",
    },
    currentTab: {
      type: String,
      default: "",
    },
    selectedBatchId: {
      type: String,
    },
    obPendingCount: {
      type: Number,
      default: 0,
    },
    items: {
      type: Array,
      default: () => [],
      required: true,
    },
    totalChanges: {
      type: Number,
      default: 0,
    },
    unapprovedData: {
      type: Object,
      default: () => {},
    },
    modalName: {
      type: String,
      default: "",
    },
    modelNameSecondary: {
      type: String,
      default: "",
    },
    exportButtonVariant: {
      type: String,
      default: "outline-secondary",
    },
    selectedBatchStatus: {
      type: String,
    },
    isSelectedBatchOverdue: {
      type: Boolean,
      default: false,
    },
    rightImageIcon: {
      type: String,
      default: null,
    },
    batches: {
      type: Array,
      default: () => [],
    },
    buttonTextSecondary: {
      type: String,
      default: null,
    },
    buttonDisabledSecondary: {
      type: Boolean,
      default: false,
    },
    rightImageIconSecondary: {
      type: String,
      default: null,
    },
    showSecondaryButton: {
      type: Boolean,
    },
    dateOfInterest: {
      type: String,
      default: "",
    },
    showDepositSteps: {
      type: Boolean,
    },
    currentOrg: {
      type: Object,
      default: () => {},
    },
    shortFallAmount: {
      type: Number,
      default: 0,
    },
    bufferAmount: {
      type: Number,
      default: 0,
    },
    batch: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      isInInsurerSpecificFormat: false,
      modalVariant: null,
    };
  },
  methods: {
    openModal(variant = "approval") {
      this.modalVariant = variant;
      this.$bvModal.show(this.modalName);
    },
    checkAndShowCDModal() {
      if (
        (this.shortFallAmount > 0 || this.bufferAmount > 0) &&
        this.currentTab === "unapproved" &&
        !this.currentOrg?.preferences?.ignoreCDCheck &&
        this.currentOrg?.featureFlags?.PREMIUM_BREAKUP &&
        this.currentOrg?.featureFlags?.RATER_BASED_PREMIUM_ESTIMATION
      ) {
        this.$bvModal.hide(this.modalName);
        this.$bvModal.show("cash-deposit-modal");
        window.posthog.capture("cash_deposit_alert_modal_shown", {
          org_name: this.currentOrg?.name,
        });
      }
    },
    openECModal(param, inInsurerSpecificFormat) {
      if (inInsurerSpecificFormat) {
        window.posthog.capture("export_changes_insurer_format", {
          org_id: fromGlobalId(this.orgId).id,
          batch_id: fromGlobalId(this.selectedBatchId).id,
        });
      }
      this.isInInsurerSpecificFormat = inInsurerSpecificFormat;
      this.$bvModal.show(param);
    },
    handleSecondaryModal(changeType = "hide") {
      if (changeType === "show") {
        this.$bvModal.show(this.modelNameSecondary);
        return;
      }

      this.$bvModal.hide(this.modelNameSecondary);
    },
    async toggleContact() {
      this.$store.commit("toggleSupportContainer");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
.info-container {
  height: 5rem;
  border: $border-width-custom-1 solid $gray-300;
}
.icon-container {
  height: 2rem;
  width: 2rem;
  border-radius: 50%;
  background: $white;
}
</style>
