<template lang="pug">
  .h-100.m-1
    template(v-if="cardData.isPlaceholder")
      .dummy-card-container.rounded.d-flex.justify-content-center.flex-column.cursor-pointer.text-center(@click= "showData")
        n-icon.text-gray-500.ml-1(name="plus-circle")
        .d-flex.justify-content-center.align-items-center
          .font-weight-medium.text-capitalize.text-gray-400 {{cardData.relation}} 
    template(v-else)
      .plan-card-container.cursor-pointer.py-1(@click= "showData")
        .row.card-height
          .col-2.col-md-3.pl-3.pr-0.d-flex.flex-column.justify-content-center.card-height
            n-avatar.img-style(
              v-if="cardData.relation!=='None selected' && cardData.gender === 'NOT ASSIGNED'",
              :size="2.5",
              :name="cardData.name",)
            img.img-style(v-else, :src="imageUrl")
          .col-5.col-md-5.p-0.d-flex.flex-column.justify-content-center.card-height
            .my-1.pl-1.text-gray-700.text-capitalize.font-weight-semibold
              .text-gray-900.text-truncate.dependent-name {{cardData.name}}
              .font-sm.d-md-block.d-inline.dependent-relation {{relation}}
            
              span.font-weight-medium.font-sm.d-none.d-md-inline-flex(v-if="cardData.gender !== 'NOT ASSIGNED'") {{cardData.gender}}
              span.font-weight-medium.font-sm.d-none.d-md-inline-flex(v-if="age")  &nbsp;• {{age}} Years

              span.font-weight-medium.font-xs.d-md-none &nbsp;• {{cardData.gender[0]}}
              span.font-weight-medium.font-xs.d-md-none(v-if="age") /&nbsp;{{age}}   
          .col-5.col-md-4.p-0.font-xs.card-height.d-flex.justify-content-end
            .mr-3.my-auto.font-weight-medium
              .row.mr-1.my-1.justify-content-end
                .span(v-for="(policy,index) in cardData.depPolicies",:key="policy")
                  .border.rounded.tag {{policyMap[policy]}}                   
</template>

<script>
import moment from "moment";
import NAvatar from "@/components/Avatar.vue";

export default {
  name: "DependentDetailsCard",
  components: {
    NAvatar,
  },
  props: {
    cardData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      policyMap: {
        topup: "TopUp",
        gmc: "GMC",
        opd: "OPD",
        gpa: "GPA",
        covid: "COV19",
        dcl: "DCL",
        ppc: "PPC",
        gtl: "GTL",
        workmen: "WM",
        "super-topup": "ST",
      },
    };
  },
  computed: {
    imageUrl() {
      return require(`@/assets/images/avatar-${this.cardData.relation}-${this.cardData.gender}.svg`);
    },
    age() {
      if (!this.cardData.dob) return;
      const dob = moment(this.cardData.dob, "YYYY-MM-DD");
      return `${moment().diff(dob, "years", false)}`;
    },
    relation() {
      if (this.cardData?.relation === "parent-in-law") return "in-law";
      return this.cardData?.relation;
    },
  },
  methods: {
    showData() {
      this.$emit("clicked", this.cardData);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
@import "@/assets/styles/mixins/_breakpoints.scss";

.plan-card-container {
  box-shadow: $box-shadow-xs;
  transition: all 0.3s ease;
  will-change: top;
  &:hover {
    box-shadow: $box-shadow;
    top: -0.3rem;
  }
  height: 5.75rem;
  @include media-breakpoint-down(sm) {
    height: auto;
  }
}
.card-height {
  height: 5.75rem;
  @include media-breakpoint-down(sm) {
    height: auto;
  }
}
.img-style {
  height: 2.5rem;
  width: 2.5rem;
  margin: 0 auto;
  @include media-breakpoint-down(sm) {
    height: 1.75rem;
    width: 1.75rem;
  }
}
.tag {
  padding: 0.2rem;
  margin: 0.1rem;
  margin-right: 0.3rem;
}
.dummy-card-container {
  height: 5.75rem;
  border: 2px dashed $gray-400;
  @include media-breakpoint-down(sm) {
    height: 3rem;
  }
}
@include media-breakpoint-down(sm) {
  .dependent-name {
    font-size: 0.75rem;
  }
  .dependent-relation {
    font-size: 0.625rem;
  }
}
</style>
