import gql from "graphql-tag";
import { getListQuery, getSingleQuery } from "../queries";
import { orgEntityFragment } from "../fragments";
import orgEntitiesSchema from "../schemas/orgEntities";
import orgEntitiesMutations from "../mutations/orgEntities";

export default {
  gqlType: "OrgEntity",
  fields: ["name", "parentOrg", "address", "pincode"],
  listQuery: getListQuery("OrgEntity"),
  singleQuery: getSingleQuery("OrgEntity"),
  singleQueryByNameAndOrgId: gql`
    query GetOrgEntityByNameAndOrgId($name: String!, $orgId: ID!) {
      getOrgEntityByNameAndOrgId(name: $name, orgId: $orgId) {
        ...OrgEntity
      }
    }
    ${orgEntityFragment}
  `,
  ...orgEntitiesSchema,
  ...orgEntitiesMutations,
  validate: (n) => {
    return false;
  },
  transform: (n, write = false) => {
    let property = "Check Metadata ->";
    const payload = {
      ...n,
      property,
      orgId: n.org?.id,
      parentOrg: n.org?.name,
    };
    if (write) {
      delete payload.org;
    }
    return payload;
  },
};
