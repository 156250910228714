<template lang="pug">
.packages-list-container(v-if="benefitId")
  .self-packages
    n-spinner(:active="isLoading",
      spinner="ring",
      size="50",
      duration="1")
    template(v-if="groupedCheckupPackages?.length")
      .font-lg.text-gray-800.font-weight-semibold.mt-4 Packages for you
      package-card.package-card.border.border-gray-300.rounded-md.my-3(v-for="groupedCheckupPackage in groupedCheckupPackages",
        :groupedCheckupPackage="groupedCheckupPackage")
      .d-flex.align-items-center.mt-3.border.border-mustard-200.bg-mustard-100.rounded-8.p-2(v-if="shouldEnableViewMoreBenefits")
        n-icon.mr-2(name="info", :size="0.8", :pathCount="2", variant="mustard-700")
        .text-gray-900.font-sm.font-weight-medium
          | To book other lab tests, please go to&nbsp;
          span.text-blue-700.font-weight-semibold.text-decoration-underline.cursor-pointer(@click="viewMoreBenefitsHandler")
            | View more benefits
          | &nbsp;on the home page.
    empty-state.border-1-dashed.rounded-lg.mt-3(v-else,
      :imagePath="require(`@/assets/images/magnifying-glass.svg`)",
      message1="Looks like we couldn't find any check-up packages linked to your organisation.",
      message2="Please reach out to Nova's customer support.")

</template>

<script>
import { mapState } from "vuex";
import resDefs from "../definitions";
import { groupCheckupPackages } from "../checkupUtils";
import PackageCard from "./PackageCard.vue";
import EmptyState from "@/components/Cards/EmptyStates/EmptyState.vue";
import NButton from "@/components/NovaButton";
export default {
  name: "PackagesList",
  components: {
    NButton,
    PackageCard,
    EmptyState,
  },
  props: {
    benefitId: {
      type: String,
      default: null,
    },
  },
  data() {
    const resName = "checkupPackages";
    return {
      groupedCheckupPackages: [],
      currentPackage: null,
      pageSize: 20,
      currentPage: 1,
      isLoading: true,
      resDef: resDefs[resName],
    };
  },
  computed: {
    ...mapState(["user"]),
    isCheckupAdmin() {
      if (!this.user?.roles) return false;
      return this.user.roles.includes("checkup_admin");
    },
    bookingOrgId() {
      if (this.isCheckupAdmin) {
        return this.$route.params?.bookingUser?.org_id;
      }
      return this.user?.org?.id;
    },
    userId() {
      if (this.isCheckupAdmin) {
        return this.$route.params?.bookingUser?.id;
      }
      return this.user?.id;
    },
    shouldEnableViewMoreBenefits() {
      return this.user?.org?.id === process.env.VUE_APP_CITIUS_TECH_ORG_ID;
    },
  },
  mounted() {
    this.$apollo.queries.groupedCheckupPackages.refetch();
  },
  async created() {
    this.$apollo.addSmartQuery("groupedCheckupPackages", {
      query: this.resDef.listQuery,
      variables: () => ({
        limit: this.pageSize,
        offset: (this.currentPage - 1) * this.pageSize,
        filter: {
          benefitId: this.benefitId,
          userId: this.userId,
        },
      }),
      update: (data) => {
        this.isLoading = false;
        return data?.checkupPackages?.edges?.length ? groupCheckupPackages(data.checkupPackages.edges) : [];
      },
    });
  },
  methods: {
    viewMoreBenefitsHandler() {
      const redirectBenefitId = process.env.VUE_APP_CITIUS_TECH_REDIRECT_BENEFIT_ID || "";
      if (this.shouldEnableViewMoreBenefits && redirectBenefitId) {
        this.$router.push({
          name: "dashboard_benefit",
          params: { benefitId: redirectBenefitId },
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.package-card {
  padding: 1.25rem;
}
</style>
