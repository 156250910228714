<template lang="pug">
  .overlay-content
    transition(name="fade", mode="out-in")
      slot
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
.overlay-content {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100vw;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background: #fff;
  overflow: auto;
  z-index: 10000;
}
</style>
