import gql from "graphql-tag";
import { dependentBenefitFragmentWithoutContent } from "./benefit";

export const dependentFragment = gql`
  fragment Dependent on Dependent {
    id
    name
    dob
    relation
    gender
    status
    meta
    benefits {
      ...DependentBenefitEdgeWithoutContent
    }
  }
  ${dependentBenefitFragmentWithoutContent}
`;

export const dependentsFragment = gql`
  fragment UserDependents on User {
    id
    dependents {
      ...Dependent
    }
  }
  ${dependentFragment}
`;
