<template lang="pug">
.nova-pagination
  b-pagination.align-items-center.mb-0(
    v-bind="{...$props, ...$attrs}",
    v-on="$listeners",
    first-text,
    last-text,
    first-number,
    last-number
    )
    template(#prev-text)
      .d-flex.align-items-center
        i.icon-chevron-left
        | Prev
    template(#next-text)
      .d-flex.align-items-center
        | Next
        i.icon-chevron-right
</template>
<script>
export default {
  name: "NPagination",
};
</script>
<style lang="scss">
@import "@/assets/styles/_variables.scss";
.nova-pagination {
  .page-link {
    border: 0px;
    border-radius: 0.25rem !important;
    color: $gray-900;
    background-color: transparent;
    margin: 0.125rem;
  }
  .page-item:hover .page-link {
    color: $gray-900;
  }
  .page-item.active .page-link {
    background-color: $gray-900;
    color: $white;
    box-shadow: none;
  }
  .page-item.disabled .page-link {
    background-color: transparent;
    color: $gray-600;
  }
}
</style>
