<template lang="pug">
.employee-missing-data-modal
  b-modal(:id="id", size="md", cancel-variant="light-secondary", :scrollable="true", data-cy="employee-missing-data-modal")
    template(v-slot:modal-title)
      .d-flex.align-items-center
        i.icon-add-user.pr-1.font-md
        .text-gray-900.font-weight-semibold.font-md Editing employee details
    ul.border-bottom.p-0.m-0.employee-missing-data-modal__nav
      li.pb-2.px-2.pt-0.d-flex.flex-column.align-items-center(v-if="missingFields.user.length > 0" :class="activeTab === 'user' ? 'active' : ''", @click="switchTabs('user')")
        n-avatar(:image="require('@/assets/images/user.svg')", :bgColor="'gray-500'", :variant="'secondary'", button)
        span.member-name Employee
      li.pb-2.px-2.pt-0.d-flex.flex-column.align-items-center(v-for="dependent, index of employeeModel.dependents", v-if="missingFields.dependents[index].length > 0", :class="activeTab === `dependent${index + 1}` ? 'active' : ''", v-bind:key="index")
        n-avatar(:image="require('@/assets/images/user.svg')", :bgColor="'gray-500'", :variant="'secondary'", button, @click="switchTabs(`dependent${index + 1}`)")
        span.member-name {{`Dependent ${index + 1}`}}
    .p-3.missing-data-vfg
      vue-form-generator(
        :schema="getActiveSchema",
        :model="getActiveModelData",
        :options="formOptions",
        ref="formData")
    template(v-slot:modal-footer)
      .d-flex.justify-content-end
        n-button.font-sm(
          variant="light-secondary",
          buttonText="Cancel",
          @click="handleClose")
        n-button.font-sm.ml-2(
          variant="dark",
          buttonText="Save Details",
          type="submit",
          @click="saveDetails",
          data-cy="employee-missing-data-modal-save-button"
          )
</template>

<script>
import moment from "moment";
import { AcceptedRelations } from "../../../../common/enums";
import adminResDefs from "../../admin/definitions";
import NButton from "@/components/NovaButton.vue";
import NAvatar from "@/components/Avatar.vue";
import resDefs from "@/portal/pages/orgAdmin/definitions.js";
import utils, {
  isVfgErrorPresent,
  validateDate,
  validateDependentAge,
  validateEmployeeAge,
  validateEmployeeId,
  validateGender,
  validateName,
} from "@/utils";

export default {
  name: "EmployeeMissingDataModal",
  components: {
    NButton,
    NAvatar,
  },
  props: {
    employeeData: {
      type: Object,
      default: () => ({}),
    },
    missingFields: {
      type: Object,
      default: () => ({}),
    },
    initialActiveTab: {
      type: String,
      default: "user",
    },
    orgId: {
      type: String,
    },
  },
  data() {
    return {
      id: "employee-missing-data-modal",
      activeTab: "user",
      formOptions: {
        validateAfterLoad: false,
        validateAfterChanged: true,
        validateAsync: true,
      },
      employeeModel: {},
      checkIfHasNoCoverageStart: utils.checkIfHasNoCoverageStart,
      customDependentValidations: {},
    };
  },
  computed: {
    isDOJRequired() {
      if (this.activeTab === "user") {
        return this.checkIfHasNoCoverageStart(this.employeeModel.user?.meta);
      }
      return false;
    },
    totalDependents: function () {
      return this.employeeModel.length;
    },
    getActiveModelData() {
      if (this.activeTab === "user") {
        return this.employeeModel.user;
      }
      // FIXME: Move out of computed
      this.setUserDataToModel();
      return this.employeeModel.dependents[this.activeTab.charAt(this.activeTab.length - 1) - 1];
    },
    getDependentSchema() {
      const depModel = this.getActiveModelData;
      // Adding dom field to dependent schema for midterm spouse additions
      if (depModel?.depMeta?.isMidTermAddition && depModel.relation === AcceptedRelations.SPOUSE) {
        return {
          fields: [
            ...this.getVfgFields.dependent.fields,
            {
              model: "depMeta.dom",
              type: "datepicker",
              fieldLabel: "Date of Marriage (as per Govt ID)",
              placeholder: "Select Date of Marriage (Spouse only)",
              min: "1900-01-01",
              max: moment().format("YYYY-MM-DD"),
              required: true,
              chip: this.getMissingChip("dom"),
              validator: "required",
            },
          ],
        };
      }
      return this.getVfgFields.dependent;
    },
    getActiveSchema() {
      if (this.activeTab === "user") {
        return this.getUserSchema;
      }
      return this.getDependentSchema;
    },
    getUserSchema() {
      if (this.isDOJRequired) {
        return {
          fields: [
            ...this.getVfgFields.user.fields,
            {
              model: "userMeta.dateOfJoining",
              type: "datepicker",
              fieldLabel: "Joining Date",
              placeholder: "Select Date of Joining",
              min: "1900-01-01",
              max: "2100-01-01",
              required: this.isDOJRequired,
              chip: this.getMissingChip("dateOfJoining"),
              validator: "required",
            },
          ],
        };
      }
      return this.getVfgFields.user;
    },
    getVfgFields: function () {
      return {
        user: {
          fields: [
            {
              model: "name",
              type: "inline-input",
              inputType: "text",
              fieldLabel: "Full Name",
              placeholder: "Enter your full name",
              validator: ["required", validateName],
              chip: this.getMissingChip("name"),
              required: true,
            },
            {
              model: "dob",
              type: "datepicker",
              fieldLabel: "Date of Birth",
              placeholder: "Select Date of Birth",
              min: "1900-01-01",
              max: moment().format("YYYY-MM-DD"),
              required: true,
              chip: this.getMissingChip("dob"),
              validator: ["required", validateEmployeeAge],
            },
            {
              model: "userMeta.employeeId",
              type: "inline-input",
              inputType: "text",
              fieldLabel: "Employee ID",
              placeholder: "Enter your Employee ID",
              validator: ["required", validateEmployeeId],
              chip: this.getMissingChip("employeeId"),
              required: true,
              subText: "Without employee ID, your insurer may not be able to add the employee to your coverage plans",
              imageIcon: "user-id",
            },
            {
              model: "gender",
              type: "select-cards",
              fieldLabel: "Gender",
              required: true,
              readOnly: this.disabled,
              validator: ["required", validateGender],
              chip: this.getMissingChip("gender"),
              subText:
                "We understand there are more gender identities. At present however, we are still learning and trying to understand the impact of other gender identities on health and come up with more friendly policies.",
              cardsData: [
                {
                  name: "gender",
                  icon: "female",
                  label: "Female",
                  card_value: "female",
                },
                {
                  name: "gender",
                  icon: "male",
                  label: "Male",
                  card_value: "male",
                },
              ],
            },
          ],
        },
        dependent: {
          fields: [
            {
              model: "dep_name",
              type: "inline-input",
              inputType: "text",
              fieldLabel: "Dependent Name",
              placeholder: "Enter dependent's full name",
              validator: "required",
              chip: this.getMissingChip("dep_name"),
              required: true,
            },
            {
              model: "dep_dob",
              type: "datepicker",
              fieldLabel: "Date of Birth",
              placeholder: "Select Date of Birth",
              min: "1900-01-01",
              max: moment().format("YYYY-MM-DD"),
              required: true,
              chip: this.getMissingChip("dob"),
              validator: ["required", this.validateDependentAge],
            },
            {
              model: "relation",
              type: "inline-select",
              fieldLabel: "Relationship",
              placeholder: "Select Relationship with dependent",
              required: true,
              validator: "required",
              transform: (v) => v.value,
              chip: this.getMissingChip("relation"),
              options: [
                { label: "Spouse", value: "spouse" },
                { label: "Child", value: "child" },
                { label: "Parent", value: "parent" },
                { label: "Parent in Law", value: "parent-in-law" },
              ],
            },
            {
              model: "dep_gender",
              type: "select-cards",
              fieldLabel: "Gender",
              required: true,
              readOnly: this.disabled,
              validator: ["required", validateGender],
              chip: this.getMissingChip("gender"),
              subText:
                "We understand there are more gender identities. At present however, we are still learning and trying to understand the impact of other gender identities on health and come up with more friendly policies.",
              cardsData: [
                {
                  name: "gender",
                  icon: "female",
                  label: "Female",
                  card_value: "female",
                },
                {
                  name: "gender",
                  icon: "male",
                  label: "Male",
                  card_value: "male",
                },
              ],
            },
          ],
        },
      };
    },
  },
  watch: {
    employeeData: {
      handler: function (newVal) {
        this.employeeModel = newVal;
      },
      deep: true,
    },
    initialActiveTab: {
      handler: function (newVal) {
        this.activeTab = newVal;
      },
    },
  },
  created() {
    this.$apollo.addSmartQuery("customDependentValidations", {
      query: adminResDefs?.orgProperties?.singleQueryByNameAndOrgId,
      variables() {
        return { name: "CUSTOM_DEPENDENT_VALIDATION_PARAMS", orgId: this.orgId };
      },
      update(data) {
        return data?.getOrgPropertyByNameAndOrgId?.meta;
      },
    });
  },
  mounted() {
    this.employeeModel = this.employeeData;

    this.$options.staticEmoployeeObject = utils.deepClone(this.employeeData);
  },
  methods: {
    switchTabs(tab) {
      this.activeTab = tab;
    },
    setUserDataToModel() {
      this.employeeModel.dependents[this.activeTab.charAt(this.activeTab.length - 1) - 1] = {
        ...this.employeeModel.dependents[this.activeTab.charAt(this.activeTab.length - 1) - 1],
        userDob: this.employeeData.user?.dob,
      };
    },
    saveDetails: async function () {
      await this.$refs.formData.validate();
      if (this.employeeModel?.dependent?.dep_dob && !validateDate(this.employeeModel.dependent.dep_dob)) {
        this.$store.commit("addToast", {
          variant: "danger",
          title: "Future Date selected",
          message: "Dependent Date of birth cannot be a future date",
        });
        return;
      }
      if (this.employeeModel?.user?.dob && !validateDate(this.employeeModel.user.dob)) {
        this.$store.commit("addToast", {
          variant: "danger",
          title: "Future Date selected",
          message: "Date of birth cannot be a future date",
        });
        return;
      }
      if (isVfgErrorPresent(this.$refs.formData.errors)) {
        this.$store.commit("addToast", {
          variant: "danger",
          message: "Please enter valid details before adding any new dependent.",
        });
      } else {
        this.$store.commit("clearToasts");

        const changedUserInfo = {};
        const changedBenefitInfo = { overrides: {} };
        for (const key in this.employeeModel.user) {
          if (this.employeeModel.user[key] !== this.$options.staticEmoployeeObject.user[key]) {
            changedUserInfo[key] = this.employeeModel.user[key];
            changedBenefitInfo.overrides[key] = this.$options.staticEmoployeeObject.user[key];
          }
        }

        const sendingVariables = {
          orgId: this.orgId,
          userId: this.employeeData.user.user_id,
          changedUserInfo: {
            ...(changedUserInfo.name ? { name: changedUserInfo.name } : {}),
            ...(changedUserInfo.userMeta ? { meta: changedUserInfo.userMeta } : {}),
            ...(changedUserInfo.dob ? { dob: changedUserInfo.dob } : {}),
            ...(changedUserInfo.gender ? { gender: changedUserInfo.gender } : {}),
          },
          changedBenefitInfo,
          type: "update",
          status: "draft",
        };

        if (this.employeeModel?.dependents?.length) {
          this.employeeModel.dependents.forEach((member, index) => {
            this.employeeModel.dependents[index] = this.filterUserDependent(member);
          });

          sendingVariables.changedUserInfo.updatedDependents = this.employeeModel.dependents.filter(
            this.isDependentsDataMissing
          );
        }

        try {
          await this.$apollo.mutate({
            mutation: resDefs.userChanges.updateUserOrDependentInfo,
            variables: sendingVariables,
          });
          this.$emit("employee-updated");
          this.$store.commit("addToast", {
            variant: "success",
            message: "Successfully updated the details",
          });
        } catch (err) {
          console.log(err);
        }
        this.handleClose();
      }
    },
    isDependentsDataMissing(dependent) {
      for (var dependentData in dependent) {
        if (!dependent[dependentData]) return false;
      }
      return true;
    },
    filterUserDependent(member) {
      return {
        ...(["self", "placeholder"].includes(member.user_type)
          ? { id: member.user_id }
          : { id: member.user_changes[0].dependentId }),
        ...(["self", "placeholder"].includes(member.user_type) ? { name: member.name } : { name: member.dep_name }),
        ...(["self", "placeholder"].includes(member.user_type) ? { dob: member.dob } : { dob: member.dep_dob }),
        ...(["self", "placeholder"].includes(member.user_type)
          ? { gender: member.gender }
          : { gender: member.dep_gender }),
        ...(["self", "placeholder"].includes(member.user_type) ? {} : { relation: member.relation }),
        ...(["self", "placeholder"].includes(member.user_type)
          ? { meta: { ...member.userMeta } }
          : { meta: { ...member?.depMeta } }),
      };
    },
    getMissingChip(field) {
      if (this.activeTab === "user") {
        if (this.missingFields.user.includes(field)) {
          return {
            text: "Missing",
            variant: "light-warning",
          };
        }
        return {};
      } else {
        if (this.missingFields.dependents[this.activeTab.charAt(this.activeTab.length - 1) - 1].includes(field)) {
          return {
            text: "Missing",
            variant: "light-warning",
          };
        }
        return {};
      }
    },
    handleClose() {
      this.employeeModel = {};
      this.$emit("modal-closed");
    },
    validateDependentAge(dep_dob) {
      return validateDependentAge(
        dep_dob,
        this.employeeData?.user,
        this.getActiveModelData,
        this.customDependentValidations
      );
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/_variables.scss";
@import "@/assets/styles/_typography.scss";

ul.employee-missing-data-modal__nav {
  display: flex;
  flex-wrap: nowrap;
  overflow: hidden;
  overflow-x: auto;
  scrollbar-width: 0px;
  list-style: none;
  .active {
    border-bottom: 3px solid $blue-400;
    span {
      color: $blue-400;
    }
  }
  .member-name {
    @extend .font-weight-semibold;
    @extend .font-xs;
    color: $gray-800;
  }
}

.missing-data-vfg {
  .vue-form-generator {
    .form-group {
      label {
        @extend .font-sm;
        @extend .font-weight-medium;
        color: $gray-800;
      }
      .field-label {
        @extend .font-weight-medium;
        @extend .font-sm;
        color: $gray-800;
      }
      .input-sub-text {
        span {
          @extend .font-xs;
          @extend .font-weight-medium;
          color: $gray-700;
        }
      }
      .b-form-datepicker {
        button {
          border-left: 0;
        }
      }
    }
  }
}
</style>
