<template lang="pug">
b-modal(
  :id="id",
  size="lg",
  centered,
  hide-header,
  hide-footer,
  body-class="p-0",
  content-class="rounded-3",
  dialog-class="checkup-dialog-modal",
  modal-class="checkup-reschedule-modal",
  @hidden="closeHandler",
  :showModal="true")
  .d-flex.flex-column.justify-content-end.align-items-center.d-sm-block.checkup-content.text-center
    img.mt-4.img-fluid(:src="require('@/assets/images/checkup-calendar.svg')")
    .mt-4.font-xl.text-gray-900.font-weight-semibold Your booking has been Rescheduled
  .d-flex.m-4.p-4.align-items-center.bg-alabaster.rounded-1.reschedule-border-color-gray
    n-icon.text-center.align-self-center.rounded-circle.bg-teal-700.calendar-icon.mr-2(name="calendar-day", variant="light", :size="1")
    .d-flex.flex-column.font-sm.font-weight-semibold
      .text-gray-800 Rescheduled to
      hr.my-1
      .text-gray-900 {{ formattedDateTime }}
  .d-flex.m-4.p-4.reschedule-content.rounded-1.reschedule-border-color-teal
    img.img-fluid.mr-3(:src="require('@/assets/images/checkup-telegram.svg')")
    .font-lg.font-weight-medium.text-gray-800
      | We'll reach out to you within 10 - 12 hours with a confirmation of your
      | rescheduling request from the provider.
</template>

<script>
import moment from "moment";
import NButton from "@/components/NovaButton.vue";
import NIcon from "@/components/NovaIcon";

export default {
  name: "CheckupRescheduleModal",
  components: {
    NButton,
    NIcon,
  },
  props: {
    id: {
      type: String,
      default: "checkup-reschedule-modal",
    },
    checkupDate: {
      type: String,
      default: "",
    },
    checkupTime: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      showModal: true,
    };
  },
  computed: {
    formattedDateTime() {
      if (this.checkupDate && this.checkupTime) {
        const parseDate = moment(this.checkupDate);
        const parseStartTime = moment(this.checkupTime.startTime, "HH:mm");
        const parseEndTime = moment(this.checkupTime.endTime, "HH:mm");
        return `${parseDate.format("ddd")} · ${parseDate.format("DD MMM YYYY")} · ${parseStartTime.format(
          "h:mm A"
        )} - ${parseEndTime.format("h:mm A")}`;
      }
      return "";
    },
  },
  methods: {
    closeHandler() {
      this.$emit("modal-closed");
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/_variables.scss";
@import "@/assets/styles/mixins/_breakpoints.scss";
.checkup-reschedule-modal {
  .calendar-icon {
    border: 0.75rem solid $teal-700;
  }
  .reschedule-content {
    background-color: rgba($teal-100, 0.5);
  }
  .reschedule-border-color-teal {
    border: 1px solid $teal-200;
  }
  .reschedule-border-color-gray {
    border: 1px solid $gray-200;
  }
  @include media-breakpoint-down(xs) {
    .checkup-content {
      min-height: 50vh;
      & > img {
        height: 9rem;
        width: 9rem;
      }
    }
    .checkup-dialog-modal {
      min-height: 100vh;
      margin: 0;
      & .modal-content {
        min-height: 100vh;
        border-radius: 0;
      }
    }
  }
  @include media-breakpoint-up(lg) {
    .modal-lg {
      max-width: 550px;
    }
  }
}
</style>
