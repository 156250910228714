import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default {
  namespaced: true,
  state: {
    formData: {
      selfDetails: {
        id: "",
        name: "",
        email: "",
        dob: "",
        displayName: "",
        gender: "",
        meta: {},
      },
      dependentDetails: [],
      skipDependentAddition: false,
      isDependentsScreenAdded: false,
    },
    sessionProps: {},
  },
  getters: {},
  mutations: {
    setSelfDetails(state, payload) {
      state.formData.selfDetails = payload;
    },
    setDependentDetails(state, payload) {
      state.formData.dependentDetails = payload;
    },
    setSkipDependentAddition(state, payload) {
      state.formData.skipDependentAddition = payload;
    },
    setIsDependentScreenAdded(state, payload) {
      state.formData.isDependentsScreenAdded = payload;
    },
    setToken(state, payload) {
      state.formData.token = payload;
    },
    updateSelfMetaDetails(state, payload) {
      state.formData.selfDetails.meta = {
        ...state.formData.selfDetails.meta,
        ...payload,
      };
    },
    setSessionProps(state, payload) {
      state.sessionProps = {
        ...state.sessionProps,
        ...payload,
      };
    },
  },
};
