import moment from "moment";
import { orgEntityStatusSchema, orgServicesOptions, industriesList } from "../constants";
import { getIdSelectorQuery } from "../queries";

export default {
  schema: {
    fields: [
      {
        model: "name",
        type: "input",
        inputType: "text",
        label: "Entity Name",
        required: true,
      },
      {
        model: "org",
        type: "id-selector",
        queryName: "orgs",
        gqlQuery: getIdSelectorQuery("Org"),
        label: "Parent Organisation",
      },
      {
        model: "address",
        type: "input",
        inputType: "text",
        label: "Address",
        required: true,
      },
      {
        model: "pincode",
        type: "input",
        inputType: "text",
        label: "Pincode",
        validator: ["required", "regexp"],
        pattern: "^[1-9]{1}[0-9]{2}\\s{0,1}[0-9]{3}$",
        required: true,
      },
      {
        model: "meta",
        type: "object",
        label: "Metadata",
        schema: {
          fields: [
            {
              model: "period",
              type: "inline-select",
              label: "Period",
              options: () => {
                const currentYear = moment().year();
                const options = [];
                for (let year = currentYear; year <= currentYear + 2; year++) {
                  options.push(`${year}-${year + 1}`);
                }
                return options;
              },
              validator: ["required"],
              required: true,
            },
            {
              model: "displayName",
              type: "input",
              inputType: "text",
              label: "Display Name",
            },
            {
              model: "servicesOffered",
              type: "select",
              inputType: "text",
              label: "Services Offered",
              values: orgServicesOptions,
            },
            {
              model: "gstin",
              type: "input",
              inputType: "text",
              label: "GSTIN",
            },
            {
              model: "industry",
              type: "inline-select",
              inputType: "text",
              label: "Industry",
              options: industriesList,
            },
            {
              model: "customOnboardingUrl",
              type: "input",
              inputType: "text",
              label: "Custom-Onboarding-Screen embed url",
            },
            {
              model: "customOnboardingTitle",
              type: "input",
              inputType: "text",
              label: "Custom-Onboarding-Screen title(defaults to 'Customize Your Coverage')",
            },
          ],
        },
      },
      {
        model: "status",
        type: "select",
        label: "Status",
        values: orgEntityStatusSchema,
        required: false,
      },
    ],
  },
  filterSchema: {
    fields: [
      {
        model: "org",
        type: "id-selector",
        queryName: "orgs",
        gqlQuery: getIdSelectorQuery("Org"),
        placeholder: "Organization filter (none)",
        showSelected: false,
      },
    ],
  },
};
