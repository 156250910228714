<template lang="pug">
.super-topup-form-container
  member-addition(
    v-if="$route.path.includes(`${TopupRoutes.ADD_MEMBERS}`)"
  )
  policy-details(
    v-else-if="$route.path.includes(`${TopupRoutes.POLICY_DETAILS}`)"
  )
  nominee-details(ref="nomineeDetails", v-else-if="$route.path.includes(`${TopupRoutes.NOMINEE_DETAILS}`)", @validated="handleValidation")

  contact-details(ref="contactDetails", v-else-if="$route.path.includes(`${TopupRoutes.CONTACT_DETAILS}`)", @validated="handleValidation")

  policy-summary(v-else)


</template>

<script>
import NovaIcon from "../../../../components/NovaIcon.vue";
import { TopupRoutes } from "../../../../common/enums/topupRoutes.enum";
import MemberAddition from "./components/MemberAddition.vue";
import PolicyDetails from "./components/PolicyDetails.vue";
import NomineeDetails from "./components/NomineeDetails.vue";
import PolicySummary from "./components/PolicySummary.vue";
import ContactDetails from "./components/ContactDetails.vue";
export default {
  name: "SuperTopupFormContainer",
  components: {
    NovaIcon,
    MemberAddition,
    PolicyDetails,
    NomineeDetails,
    ContactDetails,
    PolicySummary,
  },
  data() {
    return {
      TopupRoutes,
    };
  },
  created() {
    this.$parent.$on("storeLoaded", () => {
      this.$emit("storeLoaded");
    });
    this.$parent.$on("pushDepStates", () => {
      this.$emit("pushDepStates");
    });
  },
  methods: {
    async validateForms(ref) {
      return new Promise((resolve) => {
        this.$refs[ref].validateForms().then(resolve);
      });
    },
    handleValidation(isValid) {
      this.$emit("validated", isValid);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
</style>
