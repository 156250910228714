import gql from "graphql-tag";
import {
  strapiContentFragment,
  tpaFragment,
  tpaOfficeFragment,
  benefitFragment,
  userChangeFragment,
  userFragment,
  challengeTemplateFragment,
  orgFragment,
  orgFragmentForCliTools,
  claimFragment,
  claimResourceListFragment,
  integrationFragment,
  formFragment,
  insurerFragment,
  hospitalFragment,
  contentFragment,
  prospectFragment,
  jobFragment,
  userChangeBatchFragment,
  orgChallengeFragment,
  userChallengeFragment,
  segmentFragment,
  userSegmentFragment,
  calendarEventFragment,
  orgPropertyFragment,
  samlVendorConfigFragment,
  fileFragment,
  fileGroupFragment,
  userResourceListFragment,
  CXPodFragment,
  CXPodMemberFragment,
  CXAccountOwnerFragment,
  orgOnboardingStageTaskFragment,
  orgOnboardingStageTaskUpdateFragment,
  checkupProviderFragment,
  stagedClaimFragment,
  stagedClaimResourceListFragment,
  policyDataCheckErrorFragment,
  policyDataCheckBatchFragment,
  orgResourceListFragment,
  orgIdSelectorFragment,
  novaOrderFragment,
  pgPaymentFragment,
  premiumDetailFragment,
  benefitIdSelectorFragment,
  orgEntityFragment,
  teamFragment,
  designationFragment,
  userTeamDesignationFragment,
  echoPostTemplateFragment,
  echoPostFragment,
  userChangeBatchResourceListFragment,
  userIdSelectorFragment,
  orgEntityResourceListFragment,
  orgEntityIdSelectorFragment,
  benefitFragmentForCliTools,
  jobWithoutLogsFragment,
} from "./fragments";

export const gqlResourceConfig = {
  User: {
    plural: "users",
    fragment: userFragment,
    resourceListFragment: userResourceListFragment,
    idSelectorFragment: userIdSelectorFragment,
  },
  Org: {
    plural: "orgs",
    fragment: orgFragment,
    orgFragmentForCliTools: orgFragmentForCliTools,
    resourceListFragment: orgResourceListFragment,
    idSelectorFragment: orgIdSelectorFragment,
  },
  Insurer: { plural: "insurers", fragment: insurerFragment },
  Tpa: { plural: "tpas", fragment: tpaFragment },
  TpaOffice: { plural: "tpaOffices", fragment: tpaOfficeFragment },
  Hospital: { plural: "hospitals", fragment: hospitalFragment },
  Form: { plural: "forms", fragment: formFragment },
  Claim: { plural: "claims", fragment: claimFragment, resourceListFragment: claimResourceListFragment },
  StagedClaim: {
    plural: "stagedClaims",
    fragment: stagedClaimFragment,
    resourceListFragment: stagedClaimResourceListFragment,
  },
  Integration: { plural: "integrations", fragment: integrationFragment },
  Content: { plural: "content", fragment: contentFragment },
  Benefit: {
    plural: "benefits",
    fragment: benefitFragment,
    benefitFragmentForCliTools: benefitFragmentForCliTools,
    idSelectorFragment: benefitIdSelectorFragment,
  },
  CheckupProvider: { plural: "checkupProviders", fragment: checkupProviderFragment },
  UserChange: { plural: "userChanges", fragment: userChangeFragment },
  Prospect: { plural: "prospects", fragment: prospectFragment },
  Job: { plural: "jobs", fragment: jobFragment, resourceListFragment: jobWithoutLogsFragment },
  StrapiContent: { plural: "strapiContents", fragment: strapiContentFragment },
  OrgProperty: { plural: "orgProperties", fragment: orgPropertyFragment },
  OrgEntity: {
    plural: "orgEntities",
    fragment: orgEntityFragment,
    resourceListFragment: orgEntityResourceListFragment,
    idSelectorFragment: orgEntityIdSelectorFragment,
  },
  UserChangeBatch: {
    plural: "userChangeBatches",
    fragment: userChangeBatchFragment,
    resourceListFragment: userChangeBatchResourceListFragment,
  },
  ChallengeTemplate: {
    plural: "challengeTemplates",
    fragment: challengeTemplateFragment,
  },
  OrgChallenge: { plural: "orgChallenges", fragment: orgChallengeFragment },
  UserChallenge: { plural: "userChallenges", fragment: userChallengeFragment },
  Segment: { plural: "segments", fragment: segmentFragment },
  UserSegment: { plural: "userSegments", fragment: userSegmentFragment },
  SamlVendorConfig: { plural: "samlVendorConfigs", fragment: samlVendorConfigFragment },
  CalendarEvent: { plural: "calendarEvents", fragment: calendarEventFragment },
  File: { plural: "files", fragment: fileFragment },
  FileGroup: { plural: "fileGroups", fragment: fileGroupFragment },
  CXPod: { plural: "CXPods", fragment: CXPodFragment },
  CXPodMember: { plural: "CXPodMembers", fragment: CXPodMemberFragment },
  CXAccountOwner: { plural: "CXAccountOwners", fragment: CXAccountOwnerFragment },
  OrgOnboardingStageTask: { plural: "orgOnboardingStageTasks", fragment: orgOnboardingStageTaskFragment },
  OrgOnboardingStageTaskUpdate: {
    plural: "orgOnboardingStageTaskUpdates",
    fragment: orgOnboardingStageTaskUpdateFragment,
  },
  PolicyDataCheckError: {
    plural: "policyDataCheckErrors",
    fragment: policyDataCheckErrorFragment,
  },
  PolicyDataCheckBatch: {
    plural: "policyDataCheckBatches",
    fragment: policyDataCheckBatchFragment,
  },
  NovaOrder: { plural: "NovaOrders", fragment: novaOrderFragment },
  PgPayment: { plural: "PgPayments", fragment: pgPaymentFragment },
  PremiumDetail: { plural: "premiumDetails", fragment: premiumDetailFragment },
  Team: { plural: "teams", fragment: teamFragment },
  Designation: { plural: "designations", fragment: designationFragment },
  UserTeamDesignation: { plural: "userTeamDesignations", fragment: userTeamDesignationFragment },
  EchoPostTemplate: { plural: "echoPostTemplates", fragment: echoPostTemplateFragment },
  EchoPost: { plural: "echoPosts", fragment: echoPostFragment },
};

export const getIdSelectorQuery = (gqlType) => {
  const { plural, idSelectorFragment } = gqlResourceConfig[gqlType];
  const query = gql`
  query ${gqlType}List(
      $query: String
      $offset: Int
      $limit: Int
      $filter: JSONObject
    ) {
      ${plural}(
        query: $query
        offset: $offset
        limit: $limit
        filter: $filter
        ) {
      edges {
        node { ...${gqlType} }
      }
      totalCount
    }
  }
  ${idSelectorFragment}`;
  return query;
};

export const getListQuery = (gqlType, fragmentType = "fragment") => {
  // TODO: remove, directly call getFilteredListQuery
  if (
    [
      "User",
      "Claim",
      "Benefit",
      "StrapiContent",
      "ChallengeTemplate",
      "OrgChallenge",
      "UserChallenge",
      "OrgProperty",
      "OrgEntity",
      "Segment",
      "CalendarEvent",
      "UserSegment",
      "SamlVendorConfig",
      "File",
      "OrgOnboardingStageTask",
      "OrgOnboardingStageTaskUpdate",
      "FileGroup",
      "CXPod",
      "CXPodMember",
      "CXAccountOwner",
      "Job",
      "StagedClaim",
      "PolicyDataCheckError",
      "PolicyDataCheckBatch",
      "TpaOffice",
      "NovaOrder",
      "PgPayment",
      "Team",
      "Designation",
      "UserTeamDesignation",
      "EchoPostTemplate",
      "EchoPost",
    ].includes(gqlType)
  ) {
    return getFilteredListQuery(gqlType, fragmentType);
  }
  const { plural } = gqlResourceConfig[gqlType];
  const fragment = gqlResourceConfig[gqlType][fragmentType];
  const query = gql`
  query ${gqlType}List(
      $query: String
      $offset: Int
      $limit: Int
    ) {
      ${plural}(
        query: $query
        offset: $offset
        limit: $limit
        ) {
      edges {
        node { ...${gqlType} }
      }
      totalCount
    }
  }
  ${fragment}`;
  return query;
};

export const getFilteredListQueryForFragment = (gqlType, fragment) => {
  const { plural } = gqlResourceConfig[gqlType];
  const query = gql`
  query ${gqlType}List(
      $query: String
      $offset: Int
      $limit: Int
      $filter: JSONObject
      $sortBy: JSONObject
    ) {
      ${plural}(
        query: $query
        offset: $offset
        limit: $limit
        filter: $filter
        sortBy: $sortBy
        ) {
      edges {
        node { ...${gqlType} }
      }
      totalCount
    }
  }
  ${fragment}`;
  return query;
};

export const getFilteredListQuery = (gqlType, fragmentType = "fragment") => {
  const fragment = gqlResourceConfig[gqlType][fragmentType];
  return getFilteredListQueryForFragment(gqlType, fragment);
};

export const getResourceListQuery = (gqlType, fieldsToQuery) => {
  const { plural, resourceListFragment } = gqlResourceConfig[gqlType];
  // TODO: Research and generate schema dynamically from the passed fields
  const query = gql`
    query ${gqlType}ResourceList(
        $query: String
        $offset: Int
        $limit: Int
        $filter: JSONObject
      ) {
        ${plural}(
          query: $query
          offset: $offset
          limit: $limit
          filter: $filter
          ) {
        edges {
          node { ...${gqlType} }
        }
        totalCount
      }
    }
    ${resourceListFragment}
  `;

  return query;
};

export const getSingleQuery = (gqlType) => {
  const { fragment } = gqlResourceConfig[gqlType];
  const query = gql`query ${gqlType}Single($id: ID!){
    node(id: $id){ ...${gqlType} }
  }
  ${fragment}`;
  return query;
};

export const getECardFormats = () => {
  const query = gql`
    query eCardFormats {
      eCardFormats
    }
  `;
  return query;
};
