import gql from "graphql-tag";

export const UPLOAD_FILE = gql`
  mutation upsertFile($file: Upload!, $meta: JSONObject, $action: String!) {
    upsertFile(input: { upload: $file, meta: $meta, action: $action }) {
      file {
        id
        name
        url
        meta
        action
        uploadingUser {
          org {
            name
          }
        }
      }
    }
  }
`;
export const GET_FILE_SIGNED_URL = gql`
  query getFileSignedUrl($action: String, $filePath: String) {
    getFileSignedUrl(action: $action, filePath: $filePath)
  }
`;

export const GET_HANDBOOK_FILE_URL = gql`
  query getHandbookFileUrl($insurerId: String, $tpaId: String) {
    getHandbookFileUrl(insurerId: $insurerId, tpaId: $tpaId)
  }
`;
