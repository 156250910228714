import gql from "graphql-tag";
import { orgOnboardingStageTaskUpdateFragment } from "../fragments";

export default {
  upsertMutation: gql`
    mutation upsertOrgOnboardingStageTaskUpdate(
      $id: ID
      $orgOnboardingStageTaskId: ID
      $userId: ID
      $orgId: ID
      $benefitId: ID
      $stage: String
      $meta: JSONObject
    ) {
      upsertOrgOnboardingStageTaskUpdate(
        input: {
          id: $id
          orgOnboardingStageTaskId: $orgOnboardingStageTaskId
          userId: $userId
          orgId: $orgId
          benefitId: $benefitId
          stage: $stage
          meta: $meta
        }
      ) {
        orgOnboardingStageTaskUpdate {
          ...OrgOnboardingStageTaskUpdate
        }
      }
    }
    ${orgOnboardingStageTaskUpdateFragment}
  `,
};
