<template lang="pug">
.container
  transition(name="fade", mode="out-in")
    router-view(:key="$route.path")
  
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.active > .home-nav-text {
  color: white !important;
}
</style>
