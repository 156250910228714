import gql from "graphql-tag";

export const ngageUserDetailFragment = gql`
  fragment NgageUserDetail on NgageUserDetail {
    id
    user {
      id
      email
      name
    }
    deviceIntegrationProvider
    meta
    createdAt
    updatedAt
  }
`;
