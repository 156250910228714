// These status are different from backend batch status, Ref getBatchStatus method.
export const EndorsementBatchStatus = {
  EMPTY: "empty",
  OVERDUE: "overdue",
  ONGOING: "ongoing",
  COMPLETED: "completed",
  CURRENT: "current",
  UPCOMING: "upcoming",
  REJECTED: "rejected",
};
