import  gql  from 'graphql-tag';

const supportTicketsFragment = gql`
  fragment SupportTicket on SupportTicket {
    id
    ticketId
    ticketNumber
    status
    subject
    channel
    assignee
    isEscalated
    createdTime
    modifiedTime
  }
`;

export default {
  supportTickets:{
    listQuery:
      gql`
        query SupportTicketList(
          $limit:Int
          $offset:Int
          $filter: JSONObject
        ) {
          supportTickets(limit: $limit, offset: $offset, filter:$filter){
            edges{
              node{
                ...SupportTicket
              }
            }
            totalCount
          }
        }
        ${supportTicketsFragment}
      `,
      transform: (n) => {
        return n.supportTickets.edges.map(({ node }) => node);
      }
  }
}

