import { getListQuery, getSingleQuery } from "../queries";
import prospectMutations from "../mutations/prospects";

export default {
  gqlType: "Prospect",
  fields: ["uploaded_on", "organization", "source", "link"],
  singleQuery: getSingleQuery("Prospect"),
  listQuery: getListQuery("Prospect"),
  ...prospectMutations,
  transform: (n) => {
    return {
      ...n,
      /* eslint-disable-next-line camelcase */
      organization: n.meta?.org_name || n.meta?.orgName || null,
      uploaded_on: new Date(parseInt(n.timestamp)).toLocaleString("en-US"),
      source: n.uploaded_file_name,
    };
  },
};
