import { render, staticRenderFns } from "./NovaButton.vue?vue&type=template&id=02d422cc&scoped=true&lang=pug"
import script from "./NovaButton.vue?vue&type=script&lang=js"
export * from "./NovaButton.vue?vue&type=script&lang=js"
import style0 from "./NovaButton.vue?vue&type=style&index=0&id=02d422cc&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "02d422cc",
  null
  
)

export default component.exports