<template lang="pug">
.mt-0.h-100
  #pdf-view
</template>

<script>
export default {
  props: {
    url: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      pdfAPIReady: false,
      adobeDCView: null,
    };
  },
  watch: {
    pdfAPIReady(val) {
      if (val) {
        this.adobeDCView = new AdobeDC.View({
          clientId: process.env.VUE_APP_ADOBE_PDF_EMBED_KEY,
          divId: "pdf-view",
        });
      }
    },
  },
  created() {
    if (window.AdobeDC) {
      this.pdfAPIReady = true;
      setTimeout(() => {
        this.previewPDF();
      }, 100);
    } else {
      console.log("No adobe found");
    }
  },
  mounted() {},
  methods: {
    previewPDF() {
      const viewer = this.adobeDCView;
      viewer.previewFile(
        {
          content: {
            location: {
              url: this.url,
            },
          },
          metaData: { fileName: "Claim Form A.pdf" },
        },
        {
          defaultViewMode: "FIT_WIDTH",
          showDownloadPDF: false,
          showPrintPDF: false,
          showAnnotationTools: false,
          enableFormFilling: false,
          showBookmarks: false,
        }
      );
    },
  },
};
</script>
