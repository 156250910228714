import gql from "graphql-tag";
import { tpaOfficeFragment } from "../fragments";

export default {
  upsertMutation: gql`
    mutation upsertTpaOffice(
      $id: ID
      $tpaId: ID!
      $name: String
      $pincode: String
      $address: String
      $status: String
    ) {
      upsertTpaOffice(
        input: {
          id: $id
          tpaId: $tpaId
          name: $name
          address: $address
          pincode: $pincode
          status: $status
        }
      ) {
        tpaOffice {
          ...TpaOffice
        }
      }
    }
    ${tpaOfficeFragment}
  `,
};
