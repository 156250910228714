import gql from "graphql-tag";
import insurerBatchesMutations from "../mutations/insurerBatches";
export default {
  gqlType: "InsurerBatches",
  ...insurerBatchesMutations,
  getInsurerEndorsementBatches: gql`
    query getInsurerEndorsementBatches($batchId: ID!) {
      getInsurerEndorsementBatches(batchId: $batchId) {
        edges {
          node {
            policyName
            insurerName
            endorsementId
            insurerStatus
            insurerComment
            submittedFileUrl
            informationRequiredDocUrl
            endorsementCopyUrl
            activeListUrl
            infoDetails
            submittedAt
            submittedBy {
              name
            }
            updatedAt
          }
        }
      }
    }
  `,
};
