<template lang="pug">
  b-modal(
    v-bind="{...$props, ...$attrs}",
    v-model="ctrlValue",
    scrollable,
    v-on="$listeners",
    title='Nova Modal',
    :dialog-class="isScrollable ? 'nova-modal' : 'nova-modal overflow-modal hide-scrollbar'",
    :header-close-content="showCross ? '<i class=\"icon-cross\"> </i>' : ''",
    :body-class="isScrollable ? '' : 'hide-scrollbar'",
    body-class="nova-modal-body")
    template(v-slot:modal-header)
      slot(name="modal-header")
    template(v-slot:modal-footer)
      slot(name="modal-footer")
    template(v-slot:default)
      slot(name="default")
        p.my-4 Hello from modal!
    template(v-slot:modal-cancel)
      slot(name="modal-cancel")
    template(v-slot:modal-ok)
      slot(name="modal-ok")
</template>

<script>
export default {
  name: "NovaModal",
  props: {
    isScrollable: {
      type: Boolean,
      default: false,
    },
    showCross: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    // proxy for showModal v-model binding
    ctrlValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="scss">
@import "@/assets/styles/_variables.scss";

.overflow-modal {
  .modal-header {
    border-bottom: 0 !important;
  }
  .modal-footer {
    border-top: 0 !important;
  }
}

.nova-modal {
  .modal-content {
    border-radius: 6px;
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.04);

    .modal-header {
      font-family: "Inter", sans-serif;
      font-weight: 600;
      font-size: 1rem;
      color: $gray-900;
    }
    .modal-footer {
      padding: 1rem;
    }
    .nova-modal-body {
      font-family: "Inter", sans-serif;
      font-weight: 500;
      color: $gray-900;
    }
  }
}
</style>
