<template lang="pug">
.policy-selection-card
  .policy-selection-card-body.my-1.p-3.mx-1
    .align-items-center.cursor-pointer.justify-content-center
      b-form(@submit.prevent="")
        vue-form-generator(
          :schema="schema"
          :options="formOptions"
          ref="formData"
          :model="model"
          @validated="validateForm")
    div.p-3.bg-teal-100(v-if="note")
      .font-sm.text-gray-900 {{ note }}
      b-link.font-sm(@click="toggleSidesheet()") See how deductibles work ->
  b-tooltip(:target="`si-note-${type}`", variant="light") {{ "The maximum amount your insurance company can provide you in case of any claims" }}
    
</template>
<script>
import NovaIcon from "../../../../../components/NovaIcon.vue";
export default {
  name: "PolicySelectionCard",
  components: {
    NovaIcon,
  },
  data() {
    return {
      formOptions: {
        validateAfterLoad: true,
        validateAfterChanged: true,
        validateAsync: true,
      },
    };
  },
  props: {
    model: {
      type: Object,
      default: () => {},
    },
    defaultDeductible: {
      type: [Number, String],
      default: 0,
    },
    schema: {
      type: Object,
      default: () => {},
    },
    note: {
      type: String,
      default: "",
    },
    policyDetails: {
      type: Object,
      default: () => {},
    },
    type: {
      type: String,
      default: "",
    },
  },
  watch: {
    "model.deductible": {
      handler(value) {
        this.model.si = "None selected";
      },
    },
  },
  methods: {
    setDefaultDeductible() {
      if (this.defaultDeductible && !this.model.deductible)
        this.$set(this.model, "deductible", `${this.defaultDeductible}`);
    },
    validateForm(isValid, errors) {
      this.$store.commit("updateSuperTopupProspectStateMeta", { isFormValid: isValid });
    },
    toggleSidesheet() {
      this.$root.$emit("bv::toggle::collapse", "deductible-sidesheet");
    },
  },
  mounted() {
    this.setDefaultDeductible();
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
.policy-selection-card {
  .policy-selection-card-body {
    background-color: white;
    border: 0.125rem solid $gray-300;
    border-radius: 0.375rem;
  }
}
</style>
