<template lang="pug">
.add-dependents-modal__backdrop.bg-gray-100
  .container
    .row.bg-gray-100
      .col-md-6.mx-auto.mb-8.add-dependents-modal__top-margin
        add-dependents-card.add-dependents-modal__box-shadow(
          :user="user",
          :dependentsModel="model",
          :isInsideModal="true"
          :isMidTerm="isMidTermAddition",
          :restrictDependentAddition="restrictDependentAddition",
          :selectedDependent="selectedDependent",
          :policies="policies",
          @close="$emit('close')"
          @modelUpdated="onModelUpdated")
</template>

<script>
import NButton from "@/components/NovaButton.vue";
import NAvatar from "@/components/Avatar.vue";
import AddDependentsCard from "@/portal/pages/user/getStarted/components/AddDependentsCard.vue";

export default {
  name: "AddDependentsModalContainer",
  components: {
    NButton,
    NAvatar,
    AddDependentsCard,
  },
  props: {
    model: {
      type: Array,
      default: () => [],
    },
    user: {
      type: Object,
      default: null,
    },
    selectedDependent: {
      type: Object,
      default: () => {},
    },
    isMidTermAddition: {
      type: Boolean,
      default: false,
    },
    restrictDependentAddition: {
      type: Boolean,
      default: false,
    },
    policies: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    isNewDepAddition() {
      if (this.selectedDependent.id) {
        return false;
      }
      return true;
    },
  },
  methods: {
    onModelUpdated: function (depModel, relation) {
      this.$emit("modelUpdated", depModel, relation, this.isNewDepAddition);
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/_variables.scss";

.add-dependents-modal__backdrop {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  backdrop-filter: blur(4px);
  overflow-y: auto;
  z-index: 1000;
}

.add-dependents-modal__box-shadow {
  background: #ffffff;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
  border-radius: 12px;
}

.add-dependents-modal__top-margin {
  @media (max-width: 786px) {
    margin-top: 1.563rem;
  }
  @media (min-width: 768px) {
    margin-top: 6.25rem;
  }
}
</style>
