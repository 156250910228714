<template lang="pug">
.d-flex.position-relative.align-items-center
  n-icon.d-none.d-md-inline-block.text-gray-900.rounded-circle.bg-white.scroll-btn.cursor-pointer(name="chevron-left", :size="1.5", @click="scrollLeft", v-if="showLeftScrollButton")
  .time-cards-wrapper.d-flex.position-relative(
    ref="timeCardsWrapper",
    @scroll="handleScroll",
    :class="fadeClass"
    )
    .time-card.d-flex.align-items-center.justify-content-center.position-relative.shadow-xs(
      v-for="timeSlot in timeSlots",
      :key="timeSlot.id",
      :class="{ 'time-card-selected': timeSlot.selected }",
      @click="selectTimeSlot(timeSlot)")
      span.font-md.text-gray-900.font-weight-semibold {{ timeSlot.timeSlotText }}
  n-icon.d-none.d-md-inline-block.text-gray-900.rounded-circle.bg-white.scroll-btn.cursor-pointer(name="chevron-right", :size="1.5", @click="scrollRight", v-if="showRightScrollButton")
</template>

<script>
import moment from "moment";
export default {
  props: {
    availableTimeSlots: {
      type: Array,
      default: () => [],
    },
    value: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      showLeftScrollButton: false,
      showRightScrollButton: true,
      fadeClass: "",
    };
  },
  computed: {
    selectedTime: {
      get() {
        if (this.timeSlots?.length && this.value) {
          return this.timeSlots.find(
            (time) => time.startTime === this.value.startTime && time.endTime === this.value.endTime
          );
        }
        return {};
      },
      set(value) {
        const { startTime, endTime } = value;
        this.$emit("input", { startTime, endTime });
      },
    },
    timeSlots() {
      const possibleTimeSlots = [];
      if (this.availableTimeSlots?.length) {
        for (const [index, timeSlot] of this.availableTimeSlots.entries()) {
          const startTime = moment(timeSlot.start_time, "HH:mm");
          const endTime = moment(timeSlot.end_time, "HH:mm");
          const timeSlotText = `${startTime.format("hh:mm")} - ${endTime.format("hh:mm A")}`;
          const selected = timeSlot.start_time === this.value?.startTime && timeSlot.end_time === this.value?.endTime;
          possibleTimeSlots.push({
            id: index,
            startTime: timeSlot.start_time,
            endTime: timeSlot.end_time,
            timeSlotText: timeSlotText,
            selected,
          });
        }
      }
      return possibleTimeSlots;
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.handleScroll();
    });
  },
  methods: {
    scrollLeft() {
      this.$refs.timeCardsWrapper.scrollBy({
        left: -300,
        behavior: "smooth",
      });
    },
    scrollRight() {
      this.$refs.timeCardsWrapper.scrollBy({
        left: 300,
        behavior: "smooth",
      });
    },
    handleScroll() {
      const wrapper = this.$refs.timeCardsWrapper;
      const maxScrollLeft = wrapper.scrollWidth - wrapper.clientWidth;

      this.showLeftScrollButton = wrapper.scrollLeft > 0;
      this.showRightScrollButton = wrapper.scrollLeft < maxScrollLeft;
      this.fadeClass =
        this.showLeftScrollButton && this.showRightScrollButton
          ? "faded-left-right"
          : this.showLeftScrollButton
          ? "faded-left-only"
          : this.showRightScrollButton
          ? "faded-right-only"
          : "";
    },
    selectTimeSlot(timeSlot) {
      this.selectedTime = { ...timeSlot };
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
@import "@/assets/styles/mixins/_breakpoints.scss";

.scroll-btn {
  border: 1px solid $gray-200;
}

.time-cards-wrapper {
  overflow-x: auto;
  white-space: nowrap;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.time-cards-wrapper::-webkit-scrollbar {
  display: none;
}

.time-cards-wrapper.faded-left-only {
  mask-image: linear-gradient(to right, transparent 0%, black 5%) !important;
  -webkit-mask-image: linear-gradient(to right, transparent 0%, black 5%) !important;
}

.time-cards-wrapper.faded-right-only {
  -webkit-mask-image: linear-gradient(to right, black 95%, transparent 100%) !important;
  mask-image: linear-gradient(to right, black 95%, transparent 100%) !important;
}
.time-cards-wrapper.faded-left-right {
  mask-image: linear-gradient(to right, transparent 0%, black 5%, black 95%, transparent 100%);
  -webkit-mask-image: linear-gradient(to right, transparent 0%, black 5%, black 95%, transparent 100%);
}

.left-scroll-button,
.right-scroll-button {
  position: absolute;
  z-index: 1;
  background: $white;
  border: none;
  cursor: pointer;
}

.left-scroll-button {
  left: 0;
}

.right-scroll-button {
  right: 0;
}
.time-card {
  box-sizing: border-box;
  border: 1px solid $gray-300;
  border-radius: 0.375rem;
  margin: 0 3px;
  cursor: pointer;
  height: 2.625rem !important;
  width: 9.375rem !important;
  align-items: center;
  padding: 1rem 0.75rem;
  background-color: $white;
}
.time-card:hover {
  box-shadow: $box-shadow-sm !important;
}
.time-card-selected {
  border-color: $teal-700;
  background-color: $teal-100;
}
</style>
