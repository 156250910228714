<template lang="pug">
#insurance-benefits.policy-cards-wrapper
  .pt-2.text-gray-800.d-flex.align-items-center
    n-icon.pr-1(name="stroke-policies-3", :size="1.1")
    span.font-md.font-weight-semibold.text-gray-800(ref="policies") Insurance Benefits
  .row
    list-zero-state.m-3(v-if="allActivePolicies.length === 0", subject="dashboardPoliciesList")
    template(v-else)
      .col-12.my-3(
        :class="columnClass"
        v-for="(policyEdge,index) in allActivePolicies",
        :key="policyEdge.node.id")
        policy-card(
          :index="index",
          :policy="policyEdge.node",
          :user="user",
          :edgeMeta="policyEdge.meta",
          :formData="policyEdge.formData"
          :isSuperTopup="isSuperTopup(policyEdge)"
          :to="getPolicyRouteData(policyEdge)",
          :previewMode="previewMode")
    template(v-if="showTopupCard")
      .col-12.my-3(:class="columnClass")
        policy-card(
          :policy="addOnTopupCard",
          :variant="'addOnCards'",
          :to="{name: 'topups', params: {policy: gmcPolicy.node}}",
          :user="user")
    template(v-if="showFlexiWalletCard")
      .col-12.my-3(:class="columnClass")
        policy-card(
          :policy="addOnFlexiWalletCard",
          :variant="'addOnCards'",
          :buttonUrl="flexiWalletUrl")
</template>

<script>
import { mapGetters } from "vuex";
import PolicyCard from "./PolicyCard.vue";
import ListZeroState from "./ListZeroState.vue";
import { sortBenefitsByDisplayOrder } from "@/utils/misc";
import utils from "@/utils";

export default {
  name: "PolicyCardWrapper",
  components: {
    PolicyCard,
    ListZeroState,
  },
  props: {
    allPolicies: {
      type: Array,
      default: () => [],
    },
    user: {
      type: Object,
      default: null,
    },
    previewMode: {
      type: Boolean,
      default: false,
    },
    halfWidth: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      addOnTopupCard: {
        id: "topup-card",
        type: "addOnCards",
      },
    };
  },
  computed: {
    ...mapGetters(["getFeatureFlags"]),
    gmcPolicy() {
      return this.allPolicies.find((p) => p.node.type === "gmc");
    },
    superTopPolicy() {
      return this.allPolicies.find((p) => p.node.type === "super-topup");
    },
    columnClass() {
      return this.halfWidth ? "col-md-6" : "col-md-12";
    },
    showTopupCard() {
      return this.gmcPolicy && this.getFeatureFlags.SUPER_TOPUPS && !this.previewMode && !this.superTopPolicy;
    },
    showFlexiWalletCard() {
      return this.getFeatureFlags.FLEXI_WALLET && !this.previewMode;
    },
    flexiWalletCoins() {
      return 5000;
    },
    flexiWalletUrl() {
      return `${process.env.VUE_APP_FLEXI_WALLET_RETOOL_APP}?coins=${this.flexiWalletCoins}&userid=${this.user?.email}`;
    },
    addOnFlexiWalletCard() {
      return {
        id: "flexi-wallet-card",
        type: "addOnCards",
        infoCard: {
          title: "Current Balance",
          icon: "wallet",
          value: this.flexiWalletCoins,
        },
      };
    },
    allActivePolicies() {
      const activePolicies = this.allPolicies?.filter((policy) => !this.isPolicyExpired(policy.node));
      sortBenefitsByDisplayOrder(activePolicies || [], this.user?.org?.benefitsDisplayOrder || []);
      return activePolicies;
    },
  },
  methods: {
    isPolicyExpired(policy) {
      const expiryDate = policy.meta?.endDate ? policy.meta.endDate : "2050-01-01";
      return utils.isExpired(expiryDate);
    },
    getPolicyRouteData(policyEdge) {
      return { name: "policy", params: { policyId: policyEdge.node?.id, user: this.user } };
    },
    isSuperTopup(policyEdge) {
      return policyEdge?.node?.type == "super-topup";
    },
  },
};
</script>

<style lang="scss" scoped>
.policy-card-wrapper {
  & > div {
    max-width: 100%;
  }
}
</style>
