import gql from "graphql-tag";

export const orgOnboardingStageTaskFragment = gql`
  fragment OrgOnboardingStageTask on OrgOnboardingStageTask {
    id
    status
    task
    stage
    meta
    org {
      id
      name
    }
    orgEntity {
      id
      name
    }
    benefit {
      id
      name
      type
      isPolicy
      meta
      tpa {
        id
        name
        photoUrl
      }
      insurer {
        id
        name
        photoUrl
      }
    }
    updates {
      id
      meta
      user {
        id
        name
      }
      updatedAt
    }
    updatedAt
  }
`;
