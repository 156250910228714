export const policyTypeList = [
  "gmc",
  "opd",
  "gpa",
  "gtl",
  "covid",
  "workmen",
  "topup",
  "super-topup",
  "dcl",
  "ppc",
  "gratuity",
];
