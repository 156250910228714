import moment from "moment";
import { getIdSelectorQuery } from "../queries";
import { validateName, validateDisplayName, validateEmployeeId } from "../../../../utils/validators";
import { genderList, dependentRelationList, userPriorityList } from "../../../../utils/adminAndOrgAdmin";

export default {
  schema: {
    fields: [
      {
        model: "org",
        type: "id-selector",
        queryName: "orgs",
        gqlQuery: getIdSelectorQuery("Org"),
        label: "Organization",
        validator: ["required"],
        required: true,
      },
      {
        model: "orgEntity",
        type: "id-selector",
        queryName: "orgEntities",
        gqlQuery: getIdSelectorQuery("OrgEntity"),
        queryVariables: () => ({ filter: {} }),
        label: "Organization Entity",
      },
      {
        model: "email",
        type: "inline-input",
        label: "Email Address",
        validator: "email",
        required: true,
      },
      {
        model: "password",
        type: "input",
        inputType: "password",
        label: "New Password (Leave empty to keep unchanged)",
      },
      {
        model: "name",
        type: "inline-input",
        inputType: "text",
        label: "Full Name",
        validator: [validateName],
        required: true,
      },
      {
        model: "displayName",
        type: "inline-input",
        inputType: "text",
        validator: [validateDisplayName],
        label: "Display Name (nickname or first name)",
      },
      {
        model: "dob",
        type: "datepicker",
        label: "Date of Birth",
        min: "1900-01-01",
        max: moment().format("YYYY-MM-DD"),
        required: true,
      },
      {
        model: "gender",
        type: "select",
        label: "Gender",
        values: genderList,
        required: true,
        validator: ["required"],
      },
      {
        model: "dependents",
        type: "array",
        itemContainerComponent: "form-container",
        label: "Employee Dependents",
        items: {
          type: "object",
          schema: {
            fields: [
              {
                model: "name",
                type: "inline-input",
                inputType: "text",
                label: "Dependent Name",
                validator: [validateName],
                required: true,
              },
              {
                model: "dob",
                type: "datepicker",
                label: "Dependent Date of Birth",
                min: "1900-01-01",
                max: "2100-12-31",
                required: true,
              },
              {
                model: "gender",
                type: "select",
                label: "Gender",
                values: genderList,
                required: true,
              },
              {
                model: "relation",
                type: "select",
                label: "Relation with Employee",
                values: dependentRelationList,
                required: true,
              },
            ],
          },
        },
      },
      {
        model: "meta",
        type: "object",
        label: "Metadata",
        schema: {
          fields: [
            {
              model: "employeeId",
              type: "inline-input",
              inputType: "text",
              label: "Employee ID",
              validator: [validateEmployeeId],
              required: true,
            },
            {
              model: "contactNumber",
              type: "inline-input",
              inputType: "tel",
              label: "Contact Number",
            },
            {
              model: "dateOfJoining",
              type: "datepicker",
              label: "Date of Joining",
              disabled: false,
            },
            {
              model: "jobGrade",
              type: "select",
              label: "Employee Job Grade",
              values: [],
              fieldClasses: ["form-select"],
              disabled: false,
              required: true,
            },
            {
              model: "priority",
              type: "select",
              label: "Employee Priority",
              values: userPriorityList,
            },
            {
              model: "isPoc",
              type: "checkbox",
              label: "Is Point of Contact",
              required: false,
            },
          ],
        },
      },
    ],
  },
  inviteHrSchema: {
    fields: [
      {
        model: "org",
        type: "id-selector",
        queryName: "orgs",
        gqlQuery: getIdSelectorQuery("Org"),
        label: "Organization",
        required: true,
      },
      {
        model: "email",
        type: "input",
        inputType: "email",
        label: "Email Address",
        validator: ["email"],
        required: true,
      },
      {
        model: "name",
        type: "inline-input",
        inputType: "text",
        validator: [validateName],
        label: "Full Name",
        required: true,
      },
      {
        model: "meta",
        type: "object",
        label: "Metadata",
        schema: {
          fields: [
            {
              model: "contactNumber",
              type: "inline-input",
              inputType: "tel",
              label: "Contact Number",
              required: true,
            },
            {
              model: "jobGrade",
              type: "select",
              label: "Employee Job Grade",
              values: [],
              fieldClasses: ["form-select"],
              disabled: false,
              required: true,
            },
          ],
        },
      },
    ],
  },
  filterSchema: {
    fields: [
      {
        label: "Organization",
        model: "org",
        type: "id-selector",
        queryName: "orgs",
        gqlQuery: getIdSelectorQuery("Org"),
        placeholder: "Organization filter (none)",
        showSelected: false,
      },
      {
        model: "orgEntity",
        type: "id-selector",
        queryName: "orgEntities",
        gqlQuery: getIdSelectorQuery("OrgEntity"),
        queryVariables: () => ({ filter: {} }),
        label: "Org Entity",
        placeholder: "Org Entity filter (none)",
        visible: false,
      },
      {
        label: "User Role",
        model: "role",
        type: "inline-select",
        options: [
          { value: "org_member", label: "Org Member" },
          { value: "org_admin", label: "Org Admin" },
          { value: "org_entity_admin", label: "Org Entity Admin" },
          { value: "admin", label: "Admin" },
          { value: "cx_manager", label: "CX Manager" },
          { value: "batman", label: "Batman" },
          { value: "customer_support", label: "Customer Support" },
          { value: "admin", label: "Admin" },
          { value: "all_users", label: "All Users" },
        ],
      },
      {
        label: "GMC Ecards Filter",
        model: "ecard",
        type: "inline-select",
        options: [
          { value: "WITH_ECARDS", label: "With Ecards" },
          { value: "WITHOUT_ECARDS", label: "Without Ecards" },
          { value: "ALL_USERS", label: "All Users" },
        ],
      },
    ],
  },
};
