export const ClaimStatus = {
  DRAFT: "Draft",
  UPCOMING: "Upcoming",
  ONGOING: "On-going",
  DORMANT: "Dormant",
  COMPLETED: "Completed",
  REOPENED: "Re-opened",
  REJECTED: "Rejected",
  INVALID: "Invalid",
}
