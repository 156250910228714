import Dashboard from "./Dashboard.vue";
import StartClaim from "./StartClaim.vue";

export const Base = require("./Base.vue").default;

export const routes = [
  { path: "", component: Dashboard, name: "claims" },
  { path: "start-claim", component: StartClaim, name: "start-claim" },
  {
    path: "claim-details/:id",
    name: "claim-details",
    component: require("./ClaimDetails.vue").default,
  },
  {
    path: "claim-intimation-form",
    component: require("./ClaimForms.vue").default,
    name: "claim-intimation-form",
  },
  {
    path: "reimbursement-claim-form",
    component: require("./ClaimForms.vue").default,
    // component: ClaimForms,
    name: "reimbursement-form",
  },
  {
    path: "emergency-claim-form",
    component: require("./ClaimForms.vue").default,
    // component: ClaimForms,
    name: "emergency-claim-form",
  },
  {
    path: "opd-form",
    component: require("./ClaimForms.vue").default,
    name: "opd-form",
  },
  {
    path: "upload-documents/:id",
    name: "upload-documents",
    component: require("./UploadDocuments.vue").default,
  },
  {
    path: "generate-form-a/:id",
    name: "generate-form-a",
    component: require("./GenerateFormA.vue").default,
  },
];
