<template lang="pug">
.member-card
  .d-flex.flex-column.align-items-center.dependents-wrapper
    b-card.mt-3.w-100
      .d-flex.justify-content-between.align-items-center.w-100
        .d-flex.w-100.justify-content-between(:class="{'flex-wrap': true}")
          .d-flex.align-items-center.mr-3
            n-avatar.mb-1(
              :name="name",
              variant="success",
              :size="3")
            .d-flex.flex-column.align-items-baseline.ml-2.ml-md-3
              span.font-weight-semibold.text-gray-900.text-capitalize {{ name }}
              .d-flex.flex-row.flex-wrap.pt-1.text-gray-700.text-capitalize.font-weight-medium.font-sm
                span.text-nowrap {{ relation }}
                span.ml-1.text-nowrap • DOB
                span.ml-1.text-nowrap(v-if="dob")
                  | • {{ getFormattedDOB(dob) }}
          .d-flex.align-items-center.text-left.m-0(:class="{'border-top-dashed': isMobileBrowsing(), 'w-100': isMobileBrowsing()}")
            .pl-3.py-2(:class="{'vertical-separator': !isMobileBrowsing()}")
              .text-gray-700.font-sm Deductible Amount
              .font-md.text-gray-900.mt-2 {{ getAmountInLakhs(deductible) }}
            .pl-3
              .text-gray-700.font-sm Sum Insured
              .font-md.text-gray-900.mt-2 {{ getAmountInLakhs(si)  }}
            
      
</template>
<script>
import NovaIcon from "../../../../../components/NovaIcon.vue";
import NAvatar from "../../../../../components/Avatar.vue";
import moment from "moment";
import mixin from "../mixin";

export default {
  name: "MemberSummaryCard",
  mixins: [mixin],
  components: {
    NovaIcon,
    NAvatar,
  },
  data() {
    return {};
  },
  props: {
    name: {
      type: String,
      default: "",
    },
    relation: {
      type: String,
      default: "",
    },
    dob: {
      type: String,
      default: "",
    },
    deductible: {
      type: Number,
      default: 0,
    },
    si: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    getFormattedDOB(dob) {
      return moment(dob).format("Do MMMM YYYY");
    },
    getAmountInLakhs(amount) {
      return amount / 100000 + " Lakhs";
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
.vertical-separator {
  border-left: 1px solid $gray-400;
}
</style>
