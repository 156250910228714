import gql from "graphql-tag";
import { userChangeFragment } from "../fragments";

export default {
  updateUserOrDependentInfo: gql`
    mutation updateUserOrDependentInfo(
      $id: ID
      $orgId: ID
      $userId: ID!
      $changedUserInfo: JSONObject
      $changedDependentInfo: JSONObject
      $changedBenefitInfo: JSONObject
      $meta: JSONObject
      $dependentId: ID
      $status: String
      $type: String
    ) {
      updateUserOrDependentInfo(
        input: {
          id: $id
          orgId: $orgId
          meta: $meta
          status: $status
          type: $type
          userId: $userId
          dependentId: $dependentId
          changedUserInfo: $changedUserInfo
          changedDependentInfo: $changedDependentInfo
          changedBenefitInfo: $changedBenefitInfo
        }
      ) {
        userChange {
          ...UserChange
        }
      }
    }
    ${userChangeFragment}
  `,
  upsertMutation: gql`
    mutation createUserChange(
      $orgId: ID
      $userId: ID!
      $changedUserInfo: JSONObject
      $changedDependentInfo: JSONObject
      $changedBenefitInfo: JSONObject
      $meta: JSONObject
      $dependentId: ID
      $benefitId: ID!
      $status: String
      $type: String
    ) {
      createUserChange(
        input: {
          orgId: $orgId
          meta: $meta
          status: $status
          type: $type
          userId: $userId
          dependentId: $dependentId
          benefitId: $benefitId
          changedUserInfo: $changedUserInfo
          changedDependentInfo: $changedDependentInfo
          changedBenefitInfo: $changedBenefitInfo
        }
      ) {
        userChange {
          ...UserChange
        }
      }
    }
    ${userChangeFragment}
  `,
  updateUserChangeStatus: gql`
    mutation updateUserChangeStatus(
      $orgId: ID!
      $batchId: ID!
      $userChangeIds: [ID]
      $policyIds: [ID]
      $allBenefits: Boolean
      $currentStatus: String!
      $newStatus: String!
      $userChangeTypes: [String]
      $onboardingFilters: JSONObject
      $insurerWiseDeposit: JSONObject
    ) {
      updateUserChangeStatus(
        input: {
          orgId: $orgId
          batchId: $batchId
          userChangeIds: $userChangeIds
          policyIds: $policyIds
          allBenefits: $allBenefits
          currentStatus: $currentStatus
          newStatus: $newStatus
          userChangeTypes: $userChangeTypes
          onboardingFilters: $onboardingFilters
          insurerWiseDeposit: $insurerWiseDeposit
        }
      ) {
        success
        errorMessage
      }
    }
  `,
  addBenefitToUserAndDependentsMutation: gql`
    mutation addBenefitToUserAndDependentsMutation(
      $orgId: ID!
      $userId: ID!
      $dependents: Array
      $benefitId: ID!
      $meta: JSONObject
      $status: String
      $type: String
    ) {
      addBenefitToUserAndDependentsMutation(
        input: {
          orgId: $orgId
          userId: $userId
          dependents: $dependents
          benefitId: $benefitId
          meta: $meta
          status: $status
          type: $type
        }
      ) {
        userChange {
          ...UserChange
        }
      }
    }
    ${userChangeFragment}
  `,
  transferUserChangeBetweenBatches: gql`
    mutation transferUserChangeBetweenBatches($for: String!, $newBatchId: ID!, $userChangeIds: [ID], $batchId: ID) {
      transferUserChangeBetweenBatches(
        input: { for: $for, newBatchId: $newBatchId, userChangeIds: $userChangeIds, batchId: $batchId }
      ) {
        userChanges {
          ...UserChange
        }
      }
    }
    ${userChangeFragment}
  `,
  updateBatchPremiumData: gql`
    mutation updateBatchPremiumData($batchId: ID!, $premiumData: JSONObject!) {
      updateBatchPremiumData(input: { batchId: $batchId, premiumData: $premiumData }) {
        premiumData
      }
    }
  `,
  deleteEndorsementDocument: gql`
    mutation deleteEndorsementDocuments($batchId: ID!, $policyId: ID!, $docType: String!, $fileId: String!) {
      deleteEndorsementDocuments(
        input: { batchId: $batchId, policyId: $policyId, docType: $docType, fileId: $fileId }
      ) {
        success
      }
    }
  `,
  resyncPremiumForBatch: gql`
    mutation resyncPremiumForBatch($batchId: ID!) {
      resyncPremiumForBatch(input: { batchId: $batchId }) {
        success
      }
    }
  `,
  resyncBatchStatus: gql`
    mutation resyncBatchStatus($batchId: ID!) {
      resyncBatchStatus(input: { batchId: $batchId }) {
        success
      }
    }
  `,
  upsertEndorsementDocuments: gql`
    mutation upsertEndorsementDocuments(
      $batchId: ID!
      $endorsementDocuments: JSONObject!
      $orgAdmins: [JSONObject]!
      $cxEntities: [JSONObject]!
    ) {
      upsertEndorsementDocuments(
        input: {
          batchId: $batchId
          endorsementDocuments: $endorsementDocuments
          orgAdmins: $orgAdmins
          cxEntities: $cxEntities
        }
      ) {
        success
      }
    }
  `,
};
