<template lang="pug">
  div
    n-table.endorsement-table-container(:items="getTransformedItems()", :fields="fields", hover, @row-clicked="item =>handleSideSheetOpening(item)", tbody-tr-class="cursor-pointer")
      template(v-slot:email="{ data }")
        .d-flex.align-items-center.email-width-max.flex-nowrap
          span.font-sm.font-weight-medium.text-gray-800(
            :class="{'deleted-user-email': [$options.adminUserStatus.DELETED, $options.adminUserStatus.SUSPENDED].includes(data?.item?.status)}",
            :id="`email-${data.item.user_id}-${data.item.email}`") {{ data.item.email }}
          n-popover.w-100.p-0.border-gray-200(
            v-if="[$options.adminUserStatus.DELETED, $options.adminUserStatus.SUSPENDED].includes(data?.item?.status)",
            :id="`email-popup-${data.item.user_id}-${data.item.email}`",
            :target="`email-${data.item.user_id}-${data.item.email}`",
            triggers="hover",
            placement="right")
            template(v-slot:title)
              .d-flex.align-items-center
                n-icon.mr-1(
                  name="remove-user",
                  :pathCount="2",
                  variant="red-600")
                div Deleted user
            template(v-slot:default)
              span.font-sm.font-weight-semibold.text-gray-900 {{ data.item.email }}
              | &nbsp; has already been removed from Nova Benefits platform.
              br
              br
              span This entry seems to be wrong, please flag this to your relationship manager. Our associates will get it fixed for you.
          n-popover.w-100.p-0.border-gray-200(
            v-if="![$options.adminUserStatus.DELETED, $options.adminUserStatus.SUSPENDED].includes(data?.item?.status)",
            :id="`email-popup-${data.item.user_id}-${data.item.type}`",
            :target="`email-${data.item.user_id}-${data.item.email}`",
            triggers="hover",
            placement="top")
            template(v-slot:title)
              span.font-weight-lighter {{ data.item.email }}

      template(v-slot:doj="{ data }")
            span(:id="`employee-${data.item.user_id}-${data.item.type}`").font-sm.font-weight-medium.text-gray-800
              | {{getFormattedDate(data.item?.userMeta?.dateOfJoining)}}

      template(v-slot:name="{ data }")
        .d-flex.align-items-center.flex-nowrap.name-width-max
          n-avatar.text-blue-800.font-weight-semibold.mr-1.ml-n1(
            :name="isDependentRow(data) ? getDependentContext(data) + data.item.name : data.item.name",
            :variant="isDependentRow(data) ? 'warning' : 'light'",
            :size="2"
            )
          span.font-sm.font-weight-semibold.text-gray-900.text-nowrap.text-truncate {{ isDependentRow(data) ? data.item.dep_name : data.item.name }}

      template(v-slot:dependents="{ data }")
        template(
          v-if="getDependentCount(data.item)",
        )
          n-chip.border.additional-benefits(
            :isMinimal="true"
            :id="`dependent-${data.item.user_id}-${data.item.type}`") {{ getDependentCount(data.item) }}
            template(v-slot:icon)
              n-icon.mr-1(name="family", :size="0.8")
          n-popover.dependent-popup.w-100.p-0.border-gray-200(
            :id="`dependent-popup-${data.item.user_id}-${data.item.type}`",
            :target="`dependent-${data.item.user_id}-${data.item.type}`",
            triggers="hover",
            :placement="getDepPopoverPlacement",
            :custom-class="`dependent-popup dependent-popup-${getDepPopoverPlacement}`")
            template(v-slot:title)
              .px-3.pt-2
                .font-weight-medium Dependents Details
            template(v-for="change in getDependentChanges(data.item)")
              .w-100.p-3
                .d-flex.justify-content-between.align-items-center.w-100
                .d-flex.align-items-top
                  n-avatar.text-blue-800.font-weight-semibold(
                    :name="change.dep_name",
                    variant="warning",
                    :size="2"
                  )
                  .d-flex.flex-column.align-items-baseline.ml-2.ml-md-3
                    span.font-weight-semibold.text-gray-900.text-capitalize {{ change.dep_name }}
                    .d-flex.flex-row.flex-wrap.pt-1.text-gray-700.text-capitalize.font-weight-medium.font-sm
                      span.text-nowrap {{ change.relation }}
                    .d-flex.flex-row.flex-wrap.pt-1.text-gray-700.text-capitalize.font-weight-medium.font-sm(v-if="change.type")
                      coverage-pills-group-wrapper(
                        :item="change",
                      )
              hr
            n-button.btn-block.border-0.border-radius-inherit(
              type="button",
              buttonText="View Details",
              variant="outline-primary",
              size="xl",
              v-b-toggle="[`dependent-popup`]",
              @click="updateSidesheetData(data.item, 'dependents')",
            )

      template(v-slot:type="{ data }")
        .d-flex.align-items-center
          template(v-if="data.item?.type")
            coverage-pills-group-wrapper(
              :item = "data.item",
              triggers="hover",
              )
      template(v-slot:changeType="{ data }")
        template
          div(v-if="data.item.type === 'add'")
            n-chip(chipIcon="add-user", :variant="(isDependentRow(data) || isPlaceholderRow(data)) ? 'secondary2' : 'success'", pill) {{ getPillContent(data) }}
          div(v-else-if="data.item.type === 'delete'")
            n-chip(chipIcon="remove-user", :variant="(isDependentRow(data) || isPlaceholderRow(data)) ? 'secondary2' : 'danger'", pill) {{ getPillContent(data) }}
          div(v-else-if="data.item.type === 'update'")
            n-chip(chipIcon="rule", :variant="(isDependentRow(data) || isPlaceholderRow(data)) ? 'secondary2' : 'dark'", pill) {{ getPillContent(data) }}
      template(v-slot:chevron="{ data }")
          i.icon-chevron-right.text-gray-500.p-2.cursor-pointer(
          )
      template(v-slot:custom-foot="{ data }")
        tr
          td.text-center(:colspan="fields.length")
            div(v-if="items && items.length > 0")
              slot(name="pagination")
            div(v-else)
              slot(name="empty-state")
    employee-details-sidesheet(
      id="sidesheet",
      :employeeData="sidesheetData",
      :org-id="orgId",
  )
  </template>

<script>
import { flatten, groupBy, capitalize } from "lodash";
import CoveragePillsGroupWrapper from "../../components/CoveragePillsGroupWrapper.vue";
import { AcceptedGenders, AcceptedRelations } from "../../../../../common/enums";
import EmployeeDetailsSidesheet from "../../components/EmployeeDetailsSidesheet.vue";
import NTable from "@/components/NovaTable.vue";
import NPopover from "@/components/NovaPopover.vue";
import NAvatar from "@/components/Avatar.vue";
import NChip from "@/components/NovaChip.vue";
import NButton from "@/components/NovaButton.vue";
import utils from "@/utils";

export default {
  name: "UserChangesListTable",
  components: {
    NTable,
    NAvatar,
    NPopover,
    CoveragePillsGroupWrapper,
    NChip,
    NButton,
    EmployeeDetailsSidesheet,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
      required: true,
    },
  },
  data() {
    return {
      orgId: this.$store.state.user.org.id,
      changesMap: {},
      subChangesMap: {},
      changesData: [],
      sidesheetData: {
        user: {},
        dependents: [],
      },
      fields: [
        {
          key: "name",
          label: "Name",
          tdClass: this.dependentRowStyling(),
        },
        {
          key: "email",
          label: "Employee Email",
          tdClass: this.dependentRowStyling(),
        },
        {
          key: "doj",
          label: "Date of joining",
          tdClass: this.dependentRowStyling(),
        },
        {
          key: "dependents",
          label: "Dependents",
          tdClass: this.dependentRowStyling(),
        },
        {
          key: "type",
          label: "Endorsement Type",
          tdClass: this.dependentRowStyling(),
        },
        {
          key: "changeType",
          label: "Endorsement",
          tdClass: this.dependentRowStyling(),
        },
        {
          key: "date",
          label: "",
          tdClass: this.dependentRowStyling(),
        },
        {
          key: "chevron",
          label: "",
          tdClass: this.dependentRowStyling(),
        },
      ],
    };
  },
  computed: {
    getGroupedChanges() {
      return groupBy(this.items, (change) => {
        return change.user_id + "," + change.type;
      });
    },
    getDepPopoverPlacement() {
      return this.isSidesheetOpen() ? "left" : "right";
    },
  },
  watch: {
    items: {
      handler: function () {
        this.changesMap = {};
        this.subChangesMap = {};
        this.organiseChangesToLevels();
      },
      deep: true,
    },
  },
  created() {
    this.$options.adminUserStatus = utils.adminUserStatus;
  },
  methods: {
    handleSideSheetOpening(item) {
      const userType = item.user_type === AcceptedRelations.SELF ? "user" : "dependents";
      this.updateSidesheetData(item, userType);
    },
    isSidesheetOpen() {
      return Object.keys(this.sidesheetData.user).length;
    },
    updateSidesheetData(data, activeTab = "user") {
      window.posthog.capture("employee_detail_click", {
        org_name: this.user?.org?.name,
        email: this.user?.email,
      });
      const userId = data.user_id;
      // reset the data
      this.resetSidesheetData();
      if ([AcceptedRelations.SELF, "placeholder"].includes(data.user_type) && data.user_changes) {
        this.sidesheetData.user = data;
      } else {
        this.sidesheetData.user = this.getUserData(data);
      }
      this.items.forEach((item, index) => {
        if (item.user_id === userId && item.type === data.type) {
          if ([AcceptedRelations.SELF, "placeholder"].includes(item.user_type)) {
            if (!this.sidesheetData.user?.user_changes) this.sidesheetData.user = item;
            else {
              // compile all details of each benefit for the user for each row
              this.sidesheetData.user.user_changes = this.sidesheetData.user.user_changes.map((existingMeta) => {
                return {
                  ...existingMeta,
                  benefit_obj: {
                    ...existingMeta.benefit_obj,
                    ...(item.user_changes.find((itemMeta) => itemMeta.benefit_id === existingMeta.benefit_id)
                      ?.benefit_obj || {}),
                  },
                };
              });
            }
          } else if (item.user_type === "dependent") this.sidesheetData.dependents.push(item);
        }
      });
      this.$root.$emit("bv::toggle::collapse", "employee-details-sidesheet", { tab: activeTab });
      setTimeout(() => {
        this.$root.$emit("bv::hide::popover", `dependent-popup-${data.user_id}-${data.type}`);
      }, 100);
    },
    resetSidesheetData() {
      this.sidesheetData.user = {};
      this.sidesheetData.dependents = [];
    },
    getFormattedDate(date, format = "DD/MM/YYYY") {
      return date ? utils.getFormattedDate(date, format) : "NA";
    },
    getDependentCount(item) {
      return this.subChangesMap[this.getKeyByValue(this.changesMap, item)]?.length;
    },
    getKeyByValue(object, value) {
      return Object.keys(object).find((key) => object[key].includes(value));
    },
    getUserData(data) {
      return {
        user_id: data?.user_id,
        user_type: "placeholder",
        name: data?.name,
        email: data?.email,
        gender: data?.gender,
        dob: data?.dob,
        userMeta: data?.userMeta,
        user_changes: data?.user_changes,
        type: data?.type,
        selected: data?.selected,
      };
    },
    isDependentRow(data) {
      return utils.isDependentRow(data);
    },

    getDependentContext(data) {
      return capitalize(this.getDependentsSubject(data) + " of ");
    },
    isPlaceholderRow(data) {
      return data.item.user_type === "placeholder";
    },
    getDependentsSubject(data) {
      if (data.item.user_type === "placeholder") return "Dependents";
      switch (data.item.relation) {
        case AcceptedRelations.CHILD: {
          return data.item.dep_gender === AcceptedGenders.MALE ? "Son" : "Daughter";
        }
        case AcceptedRelations.PARENT: {
          return data.item.dep_gender === AcceptedGenders.MALE ? "Father" : "Mother";
        }
        case AcceptedRelations.PARENT_IN_LAW: {
          return data.item.dep_gender === AcceptedGenders.MALE ? "Father In Law" : "Mother In Law";
        }
      }
      return data.item.relation;
    },
    getPillContent(data) {
      switch (data.item.type) {
        case "add":
          return this.isDependentRow(data) || this.isPlaceholderRow(data) ? "Dependent Addition" : "Addition";
        case "delete":
          return this.isDependentRow(data) || this.isPlaceholderRow(data) ? "Dependent Deletion" : "Deletion";
        case "update":
          return this.isDependentRow(data) || this.isPlaceholderRow(data) ? "Dependent Update" : "Data Changes";
      }
    },
    getDependentChanges(item) {
      return this.subChangesMap[this.getKeyByValue(this.changesMap, item)];
    },
    organiseChangesToLevels() {
      Object.entries(this.getGroupedChanges).forEach((group) => {
        const [key, value] = group;
        if (value.some((val) => val.user_type === AcceptedRelations.SELF)) {
          this.changesMap[key] = value.filter((val) => val.user_type === AcceptedRelations.SELF);
          this.subChangesMap[key] = value.filter((val) => val.user_type === "dependent");
        } else if (value.length > 1) {
          const userData = this.getUserData(value[0]);
          userData.meta = this.getCommonBenefits(value);
          this.changesMap[key] = [userData];
          this.subChangesMap[key] = value;
        } else {
          this.changesMap[key] = value;
        }
      });
    },
    getCommonBenefits(arr) {
      const commonMeta = [];
      const countMap = new Map();
      arr[0].user_changes.forEach((change) => {
        const commonChange = { benefit_id: change.benefit_id };
        const hasMetaForAll = arr.slice(1).every((dep) => {
          const depChange = dep.user_changes.find((uc) => uc.benefit_id === change.benefit_id);
          if (!depChange) return false;
          Object.keys(change).forEach((key) => {
            if (key !== "benefit_id") {
              if (countMap.has(key)) {
                countMap.set(key, countMap.get(key) + 1);
              } else {
                countMap.set(key, 1);
              }
              if (countMap.get(key) === arr.length - 1) {
                commonChange[key] = change[key];
              }
            }
          });
          return true;
        });
        if (hasMetaForAll) {
          commonMeta.push(commonChange);
        }
        countMap.clear();
      });
      return commonMeta;
    },
    dependentRowStyling() {
      return (value, key, item) => {
        const data = { item };
        const styles = ["align-middle"];
        const depStyles = ["bg-alabaster"];
        if (key === "selected") styles.push("checkbox-width1");
        if (utils.isDependentRow(data)) styles.push(...depStyles);
        return styles;
      };
    },
    getTransformedItems() {
      return flatten(Object.values(this.changesMap));
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/_variables.scss";
@import "@/assets/styles/_mixins.scss";
.endorsement-table-container {
  .nova-table-wrapper {
    border-top: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}
</style>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
@import "@/assets/styles/_mixins.scss";

.endors-dep-checkbox {
  .custom-control-input:checked ~ .custom-control-label::before,
  .custom-control-input:indeterminate ~ .custom-control-label::before {
    color: #fff;
    border-color: $blackpearl;
    background-color: $blackpearl;
  }
  & > input.custom-control-input {
    min-height: 0 !important;
  }
}

.deleted-user-email {
  color: $red-600;
}
</style>
