<template lang="pug">
b-modal(
  id="booking-request-modal",
  size="md",
  centered,
  hide-header,
  hide-footer,
  @hide="handleCloseBookingRequestModal",
  modal-class="booking-request-modal",
  content-class="border-none rounded-lg",
  body-class="p-0")
  .modal-info.p-3.text-center
    img.mt-5(:src="require('@/assets/images/nova-flag.svg')", width="128")
    h3.hb3.text-tiber.px-3.pt-3.pb-0.font-weight-semibold.font-xl
      span
        | Booking Request
        br
        | Received!&nbsp;
    b-card.mx-3.my-4.py-1.rounded-2.bg-malibu-100.shadow-sm(body-class="py-2")
      .d-flex.align-items-start
        img.mx-2(:src="require('@/assets/images/airplane.svg')", height="40")
        div.text-left
          .font-md.font-weight-medium.my-0.text-gray-800
            | We'll reach out to you as soon as the provider has confirmed your booking
    .w-100.d-flex.align-items-center.justify-content-center.mt-2.mb-4
      img.mr-2(:src="require('@/assets/images/logo-main.svg')", height="20")
</template>

<script>
import NButton from "@/components/NovaButton.vue";
import NModal from "@/components/NovaModal.vue";

export default {
  name: "BookingConfirmationModal",
  components: {
    NButton,
    NModal,
  },
  computed: {
    benefitId() {
      return this.$route.params.benefitId;
    },
  },
  methods: {
    handleCloseBookingRequestModal() {
      this.$bvModal.hide("booking-request-modal");
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/_variables.scss";
@import "@/assets/styles/mixins/_breakpoints.scss";

.booking-request-modal {
  @include media-breakpoint-down(sm) {
    .modal-dialog {
      margin: 0;
    }
    .modal-content {
      border-radius: 0 !important;
      height: 100vh;
      width: 100vw;
    }
    .modal-body {
      display: flex;
      align-items: center;
    }
  }
}
</style>
