<template lang="pug">
  pills-group(
    v-if="item.type",
    :itemId="item.id",
    :popoversData="popoversData",
    :normalItems="filterBenefitsByType(item.user_changes || item.meta).specificPolicies",
    :plusItems="filterBenefitsByType(item.user_changes || item.meta).otherPoliciesAndBenefits",
    :triggers="triggers",
    :changesType="item.type",
    plusItemChipText="More",
    :statusText="statusText",
    :statusChipVariant="statusChipVariant")
  </template>

<script>
import resDefs from "../../orgAdmin/definitions";
import { PolicyTypes } from "../../../../common/enums";
import PillsGroup from "./PillsGroup.vue";
import PlusNChip from "./PlusNChip";
import NButton from "@/components/NovaButton.vue";
import NChip from "@/components/NovaChip.vue";

export default {
  name: "CoveragePillsGroupWrapper",
  components: {
    NButton,
    PlusNChip,
    NChip,
    PillsGroup,
  },
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    triggers: {
      type: String,
      default: "",
    },
    statusChipVariant: {
      type: String,
    },
    statusText: {
      type: String,
    },
    showDependentPopovers: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      popoversData: this.showDependentPopovers ? resDefs.userChanges.dependentPopovers : resDefs.userChanges.popovers,
    };
  },
  methods: {
    filterBenefitsByType(benefitsArray = []) {
      const benefitsAndPolicies = {
        specificPolicies: [],
        otherPoliciesAndBenefits: [],
      };
      benefitsArray.forEach((benefit) => {
        if (benefit.isPolicy && [PolicyTypes.GMC, PolicyTypes.GPA, PolicyTypes.GTL].includes(benefit.benefitsType)) {
          benefitsAndPolicies.specificPolicies.push(benefit);
        } else {
          benefitsAndPolicies.otherPoliciesAndBenefits.push(benefit);
        }
      });
      return benefitsAndPolicies;
    },
  },
};
</script>

<style lang="scss" scoped></style>
