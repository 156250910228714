<template lang="pug">
n-modal(
  v-bind="{...$props, ...$attrs}",
  centered,
  isScrollable=true,
  no-close-on-backdrop,
  :modal-class="modalId",
  id=modalId)
  template(v-slot:modal-header)
    .hb5.text-gray-900.my-1 {{ stepItems[currentStep-1]?.header }}
  template(v-slot:default)
    slot(name="default")
      template(v-if="isCdTransactionsModal")
        .d-flex.flex-column.px-4.mt-2.mb-2.w-100
          .d-flex.w-100.align-items-center
            .d-flex.rounded-circle.bg-teal-700.justify-content-center.align-items-center.p-2.mr-2
                n-icon(name="money", variant="light", :size="1.2")
            .d-flex.flex-column.justify-content-around.w-100
                .hb6.text-nowrap.text-gray-700 Step {{ currentStep + '/'+ stepItems?.length }}
                .hb5.font-weight-bold.text-nowrap {{ stepItems[currentStep -1]?.subHeading }}
          .progress.progress-bar-container
            .progress-bar.pb(
              role="'progressbar'",
              :aria-valuenow="currentStep",
              aria-valuemin="0",
              :aria-valuemax="totalSteps",
              :style="'width: '+(100/totalSteps)*currentStep+'%;'")
          .d-flex.w-100(v-if="currentStep < 2")
            b-form.w-100
              vue-form-generator.w-100(
                :schema="stepItems[currentStep - 1]?.schema",
                :options="$options.formOptions",
                :ref="formRef",
                :model="model",
                @update:model="updateModel"
              )
          .d-flex.flex-column.w-100(v-else)
            .hb6.text-gray-800.text-nowrap.mb-2 Please attach a payment receipt to confirm your transaction.
            .custom-file-container.bg-blue-100.d-flex.flex-column.justify-content-center.align-items-center.py-3( @drop.prevent="onDrop", 
                @dragover="dragover", 
                @dragleave="dragleave")
              template(v-if="!FILE") 
                .d-flex.flex-column.align-items-center.text-blue-900.mt-4
                  .hb3 Drop your file 
                  .hb3 here to upload 
                label.block.cursor-pointer.mt-3(for='assetsFieldHandle')
                  .d-flex.bg-blue-500.justify-content-around.px-3.py-2.rounded.text-nowrap.text-white
                    n-icon(name="attachment", variant="light", :size="1.2")
                    span Select File      
                input#assetsFieldHandle.transaction-box-input(type="file", ref="fileInput", accept=".pdf, .jpg, .jpeg, .png", @change="onFileUpload")
              div.w-100.px-3(v-else)
                .d-flex.flex-column.py-2
                  .d-flex.py-2.border-bottom.border-gray-600.w-100
                    .hb5.font-weight-bold 1 File Uploaded
                  .d-flex.justify-content-between.align-items-center.w-100.py-4.h-100
                    .d-flex.align-items-center.h-100.w-100
                      img.img-fluid.mr-3.ml-2(v-if="getFileType() === 'pdf'", :src="require('@/assets/images/placeholder-pdf.svg')", :style="{height: '40px', width:'40px', maxHeight: '40px'}")
                      img.img-fluid.mr-3.ml-2(v-else, :src="require('@/assets/images/placeholder-image.svg')", :style="{height: '40px', width:'40px', maxHeight: '40px'}")
                      span.hb5.font-weight-bold.w-75.file-name-wrap {{ FILE.name }}
                    span.cursor-pointer(@click="remove") X
            
      template(v-else)
        div
          .h4 {{ stepItems[currentStep-1]?.title }}
          .text-gray-700.font-weight-medium.font-sm {{ stepItems[currentStep-1]?.subTitle }}
        br
        .text-gray-700.font-weight-medium.font-sm(v-if="stepItems[currentStep-1]?.noteText?.length")
          span Important Note(s)
          p.text-gray-700.font-weight-medium.font-sm.py-0.my-0(v-for="note in stepItems[currentStep - 1]?.noteText", :key="note")
            i.icon-disabled-stage.font-xs
            span {{ note }}
        .my-4
          div(v-for="(item, index) in stepItems[currentStep-1]?.accordionData")
            .rounded-top(
              :class="item.style",
              v-b-toggle="item.id"
            )
              .py-4
                .d-flex.flex-row.justify-content-between
                  .d-flex.font-weight-medium
                    b-form-checkbox.ml-3.main-category-checkbox(
                      v-if="isTogglable",
                      v-model="allSelected[index]",
                      :id="item.id",
                      :indeterminate.sync="indeterminate[index]",
                      :disabled="item.isDisabled"
                      @item="toggleAll($event, index)",
                      :data-cy="item.dataCy",
                    )
                    img.align-sub.ml-3.mr-2(v-if="item.imageSrc", style="margin-top:-5px;", height="25", width="25", :src="item.imageSrc")
                    i.ml-3.mr-2.align-sub(v-if="item.icon", :class="[`icon-${item.icon}`]")
                    | {{ item.name }}
                  .d-flex.text-gray-700.font-weight-medium
                    div {{ item.countString }}
                    span.mx-3
                      n-icon(v-if="item?.formItems?.length", :name="isVisible[index] ? 'chevron-up' : 'chevron-down'")
            b-collapse(
              v-if="item?.formItems?.length",
              :id="item.id",
              :accordion="item.id",
              role="tabpanel"
              :class="[item.style, 'pb-3', { 'collapse-visible': isVisible[index] }]",
              v-model="isVisible[index]",
            )
              template(v-slot:default)
                slot(name="default")
                  div.mx-3(v-for="(item, index) in item?.formItems")
                    .pt-2.pb-3
                      .hb5.text-gray-900.mb-2 {{ item.header }}
                      b-form(v-if="item.schema")
                        vue-form-generator(
                          :schema="item.schema",
                          :options="$options.formOptions",
                          :ref="formRef",
                          :model="model",
                          @update:model="updateModel")
                      .d-flex.flex-row.justify-content-between.my-2(v-if="item.summaryContent", v-for="summaryObj in item.summaryContent")
                        .d-flex.font-weight-medium
                          span
                            i.align-sub.mr-1(:class="[...(summaryObj.iconClasses || []), `icon-${summaryObj.icon}`]")
                            | {{ summaryObj.text }}
                        .d-flex.font-weight-medium.mr-1
                          span {{ summaryObj.addSign ? summaryObj.sign === "positive" ? "+" : "-" : "" }}
                          span {{ summaryObj.amount }}
                  
              
            hr.my-2(v-if="index < stepItems[currentStep-1]?.accordionData.length-1").border-gray-400       
  template(v-slot:modal-footer)
    step-counter.mr-auto(v-if="totalSteps > 1", :totalSteps="totalSteps", :currentStep="currentStep-1")
    n-button.col-md-4(variant="light", :buttonText="getButtonTextStepModal('cancel')", @click="handleStepCancel")
    n-button.col-md-4(
      variant="dark",
      :buttonText="getButtonTextStepModal('submit')",
      :disabled="isContinueCTADisabled",
      @click="handleStepContinue",
      data-cy="endorsements-step-modal-continue-button")
</template>

<script>
import NModal from "../../../../components/NovaModal.vue";
import NovaIcon from "../../../../components/NovaIcon.vue";
import StepCounter from "@/components/StepCounter.vue";
import NButton from "@/components/NovaButton.vue";

export default {
  name: "StepModal",
  components: {
    NButton,
    NModal,
    StepCounter,
    NovaIcon,
  },
  props: {
    stepItems: {
      type: Array,
      default: () => [],
      validator: function (value) {
        return value.length >= 1;
      },
    },
    model: {
      type: Object,
      default: () => {},
    },
    id: {
      type: String,
      default: "",
    },
    isCdTransactionsModal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      currentStep: 1,
      prevStep: null,
      isVisible: [true],
      FILE: null,
    };
  },
  computed: {
    modalId() {
      return this.id ? this.id : "step-modal";
    },
    isTogglable() {
      return this.stepItems[this.currentStep - 1].isTogglable;
    },
    allSelected() {
      return this.stepItems[this.currentStep - 1].allSelected;
    },
    indeterminate() {
      return this.stepItems[this.currentStep - 1].indeterminate;
    },
    isContinueCTADisabled() {
      if (this.isCdTransactionsModal && this.currentStep === 2 && !this.FILE) {
        return true;
      }
      return this.stepItems[this.currentStep - 1].isContinueCTADisabled;
    },
    formRef() {
      return this.stepItems[this.currentStep - 1].ref;
    },
    totalSteps() {
      return this.stepItems.length;
    },
    getStepModalHeading() {
      return this.stepItems[this.currentStep - 1]?.header;
    },
  },
  watch: {
    currentStep() {
      this.isVisible = [true];
      this.$emit("step-changed", this.currentStep);
    },
  },
  created() {
    this.$options.formOptions = {
      validateAfterLoad: false,
      validateAfterChanged: true,
      validateAsync: true,
    };
  },
  methods: {
    getFileType() {
      let type = null;
      const fileType = this.FILE.type || this.FILE.meta?.type;
      type = fileType.split("/")[0];
      if (type === "application") {
        type = fileType.split("/")[1] === "pdf" ? "pdf" : "zip";
      }
      return type;
    },
    onDrop(event) {
      event.preventDefault();
      const file = event.dataTransfer.files[0];
      const acceptedFileTypes = [".pdf", ".jpg", ".jpeg", ".png"];
      const fileType = file.name.split(".").pop();

      if (file && acceptedFileTypes.includes(`.${fileType}`)) {
        this.FILE = file;
      }

      event.currentTarget.classList.add("bg-blue-100");
      event.currentTarget.classList.remove("bg-blue-200");
    },
    onFileUpload() {
      this.FILE = this.$refs.fileInput.files[0] || null;
    },
    remove() {
      this.FILE = null;
      if (this.$refs.fileInput) {
        this.$refs.fileInput.value = "";
      }
    },
    dragover(event) {
      event.preventDefault();
      if (!event.currentTarget.classList.contains("bg-blue-200")) {
        event.currentTarget.classList.remove("bg-blue-100");
        event.currentTarget.classList.add("bg-blue-200");
      }
    },
    dragleave(event) {
      // Clean up
      event.currentTarget.classList.add("bg-blue-100");
      event.currentTarget.classList.remove("bg-blue-200");
    },

    updateModel(model) {
      this.$emit("update:model", model);
    },
    getCurrentStepSchema() {
      return this.schemaItems[this.currentStep];
    },
    getButtonTextStepModal(type) {
      switch (type) {
        case "cancel": {
          return this.currentStep === 1 ? "Cancel" : "<- Go Back";
        }
        case "submit": {
          return this.currentStep === this.totalSteps
            ? this.stepItems[this.currentStep - 1].submitBtnText || "Submit"
            : "Continue ->";
        }
      }
    },
    handleStepCancel() {
      if (this.currentStep === 1) {
        if (this.isCdTransactionsModal) {
          this.model = {};
          this.FILE = null;
        }
        this.$bvModal.hide(this.modalId);
      } else {
        this.prevStep = this.currentStep;
        this.currentStep--;
      }
    },
    async handleStepContinue() {
      let returnFlag = false;
      if (this.formRef) {
        const formComponents = this.$refs[this.formRef];
        if (Array.isArray(formComponents)) {
          for (const form of formComponents) {
            await form.validate();
            if (form.errors && form.errors.length) {
              returnFlag = true;
              this.$store.commit("addToast", {
                variant: "danger",
                message: form.errors[0].error,
              });
            }
          }
        } else if (this.isCdTransactionsModal) {
          await formComponents.validate();
          if (formComponents.errors && formComponents.errors.length) {
            returnFlag = true;
            this.$store.commit("addToast", {
              variant: "danger",
              message: formComponents.errors[0].error,
            });
          }
        }
      }
      if (returnFlag) return;
      if (this.currentStep === this.totalSteps) {
        if (this.isCdTransactionsModal) {
          const file = this.FILE;
          const cdTransactionData = { ...this.model, file };
          this.$emit("submit", cdTransactionData);
          setTimeout(() => {
            this.FILE = null;
            this.currentStep = 1;
          }, 2000);
        } else {
          this.currentStep = 1;
          this.$emit("submit");
          this.$bvModal.hide(this.modalId);
        }
      } else {
        this.prevStep = this.currentStep;
        this.currentStep++;
      }
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/_variables.scss";
.main-category-checkbox {
  .custom-control-input:checked ~ .custom-control-label::before,
  .custom-control-input:indeterminate ~ .custom-control-label::before {
    color: #fff;
    border-color: $blackpearl;
    background-color: $blackpearl;
  }
}
</style>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
@import "@/assets/styles/_typography.scss";
.info-container {
  border: 1px solid $red-500;
}
.icon-container {
  width: max-content;
  border-radius: 50%;
}
.rotated-90 {
  transform: rotate(90deg);
  display: inline-block;
}
.rotated-anti-90 {
  transform: rotate(-90deg);
  display: inline-block;
}

.collapse-visible {
  display: block !important;
}
.custom-file-container {
  width: 100%;
  border-radius: 0.5rem;
  border: 1px dashed rgba(184, 213, 255, 1);
}
.file-input {
  max-width: 13rem;
}
.transaction-box-input {
  visibility: hidden;
}
.progress-bar-container {
  width: 100%;
  height: 5px;
  margin: 20px 0 20px 0;
  .pb {
    background-color: $teal-700;
    border-radius: 32px;
  }
}
.file-name-wrap {
  overflow: auto;
  white-space: wrap;
  word-break: break-all;
}
</style>
