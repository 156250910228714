<template lang="pug">
div(v-if="org")
  n-breadcrumb(
    v-bind="breadcrumbData",
    :levels="batchBreadcrumbData",
  )

  b-card.shadow-none.mb-3(
    v-if="batch",
    header-bg-variant="transparent",
    bg-variant="transparent",
    body-bg-variant="stone",
    body-class="shadow-sm",
  )
    .d-flex.justify-content-between.dashboard-header.py-1
      .d-flex.align-items-center
        .pb-2
          .h3.mt-3.mb-0.text-gray-900 {{ `${monthOnCalendar} ${yearOfBatch} Batch` }}
          .text-gray-800.font-sm.mt-1.font-weight-medium.dash-subtitle PREMIUM PAYMENT PENDING

      .d-flex.align-items-center(v-if="batch")
        .pt-2.px-3
          .text-gray-700.font-weight-semibold.font-sm No. of Employees
          .h3.font-xl.text-gray-900.mt-2 {{ batch.users }}
        .pt-2.px-3
          .text-gray-700.font-weight-semibold.font-sm No. of Dependents
          .h3.font-xl.text-gray-900.mt-2 {{ batch.dependents }}
        .pt-2.px-3
          .text-gray-700.font-weight-semibold.font-sm Total {{ displayActualPremium ? "" : "Estimated" }} Premium
          .d-flex.align-items-center.width-max-content.h3.font-xl.text-gray-900.mt-2
            span {{ getUpdatedPremiumAmount() }}
            img.ml-2(v-if="isRefundCase()", :src="require(`@/assets/images/refund.svg`)")

      .d-flex.align-items-center
        .vertical-separator.py-1
          .p-2.pl-5
            .text-gray-700.font-weight-semibold.font-sm For detailed employee information
            .d-flex.width-max-content(v-b-tooltip.hover.bottom="noInfoFileTooltipText" v-show="false")
              n-button.mt-2(
                buttonText="Download XLS",
                rightImageIcon="download",
                variant="dark",
                size="xs",
                @click="downloadInfoFile",
              )

  .d-flex
    i.icon-error.align-sub.text-mustard-600.mr-1.font-lg
    p.font-md
      template(v-if="isPolicyRaterEnabled")
        span.text-gray-800 These figures are calculated&nbsp;
        span.text-gray-800 based on the rater provided by the insurer
        template(v-if="getBenefitsWithRater.length")
          span.text-gray-800 , to know more about the rater&nbsp;
          a.font-md.link.text-decoration-underline(@click="openRaterModal") click here
      template(v-else)
        span.text-gray-800 The figures shown below are&nbsp;
        span.text-gray-800.font-weight-semibold {{ displayActualPremium ? "actual" : "estimated" }} &nbsp;
        span.text-gray-800 {{ displayActualPremium ? "and based on the documents received from the Insurer" : "and not final. Actual amount will be deducted from the CD balance as per calculated by the insurers." }}

  template(v-if="policiesGroupedByInsurer" v-for="(groupObj, id) in policiesGroupedByInsurer")
    premium-breakup(
      :insurerName= "groupObj.insurer.name",
      :insurerLogo= "groupObj.insurer.s3Url",
      :currentCDBalance= "groupObj.insurer.cdBalance",
      :minBalance= "groupObj.insurer.minCDBalance",
      :policies= "groupObj.policies",
      :premiumAddition= "getTotal(groupObj.policies, 'premiumAddition')",
      :premiumDeduction= "getTotal(groupObj.policies, 'premiumDeduction')"
      :isPaid= "isPaid",
      :isEndorsementPremiumPresent="isEndorsementPremiumPresent",
      :displayActualPremium="displayActualPremium",
      :isBatchCompleted="isBatchCompleted",
    )
  n-modal(
    v-if="isPolicyRaterEnabled && getBenefitsWithRater.length",
    id="endorsement-policy-rater-modal",
    centered,
    title="Policy Rater Card",
    size="lg",
    :hide-footer="false"
  )
    template(v-slot:modal-header, v-if="org.benefits.length")
      span Policy Rater Card
    .tabs-container.justify-content-start.align-items-center.border-bottom.pl-2.mb-3
      .tab-heading.d-inline-block.cursor-pointer.font-weight-semibold.text-center.text-gray-700.pb-3.mr-2(
        v-for="(benefit, index) in getBenefitsWithRater",
        :key="benefit.id",
        @click="switchRaterModalTabs(benefit.id)",
        :class="{'active-tab': selectedRaterPolicyId === benefit.id}") {{policyTypes[benefit.type].displayTitle}}
    n-table.rater-table-container.w-auto(:fields="getRaterTableFields", :items="getRaterTableItems", hover, ref="raterTable")
      template(v-slot:from="{data}")
        span {{ data.item.from }} years
      template(v-slot:to="{data}")
        span {{ data.item.to }} years
      template(v-slot:si="{data}")
        span {{ toINR(data.item.si) }}
      template(v-slot:amount="{data}")
        span {{ toINR(data.item.amount) }}
      template(v-slot:premium="{data}")
        span {{ toINR(data.item.premium) }}
      template(v-slot:rateCoefficient="{data}")
        span(v-if="data.item.formulaText", :class="data.item.style") {{ data.item.formulaText }}
        span(v-else) {{ data.item.rateCoefficient }}
    template(v-slot:modal-footer)
      .d-flex.justify-content-end
        n-button.font-sm(
          variant="primary",
          buttonText="Close",
          @click="closeRaterModal",)
</template>

<script>
import moment from "moment";
import NButton from "../../../components/NovaButton.vue";
import userChangeBatchesDef from "../admin/definitions/userChangeBatches";
import orgDef from "../admin/definitions/orgs";
import cliToolsDef from "../admin/definitions/cliTools";
import { getSingleQuery } from "../admin/queries";
import { policyTypes } from "../admin/constants";
import PremiumBreakup from "./components/PremiumBreakup.vue";
import NBreadcrumb from "@/components/NovaBreadcrumb.vue";
import NModal from "@/components/NovaModal.vue";
import NTable from "@/components/NovaTable.vue";
import utils from "@/utils";
import { PolicyRaterType, EndorsementBatchStatus } from "@/common/enums";

export default {
  components: {
    NButton,
    PremiumBreakup,
    NBreadcrumb,
    NModal,
    NTable,
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prevUrl = from.path;
    });
  },
  data() {
    const raterTableRowStyling = ["align-middle"];
    return {
      batchId: this.$route.params.batchId,
      orgId: this.$route.params.orgId ? this.$route.params.orgId : this.$store.state.user.org.id,
      selectedRaterPolicyId: null,
      toINR: utils.toINR,
      prevUrl: null,
      policyTypes,
      raterModalFields: {
        [PolicyRaterType.AGE_RANGE]: [
          {
            key: "from",
            label: "Start Age",
            tdClass: raterTableRowStyling,
          },
          {
            key: "to",
            label: "End Age",
            tdClass: raterTableRowStyling,
          },
          {
            key: "si",
            label: "Sum Insured",
            tdClass: raterTableRowStyling,
          },
          {
            key: "premium",
            label: "Annual Premium",
            tdClass: raterTableRowStyling,
          },
        ],
        [PolicyRaterType.FAMILY_FLOATER]: [
          {
            key: "si",
            label: "Sum Insured",
            tdClass: raterTableRowStyling,
          },
          {
            key: "premium",
            label: "Annual Premium Per Family",
            tdClass: raterTableRowStyling,
          },
        ],
        [PolicyRaterType.PER_MILLE]: [
          {
            key: "rateCoefficient",
            label: "Rate Coefficient",
            tdClass: raterTableRowStyling,
          },
        ],
      },
    };
  },
  computed: {
    displayActualPremium() {
      return this.isBatchCompleted || this.isEndorsementPremiumPresent;
    },
    isBatchCompleted() {
      return this.batch?.status === EndorsementBatchStatus.COMPLETED;
    },
    breadcrumbData() {
      return {
        rootName: "Endorsements",
        rootUrl: this.getRootUrl(),
        rootIcon: "stroke-endorsements-3",
        currentName: this.breadcrumbName,
      };
    },
    breadcrumbName() {
      return this.displayActualPremium ? "Premium Breakup" : "Estimated Premium";
    },
    isEndorsementPremiumPresent() {
      return !!(this.batch?.endorsementPremium && Object.keys(this.batch?.endorsementPremium).length > 0);
    },
    endingDate() {
      return this.batch?.endingAt || utils.getLastDateOfMonth(new Date());
    },
    monthOnCalendar() {
      return utils.getMonthShortForm(utils.getMonth(this.endingDate).toLowerCase());
    },
    dateOnCalendar() {
      return utils.getDate(this.endingDate);
    },
    yearOfBatch() {
      return moment(this.endingDate).year();
    },
    batchBreadcrumbData() {
      return [
        {
          url: this.getBatchUrl(),
          name: `${this.monthOnCalendar} ${this.yearOfBatch} Batch`,
        },
      ];
    },
    isPaid() {
      return this.batch?.meta?.premiumData?.isPaid;
    },
    getBenefitsWithRater() {
      return (
        this.org?.benefits
          ?.filter((benefit) => Object.values(PolicyRaterType).includes(benefit.node?.chargesConfig?.rater?.type))
          ?.map((benefit) => benefit.node) || []
      );
    },
    getRaterTableFields() {
      const raterType = this.getBenefitsWithRater.find((benefit) => benefit.id === this.selectedRaterPolicyId)
        ?.chargesConfig?.rater?.type;
      return this.raterModalFields[raterType];
    },
    getRaterTableItems() {
      const chargesConfig = this.getBenefitsWithRater.find(
        (benefit) => benefit.id === this.selectedRaterPolicyId
      )?.chargesConfig;

      if (chargesConfig.rater.type === PolicyRaterType.PER_MILLE) {
        const PER_MILLE_FORMULA = "Annual Premium =  ( SI / 1000 ) * Rate per mille";
        return [{ ...chargesConfig?.rater?.config }, { formulaText: PER_MILLE_FORMULA, style: "formula-text" }];
      } else {
        return chargesConfig?.rater?.config;
      }
    },
    isPolicyRaterEnabled() {
      return this.org.featureFlags.RATER_BASED_PREMIUM_ESTIMATION;
    },
    policiesGroupedByInsurer() {
      const insurers = {};
      const orgBenefits = {};
      const premiumData = this.isEndorsementPremiumPresent
        ? this.batch.endorsementPremium
        : this.batch?.meta?.premiumData?.policyWisePremiumEstimation;
      if (this.org && this.org.benefits) {
        this.org.benefits.forEach((benefit) => (orgBenefits[benefit.node.id] = benefit.node));
      }
      if (this.batch && premiumData) {
        Object.entries(premiumData).forEach(([id, policyPremium]) => {
          const insurer = orgBenefits[id]?.insurer;
          if (!insurer) {
            return;
          }
          if (!insurers[insurer.id]) {
            insurers[insurer.id] = {
              insurer: {
                ...insurer,
                ...(this.org?.meta?.insurerWiseCDBalance ? this.org.meta.insurerWiseCDBalance[insurer.id] : {}),
              },
              policies: [],
            };
          }
          insurers[insurer.id].policies.push({
            ...policyPremium,
            ...orgBenefits[id],
          });
        });
      }

      return insurers;
    },
  },
  methods: {
    getUpdatedPremiumAmount() {
      if (this.isBatchCompleted && !this.isEndorsementPremiumPresent) {
        return "N/A";
      }
      return this.totalPremiumAcrossInsurers(true);
    },
    getRootUrl() {
      return this.$route.path.includes("org-admin/")
        ? "/org-admin/changes"
        : `/admin/review/${this.$route.params.orgId}/changes`;
    },
    getBatchUrl() {
      if (this.prevUrl) return this.prevUrl;
      return this.getRootUrl();
    },
    getTotal(arr, prop) {
      let sum = 0;
      arr.forEach((item) => {
        sum += item[prop];
      });
      return sum;
    },
    isDownloadBtnDisabled() {
      return !this.batch?.premiumDetailsUrl;
    },
    async refreshJob(jobId) {
      const result = await this.$apollo.query({
        query: getSingleQuery("Job"),
        variables: { id: jobId },
        fetchPolicy: "no-cache",
      });
      return result.data.node;
    },
    async downloadInfoFile() {
      const result = await this.$apollo.mutate({
        mutation: cliToolsDef.upsertMutation,
        variables: {
          cmdName: "exportChanges",
          args: {
            orgId: this.orgId,
            batchId: this.batchId,
          },
        },
      });
      let job = result.data.invokeCliTool.job;
      // keep checking if the job has completed
      const interval = setInterval(async () => {
        job = await this.refreshJob(job.id);
        if (job.status === "completed") {
          clearInterval(interval);
          this.$store.commit("addToast", {
            variant: "success",
            message: "Successfully exported the file",
          });
          window.open(job.result.url, "_blank");
        }
        if (job.status === "failed") {
          clearInterval(interval);
          this.$store.commit("addToast", {
            variant: "danger",
            message: "Sorry, we are facing some issue while generating the file",
          });
        }
      }, 3000);
    },
    noInfoFileTooltipText() {
      return "Employee level cost is not updated yet, our team will upload it soon.";
    },
    totalPremiumAcrossInsurers(withRupeeSym) {
      const premiumData = this.isEndorsementPremiumPresent
        ? this.batch.endorsementPremium
        : this.batch?.meta?.premiumData?.policyWisePremiumEstimation;
      return utils.getTotalPremiumAcrossInsurers(premiumData, withRupeeSym);
    },
    openRaterModal() {
      this.$bvModal.show("endorsement-policy-rater-modal");
    },
    closeRaterModal() {
      this.$bvModal.hide("endorsement-policy-rater-modal");
    },
    switchRaterModalTabs(benefitId) {
      this.selectedRaterPolicyId = benefitId;
    },
    isRefundCase() {
      // if starting with negative sign
      return this.totalPremiumAcrossInsurers(true)?.startsWith("-");
    },
  },
  apollo: {
    batch: {
      query: userChangeBatchesDef.singleQuery,
      skip() {
        return !this.batchId;
      },
      variables() {
        return {
          id: this.batchId,
        };
      },
      update(data) {
        return data.node;
      },
    },
    org: {
      query: orgDef.singleQuery,
      skip() {
        return !this.orgId;
      },
      variables() {
        return {
          id: this.orgId,
        };
      },
      update(data) {
        const firstPolicyWithRater = data.node.benefits?.find((benefit) =>
          Object.values(PolicyRaterType).includes(benefit.node?.chargesConfig?.rater?.type)
        );
        this.selectedRaterPolicyId = firstPolicyWithRater?.node?.id;
        return data.node;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
@import "@/assets/styles/_typography.scss";
.vertical-separator {
  border-left: 1px solid $gray-500;
}
.tabs-container {
  overflow: auto;
  white-space: nowrap;
}
.tab-heading {
  padding: 0.5rem;
  display: inline-block;
}
.active-tab {
  color: $gray-900;
  border-bottom: 2px solid $tiber;
}
.link:hover {
  cursor: pointer;
}

.formula-text {
  color: $gray-600;
}

.h3 {
  font-weight: 600 !important;
}
</style>

<style lang="scss">
.rater-table-container {
  th {
    padding-left: 1.875rem;
    padding-right: 1.875rem;
  }
  td {
    padding-left: 1.875rem;
    padding-right: 1.875rem;
  }
}
</style>
