import { keyBy } from "lodash-es";
import { PolicyTypes } from "../common/enums";

function getMainPolicy(policies) {
  const policyTypePrecedence = [
    PolicyTypes.GMC,
    PolicyTypes.TOPUP,
    PolicyTypes.PPC,
    PolicyTypes.OPD,
    PolicyTypes.COVID,
    PolicyTypes.GPA,
  ];
  const policiesByType = keyBy(policies || this.allPolicies, (policy) => policy.node.type);

  for (const type of policyTypePrecedence) {
    if (policiesByType[type]) {
      return policiesByType[type];
    }
  }

  return {};
}

export default {
  getMainPolicy,
};
