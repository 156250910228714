<template lang="pug">
.cart-panel
  div(v-if="!isMobileBrowser() && !isApp()")
    premium-card(v-if="[TopupRoutes.POLICY_DETAILS, TopupRoutes.POLICY_SUMMARY].includes(currentStep)", :nextRoute="`/super-topups/${getNextStage(currentStep)}`", @finalConfirmationClicked="$emit('finalConfirmationClicked')")
    summary-card(v-if="currentStep !== TopupRoutes.POLICY_SUMMARY", :showButton="currentStep !== TopupRoutes.POLICY_DETAILS || (!superTopupProspectState.isNovaPlusSelected && !superTopupProspectState.isNovaExtendedSelected)", :header="SUMMARY_CONTENT_WEB[currentStep]?.header", :title="SUMMARY_CONTENT_WEB[currentStep]?.title", :bodyText1="SUMMARY_CONTENT_WEB[currentStep]?.bodyText1", :bodyText2="SUMMARY_CONTENT_WEB[currentStep]?.bodyText2", :buttonText="SUMMARY_CONTENT_WEB[currentStep]?.buttonText", :nextRoute="`/super-topups/${getNextStage(currentStep)}`", :showDeductibleSideSheet="showDeductibleSideSheet")

  mobile-summary(v-else, :header="SUMMARY_CONTENT_WEB[currentStep]?.header", :title="SUMMARY_CONTENT_WEB[currentStep]?.title", :bodyText1="SUMMARY_CONTENT_WEB[currentStep]?.bodyText1", :bodyText2="SUMMARY_CONTENT_WEB[currentStep]?.bodyText2", :buttonText="SUMMARY_CONTENT_WEB[currentStep]?.buttonText", :nextRoute="`/super-topups/${getNextStage(currentStep)}`", @toggleSummary="$emit('toggleSummary')", :isPullUpOpen="isPullUpOpen", @finalConfirmationClicked="$emit('finalConfirmationClicked')")
  

</template>
<script>
import NIcon from "../../../../components/NovaIcon.vue";
import { TopupRoutes } from "../../../../common/enums/topupRoutes.enum";
import SummaryCard from "./components/SummaryCard.vue";
import NButton from "../../../../components/NovaButton.vue";
import { SUMMARY_CONTENT_WEB } from "./constants";
import PremiumCard from "./components/PremiumCard.vue";
import MobileSummary from "./components/MobileSummary.vue";
import utils from "@/utils";
import mixin from "./mixin";
export default {
  name: "CartPanel",
  mixins: [mixin],
  components: {
    NIcon,
    NButton,
    SummaryCard,
    MobileSummary,
    PremiumCard,
  },
  data() {
    return {
      SUMMARY_CONTENT_WEB,
      TopupRoutes,
      counter: 0,
    };
  },
  props: {
    isPullUpOpen: {
      type: Boolean,
    },
  },
  computed: {
    showDeductibleSideSheet() {
      return this.currentStep === TopupRoutes.POLICY_DETAILS;
    },
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
.cart-panel {
  z-index: 6700 !important;
}
@media (max-width: 767px) {
  .fixed-bottom {
    position: fixed;
    bottom: -12%;
  }
}
</style>
