import { getListQuery, getSingleQuery } from "../queries";
import policyDataCheckErrorsMutation from "../mutations/policyDataCheckErrors";
import policyDataChecksSchema from "../schemas/policyDataChecks";

export default {
  gqlType: "PolicyDataCheckError",
  singleQuery: getSingleQuery("PolicyDataCheckError"),
  listQuery: getListQuery("PolicyDataCheckError"),
  ...policyDataCheckErrorsMutation,
  ...policyDataChecksSchema,
  transform: (n, write = false) => {
    let data = n.policyDataCheckErrors.edges;
    
    return data.map((data) => {
      const { id, org, benefit, user, dependent, policyDataCheckBatch, errorType, status, meta, createdAt, updatedAt } =
        data.node;
      return {
        id,
        org: org?.name,
        policy: benefit?.name,
        createdAt,
        updatedAt,
        status,
        jobId: policyDataCheckBatch?.job_id,
        comparisonSource: policyDataCheckBatch?.comparison_source,
        errorType: errorType,
        userEmail: user?.email || "-",
        userName: user ? user?.name : meta?.sourceFileData?.sourceName,
        dependentName: dependent?.name || "-",
        employeeId: user ? user?.meta?.employeeId : meta?.sourceFileData?.sourceEmployeeId,
        relation: user ? meta?.portalData?.portalRelation : meta?.sourceFileData?.sourceRelation,
        meta,
      };
    });
  },
};
