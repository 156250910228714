import { CheckupRoutes } from "../../../common/enums";
export const Base = require("./Base.vue").default;

export const routes = [
  {
    path: "",
    name: "checkup",
    component: Base,
    children: [
      { path: CheckupRoutes.SCHEDULE_BOOKING, name: "scheduleBooking", component: Base },
      { path: CheckupRoutes.BOOKING_HISTORY, name: "bookingHistory", component: Base },
      { path: `${CheckupRoutes.BOOKING_FORM}/:ahcNanoId`, name: "bookingForm", component: Base },
      {
        path: `${CheckupRoutes.BOOKING_FORM}/${CheckupRoutes.ADDRESS_SELECTION}/:ahcNanoId`,
        name: "addressSelection",
        component: Base,
      },
    ],
  },
];
