<template lang="pug">
    div.step-container
        span(v-for="(step, i) in totalSteps" :class="i == currentStep ? 'done' : ''")
</template>

<script>
export default {
  name: "StepCounter",
  props: {
    totalSteps: {
      type: Number,
      default: 3,
    },
    currentStep: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";

.step-container {
  display: flex;
  max-width: max-content;
  gap: 2px;
  align-items: center;
  span {
    width: 6px;
    height: 6px;
    border-radius: 50px;
    background-color: $gray-400;
  }

  .done {
    background-color: $blue-500 !important;
  }
}
</style>
