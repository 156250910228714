import {
  LOGIN_MUTATION,
  SSO_USER_TOKEN_QUERY,
  MAGICLINK_MUTATION,
  VALIDATE_MAGICLINK_MUTATION,
  MY_INFO,
  GET_USER_EMAIL_FROM_TOKEN,
  REFRESH_TOKEN,
  LOGIN_CONFIG,
  VERIFY_TOKEN,
  SET_INVITED_USER_PASSWORD,
  IMPERSONATE_USER,
  CHANGE_USER_PASSWORD,
  GENERATE_OTP_FOR_LOGIN,
} from "./../definitions/auth";
import { apolloClientRefresh } from "./../apolloRefreshToken";
import { apolloClient } from "@/portal/apollo";
/*
  mutations and queries
*/
export async function loginMutation(variables) {
  return await apolloClient.mutate({
    mutation: LOGIN_MUTATION,
    variables,
  });
}
export async function sendOtpForLogin(variables) {
  return await apolloClient.mutate({
    mutation: GENERATE_OTP_FOR_LOGIN,
    variables,
  })
}

export async function validateMagicLinkMutation(variables) {
  return await apolloClient.mutate({
    mutation: VALIDATE_MAGICLINK_MUTATION,
    variables,
  });
}

export async function sendMagicLink(variables) {
  return await apolloClient.mutate({
    mutation: MAGICLINK_MUTATION,
    variables,
  });
}

export async function fetchSSOUserToken(variables) {
  return await apolloClient.query({
    query: SSO_USER_TOKEN_QUERY,
    variables,
  });
}

export async function getMyInfo(context) {
  return await apolloClient.query({
    query: MY_INFO,
    context,
  });
}

export async function getUserEmailFromToken(variables) {
  return await apolloClient.query({
    query: GET_USER_EMAIL_FROM_TOKEN,
    variables,
  });
}

export async function refreshToken(refreshToken) {
  return apolloClientRefresh.mutate({
    mutation: REFRESH_TOKEN,
    variables: {
      refreshToken,
    },
  });
}

export async function getLoginConfig(email) {
  return apolloClient.query({
    query: LOGIN_CONFIG,
    variables: {
      email,
    },
  });
}

export async function verifyToken(variables) {
  return apolloClient.mutate({
    mutation: VERIFY_TOKEN,
    variables,
  });
}

export async function setInvitedUserPassword(variables) {
  return apolloClient.mutate({
    mutation: SET_INVITED_USER_PASSWORD,
    variables,
  });
}

export async function impersonateOtherUser(id) {
  return apolloClient.query({
    query: IMPERSONATE_USER,
    variables: {
      id,
    },
  });
}

export async function changeUserPassword(variables) {
  return await apolloClient.mutate({
    mutation: CHANGE_USER_PASSWORD,
    variables,
  });
}
