export const DEPENDENT_META_FIELDS = ["source", "dateOfJoining", "isPreferred"];

export const SELF_META_FIELDS = [
  "city",
  "source",
  "jobGrade",
  "employeeId",
  "obBypassedBy",
  "contactNumber",
  "dateOfJoining",
  "isNumberVerified",
  "impersonatedUserId",
  "showOnboardingScreens",
  "isPasswordSetupRequired",
  "whatsappUserConsent",
  "pincode",
  "altEmail",
];
