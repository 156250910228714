import gql from "graphql-tag";
import { accountFragment } from "../../fragments";

export default {
  upsertAccount: gql`
    mutation upsertAccount(
      $id: ID
      $orgId: ID!
      $orgEntityId: ID
      $type: String!
      $balance: Float!
      $minBalance: Float!
      $insurerId: ID!
      $bankDetails: JSONObject
    ) {
      upsertAccount(
        input: {
          id: $id
          orgId: $orgId
          orgEntityId: $orgEntityId
          type: $type
          balance: $balance
          minBalance: $minBalance
          insurerId: $insurerId
          bankDetails: $bankDetails
        }
      ) {
        account {
          ...Account
        }
      }
    }
    ${accountFragment}
  `,
};
