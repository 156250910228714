<template lang="pug">
  .mt-0
    b-modal(:id="id", :title="``", size="md", centered)
      template(slot="modal-header")
        .d-flex.justify-content-between.align-items-center.w-100
          .py-1.d-flex.align-items-center
            span.font-lg.font-weight-semibold.mr-2 Query
            query-status-pill.ml-3(:status="selectedQuery?.status")
          .py-1.d-flex.align-items-center
            n-button.d-none.d-md-inline-block.mr-3(
              variant="dark",
              size="sm",
              buttonText="Need help?",
              @click="showSalesIqChat")
            span.p-2.bg-gray-200.rounded-4.d-flex.align-items-center.cursor-pointer(@click="$bvModal.hide('qrm')")
              i.icon-cross.ic-medium.text-gray-700
  
      .p-2
        .text-gray-900.font-md.font-weight-medium
          | Hey {{claim?.user?.name}} 👋 <br> <br>
          | Your TPA/Insurer has asked for a couple of documents. Please upload these at the earliest! 
          query-details.mt-4(v-if="selectedQuery", :query="selectedQuery")
  
        batch-uploader-wrapper(v-if="uploadedFiles", v-model="uploadedFiles", :types="getTypeAndDescription()", :requiredDetails="getReqDetails()",@update="handleUpdate()")
        .text-gray-900.font-sm.font-weight-medium.mt-3 Any additional comments for the Nova team?
        n-text-area.font-xs.text-area.mt-1(
          v-model="claimantResponse",
          :rows="3",
          :maxLength="1000",
          :maxRows="4",
          :cols="50",
          dynamic-height=false,
          fontSize="md",
          placeholder="Enter additional details"
        )
      template.justify-content-center(v-slot:modal-footer)
        .buttons-container.d-flex.justify-content-center.align-items-center
          n-button.mr-2(
            buttonText="Save for later",
            variant="light",
            size="sm",
            @click="queryRespondedClaimant(false)"
          )
          n-button(
            variant="primary",
            size="sm",
            buttonText="Submit Query Response",
            @click="queryRespondedClaimant(true)"
          )
  
    special-modal(
      id="response-updated",
      headerIcon="icon",
      :heroImage="require('@/assets/images/Save_and_Exit_Header_Image_Container.svg')",
      heroImageSize="md",
      logoIcon="charge", 
      centered
    )
      template(v-slot:title)
        h3.hb3.d-inline.font-weight-semibold.text-gray-900 Response Updated ✨
      template(v-slot:modal-content)
        .text-center
          | Your Query response is saved.
          | Redirecting you to Claims details...
      template(v-slot:buttons-area)
        .rounded-circle
  </template>

<script>
import gql from "graphql-tag";
import SpecialModal from "../../../../../components/SpecialModal.vue";
import QueryStatusPill from "./QueryStatusPill.vue";
import QueryDetails from "./QueryDetails.vue";
import BatchUploaderWrapper from "./BatchUploaderWrapper.vue";
import NAvatar from "@/components/Avatar.vue";
import NIcon from "@/components/NovaIcon.vue";
import NButton from "@/components/NovaButton";
import NTextArea from "@/components/NovaTextArea.vue";
import utils from "@/utils";

export default {
  name: "QueryResponseModal",
  components: {
    NAvatar,
    NIcon,
    NButton,
    NTextArea,
    QueryStatusPill,
    QueryDetails,
    BatchUploaderWrapper,
    SpecialModal,
  },
  props: {
    selectedQuery: {
      type: Object,
      default: () => {},
    },
    id: {
      type: String,
      default: () => "qrp",
    },
    claim: {
      type: Object,
      default: () => {},
    },
    redirectToClaimDetails: {
      type: Boolean,
      default: false,
    },
    claimFiles: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      uploadedFiles: [],
      types: [],
      claimantResponse: this.selectedQuery?.claimantResponse,
    };
  },
  computed: {
    setClaimantResponseText() {
      this.claimantResponse = this.selectedQuery?.claimantResponse;
      return this.selectedQuery?.claimantResponse;
    },
  },
  created() {
    if (this.claimFiles && this.claimFiles.length) {
      this.uploadedFiles = this.claimFiles;
    }
  },
  mounted() {
    this.$root.$on("claimFilesUpdated", this.handleClaimFilesUpdated);
  },
  methods: {
    gotoDetailsPage() {
      this.$router.push({
        name: "claim-details",
        params: { id: this.claim.id },
      });
    },
    async queryRespondedClaimant(finalSubmission) {
      try {
        const queryDocs = this.uploadedFiles;
        await this.$apollo.mutate({
          mutation: gql`
            mutation queryRespondedClaimant(
              $id: ID
              $claimId: ID!
              $files: [JSONObject]
              $claimantResponse: String
              $isClaimantResponse: Boolean
              $finalSubmission: Boolean
            ) {
              queryRespondedClaimant(
                input: {
                  id: $id
                  claimId: $claimId
                  files: $files
                  claimantResponse: $claimantResponse
                  isClaimantResponse: $isClaimantResponse
                  finalSubmission: $finalSubmission
                }
              ) {
                claim {
                  id
                }
              }
            }
          `,
          variables: {
            id: this.selectedQuery.id,
            claimId: this.claim.id,
            files: queryDocs,
            claimantResponse: this.claimantResponse,
            isClaimantResponse: true,
            finalSubmission,
          },
        });

        this.$bvModal.hide(this.id);
        this.$bvModal.show("response-updated");
        this.$emit("responseSubmitted", finalSubmission);

        setTimeout(() => {
          this.$bvModal.hide("response-updated");
          if (this.redirectToClaimDetails) {
            this.gotoDetailsPage();
          }
        }, 3000);
      } catch (err) {
        console.log(err);
      }
    },
    preview(url) {
      window.open(url, "_blank");
    },
    showSalesIqChat() {
      this.$store.commit("toggleSupportContainer");
    },
    getTypeAndDescription() {
      return [
        {
          name: "Query Documents",
        },
      ];
    },
    getReqDetails() {
      return {
        claimId: this.claim ? this.claim.id : null,
      };
    },
    handleUpdate() {
      this.$emit("fileAdded");
    },
    handleClaimFilesUpdated(claimFiles) {
      this.uploadedFiles = claimFiles;
    },
  },
};
</script>
