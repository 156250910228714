import Vue from "vue";
import Vuex from "vuex";
import { v4 as uuid } from "uuid";
import { isEqual } from "lodash-es";
import { getJWTExpiry } from "../utils/date";
import { unset } from "../utils/localStorage";
import { fromGlobalId } from "../utils/misc";
import { logoutActiveUser } from "../common/auth";
import { cache } from "../common/apolloCache";
import { TopupRoutes } from "../common/enums/topupRoutes.enum";
import { AcceptedRelations, ProspectType } from "../common/enums";
import mobileApp from "./mobileApp";
import getStartedStore from "./pages/user/getStarted/store";
import { getActivePolicyByType } from "@/utils/user";

Vue.use(Vuex);
export const store = new Vuex.Store({
  modules: {
    getStarted: getStartedStore,
  },
  state: {
    selectedOrgEntity: null,
    showVersionStrip: false,
    majorVersionMismatch: false,
    isCheckupBookingInProgress: false,
    isCookieAndConsentPermissionGranted: false,
    apiVersion: null,
    user: {},
    token: null,
    refreshToken: null,
    redirectTo: null,
    toastsShown: false,
    toasts: [],
    navigation: {
      sectionHeader: "",
      navHeader: {
        icon: "",
        name: "",
        backButton: false,
      },
    },
    toastsCountMap: {},
    currentIntroVersion: 1,
    prospectId: "",
    topupProspectState: {},
    navBottomBorder: true,
    userFitnessData: {},
    navType: "top",
    showVersioningModal: false,
    isSwLoading: false,
    showSupportContainer: false,
    adminFilterRes: {
      filterData: {},
      searchQuery: {},
    },
    orgAdminFilterRes: {
      filterData: {},
      searchQuery: {},
    },
    userLoginToken: null,
    deepLinkTarget: null,
    scrollPositions: {},
    hrmsSyncWarningModal: {
      modalVisible: false,
      toPath: {},
      modalRequired: false,
    },
    bulkUserOperationWarningModal: {
      modalVisible: false,
      toPath: {},
      modalRequired: false,
    },
    bulkUserOperationJob: {
      jobType: "",
      jobProgress: 0,
      jobStatus: "",
      jobId: "",
    },

    novaSideNavExpanded: true,
    ahcProspectId: null,
    ahcProspectState: {
      meta: {
        prospectType: "ahc",
        ahcProspectState: {
          totalCost: 0,
          currentStep: "dependent-selection",
          isAddressSelected: false,
          isPackageSelected: false,
          isSameAsCollectionAddressSelected: true,
          userPackageCost: 0,
          scheduledAt: null,
          contactDetails: {},
          selectedGroupPackage: {},
          extraMembers: [],
          selectedMembers: [],
          selectedAddress: {},
          selectedProvider: {},
          selectedDateTimeSlot: {},
          billingAddress: {},
        },
      },
    },
    superTopupProspectState: {
      id: "",
      email: "",
      meta: {
        prospectType: ProspectType.SUPER_TOPUPS,
        user_id: "",
        superTopupProspectState: {
          totalCost: 0,
          currentStep: TopupRoutes.ADD_MEMBERS,
          policyDetail: {},
          plusDependents: [
            {
              checked: false,
              name: "",
              dob: "",
              relation: AcceptedRelations.SPOUSE,
            },
            {
              checked: false,
              name: "",
              dob: "",
              relation: AcceptedRelations.CHILD,
            },
            {
              checked: false,
              name: "",
              dob: "",
              relation: AcceptedRelations.CHILD,
            },
          ],
          parents: [
            {
              checked: false,
              name: "",
              dob: "",
              relation: AcceptedRelations.PARENT,
            },
            {
              checked: false,
              name: "",
              dob: "",
              relation: AcceptedRelations.PARENT,
            },
          ],
          existingSpouseAndChildrenInitialized: false,
          existingParentsInitialized: false,
          selectedLives: [],
          isNovaPlusSelected: false,
          novaPlusPremiumDetails: {},
          isNovaExtendedSelected: false,
          novaExtendedPremiumDetails: {},
          nomineeDetail: {},
          nomineeContactInfo: {},
          contactDetail: {},
          isFormValid: false,
        },
      },
    },
    noErrorToastMutations: [],
    claimFormData: {},
    cachedShortenedEcardUrl: "",
    postPrivacySettingRedirectionUrl: null,
  },
  getters: {
    user(state) {
      return state.user;
    },
    selectedOrgEntity(state) {
      return state.selectedOrgEntity;
    },
    isLoggedIn(state) {
      return Boolean(state.user && state.token);
    },
    areUserPermissionsGranted(state) {
      if (!state.isCookieAndConsentPermissionGranted) {
        const permissionGranted = localStorage.getItem("isCookieAndConsentPermissionGranted");
        state.isCookieAndConsentPermissionGranted = permissionGranted === "true";
      }
      return state.isCookieAndConsentPermissionGranted;
    },
    isAdmin(state) {
      return Boolean(
        state.user &&
          state.token &&
          (state.user.roles.indexOf("admin") !== -1 ||
            state.user.roles.indexOf("account_admin") !== -1 ||
            state.user.roles.indexOf("batman") !== -1 ||
            state.user.roles.indexOf("customer_support") !== -1 ||
            state.user.roles.indexOf("cx_manager") !== -1)
      );
    },
    isCxManager(state) {
      return Boolean(state.user && state.token && state.user.roles.indexOf("cx_manager") !== -1);
    },
    isOrgAdmin(state) {
      return Boolean(state.user && state.token && state.user.roles.indexOf("org_admin") !== -1);
    },
    isAccountAdmin(state) {
      return Boolean(state.user && state.token && state.user.roles.indexOf("account_admin") !== -1);
    },
    isProspect(state) {
      return Boolean(state.user.roles.indexOf("prospect") !== -1);
    },
    isCheckupAdmin(state) {
      return Boolean(state.user.roles.indexOf("checkup_admin") !== -1);
    },
    isOrgEntityAdmin(state) {
      return Boolean(state.user && state.user.roles.indexOf("org_entity_admin") !== -1);
    },
    isImposter(state) {
      return Boolean(state.user && localStorage.getItem("imposterToken"));
    },
    getToastsCount(state) {
      return (message) => state.toastsCountMap[message]?.count || 0;
    },
    getCurrentIntroVersion(state) {
      return state.currentIntroVersion;
    },
    isCheckupBookingProcessActive(state) {
      return state.isCheckupBookingInProgress;
    },
    getFeatureFlags(state) {
      const gmcPolicy = getActivePolicyByType(state.user.benefits, "gmc");
      const superTopupPolicy = getActivePolicyByType(state.user.benefits, "super-topup");

      // (temp) this is to avoid showing topup purchase option to people who have already bought it
      const topupPolicy = getActivePolicyByType(state.user.benefits, "topup");

      // temporarily showing topups for specific subset of users only
      const SUPER_TOPUPS =
        (state.user?.orgEntity?.featureFlags?.SUPER_TOPUPS || state.user?.org?.featureFlags?.SUPER_TOPUPS) &&
        gmcPolicy &&
        !superTopupPolicy &&
        !(topupPolicy && topupPolicy.node?.name?.includes("Care Super Topup"));

      return {
        ...(state.user?.orgEntity?.featureFlags || state.user?.org?.featureFlags || {}),
        SUPER_TOPUPS,
      };
    },
    getAssessments(state) {
      return {
        ...(state.user?.org?.employeeAssessments || {}),
      };
    },

    getBrandCustomizations(state) {
      return state.user?.org?.brandCustomizations;
    },
    isTopNav(state) {
      return state.navType === "top";
    },
    showVersioningModal(state) {
      return state.showVersioningModal;
    },
    isSwLoading(state) {
      return state.isSwLoading;
    },
    showSupportContainer(state) {
      return state.showSupportContainer;
    },
    getFilterData(state) {
      return state.adminFilterRes.filterData;
    },
    getSearchQueryText(state) {
      return state.adminFilterRes.searchQuery;
    },
    getOrgAdminSearchQueryText(state) {
      return state.orgAdminFilterRes.searchQuery;
    },
    getOrgAdminFilterData(state) {
      return state.orgAdminFilterRes.filterData;
    },
    getUserLoginToken(state) {
      return state.userLoginToken;
    },
    getDeepLinkTarget(state) {
      if (!state.deepLinkTarget) {
        state.deepLinkTarget = localStorage.getItem("deepLinkTarget") || "app";
      }
      return state.deepLinkTarget;
    },
    getSyncWarningModalVisibility(state) {
      return state.hrmsSyncWarningModal.modalVisible;
    },
    getBulkUserOperationWarningModalVisibility(state) {
      return state.bulkUserOperationWarningModal.modalVisible;
    },
    getBulkDeleteWarningModalVisibility(state) {
      return state.bulkDeleteWarningModal.modalVisible;
    },
    ahcProspectState(state) {
      return state.ahcProspectState?.meta?.ahcProspectState || {};
    },
    superTopupProspectState(state) {
      return state.superTopupProspectState?.meta?.superTopupProspectState || {};
    },
    tpaImageUrl: (state) => state.tpaImageUrl,
    tpaClaimsSyncDate: (state) => state.tpaClaimsSyncDate,
    getNoErrorToastMutations: (state) => state.noErrorToastMutations,
  },
  actions: {
    async loadLocalStore(ctx) {
      const userRaw = localStorage.getItem("user");
      const token = localStorage.getItem("token");
      const refreshToken = localStorage.getItem("refreshToken");
      if (userRaw && token && ctx.state.token !== token) {
        const user = JSON.parse(userRaw);
        ctx.commit("loginUser", { user, token, refreshToken });
        if (user?.orgEntity) {
          ctx.commit("setSelectedOrgEntity", user?.orgEntity);
        }
        return true;
      }
      return false;
    },
    async loadTopupLocalStore(ctx) {
      const prospectId = localStorage.getItem("prospectId");
      const localData = localStorage.getItem("topupProspectState");
      if (prospectId && localData) {
        ctx.commit("saveProspectId", prospectId);
        ctx.commit("saveTopUpProspectState", localData);
        return true;
      }
      return false;
    },
    scrollToSection(ctx, sectionInfo) {
      setTimeout(
        () => {
          const element = document.getElementById(sectionInfo.sectionId);
          element?.scrollIntoView({ behavior: "smooth" });
        },
        sectionInfo?.fromMounted ? 500 : 0
      );
      // 500ms to ensure all components and its assets are loaded
      // todo(nimesh): better way would be to have scroll handler in Parent's Mounted hook (ClaimGuide.vue)
    },
  },
  mutations: {
    setShowVersionStrip(state, showVersionStrip) {
      state.showVersionStrip = showVersionStrip;
    },
    updateMajorVersionMismatch(state, majorVersionMismatch) {
      state.majorVersionMismatch = majorVersionMismatch;
    },
    setApiVersion(state, apiVersion) {
      state.apiVersion = apiVersion;
    },
    setSelectedOrgEntity(state, selectedOrgEntity) {
      state.selectedOrgEntity = selectedOrgEntity;
    },
    startCheckupBookingProcess(state) {
      state.isCheckupBookingInProgress = true;
    },
    endCheckupBookingProcess(state) {
      state.isCheckupBookingInProgress = false;
    },
    loginUser(state, { user, token, refreshToken }) {
      if (!token || !refreshToken || refreshToken === "null") {
        console.error("Logging out because token not found");
        logoutActiveUser(false);
        return;
      }

      const accessTokenExpiry = getJWTExpiry(token);
      const refreshTokenExpiry = getJWTExpiry(refreshToken);

      // vuex state
      state.user = user;
      state.selectedOrgEntity = user?.orgEntity;
      state.token = token;
      state.refreshToken = refreshToken;
      state.accessTokenExpiry = accessTokenExpiry;
      state.refreshTokenExpiry = refreshTokenExpiry;

      // local storage
      localStorage.setItem("user", JSON.stringify(user));
      localStorage.setItem("token", token);
      localStorage.setItem("refreshToken", refreshToken);
      localStorage.setItem("accessTokenExpiry", accessTokenExpiry);
      localStorage.setItem("refreshTokenExpiry", refreshTokenExpiry);

      cache.data.clear();
      if (user?.id) {
        const isImposter = this.getters.isImposter;
        const isAppUser = mobileApp.isApp;
        window.posthog.identify(user.id, { roles: user.roles, isImposter, isAppUser });
        window.posthog.alias(user.id, fromGlobalId(user.id).id);
        window.posthog.people.set({
          email: user.email,
          name: user.displayName,
          userId: user.id,
          orgId: user.org.id,
          org: user.org.name,
        });
      }
    },
    refreshAccessToken(state, { user, token }) {
      const accessTokenExpiry = getJWTExpiry(token);

      // vuex state
      state.user = user;
      state.token = token;
      state.accessTokenExpiry = accessTokenExpiry;

      // local storage
      localStorage.setItem("user", JSON.stringify(user));
      localStorage.setItem("token", token);
      localStorage.setItem("accessTokenExpiry", accessTokenExpiry);
    },
    logoutUser(state) {
      state.user = {};
      state.token = null;
      state.refreshToken = null;
      state.accessTokenExpiry = null;
      state.refreshTokenExpiry = null;

      unset([
        "user",
        "token",
        "refreshToken",
        "accessTokenExpiry",
        "refreshTokenExpiry",
        "apollo-cache-persist",
        "imposterToken",
        "imposterUser",
        "imposterLastVisitedUrl",
        "imposterRefreshToken",
      ]);
      cache.data.clear();
      if (window.$zoho && window.$zoho.salesiq) {
        window.$zoho.salesiq.reset();
      }
    },
    /**
     * Function that adds an toast to the state.
     * @param {*} state
     * @param {*} item: {
     * message: the message to be shown.
     * variant: If it's an warning/success/danger.
     * id: the generated UUID.
     * title: the title for the toast. If this is null, the toast title will fallback to the default title in Toasts.vue
     * }
     * @returns
     */
    addToast(state, { message, variant, id, title }) {
      if (!id) id = uuid();
      const oldToast = state.toasts.find((toast) => toast.message === message);
      if (!oldToast) state.toasts.push({ variant, message, id, title });
      if (!state.toastsCountMap[message]) {
        Vue.set(state.toastsCountMap, message, { count: 0 });
      }
      state.toastsCountMap[message].count++;
      return id;
    },
    clearToast(state, id) {
      const idx = state.toasts.findIndex((toast) => toast.id === id);
      state.toastsCountMap[state.toasts[idx].message].count = 0;
      state.toasts.splice(idx, 1);
    },
    clearToasts(state) {
      state.toasts = [];
      state.toastsCountMap = {};
    },
    updateSectionHeader(state, heading) {
      if (state.navigation.sectionHeader !== heading) {
        state.navigation.sectionHeader = heading;
      }
    },
    updateNavHeader(state, { name, icon, backButton }) {
      if (backButton && backButton !== state.navigation.navHeader.backButton) {
        state.navigation.navHeader.backButton = backButton;
      }
      if (name && name !== state.navigation.navHeader.name) {
        state.navigation.navHeader.name = name;
      }
      if (icon && icon !== state.navigation.navHeader.icon) {
        state.navigation.navHeader.icon = icon;
      }
    },
    updateUser(state, user) {
      const storedUser = JSON.parse(localStorage.getItem("user"));
      if (!isEqual(storedUser, user)) {
        state.user = user;
        localStorage.setItem("user", JSON.stringify(user));
      }
    },
    updateUserProfileData(state, { displayName, meta }) {
      state.user.displayName = displayName;
      state.user.meta = meta;
    },
    saveProspectId(state, prospectId) {
      state.prospectId = prospectId;
      localStorage.setItem("prospectId", prospectId);
    },
    saveTopUpProspectState(state, topupProspectState) {
      state.topupProspectState = topupProspectState;
      localStorage.setItem("topupProspectState", topupProspectState);
    },
    updateUserFitnessData(state, userFitnessData) {
      state.userFitnessData = userFitnessData;
    },
    toggleNavBottomBorder(state, toggleState) {
      state.navBottomBorder = toggleState;
    },
    addRedirectUrl(state, redirectUrl) {
      state.redirectTo = redirectUrl;
      localStorage.setItem("redirectTo", redirectUrl);
    },
    removeRedirectUrl(state) {
      state.redirectTo = null;
      localStorage.removeItem("redirectTo");
    },
    setNavType(state, type) {
      state.navType = type;
    },
    enableVersioningModal(state, flag) {
      state.showVersioningModal = flag || false;
    },
    setSwLoadingState(state, flag) {
      state.isSwLoading = flag || false;
    },
    toggleSupportContainer(state) {
      state.showSupportContainer = !state.showSupportContainer;
    },
    hideSupportContainer(state) {
      state.showSupportContainer = false;
    },
    setFilterData(state, { tabName, filter }) {
      state.adminFilterRes.filterData[tabName] = filter;
    },
    setSearchQueryText(state, { tabName, searchQueryText }) {
      state.adminFilterRes.searchQuery[tabName] = searchQueryText;
    },
    setOrgAdminSearchQueryText(state, { tabName, searchQueryText }) {
      state.orgAdminFilterRes.searchQuery[tabName] = searchQueryText;
    },
    setOrgAdminFilterData(state, { tabName, filter }) {
      state.orgAdminFilterRes.filterData[tabName] = filter;
    },
    setUserLoginToken(state, userLoginToken) {
      state.userLoginToken = userLoginToken;
    },
    setDeepLinkTarget(state, { deepLinkTarget }) {
      state.deepLinkTarget = deepLinkTarget;
      localStorage.setItem("deepLinkTarget", deepLinkTarget);
    },
    setScrollPositions(state, { routeName, scrollPosition }) {
      state.scrollPositions[routeName] = scrollPosition;
    },
    allowHrmsSyncWarningModalToDisplay(state) {
      state.hrmsSyncWarningModal.modalRequired = true;
    },
    openHrmsSyncWarningModal(state, RedirectionPath) {
      state.hrmsSyncWarningModal.modalVisible = true;
      state.hrmsSyncWarningModal.toPath = RedirectionPath;
    },
    closeHrmsSyncWarningModal(state) {
      state.hrmsSyncWarningModal.modalVisible = false;
    },
    clearHrmsSyncWarningModal(state, resetPath = false) {
      state.hrmsSyncWarningModal.modalVisible = false;
      state.hrmsSyncWarningModal.modalRequired = false;
      if (resetPath) {
        state.hrmsSyncWarningModal.toPath = {};
      }
    },
    allowBulkUserOperationWarningModalToDisplay(state) {
      state.bulkUserOperationWarningModal.modalRequired = true;
    },
    openBulkUserOperationWarningModal(state, RedirectionPath) {
      state.bulkUserOperationWarningModal.modalVisible = true;
      state.bulkUserOperationWarningModal.toPath = RedirectionPath;
    },
    closeBulkUserOperationWarningModal(state) {
      state.bulkUserOperationWarningModal.modalVisible = false;
    },
    clearBulkUserOperationWarningModal(state, resetPath = false) {
      state.bulkUserOperationWarningModal.modalVisible = false;
      state.bulkUserOperationWarningModal.modalRequired = false;
      if (resetPath) {
        state.bulkUserOperationWarningModal.toPath = {};
      }
    },
    updateBulkUserOperationProgress(state, progress) {
      state.bulkUserOperationJob.jobProgress = progress;
    },
    updateBulkUserOperationStatus(state, status) {
      state.bulkUserOperationJob.jobStatus = status;
    },
    initBulkUserOperation(state, { jobId, operationType }) {
      state.bulkUserOperationJob = {
        jobId,
        jobStatus: "",
        jobType: operationType,
        progress: 0,
      };
    },
    resetBulkUserOperation(state) {
      state.bulkUserOperationJob = {
        jobId: "",
        jobStatus: "",
        jobType: "",
        progress: 0,
      };
    },
    toggleNovaSideNavState(state, isExpanded) {
      state.novaSideNavExpanded = isExpanded;
    },
    resetAhcProspectState(state) {
      state.ahcProspectState = {
        meta: {
          prospectType: "ahc",
          ahcProspectState: {
            currentStep: "dependent-selection",
            isAddressSelected: false,
            isSameAsCollectionAddressSelected: true,
            scheduledAt: null,
            contactDetails: {},
            selectedGroupPackage: {},
            selectedAddress: {},
            selectedProvider: {},
            selectedDateTimeSlot: {},
            selectedMembers: [],
            extraMembers: [],
          },
        },
      };
    },
    saveAhcProspectState(state, ahcProspectState) {
      state.ahcProspectState = ahcProspectState;
    },
    updateAhcProspectStateMeta(state, ahcProspectState) {
      state.ahcProspectState.meta.ahcProspectState = {
        ...state.ahcProspectState.meta.ahcProspectState,
        ...ahcProspectState,
      };
    },
    saveSuperTopupProspectState(state, superTopupProspectState) {
      state.superTopupProspectState = { ...superTopupProspectState };
    },
    updateSuperTopupProspectStateMeta(state, superTopupProspectState) {
      state.superTopupProspectState.meta.superTopupProspectState = {
        ...state.superTopupProspectState.meta?.superTopupProspectState,
        ...superTopupProspectState,
      };
    },
    updateSelectedLives(state, selectedLives) {
      state.superTopupProspectState.meta.superTopupProspectState.selectedLives = selectedLives;
    },
    setTpaImageUrl(state, tpaImageUrl) {
      Vue.set(state, "tpaImageUrl", tpaImageUrl);
    },
    setTpaClaimsSyncDate(state, tpaClaimsSyncDate) {
      Vue.set(state, "tpaClaimsSyncDate", tpaClaimsSyncDate);
    },
    addToNoErrorToastMutations(state, mutationName) {
      state.noErrorToastMutations = [...state.noErrorToastMutations, mutationName];
    },
    removeFromNoErrorToastMutations(state, mutationName) {
      state.noErrorToastMutations = state.noErrorToastMutations.filter((call) => call !== mutationName);
    },
    setClaimFormData(state, formData) {
      state.claimFormData = formData;
    },
    setCachedShortenedEcardUrl(state, url) {
      state.cachedShortenedEcardUrl = url;
    },
    setCookieAndConsentFlag(state, status) {
      state.isCookieAndConsentPermissionGranted = status;
      localStorage.setItem("isCookieAndConsentPermissionGranted", status);
    },
    setRedirectToUrl(state, redirectToUrl) {
      state.postPrivacySettingRedirectionUrl = redirectToUrl;
      localStorage.setItem("postPrivacySettingRedirectionUrl", redirectToUrl);
    },
  },
});
