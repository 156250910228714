<template lang="pug">
.alert-banner.container.px-3.rounded-2(:class="alertClasses", @mouseover="isHover=true", @mouseout="isHover=false", @mousedown="isActive=true",@mouseup="isActive=false", @click="handleBannerClick", v-if="showAlert")
  .d-flex.align-items-center(:class="justifyContent")
    .d-flex.align-items-center
      n-icon.mr-2(:name="variantProperties.iconName", :class="variantProperties.iconClass", :pathCount="2")
      .text-gray-900.font-weight-medium.flex-grow-1 {{alertMessage}}
    template(v-if="ctaText && dismissCtaText" )
      .d-none.d-sm-block.fit-content
        n-button.mr-2.shadow-xs(:buttonText="dismissCtaText", variant="light", size="sm", @click="closeAlert")
        n-button(:buttonText="ctaText", variant="dark", size="sm", @click="redirectToUrl")
    template(v-else-if="url")
      n-icon.cursor-pointer(name="chevron-right", @click="redirectToUrl", :class="variantProperties.rightIconColor")
    template(v-else-if="!url && !dismissAutomatically && !hideDismissButton")
      n-icon.cursor-pointer(name="cross", @click="closeAlert", :class="variantProperties.rightIconColor")
  .d-block.d-sm-none.mt-1(v-if="ctaText && dismissCtaText" )
    n-button.mr-2.shadow-xs(:buttonText="dismissCtaText", variant="light", size="sm", @click="closeAlert")
    n-button(:buttonText="ctaText", variant="dark", size="sm", @click="redirectToUrl")
</template>

<script>
import { mapState } from "vuex";
import NButton from "./NovaButton.vue";

export default {
  name: "NovaAlertBanner",
  components: {
    NButton,
  },
  props: {
    alertMessage: {
      type: String,
      default: null,
    },
    dismissCtaText: {
      type: String,
      default: null,
    },
    ctaText: {
      type: String,
      default: null,
    },
    dismissAutomatically: {
      type: Boolean,
      default: false,
    },
    hideDismissButton: {
      type: Boolean,
      default: false,
    },
    variant: {
      type: String,
      default: null,
    },
    url: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      showAlert: true,
      isActive: false,
      isHover: false,
      hoverColor: "",
      variantMap: {
        success: {
          bgColor: "bg-teal-100",
          iconClass: "text-teal-600",
          iconName: "check-circle",
          rightIconColor: "text-teal-600",
          hoverColor: "bg-teal-200",
        },
        warning: {
          bgColor: "bg-mustard-100",
          iconClass: "text-mustard-700",
          iconName: "warning",
          rightIconColor: "text-mustard-600",
          hoverColor: "bg-mustard-200",
        },
        "warning-2": {
          bgColor: "bg-mustard-100",
          iconClass: "text-mustard-700",
          iconName: "info",
          rightIconColor: "text-mustard-600",
          hoverColor: "bg-mustard-100",
          border: "mustard-200",
        },
        danger: {
          bgColor: "bg-red-100",
          iconClass: "text-red-500",
          iconName: "error",
          rightIconColor: "text-red-500",
          hoverColor: "bg-red-200",
        },
        info: {
          bgColor: "bg-blue-100",
          iconClass: "text-blue-500",
          iconName: "info",
          rightIconColor: "text-blue-500",
          hoverColor: "bg-blue-200",
        },
      },
    };
  },
  computed: {
    ...mapState(["alerts"]),
    justifyContent() {
      if (this.dismissAutomatically) {
        return "justify-content-center";
      }
      return "justify-content-between";
    },
    alertClasses() {
      const alertClasses = [];
      alertClasses.push(`border-${this.variant}`);
      if (this.isHover) {
        alertClasses.push(this.variantProperties.hoverColor);
      } else {
        alertClasses.push(this.variantProperties.bgColor);
      }
      if (this.isActive) {
        alertClasses.push(`border-${this.variant}`);
      }
      if (this.url && !this.ctaText) {
        alertClasses.push("cursor-pointer");
      }
      if (this.ctaText) {
        alertClasses.push("py-2");
      } else {
        alertClasses.push("py-3");
      }
      return alertClasses;
    },
    variantProperties() {
      return this.variantMap[this.variant];
    },
  },
  mounted() {
    if (this.dismissAutomatically) this.closeAlertAfterDelay();
  },
  methods: {
    handleBannerClick() {
      if (this.url && !this.ctaText) {
        this.redirectToUrl();
      }

      this.$emit("bannerClicked");
    },
    closeAlertAfterDelay() {
      setTimeout(() => {
        this.closeAlert();
      }, 5000);
    },
    redirectToUrl() {
      window.open(this.url);
      this.closeAlert();
    },
    closeAlert() {
      this.showAlert = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
.nova-toast-wrapper {
  display: flex;
  gap: 0.5rem;
}
.border-success {
  border: 1px solid $teal-600;
}
.border-warning {
  border: 1px solid $mustard-500;
}
.border-warning-2 {
  border: 1px solid $mustard-500;
}
.border-danger {
  border: 1px solid $red-500;
}
.border-info {
  border: 1px solid $blue-500;
}
.alert-banner {
  position: relative;
  z-index: 1;
}
</style>
