<template lang="pug">
n-modal(
  id="success-modal",
  dialog-class="success-modal-dialog",
  :showCross="true",
  centered,
  isScrollable=true,
  no-close-on-backdrop,
  modal-class="success-modal"
  body-class="success-modal__backdrop",
)
  template(v-slot:modal-header)
    .hb5.text-gray-900.mx-auto.my-1 Adding Dependents

  .text-center.my-3
    b-avatar.bg-blue-100(v-if="isDependentEligibleForCoverage", size="3.5rem")
      n-icon(name="shield", variant="teal-900", :size="2")
    img(v-else, :src="require(`@/assets/images/Icon.png`)", height="80", width="80")
  .modal-info.px-3.pb-10
    .text-center.text-dark.p-4.font-weight-medium
      .d-flex.align-items-center.justify-content-center
        span.hb4.mt-2 {{ getHeaderContent }}
        img.d-inline.ml-2.align-middle(
          v-if="!isDependentEligibleForCoverage",
          :src="require('@/assets/emojis/wave.svg')",
          width="25"
        )
    template(v-if="!isDependentEligibleForCoverage")
      .hb5.text-gray-900.text-center.mx-3.mb-2
        span {{ getRelation }} 
        | cannot be added to your insurance policy in the middle of the term!
      .text-center.text-gray-800.p-2.font-weight-medium.bg-malibu-100
        span.font-md.mx-1
          | However, we will add your dependent to your profile and make
          | sure they are added to your insurance when it is renewed!&nbsp
          img(:src="require(`@/assets/emojis/smiling-face.png`)",height="16", width="16").align-sub
    template(v-else-if="isNewAddition")
      .text-gray-800.p-2.font-weight-medium
        span.font-md
          | Your dependent is eligible for the following policies:
      .w-100(v-for="policy in policies")
        info-card.my-2(
          :icon="policyTypes[policy.type].icon",
          :bgColor="policyTypes[policy.type].bgColor",
          :color="policyTypes[policy.type].color")
          template(v-slot:header)
            | {{ policyTypes[policy.type].title }}
          template(v-slot:body)
            span Coverage Includes - 
            span.font-weight-semibold {{ getContent(policy) }}
  
  template(v-slot:modal-footer)
    n-button.col-md-4(variant="dark", buttonText="Finish", @click="$bvModal.hide('success-modal')")
</template>

<script>
import { capitalize } from "lodash";
import { policyTypes } from "../../../admin/constants";
import InfoCard from "./InfoCard.vue";
import NButton from "@/components/NovaButton.vue";
import NModal from "@/components/NovaModal.vue";
import utils from "@/utils";

export default {
  components: {
    NButton,
    NModal,
    InfoCard,
  },
  props: {
    policies: {
      type: Array,
      default: () => [],
    },
    relation: {
      type: String,
      default: null,
    },
    isNewAddition: {
      type: Boolean,
      default: false,
    },
    allDependentsAllowed: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      policyTypes,
    };
  },
  computed: {
    getRelation() {
      return this.$options.allowedRelations.includes(this.relation)
        ? capitalize(this.relation)
        : capitalize(this.relation) + "s";
    },
    isDependentEligibleForCoverage() {
      return !!(
        (this.$options.allowedRelations.includes(this.relation) || !this.allDependentsAllowed) &&
        this.policies.length
      );
    },
    getHeaderContent() {
      return this.isDependentEligibleForCoverage
        ? "Your dependent details have been sent to your HR representative for processing."
        : "Hi there!";
    },
  },
  created() {
    this.$options.allowedRelations = ["spouse", "child"];
  },
  methods: {
    handleClose() {
      this.$bvModal.hide("success-modal");
    },
    getContent(policy) {
      let content = utils
        .getContent(policy.coveredDependentsWrapper)
        .replace(/[.0-9]/g, "")
        .replace("and", "");
      // TODO: Remove this as this case shouldn't appear ideally considering it's for dependent addition flow
      content = content.length ? "Your " + content + " & You" : "Just You";
      return content;
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/_variables.scss";
.success-modal {
  box-shadow: $box-shadow !important;
  border: 2px solid $gray-300 !important;
  .modal-body {
    padding: 0 !important;
  }
  .modal-bg {
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
    height: 155px;
    min-width: calc(100% + 1px) !important;
    background-color: $tiber;
    position: relative;
    top: -1px;
  }
  .modal-content {
    border-top-left-radius: 0.35rem !important;
    border-top-right-radius: 0.35rem !important;
    box-shadow: $box-shadow !important;
    border: 1px solid $gray-300 !important;
  }
  .modal-info {
    position: relative;
    top: -30px;
    border: none !important;
  }
}
.success-modal + .modal-backdrop {
  background-color: white;
  opacity: 1 !important;
}
</style>
