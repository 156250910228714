<template lang="pug">
.checkup-report-sidesheet
  n-sidesheet(v-if="isNotEmpty(appointmentDetails)",
    :ssId="sidesheetId",
    :onChange="toggleBodyScrollbar",
    headerName="Digitized Reports",
    @hidden="closeHandler",
    ref="checkupReportSidesheet",
    shadow,
    backdrop)
    template(v-slot:content)
      .container
        .row.no-gutters.mb-3
          .col-8
            .row.no-gutters.mb-2
              .col.text-gray-900.font-md.font-weight-semibold Annual Health Checkup Package
            .row.no-gutters.mt-2
              .col.text-gray-700.font-md.font-weight-medium Booked for: {{ userName }}
          .col-4.text-right
            .d-flex.align-items-center.text-gray-900.font-weight-semibold.font-sm.justify-content-end
              n-icon.pr-1(:name="sampleCollectionTitleAndIcon.iconName", :size="1")
              span {{sampleCollectionTitleAndIcon.sampleCollectionTitle}}
        .row.no-gutters.mb-1
          .col
            n-button.w-100(
              buttonText="Download Reports",
              imageIcon="download",
              variant="outline-primary",
              style="height: 2.625rem;",
              @click="downloadVerifiedReports")
        hr.divider.text-gray-300.border-2
        .row.no-gutters.mb-3
          .col.font-hc.text-gray-700.letter-spacing Digitized Results
        .checkup-lab-results
          .border.rounded-8.mb-2(v-for="(labResult, labResultIndex) in formattedLabResults")
            .d-flex.justify-content-between.align-items-center.cursor-pointer.p-2(@click="toggleNthAccordion(labResultIndex)")
              span.font-md.font-weight-semibold.text-gray-900 {{ labResult.groupName }}
              n-icon(:name="isNthAccordionVisible(labResultIndex) ? 'chevron-up' : 'chevron-down'", variant="gray-900")
            b-collapse.bg-alabaster(
              role="tabpanel",
              :visible="isNthAccordionVisible(labResultIndex)")
              .mx-2.py-3.pr-2.d-flex.align-items-center.border-top(
                v-for="(testResult, testResultIndex) in labResult.testResults",
                :class="testResultIndex===0 ? 'mt-1' : ''")
                .pl-2.font-sm.font-weight-semibold.text-gray-900.checkup-result-name {{ testResult.testName }}
                n-icon.ml-2(:id="`ahc-result-${labResultIndex}-${testResultIndex}`", name="info", :pathCount="2", variant="gray-500", :size="1")
                .flex-grow-1.text-right.font-sm.font-weight-semibold(:class = "testResult.colorCode || 'text-gray-900'") {{ `${testResult.result} ${testResult.units || ""}` }}
                b-tooltip(:target="`ahc-result-${labResultIndex}-${testResultIndex}`", triggers="hover", placement="top")
                  .font-sm.font-weight-semibold.text-gray-900 {{ testResult.testName }}
                  span.font-sm.font-weight-semibold.text-gray-900(v-if="testResult.range") Normal range: &nbsp;
                  span.font-sm.font-weight-normal.text-teal-700(v-if="testResult.range") {{ `${testResult.range} ${testResult.units || ""}` }}
</template>

<script>
import _ from "lodash";
import NButton from "@/components/NovaButton.vue";
import NSidesheet from "@/components/NovaSideSheet";
import NPopover from "@/components/NovaPopover.vue";
import { getCheckupVerifiedReports } from "../queries/checkupAppointments";
import {
  CheckupResultColorCode,
  CheckupResultColorCodesPriority,
  CheckupResultColorCodeMap,
  CheckupResultEvaluationType,
} from "../checkupResultUtils";
import { getSampleCollectionTitleAndIcon } from "../checkupUtils";

export default {
  name: "CheckupReportSidesheet",
  data() {
    return {
      visibleAccordion: 1,
    };
  },
  components: {
    NButton,
    NSidesheet,
    NPopover,
  },
  props: {
    sidesheetId: {
      type: String,
      default: "",
    },

    appointmentDetails: {
      type: Object,
      default: () => {},
    },
  },
  created() {},
  computed: {
    userName() {
      return this.appointmentDetails?.member?.name || "";
    },
    checkupLabResults() {
      return this.appointmentDetails?.checkupLabResults || [];
    },
    formattedLabResults() {
      if (!this.checkupLabResults?.length) {
        return [];
      }
      const groupedResults = this.checkupLabResults.reduce((accumulator, { results, meta, checkupTestComponent }) => {
        const { units, ranges, result } = results;
        const { partnerComponentName, partnerComponentGroup } = checkupTestComponent;
        let testResult = {
          testName: partnerComponentName,
          units,
          result,
        };

        const { parsedResult } = meta;
        const colorCode = CheckupResultColorCodeMap[parsedResult?.evaluation];

        testResult.range = parsedResult?.normalRange || "";
        testResult.colorCode = colorCode || CheckupResultColorCodeMap[CheckupResultEvaluationType.UNDETERMINED];
        // converting string result to startCase
        if (_.isNaN(_.toNumber(result)))
          testResult.result = _.upperFirst(_.toLower(result.replace("-", " to "))).trim();

        // make groups according to the Test component name
        if (partnerComponentGroup in accumulator) {
          accumulator[partnerComponentGroup].testResults.push(testResult);
        } else {
          accumulator[partnerComponentGroup] = {
            groupName: partnerComponentGroup,
            testResults: [testResult],
          };
        }
        return accumulator;
      }, {});
      const groupedResultsValues = Object.values(groupedResults);
      const leastPriority = CheckupResultColorCodesPriority[CheckupResultColorCode.UNDETERMINED];

      // sorting test result based on color code
      for (const groupedResultsValue of groupedResultsValues) {
        groupedResultsValue.testResults.sort(
          (a, b) =>
            (CheckupResultColorCodesPriority[a.colorCode] || leastPriority) -
            (CheckupResultColorCodesPriority[b.colorCode] || leastPriority)
        );
      }
      groupedResultsValues.sort((a, b) => a?.groupName?.toString()?.localeCompare(b?.groupName));
      return groupedResultsValues;
    },
    sampleCollectionTitleAndIcon() {
      return getSampleCollectionTitleAndIcon(this.appointmentDetails?.checkupPackage?.sampleCollectionType);
    },
  },
  methods: {
    async downloadVerifiedReports() {
      const response = await getCheckupVerifiedReports(this.appointmentDetails.partnerAppointmentId);
      const data = response?.data?.getCheckupVerifiedReports;
      if (!data?.success || !data?.reportLink) {
        this.$store.commit("addToast", {
          variant: "danger",
          message: "Unable to download reports!",
        });
      } else {
        window.posthog.capture("checkup_user_downloaded_appointment_reports");
        window.open(data.reportLink);
      }
    },
    closeHandler() {
      this.visibleAccordion = 1;
      this.$refs.checkupReportSidesheet.$refs.novaSidesheet.hide();
    },
    toggleBodyScrollbar(visible) {
      const body = document.getElementsByTagName("body")?.[0];
      if (visible) body.classList.add("overflow-hidden");
      else body.classList.remove("overflow-hidden");
    },
    toggleNthAccordion(nth) {
      this.visibleAccordion = this.visibleAccordion !== nth ? nth : -1;
    },
    isNthAccordionVisible(nth) {
      return this.visibleAccordion === nth;
    },
    isNotEmpty(val) {
      return !_.isEmpty(val);
    },
  },
};
</script>

<style lang="scss">
.checkup-report-sidesheet {
  position: relative !important;
  z-index: 6001 !important;
  .b-sidebar-body {
    .tabs {
      display: none !important;
    }
  }
  .tooltip-inner {
    text-align: left;
  }
}
</style>
<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
@import "@/assets/styles/mixins/_breakpoints.scss";
.letter-spacing {
  letter-spacing: 0.06rem;
}
.checkup-result-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 25rem;
  @include media-breakpoint-down(sm) {
    max-width: 12rem;
  }
}
</style>
