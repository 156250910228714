<template lang="pug">
div
  .mb-2(v-for="(policy, index) in passwordPolicy", :key="index")
    n-icon.align-sub.mr-2(name="check-circle", :size="1.1", :class="`text-${passwordPolicyStateMap[policy.state].icon}`")
    .d-inline-block.font-weight-medium.font-sm(:class="`text-${passwordPolicyStateMap[policy.state].text}`") {{ policy.text }}
</template>

<script>
export default {
  name: "PasswordPolicy",
  components: {},
  props: {
    password: {
      type: String,
    },
    passwordChangeSubmitted: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      passwordPolicyStateMap: {
        default: {
          icon: "gray-500",
          text: "gray-800",
        },
        valid: {
          icon: "teal-700",
          text: "gray-800",
        },
        error: {
          icon: "red-500",
          text: "red-500",
        },
      },
    };
  },
  computed: {
    passwordPolicy() {
      // states can be default, error, alert, valid
      const template = [
        {
          text: "Minimum 8, maximum 32 characters",
          regex: /^.{8,32}$/g,
          state: "default",
        },
        {
          text: "Upper Case letters (A-Z)",
          regex: /(?=.*[A-Z])/g,
          state: "default",
        },
        {
          text: "Lower Case letters (a-z)",
          regex: /(?=.*[a-z])/g,
          state: "default",
        },
        {
          text: "Minimum 1 number",
          regex: /.*[0-9].*/g,
          state: "default",
        },
        {
          text: "Minimum 1 special character (! @ # $ % ^ & *)",
          regex: /(?=.*[@$!%*#&^])/g,
          state: "default",
        },
      ];
      template.forEach((policy) => {
        if (this.password?.match(policy.regex)) {
          policy.state = "valid";
        } else if (this.passwordChangeSubmitted) {
          policy.state = "error";
        } else {
          policy.state = "default";
        }
      });
      return template;
    },
  },
  methods: {},
};
</script>

<style lang="scss">
@import "@/assets/styles/_variables.scss";
</style>
