<template lang="pug">
div(class="d-flex flex-row checkbox-container", :class="isEmergency")
  input.p-1.ml-3(type="checkbox",
   :id="fieldId",
   :disabled="isCheckBoxDisabled",
   v-model="checkBoxValue")
  label.text-gray-700.p-1.mt-2.ml-1.font-weight-medium.mr-3(:for="fieldId") {{label}}
</template>

<script>
export default {
  name: "NCheckboxInput",
  props: {
    name: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    value: {
      type: Boolean,
      default: false,
    },
    fieldId: {
      type: String,
      required: true,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    variant: {
      type: String,
      default: "danger",
    },
  },
  data() {
    return {
      emergency: "emergency",
      highlight: "highlight",
      defaultStyle: "default",
      invalidStyle: "invalid",
    };
  },
  computed: {
    isEmergency() {
      const invalidStyles = {
        defaultStyle: !this.value,
        invalid: this.value,
      };
      const emergencyStyles = {
        emergency: this.value,
        defaultStyle: !this.value,
      };

      const highlightStyles = {
        highlight: this.value,
        defaultStyle: !this.value,
      };

      if (this.variant === "danger") {
        return emergencyStyles;
      }

      if (this.variant === "highlight") {
        return highlightStyles;
      }

      return invalidStyles;
    },
    isCheckBoxDisabled() {
      return this.isDisabled;
    },
    checkBoxValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
@import "@/assets/styles/_typography.scss";
.checkbox-container {
  border: 2px solid $gray-400;
  border-radius: 5px;
  background: $white;
}

.emergency {
  border: 2px solid $red-400;
  border-radius: 6px;
  accent-color: $red-700;
}

.highlight {
  border: 2px solid $mustard-400;
  border-radius: 6px;
  accent-color: $mustard-600;
}

.default {
  border: 2px solid $gray-400;
  border-radius: 6px;
}
.invalid {
  accent-color: $teal-800;
  border: 2px solid $teal-400;
  border-radius: 6px;
}
</style>
