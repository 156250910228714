import gql from "graphql-tag";
import commentMutations from "../mutations/comments";
export default {
  gqlType: "Comments",
  ...commentMutations,
  getBatchComments: gql`
    query getBatchComments($batchId: ID!, $orgId: ID!) {
      getBatchComments(batchId: $batchId, orgId: $orgId) {
        edges {
          node {
            content
            role
            user {
              name
            }
            createdAt
            updatedAt
          }
        }
      }
    }
  `,
};
