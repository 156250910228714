import gql from "graphql-tag";
import { tpaFragment } from "./tpa";

export const tpaOfficeFragment = gql`
  fragment TpaOffice on TpaOffice {
    id
    tpa {
      ...Tpa
    }
    name
    address
    pincode
    status
  }
  ${tpaFragment}
`;
