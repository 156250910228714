<template lang="pug">
  ElementLayout
    template(#element)
      .d-flex.flex-column.p-3(role="list" :aria-labelledby="labelId" ref="list")
        div(v-for="(val, i) in value" :key="i", role="listitem", :data-id="`${path}-${i}`")
          b-link(v-b-toggle="`${path}-item-${i}`")
            .row.bg-light
              .col.p-2
                span {{ getAccordionTitle }} {{ i + 1 }}
                .p-2.position-absolute(style="right: 0; top: 0")
                  b-link.p-1(v-if="hasRemove",title="Remove", :aria-roledescription="form$.translations.vueform.a11y.list.remove", :id="`${path}-${i}-remove-button`" , 
                    @click.prevent="handleRemove(i)", 
                    @keypress.space.enter="handleRemove(i)")
                    i.fa.fa-close
          
          b-collapse.mb-2(visible, appear,:id="`${path}-item-${i}`")
            slot(:index="i")
              component(:is="component(prototype)", v-if="prototype.type", v-bind="prototype", :name="i", :key="i")
    

      n-button.mt-2(
        v-if="hasAdd",
        :id="`${fieldId}-add-button`",
        variant="primary",
        :buttonText="addLabel",
        @keypress.enter.space="handleAdd",
        @click="handleAdd",
        tabindex="0")

    template(v-for="(component, slot) in elementSlots" #[slot])
      slot(:name="slot", :el$="el$")
        component(:is="component", :el$="el$")
</template>

<script>
import { defineElement, ListElement } from "@vueform/vueform";
import NButton from "@/components/NovaButton.vue";

export default defineElement({
  ...ListElement,
  name: "CustomListElement",
  setup(props, context) {
    const element = ListElement.setup(props, context);
    return {
      ...element,
    };
  },
  components: {
    NButton,
  },
  data() {
    return {
      accordionTitle: null,
      defaultClasses: {
        container: "",
        input: "form-text-input",
        input_danger: "has-errors",
        $input: (classes, { isDanger }) => [classes.input, isDanger ? classes.input_danger : null],
      },
    };
  },
  computed: {
    getAccordionTitle() {
      return this.$attrs?.accordionTitle ? this?.$attrs?.accordionTitle : "Title";
    },
  },
});
</script>

<style lang="scss">
.form-text-input {
  border: 1px solid black;
  outline: none;
  width: 100%;

  &.has-errors {
    border: 1px solid red;
  }
}
</style>
