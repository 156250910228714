export const UserChangeBatchStages = {
  ENROLLMENT_PENDING: "enrollment-pending",
  UNAPPROVED: "unapproved",
  APPROVED: "approved",
  REJECTED: "rejected",
  ORG_OK: "org-ok",
  NOVA_OK: "nova-ok",
  PROVIDER_OK: "provider-ok",
  DONE: "done",
};
