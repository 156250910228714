import { render, staticRenderFns } from "./Dependent.vue?vue&type=template&id=39122465&scoped=true&lang=pug"
import script from "./Dependent.vue?vue&type=script&lang=js"
export * from "./Dependent.vue?vue&type=script&lang=js"
import style0 from "./Dependent.vue?vue&type=style&index=0&id=39122465&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "39122465",
  null
  
)

export default component.exports