import { ClaimStage } from "../common/enums/claims/claimStage";
import { QueryStatus } from "../common/enums/claims/queryStatus";

export default {
  /** Assuming that a claim that has a query will definitely (only) be in one of the following stages */
  getOpenQuery(queries, claimStage) {
    if (
      [
        ClaimStage.CLAIM_UNDER_QUERY,
        ClaimStage.CLAIM_UNDER_QUERY_2,
        ClaimStage.CLAIM_UNDER_QUERY_REMINDER_1,
        ClaimStage.CLAIM_UNDER_QUERY_REMINDER_2,
      ].includes(claimStage)
    ) {
      return queries?.find((query) => query.status === QueryStatus.OPEN) || null;
    }

    return null;
  },
};
