<template lang="pug">
.mx-auto.text-center.m-4.p-4.col-md-6
  img.mt-4(v-if="!isFilterSet", :src="require('@/assets/images/support-pin-logo.svg')")
  h1.mt-4.font-xxl.font-weight-extrabold(v-else) ✨
  div
    .text-gray-700.font-weight-extrabold.font-xl.mt-4 Nothing to show here
    .text-gray-500.font-weight-medium.font-md.mt-3(
      v-if="!isFilterSet")
      | You have not raised any tickets so far. Any Support tickets you raise will be shown here.
    .text-gray-500.font-weight-medium.font-md.mt-2(v-else)
      | It seems you don't have any tickets raised in the current filter. Try adjusting your filters to view tickets.
  n-button.mt-4(
    v-if="!isFilterSet",
    buttonText="Need Help?",
    variant="dark",
    size="lg",
    @click="showChat")
</template>

<script>
import NButton from "@/components/NovaButton.vue";
export default {
  name: "ZeroState",
  components: {
    NButton,
  },
  props: {
    isFilterSet: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    showChat() {
      this.$store.commit("toggleSupportContainer");
    },
  },
};
</script>

<style lang="scss" scoped></style>
