<template lang="pug">
div
  span.pr-2.align-sub(
    v-if="schema.icon",
    :class="[`icon-${schema.icon}`, `text-${schema.iconColor}`, schema.iconOpacity?`ic-${schema.iconOpacity}`:'']")
  template(v-if="schema.chip && schema.chip.text")
    div.d-flex.justify-content-between.align-items-center
      span.h5.field-label.font-lg(v-if="schema.fieldLabel") {{ schema.fieldLabel }}
      nova-chip( :variant="schema.chip.variant") {{ schema.chip.text }}
  template(v-else)
    span.h5.field-label.font-lg(v-if="schema.fieldLabel") {{ schema.fieldLabel }}
  select-cards.pb-3(v-model="value", v-bind="schema", :validationMessage="validationMessage", :state="state",)
</template>

<script>
import { abstractField } from "vue-form-generator";
import SelectCards from "../SelectCards.vue";
import NovaChip from "../NovaChip.vue";

export default {
  components: {
    SelectCards,
    NovaChip,
  },
  mixins: [abstractField],
  computed: {
    validationMessage() {
      if (this.errors.length > 0) {
        for (const err of this.errors) {
          if (typeof err === "string") return err;
          else if (typeof err === "object" && !err.isWarning) return err.message;
        }
        return this.errors?.[0].message || "";
      }
      return "";
    },
    state() {
      let hasWarning = false;
      for (const err of this.errors) {
        if (typeof err === "string" || (typeof err === "object" && !err.isWarning)) return "error";
        else if (typeof err === "object" && err.isWarning) hasWarning = true;
      }
      return hasWarning ? "warning" : "";
    },
  },
};
</script>