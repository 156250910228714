import { startCase } from "lodash-es";
import { getFilteredListQuery, getSingleQuery } from "../queries";
import benefitsSchema from "../schemas/benefits";
import benefitsMutations from "../mutations/benefits";

export default {
  gqlType: "Benefit",
  fields: ["name", "type", "sectionName", "categoryName", "subCategory"],
  singleQuery: getSingleQuery("Benefit"),
  listQuery: getFilteredListQuery("Benefit"),
  ...benefitsSchema,
  ...benefitsMutations,
  transform: (n, write = false) => {
    let policySpecificInfo = {};
    if (n.insurer) {
      policySpecificInfo = {
        insurerId: n.insurer.id,
        insurerName: n.insurer.name,
        isPolicy: true,
      };
    } else {
      n.isPolicy = false;
    }
    if (n.content?.meta?.id) {
      n.content = {
        ...n.content,
        id: n.content?.meta?.id,
        name: `${n.content?.slug} (${n.content?.meta?.id})`,
      };
    }
    if (n.coverageText?.meta?.id) {
      n.coverageText = {
        ...n.coverageText,
        id: n.coverageText?.meta?.id,
        name: `${n.coverageText?.slug} (${n.coverageText?.meta?.id})`,
      };
    }
    if (!write) {
      n.sectionName = startCase(n.section);
      n.categoryName = startCase(n.category);
    }
    return {
      ...n,
      ...policySpecificInfo,
      meta: {
        ...n.meta,
        strapiId: n.content?.meta?.id || n.content?.id,
        coverageTextStrapiId: n.coverageText?.meta?.id || n.coverageText?.id,
      },
    };
  },
  getEdgesFromData: (data) => data.benefits.edges,
  getTotalNumberOfPolicies: (benefits) => {
    let count = 0;
    benefits.forEach((benefit) => {
      if (benefit.node.isPolicy) {
        count++;
      }
    });
    return count;
  },
  getTotalNumberOfBenefits: (benefits) => {
    let count = 0;
    benefits.forEach((benefit) => {
      if (!benefit.node.isPolicy) {
        count++;
      }
    });
    return count;
  },
};
