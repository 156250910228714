import { render, staticRenderFns } from "./ClaimDetails.vue?vue&type=template&id=aac00fd4&scoped=true&lang=pug"
import script from "./ClaimDetails.vue?vue&type=script&lang=js"
export * from "./ClaimDetails.vue?vue&type=script&lang=js"
import style0 from "./ClaimDetails.vue?vue&type=style&index=0&id=aac00fd4&prod&lang=scss"
import style1 from "./ClaimDetails.vue?vue&type=style&index=1&id=aac00fd4&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "aac00fd4",
  null
  
)

export default component.exports