import { getListQuery } from "../../queries";
import { validateInlineSelect, validateIndianMobileNumbersForVfg } from "@/utils/validators";

export default {
  schema: {
    fields: [
      {
        model: "patient",
        type: "inline-select",
        label: `Patient Name<span style="color:red">*</span>`,
        validator: [validateInlineSelect],
        required: true,
        placeholder: "Select patient",
        styleClasses: "d-inline-block col-md-6",
        // options: [],
        disabled: false,
        getSubTitle: (value) => (value.status === "deleted" ? "deleted" : null),
      },
      {
        model: "meta.secondaryEmail",
        type: "input",
        inputType: "email",
        styleClasses: "d-inline-block col-md-6",
        label: "Email ID for this Claim",
        placeholder: "-",
      },
      {
        model: "policy",
        type: "inline-select",
        queryName: "benefits",
        gqlQuery: getListQuery("Benefit"),
        styleClasses: ["d-inline-block col-md-6"],
        queryVariables: () => ({
          filter: {
            userId: "",
            isPolicy: true,
          },
        }),
        label: `Policy Name<span style="color:red">*</span>`,
        placeholder: "-None-",
        required: true,
        showSelected: false,
        validator: [validateInlineSelect, "required"],
        disabled: true,
      },
      {
        model: "meta.contact",
        type: "inline-input",
        inputType: "tel",
        label: `Phone Number for this Claim<span style="color:red">*</span>`,
        validator: ["required", validateIndianMobileNumbersForVfg],
        styleClasses: "d-inline-block col-md-6",
        required: true,
      },
      {
        model: "policy.meta.policyNumber",
        type: "input",
        inputType: "text",
        styleClasses: "d-inline-block col-md-6",
        label: "Policy Number",
        placeholder: "-",
        readonly: true,
      },
      {
        model: "patient.relation",
        type: "inline-select",
        label: `Relation to Primary<span style="color:red">*</span>`,
        placeholder: "-None-",
        styleClasses: "d-inline-block col-md-6",
        readonly: true,
        disabled: true,
      },
      {
        model: "uhid",
        type: "inline-input",
        inputType: "text",
        styleClasses: "d-inline-block col-md-6",
        label: "UHID of Patient",
        placeholder: "-",
        readonly: true,
      },
      {
        model: "patient.gender",
        type: "select-cards",
        label: "Patient Gender",
        styleClasses: "d-inline-block col-md-6",
        readOnly: true,
        required: true,
        cardsData: [
          {
            name: "gender",
            icon: "female",
            label: "Female",
            card_value: "female",
          },
          {
            name: "gender",
            icon: "male",
            label: "Male",
            card_value: "male",
          },
        ],
      },
      {
        model: "nbNumber",
        type: "input",
        inputType: "text",
        styleClasses: "d-inline-block col-md-6",
        label: `Nova Claim Identifier<span style="color:red">*</span>`,
        placeholder: "-",
        showSelected: false,
        readonly: true,
        required: true,
      },
      {
        model: "meta.crm.tpaClassification",
        type: "input",
        inputType: "text",
        styleClasses: "d-inline-block col-md-6",
        label: "TPA Classification",
        placeholder: "Medi Assist / Non Medi Assist",
        readonly: true,
      },
      {
        model: "policy.insurer.name",
        type: "input",
        inputType: "text",
        styleClasses: "d-inline-block col-md-6",
        label: "Insurer",
        placeholder: "-",
        showSelected: false,
        readonly: true,
      },

      {
        model: "meta.crm.tpa",
        type: "id-selector",
        styleClasses: "d-inline-block col-md-6",
        queryName: "tpas",
        gqlQuery: getListQuery("Tpa"),
        label: "TPA Name",
        placeholder: "For Non Medi Assist",
        showSelected: false,
        disabled: true,
      },
    ],
  },
};
