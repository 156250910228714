import { getListQuery } from "../queries";

const TpaOfficeStatuses = ["ACTIVE", "INACTIVE"];

export default {
  schema: {
    fields: [
      {
        model: "tpa",
        type: "id-selector",
        queryName: "tpas",
        gqlQuery: getListQuery("Tpa"),
        label: "Tpa",
      },
      {
        model: "name",
        type: "input",
        inputType: "text",
        label: "Branch Name",
        required: true,
      },
      {
        model: "address",
        type: "input",
        inputType: "text-area",
        label: "Branch Address",
        rows: 3,
        required: true,
      },
      {
        model: "pincode",
        type: "input",
        inputType: "text",
        validator: "regexp",
        pattern: "^[1-9]{1}[0-9]{2}\\s{0,1}[0-9]{3}$",
        label: "Pincode",
        required: true,
      },
      {
        model: "status",
        type: "select",
        values: TpaOfficeStatuses,
        label: "Status",
      },
    ],
  },
};
