<template lang="pug">
  .d-flex.flex-column.w-100.justify-center
    template(v-if="isMobileView")
      b-card.px-5.py-10(no-body)
        .row.justify-content-center.my-2
          n-avatar.shadow-none(:image="require('@/assets/images/endorsement-mobile.svg')", :size="8")
        .row.justify-content-center
          h4.font-weight-semibold Experience it on a desktop!
          p.text-gray-700.font-sm.text-center.px-3 You can utilize this feature more effectively on a desktop.
        .row.justify-content-center
          n-button(buttonText="Go Back", size="lg", @click="$router.go(-1)")
    template(v-else-if="loadingBatchData")
      b-skeleton-wrapper
            .d-flex.my-3
              b-skeleton(width="23.75rem", height="4.5rem")
              b-skeleton.ml-auto(width="12.5rem", height="4.5rem")
            b-skeleton.mb-4(height="8rem")
            b-skeleton-table(type="input", :rows=5, :columns=5, :table-props="{bordered: true}")
    template(v-else)
      n-breadcrumb(
            v-bind="breadcrumbData",
            :currentName="getSelectedBatchMonthYear"
          )
      summary-card(:batchInfo="currentBatchInfo", :policyNames="getOrgPolicyName()",:orgName="this.org.name",
      :documents="documents", :premiumAmount="getUpdatedPremiumAmount()")
      status-card(:steps="steps")
      .overview-table-wrapper.bg-white
        .search-field
          n-search-bar.col-md-6(
            v-model="searchQuery",
            :placeholder="searchFieldPlaceholder",
            )
        user-changes-list-table(
          :items="selectedTabData.changes",
        )
          template(v-slot:pagination, v-if="selectedTabData.count > pageSize")
              .d-flex.justify-content-between
                .d-flex.align-items-center.ml-2.text-gray-800.font-sm.font-weight-medium
                  | Showing {{ getOffset(currentPage) + 1 }}-{{ getRelevantRowCount() }}
                  span &nbsp;out of {{ selectedTabData.count }}
                  | &nbsp;records
                n-pagination(
                  v-model="currentPage",
                  :total-rows="selectedTabData.count",
                  :per-page="pageSize",
                  align="right"
                )
          template(v-slot:empty-state)
            empty-states(type="Endorsements",tableType="", :hasNoSearchResults="searchQuery?.length > 0")
</template>

<script>
import { mapGetters } from "vuex";
import resDefs from "../../admin/definitions";
import { UserChangeStatus } from "../../../../common/enums/userChangeStatus.enum";
import orgAdminResDefs from "../../orgAdmin/definitions";
import { EndorsementHelper } from "../helper";
import mixin from "../mixin";
import SummaryCard from "./components/SummaryCard.vue";
import StatusCard from "./components/StatusCard.vue";
import UserChangesListTable from "./components/UserChangesListTable.vue";
import utils from "@/utils";
import NButton from "@/components/NovaButton.vue";
import NAvatar from "@/components/Avatar.vue";
import NBreadcrumb from "@/components/NovaBreadcrumb.vue";
import NSearchBar from "@/components/NovaSearchBar.vue";
import EmptyStates from "@/components/Cards/EmptyStates/EmptyStateCard.vue";

export default {
  name: "EndorsementOverview",
  components: {
    NButton,
    NAvatar,
    NBreadcrumb,
    SummaryCard,
    StatusCard,
    UserChangesListTable,
    NSearchBar,
    EmptyStates,
  },
  mixins: [mixin],
  data() {
    const resName = "userChanges";
    const orgAdminResDef = orgAdminResDefs[resName];
    return {
      searchFieldPlaceholder: "Search for an employee or their dependent by name or email ID",
      loadingBatchData: false,
      orgId: null,
      batchId: this.$route.params.batchId,
      orgEntityId: null,
      currentBatchInfo: null,
      orgAdminResDef,
      batchDocuments: null,
      benefitNameToIdMapping: {},
      searchQuery: "",
      currentPage: 1,
      org: null,
      steps: null,
      documents: {
        endorsement: [],
      },
      allClubbedChangesCount: {},
      pageSize: 10,
      selectedTabData: {
        changes: [],
        count: 0,
        segregatedCount: this.getSegregatedCount(),
        obPendingCount: 0,
      },
      breadcrumbData: {
        rootName: "Endorsements",
        rootUrl: "/org-admin/changes",
        rootIcon: "stroke-endorsements-3",
      },
    };
  },
  computed: {
    ...mapGetters(["selectedOrgEntity"]),
    isEndorsementPremiumPresent() {
      return !!(
        this.currentBatchInfo?.endorsementPremium && Object.keys(this.currentBatchInfo?.endorsementPremium).length > 0
      );
    },
    isBatchCompleted() {
      return this.currentBatchInfo?.status === "completed";
    },
    isMobileView() {
      return utils.mobileCheck();
    },
    batchStatus() {
      if (this.currentBatchInfo?.status) {
        return this.currentBatchInfo.status;
      }
    },
    isSuperAdmin() {
      return !this.$route.path.includes("org-admin/");
    },
    getSelectedBatchMonthYear() {
      const monthYear = utils.getMonthYear(this.currentBatchInfo?.startingAt) || "";
      return monthYear;
    },
    currentBatchPolicies() {
      const userChanges = utils.deepClone(this.selectedTabData.changes);
      const policies = new Set();
      userChanges.forEach((change) => {
        change.user_changes.forEach((userChange) => {
          if (userChange.isPolicy) {
            policies.add(userChange.benefit_id);
          }
        });
      });
      return Array.from(policies);
    },
  },
  watch: {
    currentPage: function () {
      if (this.$apollo.queries[this.batchStatus]) {
        this.$apollo.queries[this.batchStatus].fetchMore({
          variables: {
            offset: this.getOffset(this.currentPage),
          },
          updateQuery: (previousResult, { fetchMoreResult }) => {
            const transformedClubbedData = utils.deepClone(
              this.orgAdminResDef.transformGetClubbedUserChanges(fetchMoreResult)
            );
            this.selectedTabData.changes = transformedClubbedData.changes;
            this.selectedTabData.count = transformedClubbedData.totalCount;
            this.selectedTabData.segregatedCount = transformedClubbedData.segregatedCount;
            this.selectedTabData.obPendingCount = transformedClubbedData.obPendingCount;
          },
        });
      }
    },
    searchQuery: async function (value) {
      const variables = await this.$apollo?.queries[this.batchStatus]?.options?.variables();
      if (variables) {
        variables.filter.query = value;
      }
      this.$apollo.queries[this.batchStatus].refetch();
    },
  },
  async created() {
    this.loadingBatchData = true;
    this.currentBatchInfo = await this.fetchCurrentBatchInfo();
    this.orgId = this.currentBatchInfo?.orgId;
    this.allClubbedChangesCount = (await this.getAllChangesCount()) || {};
    this.org = await this.fetchOrgDetails();
    this.steps = await this.fetchEndorsementStagesStatuses();
    this.batchDocuments = await this.fetchOrgDocuments();
    this.fetchDataForActiveScreen();
    this.loadingBatchData = false;
  },
  methods: {
    getUpdatedPremiumAmount() {
      if (!this.isRaterBaseConfigPresent || (this.isBatchCompleted && !this.isEndorsementPremiumPresent)) {
        return "N/A";
      }
      return this.totalPremiumAcrossInsurers();
    },
    totalPremiumAcrossInsurers() {
      const premiumData = this.isEndorsementPremiumPresent
        ? this.currentBatchInfo.endorsementPremium
        : this.currentBatchInfo?.meta?.premiumData?.policyWisePremiumEstimation;
      return utils.getTotalPremiumAcrossInsurers(premiumData, false);
    },
    getRelevantRowCount() {
      return Math.min(this.selectedTabData.count, this.getOffset(this.currentPage) + this.pageSize);
    },
    fetchDataForActiveScreen() {
      const variables = {
        limit: this.pageSize,
        filter: {
          orgId: this.orgId,
          userChangesStatuses: [],
          query: this.searchQuery,
        },
      };
      const clubbingData = this.batchStructure();
      clubbingData.variables = utils.deepClone(variables);
      clubbingData.variables.filter.clubbingType = this.batchStatus;
      clubbingData.variables.filter.userChangesStatuses = [
        UserChangeStatus.DRAFT,
        UserChangeStatus.ORG_OK,
        UserChangeStatus.NOVA_OK,
        UserChangeStatus.PROVIDER_OK,
        UserChangeStatus.DONE,
        UserChangeStatus.REJECTED,
      ];
      clubbingData.variables.filter.batchIds = [this.batchId];
      this.addSmartQueryForTable(this.batchStatus, clubbingData);
    },
    getSegregatedCount() {
      return {
        segregatedCount: {
          add: 0,
          delete: 0,
          update: 0,
        },
      };
    },
    batchStructure() {
      return EndorsementHelper.generateBatchStructure(
        this.batchStatus,
        this.isSuperAdmin,
        this.$route.params.tabName,
        this.getDueDate().dateString,
        this.allClubbedChangesCount
      );
    },
    getEndoDocsWithPolicyName(endorsementDocs) {
      const uploadedDocuments = [];

      if (!endorsementDocs || !Object.keys(endorsementDocs).length) {
        return uploadedDocuments;
      }

      if (Object.keys(this.benefitNameToIdMapping || {})?.length === 0) this.getOrgPolicyName();
      Object.keys(endorsementDocs || {}).forEach((policyId) => {
        for (const [docType, file] of Object.entries(endorsementDocs[policyId])) {
          file.policyName = Object.keys(this.benefitNameToIdMapping).find(
            (name) => this.benefitNameToIdMapping[name] === policyId
          );
          file.docType = docType;
          uploadedDocuments.push(file);
        }
      });

      return uploadedDocuments;
    },
    async getAllChangesCount() {
      const { data } = await this.$apollo.query({
        query: this.orgAdminResDef.getClubbedUserChangesCount,
        fetchPolicy: "no-cache",
        variables: {
          filter: {
            orgId: this.orgId,
            orgEntityId: this.orgEntityId,
            batchIds: [this.batchId],
          },
        },
      });
      return data.getClubbedUserChangesCount?.countByBatchStage;
    },
    getDueDate() {
      const dueDate = this.currentBatchInformation?.endingAt;
      return {
        dateString: utils.getDateWithSuffix(dueDate),
        remainingDays: `${utils.getSingularOrPlural("Day", utils.getDateDifference(dueDate))}`,
      };
    },
    getOrgPolicyName() {
      const userChanges = utils.deepClone(this.selectedTabData.changes);
      const policies = new Set();
      userChanges.forEach((change) => {
        change.user_changes.forEach((userChange) => {
          if (userChange.isPolicy) {
            this.benefitNameToIdMapping[`[${userChange.benefitsType}]-${userChange.benefitName}`] = userChange.benefit_id;
            policies.add(userChange.benefitName);
          }
        });
      });
      return Object.keys(this.benefitNameToIdMapping);
    },
    addSmartQueryForTable(queryName, clubbingData, callback) {
      clubbingData.variables.offset = this.getOffset(this.currentPage);
      this.$apollo.addSmartQuery(queryName, {
        query: this.orgAdminResDef.getClubbedUserChanges,
        variables: () => clubbingData.variables,
        fetchPolicy: "no-cache",
        skip: () => {
          return !this.batchId;
        },
        update: (data) => {
          const transformedClubbedData = utils.deepClone(this.orgAdminResDef.transformGetClubbedUserChanges(data));
          this.selectedTabData.changes = transformedClubbedData.changes;
          this.selectedTabData.count = transformedClubbedData.totalCount;
          this.selectedTabData.segregatedCount = transformedClubbedData.segregatedCount;
          this.selectedTabData.obPendingCount = transformedClubbedData.obPendingCount;
          this.documents.endorsement = this.getEndoDocsWithPolicyName(this.batchDocuments);
          if (callback) callback();
        },
      });
    },
    getOffset(currentPage, pageSize = 10) {
      return (currentPage - 1) * pageSize;
    },
    async fetchCurrentBatchInfo() {
      const result = await this.$apollo.query({
        query: resDefs.userChangeBatches.singleQuery,
        variables: {
          id: this.batchId,
        },
      });
      return result.data.node;
    },
    async fetchEndorsementStagesStatuses() {
      const result = await this.$apollo.query({
        query: resDefs.userChangeBatches.getEndorsementStageStatuses,
        variables: {
          batchId: this.batchId,
        },
      });
      return result.data.getEndorsementStageStatuses;
    },
    async fetchOrgDetails() {
      const result = await this.$apollo.query({
        query: resDefs.orgs.singleQuery,
        variables: {
          id: this.orgId,
        },
      });

      return result.data.node;
    },
    async fetchOrgDocuments() {
      const result = await this.$apollo.query({
        query: resDefs.userChangeBatches.getEndorsementBatchDocuments,
        variables: {
          batchId: this.batchId,
        },
      });
      return result.data.getEndorsementBatchDocuments;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
@import "@/assets/styles/_mixins.scss";
.search-field {
  padding: 1rem 0.5rem 0.5rem;
  width: 100%;
  border-left: 1px solid $gray-300;
  border-right: 1px solid $gray-300;
}
.overview-table-wrapper {
  border: 1px solid $gray-300;
  border-radius: 6px;
  box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.04);
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.06);
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.04);
  .nova-table-wrapper {
    border: none;
  }
}
</style>
