<template lang="pug">
  b-modal#export-changes-modal(
    size="lg",
    centered,
    ok-title="Execute >",
    header-class="py-2",
    ok-variant="dark",
    cancel-variant="light",
    cancel-title="Done"
    button-size="sm",
    @ok.prevent="onSubmit",
    @cancel="clearModel"
  )
    template(#modal-header)
      .w-100.font-lg.text-gray-900.font-weight-semibold.text-center.py-0
        span Exporting Changes
    .row
      .col-12
          b-form.row(:enctype="'multipart/form-data'")
            .col-12
              label Select configuration
            .col-12
              div(v-if="cmdDef")
                vue-form-generator(:schema="cmdDef.schema", :model="cmdModel")
          .text-center(v-if="loading")
            b-spinner
      .col-12(v-if="job && job.id")
        h5 Job status
          b-button.ml-1(size="sm", @click="refreshJob") Refresh
        pre.
          Status: {{job.status}}
          Result: {{job.result}}
        pre(style="max-height: 500px; white-space: pre-wrap;").
          Logs:
          > {{job.logs.join("\n> ")}}
</template>

<script>
import resDefs from "../../admin/definitions";
import { getSingleQuery } from "../../admin/queries";
export default {
  name: "ExportChangesModal",
  props: {
    orgId: {
      type: String,
      default: "",
      required: true,
    },
    currentTab: {
      type: String,
      default: "",
    },
    selectedBatchId: {
      type: String,
      default: "",
    },
    isInsurerSpecificFormat: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const cmdName = "exportChanges";
    return {
      job: {},
      cmds: resDefs.cliTools.cmdsAdminReviewChanges,
      cmdName,
      resDef: resDefs.cliTools,
      cmdModel: {
        orgId: this.orgId,
      },
      loading: false,
    };
  },
  computed: {
    cmdDef() {
      return resDefs.cliTools.cmdsAdminReviewChanges[this.cmdName];
    },
  },
  methods: {
    async refreshJob() {
      const result = await this.$apollo.query({
        query: getSingleQuery("Job"),
        variables: { id: this.job.id },
        fetchPolicy: "no-cache",
      });
      this.job = result.data.node;
    },
    clearModel() {
      this.job = null;
      this.cmdModel.uploadToCloud = null;
    },
    async onSubmit() {
      try {
        this.loading = true;
        const variables = {
          cmdName: this.cmdName,
          args: {
            ...this.cmdModel,
            isInsurerSpecificFormat: this.isInsurerSpecificFormat || false,
            changeStatus: this.currentTab
              ? this.currentTab === "unapproved"
                ? ["draft", "archive"]
                : [this.currentTab]
              : ["draft", "org-ok", "nova-ok", "done", "rejected"],
            batchId: this.selectedBatchId,
          },
        };
        const result = await this.$apollo.mutate({
          context: {
            hasUpload: true, // Important!
          },
          mutation: this.resDef.upsertMutation,
          variables,
        });
        this.job = result.data.invokeCliTool.job;
        this.loading = false;
      } catch (e) {
        this.loading = false;
        console.log(e);
      }
    },
  },
};
</script>
