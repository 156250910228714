import gql from "graphql-tag";

export const echoPostTemplateFragment = gql`
  fragment EchoPostTemplate on EchoPostTemplate {
    id
    org {
      id
      name
    }
    category
    subject
    defaultMessage
    logo {
      id
      url
      name
    }
    image {
      id
      url
      name
    }
    meta
    status
    updatedBy {
      id
      name
    }
  }
`;
