<template lang="pug">
.stats-card-container
  b-card.h-100.shadow-md.bg-alabaster.overflow-hidden(no-body, :class="getBorderRadiusClass")
    .p-3.d-flex.justify-content-between.align-items-center(v-if="!withImage",
      :class="withTopBorder ? ['border-top', 'border-5', `border-${variant}`]: ''")
      div
        .d-flex.align-items-center
          div(v-if="!mutedTitle")
            i.font-xs.mr-2(:class="[`icon-${icon}`, `text-${variant}`]")
          span.font-sm.font-weight-semibold(:class="mutedTitle ?  'text-gray-700': 'text-gray-900'")  {{ title }}
            i.icon-question.cursor-pointer.font-xs.text-gray-500.ml-1(v-b-tooltip.hover, :title="getToolTipTitle")
        .mt-1.text-gray-700.font-sm.font-weight-medium(v-if="subTitle") {{ subTitle }}
        .mt-1(v-else-if="link")
          b-link.text-gray-700(:href="link.url") {{link.text}}
      .h3.mb-0.mx-1.mt-2 {{value}}
    .d-flex.align-items-center(v-else)
      .calendar-bg.position-relative
        .text-white
          span.position-absolute.endorsbatch-caltext-1.font-weight-semibold.font-lg {{ dateOnCalendar }}
          span.position-absolute.endorsbatch-caltext-2.font-weight-semibold {{ monthOnCalendar }}
        img.p-3(:src="require('@/assets/images/calendar-3.svg')")
      .my-2
        .my-1.font-weight-semibold.font-md.text-gray-900.d-flex.align-items-center {{title}}
          i.icon-chevron-right.text-gray-500.ml-1
        .text-blackpearl.font-xs.my-1 {{subTitle}}
          i.icon-question.cursor-pointer.font-xs.text-gray-500.ml-1(v-b-tooltip.hover, :title="getToolTipTitle")
        .text-gray-800.font-xs.font-weight-medium {{subSubTitle}}
</template>

<script>
import utils from "@/utils";
export default {
  name: "StatsCard",
  props: {
    id: {
      type: Number,
      default: 0,
    },
    icon: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
    subTitle: {
      type: String,
      default: "",
    },
    subSubTitle: {
      type: String,
      default: "",
    },
    value: {
      type: Number,
      default: 20,
    },
    variant: {
      type: String,
      default: "",
    },
    link: {
      type: Object,
      default: () => {}, // {text: "", url: ""}
    },
    mutedTitle: {
      type: Boolean,
      default: false,
    },
    withTopBorder: {
      type: Boolean,
      default: false,
    },
    withImage: {
      type: Boolean,
      default: false,
    },
    dateOfInterest: {
      type: Date,
      default: () => new Date(),
    },
  },
  computed: {
    monthOnCalendar() {
      return utils.getMonthShortForm(
        utils.getMonth(this.dateOfInterest).toLowerCase()
      );
    },
    dateOnCalendar() {
      return utils.getDate(this.dateOfInterest);
    },
    getBorderRadiusClass() {
      if (this.withTopBorder) {
        return "";
      } else if (this.id === 0) {
        return ["rounded-left", "rounded-right-0"];
      } else if (this.id === 3) {
        return ["rounded-right", "rounded-left-0"];
      } else {
        return "rounded-0";
      }
    },
    getToolTipTitle() {
      let toolTipTitle = "";
      switch (this.title) {
        case "Additions":
          toolTipTitle = "New employees and dependents added.";
          break;
        case "Deletions":
          toolTipTitle = "Employees and dependents removed.";
          break;
        case "Data Changes":
          toolTipTitle =
            "Employees or dependents with any changes in their existing records.";
          break;
        case "Total Ongoing Changes":
          toolTipTitle =
            "All changes for the month, including additions and deletions of employees or dependents, and any updates to their existing records.";
          break;
        default:
          toolTipTitle =
            "The endorsements are processed once a month, you can finalize all the updates before the due date.";
      }
      return toolTipTitle;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
@import "@/assets/styles/mixins/_breakpoints.scss";
.stats-card-container {
  a {
    text-decoration: underline;
    cursor: pointer;
  }
  a:hover {
    color: $gray-700;
  }
  .card-no-border {
    border-radius: 0;
  }
  .rounded-right-0 {
    border-top-right-radius: 0rem !important;
    border-bottom-right-radius: 0rem !important;
  }
  .rounded-left-0 {
    border-top-left-radius: 0rem !important;
    border-bottom-left-radius: 0rem !important;
  }
}

.calendar-bg {
  background-image: url("~@/assets/images/calendar-bg.svg");
  background-repeat: no-repeat;
}

.endorsbatch-caltext-1 {
  top: 32%;
  left: 37%;
}
.endorsbatch-caltext-2 {
  font-size: 6px;
  top: 55%;
  left: 43%;
}
</style>
