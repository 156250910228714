import { getListQuery, getSingleQuery } from "../queries";
import orgONboardingStageTaskUpdatesMutations from "../mutations/orgOnboardingStageTaskUpdates";

export default {
  gqlType: "OrgOnboardingStageTaskUpdate",
  listQuery: getListQuery("OrgOnboardingStageTaskUpdate"),
  singleQuery: getSingleQuery("OrgOnboardingStageTaskUpdate"),
  ...orgONboardingStageTaskUpdatesMutations,
  transform: (n) => {
    return {
      ...n,
    };
  },
};
