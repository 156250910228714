import gql from "graphql-tag";

export const claimFilesFragment = gql`
  fragment ClaimFiles on ClaimFiles {
    id
    claimId
    fileDetails {
      id
      name
      url
      meta
      createdAt
    }
    docType
    isUploaded
    createSource
    status
    meta
    createdAt
    updatedAt
  }
`;
