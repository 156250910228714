<template lang="pug">
.mobile-container.border-top-gray-400

  .toggle-logo
    i(:class="[`opacity-sort`, { 'disabled': isPullUpDisabled() }]", @click="emitToggleClicked()")

  div.py-4(v-if="!hidePremium()")
    .text-gray-700.text-center.font-sm.mx-2
      h6 {{ SUMMARY_CONTENT_MOBILE?.[currentStep]?.bodyText1 }}
      div(v-if="SUMMARY_CONTENT_MOBILE?.[currentStep]?.bodyText2")
        br
        h6 {{ SUMMARY_CONTENT_MOBILE?.[currentStep]?.bodyText2 }}

  div(v-if="isPullUpOpen")
    .bs-hero(:class="[heroImageSize  ? `hero-img-${heroImageSize}`: '']")
      img.hero(:src="require('@/assets/images/confirm-choice.png')")
      div.hero-content
        div.hero-title.font-xs Your super top-up
        div.hero-subtitle.hb4 Premium Details

    div.py-4.px-3(v-if="isNovaPlusSelected")
      .d-flex.align-items-center.mt-1.mb-2.w-100
        .text-gray-900.font-sm.font-weight-semibold.flex-grow-1 
          | Nova Plus&nbsp;
          i.font-sm.icon-question(:id="`plus-popup`")
          premium-popover(:popover-id="'plus-popup'" :lives="getAllSelectedPlusMembers()", :trigger="'click'")
      .d-flex.align-items-center.my-1.w-100.text-gray-800.font-weight-medium
        .font-md.flex-grow-1 Premium
        .inr-value.font-md ₹ {{ getFormattedINRWithFloatingPoint(novaPlusPremiumDetails?.premium) || 0}}
      .d-flex.align-items-center.my-1.w-100.text-gray-800.font-weight-medium
        .font-md.flex-grow-1 GST
        .inr-value.font-md ₹ {{ getFormattedINRWithFloatingPoint(novaPlusPremiumDetails?.gst) || 0}}

    div(v-if="isNovaExtendedSelected")
        
      hr.border-top-dashed.my-0.mx-3

      div.py-4.px-3
        .d-flex.align-items-center.mt-1.mb-2.w-100
          .text-gray-900.font-sm.font-weight-semibold.flex-grow-1 
            | Nova Extended&nbsp;
            i.font-sm.icon-question(:id="`extended-popup`")
            premium-popover(:popover-id="'extended-popup'" :lives="getAllSelectedExtendedMembers()", :trigger="'click'")
        .d-flex.align-items-center.my-1.w-100.text-gray-800.font-weight-medium
          .font-md.flex-grow-1 Premium
          .inr-value.font-md ₹ {{getFormattedINRWithFloatingPoint(novaExtendedPremiumDetails?.premium) || 0}}
        .d-flex.align-items-center.my-1.w-100.text-gray-800.font-weight-medium
          .font-md.flex-grow-1 GST
          .inr-value.font-md ₹ {{getFormattedINRWithFloatingPoint(novaExtendedPremiumDetails?.gst) || 0}}

    hr.border-top-dashed.m-0

  div.pt-4.px-3(v-if="hidePremium()")
    .d-flex.align-items-center.my-1.w-100.text-gray-800.font-weight-medium
      .font-md.flex-grow-1 Total Premium
      .inr-value.font-xl.text-teal-800.font-weight-semibold ₹ {{getFormattedINRWithFloatingPoint(totalPremium)}}
    hr.border-top-dashed

  n-button.mx-2.mt-2.pb-2.mb-8.px-auto.fixed-bottom(:buttonText="buttonText || 'Confirm & Continue ->'", variant="dark", @click="nextAction()")


  div.px-3.d-flex.align-items-center(v-if="showInsurer()")
    span.text-gray-800.font-sm.pr-5 Your Super Top-up insurance is provided by
    plan-info(
      :image="getPlusPolicy?.insurer?.s3Url",
      :value="getPlusPolicy?.insurer?.name",
      size="md")
  

</template>

<script>
import NIcon from "../../../../../components/NovaIcon.vue";
import PlanInfo from "../../../components/PlanInfo.vue";
import NButton from "../../../../../components/NovaButton.vue";
import { TopupRoutes } from "../../../../../common/enums/topupRoutes.enum";
import { SUMMARY_CONTENT_MOBILE } from "./../constants";
import mixin from "./../mixin";
import PremiumPopover from "./PremiumPopover.vue";
export default {
  name: "MobileSummary",
  components: {
    NIcon,
    NButton,
    NIcon,
    PremiumPopover,
    PlanInfo,
  },
  mixins: [mixin],
  props: {
    header: {
      type: String,
    },
    title: {
      type: String,
      default: "",
    },
    bodyText1: {
      type: String,
      default: "",
    },
    bodyText2: {
      type: String,
      default: "",
    },
    buttonText: {
      type: String,
    },
    nextRoute: {
      type: String,
    },
    isPullUpOpen: {
      type: Boolean,
    },
  },
  data() {
    return {
      SUMMARY_CONTENT_MOBILE,
      TopupRoutes,
    };
  },
  methods: {
    showInsurer() {
      return this.currentStep === TopupRoutes.POLICY_SUMMARY;
    },
    nextAction() {
      if (this.$route.path.includes("/policy-summary")) {
        this.$emit("finalConfirmationClicked");
      } else {
        this.$router.push(`${this.nextRoute}`);
      }
    },
    emitToggleClicked() {
      this.$emit("toggleSummary");
    },
    hidePremium() {
      return (
        [TopupRoutes.POLICY_DETAILS, TopupRoutes.POLICY_SUMMARY].includes(this.currentStep) &&
        (this.isNovaPlusSelected || this.isNovaExtendedSelected)
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
.fixed-bottom {
  position: fixed;
  bottom: -6rem;
}
.toggle-logo {
  position: absolute;
  left: 50%;
  i {
    left: -50%;
    top: -20px;
    position: relative;
    padding: 0.375rem;
    background: white;
    border: 2px solid $gray-300;
    border-radius: 50%;
    font-size: 2rem;
    z-index: 11;
  }
}

.bs-hero {
  height: 15vh;
  position: relative;
  .hero {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }

  .hero-content {
    position: absolute;
    top: 50%;
    left: 25%;
    transform: translate(-50%, -50%);
    color: white; /* Set text color as needed */
  }

  .hero-title,
  .hero-subtitle {
    margin: 0;
  }
}
.disabled {
  opacity: 0.3;
  pointer-events: none;
}
</style>
