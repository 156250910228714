import { UserChangeBatchStages } from "../../../common/enums/userChangeBatchStages.enum";
import { EndorsementBatchStatus } from "../../../common/enums/endorsementBatchStatus.enum";
import { UserChangeStatus } from "../../../common/enums/userChangeStatus.enum";

export class EndorsementHelper {
  static orgAdminTabs = [
    UserChangeBatchStages.ENROLLMENT_PENDING,
    UserChangeBatchStages.UNAPPROVED,
    UserChangeBatchStages.APPROVED,
  ];
  static adminTabs = [
    UserChangeBatchStages.ORG_OK,
    UserChangeBatchStages.NOVA_OK,
    UserChangeBatchStages.PROVIDER_OK,
    UserChangeBatchStages.DONE,
    UserChangeBatchStages.REJECTED,
  ];
  static staticStageData = {
    [EndorsementBatchStatus.CURRENT]: {
      defaultActiveTab: UserChangeBatchStages.UNAPPROVED,
    },
    [EndorsementBatchStatus.ONGOING]: {
      value: EndorsementBatchStatus.ONGOING,
      defaultActiveTab: UserChangeBatchStages.ORG_OK,
    },
    [EndorsementBatchStatus.OVERDUE]: {
      defaultActiveTab: UserChangeBatchStages.UNAPPROVED,
    },
    [EndorsementBatchStatus.COMPLETED]: {
      tabs: null,
      bannerData: {
        bannerTitle: "These changes have already been processed",
        bannerSubtext: "",
        bannerIcon: "nova-basic",
        bannerBackground: "teal-100",
        iconColor: "teal-600",
        exportFlag: true,
        exportButtonVariant: "dark",
      },
      value: EndorsementBatchStatus.COMPLETED,
    },
    [EndorsementBatchStatus.REJECTED]: {
      tabs: null,
      bannerData: {
        bannerTitle: "All the changes have been rejected",
        bannerSubtext: "",
        bannerIcon: "nova-basic",
        bannerBackground: "mustard-100",
        iconColor: "mustard-600",
        exportFlag: false,
      },
      value: EndorsementBatchStatus.REJECTED,
    },
    [EndorsementBatchStatus.EMPTY]: {
      tabs: null,
      bannerData: {
        bannerTitle: "There are no changes in the current batch",
        bannerSubtext: "",
        bannerIcon: "nova-basic",
        bannerBackground: "teal-100",
        iconColor: "teal-600",
        exportFlag: true,
        exportButtonVariant: "dark",
      },
      value: EndorsementBatchStatus.EMPTY,
    },
  };

  static getTabsData(dueDateString) {
    return {
      [UserChangeBatchStages.ENROLLMENT_PENDING]: {
        value: UserChangeBatchStages.ENROLLMENT_PENDING,
        title: "Missing Data",
        bannerData: {
          bannerTitle: "These changes are missing critical data!",
          bannerSubtext: `Please collect the missing data before ${dueDateString} for these changes to be processed.`,
          bannerIcon: "question",
          buttonText: "Send reminders to all",
          rightImageIcon: "chevron-right",
          modalName: "send-reminder-modal",
          bannerBackground: "red-100",
          iconColor: "red-600",
        },
      },
      [UserChangeBatchStages.UNAPPROVED]: {
        value: UserChangeBatchStages.UNAPPROVED,
        title: "HR Approval Pending",
        iconColor: "mustard-600",
        bannerData: {
          bannerTitle: "These changes need your approval to be processed!",
          bannerSubtext: `Approving these will forward them as requests to your insurer on ${dueDateString} to be processed.`,
          bannerIcon: "refresh",
          buttonText: "Approve all changes",
          modalName: "banner-step-modal",
          bannerBackground: "mustard-100",
          iconColor: "mustard-600",
          exportFlag: false,
          showDepositSteps: false,
        },
      },
      [UserChangeBatchStages.APPROVED]: {
        value: UserChangeBatchStages.APPROVED,
        title: "Ready for Nova",
        bannerData: {
          bannerTitle: "These changes are ready for Nova to be processed!",
          bannerSubtext: `This batch will be sent out on ${dueDateString}.`,
          bannerIcon: "nova-basic",
          bannerBackground: "malibu-100",
          iconColor: "malibu-600",
        },
        hide: false,
      },
      [UserChangeBatchStages.ORG_OK]: {
        value: UserChangeBatchStages.ORG_OK,
        title: "Ready for Nova",
        bannerData: {
          bannerTitle: "These changes need EA approval to be processed!",
          bannerSubtext: `These changes have been approved by the HR, Please verify and approve the changes to forward it to the insurer.`,
          bannerIcon: "refresh",
          buttonText: "Approve all changes",
          bannerBackground: "mustard-100",
          modalName: "banner-step-modal",
          iconColor: "mustard-600",
          exportFlag: true,
          showDepositSteps: false,
        },
      },
      [UserChangeBatchStages.NOVA_OK]: {
        value: UserChangeBatchStages.NOVA_OK,
        title: "Submitted to Insurer",
        bannerData: {
          bannerTitle: "These changes need your approval to be processed!",
          bannerSubtext: `These changes are verified by Nova and are ready to be shared with the Insurer. You can approve all the changes after getting a final confirmation from the Insurer and mapping the ecards to the employees.`,
          bannerIcon: "refresh",
          buttonText: "Approve all changes",
          modalName: "banner-step-modal",
          bannerBackground: "mustard-100",
          iconColor: "mustard-600",
          exportFlag: true,
          showDepositSteps: false,
        },
      },
      [UserChangeBatchStages.PROVIDER_OK]: {
        value: UserChangeBatchStages.PROVIDER_OK,
        title: "Approved by Insurer",
        bannerData: {
          bannerTitle: "These changes are approved by the insurer!",
          bannerSubtext: `These changes are verified by Insurer and are ready to be marked as done. You can approve all the changes after adding endorsement documents and CD Balance details`,
          bannerIcon: "refresh",
          buttonText: "Approve all changes",
          modalName: "banner-step-modal",
          bannerBackground: "mustard-100",
          iconColor: "mustard-600",
          exportFlag: true,
          showDepositSteps: true,
        },
      },
      [UserChangeBatchStages.DONE]: {
        value: UserChangeBatchStages.DONE,
        title: "Completed",
        bannerData: {
          bannerTitle: "These changes are completed",
          bannerSubtext: "",
          bannerIcon: "refresh",
          bannerBackground: "mustard-100",
          iconColor: "mustard-600",
          exportFlag: true,
        },
      },
      [UserChangeBatchStages.REJECTED]: {
        value: UserChangeBatchStages.REJECTED,
        title: "Rejected",
        bannerData: {
          bannerTitle: "These changes were rejected",
          bannerSubtext: "",
          bannerIcon: "refresh",
          bannerBackground: "mustard-100",
          iconColor: "mustard-600",
          exportFlag: true,
        },
      },
    };
  }

  static generateBatchStructure(batchStage, isSuperAdmin, selectedTab, dueDateString, allClubbedChangesBatchWiseCount) {
    const staticData = {
      ...(batchStage === "batchesSummary"
        ? this.staticStageData[EndorsementBatchStatus.CURRENT]
        : this.staticStageData[batchStage]),
      ...(selectedTab ? { defaultActiveTab: selectedTab } : {}),
    };

    // in case of completed and empty batch no need for tabs.
    if (
      [EndorsementBatchStatus.COMPLETED, EndorsementBatchStatus.REJECTED, EndorsementBatchStatus.EMPTY].includes(
        batchStage
      )
    ) {
      return {
        ...staticData,
      };
    }

    const allTabsData = this.getTabsData(dueDateString);

    // in case of batchesSummary page only draft and org-ok/approved tabs allowed.
    if (batchStage === "batchesSummary") {
      const activeTabsCurrentBatch = {};

      this.orgAdminTabs.forEach((tab) => {
        // in case of super admin, we show org-ok state instead of approved.
        if (isSuperAdmin && tab === UserChangeBatchStages.APPROVED) {
          activeTabsCurrentBatch[UserChangeBatchStages.ORG_OK] = allTabsData[UserChangeBatchStages.ORG_OK];
        } else {
          activeTabsCurrentBatch[tab] = allTabsData[tab];
        }
      });

      return {
        ...staticData,
        tabs: activeTabsCurrentBatch,
      };
    }

    const activeTabs = {};

    if (!isSuperAdmin) {
      if (batchStage === EndorsementBatchStatus.ONGOING) {
        return {};
      }

      this.orgAdminTabs.forEach((tab) => {
        activeTabs[tab] = allTabsData[tab];
      });
    } else {
      const tabsToDisplay = [];

      // in case of daft change or current batch, push draft stages as well
      if (
        allClubbedChangesBatchWiseCount[UserChangeBatchStages.UNAPPROVED] ||
        allClubbedChangesBatchWiseCount[UserChangeBatchStages.ENROLLMENT_PENDING] ||
        batchStage === UserChangeStatus.CURRENT
      ) {
        tabsToDisplay.push(UserChangeBatchStages.ENROLLMENT_PENDING, UserChangeBatchStages.UNAPPROVED);
      }
      tabsToDisplay.push(...this.adminTabs);

      tabsToDisplay.forEach((tab) => {
        activeTabs[tab] = allTabsData[tab];
      });
    }

    return {
      ...staticData,
      tabs: activeTabs,
    };
  }

  static generateBannerData(activeTable, batchStage, endingDateString) {
    const bannerData = activeTable?.bannerData;

    if (batchStage.value === EndorsementBatchStatus.CURRENT && endingDateString) {
      bannerData.bannerSubtext = this.#getBannerSubText(endingDateString)[this.activeTable.value];
    }
    return bannerData;
  }

  static #getBannerSubText(dateString) {
    return {
      [UserChangeBatchStages.APPROVED]: `This batch will be sent out on ${dateString}.`,
      [UserChangeBatchStages.UNAPPROVED]: `Approving these will forward them as requests to your insurer on ${dateString} to be processed.`,
      [UserChangeBatchStages.ENROLLMENT_PENDING]: `Please collect the missing data before ${dateString} for these changes to be processed.`,
    };
  }
}
