<template lang="pug">
div(:class="[navBottomBorder, backgroundColor]")
  b-navbar.p-0(toggleable="md")
    .container.px-3(:class="getContainerStyleClasses")
      b-navbar-brand
        img.main-logo-style.d-none.d-md-block.my-3(:src="logoUrl",alt="nova main logo",to="/", :class= "isCheckupBookingFormPage ? 'main-logo-lg' : 'main-logo-md'", @click="handleClick")
        .row.pl-3.align-items-center(@click="gotoProfileSection",v-if="isMobileDashboard" )
          n-avatar(
            v-if="user"
            :size="2.5",
            :name="user.name",
            :image="user.photoFile?user.photoFile.url:null")
          .d-flex.flex-column.pl-2
            .font-sm.text-white.font-weight-semibold Welcome
            .font-md.text-white.font-weight-semibold {{ userDisplayName }}!
        img.main-icon-style.my-2.d-block.d-md-none(
          v-else-if="showLogo", :src="mobileLogoUrl",alt="nova main logo")
        i.icon-chevron-left.back-button.d-block.d-md-none(@click="goBack", role="button", v-else-if="!isPrivacySettingsPage")
      b-navbar-nav.d-none.d-md-inline-flex(:class= "isCheckupBookingFormPage ? ['w-100','ml-6'] : ['mr-auto'] ")
        slot(name="left")

      slot(name="middle")

      b-navbar-nav.my-2
        slot(name="right")
</template>

<script>
import { mapGetters, mapState } from "vuex";
import privacyPageMixin from "../mixins/privacyPage";
import utils from "@/utils";
import NAvatar from "@/components/Avatar.vue";
export default {
  name: "TopNavbar",
  components: {
    NAvatar,
  },
  mixins: [privacyPageMixin],
  computed: {
    ...mapGetters(["isLoggedIn"]),
    ...mapState(["user"]),
    isMobileBrowser() {
      return (utils.mobileCheck() || utils.tabCheck()) && !this.isApp;
    },
    isTiber() {
      if (this.$route.fullPath.includes("/dashboard") || this.$route.fullPath.includes("/external-checkup-booking")) {
        return true;
      }
      return false;
    },
    isMobileDashboard() {
      return this.$route.fullPath.includes("/dashboard") && this.isMobileBrowser;
    },
    isCheckupAdmin() {
      if (!this.user?.roles) return false;
      return this.user.roles.includes("checkup_admin");
    },
    logoUrl() {
      if (this.isCheckupBookingFormPage) return require("@/assets/images/insignia-black-tiber.svg");
      if (this.isTiber) return require("@/assets/images/logo-main-colored.svg");
      return require("@/assets/images/logo-main.svg");
    },
    showLogo() {
      return this.$route.fullPath.includes("reset-password") || this.isPrivacySettingsPage;
    },
    mobileLogoUrl() {
      if (this.isTiber) return require("@/assets/images/insignia-white-yellow-tick.svg");
      return require("@/assets/images/insignia-black-tiber.svg");
    },
    backgroundColor() {
      if (this.isTiber) {
        return "bg-tiber";
      }
      return "bg-white";
    },
    navBottomBorder() {
      if (this.$store.state.navBottomBorder && !this.isTiber) {
        return "mb-3 mb-md-4";
      }
      return "";
    },
    userDisplayName() {
      return this.user?.displayName || this.name?.split(" ")[0] || this.name;
    },
    showProgressIndicator() {
      return this.isCheckupBookingFormPage;
    },
    isCheckupBookingFormPage() {
      return this.$route.path.includes("/booking-form");
    },
    isAhc() {
      return this.$route.path.includes("packages") || this.isCheckupBookingFormPage;
    },
    getContainerStyleClasses() {
      let styleClasses = this.$store.state.navBottomBorder ? "top-nav-border-bottom-gray-800" : "nav-border-bottom";
      if (this.isMobileDashboard) {
        styleClasses = styleClasses + " pt-2 pb-2 py-md-0";
      } else if (!this.isAhc) {
        styleClasses += " pb-1";
      }

      return styleClasses;
    },
  },
  methods: {
    goBack() {
      if (this.$route?.name === "bookingForm") {
        this.$emit("go-back");
      } else window.history.length > 1 ? this.$router.go(-1) : this.$router.push("/");
    },
    gotoProfileSection() {
      this.$router.push("/user/profile");
    },
    handleClick() {
      if (this.isCheckupAdmin) this.$router.push("/external-checkup-booking");
      else if (this.isCheckupBookingFormPage) this.$router.push("/");
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/_variables.scss";

.main-logo-style {
  display: inline-block;
}
.main-logo-md {
  height: 1.5rem;
}
.main-logo-lg {
  height: 1.95rem;
  &:hover {
    cursor: pointer;
  }
}
.top-nav-border-bottom-gray-800 {
  border-bottom: 1px solid rgba($gray-800, 0.2);
}
.nav-border-bottom {
  border-bottom: 1px solid $gray-300;
}
@media (max-width: 768px) {
  .main-logo-style {
    height: 23px;
  }
  .main-icon-style {
    height: 1.75rem;
  }
  .nav-border-bottom {
    border-bottom: none;
  }
}

.back-button {
  font-size: 2rem;
}
</style>
