export default {
  schema: {
    fields: [
      {
        model: "meta.crm.timeline.claimIntimationByEmployee",
        type: "datepicker",
        styleClasses: "d-inline-block col-md-6 offset-md-right-6",
        label: `Claim Intimation to Nova<span style="color: red">*</span>`,
        min: "1900-01-01",
        max: "2100-12-31",
        placeholder: "DD/MM/YYYY",
        readonly: true,
        required: true,
        validator: "required",
        includeTimePicker: true
      },
    ],
  },
};
