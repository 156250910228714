import { getListQuery, getSingleQuery } from "../queries";
import { TeamsSchema } from "../schemas/teams";
import teamMutations from "../mutations/teams";
export default {
  gqlType: "Team",
  fields: ["name", "createdAt", "updatedAt"],
  searchPlaceholder: "Search by team name",
  singleQuery: getSingleQuery("Team"),
  listQuery: getListQuery("Team"),
  ...TeamsSchema,
  ...teamMutations,
  transform: (n) => {
    return { ...n };
  },
};
