export const Base = require("./Base.vue").default;
export const routes = [
  {
    path: "",
    redirect: "team",
  },
  {
    path: "team",
    component: () => import(/* webpackChunkName: "fitness" */ "./Team.vue"),
    name: "team_fitness",
  },
  {
    path: "self",
    component: () => import(/* webpackChunkName: "fitness" */ "./Self.vue"),
    name: "my_fitness",
  },
  {
    path: "rewards",
    component: () =>
      import(/* webpackChunkName: "fitness" */ "./Rewards.vue"),
    name: "rewards",
  },
  {
    path: "challenge-template-details/:id",
    component: () =>
      import(
        /* webpackChunkName: "fitness" */ "./components/ChallengeDetails.vue"
      ),
    name: "challenge_template_details",
  },
  {
    path: "org-challenge-details/:id",
    component: () =>
      import(
        /* webpackChunkName: "fitness" */ "./components/ChallengeDetails.vue"
      ),
    name: "org_challenge_details",
  },
  {
    path: "user-challenge-details/:id",
    component: () =>
      import(
        /* webpackChunkName: "fitness" */ "./components/ChallengeDetails.vue"
      ),
    name: "user_challenge_details",
  },
];
