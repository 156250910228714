<template lang="pug">
  .w-100
    v-swatches.nova-color-picker(
      v-model="ctrlValue",
      v-bind="$props",
      v-on="$listeners",
      show-fallback,
      fallback-input-type="color",
      :swatches="defaultColors",
      :row-length="rowLength",
      show-labels,
      :swatch-size="60",
      :spacing-size="45",
      :popover-x="popoverX",
      :popover-y="popoverY",
      :disabled="disabled")
    p {{ ctrlValue }}
</template>

<script>
import VSwatches from "vue-swatches";
import "vue-swatches/dist/vue-swatches.css";

export default {
  name: "NovaColorPicker",
  components: { VSwatches },
  props: {
    value: {
      type: String,
      default: null,
    },
    defaultColors: {
      type: Array,
      default: () => [],
    },
    popoverX: {
      type: String,
      default: "right",
    },
    popoverY: {
      type: String,
      default: "bottom",
    },
    rowLength: {
      type: Number,
      default: 4,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    ctrlValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
};
</script>

<style lang="scss">
.nova-color-picker {
  width: 100%;
  .vue-swatches__trigger__wrapper {
    width: 100%;
    .vue-swatches__trigger {
      width: 100% !important;
    }
  }
}
</style>
