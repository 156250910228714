import { getListQuery as getAdminListQuery, getIdSelectorQuery } from "../queries";
import { validateInlineSelect } from "@/utils/validators";
import { CheckupPackageSampleCollectionType } from "@/common/enums";
import { acceptedFamilyDefinitionsList } from "@/portal/pages/admin/constants";

export default {
  schema: {
    fields: [
      {
        model: "org",
        type: "id-selector",
        queryName: "orgs",
        gqlQuery: getAdminListQuery("Org"),
        label: `Organization <span style="color: red">*</span>`,
        validator: ["required"],
        required: true,
      },
      {
        model: "orgEntity",
        type: "id-selector",
        queryName: "orgEntities",
        gqlQuery: getIdSelectorQuery("OrgEntity"),
        queryVariables: () => ({ filter: {} }),
        label: "Org Entity",
        placeholder: "Org Entity (none)",
        visible: false,
      },
      {
        model: "benefit",
        type: "id-selector",
        queryName: "benefits",
        gqlQuery: getAdminListQuery("Benefit"),
        queryVariables: () => ({ filter: { isPolicy: false } }),
        getSubTitle: (p) => p.type,
        label: `Benefit  <span style="color: red">*</span>`,
        required: true,
      },
      {
        model: "partnerPackageId",
        type: "input",
        inputType: "text",
        label: `Partner Package Id <span style="color: red">*</span>`,
        validator: ["required", "regexp"],
        pattern: "^\\d+$",
        required: true,
      },
      // TODO: should be removed as we have same packages for both dependent and self
      {
        model: "isDependentPackage",
        label: "Is Dependent Package?",
        type: "checkbox",
        default: false,
        visible: true,
      },
      {
        model: "sampleCollectionType",
        label: `Sample Collection Type  <span style="color: red">*</span>`,
        type: "inline-select",
        reduce: (v) => v.value,
        options: [
          { label: "Home Collection", value: CheckupPackageSampleCollectionType.HOME_COLLECTION },
          { label: "In-clinic", value: CheckupPackageSampleCollectionType.INCLINIC, defaultValue: true },
          { label: "On-site (Camp/ Office)", value: CheckupPackageSampleCollectionType.ONSITE },
        ],
        required: true,
        validator: ["required", validateInlineSelect],
      },
      {
        model: "meta",
        type: "object",
        label: "Metadata",
        schema: {
          fields: [
            {
              model: "packageGroupName",
              type: "input",
              inputType: "text",
              label: "Package Group Name",
            },
            {
              model: "maximumRetailPrice",
              type: "input",
              inputType: "text",
              label: "Maximum Retail Price (optional)",
              hint: "It will be used to show the price difference, it won't be used to calculate prices.",
            },
            {
              model: "isLinearPackage",
              label: "Is Linear Package?",
              type: "checkbox",
              default: false,
              visible: true,
            },
          ],
        },
      },
    ],
  },
  filterSchema: {
    fields: [
      {
        model: "org",
        type: "id-selector",
        queryName: "orgs",
        gqlQuery: getAdminListQuery("Org"),
        placeholder: "Organization filter (none)",
        label: "Linked Org",
        showSelected: false,
      },
      {
        model: "orgEntity",
        type: "id-selector",
        queryName: "orgEntities",
        gqlQuery: getIdSelectorQuery("OrgEntity"),
        queryVariables: () => ({ filter: {} }),
        label: "Org Entity",
        placeholder: "Org Entity filter (none)",
        visible: false,
      },
      {
        model: "benefit",
        type: "id-selector",
        queryName: "benefits",
        gqlQuery: getAdminListQuery("Benefit"),
        placeholder: "Benefit (none)",
        label: "Linked Benefit",
        showSelected: false,
      },
    ],
  },
};
