<template lang="pug">
  .content(:class="contentSpacer")
    transition(name="fade", mode="out-in")
      router-view
</template>

<script>
export default {
  computed: {
    contentSpacer() {
      // TODO: refactor route to class association
      if (["onboarding_nova_email_link", "reset_password_verify"].includes(this.$route?.name)) return "no-header";
      if (this.$route?.fullPath?.startsWith("/dashboard")) return "dashboard-content-height";
      if (this.$route?.fullPath?.startsWith("/fitness")) return "fitness-content-height";
      if (
        this.$route?.fullPath?.startsWith("/org-admin/analytics") ||
        this.$route?.fullPath?.startsWith("/admin/analytics")
      )
        return "analytics-content-height";
      if (this.$route?.fullPath?.startsWith("/org-admin")) return "org-admin-content-height";
      if (this.$route?.fullPath?.startsWith("/admin")) return "admin-content-height";
      if (this.$route?.fullPath?.startsWith("/checkup")) return "checkup-content-height";
      return "";
    },
  },
};
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.1s ease-in-out;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.content {
  margin-top: 4.5rem;
}
</style>
