import { getListQuery, getSingleQuery } from "../queries";
import { PgPaymentsSchema } from "../schemas/pgPayments";

export default {
  gqlType: "PgPayment",
  fields: ["email", "paymentId", "orderId", "amount", "status", "createdAt"],
  searchPlaceholder: "Search by payment id or order id",
  singleQuery: getSingleQuery("PgPayment"),
  listQuery: getListQuery("PgPayment"),
  ...PgPaymentsSchema,
  transform: (n) => {
    const { pgPaymentId, pgOrderId } = n || {};
    return { ...n, paymentId: pgPaymentId, orderId: pgOrderId, email: n.user.email, amount: n.novaOrder.orderAmount };
  },
};
