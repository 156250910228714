export const Base = require("./Base.vue").default;

export const profileRoutes = [
  { path: "", component: require("./MobileNav.vue").default },
  { path: "basic-details", component: require("./MobileNav.vue").default },
  { path: "details", component: require("./Profile.vue").default },
  { path: "about", component: require("./Profile.vue").default },
  { path: "security", component: require("./Profile.vue").default },
  { path: "contact-details", component: require("./Profile.vue").default },
  { path: "cookie-preference", component: require("./Profile.vue").default },
  { path: "data-deletion", component: require("./Profile.vue").default },
  { path: "wearables", component: require("./components/NgageDeviceProviders.vue").default },
  { path: "reports", component: require("./Profile.vue").default },
  { path: "reports/:id", component: require("./components/Reports.vue").default },
];
export const dependentRoutes = [
  {
    path: "",
    component: require("./Dependents.vue").default,
  },
];
export const purchaseRoutes = [
  {
    path: "",
    component: require("./Purchases.vue").default,
  },
];
