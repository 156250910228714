<template lang="pug">
.p-4
  .text-center
    img.img-fluid.mb-3(
      loading="lazy",
      :src="require('../../../../../assets/images/Illustration1.png')",
      alt="Nova Benefits",
      width="50%"
    )
    .hb4.font-weight-bold.mb-3 Your reports are available on the Nova Benefits Mobile App
    .font-weight-medium.mb-3 Please open the mobile app and find the report in the profile section
    .py-4
      span
    .text-muted.mb-4.text-center
      | To download the app, you can scan the QR code or search “Nova Benefits” on
      | Playstore or Appstore to download the app!
  .d-flex.justify-content-between.align-items-center
    .d-flex.align-items-center.ml-5
      img.img-fluid(loading="lazy", :src="require('../../../../../assets/images/qr-ios.png')", alt="QR Code iOS", width="30%")
      .text-center
        .font-weight-medium Scan for IOS
        .text-muted or
        a(href="https://apps.apple.com/in/app/nova-benefits/id1561333321" target="_blank")
          img.img-fluid.mt-2(loading="lazy", :src="require('../../../../../assets/images/app-store.png')", alt="App Store", width="35%")
    .border-left.mx-4(style="height: 72px;")
    .d-flex.align-items-center.ml-4
      img.img-fluid(loading="lazy", :src="require('../../../../../assets/images/qr-android.png')", alt="QR Code Android", width="30%")
      .text-center
        .font-weight-medium Scan for Android
        .text-muted or
        a(href="https://play.google.com/store/search?q=novabenefits&c=apps" target="_blank")
          img.img-fluid.mt-2(loading="lazy", :src="require('../../../../../assets/images/google-play.png')", alt="App Store", width="35%")
  </template>

<script>
export default {
  name: "DesktopAssessmentReports",
};
</script>
