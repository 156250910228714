<template lang="pug">
.checkup-footer
  .d-flex.align-items-center.p-3.bg-white(
    :class="isReviewScreen ? 'justify-content-end justify-content-md-around mr-3 mr-md-0' : 'justify-content-between justify-content-md-around'")
    .d-flex
      n-button.text-nowrap.mr-3.align-self-center.d-none.d-md-flex(variant="outline-success",
        buttonText="Go Back",
        imageIcon="chevron-left",
        type="submit",
        :disabled="isBackButtonDisabled",
        @click="goBack")
      .d-flex.flex-column.justify-content-center(v-if="!isReviewScreen")
        .d-flex.align-items-end.justify-content-start
          .font-lg.font-weight-semibold.text-gray-900.mr-3 {{ "Annual Health Checkup" }}
          .text-blue-700.cursor-pointer.font-sm.font-weight-semibold.d-none.d-md-block(v-b-toggle="sidesheetId")
            | Package Details
        .font-sm.text-gray-700.font-weight-medium.mt-1.d-none.d-md-block(v-if="selectedMembersCount > 0")
          | {{ selectedMembersCount }} {{ selectedMembersCount > 1 ? 'members' : 'member' }} added
        .d-flex.align-items-center.text-blue-700.cursor-pointer.font-sm.font-weight-medium.d-block.d-md-none.mt-1(v-if="!isReviewScreen", v-b-toggle="sidesheetId")
          span Package Details
          n-icon(name="chevron-right", :size="0.9", variant="blue-700")
    n-button.text-nowrap(:variant="variant",
      :buttonText="isMobileView && !disableButtonTextOverride ? 'Confirm' : buttonText",
      type="submit",
      :rightImageIcon="rightImageIcon",
      :disabled="isButtonDisabled",
      @click="$emit('continueCheckupFlow')")

  package-details-sidesheet.package-sidesheet-style(v-if="!isReviewScreen"
    title="Package details for",
    :sidesheetId="sidesheetId",
    :groupedCheckupPackage="groupedCheckupPackage")

  </template>

<script>
import { mapGetters } from "vuex";
import PackageDetailsSidesheet from "./PackageDetailsSidesheet.vue";
import NButton from "@/components/NovaButton.vue";
import utils from "@/utils";

export default {
  components: {
    NButton,
    PackageDetailsSidesheet,
  },
  props: {
    variant: {
      type: String,
      default: "dark",
    },
    buttonText: {
      type: String,
      default: "Continue ->",
    },
    isButtonDisabled: {
      type: Boolean,
      default: false,
    },
    rightImageIcon: {
      type: String,
      default: null,
    },
    disableButtonTextOverride: {
      type: Boolean,
      default: false,
    },
    isBackButtonDisabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters(["ahcProspectState"]),
    isMobileView() {
      return utils.mobileCheck();
    },
    isReviewScreen() {
      return this.ahcProspectState.currentStep === "checkout";
    },
    sidesheetId() {
      const packageIds =
        this.groupedCheckupPackage?.checkupPackages?.map((checkupPackage) => checkupPackage.id)?.join("-") || "";
      return `sidesheet-${packageIds}`;
    },
    groupedCheckupPackage() {
      return this.ahcProspectState.selectedGroupPackage;
    },
    selectedMembersCount() {
      return this.ahcProspectState?.selectedMembers?.length;
    },
  },
  methods: {
    goBack() {
      this.$emit("goBack");
    },
  },
};
</script>

<style lang="scss">
.checkup-footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  left: 0;
  box-shadow: 0px -2px 0px rgba(0, 0, 0, 0.04);
  z-index: 6000 !important;
}
.footer-bg {
  height: 5.125rem;
}
</style>
