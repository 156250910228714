import gql from "graphql-tag";

export const policyDataCheckBatchFragment = gql`
  fragment PolicyDataCheckBatch on PolicyDataCheckBatch {
    id
    job_id
    comparison_source
    createdBy {
      name
      email
    }
    meta
  }
`;
