<template lang="pug">
  div
    div(v-if="!isMobileView")
      template(v-if="isBatchesSummaryLoading")
        b-skeleton-wrapper()
          div.d-flex.my-5
            b-skeleton.ml-4.p-2(variant="success", width="3rem", height="3rem")
            b-skeleton.ml-3.p-2(width="23.75rem", height="5rem")
            b-skeleton.mr-4.ml-auto.p-2(width="7.5rem", height="5rem")
          div.d-flex.justify-content-center
            b-skeleton.mb-4.bg-blue-100(width="57.5rem", height="10rem")
          div
          b-skeleton-table(type="input", :rows=5, :columns=5, :table-props="{bordered: true}")
      template(v-else-if="activeScreenName === 'batchesSummary'")
        b-card.shadow-none.mb-3(
          header-bg-variant="transparent",
          bg-variant="transparent",
          body-bg-variant="white",
          body-class="shadow-sm",
        )
          .d-flex.justify-content-between.dashboard-header.pb-3
            .d-flex.align-items-center
              .position-relative
                .text-white
                  span.position-absolute.endorsbatch-caltext-1.font-weight-semibold.font-lg {{ dateOnCalendar }}
                  span.position-absolute.endorsbatch-caltext-2.font-weight-semibold {{ monthOnCalendar }}
                img.p-3(:src="require('@/assets/images/calendar-3.svg')")
              .my-2
                .hb3.mt-3.mb-0.text-gray-900(id="batchName") {{ `${formattedOrgName} - ${monthOnCalendar} ${yearOfBatch} Batch` }}
                .text-gray-800.font-sm.mt-1.font-weight-medium.dash-subtitle {{ dashboardSubtitle }}
                b-tooltip(
                  target="batchName",
                  triggers="hover")
                  | {{ `${selectedOrgName} - ${monthOnCalendar} ${yearOfBatch} Batch` }}

            .d-flex.align-items-center.text-right
              .p-2.pr-3.vertical-separator
                .text-gray-700.font-weight-semibold.font-sm DAYS REMAINING
                .h3.font-xl.text-gray-900.mt-2 {{ daysLeft }}
              .p-2.pl-3
                .text-gray-700.font-weight-semibold.font-sm SUBMISSION DATE
                .h3.font-xl.text-gray-900.mt-2 {{ normalizedEndingDate }}

          .p-4.mt-4.no-endorsements-banner(v-if="!currentBatchTotalCount", data-cy="orgAdmin-endorsements-no-endorsement-banner")
            .h4.text-gray-900.font-xl No Endorsements yet!
            .text-gray-800.font-sm.mt-1.font-weight-medium.dash-subtitle {{ `Let’s start by adding new employees to the ${monthOnCalendar} ${yearOfBatch} batch` }}
            b-button.mt-4(
              variant="outline-dark",
              size="sm",
              v-b-modal.add-employee-modal,
            ) Add Employee
            div.endorsement-popover(v-b-tooltip.hover.bottom="noEndorsementTooltipText()")
              b-button.mt-4.ml-2(
                variant="dark",
                size="sm",
                :disabled="isMarkNoEndorsementDisabled || !currentBatch?.id",
                @click="$root.$emit('bv::show::modal', 'basic-modal')",
                v-b-modal.basic-modal,
               ) {{ isNoEndorsementBatch ? "No Endorsements marked" : "Mark No Endorsements" }}

              basic-modal(
                title="Hold on!",
                :description="`Are you sure you don't have any new employee or dependent related additions, deletions or changes for ${this.getSelectedBatchDate.nameWithStartingDate} batch?`",
                proceedBtnText="I am sure ->",
                @cancelBtnClicked="$root.$emit('bv::hide::modal', 'basic-modal')",
                @proceedBtnClicked="handleModalSwitch('basic-modal')")

              entity-selection-modal(
                v-if="isSuperAdmin && orgId && currentBatch?.id && $options.userSelectionModalStaticData[currentUserSelection]"
                hide-backdrop,
                no-close-on-backdrop,
                no-close-on-esc,
                hide-header-close,
                :entities="getEntitiesData",
                :entityName="$options.userSelectionModalStaticData[currentUserSelection].entityName",
                :selectionRequired="$options.userSelectionModalStaticData[currentUserSelection].selectionRequired",
                :closeBtnText="$options.userSelectionModalStaticData[currentUserSelection].closeBtnText",
                :continueBtnText="$options.userSelectionModalStaticData[currentUserSelection].continueBtnText",
                @close="handleUserSelectionBack",
                @submit="handleUserSelectionContinue",
              )
                template(v-slot:description)
                  span.text-gray-700.font-sm-font-weight-medium Email notification for marking this batch as no endorsements would be sent to the selected org admins. At least one org admin should be selected.
              .endorsement-doc-upload-backdrop.modal-backdrop(v-if="showEntitySelectionModalBackdrop")

          .d-flex.px-2.pb-4.pt-5.gap-2(v-else)
            StatusCard(v-for="statusCard of statusCards", v-bind="statusCard", :statusCount="currentBatchChangesCount[statusCard.status]", :isEndorsementStatusPageEnabled="isEndorsementStatusPageEnabled", :batchId="currentBatch.id")

        .d-flex.justify-content-between.mt-5.shadow-sm
          div(v-if="org").endorsement-table-container
            .hb4.font-weight-semibold.pt-3.pl-3 
              | Previous Batches
              span.text-gray-800 {{ ` - ${selectedOrgName}` }}

            .mt-3.n-tab-n-table-container
            n-table#endorsment-batches-table(
              :items="ongoingOrCompletedBatches",
              :fields="fields",
              :hover="true",
              :fixed="false",
              ref="endorsment-batches-table",
              @row-clicked="(item) => changeActiveBatch(item)",
              tbody-tr-class="cursor-pointer",
            )
              template(v-slot:monthAndYear="{ data }")
                span.font-sm.font-weight-medium.text-gray-800 {{ `${getMonthYear(data.item.startingAt)}` }}
              template(v-slot:batchStartDate="{ data }")
                span.font-sm.font-weight-medium.text-gray-800 {{ getFormattedDate(data.item.startingAt, 'Do MMM') }}
              template(v-slot:batchEndDate="{ data }")
                span.font-sm.font-weight-medium.text-gray-800 {{ getFormattedDate(data.item.endingAt, 'Do MMM') }}
              template(v-slot:status="{ data }")
                div(v-if="data.item.meta?.delayStatusConfig?.isDelayed")
                  span(class="text-red-600") DELAYED
                div(v-else-if="data.item.status === $options.EndorsementBatchStatusEnum.EMPTY")
                  span(v-if="data.item.meta?.markedNoEndorsementByHR", class="text-primary") NO ENDORSEMENTS
                  span(v-else, class="text-primary") EMPTY BATCH
                div(v-else-if="data.item.status === $options.EndorsementBatchStatusEnum.ONGOING")
                  span(class="text-primary") ACTIVATING BENEFITS
                div(v-else-if="data.item.status === $options.EndorsementBatchStatusEnum.OVERDUE")
                  span(class="text-red-600") BATCH OVERDUE
                div(v-else-if="data.item.status === $options.EndorsementBatchStatusEnum.REJECTED")
                  span(class="text-red-600") BATCH REJECTED
                div(v-else)
                  span(class="text-success") ACTIVATION COMPLETED
              template(v-slot:summary="{ data }")
                .d-flex.align-items-center
                  span.font-sm.font-weight-semibold.text-gray-900
                    | -> {{ getSingularOrPlural('Change', data.item.meta.changes.count) }}
                    span(v-if="[$options.EndorsementBatchStatusEnum.ONGOING, $options.EndorsementBatchStatusEnum.OVERDUE].includes(data.item.status)")  Submitted
                  .text-gray-400.mx-2.font-lg.font-weight-extralight
                    .d-flex.align-items-center(
                      v-if="data.item.status === $options.EndorsementBatchStatusEnum.COMPLETED"
                    ) |
                      n-chip.border.ml-1(
                        v-if="data.item.meta.approved.count > 0",
                        variant="light",
                        :isMinimal="true",
                      )
                        span.text-gray-900.font-xs.font-weight-medium {{ data.item.meta.approved.count }} Approved
                        template(v-slot:icon)
                          i.icon-check-circle.mr-1.text-success
                      n-chip.border.ml-2(
                        v-if="data.item.meta.rejected.count > 0",
                        variant="light",
                        :isMinimal="true",
                      )
                        span.text-gray-900.font-xs.font-weight-medium {{ data.item.meta.rejected.count }} Rejected
                        template(v-slot:icon)
                          .rotated.mr-1
                            i.icon-plus-circle.text-danger
              template(v-slot:orgEntityId="{ data }")
                span.font-sm.font-weight-medium.text-gray-800(:id="`orgEntityColumn-${data.item.id}`") {{ getFormattedEntityName(data.item.orgEntityId) || "NA" }}                
                b-tooltip(
                  :target="`orgEntityColumn-${data.item.id}`",
                  triggers="hover")
                  | {{ getFormattedEntityName(data.item.orgEntityId, false) }}
              template(v-slot:premiumEstimate="{ data }")
                div.width-max-content.text-gray-900
                  span {{ getTotalPremiumPerBatch(data.item, true) }}
              template(v-slot:isPaid="{ data }")
                div(v-if="data.item.meta && false")
                  span(:class="getBatchPremiumPaymentStatus(data.item).textColor") {{ getBatchPremiumPaymentStatus(data.item).text }}
              template(v-slot:linkToEndorsements="{ data }")
                i.icon-chevron-right.text-gray-600(
                  @click="changeActiveBatch(data.item)"
                )
              template(
                v-if="ongoingOrCompletedBatches && !ongoingOrCompletedBatches.length",
                v-slot:custom-foot="data"
              )
                tr
                  td.text-center(:colspan="fields.length")
                    empty-states(type="Endorsements" tableType="previous-endorsements")

      template(v-else)
        .d-flex
          n-breadcrumb(
            v-bind="breadcrumbData",
            :currentName="getSelectedBatchDate.nameWithStartingDate"
          )
          n-button.d-flex.justify-content-center.align-items-center.batch-comments-btn(
            v-if="isSuperAdmin",
            buttonText="",
            imageIcon="messaging",
            variant="light",
            size="s",
            pill
            v-b-toggle.batch-comments-sidesheet
          )
          comments-sidesheet(
            v-if="isSuperAdmin",
            id="batch-comments-sidesheet",
            :batchId="getSelectedBatchId",
            :items="comments",
            @refetchComments="onRefetchComments"
          )
        nova-progress-timeline(
          v-if="areTimelinesAllowed()",
          :states="getTimelineStates()",
        )
        template(v-if="!viaSuperAdminScreen && selectedBatch?.meta?.delayStatusConfig?.isDelayed")
          .info-container.px-4.py-3.my-3.bg-red-100.rounded-8.d-flex.align-items-center.justify-content-start.position-relative
            .icon-container.align-self-start.p-2
              i.text-red-500.icon-error
            .info-details.d-flex.align-items-start.justify-content-start.flex-column
              .info-title.font-md.font-inter.font-weight-semibold.text-gray-900.pl-2.pr-5.pb-1
                | {{ selectedBatch.meta.delayStatusConfig.reason }}
              .info-text.font-sm.font-inter.font-weight-medium.text-gray-900.pl-2.pr-4
                span This batch is delayed
                span(v-if="selectedBatch.meta.delayStatusConfig.remark") because “{{ selectedBatch.meta.delayStatusConfig.remark }}”
        template(v-if="this.activeScreenName === $options.EndorsementBatchStatusEnum.OVERDUE")
          .info-container.px-4.py-3.my-3.bg-red-100.rounded-8.d-flex.align-items-center.justify-content-start.position-relative
            .icon-container.align-self-start.p-2
              i.text-red-500.icon-error
            .info-details.d-flex.align-items-start.justify-content-start.flex-column
              .info-title.font-md.font-inter.font-weight-semibold.text-gray-900.pl-2.pr-5.pb-1
                | Batch Overdue
              .info-text.font-sm.font-inter.font-weight-medium.text-gray-900.pl-2.pr-4
                | There were changes in this batch that were either missing critical data or were unapproved, hence the batch isn't submitted yet.
                | Please fill in the missing data (if any) as soon as possible and approve the batch.
                | If you need to move changes to the next batch, please contact the Nova team.
        template(v-else-if="isSelectedBatchNoEndorsement")
          .warn-container.px-4.py-3.my-3.bg-mustard-100.rounded-8.d-flex.align-items-center.justify-content-start.position-relative
            .icon-container.align-self-start.p-2
              i.text-mustard-600.icon-warning
            .info-details.d-flex.align-items-start.justify-content-start.flex-column
              .info-title.font-md.font-inter.font-weight-semibold.text-gray-900.pl-2.pr-5.pb-1
                | No endorsement batch
              .info-text.font-sm.font-inter.font-weight-medium.text-gray-900.pl-2.pr-4
                | You have marked this batch as "No endorsement".

        summary-card(
          v-if="summaryCardVisible",
          :isCurrentBatch="isSelectedBatchCurrent()",
          :orgName="selectedOrgName",
          :title="`${getSelectedBatchDate.monthYear} Batch`",
          :subtext="summaryCardText",
          :orgId="orgId",
          :batch="selectedBatch || currentBatch",
          @refetchBatch="onRefetchBatch",
          :batches="batches",
          @update-batch="updateBatch",
          :selectedBatchId="getSelectedBatchId",
          :isDynamicPremiumEnabled="isDynamicPremiumEnabled()",
          :isPremiumBreakupEnabled="org?.featureFlags?.PREMIUM_BREAKUP",
          :transferBatchDisable="isTransferBatchDisabled",
          :isEndorsementPremiumPresent="isEndorsementPremiumPresent",
          :insurerSubmissionCounter="insurerSubmissionCounter",
        )
        changes-header(
          v-if="displayChangesHeader()",
          :tabs="getActiveTabs",
          ref="changeHeader",
          :tabsCount="allClubbedChangesCount",
          :banner-data="getBannerData",
          :defaultActiveTab="getDefaultActiveTab",
          :orgId="this.orgId",
          :selectedBatchId="getSelectedBatchId",
          :items="selectedTabData.changes",
          :draft-changes-count = "getDraftChangesCount",
          :total-count="selectedTabData.count",
          :insurer-specific="this?.org?.isInsurerSpecificAvailable"
          :unapproved-data="selectedTabData.segregatedCount",
          :ob-pending-count="selectedTabData.obPendingCount",
          :baseUrl="getBasePath()",
          :is-selected-batch-overdue="isSelectedBatchOverdue",
          :selected-batch-status="getSelectedBatchStatus",
          :dateOfInterest="getDueDate().dateString",
          :insurers="insurers",
          :policies="policies",
          :insurerPolicyMap="insurerPolicyMap",
          :batches="batches",
          :policiesWithPremium="getBenefitsWithPremiumAdded()",
          :buttonDisabledSecondary="selectedBatch?.status === $options.EndorsementBatchStatusEnum.COMPLETED",
          :currentOrg="this.org",
          :shortFallAmount="totalEstimatedPremium()?.shortFallAmount",
          :bufferAmount="totalEstimatedPremium()?.bufferAmount",
          :batch="selectedBatch || currentBatch",
          @tab-changed="changeActiveTab",
          @row-updated="refreshAffectedTabs",
          @resetFloatingBar="handleResetFloatingBar",
          @open-confirmation-modal="openConfirmationModal",
          @update-batch="updateBatch",
          @submitted-to-org-ok="handleOrgOkSubmission"
        )
        //- NOTE(ygupta): refer to route definition in admin/index.js for orgId as a param for admin review changes path
        endorsement-table(
          v-if="activeTable",
          :table-type="activeTable.value",
          :items="selectedTabData.changes",
          :draft-changes-count = "getDraftChangesCount",
          :total-count="selectedTabData.count",
          :batches="batches",
          :selectedBatch="currentBatch",
          :selectedBatchId="getSelectedBatchId",
          :is-selected-batch-overdue="isSelectedBatchOverdue",
          :unapproved-data="selectedTabData.segregatedCount",
          :insurers="insurers",
          :policies="policies",
          @open-confirmation-modal="openConfirmationModal",
          :insurerPolicyMap="insurerPolicyMap",
          :orgId="this.$route.params.orgId ? this.$route.params.orgId : this.$store.state.user.org.id",
          :policies-with-premium="getBenefitsWithPremiumAdded()"
          :is-loading="isUserChangesLoading"
          :shortFallAmount="totalEstimatedPremium()?.shortFallAmount",
          :bufferAmount="totalEstimatedPremium()?.bufferAmount",
          :currentOrg="this.org",
          ref= "endorsementTable",
          @row-updated="refreshAffectedTabs",
          @searchQuery="onSearchQueryInput",
          @insurer-submission-updated="handleInsurerSubmission",
          @submitted-to-org-ok="handleOrgOkSubmission"
          )
          template(v-slot:pagination, v-if="selectedTabData.count > pageSize")
            .d-flex.justify-content-between
              .d-flex.align-items-center.ml-2.text-gray-800.font-sm.font-weight-medium
                | Showing {{ getOffset(currentPage) + 1 }}-{{ getOffset(currentPage) + getRelevantRowCount(activeTable) }}
                span(v-if="!activeTableIsMissingData") &nbsp;out of {{ selectedTabData.count }}
                | &nbsp;records
              n-pagination(
                v-model="currentPage",
                :total-rows="selectedTabData.count",
                :per-page="pageSize",
                align="right"
              )
    div(v-else)
      b-card.px-5.py-10(no-body)
        .row.justify-content-center.my-2
          n-avatar.shadow-none(:image="require('@/assets/images/endorsement-mobile.svg')", :size="8", variant="")
        .row.justify-content-center
          h4.font-weight-semibold Experience it on a desktop!
          p.text-gray-700.font-sm.text-center.px-3 You can utilize this feature more effectively on a desktop.
        .row.justify-content-center
          n-button(buttonText="Go Back", size="lg", @click="$router.go(-1)")
    add-employee-modal
    change-confirmation-modal(
      id="change-confirmation-modal",
      :submitButtonText="getConfirmationModal?.submitButtonText",
      :header="getConfirmationModal?.header",
      :primaryText="getConfirmationModal?.primaryText",
      :secondaryText="getConfirmationModal?.secondaryText",
      :warningText="getConfirmationModal?.warningText",
      @confirm="executeConfirmationModal",
    )
  </template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";
import { groupBy } from "lodash";
import gql from "graphql-tag";
import resDefs from "../admin/definitions";
import orgAdminResDefs from "../orgAdmin/definitions";
import orgDef from "../admin/definitions/orgs";
import { UserChangeBatchStages } from "../../../common/enums/userChangeBatchStages.enum";
import { UserChangeStatus } from "../../../common/enums/userChangeStatus.enum";
import { UserChangeBatchStatus } from "../../../common/enums/userChangeBatchStatus.enum";
import router from "../../router";
import StatusCard from "./components/EndosementCard.vue";
import AddEmployeeModal from "./components/AddEmployeeModal.vue";
import EndorsementTable from "./components/EndorsementTable.vue";
import ChangesHeader from "./components/ChangesHeader.vue";
import FiltersPanel from "./components/FiltersPanel.vue";
import ReviewChangesModal from "./components/ReviewChangesModal.vue";
import SummaryCard from "./components/SummaryCard.vue";
import ChangeConfirmationModal from "./components/ChangeConfirmationModal.vue";
import { EndorsementHelper } from "./helper";
import CommentsSidesheet from "./components/CommentsSidesheet.vue";
import NButton from "@/components/NovaButton.vue";
import NChip from "@/components/NovaChip.vue";
import NTable from "@/components/NovaTable.vue";
import NIcon from "@/components/NovaIcon.vue";
import StatsCard from "@/components/Cards/StatsCard.vue";
import NInlineInput from "@/components/NovaInlineInput.vue";
import NBreadcrumb from "@/components/NovaBreadcrumb.vue";
import NModal from "@/components/NovaModal.vue";
import utils from "@/utils";
import NovaProgressTimeline from "@/components/NovaProgressTimeline.vue";
import EmptyStates from "@/components/Cards/EmptyStates/EmptyStateCard.vue";
import NAvatar from "@/components/Avatar.vue";
import BasicModal from "@/components/BasicModal.vue";
import NPopover from "@/components/NovaPopover.vue";
import { EndorsementBatchStatus } from "@/common/enums";
import EntitySelectionModal from "@/components/EntitySelectionModal.vue";

// TODO: Bring back FiltersPanel

// TODO (NV-1541): Fix hard coded ending date in banner

export default {
  name: "EndorsementBatches",
  components: {
    NButton,
    ChangeConfirmationModal,
    NTable,
    NChip,
    NIcon,
    StatsCard,
    NInlineInput,
    StatusCard,
    NModal,
    AddEmployeeModal,
    ChangesHeader,
    ReviewChangesModal,
    EndorsementTable,
    FiltersPanel,
    NovaProgressTimeline,
    SummaryCard,
    NBreadcrumb,
    EmptyStates,
    NAvatar,
    BasicModal,
    NPopover,
    EntitySelectionModal,
    CommentsSidesheet,
  },
  data() {
    const resName = "userChanges";
    const orgAdminResDef = orgAdminResDefs[resName];
    return {
      "enrollment-pending": null,
      approved: null,
      showEntitySelectionModalBackdrop: false,
      confirmationFunction: null,
      entitiesData: {
        orgAdmins: [],
        CXAccountOwners: [],
      },
      insurerSubmissionCounter: 0,
      unapproved: null,
      orgAdminResDef,
      orgId: this.$route.params.orgId ? this.$route.params.orgId : this.$store.state.user.org.id,
      searchQuery: "",
      batches: [],
      ongoingOrCompletedBatches: null,
      currentOrUpcomingBatches: null,
      allClubbedChangesCount: {},
      isBatchesSummaryLoading: false,
      isUserChangesLoading: false,
      getMonthYear: utils.getMonthYear,
      getDateWithSuffix: utils.getDateWithSuffix,
      getSingularOrPlural: utils.getSingularOrPlural,
      unapprovedChangesCount: 0,
      currentBatch: {
        endingAt: null,
        startingAt: null,
      },
      selectedBatch: null,
      showListForBatches: null,
      isNoEndorsementBatch: false,
      currentUserSelection: "org-admins",
      statusCards: [
        {
          cardIndex: 1,
          statusName: "Missing Data",
          statusDescription: "Changes that are missing important data.",
          ctaText: "View Missing Data ->",
          variant: "red",
          status: "enrollment-pending",
          dataCy: "orgAdmin-endorsements-missing-data-card",
        },
        {
          cardIndex: 2,
          statusName: "HR Approval Pending",
          statusDescription: "Changes that need HR approval to be processed.",
          ctaText: "Approve pending changes ->",
          variant: "mustard",
          status: "unapproved",
          dataCy: "orgAdmin-endorsements-hr-approval-pending-card",
        },
        {
          cardIndex: 3,
          statusName: "Ready for Nova",
          statusDescription: "Changes that are ready to be sent for processing.",
          ctaText: "View Batch ->",
          variant: "malibu",
          status: "approved",
          dataCy: "orgAdmin-endorsements-ready-for-nova-card",
        },
      ],
      isMarkNoEndorsementDisabled: false,
      activeTab: null,
      activeScreenTab: null,
      selectedTabData: {
        changes: [],
        count: 0,
        segregatedCount: this.getSegregatedCount(),
        obPendingCount: 0,
      },
      currentPage: 1,
      currentBatchChangesCount: {
        [UserChangeBatchStages.ENROLLMENT_PENDING]: 0,
        [UserChangeBatchStages.UNAPPROVED]: 0,
        [UserChangeBatchStages.ORG_OK]: 0,
        [UserChangeBatchStages.APPROVED]: 0,
      },
      pageSize: 10,
      batchIds: [],
      breadcrumbData: {
        rootName: "Endorsements",
        rootUrl: this.getBasePath(),
        rootIcon: "stroke-endorsements-3",
      },
      tabAlreadyRefreshed: false,
      comments: [],
    };
  },
  computed: {
    ...mapGetters(["getFeatureFlags", "selectedOrgEntity", "isOrgEntityAdmin"]),
    orgEntityId() {
      if (this.isOrgEntityAdmin) return this.$store.state.user?.orgEntity?.id;

      if (this.selectedOrgEntity?.id) return this.selectedOrgEntity?.id;

      if (this.$route.params?.orgEntityId) return this.$route.params?.orgEntityId;

      return null;
    },
    isEndorsementStatusPageEnabled() {
      return Boolean(!this.isSuperAdmin && this.currentBatch?.dbStatus !== UserChangeBatchStatus.DRAFT);
    },
    getEntitiesData() {
      if (this.currentUserSelection === "cx-owners") {
        // if users selected already, pass them to modal
        if (!this.entitiesData.CXAccountOwners.length) {
          return this.CXAccountOwners || [];
        }
        return this.entitiesData.CXAccountOwners;
      }

      if (!this.entitiesData.orgAdmins.length) {
        return this.orgAdmins || [];
      }
      return this.entitiesData.orgAdmins;
    },
    getSelectedOrgAdmins() {
      return this.entitiesData.orgAdmins
        .filter((orgAdmin) => orgAdmin.isSelected)
        .map((orgAdmin) => ({ name: orgAdmin.name, email: orgAdmin.email, user_id: orgAdmin.userId }));
    },
    getSelectedCXAccountOwners() {
      return this.entitiesData.CXAccountOwners.filter((cxOwner) => cxOwner.isSelected).map((cxOwner) => ({
        name: cxOwner.name,
        user_id: cxOwner.user?.id,
        email: cxOwner.user?.email,
      }));
    },
    isSuperAdmin() {
      return this.$route.path.includes("/admin");
    },
    isEndorsementPremiumPresent() {
      return !!(
        this.batchInSelection?.endorsementPremium && Object.keys(this.batchInSelection?.endorsementPremium).length > 0
      );
    },
    orgBenefits() {
      return this.org?.benefits?.filter((benefit) => benefit.node?.isPolicy).map((benefit) => benefit.node);
    },
    insurerPolicyMap() {
      const map = {};
      this.orgBenefits?.map((benefit) => {
        const insurer = benefit?.insurer;
        if (benefit?.id && insurer) map[benefit.id] = insurer.id;
      });
      return map;
    },
    policies() {
      return this.orgBenefits?.reduce((acc, benefit) => {
        if (benefit?.isPolicy && !acc.some((item) => item?.id === benefit?.id)) {
          benefit.premiumData = this.getPolicyPremium(benefit.id);
          acc.push(benefit);
        }
        return acc;
      }, []);
    },
    insurers() {
      return this.orgBenefits?.reduce((acc, benefit) => {
        const insurer = benefit?.insurer;
        if (insurer?.id && !acc.some((item) => item?.id === insurer?.id)) {
          insurer.premiumData = this.getInsurerPremium(insurer.id);
          insurer.cdBalanceData = this.getInsurerWiseCDBalance(insurer.id);
          acc.push(insurer);
        }
        return acc;
      }, []);
    },
    summaryCardText() {
      if (this.selectedBatch) {
        return `${this.selectedBatch.status === EndorsementBatchStatus.REJECTED ? "Rejected" : "Submitted"} on ${
          this.getSelectedBatchDate.submitDate
        }`;
      } else {
        return `Start date: ${utils.getDateWithSuffix(
          this.currentBatch?.startingAt,
          "Do MMMM"
        )}  |  End date: ${utils.getDateWithSuffix(this.currentBatch?.endingAt, "Do MMMM")}`;
      }
    },
    fields() {
      const rowStyling = ["align-middle"];
      const fields = [
        {
          key: "monthAndYear",
          label: "Batch Month",
          tdClass: rowStyling,
          visible: true,
        },
        {
          key: "batchStartDate",
          label: "Start Date",
          tdClass: rowStyling,
          visible: true,
        },
        {
          key: "batchEndDate",
          label: "End Date",
          tdClass: rowStyling,
          visible: true,
        },
        {
          key: "status",
          label: "Batch status",
          tdClass: rowStyling,
          visible: true,
        },
        {
          key: "summary",
          label: "Changes status",
          tdClass: rowStyling,
          visible: true,
        },
        {
          key: "orgEntityId",
          label: "Entity Name",
          tdClass: rowStyling,
          visible: Object.keys(this.orgEntityMap)?.length > 0,
        },
        {
          key: "numberOfAdditions",
          label: "",
          tdClass: rowStyling,
          visible: true,
        },
        {
          key: "numberOfDeletions",
          label: "",
          tdClass: rowStyling,
          visible: true,
        },
        {
          key: "numberOfUpdates",
          label: "",
          tdClass: rowStyling,
          visible: true,
        },
        {
          key: "dateOfSubmission",
          label: "",
          tdClass: rowStyling,
          visible: true,
        },
        {
          key: "premiumEstimate",
          label: "Premium Estimation",
          tdClass: ["align-right"],
          visible: this.org?.featureFlags?.PREMIUM_BREAKUP,
        },
        {
          key: "isPaid",
          label: "Payment Status",
          tdClass: rowStyling,
          visible: this.org?.featureFlags?.PREMIUM_BREAKUP && false,
        },
        {
          key: "linkToEndorsements",
          label: "",
          tdClass: rowStyling,
          visible: true,
        },
      ];
      return fields.filter((field) => field.visible);
    },
    checkIfCompletedOrRejectedBatch() {
      return [EndorsementBatchStatus.COMPLETED, EndorsementBatchStatus.REJECTED].includes(this.activeScreenName);
    },
    getDefaultActiveTab() {
      return !this.checkIfCompletedOrRejectedBatch ? this.batchStructure.defaultActiveTab : "";
    },
    summaryCardVisible() {
      return (
        [
          EndorsementBatchStatus.ONGOING,
          EndorsementBatchStatus.OVERDUE,
          EndorsementBatchStatus.COMPLETED,
          EndorsementBatchStatus.REJECTED,
          EndorsementBatchStatus.EMPTY,
        ].includes(this.activeScreenName) ||
        (this.isDynamicPremiumEnabled && this.activeScreenName === EndorsementBatchStatus.CURRENT)
      );
    },
    getBannerData() {
      return EndorsementHelper.generateBannerData(
        this.activeTable,
        this.activeScreenName,
        this.getDueDate().dateString
      );
    },
    activeTableIsMissingData() {
      return this.activeTable.title === "Missing Data";
    },
    currentBatchTotalCount() {
      if (this.activeScreenName === "batchesSummary") {
        return Object.entries(this.allClubbedChangesCount)?.reduce(
          (stageCount, totalCount) => +stageCount + totalCount,
          0
        );
      }
      return Object.values(this.currentBatchChangesCount).reduce((acc, tabCount) => acc + tabCount, 0);
    },
    startingDate() {
      return this.currentBatch?.startingAt;
    },
    endingDate() {
      return this.currentBatch?.endingAt;
    },
    normalizedEndingDate() {
      return moment(this.endingDate).format("MMMM DD YYYY");
    },
    monthOnCalendar() {
      return utils.getMonthShortForm(utils.getMonth(this.endingDate).toLowerCase());
    },
    dateOnCalendar() {
      return utils.getDate(this.endingDate);
    },
    currentDate() {
      return utils.getDate(new Date());
    },
    currentMonth() {
      return utils.getMonthShortForm(utils.getMonth(new Date()).toLowerCase());
    },
    yearOfBatch() {
      return moment(this.endingDate).year();
    },
    daysLeft() {
      return utils.getDateDifference(this.endingDate);
    },
    dashboardSubtitle() {
      // TODO: REVISIT
      return `These are the endorsements for the month of ${this.monthOnCalendar}. Any new employee additions done before ${this.currentMonth} ${this.currentDate} will be added to this batch.`;
    },
    isBatchesDisplayed() {
      return this.currentlyDisplaying === "batchesSummary";
    },
    getDateOfSubmission() {
      const batch = this.filterBatchById(this.batchType, this.batchId);
      return batch?.endingAt ? this.getDateWithSuffix(batch.endingAt) : "";
    },
    isMobileView() {
      return utils.mobileCheck();
    },
    activeScreenName() {
      if (this.$route.name === "draft") {
        return "current";
      } else if (this.selectedBatch?.status) {
        return this.selectedBatch.status;
      }
      return "batchesSummary";
    },
    activeTable() {
      // in case of batchesSummary no batch is selected, so no activeTable
      if (this.activeScreenName === "batchesSummary") {
        return;
      }
      // incase of completed/empty batches no tabs are present, activeTable data is present on the batchStructure itself.
      if (!this.batchStructure?.tabs) {
        return this.batchStructure;
      }

      return this.batchStructure.tabs[this.$route.params.tabName];
    },
    getDraftChangesCount() {
      return +(
        (this.allClubbedChangesCount[UserChangeBatchStages.ENROLLMENT_PENDING] ?? 0) +
        (this.allClubbedChangesCount[UserChangeBatchStages.UNAPPROVED] ?? 0)
      );
    },
    getSelectedBatchDate() {
      const monthYear = this.getMonthYear(this.selectedBatch?.startingAt) || "";
      const batch = this.selectedBatch || this.currentBatch;
      let orgOrEntityName = this.org?.name || "";

      if (batch?.orgEntityId && this.orgEntityMap?.[batch.orgEntityId]) {
        orgOrEntityName = this.orgEntityMap[batch.orgEntityId];
      }

      orgOrEntityName = orgOrEntityName.length <= 25 ? orgOrEntityName : `${orgOrEntityName.slice(0, 25)}...`;

      const startDate = this.selectedBatch?.startingAt || this.startingDate;
      const endDate = this.selectedBatch?.endingAt || this.endingDate;

      return {
        nameWithOrgOrEntityName: `${orgOrEntityName} - ${monthYear} (${this.getFormattedDate(startDate)})`,
        nameWithStartingDate: `${monthYear} (${this.getFormattedDate(startDate)})`,
        submitDate: this.getDateWithSuffix(endDate) || "",
        monthYear,
      };
    },
    viaSuperAdminScreen() {
      return !this.$route.path.includes("org-admin/");
    },
    getSelectedBatchId() {
      return this.selectedBatch?.id || this.currentBatch?.id;
    },
    getSelectedBatchStatus() {
      return this.selectedBatch?.status || this.currentBatch?.status;
    },
    isSelectedBatchOverdue() {
      return this.selectedBatch?.status === EndorsementBatchStatus.OVERDUE;
    },
    isSelectedBatchNoEndorsement() {
      return this.selectedBatch?.meta?.markedNoEndorsementByHR;
    },
    getConfirmationModal() {
      return {
        header: "Hold",
        submitButtonText: "Submit 'Ready for Nova' changes",
        warningText: "There are pending changes in the batch which are either missing data or are not approved",
        primaryText:
          "Only the changes in '<strong class='text-dark'>Ready for Nova</strong>' will be submitted to the insurer",
        secondaryText: "Data which is either missing or is not approved will be moved to current batch",
        isContinueCTADisabled: false,
        accordionData: [],
        noteText: [],
      };
    },
    batchInSelection() {
      return this.selectedBatch ?? this.batches.find((batch) => batch.id === this.getSelectedBatchId);
    },
    batchStructure() {
      const batchStructure = EndorsementHelper.generateBatchStructure(
        this.activeScreenName,
        this.viaSuperAdminScreen,
        this.$route.params.tabName,
        this.getDueDate().dateString,
        this.allClubbedChangesCount
      );
      return batchStructure;
    },
    getActiveBatchIdsArray() {
      return this.$route.params.batchId
        ? [this.$route.params.batchId]
        : this.currentOrUpcomingBatches.map((batch) => batch.id);
    },
    getActiveTabs() {
      return !this.checkIfCompletedOrRejectedBatch ? this.batchStructure.tabs : {};
    },
    orgEntityMap() {
      return (this.org?.orgEntities || []).reduce((map, orgEntity) => {
        map[orgEntity.id] = orgEntity.name;
        return map;
      }, {});
    },
    selectedOrgName() {
      const orgEntityMap = this.orgEntityMap || {};

      if (this.selectedBatch?.orgEntityId && orgEntityMap[this.selectedBatch.orgEntityId]) {
        return orgEntityMap[this.selectedBatch.orgEntityId];
      }

      if (this.orgEntityId && orgEntityMap[this.orgEntityId]) {
        return orgEntityMap[this.orgEntityId];
      }

      return this.org?.name || "";
    },
    formattedOrgName() {
      const name = this.selectedOrgName;
      return name.length <= 25 ? name : name.slice(0, 25) + "...";
    },
    isTransferBatchDisabled() {
      return (
        [this.$options.EndorsementBatchStatusEnum.COMPLETED, this.$options.EndorsementBatchStatusEnum.EMPTY].includes(
          this.selectedBatch?.status
        ) ||
        this.isNoEndorsementBatch ||
        this.allClubbedChangesCount[UserChangeBatchStages.NOVA_OK] > 0 ||
        this.allClubbedChangesCount[UserChangeBatchStages.DONE] > 0 ||
        this.allClubbedChangesCount[UserChangeBatchStages.PROVIDER_OK] > 0
      );
    },
  },
  watch: {
    "currentBatch.meta.markedNoEndorsementByHR": function () {
      this.isNoEndorsementBatch = !!this.currentBatch?.meta?.markedNoEndorsementByHR;
    },
    orgEntityId: {
      handler: "fetchBatches",
      immediate: true,
    },
    currentPage: function () {
      if (this.activeTable && this.$apollo.queries[this.activeTable?.value] && !this.tabAlreadyRefreshed) {
        this.isUserChangesLoading = true;
        this.$apollo.queries[this.activeTable.value].fetchMore({
          variables: {
            offset: this.getOffset(this.currentPage),
          },
          updateQuery: (previousResult, { fetchMoreResult }) => {
            // TODO: The previous result can be further utilized to reduce number of
            // queries run when traversing back and forth in pagination
            const transformedClubbedData = utils.deepClone(
              this.orgAdminResDef.transformGetClubbedUserChanges(fetchMoreResult)
            );
            this.selectedTabData.changes = transformedClubbedData.changes;
            this.selectedTabData.count = transformedClubbedData.totalCount;
            this.selectedTabData.segregatedCount = transformedClubbedData.segregatedCount;
            this.selectedTabData.obPendingCount = transformedClubbedData.obPendingCount;
            this.isUserChangesLoading = false;
          },
        });
      }
      this.tabAlreadyRefreshed = false;
    },
    activeScreenName(newScreen) {
      if (this.activeScreenName !== "batchesSummary") {
        if (this.$route.params.orgId) {
          window.posthog.capture("endorsements_previous_batch_click", {
            batch_submitted_on: this.endingDate,
            org_name: this.user?.org?.name,
            email: this.user?.email,
          });
        } else {
          window.posthog.capture("endorsement_current_batch_action", {
            section_clicked: this.activeScreenName,
            day_of_month: new Date().getDate(),
            org_name: this.user?.org?.name,
            email: this.user?.email,
          });
        }
      }
    },
  },
  mounted() {
    if (this.activeScreenName !== "batchesSummary") {
      if (this.$route.params.orgId) {
        window.posthog.capture("endorsements_previous_batch_click", {
          batch_submitted_on: this.endingDate,
          org_name: this.user?.org?.name,
          email: this.user?.email,
        });
      } else {
        window.posthog.capture("endorsement_current_batch_action", {
          section_clicked: this.activeScreenName,
          day_of_month: new Date().getDate(),
          org_name: this.user?.org?.name,
          email: this.user?.email,
        });
      }
    }
    if (this.$route.query.markNoEndorsements) {
      this.$bvModal.show("basic-modal");
      this.$router.replace({ query: null });
    }
    // Based on Role orgAdmin/superAdmin update 3rd card route
    if (this.isSuperAdmin) {
      const readyForNovaStatusCard = this.statusCards.find((card) => card.statusName === "Ready for Nova");
      if (readyForNovaStatusCard) {
        readyForNovaStatusCard.status = "org-ok";
      }
    }
  },
  async created() {
    this.$options.userSelectionModalStaticData = {
      "org-admins": {
        entityName: "Org Admins",
        selectionRequired: true,
        closeBtnText: "<- Go Back",
        continueBtnText: "Continue ->",
        operations: {
          doc_upload: {
            description:
              "Document related emails will be sent to the selected org admins. At least one org admin should be selected.",
          },
          send_estimates: {
            description:
              "Premium related emails will be sent to the selected org admins. At least one org admin should be selected.",
          },
        },
      },
      "cx-owners": {
        entityName: "CX Owners",
        selectionRequired: false,
        closeBtnText: "<- Go Back",
        continueBtnText: "Submit",
        operations: {
          doc_upload: {
            description: "Document related emails will be sent to the selected org CX owners.",
          },
          send_estimates: {
            description: "Premium related emails will be sent to the selected org CX owners.",
          },
        },
      },
    };
    this.$options.EndorsementBatchStatusEnum = EndorsementBatchStatus;
    await this.fetchBatches();
  },
  methods: {
    getBenefitsWithPremiumAdded() {
      return Object.keys(this.batchInSelection?.endorsementPremium ?? {});
    },
    handleInsurerSubmission() {
      this.insurerSubmissionCounter++;
    },
    async handleOrgOkSubmission() {
      await this.refetchAllChanges();
      if (
        !this.isSuperAdmin &&
        !this.allClubbedChangesCount[UserChangeBatchStages.UNAPPROVED] &&
        !this.allClubbedChangesCount[UserChangeBatchStages.ENROLLMENT_PENDING] &&
        this.currentBatch
      ) {
        this.$router.push(`/org-admin/changes/overview/${this.currentBatch.id}`);
      }
    },
    getFormattedDate(date, format = "DD/MM/YYYY") {
      return utils.getFormattedDate(date, format);
    },
    getFormattedEntityName(orgEntityId, format = true) {
      if (!orgEntityId || !this.orgEntityMap || !this.orgEntityMap[orgEntityId]) return "NA";
      const name = this.orgEntityMap[orgEntityId];
      if (format) return name.length <= 25 ? name : name.slice(0, 25) + "...";
      return name;
    },
    handleUserSelectionBack() {
      if (this.currentUserSelection === "org-admins") {
        this.$bvModal.hide("entity-selection-modal");
        this.toggleEntitySelectionBackdrop(false);
        this.$bvModal.show("basic-modal");
      }
      this.currentUserSelection = "org-admins";
    },
    openConfirmationModal(componentName) {
      this.confirmationFunction =
        componentName === "endorsementTable"
          ? this.$refs.endorsementTable.approveChanges
          : this.$refs.changeHeader.openModal;
      this.$bvModal.show("change-confirmation-modal");
    },
    executeConfirmationModal() {
      this.confirmationFunction();
    },
    handleUserSelectionContinue(updatedEntitiesData) {
      if (this.currentUserSelection === "cx-owners") {
        this.entitiesData.CXAccountOwners = updatedEntitiesData;
        this.markNoEndorsementFromHR();
        this.$bvModal.hide("entity-selection-modal");
        this.toggleEntitySelectionBackdrop(false);
        return;
      }
      this.entitiesData.orgAdmins = updatedEntitiesData;
      this.currentUserSelection = "cx-owners";
    },
    handleModalSwitch(prevModal) {
      this.$bvModal.hide(prevModal);
      this.openUserSelectionModal();
    },
    openUserSelectionModal() {
      this.toggleEntitySelectionBackdrop(true);
      this.$bvModal.show("entity-selection-modal");
    },
    toggleEntitySelectionBackdrop(toggle) {
      this.showEntitySelectionModalBackdrop = toggle || !this.showEntitySelectionModalBackdrop;
    },
    getPolicyPremium(policyId) {
      return (
        this.batchInSelection?.meta?.premiumData?.policyWisePremiumEstimation?.[policyId] &&
        utils.deepClone(this.batchInSelection?.meta?.premiumData?.policyWisePremiumEstimation?.[policyId])
      );
    },
    getInsurerWiseCDBalance(insurerId) {
      return this.org.meta?.insurerWiseCDBalance?.[insurerId];
    },
    getInsurerPremium(insurerId) {
      const batchPremiumData =
        this.batchInSelection?.meta?.premiumData?.policyWisePremiumEstimation &&
        utils.deepClone(this.batchInSelection?.meta?.premiumData?.policyWisePremiumEstimation);
      const totalPremium = {
        premiumAddition: 0,
        premiumDeduction: 0,
      };
      for (const policyId in batchPremiumData) {
        const policyInsurerId = this.insurerPolicyMap[policyId];
        if (policyInsurerId === insurerId) {
          totalPremium.premiumAddition += batchPremiumData[policyId]?.premiumAddition || 0;
          totalPremium.premiumDeduction += batchPremiumData[policyId]?.premiumDeduction || 0;
        }
      }
      return totalPremium;
    },
    async fetchBatches() {
      this.isBatchesSummaryLoading = true;
      const { data } = await this.$apollo.query({
        query: resDefs.userChangeBatches.resourceListQuery,
        variables: {
          filter: {
            orgId: this.orgId,
            orgEntityId: this.orgEntityId,
          },
        },
      });

      this.batches = data.userChangeBatches.edges.map((n) => n.node);
      // TODO: Change ongoingOrCompletedBatches name to more appropriate, skipping to prevent other incoming changes from breaking
      this.ongoingOrCompletedBatches = this.batches.filter((batch) =>
        [
          EndorsementBatchStatus.ONGOING,
          EndorsementBatchStatus.OVERDUE,
          EndorsementBatchStatus.COMPLETED,
          EndorsementBatchStatus.EMPTY,
          EndorsementBatchStatus.REJECTED,
        ].includes(batch.status)
      );

      this.currentBatch = this.batches.find(
        (batch) =>
          batch.status === EndorsementBatchStatus.CURRENT &&
          (!this.orgEntityId || this.orgEntityId === batch.orgEntityId)
      );

      this.selectedBatch = this.batches.find((batch) => batch.id === this.$route.params?.batchId);

      const currentOrUpcomingStatuses = [EndorsementBatchStatus.CURRENT, EndorsementBatchStatus.UPCOMING];
      this.currentOrUpcomingBatches = this.batches.filter(
        (batch) =>
          currentOrUpcomingStatuses.includes(batch.status) &&
          (!this.orgEntityId || this.orgEntityId === batch.orgEntityId)
      );

      await this.refetchAllChanges();
      this.fetchDataForActiveScreen();
      this.isBatchesSummaryLoading = false;
    },
    async updateBatch(newBatchId) {
      const batchId = this.getSelectedBatchId;

      await this.$apollo.mutate({
        mutation: resDefs.userChanges.transferUserChangeBetweenBatches,
        variables: {
          for: "batchId",
          newBatchId,
          batchId,
        },
      });
      this.refreshAffectedTabs();
    },
    async markNoEndorsementFromHR() {
      let result;
      // filter and format the orgAdmin data
      const selectedOrgAdmins = this.getSelectedOrgAdmins;

      // filter and format the cxOwner data
      const selectedCXOwners = this.getSelectedCXAccountOwners;
      try {
        result = await this.$apollo.mutate({
          mutation: resDefs.userChangeBatches.markNoEndorsementFromHR,
          variables: {
            batchId: this.currentBatch?.id,
            orgAdmins: selectedOrgAdmins,
            cxEntities: selectedCXOwners,
          },
        });
      } catch (e) {
        console.error("API returned with an error");
      }
      if (result?.data?.markNoEndorsementFromHR?.success) {
        this.$store.commit("addToast", {
          variant: "success",
          message: `"No endorsement" marked for the ${this.monthOnCalendar} ${this.yearOfBatch} batch`,
        });
      } else {
        this.$store.commit("addToast", {
          variant: "danger",
          message: `Unable to mark "No Endorsement" for the ${this.monthOnCalendar} ${this.yearOfBatch} batch`,
        });
      }
      this.$bvModal.hide("basic-modal");
      setTimeout(() => {
        this.$router.go();
      }, 2000);
    },
    isDynamicPremiumEnabled() {
      return (
        !!this.org?.featureFlags?.RATER_BASED_PREMIUM_ESTIMATION &&
        !(
          !process.env.VUE_APP_DYNAMIC_PREMIUM_ESTIMATION ||
          process.env.VUE_APP_DYNAMIC_PREMIUM_ESTIMATION?.toLowerCase() === "false"
        )
      );
    },
    isSelectedBatchCurrent() {
      return !this.selectedBatch || this.selectedBatch.status === EndorsementBatchStatus.CURRENT;
    },
    noEndorsementTooltipText() {
      return this.isNoEndorsementBatch
        ? "This status will automatically change whenever there is any new endorsement request."
        : "";
    },
    async onSearchQueryInput(value) {
      if (this.activeScreenTab) {
        const variables = await this.$apollo.queries[`${this.activeScreenTab}`].options.variables();
        variables.filter.query = value;
        this.$apollo.queries[`${this.activeScreenTab}`].refetch();
        return;
      }
      const variables = await this.$apollo.queries[this.activeScreenName].options.variables();
      variables.filter.query = value;
      this.$apollo.queries[this.activeScreenName].refetch();
    },
    getRelevantRowCount(activeTable) {
      return this.activeTableIsMissingData
        ? activeTable.changes?.length
        : Object.keys(
            groupBy(activeTable.changes, (change) => {
              return change.user_id + "," + change.type;
            })
          )?.length;
    },
    getBasePath() {
      if (this.$route.path.includes("org-admin/")) {
        return "/org-admin/changes";
      }
      if (this.$route.path.includes("orgEntity/")) {
        return `/admin/review/${this.$route.params.orgId}/orgEntity/${this.$route.params.orgEntityId}/changes`;
      }
      return `/admin/review/${this.$route.params.orgId}/changes`;
    },
    getOffset(currentPage, pageSize = 10) {
      return (currentPage - 1) * pageSize;
    },
    displayChangesHeader() {
      return (
        !(
          this.$route.path.includes("org-admin/") &&
          [EndorsementBatchStatus.ONGOING, EndorsementBatchStatus.COMPLETED, EndorsementBatchStatus.REJECTED].includes(
            this.activeScreenName
          )
        ) &&
        (this.batchStructure.tabs || this.checkIfCompletedOrRejectedBatch)
      );
    },
    getDueDate() {
      const dueDate = this.endingDate;
      return {
        dateString: utils.getDateWithSuffix(dueDate),
        remainingDays: `${utils.getSingularOrPlural("Day", utils.getDateDifference(dueDate))}`,
      };
    },
    changeActiveBatch(activeBatch) {
      let path;
      if (activeBatch.status === EndorsementBatchStatus.CURRENT) {
        path = "changes/draft";
        this.showListForBatches = this.currentOrUpcomingBatches;
      } else if ([EndorsementBatchStatus.ONGOING, EndorsementBatchStatus.OVERDUE].includes(activeBatch.status)) {
        path = `changes/${activeBatch.status}/${activeBatch.id}`;
        if (this.viaSuperAdminScreen) {
          path += `/${UserChangeBatchStages.ORG_OK}`;
        } else if (activeBatch.status === EndorsementBatchStatus.ONGOING) {
          path = `changes/overview/${activeBatch.id}`;
        } else {
          path += `/${UserChangeBatchStages.UNAPPROVED}`;
        }
      } else if (
        [EndorsementBatchStatus.COMPLETED, EndorsementBatchStatus.REJECTED, EndorsementBatchStatus.EMPTY].includes(
          activeBatch.status
        )
      ) {
        if (activeBatch.status == EndorsementBatchStatus.COMPLETED && !this.viaSuperAdminScreen) {
          path = `changes/overview/${activeBatch.id}`;
        } else {
          path = `changes/${activeBatch.status}/${activeBatch.id}`;
        }
      }
      this.$router.push({ path });
    },
    changeActiveTab(activeTab) {
      this.activeScreenTab = activeTab;
      if (this.$route.params.tabName !== activeTab && this.activeScreenName !== "batchesSummary") {
        this.activeTab = this.batchStructure.tabs[activeTab];
        if (this.activeScreenName === "current") {
          this.$router.push({
            path: `${this.getBasePath()}/draft/${activeTab}`,
          });
        } else {
          this.$router.push({
            path: `${this.getBasePath()}/${this.activeScreenName}/${this.$route.params.batchId}/${activeTab}`,
          });
        }
      }
    },
    refetchTableData() {
      Object.keys(this.batchStructure.tabs).map((tabName) => {
        this.$apollo.queries[`${this.batchType}-${tabName}`].refetch();
      });
    },
    openReviewChangesModal() {
      this.$bvModal.show("banner-step-modal");
    },
    areTimelinesAllowed() {
      return !(
        [EndorsementBatchStatus.OVERDUE, EndorsementBatchStatus.EMPTY, EndorsementBatchStatus.REJECTED].includes(
          this.activeScreenName
        ) || this.isSelectedBatchNoEndorsement
      );
    },
    getTimelineStates() {
      const states = {
        "Collecting employee information": {
          heading: "Collecting Employee Information",
          info: `Collect and approve all endorsements data for this batch before it goes out to Nova on ${this.getSelectedBatchDate.submitDate}.`,
        },
        "Activating Benefits": {
          heading: "Activating Benefits",
          info: "Your batch is being processed by Nova to activate benefits.",
        },
        "Activation completed": {
          heading: "Activation Completed",
          info: "Your batch is processed and benefits have been activated by Nova.",
        },
      };

      const stateTypes = {
        completed: {
          headingColor: "gray-900",
          color: "teal-700",
          lineColor: "teal-700",
          status: "Completed",
          icon: "check-circle",
          chipVariant: "light-success",
        },
        ongoing: {
          headingColor: "gray-900",
          color: "mustard-600",
          lineColor: "mustard-600",
          status: "Ongoing",
          icon: "progress-1-wider",
          chipVariant: "light-warning",
        },
        pending: {
          headingColor: "gray-700",
          color: "gray-700",
          lineColor: "gray-500",
          status: "Pending",
          icon: "progress-1-thinner",
          chipVariant: "extra-light-secondary",
        },
      };

      switch (this.activeScreenName) {
        case "current":
          return [
            {
              ...states["Collecting employee information"],
              ...stateTypes.ongoing,
            },
            { ...states["Activating Benefits"], ...stateTypes.pending },
            { ...states["Activation completed"], ...stateTypes.pending },
          ];
        case "ongoing":
          return [
            {
              ...states["Collecting employee information"],
              ...stateTypes.completed,
            },
            { ...states["Activating Benefits"], ...stateTypes.ongoing },
            { ...states["Activation completed"], ...stateTypes.pending },
          ];
        case "completed":
          return [
            {
              ...states["Collecting employee information"],
              ...stateTypes.completed,
            },
            { ...states["Activating Benefits"], ...stateTypes.completed },
            { ...states["Activation completed"], ...stateTypes.completed },
          ];
      }
    },
    addSmartQueryForTable(queryName, clubbingData, cb) {
      clubbingData.variables.offset = this.getOffset(this.currentPage);

      this.$apollo.addSmartQuery(queryName, {
        query: this.orgAdminResDef.getClubbedUserChanges,
        variables: () => clubbingData.variables,
        fetchPolicy: "no-cache",
        skip: () => {
          if (this.activeScreenName === "batchesSummary" && this.getActiveBatchIdsArray?.length > 0) {
            return false;
          }
          return !(clubbingData.variables.filter.batchIds.length && clubbingData?.value === this.activeTable?.value);
        },
        update: (data) => {
          const transformedClubbedData = utils.deepClone(this.orgAdminResDef.transformGetClubbedUserChanges(data));
          if (this.activeScreenName === "batchesSummary") {
            this.currentBatchChangesCount[clubbingData?.value] = transformedClubbedData.totalCount;
          } else {
            this.selectedTabData.changes = transformedClubbedData.changes;
            this.selectedTabData.count = transformedClubbedData.totalCount;
            this.selectedTabData.segregatedCount = transformedClubbedData.segregatedCount;
            this.selectedTabData.obPendingCount = transformedClubbedData.obPendingCount;
          }

          if (cb) cb();
          this.isUserChangesLoading = false;
        },
      });
    },
    fetchDataForActiveScreen() {
      // add entity filter here
      const variables = {
        limit: this.pageSize,
        filter: {
          orgId: this.orgId,
          orgEntityId: this.orgEntityId,
          userChangesStatuses: [],
          query: this.searchQuery,
        },
      };

      this.isUserChangesLoading = true;
      if (this.batchStructure.tabs) {
        Object.keys(this.batchStructure.tabs).map((tabName) => {
          const clubbingData = this.batchStructure.tabs[tabName];
          clubbingData.variables = utils.deepClone(variables);
          if (
            [UserChangeBatchStages.ENROLLMENT_PENDING, UserChangeBatchStages.UNAPPROVED].includes(tabName) ||
            clubbingData.value === EndorsementBatchStatus.OVERDUE
          ) {
            clubbingData.variables.filter.clubbingType = tabName;
          }
          let userChangesStatuses = [];
          if (tabName === UserChangeBatchStages.ENROLLMENT_PENDING || tabName === UserChangeBatchStages.UNAPPROVED) {
            userChangesStatuses = [UserChangeStatus.DRAFT];
          } else if (tabName === UserChangeBatchStages.APPROVED) {
            userChangesStatuses = [
              UserChangeStatus.ORG_OK,
              UserChangeStatus.NOVA_OK,
              UserChangeStatus.PROVIDER_OK,
              UserChangeStatus.DONE,
            ];
          } else if (this.viaSuperAdminScreen) {
            userChangesStatuses = [tabName];
          }
          clubbingData.variables.filter.userChangesStatuses.push(...userChangesStatuses);
          clubbingData.variables.filter.batchIds = this.getActiveBatchIdsArray;
          this.addSmartQueryForTable(tabName, clubbingData, () => {
            if (this.batchStructure.defaultActiveTab === tabName) {
              this.changeActiveTab(this.batchStructure.defaultActiveTab);
            }
          });
        });
      } else {
        const clubbingData = this.batchStructure;
        clubbingData.variables = utils.deepClone(variables);
        clubbingData.variables.filter.clubbingType = this.activeScreenName;
        clubbingData.variables.filter.userChangesStatuses = [
          UserChangeStatus.DRAFT,
          UserChangeStatus.ORG_OK,
          UserChangeStatus.NOVA_OK,
          UserChangeStatus.PROVIDER_OK,
          UserChangeStatus.DONE,
          UserChangeStatus.REJECTED,
        ];
        clubbingData.variables.filter.batchIds = [this.$route.params.batchId];
        this.addSmartQueryForTable(this.activeScreenName, clubbingData);
      }
    },
    async refreshAffectedTabs(affectedTabs) {
      // TODO: (NV-693) Affected tabs will be passed once we implement overdue batches
      if (!affectedTabs) {
        affectedTabs = Object.keys(this.batchStructure.tabs);
      }
      affectedTabs.forEach((affectedTab) => {
        this.$apollo.queries[affectedTab].refetch();
      });

      // Refetch batch
      await this.onRefetchBatch(this.getSelectedBatchId);

      await this.refetchAllChanges();

      this.currentPage = 1;
      this.tabAlreadyRefreshed = true;
    },
    getSegregatedCount() {
      return {
        segregatedCount: {
          add: 0,
          delete: 0,
          update: 0,
        },
      };
    },
    getBatchPremiumPaymentStatus(batch) {
      return utils.getBatchPremiumPaymentStatus(batch, this.org);
    },
    checkIfBatchCompletedOrRejected(batch) {
      return [EndorsementBatchStatus.COMPLETED, EndorsementBatchStatus.REJECTED].includes(batch.status);
    },
    getTotalPremiumPerBatch(item, withRupeeSym) {
      if (this.checkIfBatchCompletedOrRejected(item) && !item.endorsementPremium) {
        return "N/A";
      }
      const premiumData = item.endorsementPremium ?? item?.meta?.premiumData?.policyWisePremiumEstimation;
      return utils.getTotalPremiumAcrossInsurers(premiumData, withRupeeSym);
    },
    handleResetFloatingBar() {
      this.$refs.endorsementTable.resetFloatingToolbar(); // we are calling a method of a child component
    },
    async onRefetchBatch(id) {
      const { data } = await this.$apollo.query({
        query: resDefs.userChangeBatches.singleQuery,
        variables: { id },
        fetchPolicy: "no-cache",
      });

      this.updateBatchData(this.batches, data.node);
      this.updateBatchData(this.ongoingOrCompletedBatches, data.node);
      this.updateBatchData(this.currentOrUpcomingBatches, data.node);

      if (data.node.status === EndorsementBatchStatus.CURRENT) {
        this.currentBatch = data.node;
      }
      this.selectedBatch = data.node;
    },
    updateBatchData(batches, updatedBatch) {
      const index = batches.findIndex((batch) => batch.id === updatedBatch.id);
      if (index !== -1) {
        batches[index] = updatedBatch;
      }
    },
    async getAllChangesCount() {
      const { data } = await this.$apollo.query({
        query: this.orgAdminResDef.getClubbedUserChangesCount,
        fetchPolicy: "no-cache",
        variables: {
          filter: {
            orgId: this.orgId,
            orgEntityId: this.orgEntityId,
            batchIds: this.getActiveBatchIdsArray,
          },
        },
      });
      const transformedClubbedData = utils.deepClone(this.orgAdminResDef.transformGetClubbedUserChangesCount(data));
      return transformedClubbedData;
    },
    async refetchAllChanges() {
      if (!this.getActiveBatchIdsArray?.length) return;

      const transformedClubbedData = await this.getAllChangesCount();
      this.allClubbedChangesCount = transformedClubbedData?.countByBatchStage || {};
    },
    isWithinLast3Months(dateString) {
      const currentDate = new Date();
      const objectDate = new Date(dateString);
      const threeMonthsAgo = new Date();
      threeMonthsAgo.setMonth(currentDate.getMonth() - 3);
      return objectDate >= threeMonthsAgo;
    },
    totalEstimatedPremium() {
      let totalShortFallAndBufferAmount = { shortFallAmount: 0, bufferAmount: 0 };
      const orgBenefits = {};
      this.org?.benefits?.forEach((benefit) => {
        orgBenefits[benefit.node.id] = benefit.node;
      });

      const relevantBatches = this.batches.filter(
        (batch) =>
          this.isWithinLast3Months(batch.endingAt) &&
          ![
            UserChangeBatchStatus.DONE,
            UserChangeBatchStatus.REJECTED,
            UserChangeBatchStatus.DELETED,
            UserChangeBatchStatus.EMPTY,
          ].includes(batch.dbStatus)
      );
      const totalPremiumInsurerWise = this.getTotalPremiumInsurerWise(relevantBatches, orgBenefits);
      const insurerWiseCdBalance = this.org.meta.insurerWiseCDBalance;
      if (!insurerWiseCdBalance || Object.keys(insurerWiseCdBalance).length === 0) {
        return totalShortFallAndBufferAmount;
      }

      totalShortFallAndBufferAmount = this.getTotalShortFallAmountInsurerWise(
        insurerWiseCdBalance,
        totalPremiumInsurerWise
      );

      return totalShortFallAndBufferAmount;
    },
    getTotalPremiumInsurerWise(batches, orgBenefits) {
      orgBenefits = Object.values(orgBenefits)?.filter((item) => item.isPolicy === true);

      const totalEstimatedPremiumInsurerWise = orgBenefits.reduce((result, item) => {
        let policyWiseEstimation;
        if (result.has(item.insurer.id)) {
          policyWiseEstimation = result.get(item.insurer.id);
        } else {
          policyWiseEstimation = new Map();
          if (item?.insurer?.id) {
            result.set(item.insurer.id, policyWiseEstimation);
          }
        }

        const policyData = { totalPremium: 0, totalPremiumAddition: 0, totalPremiumDeductions: 0 };
        batches.forEach((batch) => {
          const policyEstimation =
            batch.meta?.premiumData?.policyWisePremiumEstimation &&
            batch.meta?.premiumData?.policyWisePremiumEstimation[item.id];
          if (policyEstimation) {
            policyData.totalPremiumAddition += policyEstimation.premiumAddition;
            policyData.totalPremiumDeductions += policyEstimation.premiumDeduction;
            policyData.totalPremium = policyData.totalPremiumAddition - policyData.totalPremiumDeductions;
          }
        });

        if (item?.id) {
          policyWiseEstimation.set(item.id, policyData);
        }
        return result;
      }, new Map());
      return totalEstimatedPremiumInsurerWise;
    },

    getTotalShortFallAmountInsurerWise(insurerWiseCdBalance, estimatedPremiumInsurerWise) {
      let shortFallAmount = 0;
      let bufferAmount = 0;

      for (const insurerId in insurerWiseCdBalance) {
        const policyValues = estimatedPremiumInsurerWise.get(insurerId);
        let totalPremiums = 0;

        if (policyValues) {
          policyValues.forEach((policy) => {
            totalPremiums += policy.totalPremium;
          });
        }

        const cdBalance = insurerWiseCdBalance[insurerId].cdBalance;
        const minBalance = insurerWiseCdBalance[insurerId].minCDBalance;

        if (totalPremiums > cdBalance) {
          const cdShortFall = totalPremiums - cdBalance;
          shortFallAmount = shortFallAmount + cdShortFall;
        }
        if (cdBalance < minBalance) {
          bufferAmount = bufferAmount + (minBalance - cdBalance);
        }
      }

      return { shortFallAmount, bufferAmount };
    },

    onRefetchComments() {
      this.$apollo.queries.comments.refetch();
    },
  },
  apollo: {
    org: {
      query: orgDef.singleQuery,
      skip() {
        return !this.orgId;
      },
      variables() {
        return {
          id: this.orgId,
        };
      },
      update(data) {
        return data.node;
      },
    },
    orgAdmins: {
      skip() {
        return !this.orgId || !this.isSuperAdmin;
      },
      query: gql`
        query getActiveOrgAdmins($orgIds: [ID]!, $orgEntityId: ID) {
          getActiveOrgAdmins(orgIds: $orgIds, orgEntityId: $orgEntityId) {
            edges {
              node {
                userId
                name
                email
                orgId
              }
            }
            totalCount
          }
        }
      `,
      variables() {
        return {
          orgIds: [this.orgId],
          orgEntityId: this.orgEntityId,
        };
      },
      update(data) {
        return data.getActiveOrgAdmins.edges.map(({ node }) => node);
      },
    },
    CXAccountOwners: {
      skip() {
        return !this.orgId || !this.isSuperAdmin;
      },
      query: resDefs.CXAccountOwners.listQuery,
      variables() {
        return {
          filter: {
            orgId: this.orgId,
          },
        };
      },
      update(data) {
        return data.CXAccountOwners.edges.map(({ node }) => node);
      },
    },
    comments: {
      skip() {
        return !this.isSuperAdmin || !this.getSelectedBatchId;
      },
      query: resDefs.comments.getBatchComments,
      variables() {
        return {
          batchId: this.getSelectedBatchId,
          orgId: this.orgId,
        };
      },
      update(data) {
        this.comments = data.getBatchComments.edges.map(({ node }) => node) || [];
        return this.comments;
      },
      fetchPolicy: "no-cache",
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";

.n-tab-n-table-container {
  .nav-tabs > .nav-item > a,
  .nav-tabs > .nav-item > a:focus,
  .nav-tabs > .nav-item > a:hover {
    border: 0;
  }
}
.rotated {
  transform: rotate(135deg);
}

.endorsbatch-caltext-1 {
  top: 32%;
  left: 37%;
}
.endorsbatch-caltext-2 {
  font-size: 6px;
  top: 55%;
  left: 43%;
}

.dash-subtitle {
  max-width: 30vw;
}

.vertical-separator {
  border-right: 1px solid $gray-400;
}

.dashboard-header {
  border-bottom: 1px solid $gray-300;
}

.no-endorsements-banner {
  border-radius: $border-radius;
  background: $blue-100 url("~@/assets/images/emp-card.svg") right no-repeat;
}

.checkbox-width1 {
  padding-left: 7px !important;
  padding-right: 0px !important;
}

.endorsement-table-container {
  width: -webkit-fill-available;
}

.endorsement-popover {
  display: inline-block;
}

.chip-container-endorsement {
  width: 90%;
}

.light-success {
  .n-chip {
    background-color: $teal-100 !important;
    color: $teal-800 !important;
  }
}
.light-warning {
  .n-chip {
    background-color: $mustard-100 !important;
    color: $mustard-800 !important;
  }
}
.light-secondary {
  .n-chip {
    background-color: $gray-100 !important;
    color: $gray-900 !important;
    border: $border-width-custom-1 solid $gray-300 !important;
  }
}

.info-container {
  border: 1px solid $red-500;
}
.warn-container {
  height: 4rem;
  border: 1px solid $mustard-400;
}
.icon-container {
  padding: 0 !important;
  width: max-content;
  border-radius: 50%;
  height: max-content;
}

.batch-comments-btn {
  height: 42px;
  width: 42px;
}
</style>
