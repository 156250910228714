<template lang="pug">
.booking-form
  n-spinner(:active="isLoading", is-full-screen)
  checkup-dependents-selection(v-if="currentStep===CheckupRoutes.DEPENDENT_SELECTION", @goToNextStep="goToNextStep", @backHandler="backHandler")
  checkup-address-selection(v-if="currentStep===CheckupRoutes.ADDRESS_SELECTION && !isAddressSelected",
    @goToNextStep="goToNextStep",
    @backHandler="backHandler",
    @sync-ahc-prospect-state="syncAhcProspectState")
  provider-selection(v-if="currentStep===CheckupRoutes.PROVIDER_SELECTION",
    :isHomeCollection="isHomeCollection",
    @goToNextStep="goToNextStep",
    @backHandler="backHandler",
    @sync-ahc-prospect-state="syncAhcProspectState")
  review-booking-details(v-if="currentStep===CheckupRoutes.CHECKOUT",
    ref="reviewPage",
    @backHandler="backHandler",
    @create-checkup-booking="debouncedCreateCheckupBooking")
  booking-request-modal
</template>

<script>
import { mapGetters } from "vuex";
import { debounce } from "lodash";
import { displayRazorpay } from "../payments/razorpay/config";
import { NovaProductType } from "../novaProducts/enum";
import { postalApi } from "../../../utils/misc";
import CheckupAddressSelection from "./components/CheckupAddressSelection.vue";
import ProviderSelection from "./components/ProviderSelection.vue";
import ProgressIndicator from "./components/ProgressIndicator.vue";
import CheckupDependentsSelection from "./components/DependentsSelection.vue";
import resDefs from "./definitions.js";
import BookingRequestModal from "./components/BookingRequestModal.vue";
import ReviewBookingDetails from "./components/ReviewBookingDetails.vue";
import utils from "@/utils";
import { CheckupPackageSampleCollectionType, CheckupRoutes } from "@/common/enums";

export default {
  name: "BookingForm",
  components: {
    ProgressIndicator,
    CheckupDependentsSelection,
    CheckupAddressSelection,
    ProviderSelection,
    BookingRequestModal,
    ReviewBookingDetails,
  },
  props: {
    user: {
      type: Object,
      default: () => {},
    },
    isProceedButtonDisabled: {
      type: Boolean,
      default: false,
    },
    linearPackages: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isLoading: false,
      checkupPackage: {},
      dependents: [],
      novaOrderId: null,
    };
  },
  computed: {
    CheckupRoutes: () => CheckupRoutes,
    ...mapGetters(["ahcProspectState", "isCheckupAdmin"]),
    sampleCollectionType() {
      return this.ahcProspectState?.selectedPackage?.sampleCollectionType || "";
    },
    selectedMembers() {
      return this.ahcProspectState?.selectedMembers || [];
    },
    isHomeCollection() {
      return this.checkupPackage.sampleCollectionType === CheckupPackageSampleCollectionType.HOME_COLLECTION;
    },
    isAddressSelected() {
      return this.ahcProspectState?.isAddressSelected || false;
    },
    contactDetails() {
      return this.ahcProspectState?.contactDetails || {};
    },
    currentStep() {
      return this.ahcProspectState?.currentStep;
    },
    bookingUser() {
      if (this.isCheckupAdmin) return this.$route.params?.bookingUser;
      return this.user;
    },
    billingAddress() {
      if (!this.addressData || Object.keys(this.addressData).length === 0) return "";

      const { address, address_line_one, address_line_two, pincode, state } = this.addressData;

      const fullAddress = address
        ? `${address} - ${pincode}`
        : `${address_line_one || ""}, ${address_line_two || ""}, ${city || ""}, ${state || ""} - ${pincode || ""}`;

      return fullAddress;
    },
    addressData() {
      return this.ahcProspectState?.billingAddress?.address
        ? this.ahcProspectState.billingAddress
        : this.ahcProspectState.selectedAddress || {};
    },
  },
  created() {
    this.debouncedCreateCheckupBooking = debounce(this.createCheckupBooking, 500);
  },
  methods: {
    async getStateFromPincode(pincode) {
      let state = "";
      // TODO: use the pincode patterns to fetch the states and remove the api call here
      const location = await postalApi(pincode);
      if (!location || location.status === "Error") {
        return "";
      }
      state = location?.result?.State;
      return state;
    },
    onPaymentSuccess(res) {
      this.$router.push({
        name: "payment_check",
        params: { pgOrderId: res.razorpay_order_id, productType: NovaProductType.CHECKUP_PACKAGES },
      });
    },
    async backHandler() {
      const previousStageMap = {
        [this.CheckupRoutes.ADDRESS_SELECTION]: this.CheckupRoutes.DEPENDENT_SELECTION,
        [this.CheckupRoutes.PROVIDER_SELECTION]: this.CheckupRoutes.ADDRESS_SELECTION,
        [this.CheckupRoutes.CHECKOUT]: this.CheckupRoutes.PROVIDER_SELECTION,
      };

      if (this.currentStep === this.CheckupRoutes.DEPENDENT_SELECTION) {
        this.$store.commit("resetAhcProspectState");
        if (this.isCheckupAdmin) {
          this.$router.push({
            name: "scheduleBooking",
            params: { benefitId: this.$route.params.benefitId, bookingUser: this.$route.params.bookingUser },
          });
        } else {
          this.$router.push({ name: "scheduleBooking" });
        }
      } else {
        const previousStage = previousStageMap[this.currentStep];
        const prospectState = { currentStep: previousStage };
        if (this.currentStep === this.CheckupRoutes.PROVIDER_SELECTION) {
          prospectState.selectedDateTimeSlot = {};
          prospectState.selectedProvider = {};
        }
        window.scrollTo({ top: 0, behavior: "smooth" });
        this.$store.commit("updateAhcProspectStateMeta", prospectState);
      }
    },
    async createCheckupBooking() {
      this.$emit("sync-ahc-prospect-state", { currentStep: CheckupRoutes.CHECKOUT });
      const { name, address, partnerId, meta } = this.ahcProspectState?.selectedProvider || {};
      if (!utils.validateIndianMobileNumbers(this.contactDetails?.phoneNumber)) {
        this.$store.commit("addToast", {
          variant: "danger",
          message: "Please provide a valid phone number",
        });
        this.$store.commit("endCheckupBookingProcess");
        return;
      }

      this.isLoading = true;
      try {
        const billingAddress = this.billingAddress;
        const { state, pincode } = this.addressData;
        const stateOfResidence = state || (await this.getStateFromPincode(pincode));
        const membersBookingData = this.selectedMembers.map(
          ({
            userId,
            dependentId,
            extraMemberId,
            checkupPackage: { id: checkupPackageId },
            name,
            age,
            gender,
            dob,
            relation,
          }) => ({
            userId,
            dependentId,
            extraMemberId,
            checkupPackageId,
            name,
            age,
            gender,
            dob,
            relation,
          })
        );

        const checkupPackageIds = this.selectedMembers.map(
          ({ checkupPackage: { id: checkupPackageId } }) => checkupPackageId
        );
        const variables = {
          userId: this.$route.params?.bookingUser?.id || this.user?.id,
          benefitId: this.$route.params.benefitId,
          membersBookingData,
          meta: {
            providerName: name,
            providerAddress: address,
            userAddress: { ...this.ahcProspectState.selectedAddress },
            userContactDetails: { ...this.contactDetails },
          },
          scheduledAt: this.ahcProspectState?.scheduledAt,
          partnerProviderId: partnerId,
          partnerProviderMeta: meta,
          billingAddress,
          stateOfResidence,
        };
        if (this.isCheckupAdmin) {
          variables.meta = {
            ...variables.meta,
            isAdminCheckupBooking: true,
            adminCheckupBookingEmail: this.user?.email,
          };
        }
        delete variables?.meta?.userAddress?.address;

        const existingCheckupBookings = await this.$apollo.query({
          query: resDefs.checkupBookings.listQuery,
          variables: {
            filter: {
              userId: variables.userId,
              partnerProviderId: partnerId,
            },
          },
        });
        const existingAppointments = existingCheckupBookings?.data?.checkupBookings?.edges
          ?.map((bookings) => {
            return bookings?.node?.checkupAppointments || [];
          })
          ?.flat();

        // Checking if some existing appointment already present for same package id, provider and timeslot
        for (const appointment of existingAppointments) {
          const appointmentScheduledAt = new Date(appointment?.node?.scheduledAt).getTime();
          const prospectScheduledAt = new Date(this.ahcProspectState?.scheduledAt).getTime();

          if (
            appointmentScheduledAt === prospectScheduledAt &&
            checkupPackageIds.includes(appointment?.node?.checkupPackage?.id)
          ) {
            this.$store.commit("addToast", {
              variant: "danger",
              message:
                "Appointment with same provider and timeslot already exists for the selected package, Please choose a different provider or timeslot!",
            });
            this.$store.commit("endCheckupBookingProcess");
            this.isLoading = false;
            return;
          }
        }

        const { data } = await this.$apollo.mutate({
          mutation: resDefs.checkupBookings.mutation,
          variables,
        });

        this.isLoading = false;
        const { novaOrder } = data.upsertCheckupBooking.checkupBooking;
        if (data && novaOrder.orderAmount) {
          const order = {
            description: `AHC booking for ${this.user.name} `,
            pgOrderId: novaOrder.pgOrderId,
            novaOrderId: novaOrder.id,
            productType: "checkup_packages",
            contactNumber: this.contactDetails?.phoneNumber,
            // TODO: Add user names here
            notes: { bookingFor: "All user names here" },
          };
          this.novaOrderId = novaOrder.id;
          displayRazorpay(order, this.onPaymentSuccess);
          this.$emit("skip-discard-modal");
          // TODO: Add success faliure journey here
        } else if (data && !novaOrder.orderAmount) {
          this.showBookingRequestModal();
          this.$store.commit("endCheckupBookingProcess");
        }
      } catch (err) {
        this.$store.commit("endCheckupBookingProcess");
        this.isLoading = false;
        console.log(err);
      }
    },
    showBookingRequestModal() {
      this.$bvModal.show("booking-request-modal");
      this.$emit("skip-discard-modal");
      // Wait for 3 seconds before redirecting
      setTimeout(() => {
        this.$store.commit("resetAhcProspectState");
        this.$bvModal.hide("booking-request-modal");
        if (this.isCheckupAdmin) {
          this.$router.push({
            name: "bookingHistory", // TODO: push to packages screen, if it is a linear package booking
            params: { benefitId: this.$route.params.benefitId, bookingUser: this.$route.params.bookingUser },
          });
        } else {
          this.$router.push(
            `/checkup/${this.$route.params.benefitId}/${CheckupRoutes.BOOKING_HISTORY}?bookingComplete=true`
          );
        }
      }, 3000);
    },
    async goToNextStep() {
      const nextStepMap = {
        [CheckupRoutes.DEPENDENT_SELECTION]: CheckupRoutes.ADDRESS_SELECTION,
        [CheckupRoutes.ADDRESS_SELECTION]: CheckupRoutes.PROVIDER_SELECTION,
        [CheckupRoutes.PROVIDER_SELECTION]: CheckupRoutes.CHECKOUT,
      };

      const currentStep = this.currentStep;
      const nextStep = nextStepMap[currentStep];
      window.scrollTo({ top: 0, behavior: "smooth" });
      this.$store.commit("updateAhcProspectStateMeta", { currentStep: nextStep });
      this.syncAhcProspectState({ currentStep });
    },
    syncAhcProspectState(options) {
      this.$emit("sync-ahc-prospect-state", options);
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/_variables.scss";
.booking-form {
  .progress {
    height: 2rem;
  }
  hr {
    border-color: $gray-300;
  }
}
.icon-arrow:hover {
  background-color: $teal-200;
}
</style>
