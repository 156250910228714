<template lang="pug">
div
  n-toast(
    v-for="item in $store.state.toasts",
    :variant="item.variant",
    :key="item.id",
    :id="item.id",
    @hidden="toastDismissed(item.id)")
    template(v-slot:toast-title)
      .toast-title
        span {{getTitle(item)}}
        n-chip(v-if="errorCount(item.message) > 1", :variant="item.variant", pill) {{errorCount(item.message)}}
    template(v-slot:toast-content)
      span.align-middle  {{ item.message }}
</template>

<script>
import NToast from "./NovaToast.vue";
import NChip from "@/components/NovaChip.vue";

export default {
  components: {
    NChip,
    NToast,
  },
  methods: {
    toastDismissed(id) {
      this.$store.commit("clearToast", id);
    },
    /**
     * Function that gets the title for the toast.
     * @param {*} item - If you pass the item.title attribute, it overrides the default title.
     */
    getTitle(item) {
      const defaultVariantTitles = {
        danger: "Oops!",
        success: "Success",
        warning: "Important note",
      };

      return item.title || defaultVariantTitles[item.variant] || "Notification";
    },
    errorCount(message) {
      return this.$store.getters.getToastsCount(message);
    },
  },
};
</script>

<style scoped lang="scss">
.toast-title {
  display: flex;
  gap: 0.5rem;
}
</style>
