<template lang="pug">
.premium-card-container.m-2.border-top-gray-400
  .premium-card.cursor-pointer.align-items-center
    .bs-hero
      img.hero(:src="require('@/assets/images/confirm-choice.png')")
      div.hero-content
        div.hero-title.font-xs Your super top-up
        div.hero-subtitle.hb4 Premium Details
        
    div.py-4.px-2.mx-2(v-if="!isNovaPlusSelected && !isNovaExtendedSelected")
     .hb6.font-sm Select coverage and deductible amount to view the premium details
    div(v-else)
      div.py-4.px-3(v-if="isNovaPlusSelected")
        .d-flex.align-items-center.mt-1.mb-2.w-100
          .text-gray-900.font-sm.font-weight-semibold.flex-grow-1 
            | Nova Plus&nbsp;
            i.font-sm.icon-question(
              :id="`plus-popup`"
            )
            premium-popover(:popover-id="'plus-popup'" :lives="getAllSelectedPlusMembers()", :trigger="'hover'")

        .d-flex.align-items-center.my-1.w-100.text-gray-800.font-weight-medium
          .font-sm.flex-grow-1 Premium
          .inr-value.font-sm ₹ {{ getFormattedINRWithFloatingPoint(novaPlusPremiumDetails?.premium) || 0 }}
        .d-flex.align-items-center.my-1.w-100.text-gray-800.font-weight-medium
          .font-sm.flex-grow-1 GST
          .inr-value.font-sm ₹ {{ getFormattedINRWithFloatingPoint(novaPlusPremiumDetails?.gst) || 0 }}

      div(v-if="isNovaExtendedSelected")
          
        hr.border-top-dashed.m-0

        div.py-4.px-3
          .d-flex.align-items-center.mt-1.mb-2.w-100
            .text-gray-900.font-sm.font-weight-semibold.flex-grow-1 
              | Nova Extended&nbsp;
              i.font-sm.icon-question(
                :id="`extended-popup`"
              )
              premium-popover(:popover-id="'extended-popup'" :lives="getAllSelectedExtendedMembers()", :trigger="'hover'")
          .d-flex.align-items-center.my-1.w-100.text-gray-800.font-weight-medium
            .font-sm.flex-grow-1 Premium
            .inr-value.font-sm ₹ {{ getFormattedINRWithFloatingPoint(novaExtendedPremiumDetails?.premium) || 0 }}
          .d-flex.align-items-center.my-1.w-100.text-gray-800.font-weight-medium
            .font-sm.flex-grow-1 GST
            .inr-value.font-sm ₹ {{ getFormattedINRWithFloatingPoint(novaExtendedPremiumDetails?.gst) || 0 }}
      
      hr.border-top-dashed.m-0
      
      div.pt-4.px-3
        .d-flex.align-items-center.my-1.w-100.text-gray-800.font-weight-medium
          .font-sm.flex-grow-1 Total Premium
          .inr-value.font-lg.text-teal-800.font-weight-semibold ₹ {{ getFormattedINRWithFloatingPoint(totalPremium) }}
        
      
      .py-4.mx-2
        n-button.mt-2.pb-2.mb-2.px-auto.desk-view.w-100(:buttonText="buttonText || 'Confirm & Continue ->'", variant="dark", @click="nextAction()")
        .text-center 
          span.font-xs
            | Ensure top-up coverage is added for all required members before moving forward. You will not be able to add members until next renewal ☘️

  div.mt-4(v-if="currentStep === 'policy-summary'")
    span.text-gray-800.text-sm Your Super Top-up insurance is provided by
    plan-info(
      :image="getPlusPolicy?.insurer?.s3Url",
      :value="getPlusPolicy?.insurer?.name",
      size="lg")

  

</template>
<script>
import NIcon from "../../../../../components/NovaIcon.vue";
import NButton from "../../../../../components/NovaButton.vue";
import NPopover from "@/components/NovaPopover.vue";
import NAvatar from "@/components/Avatar.vue";
import PremiumPopover from "./PremiumPopover.vue";
import PlanInfo from "../../../components/PlanInfo.vue";
import mixin from "./../mixin";
export default {
  name: "PremiumCard",
  mixins: [mixin],
  components: {
    NIcon,
    NButton,
    NIcon,
    NAvatar,
    NPopover,
    PremiumPopover,
    PlanInfo,
  },
  data() {
    return {};
  },
  props: {
    header: {
      type: String,
    },
    title: {
      type: String,
      default: "",
    },
    bodyText1: {
      type: String,
      default: "",
    },
    bodyText2: {
      type: String,
      default: "",
    },
    buttonText: {
      type: String,
    },
    nextRoute: {
      type: String,
    },
  },
  computed: {},
  methods: {
    nextAction() {
      if (this.$route.path.includes("/policy-summary")) {
        this.$emit("finalConfirmationClicked");
      } else {
        this.$router.push(`${this.nextRoute}`);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
.premium-card-container {
  .premium-card {
    border-radius: 0.375rem;
    background-color: white;
    border: 0.125rem solid $gray-300;
    .bs-hero {
      height: 10vh;
      position: relative;
      .hero {
        object-fit: cover;
        height: 100%;
        width: 100%;
      }

      .hero-content {
        position: absolute;
        top: 50%;
        left: 30%;
        transform: translate(-50%, -50%);
        color: white; /* Set text color as needed */
      }

      .hero-title,
      .hero-subtitle {
        margin: 0;
      }
    }
  }

  .hero-img-md {
    height: 17vh;
  }
}
</style>
