<template lang="pug">
div.py-7
  .d-flex.justify-content-center
    img.sizing(:src="require(`@/assets/images/hourglass.svg`)")
  div
    .empty-states-msg1.font-weight-semibold.font-xl No data added yet!
    .empty-states-msg2.font-lg.font-weight-medium.mx-auto(v-if="showSubtext")  Not for long though. Your documents will appear here as and when they are available ✨
    .empty-states-msg2.font-lg.font-weight-medium.mx-auto.mt-2(v-if="showSubtext")  If you need any assistance, we are just a click away!
  .d-flex.justify-content-center.mt-4(v-if="showSubtext")
    n-button(buttonText="Contact Nova", variant="dark", @click = "toggle")
</template>

<script>
import NButton from "../../NovaButton.vue";
export default {
  name: "Documents",
  components: {
    NButton,
  },
  computed: {
    showSubtext() {
      return this.$route.fullPath.includes("org-admin");
    },
  },
  methods: {
    toggle() {
      this.$store.commit("toggleSupportContainer");
    },
  },
};
</script>

<style>
.sizing {
  width: 9.375rem;
  height: 9.375rem;
}
</style>
