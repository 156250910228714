import { ClaimType } from "@/common/enums/claims/claimType";

export const dependentDropdownOptions = [
  { label: "Spouse", value: "spouse" },
  { label: "Child", value: "child" },
  { label: "Parent", value: "parent" },
  { label: "Parent in Law", value: "parent-in-law" },
  { label: "Sibling", value: "sibling" },
];

export const ClaimsGuideContentList = {
  [ClaimType.REIMBURSEMENT]: {
    intro1: {
      id: "#reimbursement-intro-section-1",
      title: "A brief introduction to Insurance Claims",
    },
    intro2: {
      id: "#reimbursement-intro-section-2",
      title: "What are Reimbursement Claims ?",
    },
    fiveSteps: {
      id: "#reimbursement-5-steps-to-get-your-claim",
      title: "5 Steps to get your claim reimbursed",
    },
    exploreMoreTopics: {
      id: "#reimbursement-explore-more-topics",
      title: "Explore more topics on our claims guide",
    },
    listOfDocs: {
      id: "#reimbursement-list-of-docs",
      title: "List of documents you need to collect from the hospital",
    },
    otherDocs: {
      id: "#reimbursement-other-docs",
      title: "Other documents you need to upload while filing a claim",
    },
    processOfClaimIntimation: {
      id: "#reimbursement-process-of-claim-intimation",
      title: "Process of claim intimation on Nova Portal",
    },
    processOfDocUpload: {
      id: "#reimbursement-process-of-doc-upload",
      title: "Process of uploading documents on the Nova Portal",
    },
    diffTable: {
      id: "#reimbursement-diff",
      title: "Difference between Cashless and Reimbursement Claims",
    },
    faq: {
      id: "#reimbursement-faq",
      title: "Frequently Asked Questions about Reimbursement Claims",
    },
  },
  [ClaimType.CASHLESS]: {
    intro1: {
      id: "#cashless-intro-section-1",
      title: "What are cashless Claims ?",
    },
    intro2: {
      id: "#cashless-intro-section-2",
      title: "Cashless Claim Process",
    },
    stepsToFile: {
      id: "#cashless-steps-to-file",
      title: "4 steps to file your cashless claim",
    },
    exploreMoreTopics: {
      id: "#cashless-explore-more-topics",
      title: "Explore more topics on our claims guide",
    },
    diffTable: {
      id: "#cashless-diff",
      title: "Difference between Cashless and Reimbursement Claims",
    },
    faq: {
      id: "#cashless-faq",
      title: "Frequently Asked Questions about Cashless Claims",
    },
  },
};

export const claimsGuideDifferenceItems = [
  {
    differences: "What is it?",
    cashless_claims:
      "A cashless claim involves visiting a hospital within the insurer's network, where the health insurer directly settles your bills.",
    reimbursement_claims:
      "You bear the upfront cost of hospital bills after treatment. You then present these bills and relevant medical documents to your insurer for claim approval.",
  },
  {
    differences: "Claim Process",
    cashless_claims:
      "A cashless claim involves visiting a hospital within the insurer's network, where the health insurer directly settles your bills.",
    reimbursement_claims:
      "You bear the upfront cost of hospital bills after treatment. You then present these bills and relevant medical documents to your insurer for claim approval.",
  },
  {
    differences: "Claim Settlement",
    cashless_claims:
      "A cashless claim involves visiting a hospital within the insurer's network, where the health insurer directly settles your bills.",
    reimbursement_claims:
      "You bear the upfront cost of hospital bills after treatment. You then present these bills and relevant medical documents to your insurer for claim approval.",
  },
  {
    differences: "Claim Approval",
    cashless_claims:
      "Should get approval 72-hours before in case of planned hospitalisation, and within 24-hours in case of a medical emergency.",
    reimbursement_claims: "No Claim Approval Required.",
  },
  {
    differences: "Time Taken",
    cashless_claims: "Generally quicker when compared to reimbursement.",
    reimbursement_claims: "May take longer as it requires processing of reimbursement claims.",
  },
  {
    differences: "Documents Required",
    cashless_claims:
      "Need to fill in the required form given by the TPA at the hospital. You don’t need to submit bills or other documents.",
    reimbursement_claims:
      "You need to submit your health invoices, including medical bills, doctor’s prescriptions, and any other relevant information.",
  },
  {
    differences: "Flexibility",
    cashless_claims: "The insured must opt for treatment at a hospital within the insurer's network.",
    reimbursement_claims:
      "Treatment can be availed at the hospital of the insured's choice. Verification of treatment is required to claim reimbursement.",
  },
  {
    differences: "Hospital Availability",
    cashless_claims: "Can only be availed at a hospital in the insurer's network.",
    reimbursement_claims: "Treatment can be availed at any hospital within the scope of the insurance.",
  },
];

export const ADDITIONAL_PARAMS_FOR_BENEFIT = ["name", "email", "gender", "dob", "contactNumber", "orgName"];
