import { getFilteredListQuery, getSingleQuery } from "../queries";
import { userChangeFragment, userChangesFragment } from "../fragments";
import userChangesSchema from "../schemas/userChanges";
import userChangesMutations from "../mutations/userChanges";

export default {
  gqlType: "UserChange",
  displayName: "Review Changes",
  fields: ["employeeId", "name", "type", { key: "date", label: "DoJ/DoE" }, "jobGrade"],
  listQuery: getFilteredListQuery("UserChange"),
  singleQuery: getSingleQuery("UserChange"),
  ...userChangesSchema,
  ...userChangesMutations,
  transform: ({ node }) => {
    return { ...node, orgId: node.org.id };
  },
  transformUpdateUserOrDependentInfo: (data) => data.updateUserOrDependentInfo.userChange,
  getNodeFromUpsertData: (data) => {
    return data.createUserChange.userChange;
  },
  transformAddBenefitToUserAndDependentsMutation: (data) => data.addBenefitToUserAndDependentsMutation.userChange,
  userChangesFragment: userChangesFragment,
  userChangesFragmentName: "UserChanges",
  userChangeFragment: userChangeFragment,
  userChangeFragmentName: "UserChange",
  getEdgesFromData: (data) => data.userChanges.edges,
  getTotalCountFromData: (data) => data.userChanges.totalCount,
};
