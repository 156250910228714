<template lang="pug">
.text-center
  .font-weight-semibold.font-lg.text-center.mb-3 Verify your Phone Number
  .flex.align-items-center.justify-content-center.mx-3
    .text-center.font-inter.font-weight-medium.text-gray-800.text-md.w-100.px-md-4(style="line-height: 1.5;")
      span
        | Please enter the 6-digit OTP sent to you via SMS on
        span.font-weight-semibold.mr-1 +91
      span.font-weight-semibold {{ profileModel.meta?.contactNumber }}
      n-icon.ml-2(name="edit", variant="success", :size="1",@click="handleEditClick")
  span
    otp-input.my-4.w-100(
      :digits="6",
      :placeholder="''",
      :isValid="isOtpStateValid",
      :isDisabled="isOTPInputDisabled",
      :resetSignal="resetInputSignal",
      :errorClass="messageType",
      @on-complete="handleOtpComplete"
      @on-changed="handleOtpChanged"
      :separateInputClass="successString ? successString: null")
      template(v-slot:errorMessage)
        .d-flex.align-items-center.justify-content-flex-start.mb-3.mt-2(:class="{ 'ml-4': !forLogin, 'ml-md-4': !forLogin}")
          n-icon.mr-2(:name="messageData[messageType]?.icon",:variant="messageData[messageType]?.variant",:size="1",v-if="messageData[messageType]?.message", :class="{'ml-2': forLogin, 'ml-3': !forLogin }")
          span.font-sm.font-weight-medium.my-1(v-if="messageData[messageType]?.message",:class="messageType === 'error' ? 'text-red-500' : 'text-teal-700'",) {{messageData[messageType]?.message}}
          span.font-sm.font-weight-medium.my-1(v-else) &nbsp;
    div(:class="{'mx-2': forLogin, 'mx-3': !forLogin }")
      .justify-content-between.w-100(:class="{'d-none': !showActions, 'd-flex': showActions}")
        count-timer.otp-timer.font-weight-semibold.ml-1(:initialTime="initialTimer", :resetSignal="resetTimerSignal", @time-up="handleOtpTimeUp")
        b-link.font-weight-semibold.font-md.font-inter.text-decoration-none(v-if="showResendButton", :disabled="disableResend", @click.prevent="handleResendOtp", ref="resendLinkRef")
            span.resend-link.text-teal-700.mr-1(:style="{ 'opacity': disableResend ? '0.3' : '1'}") Resend OTP
            span.timer-style.d-inline-block.text-gray-900(v-if="disableResend") ({{ resendTimer }}s)
    .my-2.py-1.mt-1(v-if="!showActions")
  div(:class="{ 'mx-3': !forLogin,}")
    .w-100.mt-4.pt-2
      n-button.d-md-block.d-sm-inline.w-100.mt-2.mb-1(variant="success", buttonText="Verify & Continue",@click="handleConfirmOtp",size="md",:disabled="!isOtpValid || !valueUpdated")
  #recaptcha_widget
</template>

<script>
import OtpInput from "../../../../components/OtpInput.vue";
import CountTimer from "../../../../components/CountTimer.vue";
import otpMutation from "../../admin/mutations/otp";
import { OtpErrorType } from "../../../../common/enums/otpErrors.enum";
import NButton from "@/components/NovaButton.vue";
import NIcon from "@/components/NovaIcon.vue";
import SpecialModal from "@/components/SpecialModal.vue";

export default {
  name: "OtpModal",
  components: {
    OtpInput,
    CountTimer,
    NButton,
    SpecialModal,
    NIcon,
  },
  props: {
    profileModel: {
      type: Object,
      default: () => ({}),
    },
    user: {
      type: Object,
      default: () => ({}),
    },
    forLogin: {
      type: Boolean,
      default: false,
    },
    customSendOtpMutation: {
      type: Function,
      default: undefined,
    },
    customVerifyOtpMutation: {
      type: Function,
      default: undefined,
    },
    sendOtpOnMount: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["otp-verified", "cancelled"],
  data() {
    return {
      messageType: "",
      errorString: "",
      successString: "",
      showActions: true,
      otp: "",
      attemptsLeft: 3,
      initialTimer: "5:00",
      showResendButton: false,
      resetTimerSignal: false,
      resetInputSignal: false,
      isConfirmBtnEnabled: true,
      isOTPInputDisabled: false,
      isReqInFlight: false,
      otpGenerated: 0,
      otpErrorStateTimeout: null,
      resendInterval: null,
      resendTimer: 5,
      valueUpdated: false,
    };
  },
  computed: {
    isOtpStateValid() {
      return this.errorString === "";
    },
    isOtpValid() {
      return this.otp.length === 6 && this.isConfirmBtnEnabled;
    },
    disableResend() {
      return this.resendTimer > 0;
    },
    messageData() {
      const errorData = {
        icon: "error",
        variant: "danger",
        message: this.errorString,
      };

      const successData = {
        icon: "check-circle",
        variant: "success",
        message: this.successString,
      };
      return {
        error: errorData,
        success: successData,
      };
    },
  },
  mounted() {
    if (this.sendOtpOnMount) {
      this.sendOTP();
    } else {
      this.otpGenerated = 1;
    }
    this.showResendButton = true;
    this.setupResendTimer();
  },
  beforeDestroy() {
    /** Ensure to remove the event listener when the component is destroyed */
    this.$root.$off("bv::modal::shown");
  },
  methods: {
    handleEditClick() {
      this.hideOtpModal();
      this.$emit("edit-clicked");
    },
    setupResendTimer() {
      this.resendTimer = 15;
      this.showResendButton = true;
      this.resendInterval = setInterval(() => {
        if (this.resendTimer === 1) {
          clearInterval(this.resendInterval);
        }
        this.resendTimer = this.resendTimer - 1;
      }, 1000);
    },
    handleOtpComplete(value) {
      this.valueUpdated = true;
      this.otp = value;
    },
    handleOtpChanged(value) {
      if (this.errorString) {
        this.valueUpdated = true;
      }
    },
    async checkOtpRetries() {
      if (this.attemptsLeft <= 0) {
        this.messageType = "error";
        this.errorString = "Authentication is disabled. Try again in 1 minute. ";
        this.showActions = false;
        this.isOTPInputDisabled = true;
        this.isConfirmBtnEnabled = false;
        this.resetInputSignal = !this.resetInputSignal;
        this.resetOtpErrorState(60, async () => {
          this.hideOtpModal();
          this.showActions = true;
          this.showResendButton = false;
          this.attemptsLeft = 3;
          this.resetTimerSignal = !this.resetTimerSignal;
          this.initialTimer = "5:00";
          this.isOTPInputDisabled = false;
          this.isConfirmBtnEnabled = true;
        });
      }
      return true;
    },
    async verifyOtpMutation() {
      if (this.customVerifyOtpMutation) return this.customVerifyOtpMutation(this.otp);
      return this.$apollo.mutate({
        mutation: otpMutation.verifyOtpMutation,
        variables: {
          userId: this.user?.id,
          contactNumber: this.profileModel.meta.contactNumber,
          otp: this.otp,
          consent: { whatsappConsent: this.profileModel.meta.whatsappUserConsent },
        },
      });
    },
    async handleConfirmOtp() {
      this.successString = "";
      if (this.isReqInFlight) return;
      const attemptsOver = await this.checkOtpRetries();
      if (!attemptsOver) return;
      try {
        this.isReqInFlight = true;
        this.attemptsLeft = this.attemptsLeft - 1;
        const res = await this.verifyOtpMutation();
        if (res.data.verifyOtp.status === false) {
          window.posthog.capture("otp_verification_failed", {
            attempts: this.attemptsLeft,
            user_id: this.user?.id,
            org_id: this.user?.orgId,
            org_name: this.user?.org?.name,
            total_otp_generated: this.otpGenerated,
            forLogin: this.forLogin,
          });
          if (this.attemptsLeft === 0 && this.otpGenerated >= 3) {
            return await this.onMaxOtpSent();
          }
          if (this.attemptsLeft === 0) {
            await this.checkOtpRetries();
            return;
          }
          this.messageType = "error";
          this.errorString = this.getConfirmOtpErrorString();
          this.showActions = true;
          this.valueUpdated = false;
        } else {
          this.errorString = "";
          this.isOtpVerified = true;

          this.$emit("otp-verified");
          window.posthog.capture("otp_verify_success", {
            attempts: 3 - this.attemptsLeft,
            user_id: this.user?.id,
            org_id: this.user?.orgId,
            org_name: this.user?.org?.name,
            forLogin: this.forLogin,
          });
        }
      } catch (error) {
        this.messageType = "error";
        const errMsg = error?.graphQLErrors[0]?.message;
        this.errorString = errMsg;
        if ([OtpErrorType.MAX_VERIFY_ATTEMPTS, OtpErrorType.OTP_NOT_FOUND].includes(errMsg)) {
          this.messageType = "error";
          this.errorString = "You have reached maximum attempts, please resend the otp";
          this.showActions = false;
          this.resetOtpErrorState(3, () => {
            this.$refs.resendLinkRef.focus();
          });
        }
      } finally {
        this.isReqInFlight = false;
      }
    },
    async handleResendOtp() {
      this.showResendButton = false;
      this.resetTimerSignal = !this.resetTimerSignal;
      this.attemptsLeft = 3;
      this.resetInputSignal = !this.resetInputSignal;
      this.otp = "";
      this.setupResendTimer();
      this.messageType = "success";
      this.successString = "We’ve sent an OTP again via SMS. Please check.";
      await this.sendOTP();
    },
    async sendOtpMutation() {
      if (this.customSendOtpMutation) {
        return this.customSendOtpMutation();
      }
      return this.$apollo.mutate({
        mutation: otpMutation.generateOtpMutation,
        variables: {
          userId: this.user?.id,
          contactNumber: this.profileModel.meta.contactNumber,
        },
      });
    },
    async sendOTP() {
      try {
        window.posthog.capture("otp_generated_success", {
          attempts: ++this.otpGenerated,
          user_id: this.user?.id,
          org_id: this.user?.orgId,
          org_name: this.user?.org?.name,
          forLogin: this.forLogin,
        });
        await this.sendOtpMutation();
      } catch (error) {
        window.posthog.capture("otp_generation_failed", {
          user_id: this.user?.id,
          org_id: this.user?.orgId,
          org_name: this.user?.org?.name,
          forLogin: this.forLogin,
        });
        const errMsg = error.graphQLErrors[0].message;
        if (errMsg.includes(OtpErrorType.MAX_GENERATION_ATTEMPTS)) {
          this.onMaxOtpSent();
        } else if (errMsg.includes(OtpErrorType.DUPLICATE_NUMBER)) {
          this.hideOtpModal();
        }
      }
    },
    async handleOtpTimeUp(time) {
      if (time !== "5:00") return;
      this.isOTPInputDisabled = true;
      this.isConfirmBtnEnabled = false;
      this.$refs.resendLinkRef.$el.style.visibility = "hidden";
      this.initialTimer = "0:30";
      this.resetInputSignal = !this.resetInputSignal;
      setTimeout(() => {
        this.isOTPInputDisabled = false;
        this.isConfirmBtnEnabled = true;
        this.$refs.resendLinkRef.$el.style.visibility = "visible";
        this.showResendButton = false;
        this.attemptsLeft = 3;
        this.resetInputSignal = !this.resetInputSignal;
        this.initialTimer = "5:00";
        this.otp = "";
      }, 30 * 1000);
    },
    resetOtpErrorState(timeinSeconds, cb) {
      if (this.otpErrorStateTimeout) clearTimeout(this.otpErrorStateTimeout);
      this.otpErrorStateTimeout = setTimeout(() => {
        this.errorString = "";
        this.showActions = true;
        if (cb) cb();
      }, timeinSeconds * 1000);
    },
    onMaxOtpSent() {
      this.showResendButton = true;
      this.messageType = "error";
      this.errorString = "Please wait for 10 minutes to try again!";
      this.resetInputSignal = !this.resetInputSignal;
      this.initialTimer = "9:00";
      this.isOTPInputDisabled = true;
      this.isConfirmBtnEnabled = false;
      this.showActions = false;
      // its unlikely user will wait for 10 mins on this page though
      this.resetOtpErrorState(60 * 10, () => {
        this.isOTPInputDisabled = false;
        this.isConfirmBtnEnabled = true;
        this.initialTimer = "5:00";
        this.otpGenerated = 0;
        this.sendOTP();
        this.attemptsLeft = 3;
        this.showResendButton = false;
        setTimeout(() => {
          this.showResendButton = true;
        }, 30 * 1000);
      });
    },
    getConfirmOtpErrorString() {
      return `Incorrect OTP entered. You have ${this.attemptsLeft} attempt(s) left`;
    },
    resetOtpInput() {},
    hideOtpModal() {
      this.$bvModal.hide("otp-verification-modal");
      this.$emit("cancelled");
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/mixins/_breakpoints.scss";
@import "@/assets/styles/_variables.scss";

.otp-modal {
  .resend-link,
  .otp-sent-text {
    margin-right: 1rem;
  }
  @include media-breakpoint-down(sm) {
    .resend-link,
    .otp-timer,
    .otp-sent-text {
      margin: 0 !important;
    }
  }

  #otp-verification-modal header.modal-header {
    border-bottom: none !important;
  }
}
.timer-style {
  width: 2.1875rem !important;
}
</style>
