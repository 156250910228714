<template lang="pug">
  b-sidebar(
    :visible="visible",
    :id="ssId",
    sidebar-class="nova-sidebar",
    v-bind="{...$props, ...$attrs}",
    v-on="$listeners",
    @change="onChange",
    ref="novaSidesheet",
    z-index=7001,
    shadow,
    right)
    template(#header)
      n-icon.text-gray-600.py-2(v-if="isMobile", name="arrow", :size="1.25", v-b-toggle:[ssId])
      n-avatar(v-if="showAvatar", :image="require(`@/assets/images/${avatarImage}`)", :size="2.5")
      div.heading-row.py-2.pl-2.d-flex
        n-icon.text-gray-600.pr-2(v-if="showHeaderIcon", :name="headerIcon", :size="1.25")
        span.font-weight-medium.font-lg.text-gray-900.nova-sidebar-header {{ headerName }}
        n-icon.text-gray-600.pl-2.align-self-center(v-if="infoText", name="info", :size="1", :pathCount="2", v-b-tooltip.hover.bottomright="infoText")
      div.header-right-actions.py-2
        b-button-group.ctrl-btn-grp(v-if="showNavControls")
          n-button(variant="outline-dark", imageIcon="chevron-left", buttonText="", size="sm")
          n-button(variant="outline-dark", imageIcon="chevron-right", buttonText="", size="sm")
        n-icon.text-gray-600.py-2(v-if="!isMobile", name="cross", :size="1.25", v-b-toggle:[ssId])
    .sidesheet-content.w-100.pt-2
      slot(name="content")
    b-tabs(v-if="!hideTabs", content-class="h-100", v-bind:class="{'pl-4': !centeredTabs, 'px-3': centeredTabs}", nav-wrapper-class="sticky-top bg-white n-nav-wrapper")
      slot(name="tabs")
    template(#footer)
      slot(name="footer")
</template>

<script>
import NButton from "@/components/NovaButton";
import NAvatar from "@/components/Avatar";
import NTabItem from "@/components/NovaTabItem.vue";
import utils from "@/utils";

export default {
  components: {
    NButton,
    NTabItem,
    NAvatar,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    headerName: {
      type: String,
      default: "",
    },
    avatarImage: {
      type: String,
      default: "",
    },
    showNavControls: {
      type: Boolean,
      default: false,
    },
    showAvatar: {
      type: Boolean,
      default: false,
    },
    hideTabs: {
      type: Boolean,
      default: false,
    },
    ssId: {
      type: String,
      required: true,
      default: "demo-ss",
    },
    onChange: {
      type: Function,
      default: () => null,
    },
    showHeaderIcon: {
      type: Boolean,
      default: false,
    },
    headerIcon: {
      type: String,
      default: "messaging",
    },
    centeredTabs: {
      type: Boolean,
      default: false,
    },
    infoText: {
      type: String,
      default: "",
    },
  },
  computed: {
    isMobile() {
      return utils.mobileCheck();
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/_variables.scss";

.n-nav-wrapper {
  .nav-tabs {
    border-bottom: none;
    .nav-link {
      margin-bottom: 0px;
    }
  }
}

.b-sidebar {
  width: 37.5rem !important;
}

.nova-sidebar {
  .b-sidebar-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: white;
    padding-bottom: 1rem;
    border-top-left-radius: 0.25rem;
    .header-right-actions {
      display: flex;
      gap: 1.625rem;
      .btn {
        i {
          color: $gray-600;
        }
      }
      .btn-group {
        .btn {
          border-width: 1px;
          border-color: $gray-400;
          padding: 0.25rem 0.45rem;
          box-shadow: $box-shadow-xs !important;
        }
      }

      .close-btn {
        color: $gray-600;

        &:hover {
          color: $gray-900;
        }
      }
    }
  }

  .b-sidebar-body {
    background: white;
    border-bottom-left-radius: 0.25rem;
    .nav {
      box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.04);
    }
  }
  .b-sidebar-header {
    border-bottom: 1px solid $gray-300;
  }
}
</style>
