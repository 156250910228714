import { EchoPostTemplateStatus } from "../../../../common/enums";
import { getListQuery } from "../queries";
import { EchoPostTemplateCategory, FileAction } from "@/common/enums";

export default {
  schema: {
    fields: [
      {
        model: "org",
        type: "id-selector",
        queryName: "orgs",
        gqlQuery: getListQuery("Org"),
        label: "Organization",
        required: true,
      },
      {
        model: "category",
        type: "select",
        values: Object.values(EchoPostTemplateCategory),
        label: "Post Template Category",
        required: true,
      },
      {
        model: "subject",
        type: "inline-input",
        inputType: "text",
        label: "Post Template Subject",
        hint: "Like Ownership, Cheers, Thanks, etc.",
        required: true,
      },
      {
        model: "defaultMessage",
        type: "text-area",
        inputType: "text",
        label: "Default Message for automatic posts",
      },
      {
        model: "image",
        type: "single-file-uploader",
        label: "Image that goes with the post (see preview)",
        action: FileAction.EchoPostTemplateImage,
        folderName: "echo-post-images",
      },
      {
        model: "logo",
        type: "single-file-uploader",
        label: "Client Logo (see preview)",
        action: FileAction.EchoPostTemplateImage,
        folderName: "echo-post-images",
      },
      {
        model: "status",
        type: "select",
        values: Object.values(EchoPostTemplateStatus),
        label: "Post Template Status",
        hint: "Leave empty while creating the template",
        styleClasses: "d-inline-flex col-md-6 flex-column pl-0",
      },
      {
        model: "updatedBy",
        type: "id-selector",
        queryName: "users",
        gqlQuery: getListQuery("User"),
        label: "Last Updated By",
        disabled: true,
        styleClasses: "d-inline-flex col-md-6 flex-column pr-0",
      },
      {
        model: "meta",
        type: "object",
        schema: {
          fields: [
            {
              model: "useGradientBackground",
              type: "checkbox",
              inputType: "checkbox",
              label: "Use Gradient Background instead of Flat Color",
              styleClasses: "d-inline-flex col-md-6 align-middle pl-0",
            },
            {
              model: "textColor",
              type: "select",
              values: ["WHITE", "BLACK"],
              label: "Text Color",
              styleClasses: "d-inline-flex col-md-6 flex-column align-middle pr-0",
            },
            {
              model: "backgroundColor",
              type: "color-picker",
              label: "Post Background Color Flat",
              defaultColors: [["#44338E", "#004099", "#882424", "#196761"]], // nova branded colors for echo
            },
            {
              model: "backgroundColorGradient",
              type: "select",
              values: ["PURPLE", "BLUE", "RED", "GREEN", "YELLOW"],
              label: "Post Background Color Gradient",
            },
          ],
        },
      },
    ],
  },
}
