import gql from "graphql-tag";
import { teamFragment } from "../fragments";

export default {
  upsertMutation: gql`
    mutation upsertTeam($id: ID, $name: String!, $status: String) {
      upsertTeam(input: { id: $id, name: $name, status: $status }) {
        team {
          ...Team
        }
      }
    }
    ${teamFragment}
  `,
};
