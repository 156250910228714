import gql from "graphql-tag";
import { benefitFragment } from "../fragments";

export default {
  upsertMutation: gql`
    mutation upsertBenefit(
      $id: ID
      $insurerId: ID
      $isPolicy: Boolean
      $restrictedFields: [String]
      $category: String
      $subCategory: String
      $type: String
      $name: String
      $section: String
      $meta: JSONObject
    ) {
      upsertBenefit(
        input: {
          id: $id
          insurerId: $insurerId
          isPolicy: $isPolicy
          restrictedFields: $restrictedFields
          category: $category
          subCategory: $subCategory
          type: $type
          name: $name
          meta: $meta
          section: $section
        }
      ) {
        benefit {
          ...Benefit
        }
      }
    }
    ${benefitFragment}
  `,
  enableRaterConfigFields: gql`
    mutation enableRaterConfigFields($policyId: ID!) {
      enableRaterConfigFields(input: { policyId: $policyId }) {
        success
      }
    }
  `,
  triggerMailsOnNovaXClicks: gql`
    mutation triggerMailsOnNovaXClicks($userId: ID!) {
      triggerMailsOnNovaXClicks(input: { userId: $userId }) {
        success
      }
    }
  `,
};
