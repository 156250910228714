<template lang="pug">
  .rounded.shadow-sm.p-2.border.w-100(:class="errorStyleClass")
    b-modal(:id="`delete-${componentId}`", body-class="overflow-hidden", centered, hide-header, hide-footer, scrollable)
      p.font-lg.font-weight-semibold.text-gray-900 Are you sure you want to delete {{ deleteMode === 'all'? 'all the files':'this file'}}?
      .d-flex.justify-content-end.mt-5
        n-button.mr-2(buttonText="Delete" type="button" variant="outline-danger" @click="deleteFiles()")
        n-button(buttonText="Cancel" type="button" @click="$bvModal.hide(`delete-${componentId}`)")
    file-upload.d-none.position-absolute(
      @input-filter="inputFilter",
      @input-file="inputFile", 
      :size="maxSize",
      :accept="accept",
      :extensions="extensions",
      :name="type")
    .d-flex.justify-content-between.align-items-center.p-2.w-100
      .d-flex.align-items-center.w-75
        label.cursor-pointer.d-flex.align-items-center.m-0(v-if="noFiles", :for="type")
          n-icon.mr-2(name="plus-circle", variant="gray-600", :pathCount="2")
        n-icon.mr-2(v-else name="check-circle", variant="teal-700", :pathCount="2")
        span.font-weight-medium {{ type }}
          br
          .font-xs.text-gray-700.mt-1.doc-description.font-sm(v-if="description") {{ description }}
      label.cursor-pointer.d-flex.align-items-center.m-0(v-if="noFiles", :for="type")
        n-icon.rounded.border.p-1.shadow-sm(name="upload", variant="blue-500", :size="1")
      n-icon.cursor-pointer(v-else name="delete", variant="red-500", @click="$bvModal.show(`delete-${componentId}`)")
      
    .second.border-top.p-4.text-center(v-if="loading")
      b-spinner(:show="loading")
    .second.border-top(v-else-if="!noFiles")
      .row.px-2.mt-2
        .col-4.mt-3(v-for="(file, index) in value" :key="file.id")
          .d-flex.justify-content-center.rounded.bg-gray-500.py-2.position-relative
            .d-flex.justify-content-center.position-absolute.border.rounded-circle.cancel-icon.bg-white
              n-icon.cursor-pointer(name="cross", variant="gray-900", :size="1", @click="deleteFiles(file)")
            n-icon.cursor-pointer(name="eye", variant="gray-900", @click="preview(file)")
          .d-flex.justify-content-center.mt-1
            span.font-sm.text-truncate {{ file.fileDetails.name }}
          
      .d-flex.justify-content-center.pt-4.pb-1
        label.text-blue-500.font-sm.font-weight-medium.cursor-pointer(:for="type") + Add More
</template>

<script>
import FileUpload from "vue-upload-component";
import { kebabCase } from "lodash";
import gql from "graphql-tag";
import { mapGetters } from "vuex";
import { ClaimSourceTypes, FileAction } from "../../../../../common/enums";
import adminResDefs from "../../../admin/definitions";
import NButton from "@/components/NovaButton.vue";
import NPopover from "@/components/NovaPopover.vue";

export default {
  components: {
    FileUpload,
    NButton,
    NPopover,
  },
  props: {
    type: {
      type: String,
      default: () => null,
    },
    description: {
      type: String,
      default: () => null,
    },
    value: {
      type: Array,
      default: () => [],
    },
    accept: {
      type: String,
      default: "image/png,image/gif,image/jpeg,image/webp,text/pdf,pdf,application/pdf",
    },
    extensions: {
      type: String,
      default: "gif,jpg,jpeg,png,webp,pdf,doc",
    },
    isErrorState: {
      type: Boolean,
      default: false,
    },
    requiredDetails: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    const allFiles = [...this.value];
    const id = kebabCase(this.type);
    return {
      allFiles,
      loading: false,
      selectedFile: null,
      deleteMode: "all",
      componentId: id,
      maxSize: 1024 * 1024 * 3, // 3 MB
    };
  },
  computed: {
    ...mapGetters(["isAdmin"]),
    noFiles() {
      return this.allFiles.length < 1;
    },
    errorStyleClass() {
      return this.isErrorState && this.noFiles ? "border-danger" : "";
    },
  },
  watch: {
    value(val) {
      this.allFiles = val;
    },
  },
  methods: {
    preview(file) {
      if (file.fileDetails.url) window.open(file.fileDetails.url, "_blank");
    },
    getFileType(file) {
      return file.type || file.meta?.type;
    },
    async deleteFiles(idx = null) {
      const variables = {};
      if (idx !== null) {
        this.selectedFile = idx;
        this.deleteMode = "single";
        variables.id = this.selectedFile.id;
        variables.fileId = this.selectedFile.fileDetails.id;
      } else {
        this.deleteMode = "all";
        variables.claimId = this.requiredDetails.claimId;
        variables.docType = this.type;
        // If there are no files to delete, return early
        if (this.allFiles.length === 0) {
          this.$bvModal.hide(`delete-${this.componentId}`);
          return;
        }
      }
      const result = await this.$apollo.mutate({
        mutation: gql`
          mutation deleteClaimFile($id: ID, $fileId: ID, $claimId: ID, $docType: String) {
            deleteClaimFile(input: { id: $id, fileId: $fileId, claimId: $claimId, docType: $docType }) {
              result
            }
          }
        `,
        variables,
      });
      this.$emit("input", this.allFiles);
      this.$root.$emit("claimFileUploaded");
      this.$bvModal.hide(`delete-${this.componentId}`);
    },

    async inputFile(newFile, oldFile) {
      if (newFile && !oldFile) {
        if (newFile.size >= 0 && newFile.size <= this.maxSize) {
          await this.sendToDB(newFile);
        } else {
          this.$store.commit("clearToasts");
          this.$store.commit("addToast", {
            variant: "danger",
            message: "File size too large!",
          });
        }
      }
    },
    // https://lian-yue.github.io/vue-upload-component/#/en/documents#options-events-input-filter
    // reference code used from docs, being used in Claimsfileupload.vue
    inputFilter(newFile, oldFile, prevent) {
      if (newFile && !oldFile && /\.(jfif)$/i.test(newFile.name)) {
        this.$store.commit("addToast", {
          variant: "danger",
          message: "Invalid image format",
        });
        return prevent();
      }
      if (newFile && (!oldFile || newFile.file !== oldFile.file)) {
        newFile.blob = "";
        const URL = window.URL || window.webkitURL;
        if (URL && URL.createObjectURL) {
          newFile.blob = URL.createObjectURL(newFile.file);
        }
        newFile.thumb = "";
        if (newFile.blob && newFile.type.substr(0, 6) === "image/") {
          newFile.thumb = newFile.blob;
        }
      }
    },
    async sendToDB(newFile) {
      this.loading = true;
      const variables = {
        claimId: this.requiredDetails.claimId,
        file: newFile.file,
        docType: this.type,
        createSource: this.isAdmin ? ClaimSourceTypes.PORTAL_SUPER_ADMIN : ClaimSourceTypes.PORTAL_USER,
        isUploaded: false,
        isRecentlyUploaded: !this.isAdmin ? true : undefined,
        action: FileAction.FileUpload,
        fileType: this.getFileType(newFile),
      };
      try {
        await this.$apollo.mutate({
          mutation: adminResDefs.claims.upsertClaimFiles,
          variables: variables,
        });
        this.$emit("input", this.allFiles);
        this.$root.$emit("claimFileUploaded");
        this.loading = false;
      } catch (err) {
        console.log(err);
        this.$store.commit("addToast", {
          variant: "danger",
          message: "Something went wrong, unable to upload file.",
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
.cancel-icon {
  top: 0;
  right: 0;
  transform: translate(30%, -30%);
}

.doc-description {
  line-height: 0.825rem;
}
</style>
