export default {
  schema: {
    fields: [],
  },
  filterSchema: {
    fields: [
      {
        model: "status",
        type: "select",
        values: [
          { id: "failed", name: "Failed" },
          { id: "completed", name: "Completed" },
        ],
      },
    ],
  },
};
