<template lang="pug">
.add-dependents-modal
  b-card.px-3.dep-selection-card(:class="`shadow-${isInsideModal ? 'none' : 'lg'} ${!isProfileDependentsScreen && 'dep-selection-card_small-height'}}`", body-class="px-2")
    .my-4.d-lg-none.text-center.font-weight-medium.text-gray-800.font-sm(v-for="paragraph in coverageInfo") {{ paragraph }}
    .modal-heading.mt-1.mb-4(v-if="isProfileDependentsScreen")
      .d-flex.justify-content-between.flex-1.align-text-center
        span
        span.font-weight-semibold {{ isUpdateModal ? "Updating Dependents" : "Adding Dependents" }}
        n-icon.cursor-pointer.font-weight-semibold(
          :size="1",
          name="cross",
          @click="closeModal")
      hr
      span.depText.font-lg.pt-4.pb-4.font-weight-medium(v-if="!isWellnessOnlyOrg") {{ getHeadingText }}
    .avatar-container(v-if="!isInsideModal")
      n-avatar(
        v-if="model.relation!=='None selected' && model.gender === 'NOT ASSIGNED'",
        :size="2.5",
        :name="model.name",)
      img.dep-profile-image(
        v-else-if="model.relation!=='None selected' && model.gender",
        :src="require(`@/assets/images/avatar-${model.relation}-${model.gender}.svg`)")
      img.dep-profile-image(
        v-else,
        :src="require('@/assets/images/user.svg')")
      hr.ml-1.mt-2
    b-form(@submit.prevent="")
      vue-form-generator(
        :schema="getSchema",
        :options="formOptions",
        ref="formData",
        :model="model")

      b-card.shadow-none(
        v-if="pOrILFlag && (model.relation==='parent' || model.relation==='parent-in-law')"
        :body-class="stylingClasses")
        p.text-gray-800.font-sm.mb-0 {{ getText() }}

      n-button.w-100.my-3(
        v-if="!showDeleteorUpdateButton && !isInsideModal",
        variant="dark",
        buttonText="Save and Add",
        rightImageIcon="plus-circle",
        type="submit",
        @click="submitForm(saveDetails)",
        :disabled="isDisabled",
        data-cy="user-add-dependent-save-btn")

      .d-flex.justify-content-between.pb-4(v-if="isInsideModal && !isProfileDependentsScreen")
        n-button.w-50.mt-3(
          v-if="showDeleteorUpdateButton",
          variant="outline-primary",
          buttonText="Delete",
          @click.prevent="showDeletePopup()")
        n-button.w-50.mt-3.ml-2(
          v-if="showDeleteorUpdateButton",
          variant="dark",
          buttonText="Save",
          type="submit",
          @click="submitForm(updateDependent)",
          :disabled="isDisabled")

        n-button.w-50.mt-3.ml-2(
          v-if="!showDeleteorUpdateButton",
          variant="dark",
          buttonText="Save",
          type="submit",
          @click="submitForm(saveDetails)",
          :disabled="isDisabled")
      .reminder-modal-footer.pb-2(v-else-if="isProfileDependentsScreen")
        hr
        .buttons.d-flex.align-right
          n-button.mx-1.px-2(
            variant="light",
            buttonText="Cancel",
            @click="closeModal",)
          n-button.mx-1.px-2(
            variant="dark",
            :buttonText="submitFormButtonText",
            type="submit",
            data-cy="dependent-add-submit",
            @click="submitForm(saveDetails)",
            :disabled="isDisabled")

      .d-flex(v-else)
        n-button.w-50.mr-1.mt-3.mb-4(
          v-if="showDeleteorUpdateButton",
          variant="outline-primary",
          buttonText="Delete Dependent",
          @click="showDeletePopup()")
        n-button.w-50.ml-1.mt-3.mb-4(
          v-if="showDeleteorUpdateButton",
          variant="dark",
          buttonText="Update",
          type="submit",
          @click="submitForm(updateDependent)",
          :disabled="isDisabled")

    special-modal(
      id="dependent-deletion-modal",
      headerIcon="icon",
      bgColor="red-300",
      iconColor="red-600",
      logoIcon="warning",
      centered)
      template(v-slot:title)
        h3.hb3.d-inline.text-gray-900 Delete Dependent
      template(v-slot:modal-content)
        .text-center
          | Delete {{selectedDependent.name}}?
        .text-center
          | Once deleted, they will not be covered in your insurance policies.
      template(v-slot:buttons-area)
        .d-flex.align-items-center.w-100.mt-3.mb-2.px-5
          n-button.w-50.mr-2(
            variant="light",
            button-text="Skip",
            @click="$bvModal.hide('dependent-deletion-modal')")
          n-button.w-50(
            variant="danger",
            button-text="Delete",
            @click="deleteDependent(user, selectedDependent)")
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";
import { dependentDropdownOptions } from "../../constants";
import resDefs from "../../../admin/definitions";
import { isWellnessOnlyOrg } from "../../../../../utils";
import NAvatar from "@/components/Avatar.vue";
import NIcon from "@/components/NovaIcon.vue";
import utils, { isVfgErrorPresent } from "@/utils";
import { MAX_SPOUSE_COUNT, MAX_P_AND_PIL_COUNT } from "@/common/constants/dependent";
import {
  validateDependentAge,
  validateDependentGender,
  validateName,
  validateDependentCount,
  validateDateOfMarriage,
  validateInlineSelect,
} from "@/utils/validators";
import NButton from "@/components/NovaButton.vue";
import NInlineRadio from "@/components/NovaInlineRadio.vue";
import SpecialModal from "@/components/SpecialModal.vue";
import { AcceptedRelations } from "@/common/enums";

export default {
  name: "AddDependentsCard",
  components: {
    NButton,
    NIcon,
    NInlineRadio,
    NAvatar,
    SpecialModal,
  },
  props: {
    user: {
      type: Object,
      default: null,
    },
    dependentsModel: {
      type: Array,
      default: () => [],
    },
    selectedDependent: {
      type: Object,
      default: () => {},
    },
    isInsideModal: {
      type: Boolean,
      default: false,
    },
    coverageInfo: {
      type: Array,
      default: () => [],
    },
    policies: {
      type: Array,
      default: () => [],
    },
    isMidTerm: {
      type: Boolean,
      default: false,
    },
    restrictDependentAddition: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dependentDropdownOptions,
      coveredDependentsObject: utils.totalCoveredDependents(this.policies),
      showDeleteorUpdateButton: false,
      model: {},
      dependent: {},
      coverPOrPil: "",
      coverChild: "",
      addedParentData: [],
      addedPilData: [],
      coveredChildren: [],
      customDependentValidations: {},
      schema: {
        fields: [
          {
            model: "name",
            type: "inline-input",
            inputType: "text",
            label: `Dependent Name<span style="color: red">*</span>`,
            placeholder: "Enter dependent’s full name",
            required: true,
            noPadding: true,
            styleClasses: "mb-4",
            validator: [validateName],
            "data-cy": "user-add-dependents-name",
          },
          {
            model: "dob",
            type: "datepicker",
            label: `Date of Birth (as per Govt ID)<span style="color: red">*</span>`,
            required: true,
            min: "1900-01-01",
            max: moment().format("YYYY-MM-DD"),
            styleClasses: "d-inline-block w-75 pr-2 mb-4",
            labelClasses: "mb-2 pb-1",
            shouldFormat: true,
            validator: [this.validateDependentAge],
            dataCy: "user-add-dependents-dob",
          },
          {
            model: "age",
            type: "inline-input",
            inputType: "text",
            label: "Age",
            disabled: true,
            styleClasses: "d-inline-block w-25 pl-2 mb-2 align-top",
            labelClasses: "mb-2",
          },
          {
            model: "gender",
            type: "select-cards",
            label: `Gender<span style="color: red">*</span>`,
            validator: [validateDependentGender],
            cardsData: [
              {
                name: "gender",
                icon: "female",
                label: "Female",
                card_value: "female",
                dataCy: "user-add-dependents-gender-female",
              },
              {
                name: "gender",
                icon: "male",
                label: "Male",
                card_value: "male",
                dataCy: "user-add-dependents-gender-male",
              },
            ],
            styleClasses: "mt-0 mb-2",
          },
          {
            model: "relation",
            type: "inline-select",
            label: `Relationship<span style="color: red">*</span>`,
            placeholder: "How is the dependent related to you?",
            validator: [validateDependentCount, validateInlineSelect],
            dataCy: "user-add-dependents-relation",
            reduce: (v) => v.value,
            options: dependentDropdownOptions,
            disabled: (model) => Boolean(model.id) || Boolean(model.tempId),
            hint: (model) =>
              model.id || model.tempId ? "Relationship is locked, please delete and create the dependent again." : "",
            onChanged: this.handleRelationChanged,
          },
        ],
      },
      formOptions: {
        validateAfterLoad: false,
        validateAfterChanged: true,
        validateAsync: true,
      },
      bgClass: "mustard-100",
      pOrPilOptions: [
        { label: "Parents", value: "parent" },
        { label: "Parents-in-law", value: "parent-in-law" },
      ],
      childInclusionOptions: [
        { label: "Include Dependent", value: "includeChild" },
        { label: "Don’t include", value: "dontIncludeChild" },
      ],
    };
  },
  computed: {
    ...mapGetters(["getFeatureFlags"]),
    age() {
      return moment().diff(this.model.dob, "years", false);
    },
    isDisabled() {
      const index = this.dependentsModel.findIndex((dep) => this.isSamePerson(dep, this.model));
      // FIXME: clean up, remove nesting
      return !(
        this.model.name &&
        this.model.dob &&
        this.model.gender &&
        this.model.relation &&
        this.model.relation !== "None selected" &&
        (this.model.id || this.model.tempId ? this.dependentsModel[index]?.relation === this.model.relation : true) &&
        (utils.showDOMField(this.model, this.isMidTerm) ? this.model.meta?.dom : true)
      );
    },
    pOrILFlag() {
      if (this.policies) {
        return this.coveredDependentsObject?.pOrILFlag;
      }
      return false;
    },
    showOptionsForChild() {
      if (this.coveredChildren.length < this.coveredDependentsObject?.childrenCount || this.model.meta?.isPreferred)
        return true;
      return false;
    },
    stylingClasses() {
      return [
        { "text-center": this.isProfileDependentsScreen },
        `bg-${this.bgClass}`,
        "border",
        "border-1",
        "border-gray-200",
        "rounded-lg",
      ];
    },
    isWellnessOnlyOrg() {
      return isWellnessOnlyOrg(this.user?.org, this.policies || []);
    },
    submitFormButtonText() {
      if (this.isUpdateModal) {
        return "Save Changes";
      }
      if (this.isWellnessOnlyOrg) {
        return "Add Dependent";
      }
      return "Request Addition";
    },
    getHeadingText() {
      if (this.isUpdateModal) {
        return "Editing any detail requires approval from the insurer. Edit the details and save changes to create a request";
      }
      return "Adding a dependent requires approval from the insurer. Fill up this form to create a dependent addition request 👋";
    },
    getSchema() {
      this.updateRelationOptions();

      if (this.isMidTerm || this.restrictDependentAddition) {
        this.schema.fields.find((field) => field.model === "relation").options = this.dependentDropdownOptions.filter(
          // Only child and spouse can be added in mid term
          (option) =>
            this.schema.fields
              .find((field) => field.model === "relation")
              .options.filter((item) => [AcceptedRelations.CHILD, AcceptedRelations.SPOUSE].includes(item.value))
              ?.includes(option)
        );
      }

      if (utils.showDOMField(this.model, this.isMidTerm)) {
        return {
          fields: [
            ...this.schema.fields,
            {
              model: "meta.dom",
              type: "datepicker",
              label: `Date of Marriage (as per Govt ID)<span style="color: red">*</span>`,
              min: "1900-01-01",
              max: moment().format("YYYY-MM-DD"),
              required: true,
              ...(!this.model.id ? { validator: [validateDateOfMarriage] } : {}),
              dataCy: "user-add-dependents-dom",
              disabled: this.model.id,
            },
          ],
        };
      }

      return this.schema;
    },
    isUpdateModal() {
      if (this.selectedDependent.id) {
        return true;
      }
      return false;
    },
    isProfileDependentsScreen() {
      return this.$route.path.includes("user/dependents");
    },
  },
  watch: {
    selectedDependent() {
      if (!this.isInsideModal) {
        this.showDeleteorUpdateButton = !this.selectedDependent.isPlaceholder;
        this.model = utils.deepClone(this.selectedDependent);
        if (this.model.relation === "child") this.setNextChildCoverage();
        this.setModelAge();
      }
      this.setDataToModel();
    },
    age() {
      this.setModelAge();
    },
    dependentsModel: {
      handler: function () {
        this.addedParentData = [];
        this.addedPilData = [];
        this.dependentsModel.forEach((dep) => {
          if (dep.relation === AcceptedRelations.PARENT)
            this.addedParentData.push({ tempId: dep.tempId, gender: dep.gender });
          if (dep.relation === AcceptedRelations.PARENT_IN_LAW)
            this.addedPilData.push({ tempId: dep.tempId, gender: dep.gender });
          if (dep.meta?.isPreferred) {
            switch (dep.relation) {
              case AcceptedRelations.PARENT:
                this.coverPOrPil = "parent";
                this.$emit("pPilCoverage", this.coverPOrPil);
                break;
              case AcceptedRelations.PARENT_IN_LAW:
                this.coverPOrPil = "parent-in-law";
                this.$emit("pPilCoverage", this.coverPOrPil);
                break;
              case AcceptedRelations.CHILD: {
                const sameChild = this.coveredChildren.find((coveredChild) => this.isSamePerson(coveredChild, dep));
                if (!sameChild) {
                  this.coveredChildren.push(dep);
                }
                break;
              }
            }
          }
        });
        if (
          !this.dependentsModel.length ||
          (this.coverPOrPil && this.dependentsModel.every((dep) => dep.relation !== this.coverPOrPil))
        ) {
          const setCoverPOorPil = this.dependentsModel?.find((dep) =>
            [AcceptedRelations.PARENT, AcceptedRelations.PARENT_IN_LAW].includes(dep.relation)
          );
          this.coverPOrPil = setCoverPOorPil?.relation;
          this.$emit("pPilCoverage", setCoverPOorPil?.relation);
        }
        // run this only in case of ob flow
        // TODO : temp fix, validations need to be refactored.
        if (!this.isInsideModal) {
          this.setDataToModel();
        }
      },
      immediate: true,
    },
  },
  created() {
    this.setUserDataToModel();
    this.setParentDataToModel();

    this.$apollo.addSmartQuery("customDependentValidations", {
      query: resDefs?.orgProperties?.singleQueryByNameAndOrgId,
      variables() {
        return {
          name: "CUSTOM_DEPENDENT_VALIDATION_PARAMS",
          orgId: this.user?.org?.id,
          orgEntityId: this.user?.orgEntity?.id,
        };
      },
      update(data) {
        return data?.getOrgPropertyByNameAndOrgId?.meta;
      },
    });
  },
  mounted() {
    if (this.isInsideModal) {
      this.model = this.selectedDependent;
      this.showDeleteorUpdateButton = Boolean(this.model?.name);
    }
    this.setNextChildCoverage();
    this.setModelAge();
  },
  methods: {
    async submitForm(action) {
      action();
    },
    relationCount() {
      const relationsCount = {
        [AcceptedRelations.CHILD]: 0,
        [AcceptedRelations.SPOUSE]: 0,
        [AcceptedRelations.PARENT]: 0,
        [AcceptedRelations.PARENT_IN_LAW]: 0,
        [AcceptedRelations.SIBLING]: 0,
      };

      this.dependentsModel.forEach((dep) => {
        if (relationsCount.hasOwnProperty(dep.relation)) {
          relationsCount[dep.relation]++;
        }
      });

      return relationsCount;
    },
    updateRelationOptions() {
      const coveredDependentsArray = utils.deepClone(this.coveredDependentsObject.coveredDependents);
      const relationsCount = this.relationCount();

      let addedPOrPilCount = 0;

      if (this.coveredDependentsObject.pOrILFlag && this.coveredDependentsObject.groupedParentsCount <= 0) {
        coveredDependentsArray.push(AcceptedRelations.PARENT, AcceptedRelations.PARENT_IN_LAW);
        if (relationsCount[AcceptedRelations.PARENT] > 0) {
          const index = coveredDependentsArray.indexOf(AcceptedRelations.PARENT_IN_LAW);
          coveredDependentsArray.splice(index, 1);
          addedPOrPilCount = relationsCount[AcceptedRelations.PARENT];
        }
        if (relationsCount[AcceptedRelations.PARENT_IN_LAW] > 0) {
          const index = coveredDependentsArray.indexOf(AcceptedRelations.PARENT);
          coveredDependentsArray.splice(index, 1);
          addedPOrPilCount = relationsCount[AcceptedRelations.PARENT_IN_LAW];
        }
      }

      this.schema.fields.find((field) => field.model === "relation").options = this.dependentDropdownOptions.filter(
        (option) => {
          if (
            option.value === AcceptedRelations.SPOUSE &&
            relationsCount[AcceptedRelations.SPOUSE] >= MAX_SPOUSE_COUNT
          ) {
            return false;
          }

          // sibling option added only when sibling group is present with child
          if (
            option.value === AcceptedRelations.SIBLING &&
            (this.coveredDependentsObject.groupedChildSiblingCount <= 0 ||
              relationsCount[AcceptedRelations.CHILD] >= this.coveredDependentsObject.groupedChildSiblingCount)
          ) {
            return false;
          }

          // Check if it's a PARENT or PARENT_IN_LAW, and the maximum count for any of PARENT or PARENT_IN_LAW has been reached
          if (
            this.coveredDependentsObject.pOrILFlag &&
            (option.value === AcceptedRelations.PARENT || option.value === AcceptedRelations.PARENT_IN_LAW) &&
            addedPOrPilCount >= MAX_P_AND_PIL_COUNT
          ) {
            return false;
          }

          // Check if it's a PARENT and the maximum count for PARENT has been reached
          if (
            !this.coveredDependentsObject.pOrILFlag &&
            option.value === AcceptedRelations.PARENT &&
            (relationsCount[AcceptedRelations.PARENT] >= MAX_P_AND_PIL_COUNT ||
              (this.coveredDependentsObject.groupedParentsCount > 0 &&
                relationsCount[AcceptedRelations.PARENT] + relationsCount[AcceptedRelations.PARENT_IN_LAW] >=
                  this.coveredDependentsObject.groupedParentsCount))
          ) {
            return false;
          }

          // Check if it's a PARENT_IN_LAW and the maximum count for PARENT_IN_LAW has been reached
          if (
            !this.coveredDependentsObject.pOrILFlag &&
            option.value === AcceptedRelations.PARENT_IN_LAW &&
            (relationsCount[AcceptedRelations.PARENT_IN_LAW] >= MAX_P_AND_PIL_COUNT ||
              (this.coveredDependentsObject.groupedParentsCount > 0 &&
                relationsCount[AcceptedRelations.PARENT_IN_LAW] + relationsCount[AcceptedRelations.PARENT] >=
                  this.coveredDependentsObject.groupedParentsCount))
          ) {
            return false;
          }

          if (
            option.value === AcceptedRelations.SIBLING &&
            relationsCount[AcceptedRelations.SIBLING] + relationsCount[AcceptedRelations.CHILD] >=
              this.coveredDependentsObject.groupedChildSiblingCount
          ) {
            return false;
          }

          if (!coveredDependentsArray.includes(option.value)) {
            return false;
          }

          if (option.value !== AcceptedRelations.CHILD) {
            return true;
          }

          return !!(
            relationsCount[AcceptedRelations.CHILD] + relationsCount[AcceptedRelations.SIBLING] <
              Math.max(
                this.coveredDependentsObject.childrenCount,
                this.coveredDependentsObject.groupedChildSiblingCount
              ) || this.model?.meta?.isPreferred
          );
        }
      );
    },
    setModelAge() {
      if (!this.model?.dob || isNaN(this.age) || this.age === this.model?.age) return;
      this.$set(this.model, "age", this.age);
    },
    setUserDataToModel() {
      this.$set(this.model, "userGender", this.user.gender);
      this.$set(this.model, "userDob", this.user.dob);
    },
    setParentDataToModel() {
      this.$set(this.model, "addedParentData", this.addedParentData);
      this.$set(this.model, "addedPilData", this.addedPilData);
    },
    setDepCountToModel() {
      this.$set(this.model, "dependentsByRelationCount", this.getDependentsByRelationCount());
    },
    setMidTermAdditionToModel() {
      this.$set(this.model, "isMidTerm", this.isMidTerm);
    },
    validateDependentAge(dob) {
      return validateDependentAge(dob, this.user, this.model, this.customDependentValidations);
    },
    getDependentsByRelationCount() {
      const dependentsByRelationCount = {};
      if (this.model.id || this.model.tempId) {
        // not taking the count of the currently editing dependent
        this.dependentsModel
          .filter(
            (dep) =>
              (this.model.id && dep?.id !== this.model.id) || (this.model.tempId && dep?.tempId !== this.model.tempId)
          )
          .forEach((dep) => {
            dependentsByRelationCount[dep.relation] = 1 + (dependentsByRelationCount[dep.relation] || 0);
          });
      } else {
        this.dependentsModel.forEach((dep) => {
          dependentsByRelationCount[dep.relation] = 1 + (dependentsByRelationCount[dep.relation] || 0);
        });
      }
      return dependentsByRelationCount;
    },
    setDataToModel() {
      this.setUserDataToModel();
      this.setParentDataToModel();
      this.setMidTermAdditionToModel();
      this.setDepCountToModel();
    },
    sanitizeNameForComparison(name) {
      return name?.toLowerCase().replace(/\s+/g, "");
    },
    async saveDetails() {
      try {
        await this.$refs.formData.validate();
        if (isVfgErrorPresent(this.$refs.formData.errors)) {
          this.$store.commit("addToast", {
            variant: "danger",
            message: "Please enter valid details before continuing to the next step",
          });
          return;
        }
        delete this.model.isPlaceholder;
        this.dependent = this.model;
        const isDependentAlreadyPresent = this.dependentsModel.findIndex(
          (dep) =>
            this.sanitizeNameForComparison(dep.name) === this.sanitizeNameForComparison(this.model.name) &&
            dep.relation === this.model.relation
        );
        if (!this.isProfileDependentsScreen && isDependentAlreadyPresent !== -1) {
          this.$store.commit("addToast", {
            variant: "danger",
            message: "Dependent with this name already exists!",
          });
          return;
        }
        // If the dependent is new and doesn't have an id, assign a temporary id.
        if (!this.dependent.id) {
          this.dependent.tempId = `temp-${Date.now()}`;
        }
        const updatedDependents = this.isProfileDependentsScreen
          ? [this.dependent]
          : this.updateCoverage([...this.dependentsModel, this.dependent]);
        this.$emit("modelUpdated", updatedDependents, this.model.relation);
        if (this.isProfileDependentsScreen) this.closeModal();
      } catch (err) {
        console.log(err);
      }
    },
    async updateDependent() {
      try {
        await this.$refs.formData.validate();
        if (isVfgErrorPresent(this.$refs.formData.errors)) {
          this.$store.commit("addToast", {
            variant: "danger",
            message: "Please enter valid details before continuing to the next step",
          });
          return;
        }
        delete this.model.isPlaceholder;
        const index = this.dependentsModel.findIndex((dep) => this.isSamePerson(dep, this.model));
        let updatedDependents;
        if (this.isProfileDependentsScreen) {
          updatedDependents = [this.model];
        } else {
          updatedDependents = utils.deepClone(this.dependentsModel);
          this.updateCoverage(updatedDependents);

          if (this.model.id) {
            updatedDependents[index] = { ...this.model, isUpdatedDependent: true };
          }
        }
        this.$emit("modelUpdated", updatedDependents, this.model.relation);
      } catch (err) {
        console.log(err);
      }
    },
    async deleteDependent(user, dependent) {
      try {
        if (dependent.relation === AcceptedRelations.CHILD) {
          const index = this.coveredChildren.findIndex((child) => this.isSamePerson(child, dependent));
          if (index >= 0) this.coveredChildren.splice(index, 1);
        }
        const index = this.dependentsModel.findIndex((dep) => this.isSamePerson(dep, dependent));
        if (index >= 0) this.dependentsModel.splice(index, 1);
        this.$bvModal.hide("dependent-deletion-modal");
        if (dependent.id) {
          this.$emit("addToDeletedDependents", dependent.id);
        }
        if (this.isInsideModal) this.$bvModal.hide("add-dependents-modal-mobile");
        this.resetForm();
        this.setNextChildCoverage();
      } catch (err) {
        console.log(err);
      }
    },
    showDeletePopup() {
      this.$bvModal.show("dependent-deletion-modal");
    },
    resetForm() {
      this.showDeleteorUpdateButton = false;
      this.model = {};
      this.setDataToModel();
    },
    setNextChildCoverage() {
      if (this.model.meta?.hasOwnProperty("isPreferred"))
        this.coverChild = this.model.meta.isPreferred ? "includeChild" : "dontIncludeChild";
      else if (this.coveredChildren.length < this.coveredDependentsObject?.childrenCount)
        this.coverChild = "includeChild";
      else this.coverChild = "dontIncludeChild";
    },
    isSamePerson(p1, p2) {
      if (p1.id && p2.id) return p1.id === p2.id;
      else if (p1.tempId && p2.tempId) return p1.tempId === p2.tempId;
      return p1.name === p2.name && p1.dob === p2.dob && p1.gender === p2.gender && p1.relation === p2.relation;
    },
    updateCoverage(dependentsModel) {
      dependentsModel.forEach((dep, index = 0) => {
        if (dep.relation === AcceptedRelations.PARENT || dep.relation === AcceptedRelations.PARENT_IN_LAW) {
          dep = {
            ...dep,
            meta: {
              ...dep.meta,
              isPreferred: this.coverPOrPil
                ? this.coverPOrPil === dep.relation
                : this.coveredDependentsObject.pOrILFlag ||
                  this.coveredDependentsObject.coveredDependents.includes(dep.relation),
            },
          };
        }
        if (dep.relation === AcceptedRelations.CHILD && !dep?.meta?.hasOwnProperty("isPreferred")) {
          dep = {
            ...dep,
            meta: {
              ...dep.meta,
              isPreferred: false,
            },
          };
        }
        dependentsModel[index] = dep;
        index++;
      });
      if (this.model.relation === "child") {
        const index = this.coveredChildren.findIndex((child) => this.isSamePerson(child, this.model));
        if (this.coverChild === "includeChild") {
          this.model = { ...this.model, meta: { ...this.model.meta, isPreferred: true } };
          if (index >= 0) this.coveredChildren[index] = this.model;
        } else if (this.coverChild === "dontIncludeChild") {
          this.model = { ...this.model, meta: { ...this.model.meta, isPreferred: false } };
          if (index >= 0) this.coveredChildren.splice(index, 1);
        }
        const idx = dependentsModel.findIndex((dep) => this.isSamePerson(dep, this.model));
        dependentsModel[idx] = this.model;
      }
      if (this.model.relation === "spouse") {
        this.model = { ...this.model, meta: { ...this.model.meta, isPreferred: true } };
        const idx = dependentsModel.findIndex((dep) => this.isSamePerson(dep, this.model));
        dependentsModel[idx] = this.model;
      }
      return dependentsModel;
    },
    getText() {
      let text = "";
      if (this.pOrILFlag && [AcceptedRelations.PARENT, AcceptedRelations.PARENT_IN_LAW].includes(this.model.relation)) {
        this.bgClass = "mustard-100";
        text = "Your Health Insurance can cover either your Parents, or Parents-in-law.";
        if (
          !this.isProfileDependentsScreen &&
          this.coverPOrPil &&
          (this.addedParentData.length || this.addedPilData.length)
        ) {
          const notCoveredRelation =
            this.coverPOrPil === AcceptedRelations.PARENT ? AcceptedRelations.PARENT_IN_LAW : AcceptedRelations.PARENT;
          text += ` Please delete your ${this.coverPOrPil} if you wish to cover your ${notCoveredRelation}.`;
        }
      }
      return text;
    },
    closeModal() {
      this.$emit("close");
    },
    handleRelationChanged() {
      if (this.model.relation === "child" && this.model?.meta?.dom) {
        delete this.model.meta.dom;
      }
      this.$refs.formData?.validate();
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/_variables.scss";
.add-dependents-modal {
  .avatar-container {
    hr {
      width: 2rem;
      height: 0.2rem;
      background: $blue-300;
      border-radius: 1.5rem;
    }
  }
  .modal-heading,
  .reminder-modal-footer {
    hr {
      border-top-color: $gray-400;
      width: 150%;
      margin-left: -3.5rem;
    }
  }
  .dep-profile-image {
    height: 2.5rem;
    width: 2.5rem;
    border: 0.2rem solid $blue-300;
    border-radius: 50%;
  }
  .dep-selection-card {
    overflow: auto;
    overflow-x: hidden;
  }
  .dep-selection-card_small-height {
    height: 65vh;
  }
  .bg-yellow > .card-body {
    background: $mustard-100;
    border: 0.1rem solid $gray-200;
    border-radius: 0.5rem;
  }
  .bg-red {
    background: $red-200;
  }
  .radio {
    min-height: 0.5rem !important;
    vertical-align: middle;
  }
  .depText {
    color: $gray-900;
  }
  .buttons {
    place-content: flex-end;
  }
}
</style>
