<template lang="pug">
div
  .backdrop.d-sm-none(v-if="showSupportContainer", :class="active")
  #contact-support-container.contact-support-container.px-2.overflow-visible(:class="active")
    #custom-prompt.rounded-md.shadow-xl.px-4.py-3.bg-white.d-none.d-md-block.invisible(v-if="!firstOpen")
      .font-weight-semibold.font-md {{ supportDetails.promptTitle }}
        img.ml-2(:src="require(`@/assets/emojis/${supportDetails.promptTitleEmoji}.png`)", width="14")
      p.m-0.pt-2.text-gray-700.font-weight-medium.font-sm {{ supportDetails.promptSubtitle }}
      .arrow-right

    .icon-question-container.cursor-pointer.rounded-circle.bg-tiber.justify-content-center.align-items-center.d-none.d-md-flex(
      @click="toggle",
      :class="active")
      n-icon(name="support-channels", variant="gray-100", :size="1.5")
    n-icon.cursor-pointer.shadow-lg(
      name="chevron-down",
      :size="1.8",
      @click="toggle",
      :variant="supportDetails.textColor",
      :class="[active, `bg-${supportDetails.bgColor}`]")
    .contact-support.bg-white.shadow-lg.d-flex-row.my-3.py-3(:class="[active, `bg-${supportDetails.bgColor}`]")
      header.px-4.border-bottom-1-solid(:class="`border-${supportDetails.separator}`")
        h4.hb4.pt-3.pb-2(:class="`text-${supportDetails.textColor}`") Got a doubt?
        .mb-4.font-weight-medium(:class="`text-${supportDetails.grayTextColor}`") {{ supportDetails.subtitle }}
      main.px-4
        .font-hc.pt-4.pb-3(:class="`text-${supportDetails.grayTextColor}`") Contact Info
        template(v-if="showPhoneNumber")
          .cta-call.mt-1
            button.btn.p-0.border-none.shadow-none.w-100
              a.d-flex.align-items-center.cursor-pointer.text-decoration-none(
                :href="`tel:${supportPhoneNumber}`",
                @click="ctaClick('call')")
                .bg-blue-500.rounded-circle.p-2.d-flex
                  n-icon(name="call", variant="white", :size="1")
                span.ml-3.text-gray-900.font-weight-medium {{ supportDetails.phone }}
                n-icon.ml-auto.d-md-none(name="chevron-right", variant="gray-900")
        .cta-email.d-flex.py-1
          button.btn.p-0.border-none.shadow-none.w-100
            a.d-flex.align-items-center.text-decoration-none(
              :href="`mailto:${supportDetails.email}`",
              target="_blank",
              @click="ctaClick('email')")
              .rounded-circle.p-2.d-flex(:class="`bg-${supportDetails.iconBgColor}`")
                n-icon(name="mail", variant="white", :size="1")
              span.ml-3.font-weight-medium(:class="`text-${supportDetails.textColor}`") {{ supportDetails.email }}
              n-icon.ml-auto.d-md-none(name="chevron-right", variant="gray-900")
          button.copy-email-btn.btn.btn-sm.border.p-1.d-none.d-md-flex.bg-white(@click="copyEmail", :class="{ 'dark': isAdminPage }")
            n-icon.p-1(name="copy", :size="1", variant="gray-900")
      .chat.w-100
        button.mt-2.px-3.py-4.btn.rounded-8.w-100.d-flex.align-items-center(
          :class="[`bg-${supportDetails.cta.bgColor}`, `text-${supportDetails.cta.textColor}`]",
          @click="ctaClick('support')")
          n-icon.bg-white.text-tiber.rounded-circle.p-2(v-if="!isAdminPage", name="messaging")
          img(v-else, :src="require(`@/assets/images/whatsapp-logo-2.svg`)", width="30", height="30")
          span.font-lg.font-weight-medium.ml-3 {{ supportDetails.cta.label }}
          n-icon.ml-auto.shift-on-hover(name="chevron-right", :variant="supportDetails.cta.textColor")

</template>

<script>
import { mapGetters } from "vuex";
import utils, { setBackgroundScroll } from "@/utils";

export default {
  name: "ContactSupport",
  props: {
    isSidebarExpanded: { type: Boolean, default: false },
  },
  data() {
    return {
      show: true,
      firstOpen: false,
      ctaClicked: false,
      showPhoneNumber: false,
    };
  },
  computed: {
    ...mapGetters(["showSupportContainer"]),
    active() {
      return { active: this.showSupportContainer };
    },
    supportPhoneNumber() {
      // TODO: to be replaced by org specfic number fetching logic.
      return "04049174207";
    },
    isAdminPage() {
      return this.$route?.fullPath?.startsWith("/org-admin");
    },
    supportDetails() {
      const phone = utils.mobileCheck() ? "Give us a call" : this.supportPhoneNumber;
      if (this.isAdminPage) {
        return {
          promptTitle: "Nova Priority Support",
          promptTitleEmoji: "star",
          promptSubtitle: "Quick help, 24x7. We're here for you",
          subtitle: "Reach out to your Relationship Manager over an Email or a WhatsApp chat for support.",
          phone,
          email: "hrsupport@nova-benefits.com",
          bgColor: "tiber",
          textColor: "white",
          grayTextColor: "gray-400",
          separator: "malibu-900",
          iconBgColor: "malibu-900",
          cta: {
            label: "Chat with us on WhatsApp",
            bgColor: "whatsapp",
            textColor: "tiber",
          },
        };
      }
      return {
        promptTitle: "Feeling lost?",
        promptTitleEmoji: "thinking-face",
        promptSubtitle: "We're here to help",
        subtitle: "Reach out to our experts via call or email or chat with us for support.",
        phone,
        email: "care@getnovaapp.com",
        bgColor: "white",
        textColor: "tiber",
        grayTextColor: "gray-700",
        separator: "gray-300",
        iconBgColor: "teal-700",
        cta: {
          label: "Chat with us?",
          bgColor: "tiber",
          textColor: "white",
        },
      };
    },
  },
  watch: {
    showSupportContainer(value) {
      if (value) this.firstOpen = true;
      if (utils.mobileCheck()) {
        setBackgroundScroll(this.showSupportContainer || this.isSidebarExpanded);
      }
    },
  },
  mounted() {
    const id = setInterval(() => {
      const prompt = document.getElementById("custom-prompt");
      if (prompt) {
        prompt.classList.remove("invisible");
        clearInterval(id);
        setTimeout(() => {
          prompt.classList.add("invisible");
        }, 5000);
      }
    }, 10);
  },
  methods: {
    toggle() {
      window.posthog.capture("clicked_on_floater_button");
      if (!this.ctaClicked && this.firstOpen) {
        window.posthog.capture("not_clicked_on_cta");
      }
      this.$store.commit("toggleSupportContainer");
    },
    showZohoSalesIqChat() {
      window.posthog.capture("clicked_on_chat_button");
      this.ctaClicked = true;
      if (window?.$zoho?.salesiq?.floatwindow) {
        window.$zoho.salesiq.floatwindow.visible("show");
      }
    },
    copyEmail() {
      this.ctaClicked = true;
      window.posthog.capture("clicked_on_copy_button");
      navigator.clipboard.writeText(this.supportDetails.email);
    },
    ctaClick(action) {
      this.ctaClicked = true;
      if (action === "call") {
        window.posthog.capture("clicked_on_call_button");
      } else if (action === "email") {
        window.posthog.capture("clicked_on_email_button");
      } else {
        if (this.isAdminPage) {
          const target = utils.mobileCheck() ? "_self" : "_blank";
          window.open(process.env.VUE_APP_NOVA_WHATSAPP_ADMIN_SUPPORT, target);
        } else {
          this.showZohoSalesIqChat();
        }
        this.toggle();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
@import "@/assets/styles/mixins/_breakpoints.scss";

.backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: #666;
  opacity: 0;
  z-index: 4999;
  &.active {
    opacity: 0.33;
  }
}
#custom-prompt {
  position: absolute;
  top: -0.5rem;
  right: 5.5rem;
  width: max-content;
  transition: 0.1s linear transform;
  z-index: 4999;
  .arrow-right {
    position: absolute;
    right: -0.5rem;
    top: 2rem;

    width: 0;
    height: 0;
    border-top: 0.5rem solid transparent;
    border-bottom: 0.5rem solid transparent;
    border-left: 0.5rem solid $white;
  }
}
.border-bottom-1-solid {
  border-bottom-width: 1px;
  border-bottom-style: solid;
}

.contact-support-container {
  position: fixed;
  right: 1%;
  bottom: 3%;
  z-index: 6000;
  overflow: visible;
}

.icon-question-container {
  float: right;
  transition: bottom 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275), opacity 0.1s linear;
  z-index: 5000;
  height: 4rem;
  width: 4rem;
  &.active {
    opacity: 0%;
    bottom: 5%;
  }
}

.contact-support {
  height: 0;
  width: 0;
  opacity: 0;
  visibility: hidden;
  border-radius: 0.5rem;
  position: relative;
  transition: 0.3s height cubic-bezier(0.075, 0.82, 0.165, 1), 0.2s opacity linear;

  &.active {
    opacity: 100;
    visibility: visible;
    height: 22.5rem;
    width: 22.5rem;
  }
}

.icon-chevron-down {
  display: none;
  position: absolute;
  right: 2%;
  top: -4rem;
  color: $white;
  border-radius: 50%;
  height: 4rem;
  width: 4rem;

  &.active {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.chat {
  position: absolute;
  bottom: 0.75rem;
  padding: 0 0.75rem;

  .shift-on-hover {
    transition: left 0.3s ease;
    position: relative;
    left: 0;
  }
  &:hover {
    .shift-on-hover {
      left: 0.5rem;
    }
  }
}

.copy-email-btn {
  margin-left: auto;

  &:hover {
    background-color: $tiber !important;
    i {
      color: white;
    }
  }
  &.dark:hover {
    background-color: $gray-400 !important;
    i {
      color: black;
    }
  }
  &:active {
    box-shadow: 0 5px #666;
    transform: translateY(4px);
  }
  justify-content: center;
  align-items: center;
}

// .copy-email-btn:hover i.icon-copy::before {
//   color: white !important;
// }

@include media-breakpoint-down(sm) {
  .contact-support-container {
    max-width: 92vw;
    padding-left: 0 !important;
    right: -2%;
    &.active {
      right: 3%;
    }
  }

  .contact-support.active {
    width: 100%;
    margin: 0 auto;
  }

  .chat {
    margin-top: 1.67rem;
  }

  .icon-question-container {
    margin-bottom: 1rem;
    margin-right: 0.5rem;
  }
}
</style>
