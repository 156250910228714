<template lang="pug">
b-progress.progress-indicator-container
  b-progress-bar(
    v-for="(step, index) in steps",
    :key="index",
    :value="progress",
    :label="step.label")
    .d-flex.align-items-center
      n-icon.text-teal-800.mr-2(name="check-circle", v-if="index<currentStepNumber-1")
      .progress-indicator-bullet.mr-2.d-flex.justify-content-center.align-items-center(v-else,
        :class="index===currentStepNumber-1? 'active-bullet' : 'inactive-bullet'")
        span.font-sm(:class="index === currentStepNumber-1 ? 'text-teal-800' : 'text-gray-600'") {{ index + 1 }}
      .progress-indicator-label.p-0.m-0.d-md-none.d-lg-block.font-md(
        :class="index === currentStepNumber - 1 ? 'active-label' : 'inactive-label'") {{ step.label }}
      .progress-indicator-line.linear.mx-2(v-if="index !== steps.length - 1", :class="{ 'active-line': index < currentStepNumber - 1 }")
</template>

<script>
export default {
  name: "ProgressIndicator",
  props: {
    currentStepNumber: {
      type: Number,
      default: 1,
    },
    totalSteps: {
      type: Number,
      default: 4,
    },
    steps: {
      type: Array,
      default: function () {
        return [
          { label: "Add Members" },
          { label: "Select Address" },
          { label: "Select Provider & Slot" },
          { label: "Review" },
        ];
      },
    },
  },
  data() {
    return {
      progress: 100 / this.totalSteps,
    };
  },
  methods: {},
};
</script>

<style lang="scss">
@import "@/assets/styles/_variables.scss";

.progress-indicator-container {
  height: auto !important;
  .progress-bar {
    background-color: white;
  }
  .progress-indicator-bullet {
    width: 1.25rem;
    min-width: 1.25rem;
    height: 1.25rem;
    border-radius: 50%;

    &.active-bullet {
      border: 1px solid $teal-700;
    }
    &.inactive-bullet {
      border: 1px solid $gray-400;
    }
  }
  .active-label {
    font-weight: 600;
    color: $teal-800;
  }
  .inactive-label {
    font-weight: 400;
    color: $gray-700;
  }
  .progress-indicator-line {
    background-color: transparent;
    border-top: 0.063rem dashed $gray-600;
    min-width: 0.875rem;
    flex-grow: 1;
    &.active-line {
      border-top: 0.063rem dashed $teal-700;
    }
  }
}
</style>
