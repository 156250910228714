import gql from "graphql-tag";
import { getListQuery, getSingleQuery, getResourceListQuery } from "../queries";
import { dependentsFragment, userBenefitsFragment, userResourceListFragment } from "../fragments";
import usersSchema from "../schemas/users";
import usersMutations from "../mutations/users";
import { UserAddSource } from "../../../../common/enums";

export default {
  gqlType: "User",
  fields: ["empId", "email", "name", "gender", "orgName", "status"],
  singleQuery: getSingleQuery("User"),
  resourceListQuery: getResourceListQuery("User", ["empId", "email", "name", "gender", "orgName", "status"]),
  resourceListFragment: userResourceListFragment,
  resourceListFragmentName: "User",
  listQuery: getListQuery("User"),
  ...usersSchema,
  ...usersMutations,
  transform: (n) => {
    return {
      ...n,
      orgName: n.org ? n.org.name : null,
      orgEntityName: n.orgEntity ? n.orgEntity.name : null,
      orgId: n.org ? n.org.id : null,
      entityId: n.orgEntity ? n.orgEntity.id : null,
    };
  },
  transformUpsertMutationVariables: (variables) => {
    // TODO: Remove and shift to a cleaner solution ASAP.
    const { id, org, name, displayName, email, gender, dob, roles, oldPassword, password, meta, dependents, status } = {
      ...variables,
    };
    if (!id) meta.source = UserAddSource.ADMIN;
    const trimmedDependents = dependents?.map((dependent) => {
      const { id, name, dob, relation, gender } = { ...dependent };
      return {
        id,
        name,
        dob,
        relation,
        gender,
      };
    });
    return {
      id,
      orgId: org.id,
      orgEntityId: variables?.orgEntity?.id,
      name,
      displayName,
      email,
      gender,
      dob,
      roles,
      oldPassword,
      password,
      meta,
      dependents: trimmedDependents,
      status,
    };
  },
  transformAddBenefit: (data) => data.addUserToBenefit.userBenefitEdge,
  transformDeleteBenefit: (data) => data.deleteUserFromBenefit.deletedCount,
  benefitsFragment: userBenefitsFragment,
  benefitsFragmentName: "UserBenefits",
  transformAddDependent: (data) => data.upsertDependent.dependent,
  transformInsertDependentWithEndorsements: (data) => data.insertDependentWithEndorsements.dependent,
  transformDeleteDependent: (data) => data.deleteDependent.deletedCount,
  dependentsFragment: dependentsFragment,
  dependentsFragmentName: "UserDependents",
  impersonateUser: gql`
    query getUserToken($id: ID!) {
      getUserToken(id: $id)
    }
  `,
};
