import gql from "graphql-tag";
import { userAddressFragment } from "../../portal/pages/admin/fragments/userAddress";
import { dependentFragment, userBenefitFragmentWithoutContent } from "./../../portal/pages/admin/fragments";

export const LEAN_USER_FRAGMENT = gql`
  fragment User on User {
    id
    name
    displayName
    roles
    dob
    meta
    gender
    status
    email
    dependents {
      ...Dependent
    }
    photoFile {
      id
      url
    }
    org {
      id
      name
      featureFlags
      meta
      brandCustomizations
      orgEntities {
        id
        name
      }
    }
    orgEntity {
      id
      name
      featureFlags
      meta
      brandCustomizations
    }
    benefits {
      ...UserBenefitEdgeWithoutContent
    }
    isPasswordWeak
    userAddresses {
      ...UserAddress
    }
    ngageUserDetail {
      id
      meta
      deviceIntegrationProvider
      updatedAt
    }
    insuranceStatus
  }
  ${userBenefitFragmentWithoutContent}
  ${dependentFragment}
  ${userAddressFragment}
`;

export const LOGIN_MUTATION = gql`
  mutation LoginMutation(
    $email: String
    $password: String
    $auth_type: String!
    $recaptchaToken: JSONObject
    $phoneNumber: String
    $otp: String
  ) {
    authenticateUser(
      input: {
        email: $email
        password: $password
        auth_type: $auth_type
        recaptchaToken: $recaptchaToken
        phoneNumber: $phoneNumber
        otp: $otp
      }
    ) {
      refreshToken
      token
      passwordResetToken
      user {
        ...User
      }
    }
  }
  ${LEAN_USER_FRAGMENT}
`;

export const SSO_USER_TOKEN_QUERY = gql`
  query fetchAuthTokens($ssoGrantToken: String) {
    fetchAuthTokens(ssoGrantToken: $ssoGrantToken)
  }
`;

export const MAGICLINK_MUTATION = gql`
  mutation sendMagicLink($email: String!, $recaptchaToken: JSONObject!) {
    sendMagicLink(input: { email: $email, recaptchaToken: $recaptchaToken }) {
      success
    }
  }
`;

export const VALIDATE_MAGICLINK_MUTATION = gql`
  mutation magicLinkValidation($magicToken: String!) {
    magicLinkValidation(input: { magicToken: $magicToken }) {
      token
      refreshToken
      user {
        ...User
      }
    }
  }
  ${LEAN_USER_FRAGMENT}
`;

export const MY_INFO = gql`
  query MyInfo {
    me {
      id
      email
      name
      displayName
      gender
      dob
      meta
      status
      insuranceStatus
      roles
      isMidTerm
      photoFile {
        id
        name
        url
        meta
      }
      org {
        id
        name
        status
        isAssessmentAvailable
        featureFlags
        brandCustomizations
        meta
        preferences
        benefitsDisplayOrder
        employeeAssessments
        orgEntities {
          id
          name
          meta
          address
          pincode
        }
        customBanners
      }
      orgEntity {
        id
        name
        status
        featureFlags
        brandCustomizations
        preferences
        employeeAssessments
        meta
      }
      dependents {
        ...Dependent
      }
      benefits {
        ...UserBenefitEdgeWithoutContent
      }
      userAddresses {
        ...UserAddress
      }
      inviteLogs
      restrictDependentAddition
      ngageUserDetail {
        id
        meta
        deviceIntegrationProvider
        updatedAt
      }
      doesActiveStepCountChallengeExist
      hasAssessmentReports
    }
  }
  ${dependentFragment}
  ${userBenefitFragmentWithoutContent}
  ${userAddressFragment}
`;

export const REFRESH_TOKEN = gql`
  mutation AuthenticateWithRefreshToken($refreshToken: String) {
    authenticateWithRefreshToken(input: { refreshToken: $refreshToken }) {
      token
      user {
        ...User
      }
    }
  }
  ${LEAN_USER_FRAGMENT}
`;

export const LOGIN_CONFIG = gql`
  query fetchLoginConfig($email: String) {
    fetchLoginConfig(email: $email)
  }
`;

export const VERIFY_TOKEN = gql`
  mutation VerifyToken($token: String!, $email: String!) {
    verifyToken(input: { token: $token, email: $email }) {
      refreshToken
      token
      user {
        ...User
      }
      passwordlessOnboarding
    }
  }
  ${LEAN_USER_FRAGMENT}
`;

export const SET_INVITED_USER_PASSWORD = gql`
  mutation setPassword($token: String!, $email: String!, $newPassword: String!) {
    setPassword(input: { token: $token, email: $email, newPassword: $newPassword }) {
      passwordSet
    }
  }
`;

export const IMPERSONATE_USER = gql`
  query getUserToken($id: ID!) {
    getUserToken(id: $id)
  }
`;

export const GET_USER_EMAIL_FROM_TOKEN = gql`
  query getUserEmailFromToken($token: String!) {
    getUserEmailFromToken(token: $token)
  }
`;

export const CHANGE_USER_PASSWORD = gql`
  mutation changePassword($id: ID!, $currentPassword: String, $newPassword: String!, $isAdminPage: Boolean) {
    changePassword(
      input: { id: $id, currentPassword: $currentPassword, newPassword: $newPassword, isAdminPage: $isAdminPage }
    ) {
      passwordUpdated
    }
  }
`;

export const GENERATE_OTP_FOR_LOGIN = gql`
  mutation SendOtpForLogin($contactNumber: String!, $recaptchaToken: JSONObject) {
    sendOtpForLogin(input: { contactNumber: $contactNumber, recaptchaToken: $recaptchaToken }) {
      status
    }
  }
`;
