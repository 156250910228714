<template lang="pug">
.row.mx-0.px-0
  .link.text-left.pb-1.px-0(
    v-if="fieldLabel",
    :class="labelPos==='top'?'col-12':'col-12 col-md-3'")
    template(v-if="chip && chip.text")
      div.d-flex.justify-content-between.align-items-center
        span.field-label(:class="labelPos==='top'?'font-sm':'font-md'") {{ fieldLabel }}
        nova-chip( :variant="chip.variant") {{ chip.text }}
    template(v-else)
      span(:class="labelPos==='top'?'font-sm':'font-md'") {{ fieldLabel }}

  .px-0(:class="labelPos==='top'?'w-100':'col-12 col-md-9'")
    .row.mx-0.p-0.input-wrapper(:class="[state, customClasses, readonly || disabled ? 'bg-gray-200':'']")
      template(v-if="this.inputType==='tel'")
        span.align-self-center.pl-2.ml-1.text-gray-600.font-weight-medium(:class="telPrefixClass()") +91
      template(v-else)
        label(:for="`n-inline-input-${_uid}`")
          .d-inline-block.h-100.font-sm.pl-3.py-0.cursor-text(v-if="imageIcon")
            .text-center.icon-position(:class="'icon-'+imageIcon")
      .d-inline-block.py-0(:class="getInputWidthClass()")
        input.nova-input.inline-input.px-2.border-0.w-100(
          :id="`n-inline-input-${_uid}`",
          :class="{'pl-3': !imageIcon && this.inputType!=='tel'}",
          v-bind="{...inputProps, ...inputAttrs}",
          v-model="ctrlValue",
          :type="inputType",
          ref="inputField")
      .d-inline-block.h-100.pl-2.py-0(v-if="rightImageIcon || isPasswordField")
        .text-center.icon-position.cursor-pointer(:class="rightIconClasses", @click="handleRightIconClick")

    .input-sub-text.text-left.pt-2(v-if="validationMessage", :class="state")
      n-icon.pr-1.sub-text-icon.align-middle(v-if="state && state !== 'disabled'", :name="stateIconMap[state] || ''", :size="1")
      span.align-middle {{ validationMessage }}
    .input-sub-text.link.text-left.pt-2(v-if="subInfoLink")
      span.pr-1.icon-plus-circle.sub-text-icon
      b-link.font-sm(:href="subInfoLink", target="_blank") Learn more about this
    .input-sub-text.text-left.pt-2.d-flex.align-items-center(v-if="subText")
      n-icon.sub-text-icon.align-middle(v-if="subTextIcon", :name="subTextIcon", :variant="subTextIconVariant", :customClass="subTextIconClass", :size="1", :pathCount="subTextIconPathCount")
      span.ml-1 {{ subText }}
</template>

<script>
import NovaChip from "./NovaChip.vue";
import NIcon from "./NovaIcon.vue";

export default {
  name: "NInlineInput",
  components: {
    NovaChip,
    NIcon,
  },
  props: {
    /* eslint-disable-next-line vue/require-prop-types, vue/require-default-prop */
    value: {},
    fieldLabel: {
      type: String,
      default: null,
    },
    labelPos: {
      type: String,
      default: "top",
    },
    placeholder: {
      type: String,
      default: "",
    },
    state: {
      type: String,
      default: null,
    },
    customClasses: {
      type: Array,
      default: () => [],
    },
    imageIcon: {
      type: String,
      default: null,
    },
    rightImageIcon: {
      type: String,
      default: null,
    },
    required: {
      type: Boolean,
      default: false,
    },
    subText: {
      type: String,
      default: null,
    },
    subTextIcon: {
      type: String,
      default: null,
    },
    subTextIconClass: {
      type: String,
      default: null,
    },
    subTextIconPathCount: {
      type: String,
      default: null,
    },
    validationMessage: {
      type: String,
      default: null,
    },
    subInfoLink: {
      type: String,
      default: null,
    },
    inputType: {
      type: String,
      default: "text",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    chip: {
      type: Object,
      default: () => ({}),
    },
    allowZero: {
      type: Boolean,
      default: false,
    },
    shouldAnimateTel: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const stateIconMap = {
      success: "check-circle",
      error: "error",
      warning: "warning",
      info: "error",
    };
    return {
      stateIconMap,
      fieldType: "",
      rightIconClasses: [],
    };
  },
  computed: {
    ctrlValue: {
      get() {
        return this.value;
      },
      set(value) {
        if (this.inputType === "number") {
          if (value === "0" && !this.allowZero) {
            this.$emit("input", null);
          } else {
            this.$emit("input", parseFloat(value));
          }
        } else {
          this.$emit("input", value);
        }
      },
    },
    isPasswordField() {
      return this.inputType === "password";
    },
    inputProps() {
      return this.filterNonApplicableFields(this.$props);
    },
    inputAttrs() {
      return this.filterNonApplicableFields(this.$attrs);
    },
  },
  watch: {
    inputType() {
      this.fieldType = this.inputType || "text";
      this.setRightIconClasses();
    },
    rightImageIcon() {
      this.setRightIconClasses();
    },
  },
  mounted() {
    this.fieldType = this.inputType || "text";
    this.setRightIconClasses();
  },
  methods: {
    getInputWidthClass() {
      if ((this.imageIcon && (this.rightImageIcon || this.isPasswordField)) || this.inputType === "tel") {
        return "w-input-both-icons";
      }
      if (this.imageIcon || this.rightImageIcon || this.isPasswordField) {
        return "w-input-one-icon";
      }
      return "w-100";
    },
    handleRightIconClick() {
      if (!this.isPasswordField) {
        this.$emit("click-right-icon");
        return;
      }
      const pwdField = document.getElementById(`n-inline-input-${this._uid}`);
      pwdField.type = pwdField.type === "password" ? "text" : "password";
      this.fieldType = pwdField.type;
      this.setRightIconClasses();
    },
    setRightIconClasses() {
      if (this.isPasswordField) {
        this.rightIconClasses = [
          "icon-eye" + (this.fieldType === "text" ? "-closed" : ""),
          "cursor-pointer",
          "password-toggle-color",
        ];
      } else {
        this.rightIconClasses = ["icon-" + this.rightImageIcon];
      }
    },
    filterNonApplicableFields(fields) {
      const {
        validator,
        validationMessage,
        label,
        inputType,
        fieldLabel,
        labelPos,
        model,
        chip,
        customClasses,
        imageIcon,
        rightImageIcon,
        subText,
        subTextIcon,
        subTextIconPathCount,
        subTextIconClass,
        subInfoLink,
        state,
        ...applicableFields
      } = fields;
      return applicableFields;
    },
    telPrefixClass() {
      return this.shouldAnimateTel ? "animate-tel" : "";
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
input {
  border-width: 0;
  padding: 0;
  background-color: inherit;
  cursor: inherit;
}
.inline-input {
  min-height: 2.25rem !important;
  box-sizing: border-box;
  color: $gray-900;
}
.icon-position {
  position: relative;
  top: 0.5rem;
  width: 1.25rem;
}
.border-right-grey-400 {
  border-right: 1.75px solid $gray-400;
}
.w-input-one-icon {
  width: calc(100% - 2.25rem);
}
.w-input-both-icons {
  width: calc(100% - 5rem);
}
.sub-text-icon {
  font-size: 1rem;
  vertical-align: text-bottom;
}
.password-toggle-color {
  color: $gray-800 !important;
}
.input-wrapper {
  max-height: 2.25rem;
}
.animate-tel {
  animation-delay: 0.05s;
  animation: forwards 0.3s expand cubic-bezier(0.075, 0.82, 0.165, 1);
}
@keyframes expand {
  from {
    transform: scaleX(0.1) translateX(-20px);
  }
  to {
    transform: scaleX(1) translateX(0);
  }
}
</style>
