import { camelCase } from "lodash-es";
import { ClaimCategory } from "../../../common/enums/claims/claimCategory";
import { ClaimDocument, ExcludedClaimDocs } from "../../../common/enums/claims/claimDocument";
import { ClaimStage } from "../../../common/enums/claims/claimStage";
import { ClaimStatus } from "../../../common/enums/claims/claimStatus";
import { ClaimType } from "../../../common/enums/claims/claimType";
import { OPDDocument } from "../../../common/enums/claims/opdDocuments";
import { ProcessFlow } from "../../../common/enums/claims/processFlow";
import { Status as StatusEnum } from "../../../common/enums/claims/status";
import { claimTypeToClaimCategory } from "../../../common/enums/claims/maps/claimTypeToClaimCategory";
import { processFlowToStages } from "../../../common/enums/claims/maps/processFlowToStages";
import { NgageDeviceIntegrationProviders } from "../../../common/enums/ngageDeviceIntegrationProviders.enum";

export const challengeStatusSchema = {
  CREATED: "CREATED",
  IN_PROGRESS: "IN_PROGRESS",
  ON_HOLD: "ON_HOLD",
  COMPLETED: "COMPLETED",
  SUCCESS: "SUCCESS",
  FAILURE: "FAILURE",
  DELETED: "DELETED",
};
export const OrgType = {
  WELLNESS_AND_INSURANCE: "WELLNESS_AND_INSURANCE",
  WELLNESS_ONLY: "WELLNESS_ONLY",
  INSURANCE_ONLY: "INSURANCE_ONLY",
};

export const orgStatusSchema = [
  { id: "ACTIVE", name: "Active" },
  { id: "DELETED", name: "Deleted" },
  { id: "PROSPECT", name: "Prospect" },
  { id: "INACTIVE", name: "Inactive" },
];

export const orgEntityStatusSchema = [
  { id: "ACTIVE", name: "Active" },
  { id: "DELETED", name: "Deleted" },
  { id: "INACTIVE", name: "Inactive" },
];

export const userRoles = [
  "guest",
  "user",
  "org_member",
  "org_admin",
  "account_admin",
  "admin",
  "prospect",
  "checkup_admin",
  "org_entity_admin",
  "batman",
  "cx_manager",
  "customer_support",
];

export const policyTypes = {
  "no-policy-defined": {
    title: "Group Health Insurance",
    subtitle: "Just a little longer before your policy is activated.",
    image: "nopolicy",
    icon: "hospital",
    type: "no-policy-defined",
  },
  gmc: {
    title: "Group Health Insurance",
    subtitle: "Your group mediclaim insurance policy",
    tagline: "GMC - Group Mediclaim Policy",
    icon: "hospital",
    image: "stethoscope",
    color: "red-500",
    bgColor: "red-100",
    displayTitle: "Group Medical Cover",
  },
  opd: {
    title: "OPD Coverage",
    subtitle: "Your group OPD insurance coverage policy",
    tagline: "OPD - Outpatient Department Coverage",
    icon: "clinic",
    image: "stethoscope",
    color: "malibu-800",
    bgColor: "malibu-100",
    displayTitle: "Group OPD Cover",
  },
  gpa: {
    title: "Group Personal Accident Coverage",
    subtitle: "Your group personal accident insurance policy details",
    tagline: "GPA - Group Personal Accident",
    icon: "bed",
    image: "hospital-bed",
    color: "mustard-600",
    bgColor: "mustard-100",
    displayTitle: "Group Personal Accident Policy",
  },
  gtl: {
    title: "Group Term Life Insurance",
    subtitle: "Your group term life insurance policy",
    tagline: "TL - Term Life Insurance",
    icon: "umbrella",
    image: "umbrella",
    color: "malibu-600",
    bgColor: "malibu-100",
    displayTitle: "Group Term Life Policy",
  },
  covid: {
    title: "COVID Protection",
    subtitle: "Wearing a mask and washing your hands is still the best policy",
    tagline: "COV19 - Covid Coverage Policy",
    icon: "covid",
    image: "hospital-bed",
    color: "purple-400",
    bgColor: "purple-100",
    displayTitle: "Covid",
  },
  topup: {
    title: "Top-up",
    subtitle: "Better coverage for you and your family",
    tagline: "Top-up",
    icon: "flash",
    image: "extra-coverage",
    color: "teal-700",
    bgColor: "teal-100",
    displayTitle: "Topup Policy",
  },
  "super-topup": {
    title: "Super Top-up",
    subtitle: "Better coverage for you and your family",
    tagline: "Super Top-up",
    icon: "flash",
    image: "extra-coverage",
    color: "teal-700",
    bgColor: "teal-100",
    displayTitle: "Super Topup Policy",
    isPurchased: true,
  },
  dental: {
    title: "Dental Care",
    subtitle: "Make sure the smile stays on",
    tagline: "Dental Care Policy",
    icon: "teeth",
    image: "dental-tools",
    color: "blue-400",
    bgColor: "blue-100",
    displayTitle: "Dental Policy",
  },
  gratuity: {
    title: "Future Service Gratuity",
    subtitle: "Your gratuity benefit",
    tagline: "Future Service Gratuity",
    icon: "circular-star",
    color: "malibu-800",
    bgColor: "malibu-100",
    displayTitle: "Future Service Gratuity",
  },
  workmen: {
    title: "Workmen Compensation",
    subtitle: "Your workmen compensation insurance policy details",
    tagline: "Workmen Compensation Insurance Policy",
    icon: "wheelchair",
    image: "hospital-bed",
    color: "gray-900",
    bgColor: "gray-100",
    displayTitle: "Workmen Compensation Insurance Policy",
  },
  dcl: {
    title: "Life Insurance",
    subtitle: "Your direct contractual liability policy details",
    tagline: "CL - Contractual Liability Insurance",
    icon: "bed",
    image: "umbrella",
    color: "malibu-600",
    bgColor: "malibu-100",
    displayTitle: "Direct Contractual Liability Policy",
  },
  ppc: {
    title: "Parental Policy",
    subtitle: "Health insurance policy for your parents",
    tagline: "Health insurance policy for your parents",
    icon: "hospital",
    image: "stethoscope",
    color: "red-500",
    bgColor: "red-100",
    displayTitle: "Parental Policy",
  },
  addOnCards: {
    "topup-card": {
      title: "Looking for extra\ncoverage options?",
      subtitle: "You can purchase a super top-up over your work policy and expand your coverage by 50 lacs",
      btnText: "Learn more about super top-up ->",
      image: "new-product-bg-1.svg",
    },
    "flexi-wallet-card": {
      title: "Want to customize your benefits?",
      subtitle: "You can customize your benefits with the\nadditional wallet amount from your company.",
      btnText: "Customize Your Benefits ->",
      image: "flexi-wallet-card.svg",
    },
  },
};

export const benefitTypes = {
  "partner-signup": {
    subtitle: "Employee Benefits",
  },
  other: {
    subtitle: "Other",
  },
  "free-coupon": {
    subtitle: "Free Coupon",
  },
  "embeded-partners": {
    subtitle: "Embeded Partner",
  },
  "sso-partners": {
    subtitle: "SSO Partner",
  },
  "checkup-partners": {
    subtitle: "Checkup Partner",
  },
};

export const claimsStatus = StatusEnum;

export const claimStatusList = Object.values(claimsStatus);

export const claimStatusListAsCrm = Object.values(ClaimStatus);

export const claimDocumentsList = Object.keys(ClaimDocument)
  .filter((key) => !ExcludedClaimDocs.includes(ClaimDocument[key]))
  .map((key) => ClaimDocument[key]);

export const claimDocumentSegments = {
  "Insurance Claim Forms": [
    ClaimDocument.CLAIM_FORM_PART_A,
    ClaimDocument.CLAIM_FORM_PART_B,
    ClaimDocument.OTHER_CLAIM_FORMS,
  ],
  "Identification Docs": [
    ClaimDocument.AADHAAR_PATIENT,
    ClaimDocument.AADHAAR_PRIMARY,
    ClaimDocument.PAN_PRIMARY,
    ClaimDocument.E_CARD,
    ClaimDocument.CANCELLED_CHEQUE,
  ],
  "Medical Reports & Prescriptions": [
    ClaimDocument.CONSULTATION_PAPERS,
    ClaimDocument.MED_INV_PRESCRIPTION,
    ClaimDocument.IMPLANTS_LABEL,
    ClaimDocument.INVESTIGATION_REPORT,
    ClaimDocument.DISCHARGE_SUMMARY,
  ],
  "Bills & Receipts": [
    ClaimDocument.PHARMACY_BILL,
    ClaimDocument.INVESTIGATION_BILL,
    ClaimDocument.IMPLANTS_INVOICE,
    ClaimDocument.HOSPITAL_BILL,
    ClaimDocument.OPD_BILL,
    ClaimDocument.PAID_RECEIPT,
  ],
  "Other Documents": [ClaimDocument.OTHER_DOCUMENTS],
  "Query Documents": [ClaimDocument.QUERY_DOCUMENTS],
};

export const claimDocumentSegmentMandatoryDoc = {
  "Insurance Claim Forms": [ClaimDocument.CLAIM_FORM_PART_A, ClaimDocument.CLAIM_FORM_PART_B],
  "Identification Docs": [ClaimDocument.AADHAAR_PATIENT, ClaimDocument.AADHAAR_PRIMARY, ClaimDocument.CANCELLED_CHEQUE],
  "Medical Reports & Prescriptions": [ClaimDocument.INVESTIGATION_REPORT, ClaimDocument.DISCHARGE_SUMMARY],
  "Bills & Receipts": [ClaimDocument.HOSPITAL_BILL],
};

export const opdClaimDocumentSegmentMandatoryDoc = {
  "Insurance Claim Forms": [ClaimDocument.CLAIM_FORM_PART_A],
  "Identification Docs": [ClaimDocument.AADHAAR_PATIENT, ClaimDocument.AADHAAR_PRIMARY, ClaimDocument.CANCELLED_CHEQUE],
  "Medical Reports & Prescriptions": [],
  "Bills & Receipts": [],
};

export const claimDocumentDescription = new Map();
claimDocumentDescription.set(ClaimDocument.CLAIM_FORM_PART_A, "");
claimDocumentDescription.set(ClaimDocument.CLAIM_FORM_PART_B, "");
claimDocumentDescription.set(ClaimDocument.AADHAAR_PRIMARY, "");
claimDocumentDescription.set(ClaimDocument.AADHAAR_PATIENT, "");
claimDocumentDescription.set(
  ClaimDocument.CANCELLED_CHEQUE,
  "Cancelled cheque from the Primary Insured, addressed to the Corporation that they work for (not the direct employer)"
);
claimDocumentDescription.set(ClaimDocument.PAN_PRIMARY, "");
claimDocumentDescription.set(ClaimDocument.E_CARD, "");
claimDocumentDescription.set(ClaimDocument.AML_ID_PROOF, "");
claimDocumentDescription.set(
  ClaimDocument.DISCHARGE_SUMMARY,
  "The final detailed treatment summary given by the hospital at the time of discharge"
);
claimDocumentDescription.set(
  ClaimDocument.INVESTIGATION_REPORT,
  "Reports with the results of any tests and investigations conducted (eg. blood tests, x-rays, MRI etc)"
);
claimDocumentDescription.set(
  ClaimDocument.HOSPITAL_BILL,
  "Final bill received from the hospital (this bill would be created by the hospital at the end of the treatment, at the time of the discharge)"
);
claimDocumentDescription.set(ClaimDocument.PAID_RECEIPT, "Proof of your payment to all the expenses incurred");
claimDocumentDescription.set(
  ClaimDocument.MED_INV_PRESCRIPTION,
  "Prescription written to buy medication and supplies from the pharmacy, and for other tests and investigations (eg. blood tests, x-rays, MRI etc)"
);
claimDocumentDescription.set(ClaimDocument.PHARMACY_BILL, "All medicine and medical equipment bills");
claimDocumentDescription.set(
  ClaimDocument.INVESTIGATION_BILL,
  "All bills of tests and investigations that were conducted (eg. blood tests, x-rays, MRI etc)"
);
claimDocumentDescription.set(
  ClaimDocument.CONSULTATION_PAPERS,
  "Consultation papers from the treating Doctor - this would include notes taken during your visit, treatment plans and other instructions"
);
claimDocumentDescription.set(ClaimDocument.IMPLANTS_INVOICE, "Bill for the implant/s purchased");
claimDocumentDescription.set(
  ClaimDocument.IMPLANTS_LABEL,
  "Any documents that help with the details of the implant (eg. the labels, packaging, stickers on the implant)"
);
claimDocumentDescription.set(ClaimDocument.OPD_BILL, "Applicable for OPD reimbursement claims only");
claimDocumentDescription.set(
  ClaimDocument.OTHER_DOCUMENTS,
  "Add any other documents that have not been covered in the list above"
);
claimDocumentDescription.set(
  ClaimDocument.QUERY_DOCUMENTS,
  "Please add only those documents, that have been requested in the form of a ‘Query’."
);

export const opdClaimDocumentsList = Object.keys(OPDDocument).map((key) => OPDDocument[key]);

export const opdClaimDocumentSegments = {
  "Insurance Claim Forms": [OPDDocument.CLAIM_FORM_PART_A],
  "Identification Docs": [
    OPDDocument.AADHAAR_PATIENT,
    OPDDocument.AADHAAR_PRIMARY,
    OPDDocument.PAN_PRIMARY,
    OPDDocument.E_CARD,
    OPDDocument.CANCELLED_CHEQUE,
  ],
  "Medical Reports & Prescriptions": [
    OPDDocument.DOC_CONSULTATION_PAPERS,
    OPDDocument.MED_INV_PRESCRIPTION,
    OPDDocument.REPORTS,
  ],
  "Bills & Receipts": [
    OPDDocument.PHARMACY_BILL,
    OPDDocument.INVESTIGATION_BILL,
    OPDDocument.OPD_BILL,
    OPDDocument.PAYMENT_RECEIPT,
  ],
  "Other Documents": [OPDDocument.OTHER_DOCUMENTS],
};

export const opdClaimDocumentDescription = new Map();

// Insurance Claim Forms
opdClaimDocumentDescription.set(OPDDocument.CLAIM_FORM_PART_A, "");

// Identification Docs
opdClaimDocumentDescription.set(OPDDocument.AADHAAR_PRIMARY, "");
opdClaimDocumentDescription.set(OPDDocument.AADHAAR_PATIENT, "");
opdClaimDocumentDescription.set(OPDDocument.PAN_PRIMARY, "");
opdClaimDocumentDescription.set(OPDDocument.E_CARD, "");
opdClaimDocumentDescription.set(
  OPDDocument.CANCELLED_CHEQUE,
  "Cancelled cheque from the Primary Insured, addressed to the Corporation that they work for (not the direct employer)"
);

// Medical Reports & Prescriptions
opdClaimDocumentDescription.set(
  OPDDocument.DOC_CONSULTATION_PAPERS,
  "Consultation papers from the treating Doctor - this would include notes taken during your visit, treatment plans and other instructions"
);
opdClaimDocumentDescription.set(
  OPDDocument.MED_INV_PRESCRIPTION,
  "Prescription written to buy medication and supplies from the pharmacy, and for other tests and investigations (eg. blood tests, x-rays, MRI etc)"
);
opdClaimDocumentDescription.set(
  OPDDocument.REPORTS,
  "Reports with the results of any tests and investigations conducted (eg. blood tests, x-rays, MRI etc)"
);

// Bills & Receipts
opdClaimDocumentDescription.set(OPDDocument.PHARMACY_BILL, "All medicine and medical equipment bills");
opdClaimDocumentDescription.set(
  OPDDocument.INVESTIGATION_BILL,
  "All bills of tests and investigations that were conducted (eg. blood tests, x-rays, MRI etc)"
);
opdClaimDocumentDescription.set(OPDDocument.OPD_BILL, "All bills of OPD consultations.");
opdClaimDocumentDescription.set(OPDDocument.PAYMENT_RECEIPT, "Proof of your payment to all the expenses incurred");

// Other Documents
opdClaimDocumentDescription.set(
  OPDDocument.OTHER_DOCUMENTS,
  "Add any other documents that have not been covered in the list above"
);
claimDocumentDescription.set(
  ClaimDocument.QUERY_DOCUMENTS,
  "Please add only those documents, that have been requested in the form of a ‘Query’."
);

export const acceptedFamilyDefinitionsList = [
  "E",
  "ES",
  "ES2C",
  "ES2CP",
  "ES2CP/IL",
  "ES2CP+IL",
  "ES3C",
  "ES3CP",
  "ES3CP/IL",
  "ES3CP+IL",
  "ES4C",
  "ES4CP",
  "ES4CP/IL",
  "ES4CP+IL",
  "ES5C",
  "ES5CP",
  "ES5CP/IL",
  "ES5CP+IL",
  "ES6C",
  "ES6CP",
  "ES6CP/IL",
  "ES6CP+IL",
  "ES8CP/IL",
  "ES8CP+IL",
  "EP/IL",
  "EP",
  "P",
  "P/IL",
  "$E_S_2(C+W)_P/IL",
  "$E_S_2C_2(P+IL)",
];

export const acceptedPackageFreeForList = [
  "None",
  "E",
  "S",
  "ES",
  "ES2C",
  "ES2CP",
  "ES2CP/IL",
  "ES2CP+IL",
  "ES3C",
  "ES3CP",
  "ES3CP/IL",
  "ES3CP+IL",
  "ES4C",
  "ES4CP",
  "ES4CP/IL",
  "ES4CP+IL",
  "ES5C",
  "ES5CP",
  "ES5CP/IL",
  "ES5CP+IL",
  "ES6C",
  "ES6CP",
  "ES6CP/IL",
  "ES6CP+IL",
  "ES8CP/IL",
  "ES8CP+IL",
  "EP/IL",
  "EP",
  "P",
  "P/IL",
];
export const cdPaymentMethods = ["RTGS", "NEFT", "CHEQUE_DEPOSIT"];
export const cashlessClaimCategoryList = claimTypeToClaimCategory.get(ClaimType.CASHLESS);
export const reimbursementClaimCategoryList = claimTypeToClaimCategory.get(ClaimType.REIMBURSEMENT);
export const claimCategoryList = Object.values(ClaimCategory);
export const allClaimStageOptions = Object.values(ClaimStage);
export const processFlowList = Object.values(ProcessFlow);

export const claimStageListForAfterApproval = processFlowToStages.get(ProcessFlow.HARD_COPY_AFTER_APPROVAL);
export const claimStageListForBeforeApproval = processFlowToStages.get(ProcessFlow.HARD_COPY_BEFORE_APPROVAL);
export const claimStageListForNoHardCopy = processFlowToStages.get(ProcessFlow.NO_HARD_COPY);
export const claimStageListForNoFlow = processFlowToStages.get(ProcessFlow.NO_FLOW);

export const claimStageListForAfterApprovalKeyMap = {};
claimStageListForAfterApproval.map((stage) => {
  claimStageListForAfterApprovalKeyMap[stage] = camelCase(stage);
});

export const claimStageListForBeforeApprovalKeyMap = {};
claimStageListForBeforeApproval.map((stage) => {
  claimStageListForBeforeApprovalKeyMap[stage] = camelCase(stage);
});

export const claimStageListForNoHardCopyKeyMap = {};
claimStageListForNoHardCopy.map((stage) => {
  claimStageListForNoHardCopyKeyMap[stage] = camelCase(stage);
});

export const claimStageListForNoFlowKeyMap = {};
claimStageListForNoFlow.map((stage) => {
  claimStageListForNoFlowKeyMap[stage] = camelCase(stage);
});

export const processFlowStageMap = {};
processFlowStageMap[ProcessFlow.HARD_COPY_AFTER_APPROVAL] = claimStageListForAfterApprovalKeyMap;
processFlowStageMap[ProcessFlow.HARD_COPY_BEFORE_APPROVAL] = claimStageListForBeforeApprovalKeyMap;
processFlowStageMap[ProcessFlow.NO_HARD_COPY] = claimStageListForNoHardCopyKeyMap;
processFlowStageMap[ProcessFlow.NO_FLOW] = claimStageListForNoFlowKeyMap;

export const claimActionModal = {
  saveAndExit: {
    actionName: "Information Saved ✨",
    actionHeroImage: require("@/assets/images/Save_and_Exit_Header_Image_Container.svg"),
    actionDescription: "Your Claim is saved and sound. \nRedirecting you to the Claims list...",
  },
  saveAndNew: {
    actionName: "Opening New 💫",
    actionHeroImage: require("@/assets/images/Save_and_CreateNew_Header_Image_Container.svg"),
    actionDescription: "Your Claim is saved and sound. \nOpening a new empty form for you!",
  },
  save: {
    actionName: "Saving ⚡️",
    actionHeroImage: require("@/assets/images/save.svg"),
    actionDescription: "Sit tight, the claim data is getting updated to Nova...",
  },
  duplicate: {
    actionName: "Duplicating 💫",
    actionHeroImage: require("@/assets/images/Duplicate_Claim_Header_Image_Container.svg"),
    actionDescription: "Your Claim is saved and sound. \nDuplicating the claim details...",
  },
};

export const allClaimStageOptionKeys = [
  ...new Set([
    ...Object.values(claimStageListForAfterApprovalKeyMap),
    ...Object.values(claimStageListForBeforeApprovalKeyMap),
    ...Object.values(claimStageListForNoHardCopyKeyMap),
    ...Object.values(claimStageListForNoFlowKeyMap),
  ]),
];

export const orgOnboardingStages = {
  TEAM_SET_UP: "TEAM_SET_UP",
  COMMUNICATION: "COMMUNICATION",
  BENEFIT_ACTIVATION: "BENEFIT_ACTIVATION",
  POLICY_ADDITIONAL_DATA: "POLICY_ADDITIONAL_DATA",
  INSURER_REVIEW: "INSURER_REVIEW",
  ISSUE_POLICY_COPY: "ISSUE_POLICY_COPY",
  ISSUE_ECARDS: "ISSUE_ECARDS",
  ORIENTATION: "ORIENTATION",
};

// stages related to policy and benefits are not to be included
export const orgOnboardingStageSectionMap = {
  [orgOnboardingStages.TEAM_SET_UP]: "Org Setup",
  [orgOnboardingStages.COMMUNICATION]: "Org Setup",
  [orgOnboardingStages.ORIENTATION]: "Orientation",
};

export const orgOnboardingStageLabels = {
  TEAM_SET_UP: "Team Set up",
  COMMUNICATION: "Communication",
  POLICY_ADDITIONAL_DATA: "Additional data required",
  INSURER_REVIEW: "Under Insurer Review",
  ISSUE_POLICY_COPY: "Policy Copy Issued",
  ISSUE_ECARDS: "E-cards Issued",
  BENEFIT_ACTIVATION: "Benefits Activation",
  ORIENTATION: "Orientation",
};

export const orgOnboardingStageTaskLabels = {
  TEAM_EMPLOYEE_DATA_VERIFICATION: "Verifying Employee Data",
  TEAM_DOCUMENT_VERIFICATION: "Verifying Org Documents",
  POLICY_EMPLOYEE_DATA_VERIFICATION: "Verifying additional Employee Data",
  POLICY_DOCUMENT_VERIFICATION: "Verifying Policy Documents",
  INSURER_REVIEW: "Under Insurer Review",
  ISSUE_POLICY_COPY: "Policy Copy Issued",
  RECEIVED_ECARDS: "E-cards Received",
  ISSUE_ECARDS: "E-cards Issued",
  BENEFIT_ACTIVATION: "Benefits Activation",
  POLICY_PARAMETERS_EMAIL: "Policy Parameters Email",
  PORTAL_ACTIVATION_EMAIL: "Portal Activation Email",
  ECARD_EMAIL: "E-card Linked Email",
  SEND_EDUCATIONAL_EMAILS: "Send educational emails",
  ORIENTATION_SCHEDULED: "Orientation Scheduled",
  ORIENTATION_COMPLETED: "Orientation Completed",
};

export const orgOnboardingStageTaskStatusSchema = {
  NOT_STARTED: "NOT_STARTED",
  IN_PROGRESS: "IN_PROGRESS",
  ON_HOLD: "ON_HOLD",
  COMPLETED: "COMPLETED",
  DELETED: "DELETED",
};

export const orgOnboardingStageTaskStatusText = {
  NOT_STARTED: "Not Started",
  IN_PROGRESS: "Ongoing",
  ON_HOLD: "On Hold",
  COMPLETED: "Completed",
};

export const orgOnboardingStageTaskStatusColorClass = {
  NOT_STARTED: "red-600",
  IN_PROGRESS: "blue-600",
  ON_HOLD: "mustard-600",
  COMPLETED: "teal-700",
};

export const orgOnboardingStageTaskStatusWeight = {
  NOT_STARTED: 0,
  IN_PROGRESS: 2,
  ON_HOLD: 2,
  COMPLETED: 4,
};

export const orgOnboardingStageFilters = {
  org: [{ id: "TEAM_SET_UP", name: "Team Set up" }],
  policy: [
    { id: "POLICY_ADDITIONAL_DATA", name: "Additional data required" },
    { id: "INSURER_REVIEW", name: "Under Insurer Review" },
    { id: "ISSUE_POLICY_COPY", name: "Policy Copy Issued" },
    { id: "ISSUE_ECARDS", name: "E-cards Issued" },
  ],
  benefit: [{ id: "BENEFIT_ACTIVATION", name: "Benefits Activation" }],
  orientation: [{ id: "ORIENTATION", name: "Orientation" }],
};

export const orgOnboardingTaskFilters = {
  TEAM_SET_UP: [
    { id: "TEAM_EMPLOYEE_DATA_VERIFICATION", name: "Verifying Employee Data" },
    { id: "TEAM_DOCUMENT_VERIFICATION", name: "Verifying Org Documents" },
  ],
  POLICY_ADDITIONAL_DATA: [
    { id: "POLICY_EMPLOYEE_DATA_VERIFICATION", name: "Verifying additional Employee Data" },
    { id: "POLICY_DOCUMENT_VERIFICATION", name: "Verifying Policy Documents" },
  ],
  ORIENTATION: [
    { id: "ORIENTATION_SCHEDULED", name: "Orientation Scheduled" },
    { id: "ORIENTATION_COMPLETED", name: "Orientation Completed" },
  ],
};

export const sideNavbarType = {
  ORG_ADMIN: "orgAdmin",
  ADMIN: "admin",
};

export const sideNavbarData = {
  orgAdmin: {
    sideNavLinks: [
      {
        icon: "opacity-home-2",
        name: "Home",
        route: "/org-admin",
      },
      {
        icon: "opacity-endorsements",
        name: "Onboarding",
        route: "/org-admin/onboarding",
        excludeIn: [OrgType.WELLNESS_ONLY],
      },
      {
        icon: "opacity-employee-id",
        name: "Team",
        route: "/org-admin/members",
      },
      {
        icon: "opacity-endorsements",
        name: "Review Changes",
        route: "/org-admin/review-user-changes",
        excludeIn: [OrgType.WELLNESS_ONLY],
      },
      {
        icon: "opacity-endorsements",
        name: "Endorsements",
        route: "/org-admin/changes",
        excludeIn: [OrgType.WELLNESS_ONLY],
      },
      {
        icon: "rupee",
        name: "CD Accounts",
        route: "/org-admin/cd-accounts",
        excludeIn: [OrgType.WELLNESS_ONLY],
      },
      {
        icon: "office",
        name: "Org Hierarchy",
        route: "/org-admin/org-hierarchy",
      },
      {
        icon: "opacity-claims-2",
        name: "Claims",
        route: "/org-admin/claims",
      },
      {
        icon: "opacity-benefits",
        name: "Benefits",
        route: "/org-admin/benefits",
      },
      {
        icon: "opacity-organization",
        name: "Organization",
        route: "/org-admin/organization",
      },
      {
        icon: "document",
        name: "Documents",
        route: "/org-admin/files",
      },
      {
        icon: "analytics",
        iconPathCount: 2,
        name: "Analytics",
        route: "/org-admin/analytics",
      },
      {
        icon: "opacity-engineering-2",
        iconPathCount: 2,
        name: "Echo Templates",
        route: "/org-admin/echoPostTemplates",
        divideAfter: true,
      },
    ],
    sideNavFunctions: [
      {
        icon: "support-2",
        name: "Support",
      },
      {
        icon: "sign-out-2",
        variant: "red-300",
        name: "Sign out",
        route: "/login/logout",
      },
    ],
  },
  admin: {
    sideNavLinks: [
      {
        icon: "opacity-home-2",
        name: "Home",
        route: "/admin",
      },
      {
        icon: "opacity-users-2",
        name: "Users",
        iconPathCount: 3,
        route: "/admin/users",
      },
      {
        icon: "opacity-organization",
        name: "Orgs",
        route: "/admin/orgs",
      },
      {
        icon: "opacity-benefits",
        name: "Policies & Benefits",
        route: "/admin/policies",
      },
      {
        icon: "opacity-policies",
        name: "Insurers & TPAs",
        route: "/admin/insurers",
      },
      {
        icon: "opacity-cli-tools",
        name: "CLI Tools",
        route: "/admin/clitools",
      },
      {
        icon: "opacity-claims-2",
        name: "Claims",
        route: "/admin/claims",
      },
      {
        icon: "opacity-endorsements",
        name: "Endorsements",
        route: "/admin/review",
      },
      {
        icon: "rupee-box",
        name: "Orders",
        route: "/admin/NovaOrders",
      },
      {
        icon: "opacity-cx-entities",
        name: "CX Entities",
        iconPathCount: 4,
        route: "/admin/CXPods",
      },
      {
        icon: "opacity-engineering-2",
        name: "Engineering",
        route: "/admin/jobs",
      },
      {
        icon: "dumbell",
        name: "Fitness",
        route: "/admin/challengeTemplates",
      },
      {
        icon: "analytics",
        name: "Analytics",
        route: "/admin/analytics",
      },
      {
        icon: "opacity-employee-id",
        name: "Team Managment",
        route: "/admin/teams",
      },
      {
        icon: "miscellaneous",
        name: "Miscellaneous",
        route: "/admin/content",
        divideAfter: true,
      },
    ],
    sideNavFunctions: [],
  },
};

export const adminActiveTabTopNavData = {
  home: null,
  users: null,
  orgs: [
    {
      route: "/admin/orgs",
      title: "Orgs",
    },
    {
      route: "/admin/orgEntities",
      title: "Entity Management",
    },
    {
      route: "/admin/orgProperties",
      title: "Org Properties",
    },
    {
      route: "/admin/hr-onboarding",
      title: "HR Onboarding",
    },
    {
      route: "/admin/integrations",
      title: "Integrations",
    },
    {
      route: "/admin/enrollmentData",
      title: "Enrollment Data",
    },
  ],
  policies: [
    {
      route: "/admin/policies",
      title: "Policies",
    },
    {
      route: "/admin/benefits",
      title: "Benefits",
    },
    {
      route: "/admin/checkupPackages",
      title: "Checkup Packages",
    },
    {
      route: "/admin/policyDataChecks",
      title: "Policy Data Checks",
    },
    {
      route: "/admin/assessments",
      title: "Assessments",
    },
  ],
  insurers: [
    {
      route: "/admin/insurers",
      title: "Insurers",
    },
    {
      route: "/admin/tpas",
      title: "TPAs",
    },
    {
      route: "/admin/tpaOffices",
      title: "TPA Offices",
    },
    {
      route: "/admin/hospitals",
      title: "Hospitals",
    },
  ],
  cliTools: null,
  claims: [
    {
      route: "/admin/claims",
      title: "Claims",
    },
    {
      route: "/admin/stagedClaims",
      title: "Error Log",
    },
  ],
  endorsements: null,
  cxEntities: [
    {
      route: "/admin/CXPods",
      title: "CX Pods",
    },
    {
      route: "/admin/CXPodMembers",
      title: "CX Pod Members",
    },
    {
      route: "/admin/CXAccountOwners",
      title: "CX Account Owners",
    },
    {
      route: "/admin/CXPodOnboarding",
      title: "CX Pod Onboarding Form",
    },
  ],
  engineering: [
    {
      route: "/admin/jobs",
      title: "Jobs",
    },
    {
      route: "/admin/files",
      title: "Files",
    },
    {
      route: "/admin/samlVendorConfigs",
      title: "Saml Vendor configs",
    },
    {
      route: "/admin/forms",
      title: "Forms",
    },
    {
      route: "/admin/fileGroups",
      title: "File Groups",
    },
  ],

  teamManagement: [
    {
      route: "/admin/teams",
      title: "Teams",
    },
    {
      route: "/admin/designations",
      title: "Designations",
    },
    {
      route: "/admin/userTeamMappings",
      title: "Map Users",
    },
  ],
  fitness: [
    {
      route: "/admin/challengeTemplates",
      title: "Challenge Templates",
    },
    {
      route: "/admin/orgChallenges",
      title: "Org Challenges",
    },
    {
      route: "/admin/userChallenges",
      title: "User Challenges",
    },
    {
      route: "/admin/ngageCalendar",
      title: "Ngage Calendar",
    },
  ],

  novaOrders: [
    {
      route: "/admin/NovaOrders",
      title: "Orders",
    },
    {
      route: "/admin/PgPayments",
      title: "Payments",
    },
  ],
  analytics: null,
  miscellaneous: [
    {
      route: "/admin/content",
      title: "Content",
    },
    {
      route: "/admin/prospects",
      title: "Prospects",
    },
    {
      route: "/admin/compare-policy",
      title: "Compare Policy",
    },
    {
      route: "/admin/segments",
      title: "Segments",
    },
    {
      route: "/admin/userSegments",
      title: "User Segments",
    },
    {
      route: "/admin/echoPostTemplates",
      title: "Echo Post Templates",
    },
    {
      route: "/admin/echoPosts",
      title: "Echo Posts",
    },
  ],
};

export const industriesList = [
  "Staffing and Recruiting",
  "Education Administration Programs",
  "Human Resources",
  "Hospitals and Health Care",
  "Retail",
  "Chemical",
  "IT Services and IT Consulting",
  "Consumer Services",
  "Travel Arrangements",
  "Textile Manufacturing",
  "Market Research",
  "Education",
  "Environmental Services",
  "Service Provider",
  "Technology",
  "Retail, E -Commerce",
  "Insurance",
  "Biotechnology Research",
  "Manufacturing",
  "Machinery",
  "Financial Services",
  "Construction, Infrastructure",
  "Restaurants",
  "Business Consulting and Services",
  "Data Infrastructure and Analytics",
  "Human Resources Services",
  "E-Learning Providers",
  "Wholesale",
  "E-learning",
  "Food and Beverage Retail",
  "Internet",
  "Philanthropy",
  "Software Development",
  "Automation Machinery Manufacturing",
  "Hospitality",
  "Wellness and Fitness Services",
  "Engineering",
  "Computer Games",
  "Medical Equipment Manufacturing",
  "Technology, Information and Media",
  "Motor Vehicle Manufacturing",
  "BFSI",
  "Courier , Logistics , Supply Chain ,Packaging , Transport",
  "Civil Engineering",
  "Information Technology & Services",
  "Information Technology (IT)",
  "Computer Software",
  "Technology, Information and Internet",
  "Industrial Machinery Manufacturing",
  "Other",
];

export const orgServicesOptions = [
  { id: "WELLNESS_AND_INSURANCE", name: "Wellness and Insurance" },
  { id: "WELLNESS_ONLY", name: "Wellness Only" },
  { id: "INSURANCE_ONLY", name: "Insurance Only" },
];

export const ALLOWED_ORG_FIELDS_FOR_UPSERT = [
  "id",
  "name",
  "address",
  "pincode",
  "batchConfig",
  "meta",
  "status",
  "preferences",
];

export const FUTURE_MAX_LIMIT_DOJ = 30;
export const DEFAULT_DOJ_FOR_ORG = 45;

export const ngageDeviceIntegrationProvidersConfig = {
  [NgageDeviceIntegrationProviders.IOS_HEALTH_KIT]: {
    id: NgageDeviceIntegrationProviders.IOS_HEALTH_KIT,
    name: "Apple Health",
    os: "ios",
    image: "apple-health.png",
    active: true,
  },
  [NgageDeviceIntegrationProviders.ANDROID_HEALTH_CONNECT]: {
    id: NgageDeviceIntegrationProviders.ANDROID_HEALTH_CONNECT,
    name: "Android Health Connect",
    os: "android",
    image: "android-health-connect.png",
    active: true,
  },
  [NgageDeviceIntegrationProviders.GOOGLE_FIT]: {
    id: NgageDeviceIntegrationProviders.GOOGLE_FIT,
    name: "Google Fit",
    image: "google-fit.png",
    active: false,
  },
  [NgageDeviceIntegrationProviders.FITBIT]: {
    id: NgageDeviceIntegrationProviders.FITBIT,
    name: "Fitbit",
    image: "fitbit.png",
    active: false,
  },
  [NgageDeviceIntegrationProviders.MYFITNESSPAL]: {
    id: NgageDeviceIntegrationProviders.MYFITNESSPAL,
    name: "My Fitness Pal",
    image: "myfitnesspal.png",
    active: false,
  },
  [NgageDeviceIntegrationProviders.STRAVA]: {
    id: NgageDeviceIntegrationProviders.STRAVA,
    name: "Strava",
    image: "strava.png",
    active: false,
  },
  [NgageDeviceIntegrationProviders.GARMIN]: {
    id: NgageDeviceIntegrationProviders.GARMIN,
    name: "Garmin",
    image: "garmin.png",
    active: false,
  },
};
